import {Breadcrumb, Typography, Col, Row, Image} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import React, {useEffect} from "react";
import axiosInstance from "../../services/axios";

const {Title, Paragraph, Text} = Typography;

const VehicleManagement = (props) => {

    useEffect(() => {
        const payload = {page_name: 'help_center_vehicle_management'}
        axiosInstance.post('/api/visit', payload).then(res => {}).catch(error => {})
     }, []);

    return (<>
        <Title style={{margin: 0, marginBottom: 24}} level={1}> Vehicle Management </Title>
        <Row style={{gap: 16, maxWidth: 1200, marginBottom: 24}}>
            <Text>
                The management of vehicles differs between direct carvaloo customers and INVERS customers.
            </Text>
            <Row style={{gap: 0, maxWidth: 1200, marginBottom: 0}}>
                <Title style={{margin: 0, marginBottom: 0}} level={5}> carvaloo Customers </Title>
                <Text>
                    Currently, vehicles cannot be managed by customers directly in our WebApp. We can create new vehicles for you or update information (such as the license plate number) in the WebApp for you. Please contact us via <a href="mailto:operations-support@carvaloo.com">operations-support@carvaloo.com</a>.
                    As soon as the vehicles have been added to our system, they can be connected to the telematics using our InstallationApp so that the damage detection is activated.
                    The process for this is described in the
                    <a href={'#installation_app'} onClick={() => props.setActiveTab('mobile_apps')} style={{marginLeft: 4, marginRight: 4}}>
                        InstallationApp
                    </a>
                    section.
                </Text>
            </Row>
            <Row style={{gap: 0, maxWidth: 1200, marginBottom: 0}}>
                <Title style={{margin: 0, marginBottom: 0}} level={5}> INVERS Customers </Title>
                <Text>
                    INVERS customers can manage their vehicles in Fleet Control and Smart Control, i.e. create new vehicles or change the licence plate number. 
                    As long as the Damage Detection Module is (DDM) is activated, the changes are transferred directly to our WebApp via an API route between INVERS and carvaloo.
                </Text>
            </Row>
        </Row>
    </>)
};

export default VehicleManagement;
