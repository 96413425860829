import React, {useState, useRef, useEffect, useCallback, useContext} from "react";
import {GoogleMap, useJsApiLoader, InfoWindow, Marker, MarkerClusterer, Autocomplete} from "@react-google-maps/api";
import axiosInstance from "services/axios";
import {notification, Button} from "antd";
import {debounce} from 'lodash';
import {SettingsContext} from "../../App";

const containerStyle = {
    width: '100%',
    height: '100%'
};

const libraries = ['places'];

const SimpleMapComponent = (props) => {

    const containerRef = React.createRef();
    const [mapHeight, setMapHeight] = useState(window.innerHeight - 80);
    const [loadCounter, setLoadCounter] = useState(0);
    const [filteredMarkers, setFilteredMarkers] = useState([]);
    const [activeMarker, setActiveMarker] = useState(null);
    const [activeSecondaryMarker, setActiveSecondaryMarker] = useState(null);
    const [activeTertiaryMarker, setActiveTertiaryMarker] = useState(null);
    const [map, setMap] = useState(null)

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBZoGSnnZux0DvKt8qvhJenQL_OxnyqMjk",
        libraries: libraries
    })

    useEffect(() => {
        function handleResize() {
            const windowHeight = window.innerHeight;
            const mapRelativeHeight = props.relativeHeight || 86;
            const desiredHeight = (windowHeight * mapRelativeHeight) / 100 - 30; // 30% of the window height

            if (props.mapHeight) {
                setMapHeight(props.mapHeight);
            } else {
                setMapHeight(desiredHeight);
            }
        }

        // Initial calculation
        handleResize();

        // Add an event listener to the window for resizing
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [props.relativeHeight]);

    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(props.center);
        // console.log(bounds)
        map.fitBounds(bounds);
        // console.log(map.getZoom())
        setMap(map)
    }, [])

    // if (map) {
    //     console.log(map.getZoom())
    // }

    useEffect(() => {
        if (props.center && map) {
            const bounds = new window.google.maps.LatLngBounds(props.center);
            // Calculate latitude and longitude offsets for north, south, east, and west
            const offset = 0.1; // Adjust this value as needed for the desired distance
            const northPoint = new window.google.maps.LatLng(props.center.lat + offset, props.center.lng);
            const southPoint = new window.google.maps.LatLng(props.center.lat - offset, props.center.lng);
            const eastPoint = new window.google.maps.LatLng(props.center.lat, props.center.lng + offset);
            const westPoint = new window.google.maps.LatLng(props.center.lat, props.center.lng - offset);

            // Extend bounds to include all four additional points
            bounds.extend(northPoint);
            bounds.extend(southPoint);
            bounds.extend(eastPoint);
            bounds.extend(westPoint);

            // Fit the map to the adjusted bounds
            // console.log(bounds)
            map.fitBounds(bounds);
            // console.log(map.getZoom())
        }
    }, [props.center, map]);

    useEffect(() => {
        // Update the key whenever the prop changes
        if (map) {
            if (props.staticPolygon) {
                const polygon = new window.google.maps.Polygon(props.staticPolygon);

                const bounds = new window.google.maps.LatLngBounds();
                props.staticPolygon.paths.forEach(function (latLng) {
                    bounds.extend(latLng);
                });
                // console.log(bounds)
                map.fitBounds(bounds);
                // console.log(map.getZoom())
                polygon.setMap(map);
            }
        }
    }, [map, props.staticPolygon]);

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    useEffect(() => {
        // Update the key whenever the prop changes
        if (map) {
            map.setOptions({
                styles: !props.customStyle ? props.customStyle : [],
            });
        }
    }, [props.customStyle]);

    useEffect(() => {
        if (map) {

            let dynamicPolygons = []; // Store the circle instance

            // Clean up by removing all polygons
            if (dynamicPolygons.length > 0) {
                dynamicPolygons.forEach(polygon => {
                    polygon.instance.setMap(null);
                });
                dynamicPolygons.length = 0; // Clear the dynamicPolygons array
            }

            let bounds = new window.google.maps.LatLngBounds();

            // Draw the new polygons if the certain argument is provided in props
            if (props.dynamicPolygons && props.dynamicPolygons.length > 0 && map) {
                props.dynamicPolygons.forEach(polygonData => {

                    const polygonOptions = {
                        paths: polygonData.paths,
                        strokeColor: polygonData.strokeColor || '#3a9b2c',
                        strokeOpacity: polygonData.strokeOpacity || 0.50,
                        strokeWeight: polygonData.strokeWeight || 5,
                        fillColor: polygonData.fillColor || '#3a9b2c',
                        fillOpacity: polygonData.fillOpacity || 0,
                        map,
                    };

                    if (!props.markers) {
                        polygonData.paths.forEach(function (latLng) {
                            bounds.extend(latLng);
                        });
                    }

                    const newPolygon = new window.google.maps.Polygon(polygonOptions);
                    dynamicPolygons.push({instance: newPolygon, data: polygonData});

                    if (props.hoverDynamicPolygons) {
                        const infoWindow = new window.google.maps.InfoWindow({
                            content: polygonData.text || 'Hover text' // Customize the text to be displayed
                        });

                        newPolygon.addListener('mouseover', function (event) {
                            // Change polygon style or perform actions when mouse is over the polygon
                            // For example, change stroke color or fill opacity
                            newPolygon.setOptions({
                                fillOpacity: 0.5 // Change fill opacity to 50%
                            });
                            infoWindow.setPosition(event.latLng);
                            infoWindow.open(map);
                        });

                        newPolygon.addListener('mouseout', function () {
                            // Restore original style or perform actions when mouse leaves the polygon
                            // Restore original stroke color and fill opacity
                            newPolygon.setOptions({
                                fillOpacity: polygonData.fillOpacity || 0 // Restore original fill opacity
                            });
                            infoWindow.close();
                        });
                    }

                    // const lineSymbol = {
                    //   path: "M 0,-1 0,1",
                    //   strokeOpacity: 1,
                    //   scale: 4,
                    // };

                });
            }

            if (!props.markers && props.dynamicPolygons) {
                // console.log(bounds)
                map.fitBounds(bounds);
                // console.log(map.getZoom())
            }

            return () => {
                // Clean up by removing all polygons when the component unmounts
                dynamicPolygons.forEach(polygon => {
                    polygon.instance.setMap(null);
                });
            };
        }
    }, [map, props.dynamicPolygons, loadCounter]);

    useEffect(() => {

        setActiveMarker(null)

        if (map && props.markers && props.markers.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            props.markers.forEach((marker) => {

                if (props.selectedMarker) {
                    if (marker.location && marker.location.lat && marker.location.lon && marker.id === props.selectedMarker) {
                        bounds.extend(new window.google.maps.LatLng(marker.location.lat, marker.location.lon));

                        if ('current_location' in marker) {
                            bounds.extend(new window.google.maps.LatLng(marker.current_location.lat, marker.current_location.lon));
                        }
                    }
                } else {
                    if (marker.location && marker.location.lat && marker.location.lon) {
                        bounds.extend(new window.google.maps.LatLng(marker.location.lat, marker.location.lon));
                    }
                }
            });

            if (props.boundsSecondary && props.markersSecondary) {
                props.markersSecondary.forEach((marker) => {
                    if (marker.location && marker.location.lat && marker.location.lon) {
                        bounds.extend(new window.google.maps.LatLng(marker.location.lat, marker.location.lon));
                    }
                });
            }

            if (props.boundsTertiary && props.markersTertiary) {
                props.markersTertiary.forEach((marker) => {
                    if (marker.location && marker.location.lat && marker.location.lon) {
                        bounds.extend(new window.google.maps.LatLng(marker.location.lat, marker.location.lon));
                    }
                });
            }

            if (!props.ignoreBounds) {
                // console.log(bounds)
                map.fitBounds(bounds);
                // console.log(map.getZoom())

                // // Set a maximum zoom level
                const maxZoomLevel = props.maxZoomLevel ? props.maxZoomLevel : 10;
                if (map.getZoom() > maxZoomLevel) {
                    // console.log(map.getZoom())
                    map.setZoom(maxZoomLevel);
                }

                if (props.markers.length === 1 && (!props.boundsSecondary && props.markersSecondary)) {
                    map.setZoom(props.singleZoomLevel ? props.singleZoomLevel : 10)
                }
            }
        }
    }, [map, props.markers, props.markersSecondary, props.selectedMarker]);

    const getIconWithFormatting = (scalingFactor, markerColor, markerIcon, minSize) => {

        if (markerIcon === undefined) {
            return null
        }

        const scaledWidth = markerIcon.scaledWidth * scalingFactor;
        const scaledHeight = markerIcon.scaledHeight * scalingFactor;

        // Apply the minimum size constraint
        const finalWidth = Math.max(scaledWidth, minSize);
        const finalHeight = Math.max(scaledHeight, minSize);

        let anchorHeight = markerIcon.anchorHeight
        let anchorWidth = markerIcon.anchorWidth
        if (scalingFactor !== 1) {
            anchorHeight = finalHeight / 2
            anchorWidth = finalWidth / 2
        }

        let svgURI = markerIcon.url
        if (markerColor !== 'default') {
            const svgPart = markerIcon.url.split(",")[1];
            const decodedSvg = decodeURIComponent(svgPart);
            const coloredSvgString = decodedSvg.replace(/fill="#[0-9a-fA-F]+"/, `fill="${markerColor}"`);
            svgURI = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(coloredSvgString)}`;
        }

        return {
            url: svgURI,
            anchor: new window.google.maps.Point(anchorHeight, anchorWidth),
            scaledSize: new window.google.maps.Size(finalHeight, finalWidth),
        };
    };

    useEffect(() => {
            let timeoutIds = []; // Array to store timeout IDs for clearing later

            const clearAllTimeouts = () => {
                timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
                timeoutIds = []; // Clear the timeout IDs array
            };

            // Update the key whenever the prop changes
            if (props.markers) {

                let markerArray = props.markers

                if (props.selectedLabel) {
                    markerArray = props.markers.filter(marker => {
                        return props.selectedLabel.includes(marker.label); // Show markers with the selected label
                    })
                }

                if (props.animateTracking) {

                    const dataMarkerDelay = props.animationDuration / markerArray.length * 1000
                    const markerDelay = Math.max(dataMarkerDelay, 50)

                    if (props.pauseAnimateTracking === null) {
                        setFilteredMarkers([])
                        markerArray.forEach((marker, index) => {
                            const timeout = setTimeout(() => {
                                setFilteredMarkers(prevMarkers => [...prevMarkers, marker]);
                            }, index * markerDelay); // Change the delay (in milliseconds) as needed
                            timeoutIds.push(timeout); // Store timeout ID
                        });
                    } else if (props.pauseAnimateTracking === false) {
                        const currentIndex = filteredMarkers.length
                        markerArray.forEach((marker, index) => {
                            if (index >= currentIndex) {
                                const indexOffset = index - currentIndex
                                const timeout = setTimeout(() => {
                                    setFilteredMarkers(prevMarkers => [...prevMarkers, marker]);
                                }, indexOffset * markerDelay); // Change the delay (in milliseconds) as needed
                                timeoutIds.push(timeout); // Store timeout ID
                            }
                        });
                    }
                } else {
                    clearAllTimeouts();
                    setFilteredMarkers(markerArray)
                }
            } else {
                clearAllTimeouts();
                setFilteredMarkers([])
            }

            return clearAllTimeouts;

        }, [props.selectedLabel, props.markers, props.animateTracking, props.pauseAnimateTracking, props.animationDuration]
    );

    useEffect(() => {
        // Update the key whenever the prop changes
        if (map) {
            if (props.isPOIActive && props.customStyle) {
                const filteredGoogleMapsStyle = props.customStyle.filter(styleObject => {
                    return styleObject.featureType !== "poi";
                });
                filteredGoogleMapsStyle.push({featureType: "poi", stylers: [{visibility: "on"}]})
                map.setOptions({
                    styles: filteredGoogleMapsStyle,
                });
            }
        }
    }, [props.isPOIActive]);

    return isLoaded ? (
        <div style={{
            height: mapHeight, width: '100%', minHeight: 200,
            // borderRadius: 8, overflow: 'hidden'
        }}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                    gestureHandling: "cooperative",
                    styles: props.customStyle ? props.customStyle : [],
                    zoomControlOptions: {position: 6},
                    streetViewControlOptions: {position: 6},
                    fullscreenControlOptions: {position: 6},
                }}
            >
                {filteredMarkers && Array.isArray(filteredMarkers) ? (
                    filteredMarkers.map((marker, index) => {
                        if (marker.location && marker.location.lat && marker.location.lon) {

                            // console.log('Rendering')
                            // console.log(filteredMarkers)

                            let zIndex = 10000
                            if (marker.id === props.selectedMarker) {
                                zIndex = 30000
                            } else if (marker.zIndex) {
                                zIndex = marker.zIndex
                            }

                            let markerIcon = marker.icon
                            if (!props.deactivateSelectMode) {
                                if (props.selectedMarker !== null && marker.id !== props.selectedMarker) {
                                    markerIcon = 'none'
                                }
                            }

                            let iconUrl = props.iconDictionary[markerIcon]
                            if (props.selectedMarker !== null && marker.id !== props.selectedMarker && marker.label === 'resolved') {
                                return null;
                            }
                            const markerIconObj = {
                                url: iconUrl, // Set the icon based on selection
                                anchor: new window.google.maps.Point(marker.markerSize / 2 || 16, marker.markerSize / 2 || 16),
                                scaledSize: new window.google.maps.Size(marker.markerSize || 32, marker.markerSize || 32),
                            }

                            return (
                                <Marker
                                    key={`${index}-${props.renderMap}`}
                                    position={{lat: marker.location.lat, lng: marker.location.lon}}
                                    onClick={() => {
                                        if (activeMarker === marker) {
                                            props.onSelectedMarkerChange(null)
                                            setActiveMarker(null);
                                        } else {
                                            props.onSelectedMarkerChange(marker);
                                            setActiveMarker(marker);
                                        }
                                    }}
                                    zIndex={zIndex}
                                    opacity={marker.transparencyFactor || 1}
                                    icon={props.markerIconPrimary ? getIconWithFormatting(
                                        marker.scalingFactor || 1, marker.markerColor || 'default',
                                        props.markerIconPrimary, 8) : markerIconObj}
                                />
                            );
                        }
                        return null;
                    })
                ) : null}
                {activeMarker && props.markerInfo && (
                    <InfoWindow
                        position={{
                            lat: activeMarker.location.lat,
                            lng: activeMarker.location.lon,
                        }}
                        onCloseClick={() => {
                            setActiveMarker(null);
                        }}
                    >
                        {props.markerInfo(activeMarker)}
                    </InfoWindow>
                )}
                {props.markersSecondary && Array.isArray(props.markersSecondary)
                    ? props.markersSecondary.map((marker, index) =>
                        marker.location && marker.location.lat && marker.location.lon ? (
                            <Marker
                                key={index}
                                zIndex={1000}
                                onClick={() => {
                                    setActiveSecondaryMarker(marker)
                                }}
                                position={{lat: marker.location.lat, lng: marker.location.lon}}
                                opacity={marker.transparencyFactor || 1}
                                icon={Array.isArray(props.markerIconSecondary) ? getIconWithFormatting(
                                    marker.scalingFactor || 1, marker.markerColor || 'default',
                                    props.markerIconSecondary[index], 8) : getIconWithFormatting(
                                    marker.scalingFactor || 1, marker.markerColor || 'default',
                                    props.markerIconSecondary, 8)}
                            />
                        ) : null
                    )
                    : null}
                {activeSecondaryMarker && props.markerInfoSecondary && (
                    <InfoWindow
                        position={{
                            lat: activeSecondaryMarker.location.lat,
                            lng: activeSecondaryMarker.location.lon,
                        }}
                        onCloseClick={() => {
                            setActiveSecondaryMarker(null);
                        }}
                    >
                        {props.markerInfoSecondary(activeSecondaryMarker)}
                    </InfoWindow>
                )}
                {props.markersTertiary && Array.isArray(props.markersTertiary)
                    ? props.markersTertiary.map((marker, index) =>
                        marker.location && marker.location.lat && marker.location.lon ? (
                            <Marker
                                key={index}
                                onClick={() => {
                                    setActiveTertiaryMarker(marker)
                                }}
                                zIndex={100}
                                position={{lat: marker.location.lat, lng: marker.location.lon}}
                                opacity={marker.transparencyFactor || 1}
                                icon={props.markerIconTertiary ? getIconWithFormatting(
                                    marker.scalingFactor || 1, marker.markerColor || 'default',
                                    props.markerIconTertiary, 8) : undefined}
                            />
                        ) : null
                    )
                    : null}
                {activeTertiaryMarker && props.markerInfoTertiary && (
                    <InfoWindow
                        position={{
                            lat: activeTertiaryMarker.location.lat,
                            lng: activeTertiaryMarker.location.lon,
                        }}
                        onCloseClick={() => {
                            setActiveTertiaryMarker(null);
                        }}
                    >
                        {props.markerInfoTertiary(activeTertiaryMarker)}
                    </InfoWindow>
                )}
            </GoogleMap>
        </div>
    ) : <></>
}

export default React.memo(SimpleMapComponent)