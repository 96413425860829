import React, {useContext, useEffect, useState} from 'react';

import {Card, Col, Modal, notification, Spin, Row} from 'antd';

import axiosInstance from "services/axios";
import RelativeChart from "./RelativeChart"
import AbsoluteChart from "./AbsoluteChart"
import CountChart from "./CountChart";
import {SettingsContext} from "../../App.js";
import {convertMilesToKilometers} from "../../utility/conversions";


const ScoringOverview = (props) => {

    const [loading, setLoading] = useState(false);
    const [relativeData, setRelativeData] = useState(null);
    const [absoluteData, setAbsoluteData] = useState(null);
    const [countData, setCountData] = useState(null);
    const [meanData, setMeanData] = useState(null);
    const [maxAbsolute, setMaxAbsolute] = useState(null);
    const [maxCount, setMaxCount] = useState(null);
    const [clickedDriverId, setClickedDriverId] = useState(null);
    const {timezone, distance} = useContext(SettingsContext);

    const handleDriverClick = (params) => {
        if (params.data.data.driver !== 'average') {
            setClickedDriverId((prevDriverId) => {
                if (params.data.data.driver !== prevDriverId) {
                    props.setSelectedDriver(params.data.data.driver);
                    return params.data.data.driver;
                } else {
                    props.setSelectedDriver(null);
                    return null;
                }
            });
        }
    };

    const updateDriverData = (prevData, clickedDriverId) => {
        if (prevData) {

            // Update the array based on the selected driver
            if (clickedDriverId) {
                return prevData.map(element => {
                    const currentType = element.type.replace('_no_click', '')
                    if (element.driver === clickedDriverId) {
                        return {...element, type: currentType.replace('_no_click', '')};
                    } else {
                        return {...element, type: currentType + '_no_click'};
                    }
                })
            } else {
                return prevData.map(element => {
                    const currentType = element.type
                    return {...element, type: currentType.replace('_no_click', '')};
                })
            }
        } else {
            return prevData
        }
    };

    useEffect(() => {
        setClickedDriverId(props.selectedDriver)
    }, [props.selectedDriver]);

    useEffect(() => {
        setRelativeData((prevRelativeData) => updateDriverData(prevRelativeData, clickedDriverId));
        setAbsoluteData((prevAbsoluteData) => updateDriverData(prevAbsoluteData, clickedDriverId));
        setCountData((prevCountData) => updateDriverData(prevCountData, clickedDriverId));
    }, [clickedDriverId]);


    useEffect(() => {
        const payload = {
            customer: props.customer,
            page: 0,
            distance: distance,
            cat_value: props.categoryValue,
            sort_value: props.sortValue,
            page_size: props.displaySize,
            date_start: props.dateRangeFilter[0],
            date_end: props.dateRangeFilter[1],
            min_distance: convertMilesToKilometers(props.minDistance, distance),
            min_trip_count: props.minTripCount,
            min_reservation_count: props.minReservationCount
        };

        setLoading(true)
        axiosInstance.post('/api/prevention/charts', payload)
            .then(res => {
                setRelativeData(res.data.relative_data)
                setAbsoluteData(res.data.absolute_data)
                setCountData(res.data.count_data)
                setMeanData(res.data.mean_data)
                setMaxAbsolute(res.data.absolute_max)
                setMaxCount(res.data.count_max)
                setLoading(false)
                if (res.data.relative_data.length === 0) {
                    notification['warning']({message: 'No data available with selected filters'});
                }
            })
            .catch(error => {
                setLoading(false)
                console.log(error);
                notification['error']({message: 'Error in ScoringOverview', description: error.message});
            })
    }, [props.customer, props.categoryValue, props.sortValue, props.displaySize, props.dateRangeFilter, props.minDistance, props.minTripCount, props.minReservationCount, distance]);

    // const handleSelectClick = (value) => {
    //     if (value === 'all') {
    //         props.setActionFilter(['firing', 'pending', 'resolved'])
    //     } else if (value === 'active') {
    //         props.setActionFilter(['firing', 'pending'])
    //     } else {
    //         props.setActionFilter([value])
    //     }
    //     props.handleSelectedChange(null)
    // };

    return (<>
            <Modal
                open={loading}
                closable={false}
                footer={null}
                centered={true}
                mask={true}
                maskClosable={false}
                styles={{ body: {backdropFilter: 'blur(3px)'}}}
            >
                <div>
                    <Spin style={{marginRight: 20}} size="large"/>
                    Loading charts...
                </div>
            </Modal>
            <Card
                style={{
                    width: 'calc(100% - 336px)',
                    height: 678,
                    boxSizing: 'border-box',
                    border: '1px solid #e8e8e8',
                    marginLeft: 16,
                }}
                styles={{ body: {
                    paddingRight: 24,
                    paddingLeft: 24,
                    paddingTop: 10,
                    paddingBottom: 10,
                }}}
                title={<>
                    Scoring Overview
                    {/*<InfoTooltip*/}
                    {/*    text=*/}
                    {/*        "GPS Track before and after the alert as well as the last GPS Position of the vehicle.*/}
                    {/*            Active alerts include firing and pending alerts."*/}
                    {/*/>*/}
                </>}
            >
                {relativeData && absoluteData && countData &&
                    <Row>
                        <Col span={3} style={{marginLeft: -24}}>
                            <RelativeChart
                                data={relativeData.filter((point) => point.driver === 'average')}
                                average={true}
                                titleColor={'white'}
                                title={'.'}
                                hideAxisY={true}
                                handleDriverClick={handleDriverClick}
                                categoryValue={props.categoryValue}
                                sortValue={props.sortValue}
                            />
                            <AbsoluteChart
                                data={absoluteData.filter((point) => point.driver === 'average')}
                                average={true}
                                titleColor={'white'}
                                maxValueY={maxAbsolute}
                                title={'.'}
                                hideAxisY={true}
                                handleDriverClick={handleDriverClick}
                                categoryValue={props.categoryValue}
                            />
                            <CountChart
                                data={countData.filter((point) => point.driver === 'average')}
                                average={true}
                                titleColor={'white'}
                                maxValueY={maxCount}
                                title={'.'}
                                hideAxisY={true}
                                handleDriverClick={handleDriverClick}
                                categoryValue={props.categoryValue}
                            />
                        </Col>
                        <Col span={21}>
                            <RelativeChart
                                data={relativeData.filter((point) => point.driver !== 'average')}
                                average={true}
                                // meanData={meanData}
                                handleDriverClick={handleDriverClick}
                                categoryValue={props.categoryValue}
                                sortValue={props.sortValue}
                            />
                            <AbsoluteChart
                                data={absoluteData.filter((point) => point.driver !== 'average')}
                                average={true}
                                maxValueY={maxAbsolute}
                                handleDriverClick={handleDriverClick}
                                categoryValue={props.categoryValue}
                            />
                            <CountChart
                                data={countData.filter((point) => point.driver !== 'average')}
                                average={true}
                                maxValueY={maxCount}
                                handleDriverClick={handleDriverClick}
                                categoryValue={props.categoryValue}
                            />
                        </Col>
                    </Row>
                }
            </Card>
        </>
    );
}

export default ScoringOverview;