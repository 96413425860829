export function CheckboxRenderer() {}

    CheckboxRenderer.prototype.init = function(params) {
      this.params = params;

      this.eGui = document.createElement('input');
      this.eGui.type = 'checkbox';
      this.eGui.disabled = true
      this.eGui.checked = params.value;
    }

    CheckboxRenderer.prototype.getGui = function(params) {
      return this.eGui;
    }