import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';

import {
    Button,
    Card,
    Row, Typography, Divider
} from 'antd';
import dayjs, {Dayjs} from "dayjs";
import {SettingsContext} from "../../App.js";
import {convertKilometersToMiles} from '../../utility/conversions.js';

const {Title, Paragraph, Text, Link} = Typography;


const MapMarker = (props) => {

    return (
        <div style={{whiteSpace: "pre-line"}}>
            <Row>
                <Text>
                   Warning ID: {props.id}
                </Text>
            </Row>
        </div>
    );
}

export default MapMarker;