import React, {useEffect, useState, useContext} from "react";
import {useLocation, Link, useNavigate} from "react-router-dom";
import {
    ConfigProvider,
    Breadcrumb,
    Layout,
    Menu,
    Image,
    Form,
    Switch,
    Typography,
    Popover,
    Dropdown,
    Space,
    message,
    notification,
    Radio,
    Button,
    Row,
    Input, Col, Tabs
} from 'antd';
import Icon, {
    GroupOutlined,
    HomeOutlined,
    MobileOutlined,
    QuestionCircleOutlined,
    SafetyOutlined,
    UserOutlined
} from "@ant-design/icons";
import OverviewCard from "./OverviewCard";

import {ReactComponent as Installation} from "../../assets/icons/carvaloo-settings_current.svg";
import {ReactComponent as User} from "../../assets/icons/carvaloo-user_current.svg";
import {ReactComponent as Events} from "../../assets/icons/carvaloo-events_current.svg";
import {ReactComponent as Fleet} from "../../assets/icons/carvaloo-fleet_current.svg";
import {ReactComponent as Security} from "../../assets/icons/carvaloo-security_blue.svg";

const {Title, Paragraph, Text} = Typography;
const {Search} = Input;

const Overview = (props) => {

    // Return any JSX for the HomeScreen component (optional)
    return (<>
        <div>
            <Row style={{gap: 24, marginBottom: 24}}>
                <OverviewCard
                    icon={<Icon component={Events} style={{
                        fontSize: '72px',
                        verticalAlign: 'middle',
                        fill: '#088ed7',
                        color: '#088ed7'
                    }}/>}
                    title={'Damage Events'}
                    text={'The carvaloo AI automatically detects and notifies on all potential damage events. This section describes the carvaloo damage detection and damage case management in the carvaloo WebApp.'}
                    setActiveTab={props.setActiveTab}
                    tabKey={'damage_events'}
                />
                <OverviewCard
                    icon={<Icon component={Installation} style={{
                        fontSize: '72px',
                        verticalAlign: 'middle',
                        fill: '#088ed7',
                        color: '#088ed7'
                    }}/>}
                    title={'Installation'}
                    text={'The activation of the carvaloo services requires the physical installation of a telematics unit and the virtual pairing between vehicle and telematics unit.'}
                    setActiveTab={props.setActiveTab}
                    tabKey={'installation'}
                />
                <OverviewCard
                    icon={<Icon component={User} style={{
                        fontSize: '72px',
                        verticalAlign: 'middle',
                        fill: '#088ed7',
                        color: '#088ed7'
                    }}/>}
                    title={'User Management'}
                    text={'Manage users, user access to carvaloo apps and the recipients for carvaloo notifications.'}
                    setActiveTab={props.setActiveTab}
                    tabKey={'user_management'}
                />
                <OverviewCard
                    icon={<MobileOutlined style={{fontSize: '72px', verticalAlign: 'middle', color: '#088ed7'}}/>}
                    title={'Mobile Apps'}
                    text={'carvaloo provides browser-based mobile apps to manage installation/deinstallation of telematics sensor units, carry out vehicle inspections and provide feedback to damage events.'}
                    setActiveTab={props.setActiveTab}
                    tabKey={'mobile_apps'}
                />
                <OverviewCard
                    icon={<Icon component={Fleet} style={{
                        fontSize: '72px',
                        verticalAlign: 'middle',
                        fill: '#088ed7',
                        color: '#088ed7'
                    }}/>}
                    title={'Vehicle Management'}
                    text={'Manage vehicle, station and fleet information.'}
                    setActiveTab={props.setActiveTab}
                    tabKey={'vehicle_management'}
                />
                {
                    props.permissions?.security &&
                    <OverviewCard
                        icon={<Icon component={Security} style={{
                            fontSize: '72px',
                            verticalAlign: 'middle',
                            fill: '#088ed7',
                            color: '#088ed7'
                        }}/>}
                        title={'Fleet Security'}
                        text={'The fleet security service supports the fleet operator regarding rim thefts, vehicle thefts, vandalism or vehicles leaving the permitted operation area.'}
                        setActiveTab={props.setActiveTab}
                        tabKey={'fleet_security'}
                    />
                }
                <OverviewCard
                    icon={<QuestionCircleOutlined
                        style={{fontSize: '72px', verticalAlign: 'middle', color: '#088ed7'}}/>}
                    title={'FAQ'}
                    text={'Find the frequently asked questions by our customers in this section.'}
                    setActiveTab={props.setActiveTab}
                    tabKey={'faq'}
                />
            </Row>
        </div>

    </>)
};

export default Overview;
