import {Breadcrumb, Typography, Col, Row, Image} from "antd";
import Icon, {
    InfoCircleOutlined,
    CheckOutlined,
    BellOutlined,
    ExclamationCircleFilled,
    WarningFilled, CloseCircleFilled, CheckCircleOutlined, AlertOutlined, CopyOutlined
} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import axiosInstance from "../../services/axios";

const {Title, Paragraph, Text} = Typography;

const FleetSecurity = (props) => {

    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoaded = () => {
        setImageLoaded(true);
    };

    useEffect(() => {
        const payload = {page_name: 'help_center_vehicle_management'}
        axiosInstance.post('/api/visit', payload).then(res => {
        }).catch(error => {
        })
    }, []);

    return (<>
        {
            props.permissions?.security &&
            <>
                <Title style={{margin: 0, marginBottom: 24}} level={1}> Fleet Security </Title>
                <Row style={{gap: 16, maxWidth: 1200, marginBottom: 24}}>
                    <Text>
                        The fleet security service consists of four components: Geofencing, Theft, Suspicious Activity,
                        Reservation. To ensure full functionality, the booking data is essential for distinguishing
                        between
                        normal and suspicious behavior. The Suspicious Activity and Reservation components rely fully on
                        booking
                        data, while the Theft component relies partly on it.
                    </Text>
                    <Row style={{gap: 0, maxWidth: 1200, marginBottom: 24}}>
                        <Title id="geofencing" style={{margin: 0, marginBottom: 24}} level={3}> Geofencing </Title>
                        <Text>
                            Geofences are specific polygons of geocoordinates. Those polygons are specific geographic
                            zones
                            which define the operating area where the car is allowed or not allowed to drive. If the car
                            leaves
                            the allowed zone (zone exit) or enters a prohibited area (zone enter), a geofence alert will
                            be
                            triggered. Thereby the alerts can assume three different states.
                        </Text>
                    </Row>
                    <Row style={{gap: 0, maxWidth: 600, marginBottom: 0}}>
                        <Col span={3} style={{display: 'flex'}}>
                            <ExclamationCircleFilled
                                style={{fontSize: 28, color: '#088ED7', marginLeft: "auto", marginRight: 16}}/>
                        </Col>
                        <Col span={21}>
                            <Text>
                                The alert will be 'Pending' as soon as the vehicles leaves the operating area until the
                                previous
                                defined time outside the area is reached (e.g. 60 minutes).
                            </Text>
                        </Col>
                    </Row>
                    <Row style={{gap: 0, maxWidth: 600, marginBottom: 0}}>
                        <Col span={3} style={{display: 'flex'}}>
                            <CheckCircleOutlined
                                style={{fontSize: 28, color: '#088ED7', marginLeft: "auto", marginRight: 16}}/>
                        </Col>
                        <Col span={21}>
                            <Text>
                                If the car enters the operating area again within the pending time, the alert will
                                automatically
                                switch to 'Resolved'. </Text>
                        </Col>
                    </Row>
                    <Row style={{gap: 0, maxWidth: 600, marginBottom: 0}}>
                        <Col span={3} style={{display: 'flex'}}>
                            <WarningFilled
                                style={{fontSize: 28, color: '#088ED7', marginLeft: "auto", marginRight: 16}}/>
                        </Col>
                        <Col span={21}>
                            <Text>
                                After the pending time, the alert will switch to state 'Firing'. For those confirmed
                                alerts,
                                notifications to the fleet operator can be enabled. </Text>
                        </Col>
                    </Row>
                    <Row style={{gap: 0, maxWidth: 600, marginBottom: 0}}>
                        <Col span={3} style={{display: 'flex'}}>
                            <CloseCircleFilled
                                style={{fontSize: 28, color: '#E55555', marginLeft: "auto", marginRight: 16}}/>
                        </Col>
                        <Col span={21}>
                            <Text>
                                The fleet operator can dismiss any alert at any time manually if the exit of the
                                operating area
                                is not relevant. In this case, the state of the alert will switch to 'Resolved'. </Text>
                        </Col>
                    </Row>
                    <Text>
                        The current configuration of geofences including the pending time can be viewed in the
                        configuration
                        tab. Please contact us, if you want to change the configuration.
                    </Text>
                    <Image
                        preview={false}
                        width={'100%'}
                        style={{paddingBottom: imageLoaded ? 0 : '40%', maxWidth: 800}}
                        src={require("../../assets/images/help_center/config.png")}
                        onLoad={handleImageLoaded}
                    />
                    <Row style={{gap: 0, maxWidth: 1200, marginBottom: 24}}>
                        <Title id="theft" style={{margin: 0, marginBottom: 24}} level={3}> Theft </Title>
                        <Text>
                            Theft alerts will be triggered if our AI models detect an unallowed tire change (Component
                            Theft) or
                            the vehicle is moved outside a booking (Vehicle Theft).
                            Thereby, it is possible to define specific geocoordinates where technical interventions on
                            the
                            vehicle are allowed, e.g. cooperating workshops, so that no alert is triggered.
                        </Text>
                    </Row>
                    <Row style={{gap: 0, maxWidth: 600, marginBottom: 0}}>
                        <Col span={3} style={{display: 'flex'}}>
                            <WarningFilled
                                style={{fontSize: 28, color: '#088ED7', marginLeft: "auto", marginRight: 16}}/>
                        </Col>
                        <Col span={21}>
                            <Text>
                                Every theft event which is recognized by the AI is immediately triggering a notification
                                to the
                                fleet operator ('Firing' state). </Text>
                        </Col>
                    </Row>
                    <Row style={{gap: 0, maxWidth: 600, marginBottom: 0}}>
                        <Col span={3} style={{display: 'flex'}}>
                            <CheckCircleOutlined
                                style={{fontSize: 28, color: '#088ED7', marginLeft: "auto", marginRight: 16}}/>
                        </Col>
                        <Col span={21}>
                            <Text>
                                The fleet operator has the option to confirm these alerts. Once an alert has been
                                confirmed, a
                                specific event can be created. After creation, it will be visible on the 'Events' page
                                and can
                                be managed in a similar manner to damage events. </Text>
                        </Col>
                    </Row>
                    <Row style={{gap: 0, maxWidth: 600, marginBottom: 0}}>
                        <Col span={3} style={{display: 'flex'}}>
                            <CloseCircleFilled
                                style={{fontSize: 28, color: '#E55555', marginLeft: "auto", marginRight: 16}}/>
                        </Col>
                        <Col span={21}>
                            <Text>
                                The alerts can also be dismissed if the theft event is incorrect or
                                is not relevant. In these cases it is also possible to confirm the alert again at a
                                later stage.
                            </Text>
                        </Col>
                    </Row>
                    <Row style={{gap: 0, maxWidth: 1200, marginBottom: 24}}>
                        <Title id="suspicious" style={{margin: 0, marginBottom: 24}} level={3}> Suspicious
                            Activity </Title>
                        <Text>
                            The Suspicious Activity service component includes alerts which are triggered through our
                            pattern-based AI which detects suspicious movement such as vandalism outside a reservation.
                            Those alerts can be managed the same way as Theft alerts (see above).
                        </Text>
                    </Row>
                    <Row style={{gap: 0, maxWidth: 1200, marginBottom: 24}}>
                        <Title id="reservation" style={{margin: 0, marginBottom: 24}} level={3}> Reservation </Title>
                        <Text>
                            The reservation component gives an estimation if ongoing reservations can be finished in
                            time.
                            Thereby we calculate if the vehicle can be brought back to the station or a defined
                            operating area
                            (defined geofence) within the booking time based on the current vehicle position.
                        </Text>
                    </Row>
                    <Row style={{gap: 0, maxWidth: 1200, marginBottom: 24}}>
                        <Title id="manage" style={{margin: 0, marginBottom: 24}} level={3}> Manage the Security
                            Alerts </Title>
                        <Text>
                            The management of the various security alerts within the user interface has been designed to
                            be as
                            intuitive as possible and is described in more detail below.
                        </Text>
                    </Row>
                    <Row style={{gap: 0, maxWidth: 1200, marginBottom: 0}}>
                        <Title style={{margin: 0, marginBottom: 0}} level={5}> Filter alerts </Title>
                        <Text>
                            The filters help to organize the different alerts. When setting a specific time range or
                            status
                            filter, the map and table with the alerts will automatically be adjusted. By default, only
                            alerts
                            within the last 30 days are being shown. The global search bar allows to search for various
                            alert
                            details - such as different geofences. Click on the respective dashboard card to get an
                            overview of
                            the alerts of one specific service component (e.g. Thefts).
                        </Text>
                        <Image
                            preview={false}
                            width={'100%'}
                            style={{paddingBottom: imageLoaded ? 0 : '20%', maxWidth: 800, marginTop: 16}}
                            src={require("../../assets/images/help_center/dashboard.png")}
                            onLoad={handleImageLoaded}
                        />
                    </Row>
                    <Row style={{gap: 0, maxWidth: 1200, marginBottom: 0}}>
                        <Title style={{margin: 0, marginBottom: 0}} level={5}> Select an alert</Title>
                        <Text>
                            The details of a specific alert can be viewed by clicking on the respective dot in the map
                            or
                            selecting the alert via the alert table below the map. After selecting, the map shows
                            additional
                            details depending on the alert category. E.g. in the case of geofence alerts, the specific
                            trip and
                            the live location of the vehicle is shown, if the alert is still in state 'Firing'.
                        </Text>
                        <Image
                            preview={false}
                            width={'100%'}
                            style={{paddingBottom: imageLoaded ? 0 : '40%', maxWidth: 800, marginTop: 16}}
                            src={require("../../assets/images/help_center/table.png")}
                            onLoad={handleImageLoaded}
                        />
                    </Row>
                    <Row style={{gap: 0, maxWidth: 1200, marginBottom: 0}}>
                        <Title style={{margin: 0, marginBottom: 0}} level={5}> Update an alert</Title>
                        <Text>
                            The status of each alert can be changed by the fleet operator by clicking 'Dismiss' or
                            'Confirm' in
                            the event details tile. However it is not possible to manually confirm geofencing alerts as
                            these are bound to the physical location of the vehicle.
                        </Text>
                    </Row>
                </Row>
            </>
        }
    </>)
};

export default FleetSecurity;
