import React, {useState, useEffect} from 'react';
import {Descriptions, Card, Badge, Form, Input, Popover, Button, Table, Row, Tabs, Typography} from 'antd';
import axiosInstance from "services/axios";

const {Title, Paragraph, Text, Link} = Typography;
const {TabPane} = Tabs;

const HeaderRow = (props) => {

    return (
        <>
            <Card
                style={{
                    width: '100%',
                    height: 80,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #e8e8e8',
                    marginBottom: '8px',
                }}
                styles={{ body: {
                    paddingRight: 24,
                    paddingLeft: 24,
                    paddingTop: 10,
                    paddingBottom: 10,
                }}}
            >
                <Text style={{fontWeight: 500, fontSize: 20}}>{props.title}</Text>
                {props.tabItems &&
                    <Tabs
                        defaultActiveKey={props.default ? props.default : "1"}
                        items={props.tabItems}
                        style={{height: 32, fontSize: 16}}
                        size={'small'}
                        onTabClick={(e) => props.setActiveTab(e)}
                    />
                }
            </Card>
        </>
    );
};

export default HeaderRow;