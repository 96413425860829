import React, { useState, useRef, useEffect } from 'react';
import { Popover, Card } from 'antd';

function HoverCellRenderer(props) {
  const [isHovering, setIsHovering] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  function handleMouseEnter() {
    timeoutRef.current = window.setTimeout(() => {
      setIsHovering(true);
    }, 500);
  }

  function handleMouseLeave() {
    window.clearTimeout(timeoutRef.current);
    setIsHovering(false);
  }

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {props.value}
      {isHovering && (
        // <Popover content={<Card>{props.value}</Card>}>
        //   <div style={{ display: 'none' }} />
        // </Popover>
          <div> TEST</div>
      )}
    </div>
  );
}

export default HoverCellRenderer;