import React, {useEffect, useState, useContext} from "react";
import {
    Typography,
    Input, Col, Tabs, Tooltip
} from 'antd';
import Icon, {HomeOutlined, InfoCircleOutlined} from "@ant-design/icons";


const InfoTooltip = ({text}) => {
    return (
        <Tooltip title={text}>
            <InfoCircleOutlined style={{color: '#1890ff', fontSize: '12px', marginLeft: '5px'}}/>
        </Tooltip>
    );
};

export default InfoTooltip;
