import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';
import {debounce} from 'lodash';
import ReactJson from "react-json-view";
import {
    Alert,
    Button,
    Card,
    Descriptions,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Radio,
    Typography,
    Input,
    Popover,
    Switch,
    Table,
    Slider,
    Form,
    Divider, Tooltip, Statistic, DatePicker
} from 'antd';

import Icon, {InfoCircleOutlined, CheckOutlined, BellOutlined, DeleteOutlined} from "@ant-design/icons";
import {FaFile, FaCog} from 'react-icons/fa';
import axiosInstance from "services/axios";
import ToDoInfoModal from "../DashboardCarvaloo/ToDoInfoModal";
import "./custom.css";
import calendarIcon from '../../assets/icons/calendar.svg';
import numberIcon from '../../assets/icons/number.svg';
import editIcon from '../../assets/icons/edit.svg';
import compassIcon from '../../assets/icons/compass.svg';
import checkIcon from '../../assets/icons/check.svg';
import crossIcon from '../../assets/icons/red_x.svg';
import dayjs from "dayjs";
import {SettingsContext} from "../../App.js";

import {convertKilometersToMiles, convertMilesToKilometers} from '../../utility/conversions.js';

const {Search} = Input;
const {Title, Text} = Typography;
const {RangePicker} = DatePicker;


const Configurator = (props) => {

    const {timezone, distance} = useContext(SettingsContext);

    const [data, setData] = useState(null);
    const [currentDateRange, setCurrentDateRange] = useState(props.dateRangeFilter);
    const [displaySize, setDisplaySize] = useState(props.displaySize);
    const [minDistance, setMinDistance] = useState(props.minDistance);
    const [minTripCount, setMinTripCount] = useState(props.minTripCount);
    const [minReservationCount, setMinReservationCount] = useState(props.minReservationCount);

    const sliderMarks = {
        10: '10',
        20: '20',
        30: '30',
        40: '40',
        50: '50',
    };
    const maxSliderDriver = Math.max(...Object.keys(sliderMarks).map(Number));

    const distanceMarks = {
        0: '0',
        50: '50',
        100: '100',
        250: '250',
        500: '500',
        // 750: '750',
        // 1000: '1000',
    };
    const maxSliderDistance = Math.max(...Object.keys(distanceMarks).map(Number));

    const tripMarks = {
        0: '0',
        10: '10',
        20: '20',
        50: '50',
        100: '100',
        // 150: '150',
        // 200: '200',
        // 250: '250',
    };
    const maxSliderTrip = Math.max(...Object.keys(tripMarks).map(Number));

    const reservationMarks = {
        0: '0',
        // 2: '2',
        5: '5',
        10: '10',
        25: '25',
        // 30: '30',
        // 40: '40',
        50: '50',
    };
    const maxSliderReservation = Math.max(...Object.keys(reservationMarks).map(Number));
    // const handleResolveClick = (e) => {
    //     props.setSortValue(e.target.value)
    // };

    const handleCategoryChange = (e) => {
        props.setCategoryValue(e.target.value)
    };

    const handleRadioChange = (e) => {
        props.setSortValue(e.target.value)
    };

    // const debouncedSetDisplay = useCallback(
    //     debounce((value) => {
    //         props.setDisplaySize(value);
    //     }, 1000)(), []
    // );
    //
    // const handleSliderChange = (value) => {
    //     setDisplaySize(value)
    //     debouncedSetDisplay(value);
    // }

    const handleDateRangeChange = (data) => {
        if (currentDateRange[1] === null || currentDateRange[0] === null) {
            if (data[1] !== null && data[0] !== null) {
                setCurrentDateRange([data[0], data[1].endOf('month')]);
                props.setDateRangeFilter([data[0], data[1].endOf('month')]);
            }
        } else if (data[0] !== props.dateRangeFilter[0]) {
            setCurrentDateRange([data[0], null]);
        } else if (data[1] !== props.dateRangeFilter[1]) {
            setCurrentDateRange([null, data[1]]);
        }
    }

    const handleDistanceChange = debounce((value) => {
        props.setMinDistance(value)
    }, 1000); // 1000 milliseconds (1 second) delay

    const handleTripChange = debounce((value) => {
        if (value) {
            props.setMinTripCount(parseInt(value))
        } else {
            props.setMinTripCount(10)
        }
    }, 1000); // 1000 milliseconds (1 second) delay

    const handleReservationChange = debounce((value) => {
        if (value) {
            props.setMinReservationCount(parseInt(value))
        } else {
            props.setMinReservationCount(10)
        }

    }, 1000); // 1000 milliseconds (1 second) delay

    return (<>
            <Card
                style={{
                    width: 320,
                    height: 678,
                    boxSizing: 'border-box',
                    border: '1px solid #e8e8e8',
                }}
                styles={{ body: {
                    paddingRight: 24,
                    paddingLeft: 24,
                    paddingTop: 24,
                    paddingBottom: 24,
                }}}
                title="Scoring Configurator"
                // extra={
                //     !data?.resolved && props.permissions.security === 'fleet-security_editor' &&
                //     <Button
                //         onClick={handleResolveClick}
                //         icon={<DeleteOutlined style={{marginRight: 8}}/>}
                //         style={{
                //             // backgroundColor: '#ffffff',
                //             // color: '#E55555',
                //             // borderColor: '#E55555',
                //             marginLeft: 12
                //         }}
                //     >
                //         Reset
                //     </Button>
                // }
            >
                <Row gutter={[8, 16]} style={{marginLeft: 2}}>
                    <Row style={{marginTop: -8}}>
                        <Text style={{marginRight: 100}}>Rank by category</Text>
                    </Row>
                    <Row style={{marginTop: -8}}>
                        <Radio.Group
                            onChange={handleCategoryChange}
                            options={[
                                {label: 'Time', value: 'time'},
                                {label: 'Distance', value: 'distance'},
                            ]}
                            value={props.categoryValue}
                            optionType="button"
                            buttonSolidCheckedActiveBg={'red'}
                            buttonStyle={{
                                buttonCheckedBg: 'red'
                            }}
                        />
                    </Row>
                    <Row>
                        <Text>Rank by driving behavior</Text>
                        <Radio.Group
                            onChange={handleRadioChange}
                            style={{marginTop: 8}}
                            options={[
                                {label: 'Aggressive', value: 'aggressive'},
                                {label: 'Normal', value: 'normal'},
                                {label: 'Cautious', value: 'cautious'},
                            ]}
                            value={props.sortValue}
                            optionType="button"
                            buttonSolidCheckedActiveBg={'red'}
                            buttonStyle={{
                                buttonCheckedBg: 'red'
                            }}
                        />
                    </Row>
                    <Row>
                        <Text>Number of displayed drivers</Text>
                        <Slider
                            style={{marginRight: 12, marginLeft: 12, marginTop: 8, width: 240}}
                            marks={sliderMarks}
                            min={10}
                            max={maxSliderDriver}
                            value={displaySize}
                            onChange={(value) => setDisplaySize(value)}
                            onAfterChange={(value) => props.setDisplaySize(value)}
                        />
                    </Row>
                    <Divider style={{marginTop: -10, marginBottom: -10}}>Optional Filters</Divider>
                    <Row>
                        <Text>Time range</Text>
                        <RangePicker
                            style={{width: 260, marginBottom: 0}}
                            value={currentDateRange}
                            defaultValue={props.dateRangeFilter}
                            onChange={handleDateRangeChange}
                            picker={'month'}
                        />
                    </Row>
                    <Row>
                        <Text>Minimum total driven distance {distance === 'kilometers' ? '(km)' : '(miles)'}</Text>
                        <Slider
                            style={{marginRight: 12, marginLeft: 12, marginTop: 8, width: 240}}
                            marks={distanceMarks}
                            min={0}
                            max={maxSliderDistance}
                            value={minDistance}
                            onChange={(value) => setMinDistance(value)}
                            onAfterChange={(value) => props.setMinDistance(value)}
                        />
                    </Row>
                    <Row>
                        <Text>Minimum total reservation count</Text>
                        <Slider
                            style={{marginRight: 12, marginLeft: 12, marginTop: 8, width: 240}}
                            marks={reservationMarks}
                            min={0}
                            max={maxSliderReservation}
                            value={minReservationCount}
                            onChange={(value) => setMinReservationCount(value)}
                            onAfterChange={(value) => props.setMinReservationCount(value)}
                        />
                    </Row>
                    <Row>
                        <Text>Minimum total trip count</Text>
                        <Slider
                            style={{marginRight: 12, marginLeft: 12, marginTop: 8, width: 240}}
                            marks={tripMarks}
                            // tooltip={{ formatter: null }}
                            min={0}
                            max={maxSliderTrip}
                            value={minTripCount}
                            onChange={(value) => setMinTripCount(value)}
                            onAfterChange={(value) => props.setMinTripCount(value)}
                        />
                    </Row>
                </Row>

                {/*<Form.Item*/}
                {/*    label="Select vehicle type"*/}
                {/*    // tooltip={{title: 'Tooltip with customize icon'}}*/}
                {/*>*/}
                {/*    <Input placeholder="type"/>*/}
                {/*</Form.Item>*/}
            </Card>
        </>
    )
        ;
}


export default Configurator;
