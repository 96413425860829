import {Popover, Button, Checkbox, Form, Input, Typography, notification, Divider, Radio, Select, Card} from 'antd';

import React from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import moment from "moment";
import axiosInstance from "services/axios";
import {useAuth} from "react-oidc-context";

dayjs.extend(utc);

const { Title, Text } = Typography;

const NewEventRenderer = ({permission = '', ...props}) => {

  const auth = useAuth();

  const CarvalooStatus = props.data.CarvalooStatus
  const NoSensorBox = props.data.SensorBox === null
  const RenderAddButton = (
      CarvalooStatus === null ||
      CarvalooStatus === undefined ||
      CarvalooStatus === 'ToDo' ||
      CarvalooStatus === 'Relevant')

  let buttonBackground = '#d7d7d7'
  let buttonText = 'black'

  if (CarvalooStatus === 'ToDo') {
    buttonBackground = 'red'
    buttonText = 'white'
  }
  if (CarvalooStatus === 'Relevant') {
    buttonBackground = '#088ed7'
    buttonText = 'white'
  }

  const ExistsProd = props.data.production_alert

  const onFinish = (values) => {
    values.event_id = props.data.event_id
    values.editor = (auth.user?.profile.name)

    console.log('Sending request with:', values);
    notification['info']({message: 'Vorfall wird angelegt ...'});
    axiosInstance.post('./api/incidents/create_event', values)
        .then(res => {
          console.log('Success:', res);
          notification['success']({
            message: 'Vorfall ' + values.event_id + ' erfolgreich in der carvaloo WebApp angelegt.',
            duration: 10,
            maxCount: 1
          })
          props.api.refreshServerSide();
        })
        .catch(error => {
          console.log(error);
          notification['error']({message: 'Error', description: error.message});
          props.api.refreshServerSide();
        })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  if (NoSensorBox) {
    return (
      <Button
          style={{ backgroundColor: 'lightgray', color: 'red'}}
          disabled={true}
          title="Fahrzeug ist nicht mit CarValoo SensorBox ausgestattet"
      >
        Add
      </Button>
    )
  }
  else if (RenderAddButton) {
    return (
      <Popover
        trigger="click"
        placement="right"
        content={
          // <Card style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <Form
                name="basic"
                labelCol={{ span: 5}}
                wrapperCol={{ span: 48}}
                // style={{ width: 600, height: 500}}
                style={{ width: 600}}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                {/*<Text style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center', display:'block' }}>*/}
                {/*  Vorfall {props.data.event_id}*/}
                {/*</Text>*/}
                {/*<Divider />*/}
                <Form.Item
                  label="Datum"
                  name="observed_form"
                  // initialValue={dateObj.toISOString()}
                  initialValue={props.data.Vorfalldatum}
                  style={{ marginBottom: "4px" }}
                >
                  <Input
                    disabled={true}
                  />
                </Form.Item>

                <Form.Item
                  label="Art"
                  name="observation_type"
                  initialValue={props.data.gemeldetVon}
                  style={{ marginBottom: "4px" }}
                >
                  <Radio.Group>
                    <Radio.Button value="Arbeitsgruppe">Arbeitsgruppe</Radio.Button>
                    <Radio.Button value="Nachfolger">Nachfolger</Radio.Button>
                    <Radio.Button value="Sensorbox">Sensorbox</Radio.Button>
                    <Radio.Button value="Verursacher">Verursacher</Radio.Button>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="Details"
                  name="inspection_result"
                  initialValue={props.data.DetailsText}
                  style={{ marginBottom: "4px" }}
                >
                  <Input.TextArea rows={2}/>
                </Form.Item>

                <Form.Item
                  label="Comment"
                  name="event_info_text"
                  initialValue={props.data.Comment}
                  style={{ marginBottom: "4px" }}
                >
                  <Input.TextArea rows={5}/>
                </Form.Item>

                <Form.Item
                  label="Schadenort"
                  name="event_location"
                  initialValue={props.data.Schadenort}
                  style={{ marginBottom: "4px" }}
                >
                  <Input.TextArea rows={2}/>
                </Form.Item>

                <Form.Item
                  label="Schadenhöhe"
                  name="schadenhoehe"
                  initialValue={props.data.Offerte}
                  style={{ marginBottom: "4px" }}
                >
                  <Input />
                </Form.Item>
                <Divider style={{ marginBottom: "4px",  marginTop: "4px"}}/>
                <Form.Item label="Ticket" style={{ marginBottom: "4px"}}>
                  {(permission === "incidentApp_admin") &&
                    <Form.Item
                      name="ticket_form"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ marginBottom: "4px", display: 'inline-block' }}
                    >
                      <Checkbox></Checkbox>
                    </Form.Item>
                  }
                  {!(permission === "incidentApp_admin") &&
                    <Form.Item
                      name="ticket_form"
                      valuePropName="checked"
                      initialValue={true}
                      style={{ marginBottom: "4px", display: 'inline-block' }}
                    >
                      <Checkbox disabled={true}></Checkbox>
                    </Form.Item>
                  }
                  <Form.Item
                    label="Priorität"
                    name="ticket_priority"
                    valuePropName="checked"
                    initialValue={'Medium'}
                    style={{marginLeft:"10px", marginBottom: "4px", display: 'inline-block' }}
                  >
                    <Select
                      defaultValue={'Medium'}
                      optionFilterProp="children"
                      options={[
                        {
                          label: 'Hoch',
                          value: 'High',
                        },
                        {
                          label: 'Mittel',
                          value: 'Medium',
                        },
                        {
                          label: 'Niedrig',
                          value: 'Low',
                        },
                      ]}
                    />
                  </Form.Item>
                </Form.Item>
                {(ExistsProd) &&
                  <Form.Item
                    name="alert"
                    valuePropName="checked"
                    rules={[{ required: true, message: 'Bitte bestätigen um den Fall anzulegen' }]}
                    initialValue={null}
                  >
                    <Checkbox style={{ color: 'red', marginLeft: "20px"}}>
                      Fall reaktiv anlegen obwohl eventuell passende produktiv Meldungen vorliegen
                    </Checkbox>
                  </Form.Item>
                }
                <Divider style={{ marginBottom: "4px",  marginTop: "4px"}}/>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }} style={{ marginBottom: "4px",  marginTop: "4px"}}>
                  <Button
                      style={{ marginBottom: "4px",  marginTop: "16px"}}
                      type="primary"
                      htmlType="submit"
                  >
                    Vorfall in der carvaloo Webapp anlegen
                  </Button>
                </Form.Item>
              </Form>
            // </Card>
        }
      >
        <Button
            style={{ backgroundColor: buttonBackground, color: buttonText}}
        >
          Add
        </Button>
      </Popover>
    );
  } else {
      let color;

      switch (CarvalooStatus) {
        case 'keine Zuordnung möglich':
          color = '#c9c2c2';
          break;
        case 'in Bearbeitung':
          color = '#FFB400';
          break;
        case 'erfolgreich zugeordnet':
          color = '#088ed7';
          break;
        default:
          color = 'gray';
      }

      const circleStyle = {
        backgroundColor: color,
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        margin: 'auto'
      };

      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <div style={circleStyle} title={CarvalooStatus}></div>
        </div>
      );
    // return <Text>{CarvalooStatus}</Text>;
  }
}


export default NewEventRenderer;