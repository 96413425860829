import React, {useState, useEffect} from 'react';
import {Descriptions, Card, Form, Input, Popover, Button, Table, notification, Modal, Row} from 'antd';
import axiosInstance from "services/axios";
import {MapContainer, TileLayer, Marker, Popup, useMap} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import './DetailsCard.css'
import {useAuth} from "react-oidc-context";

function UpdateMapCentre(props) {
    const map = useMap();
    map.panTo(props.mapCentre);
    return null;
}

const DetailsCard = (props) => {

    const {event_id = null} = props;
    const [data, setData] = useState({});
    const [loading, setLoading] = useState({});
    const [ignore, setIgnore] = useState(null);
    const [showIgnore, setShowIgnore] = useState(false);
    const [ignoreCount, setIgnoreCount] = useState(0);

    const auth = useAuth();
    const profileRoles = (auth?.user?.profile?.resource_access.incident_frontend?.roles) || []

    const columnsNormal = [
        {
            title: 'Reservation',
            dataIndex: 'reservation_id',
            key: 'reservation_id',
        },
        {
            title: 'Typ',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Von',
            dataIndex: 'start',
            key: 'start',
        },
        {
            title: 'Erstzutritt',
            dataIndex: 'actual_start',
            key: 'actual_start',
        },
        {
            title: 'Abmeldung',
            dataIndex: 'actual_end',
            key: 'actual_end',
        },
        {
            title: 'Bis',
            dataIndex: 'end',
            key: 'end',
        },
        {
            title: 'Laufleistung',
            dataIndex: 'total_mileage',
            key: 'total_mileage',
        },
        // {
        //   title: 'Vorfall',
        //   dataIndex: 'Schadenvorfaelle',
        //   key: 'Schadenvorfaelle',
        // }
    ]

    const getRowClassName = (record) => {
        // Condition to check if the age is greater than 30

        if (record.type === 'Service') {
            return 'highlighted-row-service'; // CSS class name for highlighting
        }

        if (record.Schadenvorfaelle === event_id) {
            return 'highlighted-row'; // CSS class name for highlighting
        }
        if (parseInt(record.driver_id, 10) === data?.MelderEPNr) {
            return 'highlighted-row'; // CSS class name for highlighting
        }

        return ''; // Empty string if the condition is not met
    };

    useEffect(() => {
        if (event_id) {
            setLoading(true)
            setData({});
            axiosInstance.get('./api/incidents/' + event_id)
                .then(function (response) {
                    let data_get = response.data.data;
                    console.log(response.data.data)
                    setLoading(false)

                    if (data_get.latitude && data_get.longitude) {
                        data_get.gps = true;
                        data_get.latitude = parseFloat(data_get.latitude).toFixed(5);
                        data_get.longitude = parseFloat(data_get.longitude).toFixed(5);
                    } else {
                        data_get.gps = false;
                    }

                    setData(data_get);
                })
                .catch(function (error) {
                    setLoading(false)
                    console.log(error);
                    setData({});
                });
        } else {
            setData({});
        }
    }, [event_id]);

    const onIgnoreVorfall = (event_id) => {
        setIgnore(event_id)
        setShowIgnore(true)
    }

    const handleCancel = () => {
        setShowIgnore(false)
    };

    const handleRemoveConfirm = () => {
        axiosInstance.post(
            '/api/incidents/ignore',
            {event_id: ignore, customer: props.customer, unignore: data?.ignore})
            .then(res => {
                setShowIgnore(false)
                notification['success']({
                    message: 'Labeled incident as ignore - please refresh page',
                });
                setIgnoreCount(prevCounter => prevCounter + 1)
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error ignoring incident',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    };



    return (
        <>
            <Modal
                title="Ignore Vorfall"
                open={showIgnore}
                onCancel={handleCancel}
                width={350}
                footer={null}
            >
                <Row justify="center" style={{marginBottom: 16}}>
                    <p>Are you sure you want to label the incident {ignore} as "ignore"?</p>
                </Row>
                <Row justify="center">
                    <Button type="primary" onClick={handleRemoveConfirm} style={{marginRight: 8}}>
                        Yes
                    </Button>
                    <Button onClick={handleCancel}>
                        No
                    </Button>
                </Row>
            </Modal>
            <Descriptions
                // title={
                //   <div style={{ textAlign: 'center' }}>
                //     <h3>Details</h3>
                //   </div>
                // }
                style={{}}
                bordered column={1}
                size={'small'}
                contentStyle={{'minWidth': '250px', 'maxWidth': '300px'}}
            >
                <Descriptions.Item label="Vorfall ID"
                                   style={{marginBottom: "0px"}}>{data.event_id || ''}</Descriptions.Item>
                <Descriptions.Item label="Fahrzeug ID">{data.vehicle_id || ''}</Descriptions.Item>
                <Descriptions.Item label="Carvaloo Box">{data.box_id || ''}</Descriptions.Item>
                <Descriptions.Item
                    label="Datum">{data.Vorfalldatum ? data.Vorfalldatum.replace('T', ' - ').substring(0, 21) : ''}</Descriptions.Item>
                <Descriptions.Item label="Gemeldet">{data.gemeldetVon || ''}</Descriptions.Item>
                <Descriptions.Item label="Reservation">
                    {data.reservations && (
                        <Popover
                            title={'Reservationen'}
                            trigger="click"
                            placement="left"
                            content={
                                <Table
                                    size='small'
                                    columns={columnsNormal}
                                    dataSource={data?.reservations}
                                    pagination={false}
                                    rowClassName={getRowClassName}
                                    scroll={{y: 600}}
                                    style={{width: 900}}
                                />
                            }
                        >
                            <Button size={'small'}>Anzeigen</Button>
                        </Popover>
                    )}
                    {/*{data.service_reservations && (*/}
                    {/*    <Popover*/}
                    {/*        title={'Service Reservationen'}*/}
                    {/*        trigger="click"*/}
                    {/*        placement="left"*/}
                    {/*        content={*/}
                    {/*            <Table*/}
                    {/*                size='small'*/}
                    {/*                columns={columnsService}*/}
                    {/*                dataSource={data?.service_reservations}*/}
                    {/*                pagination={false}*/}
                    {/*                // scroll={{ y: 320 }}*/}
                    {/*                // style={{'maxHeight': '400px'}}*/}
                    {/*            />*/}
                    {/*        }*/}
                    {/*    >*/}
                    {/*        <Button size={'small'}>Service</Button>*/}
                    {/*    </Popover>*/}
                    {/*)}*/}
                </Descriptions.Item>
                {/*<Descriptions.Item label="Status">*/}
                {/*    Mobility: {data.VorfallStatus || ''}*/}
                {/*    <br/>*/}
                {/*    Carvaloo: {data.CarvalooStatus || ''}*/}
                {/*    <br/>*/}
                {/*    /!*Matching: {'placeholder'}*!/*/}
                {/*    /!*<br/>*!/*/}
                {/*    Hinzugefügt am: {(data.editor_datetime) || ''}*/}
                {/*    <br/>*/}
                {/*</Descriptions.Item>*/}
                <Descriptions.Item label="Kommentar">
                    <Input.TextArea
                        rows={7}
                        value={data.Comment}
                        disabled={true}
                        style={{color: '#000000'}}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Details">
                    <Input.TextArea
                        rows={2}
                        value={data.DetailsText}
                        disabled={true}
                        style={{color: '#000000'}}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Schadenort">
                    <Input.TextArea
                        rows={2}
                        value={data.Schadenort || ''}
                        disabled={true}
                        style={{color: '#000000', width: '400px', height: 60}}
                    />
                </Descriptions.Item>
                {/*<Descriptions.Item*/}
                {/*  label="Station">{data.Name || ''}*/}
                {/*</Descriptions.Item>*/}
            </Descriptions>
            {profileRoles.includes("carvaloo") && data.ignore === false &&
                <Button style={{marginTop: 10}} onClick={() => onIgnoreVorfall(data.event_id)}>
                    Ignore Vorfall
                </Button>
            }
            {profileRoles.includes("carvaloo") && data.ignore === true &&
                <Button style={{marginTop: 10}} onClick={() => onIgnoreVorfall(data.event_id)}>
                    Unignore Vorfall
                </Button>
            }
            {/*{data.gps && (*/}
            {/*  <Card size={'small'} style={{'marginTop': '10px'}}>*/}
            {/*    <MapContainer*/}
            {/*      center={[data.latitude, data.longitude]}*/}
            {/*      zoom={13}*/}
            {/*      scrollWheelZoom={false}*/}
            {/*      style={{"height": "250px", "minWidth": "300px"}}*/}
            {/*    >*/}
            {/*      <TileLayer*/}
            {/*        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'*/}
            {/*        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"*/}
            {/*      />*/}
            {/*      <Marker position={[data.latitude, data.longitude]}>*/}
            {/*        <Popup>*/}
            {/*          {"Vorfall: " + data.event_id}*/}
            {/*          <br/>*/}
            {/*          {"Datum: " + data.Vorfalldatum}*/}
            {/*        </Popup>*/}
            {/*      </Marker>*/}
            {/*      <UpdateMapCentre mapCentre={[data.latitude, data.longitude]}/>*/}
            {/*    </MapContainer>*/}
            {/*  </Card>*/}
            {/*)}*/}
        </>
    )
        ;
};

export default DetailsCard;