import React, {useEffect, useState} from "react";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {Button, Modal, Typography} from 'antd';
import {useAuth} from "react-oidc-context";

const {Paragraph} = Typography;
export const AuthError = () => {

    const auth = useAuth();
    const [open, setOpen] = useState(false);

    let errorMessageTitle = "Authentication Error";
    let errorMessageBody = "";
    let showErrorModal = false;
    if (auth.error) {
        console.log('Auth Error occured', auth.error, auth.error.message)
        switch (auth.error.message) {
            case "AbortError The operation was aborted":
                showErrorModal = false;
                break;
            case "Token is not active":
                errorMessageTitle = "Token is not active";
                errorMessageBody = "Your access token is not active anymore. You have to sign in again."
                showErrorModal = true;
                break;
            case "Session not active":
                errorMessageTitle = "Your session has expired";
                errorMessageBody = "Your session has expired and cannot be renewed. You have to sign in again."
                showErrorModal = true;
                break;
            case "Failed to fetch":
                errorMessageBody = "Network Error: Failed to fetch token."
                showErrorModal = true;
                break;
            case "NetworkError when attempting to fetch resource":
                errorMessageBody = "Network dError: Failed to fetch token."
                showErrorModal = true;
                break;
            default:
                errorMessageBody = auth.error.message
        }
    } else {
        showErrorModal = false;
        errorMessageBody = "";
    }

    useEffect(() => {
        if (auth.error && showErrorModal) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [auth.error]);

    React.useEffect(() => {
        auth.events.addSilentRenewError((error) => {
            console.log("Error occured during silent renew", error)

            // check for the hopeless
            if (error.name === "ErrorResponse") {
                // application must take care of error. Wont retry
                console.log('Error', error);
                return;
            }

            // Schedule retry in 5 seconds
            setTimeout(async () => {
                const user = auth.user;
                if (user && (!user.expired || user.refresh_token)) {
                    console.log('Restart silent renew')
                    auth.stopSilentRenew();
                    auth.startSilentRenew();
                } else {
                    console.log('Silent Renew not restarted due to missing/expired user')
                }
            }, 5 * 1000);
        })

    }, [auth.events, auth.signinSilent]);

    const handleSignOut = () => {
        setOpen(false);
        console.log('Sign user out');
        auth.signoutRedirect();
    };
    const handleSignInAgain = () => {
        setOpen(false);
        auth.removeUser().then(() => {
            console.log('Redirect user to signin')
            auth.signinRedirect();
        })
    };
    const handleIgnore = () => {
        // try to do a silent refresh once. If it fails redirect to login screen
        console.log('Handle refresh')
        auth.signinSilent().catch((error) => {
            console.log('Error occured', error)
            // signinRedirectCustom(auth, searchParams);
        }).then((user) => {
            if (user) {
                console.log('signin succeded', user)
            } else {
                console.log('Error occured', user)
                // signinRedirectCustom(auth, searchParams);
            }
            setOpen(false);
        })
    };


    return (
        <Modal
            title={<><ExclamationCircleOutlined style={{color: "#1677ff"}}/> <span
                style={{color: "#1677ff"}}>{errorMessageTitle}</span></>}
            open={open}
            closable={false}
            footer={[
                <Button key="signout" type="primary" onClick={handleSignInAgain}>
                    Sign in again
                </Button>,
                <Button key="refresh" onClick={handleIgnore}>
                    Ignore
                </Button>,
            ]}
        >
            <Paragraph>{errorMessageBody}</Paragraph>
        </Modal>
    );
}

