import React, {useEffect, useState} from 'react';
import {Column} from '@ant-design/charts';
import {Typography} from "antd";

const {Paragraph, Text} = Typography;
const {Title} = Typography;

const CountChart = (props) => {
    const [config, setConfig] = useState(null);

    const handleClick = (params) => {
        if (props.handleDriverClick) {
            props.handleDriverClick(params)
        }
    };

    useEffect(() => {

        const seriesColors = {
            reservation: 'rgba(135,135,135, 1)', // Replace with your desired color for 'aggressive' series
            trip: 'rgba(213,213,213, 1)', // Replace with your desired color for 'normal' series
        };

        const seriesFieldValues = ['reservation', 'trip'];
        const colorMapping = {};
        seriesFieldValues.forEach((type) => {
            colorMapping[type] = seriesColors[type];
            colorMapping[`${type}_no_click`] = `${seriesColors[type].replace('1)', '0.2)')}`;
        });

        if (props.data) {
            setConfig({
                    data: props.data,
                    isGroup: true,
                    xField: 'driver',
                    yField: 'value',
                    yAxis: {
                        visible: props.hideAxisY !== true,
                        max: props.maxValueY
                    },
                    columnStyle: {
                        radius: 3, // Adjust the values to set the radius for each corner individually (top-left, top-right, bottom-right, bottom-left)
                    },
                    xAxis: {
                        label: {
                            formatter: (text) => {
                                if (text === 'average') {
                                    return 'average driver'
                                } else {
                                    return text.includes('-') ? text.split('-')[0] : text;
                                }
                            },
                        },
                    },
                    seriesField: 'type', // This is used to stack the bars
                    legend: false, // You can adjust the legend position,
                    color: (datum) => colorMapping[datum.type],
                    onReady: (plot) => {
                        plot.on('element:click', handleClick)
                    }
                }
            );
        }
    }, [props.data]);

    return (<>
        <div style={{height: 150, width: '100%', marginTop: 55}}>
            <Title level={5} align="center"
                   style={{marginTop: 0, color: props.titleColor ? props.titleColor : 'black'}}>
                {props.title ? props.title : `Total reservation and trip counts`}
            </Title>
            {config &&
                <Column
                    {...config}
                />
            }
        </div>
    </>)
};

export default CountChart;