import axiosInstance from "./axios";

const handleDownload = async (filename) => {
        try {

            const payload = {
                filename: filename
            }

            // Send a POST request to the backend to initiate the download
            const response = await axiosInstance.post('/api/download', payload, {
                responseType: 'blob'
            });

            // Create a temporary URL for the Blob
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary anchor element
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = filename; // Set the desired filename

            // Programmatically click the anchor to trigger the download
            anchor.click();

            // Clean up by revoking the URL
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

export default handleDownload