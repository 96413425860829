import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {TinyColumn} from '@ant-design/plots';
import {Typography, Row} from "antd";

const {Title, Text} = Typography;

const TinyChart = (props) => {

    const [config, setConfig] = useState(null);

    const data = [274, 337, 81, 497, 666, 219, 269, 274, 337, 81, 497, 666, 219, 269];

    useEffect(() => {
        setConfig({
            data,
            color: '#83C6EA', // Set your desired color here
            tooltip: {
                customContent: function (x, data) {
                    return `Week ${x}: ${data[0]?.data?.y.toFixed(2)}`;
                },
            },
        })
    }, []);

    return (<>
        <div style={{height: 40, width: '100%', marginTop: 0}}>
            {config &&
                <TinyColumn
                    {...config}
                />
            }
            <Row>
                <Text style={{fontSize: 11, marginLeft: 6}}>
                    Quarterly
                </Text>
                <div
                    style={{
                        marginLeft: 10,
                        marginTop: 7,
                        width: 0,
                        height: 0,
                        borderLeft: '4px solid transparent',
                        borderRight: '4px solid transparent',
                        borderTopWidth: '6px',
                        borderTopStyle: 'solid',
                        borderTopColor: true ? 'green' : 'red',
                    }}></div>
                <Text style={{fontSize: 11, marginLeft: 6, color: "green"}}>
                    10.5%
                </Text>
            </Row>
        </div>
    </>)


};

export default TinyChart;