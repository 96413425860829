import {Breadcrumb} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import React from "react";

const TabContent = (props) => {

    return (<>
        <Breadcrumb
            style={{marginBottom: 16}}
            items={[
                {
                    href: '',
                    title: <HomeOutlined/>,
                }
            ]}
        />
        {props.content}
    </>)
};

export default TabContent;
