import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';

import {
    Button,
    Card,
    DatePicker, notification, Typography, Tooltip, Row, Col, Switch
} from 'antd';
import dayjs, {Dayjs} from "dayjs";

import axiosInstance from "services/axios";
import SimpleMapComponent from "../../components/SimpleMapComponent/SimpleMapComponent";
import {customGoogleMapsStyle} from "../../assets/styles/google-maps-style";
import stationIcon from '../../assets/icons/station_3.svg';
import carIcon from '../../assets/icons/car_black.svg';
import geoFencingIcon from '../../assets/icons/geofencing.svg';
import reservationIcon from '../../assets/icons/reservation.svg';
import theftIcon from '../../assets/icons/theft.svg';
import noneIcon from '../../assets/icons/none.svg';
import suspiciousIcon from '../../assets/icons/suspicious.svg';

import {formatArrayOfObjects} from "./TableHelpers";
import MapMarker from "./MapMarker";
import {InfoCircleOutlined} from '@ant-design/icons';
import {SettingsContext} from "../../App.js";

const {RangePicker} = DatePicker;
const {Paragraph, Text} = Typography;

const stationIconObj = {
    url: stationIcon,
    anchorHeight: 12,
    anchorWidth: 12,
    scaledWidth: 24,
    scaledHeight: 24,
    colorAdjust: false
}

const carIconObj = {
    url: carIcon,
    anchorHeight: 12,
    anchorWidth: 12,
    scaledWidth: 24,
    scaledHeight: 24,
    colorAdjust: false
}

const circleString = `<svg width="800px" height="800px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="iconify iconify--emojione" preserveAspectRatio="xMidYMid meet"><circle cx="32" cy="32" r="30" opacity="0.25" fill="#0071bc"></circle></svg>`;
const circleIconObj = {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(circleString)}`,
    anchorHeight: 5,
    anchorWidth: 5,
    scaledWidth: 10,
    scaledHeight: 10,
    colorAdjust: true,
};

const iconDictionary = {
    geofencing: geoFencingIcon,
    reservation: reservationIcon,
    theft: theftIcon,
    suspicious: suspiciousIcon,
    none: noneIcon
};


const MapConfigCard = (props) => {

    const [forceUpdate, setForceUpdate] = useState(0)
    const [responseData, setResponseData] = useState(null);
    const [mapData, setMapData] = useState(null);
    const [mapDataSecondary, setMapDataSecondary] = useState(null);
    const [mapDataTracking, setMapDataTracking] = useState(null);
    const [dynamicPolygons, setDynamicPolygons] = useState(null);
    const [isPOIActive, setPOIActive] = useState(false);

    const [markerArraySecondary, setMarkerArraySecondary] = useState([stationIconObj]);

    const {mapCenter} = useContext(SettingsContext);

    useEffect(() => {
        // let clonedGeoFencePolygon = Object.assign({}, props.geoFencePolygon);

        // if ('London' in clonedGeoFencePolygon) {
        //     delete clonedGeoFencePolygon.London;
        // }

        let clonedGeoFencePolygon = []

        for (const [key, value] of Object.entries(props.geoFencePolygon)) {
            for (const element of value) {
                clonedGeoFencePolygon.push(element)
            }
        }

        setDynamicPolygons(Object.values(clonedGeoFencePolygon))
    }, [props.geoFencePolygon]);

    const InfoTooltip = ({text}) => {
        return (
            <Tooltip title={text}>
                <InfoCircleOutlined style={{color: '#1890ff', fontSize: '16px', marginLeft: '5px'}}/>
            </Tooltip>
        );
    };

    const handleTogglePOI = () => {
        setPOIActive(!isPOIActive);
    };

    return (<>
            <Card
                style={{
                    width: '100%',
                    height: 770,
                    flex: '1',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #e8e8e8',
                    marginRight: '24px',
                    marginLeft: 4
                }}
                styles={{ body: {
                    paddingRight: 24,
                    paddingLeft: 24,
                    paddingTop: 10,
                    paddingBottom: 10,
                }}}
                title={
                    <>
                        Geofencing
                        <InfoTooltip
                            text="Overview of the currently configured geofences"
                        />
                    </>
                }
                extra={
                    <Switch
                        size={'medium'}
                        onChange={handleTogglePOI}
                        checkedChildren="POIs"
                        unCheckedChildren="POIs"
                    />
                }
            >
                {dynamicPolygons &&
                    <SimpleMapComponent
                        mapHeight={690}
                        customStyle={customGoogleMapsStyle}
                        center={mapCenter}
                        dynamicPolygons={dynamicPolygons}
                        isPOIActive={isPOIActive}
                        hoverDynamicPolygons={true}
                    />
                }
            </Card>
        </>
    );
}

export default MapConfigCard;