import React, {useState, useRef, useCallback, useEffect} from 'react';
import ReactJson from "react-json-view";
import {
    Button,
    Card,
    Descriptions,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Popover,
    Switch,
    Table,
    Divider, Statistic, DatePicker, Tooltip
} from 'antd';

import Icon, {InfoCircleOutlined} from "@ant-design/icons";
import axiosInstance from "services/axios";
import DashboardCard from "./DashboardCard";

import stationIcon from '../../assets/icons/station_3.svg';
import geoFencingIcon from '../../assets/icons/geofencing.svg';
import reservationIcon from '../../assets/icons/reservation.svg';
import theftIcon from "../../assets/icons/theft.svg";
import suspiciousIcon from "../../assets/icons/suspicious.svg";
import dayjs from "dayjs";

const {Search} = Input;
const {Paragraph, Text} = Typography;
const {RangePicker} = DatePicker;
const {Option} = Select; // Destructure Option from Select

const Dashboard = (props) => {

    const [data, setData] = useState(null);
    const [fleetOptions, setFleetOptions] = useState(null);
    const [defaultFleet, setDefaultFleet] = useState(null);

    const onFilterSearch = (search) => {
        if (search === undefined || search === null) {
            props.setSearchFilter(null)
        } else {
            props.setSearchFilter(search)
        }
        props.handleSelectedChange(null)
    }

    const onFilterDate = (dateRange) => {
        if (dateRange === null || dateRange === undefined) {
            props.setDateRangeFilter([null, null])
        } else if (dateRange[0] === null || dateRange[1] === null) {
        } else {
            props.setDateRangeFilter(dateRange)
        }
        props.handleSelectedChange(null)
    }

    useEffect(() => {
        if (props.fleets) {
            if (props.fleetFilter) {
                setFleetOptions(['all', ...props.fleets]);
                setDefaultFleet(props.fleetFilter)
            } else if (props.fleets.length > 1 || props.fleets.length === 0) {
                setFleetOptions(['all', ...props.fleets]);
                setDefaultFleet('all')
            } else {
                setDefaultFleet(props.fleets[0])
                setFleetOptions(props.fleets);
            }
        }

    }, [props.fleets, props.fleetFilter]);

    useEffect(() => {
        const payload = {
            customer: props.customer,
            fleet: props.fleetFilter,
            date_range: props.dateRangeFilter,
            search: props.searchFilter,
            action: props.actionFilter,
            matching: props.matchingFilter,
        };

        const fetchData = async () => {
            try {
                const response = await axiosInstance.post('/api/geofunctions/statistics', payload);
                setData(response.data)
            } catch (error) {
                console.log(error);
                notification['error']({message: 'Error retrieving alerts', description: error.message});
            }
        };

        if (props.dateRangeFilter) {
            fetchData();
        }

    }, [props.update, props.fleetFilter, props.customer, props.dateRangeFilter, props.actionFilter, props.searchFilter, props.matchingFilter]);

    const handleSelectChange = (value) => {
        if (value === 'all') {
            props.setActionFilter(['firing', 'pending', 'resolved'])
        } else if (value === 'active') {
            props.setActionFilter(['firing', 'pending'])
        } else {
            props.setActionFilter([value])
        }
        props.handleSelectedChange(null)
    };

    const handleSelectMatchingChange = (value) => {
        if (value === 'all') {
            props.setMatchingFilter(null)
        } else if (value === 'confirmed') {
            props.setMatchingFilter(true)
        } else if (value === 'dismissed') {
            props.setMatchingFilter(false)
        }
    };

    const handleSelectFleetChange = (value) => {
        props.setFleetFilter(value)
        props.handleSelectedChange(null)
    };

    const rangePresets = [
        {label: 'Last 1 day', value: [dayjs().add(-1, 'd'), dayjs()]},
        {label: 'Last 2 days', value: [dayjs().add(-2, 'd'), dayjs()]},
        {label: 'Last 7 days', value: [dayjs().add(-7, 'd'), dayjs()]},
        {label: 'Last 14 days', value: [dayjs().add(-14, 'd'), dayjs()]},
        {label: 'Last 30 days', value: [dayjs().add(-30, 'd'), dayjs()]},
        {label: 'Last 90 days', value: [dayjs().add(-90, 'd'), dayjs()]},
        {label: 'Last 180 days', value: [dayjs().add(-180, 'd'), dayjs()]},
        {label: 'Last 365 days', value: [dayjs().add(-365, 'd'), dayjs()]},
    ];

    const InfoTooltip = ({text}) => {
        return (
            <Tooltip title={text}>
                <InfoCircleOutlined style={{color: '#1890ff', fontSize: '12px', marginLeft: '5px'}}/>
            </Tooltip>
        );
    };

    return (
        <>
            <Row style={{padding: 24}} gutter={[8, 24]} ref={props.refCategory}>
                <Card
                    ref={props.refFilter}
                    style={{
                        width: 400,
                        boxSizing: 'border-box',
                        boxShadow: '0px 2px 4px 0px rgba(0, 35, 11, 0.20)',
                        marginRight: 24
                    }}
                    styles={{
                        body: {
                            paddingRight: 24,
                            paddingLeft: 24,
                            paddingTop: 24,
                            paddingBottom: 24,
                        }
                    }}
                    title={
                        <>
                            <Text>
                                Filter
                            </Text>
                            <InfoTooltip text={'The Global Search filter searches in the following alert attributes: EventID, Category, Situation, Details and Vehicle ID. A common use case would be to search for specific geofences.'}/>
                        </>
                    }
                >
                    <Row style={{marginBottom: 8}}>
                        <Col span={16}>
                            {defaultFleet &&
                                <Select
                                    defaultValue={defaultFleet}
                                    onChange={handleSelectFleetChange}
                                    style={{width: 220, marginBottom: 8}}
                                >
                                    {fleetOptions.map(option => (
                                        <Option key={option} value={option}>
                                            {option === 'all' ? 'All Fleets' : option.charAt(0).toUpperCase() + option.slice(1)}
                                        </Option>
                                    ))}
                                </Select>
                            }
                            <RangePicker
                                // picker="month"
                                // open={isOpen}
                                // onOpenChange={(open) => setIsOpen(open)}
                                // onSelect={props.handleSelect}
                                value={props.dateRangeFilter}
                                presets={rangePresets}
                                allowClear={false}
                                onCalendarChange={onFilterDate}
                                style={{
                                    width: 220,
                                    height: 32,
                                }}
                            />
                        </Col>
                        <Col>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                <Select
                                    // defaultValue="all"
                                    onChange={handleSelectChange}
                                    style={{width: 106, marginLeft: 10}}
                                    placeholder={'Status'}
                                >
                                    <Option value="all">All</Option>
                                    <Option value="active">Active</Option>
                                    <Option value="pending">Pending</Option>
                                    <Option value="firing">Firing</Option>
                                    <Option value="resolved">Resolved</Option>
                                </Select>
                                <Select
                                    // defaultValue="all"
                                    onChange={handleSelectMatchingChange}
                                    style={{width: 106, marginLeft: 10}}
                                    placeholder={'Feedback'}
                                >
                                    <Option value="all">All</Option>
                                    <Option value="confirmed">Confirmed</Option>
                                    <Option value="dismissed">Dismissed</Option>
                                </Select>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Search
                            placeholder="Global Search"
                            onSearch={onFilterSearch}
                            allowClear={true}
                            size={'medium'}
                            style={{width: 350}}
                        />
                    </Row>

                </Card>
                {(props.activeFleetsecurity) && (data?.geofencing) &&
                    <DashboardCard
                        card={'geofencing'}
                        icon={geoFencingIcon} title={'Geofencing'}
                        tooltip={'Alerts when exiting the configured zone or entering a forbidden zone. Vehicle tracking data will be shown for the duration of the alert. Notifications can be activated individually for each configured Geofence. Please check the configuration tab for more details.'}
                        active={data.geofencing.active} total={data.geofencing.total} trend={data.geofencing.trend}
                        handleCardClick={props.handleCardClick} issueFilter={props.issueFilter}
                    />}
                {(props.activeFleetsecurity) && (data?.reservation) &&
                    <DashboardCard
                        card={'reservation'}
                        icon={reservationIcon} title={'Reservation'}
                        tooltip={'Alerts when the reservation is overdrawn.'}
                        active={data.reservation.active} total={data.reservation.total}
                        trend={data.reservation.trend}
                        handleCardClick={props.handleCardClick} issueFilter={props.issueFilter}
                    />}
                {(props.activeFleetsecurity) && (data?.theft) &&
                    <DashboardCard
                        card={'theft'}
                        icon={theftIcon} title={'Theft'}
                        tooltip={'Alerts for activity outside a reservation (e.g. car movement, or rim/tire theft). Vehicle tracking data will be shown for the duration of the alert. Notifications for alerts in this category are automatically activated.'}
                        active={data.theft.active} total={data.theft.total} trend={data.theft.trend}
                        handleCardClick={props.handleCardClick} issueFilter={props.issueFilter}
                    />}
                {(props.activeFleetsecurity) && (data?.suspicious) &&
                    <DashboardCard
                        card={'suspicious'}
                        icon={suspiciousIcon} title={'Suspicious Activity'}
                        tooltip={'Alerts for suspicious activity (e.g. vandalism). Vehicle tracking data will be shown for the duration of the alert. '}
                        active={data.suspicious.active} total={data.suspicious.total} trend={data.suspicious.trend}
                        handleCardClick={props.handleCardClick} issueFilter={props.issueFilter}
                    />}
            </Row>
        </>
    );
}


export default Dashboard;
