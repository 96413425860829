import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';

import {
    Button,
    Card,
    DatePicker, notification, Typography, Tooltip, Row, Col, Modal, Spin
} from 'antd';
import dayjs, {Dayjs} from "dayjs";

import axiosInstance from "services/axios";
import SimpleMapComponent from "../../components/SimpleMapComponent/SimpleMapComponent";
import {customGoogleMapsStyle} from "../../assets/styles/google-maps-style";
import stationIcon from '../../assets/icons/station_3.svg';
import carIcon from '../../assets/icons/car_black.svg';
import geoFencingIcon from '../../assets/icons/geofencing.svg';
import reservationIcon from '../../assets/icons/reservation.svg';
import theftIcon from '../../assets/icons/theft.svg';
import noneIcon from '../../assets/icons/none.svg';
import suspiciousIcon from '../../assets/icons/suspicious.svg';

import {formatArrayOfObjects} from "./TableHelpers";
import MapMarker from "./MapMarker";
import CarMarker from "./CarMarker";
import {InfoCircleOutlined} from '@ant-design/icons';
import {SettingsContext} from "../../App.js";
import {timezoneDateTimeString, timezoneTimeString} from '../../utility/conversions.js';

const {RangePicker} = DatePicker;
const {Paragraph, Text} = Typography;

const stationIconObj = {
    url: stationIcon,
    anchorHeight: 12,
    anchorWidth: 12,
    scaledWidth: 24,
    scaledHeight: 24,
    colorAdjust: false
}

const carIconObj = {
    url: carIcon,
    anchorHeight: 12,
    anchorWidth: 12,
    scaledWidth: 24,
    scaledHeight: 24,
    colorAdjust: false
}

const circleString = `<svg width="800px" height="800px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="iconify iconify--emojione" preserveAspectRatio="xMidYMid meet"><circle cx="32" cy="32" r="30" opacity="0.25" fill="#0071bc"></circle></svg>`;
const circleIconObj = {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(circleString)}`,
    anchorHeight: 5,
    anchorWidth: 5,
    scaledWidth: 10,
    scaledHeight: 10,
    colorAdjust: true,
};

const iconDictionary = {
    geofencing: geoFencingIcon,
    reservation: reservationIcon,
    theft: theftIcon,
    suspicious: suspiciousIcon,
    none: noneIcon
};


const MapCard = (props) => {

    const [initialRun, setInitialRun] = useState(true);
    const [forceUpdate, setForceUpdate] = useState(0)
    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(null);
    const [mapData, setMapData] = useState(null);
    const [alertData, setAlertData] = useState(null);
    const [mapDataSecondary, setMapDataSecondary] = useState(null);
    const [mapDataTracking, setMapDataTracking] = useState(null);
    const [dynamicPolygons, setDynamicPolygons] = useState([]);
    const [lastUpdate, setLastUpdate] = useState(null);

    const [markerArraySecondary, setMarkerArraySecondary] = useState([stationIconObj]);

    const {mapCenter, timezone} = useContext(SettingsContext);

    useEffect(() => {
        const payload = {
            customer: props.customer,
            date_range: props.dateRangeFilter,
            search: props.searchFilter,
            fleet: props.fleetFilter,
            matching: props.matchingFilter,
        };

        const fetchData = async () => {
            try {
                if (initialRun) {
                    setLoading(true)
                }
                const response = await axiosInstance.post('/api/geofunctions/alerts/map', payload);
                setLoading(false)
                const currentDate = new Date()
                setLastUpdate(currentDate.toISOString())
                if (response.data.markers !== mapData) {
                    setResponseData(response.data.markers)
                }
            } catch (error) {
                setLoading(false)
                console.log(error);
                notification['error']({message: 'Error retrieving alerts', description: error.message});
            }

            if (initialRun) {
                setInitialRun(false)
            }

        };

        if (props.selectedAlert === null && props.dateRangeFilter) {
            setMapData(null)
            fetchData();
        }

    }, [props.update, forceUpdate, props.dateRangeFilter, props.searchFilter, props.fleetFilter, props.matchingFilter]);

    useEffect(() => {
        const payload = {
            customer: props.customer,
            alert_id: props.selectedAlert,
        };

        const fetchData = async () => {
            try {
                const response = await axiosInstance.post('/api/geofunctions/alert/location', payload);

                const currentDate = new Date()
                setLastUpdate(currentDate.toISOString())
                setAlertData(response.data.alert)

                if (response.data.alert.alert_status === 'firing' || response.data.alert.alert_status === 'pending') {
                    if (response.data.current) {
                        // const currentLocation = {
                        //     location: response.data.positions.slice(-1)[0].location
                        // }
                        setMarkerArraySecondary([stationIconObj, carIconObj])
                        setMapDataSecondary([response.data.secondary_markers[0], response.data.current])
                    } else {
                        // setMarkerArraySecondary([stationIconObj])
                        setMapDataSecondary([response.data.secondary_markers[0]])
                        notification['warning']({message: 'No last current position available'});
                    }

                } else {
                    setMapDataSecondary(response.data.secondary_markers)
                    setMarkerArraySecondary([stationIconObj])
                }

                setMapDataTracking(response.data.positions)
                if (mapData) {
                    setMapData([...mapData, ...response.data.alert_position]);
                } else {
                    setMapData(response.data.alert_position)
                }

            } catch (error) {
                console.log(error);
                notification['error']({message: 'Error retrieving alert map data', description: error.message});
            }
        };

        if (props.selectedAlert) {
            setMapDataTracking(null)
            setMapDataSecondary(null)
            fetchData();
        }
        if (props.selectedAlert === null) {
            setForceUpdate(forceUpdate + 1)
            setMapDataTracking(null)
            setMapDataSecondary(null)
        }

    }, [props.selectedAlert, props.geoFencePolygon]);

    useEffect(() => {
        if (alertData && alertData.misuse_category === 'geofencing' && props.geoFencePolygon && props.selectedAlert) {
            const label = alertData.misuse_details.geofence
            if (label in props.geoFencePolygon) {
                setDynamicPolygons(props.geoFencePolygon[label])
            } else {
                setDynamicPolygons(props.geoFencePolygon[Object.keys(props.geoFencePolygon)[0]])
            }

        } else {
            setDynamicPolygons([])
        }
    }, [alertData, props.geoFencePolygon, props.selectedAlert])

    useEffect(() => {
        if (responseData) {
            if (props.issueFilter) {
                const filteredData = responseData.filter((entry) => entry.misuse_category === props.issueFilter);
                setMapData(filteredData)
            } else {
                setMapData(responseData)
            }
        }
    }, [responseData, props.issueFilter])

    const InfoTooltip = ({text}) => {
        return (
            <Tooltip title={text}>
                <InfoCircleOutlined style={{color: '#1890ff', fontSize: '16px', marginLeft: '5px'}}/>
            </Tooltip>
        );
    };

    const onSelectedMarkerChange = (marker) => {
        if (marker) {
            props.handleSelectedChange(marker.UAID);
        } else {
            props.handleSelectedChange(null)
        }
    };

    const handleDeselectClick = () => {
        props.handleSelectedChange(null)
    };

    return (<>
            <Modal
                open={loading}
                closable={false}
                footer={null}
                centered={true}
                mask={true}
                maskClosable={false}
                styles={{body: {backdropFilter: 'blur(3px)'}}}
            >
                <div>
                    <Spin style={{marginRight: 20}} size="large"/>
                    Loading security alerts...
                </div>
            </Modal>
            <div ref={props.refMap}>
                <div style={{position: "absolute", zIndex: 5, top: 10, left: 200}}>
                    <Button size={'large'}>
                        Refreshed: {timezoneTimeString(lastUpdate, timezone)}
                    </Button>
                </div>
                <SimpleMapComponent
                    mapHeight={550}
                    customStyle={customGoogleMapsStyle}
                    center={mapCenter}
                    markers={mapData}
                    markersSecondary={mapDataSecondary}
                    markersTertiary={mapDataTracking}
                    iconDictionary={iconDictionary}
                    markerIconSecondary={markerArraySecondary}
                    markerIconTertiary={circleIconObj}
                    onSelectedMarkerChange={onSelectedMarkerChange}
                    dynamicPolygons={dynamicPolygons}
                    markerInfoSecondary={CarMarker}
                    markerInfoTertiary={MapMarker}
                    selectedMarker={props.selectedAlert}
                    selectedLabel={props.actionFilter}
                    filterButtons={true}
                    boundsSecondary={true}
                    maxZoomLevel={12}
                />
            </div>

        </>
    );
}

export default MapCard;