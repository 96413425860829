import React, {useState, useCallback, useEffect} from "react";
import {Card, Col, notification, Row, Statistic} from 'antd';
import dayjs from 'dayjs';
import axiosInstance from "services/axios";

import ClusterMap from "../components/ClusterMap/ClusterMap";
import ClusterMapModal from "../components/ClusterMap/ClusterMapModal";
import EventTool from "../components/EventTool/EventTool";
import Dashboard from "../components/Dashboard/Dashboard";
import DashboardCarvaloo from "../components/DashboardCarvaloo/DashboardCarvaloo";
import FilterCard from "../components/FilterCard/FilterCard";
import {ErrorBoundary} from "react-error-boundary";

import {
    findDuplicateLocations,
    mutateLocationsOnDuplicate,
} from "../utility/duplicate_locations";

const IncidentScreen = (props) => {
    const [mapLocations, setMapLocations] = useState([]);
    const [mapLocationsLoading, setMapLocationsLoading] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState(null);
    // FILTERING ON MAP
    const [dateRange, setDateRange] = useState([dayjs().add(-30, 'd'), dayjs()]);
    const [fleetRegion, setFleetRegion] = useState(null);
    const [eventStatus, setEventStatus] = useState(null);
    const [resetColumns, setResetColumns] = useState(false);
    const [resetFilters, setResetFilters] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false);
    const [carvalooFilter, setCarvalooFilter] = useState(false);
    const [verursacherFilter, setVerursacherFilter] = useState(false);
    const [relevantFilter, setRelevantFilter] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(null);

    // MODALS
    const [mapModalOpen, setMapModalOpen] = useState(false);
    const [detailCardOpen, setDetailCardOpen] = useState(true);

    const handleDateRangeChange = useCallback(
        (data) => setDateRange(data),
        [setDateRange]
    );

    const handleFleetRegionChange = useCallback((data) => setFleetRegion(data), []);

    const handleEventStatusChange = useCallback((data) => setEventStatus(data), []);

    const handleRefreshTableChange = useCallback((data) => setRefreshTable(data), []);

    const handleResetColumnsChange = useCallback((data) => setResetColumns(data), []);

    const handleResetFilterChange = useCallback((data) => setResetFilters(data), []);

    const handleCarvalooFilterChange = useCallback((data) => setCarvalooFilter(data), []);

    const handleVerursacherFilterChange = useCallback((data) => setVerursacherFilter(data), []);

    const handleRelevantFilterChange = useCallback((data) => setRelevantFilter(data), []);

    const handleMapModalChange = useCallback((data) => setMapModalOpen(!mapModalOpen), []);

    const handleDetailsCardChange = useCallback((data) => setDetailCardOpen(data), []);

    const handleSelectedMarkerChange = useCallback(
        (data) => {
            if (data?.id !== selectedMarker?.id) {
                setSelectedMarker(data);
            }
        },
        [selectedMarker]
    );

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set('organization', props.customerId);
        const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;
        window.history.replaceState({}, document.title, newUrl);
    }, []);

    const getMapClusterDataEvents = (dateRange, mapStateData) => {
        return axiosInstance.post(
            './api/incidents/map/events',
            {
                fleet_region: fleetRegion,
                date: dateRange,
                state: mapStateData
            },
        );
    };

    useEffect(() => {
        setSelectedMarker(null);
        setMapLocationsLoading(true);
        getMapClusterDataEvents(
            dateRange ? dateRange.map((item) => item.toDate()) : null,
            eventStatus
        )
            .then(({data}) => {
                if (!data.data) return;
                const points = data.data.map((location) => ({
                    type: "Feature",
                    properties: {
                        ...location.location,
                        cluster: false,
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [
                            parseFloat(location.location.longitude),
                            parseFloat(location.location.latitude),
                        ],
                    },
                }));
                // const duplicateLocs = findDuplicateLocations(points);
                // mutateLocationsOnDuplicate(duplicateLocs, points);
                setMapLocations(points);
            })
            .finally((_) => setMapLocationsLoading(false));
    }, [eventStatus, dateRange, fleetRegion]);

    return (
        <>
            <div style={{minWidth: 1080, padding: 8}}>
                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                    <ClusterMapModal
                        showModal={() => setMapModalOpen(true)}
                        hideModal={() => setMapModalOpen(false)}
                        open={mapModalOpen}
                        mapHeight={'700px'}
                        mapLocations={mapLocations}
                        mapLocationsLoading={mapLocationsLoading}
                        selectedMarker={selectedMarker}
                        handleSelectedMarkerChange={handleSelectedMarkerChange}
                        offsetLeft={'0px'}
                        offsetTop={'0px'}
                    />
                </ErrorBoundary>
                <Row gutter={[16, 16]}>
                    <Col span={17}>
                        <Row>
                            <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                <FilterCard
                                    eventStatus={eventStatus}
                                    dateRange={dateRange}
                                    onDateRangeChange={handleDateRangeChange}
                                    onFleetRegionChange={handleFleetRegionChange}
                                    onEventStatusChange={handleEventStatusChange}
                                    onResetColumnsChange={handleResetColumnsChange}
                                    onResetFilterChange={handleResetFilterChange}
                                    onRefreshTableChange={handleRefreshTableChange}
                                    onMapChange={handleMapModalChange}
                                    onDetailsChange={handleDetailsCardChange}
                                    detailCardOpen={detailCardOpen}
                                />
                            </ErrorBoundary>
                        </Row>
                        <Row>
                            <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                <Dashboard
                                    permissions={props.permissions}
                                    dateRange={dateRange}
                                    fleetRegion={fleetRegion}
                                    selectedEventId={selectedEventId}
                                    onRefreshTableChange={handleRefreshTableChange}
                                />
                            </ErrorBoundary>
                        </Row>
                        <Row>
                            <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                <DashboardCarvaloo
                                    permissions={props.permissions}
                                    dateRange={dateRange}
                                    fleetRegion={fleetRegion}
                                    onCarvalooFilterChange={handleCarvalooFilterChange}
                                    onVerursacherFilterChange={handleVerursacherFilterChange}
                                    onRelevantFilterChange={handleRelevantFilterChange}
                                />
                            </ErrorBoundary>
                        </Row>
                    </Col>
                    <Col span={7}>
                        <ErrorBoundary fallback={<div>Something went wrong</div>}>
                            <ClusterMap
                                mapHeight={'210px'}
                                mapLocations={mapLocations}
                                mapLocationsLoading={mapLocationsLoading}
                                selectedMarker={selectedMarker}
                                handleSelectedMarkerChange={handleSelectedMarkerChange}
                                offsetLeft={'-8px'}
                                offsetTop={'-8px'}
                            />
                        </ErrorBoundary>
                    </Col>
                </Row>
                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                    <EventTool
                        permissions={props.permissions}
                        eventStatus={eventStatus}
                        carvalooFilter={carvalooFilter}
                        verursacherFilter={verursacherFilter}
                        relevantFilter={relevantFilter}
                        dateRange={dateRange}
                        fleetRegion={fleetRegion}
                        resetColumns={resetColumns}
                        resetFilters={resetFilters}
                        refresh={refreshTable}
                        detailCardOpen={detailCardOpen}
                        selectedEventId={selectedEventId}
                        setSelectedEventId={setSelectedEventId}
                    />
                </ErrorBoundary>
            </div>
        </>
    );
};

export default IncidentScreen;
