import React from "react";

import geoFencingIcon from '../../assets/icons/geofencing.svg';
import reservationIcon from '../../assets/icons/reservation.svg';
import theftIcon from "../../assets/icons/theft.svg";
import suspiciousIcon from "../../assets/icons/suspicious.svg";
import {ExclamationCircleFilled, WarningFilled, CheckCircleOutlined, CloseCircleFilled} from "@ant-design/icons";

const iconDictionary = {
    geofencing: geoFencingIcon,
    reservation: reservationIcon,
    theft: theftIcon,
    suspicious: suspiciousIcon
};

export const detailsFormatter = (params) => {
    if (params.value === null || params.value === undefined) {
        return null; // Return null if the date value is null
    }
    return Object.keys(params.value).map(key => `${key} : ${params.value[key]}`).join(";");
};

export const dateTimeFormatter = (params) => {
    if (params.value === null || params.value === undefined) {
        return null; // Return null if the date value is null
    }
    return new Date(params.value).toISOString().substring(0, 10);
    // const date = new Date(params.value);
    // return date.toLocaleString('de-DE');
};

export const customDateComparator = (date1, date2) => {
    const parsedDate1 = new Date(date1);
    const parsedDate2 = new Date(date2);

    if (parsedDate1 < parsedDate2) {
        return -1;
    } else if (parsedDate1 > parsedDate2) {
        return 1;
    } else {
        return 0;
    }
};

export const customDateRenderer = (params) => {
    if (params.value === null || params.value === undefined) {
        return null; // Return null if the date value is null
    }
    const date = new Date(Date.parse(params.value));
    const options = {month: '2-digit', day: 'numeric', hour: '2-digit', minute: '2-digit'};
    return date.toLocaleDateString(undefined, options)
};

export function formatDateToLocal(inputDate) {
    const date = new Date(inputDate);
    const options = {
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    };

    return date.toLocaleString('de-DE', options);
}

export function formatArrayOfObjects(array) {
    return array.map(obj => ({
        ...obj,
        created_datetime_str: formatDateToLocal(obj.created_datetime),
        timestamp_str: formatDateToLocal(obj.timestamp)
    }));
}

export function iconCellRenderer(params) {
    // Get the value from the 'issue' column
    const issue = params.data.misuse_category;

    // Check if the issue is in the iconDictionary
    if (iconDictionary.hasOwnProperty(issue)) {
        // If it exists, render the corresponding SVG icon along with the issue text
        const iconSVG = iconDictionary[issue];

        const imgStyle = {
            marginBottom: '-4px', // Adjust the margin to move the icon down
        };

        return (
            <div>
                <img src={iconSVG} alt={issue} width="16" height="16" style={imgStyle}/>
                &nbsp;&nbsp;{issue}
            </div>
        );
    }

    // If the issue is not found in the dictionary, you can return a default icon or any other content
    return null;
}

export function statusCellRenderer(params) {
    // Get the value from the 'issue' column
    const status = params.data.alert_status;

    // Check if the issue is in the iconDictionary
    if (status === 'pending') {
        return (
            <ExclamationCircleFilled style={{fontSize: 16, color: '#088ED7', marginLeft: 12}}/>
        );
    }

    if (status === 'firing') {
        return (
            <WarningFilled style={{fontSize: 16, color: '#088ED7', marginLeft: 12}}/>
        );
    }

    if (status === 'resolved') {
        return (
            <CheckCircleOutlined style={{fontSize: 16, color: '#088ED7', marginLeft: 12}}/>
        );
    }

    // If the issue is not found in the dictionary, you can return a default icon or any other content
    return null;
}

export function feedbackCellRenderer(params) {
    // Get the value from the 'issue' column
    const feedback = params.data.matching;

    // Check if the issue is in the iconDictionary
    if (feedback === true) {
        return (
            <CheckCircleOutlined style={{fontSize: 16, color: '#088ED7', marginLeft: 18}}/>
        );
    }

    if (feedback === false) {
        return (
            <CloseCircleFilled style={{fontSize: 16, color: '#E55555', marginLeft: 18}}/>
        );
    }

    // If the issue is not found in the dictionary, you can return a default icon or any other content
    return null;
}

export function customTimeRenderer(params) {

    if (params.value === undefined) {
        return null
    }
    if (params.value === null) {
        return null
    }
    const timeParts = params.value.split(':').map(Number);
    let formattedTime = '';

    let hours = timeParts[0];
    const minutes = timeParts[1];

    if (hours >= 24) {
        const days = Math.floor(hours / 24);
        formattedTime += days + 'd ';
        hours %= 24; // Remove days from hours
    }

    if (hours > 0) {
        formattedTime += hours + 'h ';
    }

    if (minutes > 0 || formattedTime === '') {
        formattedTime += minutes + 'm';
    }

    return formattedTime.trim();
}


export const customDeltaComparator = (delta1, delta2) => {
    if (delta1 === null && delta2 === null) {
        return 0; // Both values are null, consider them equal
    } else if (delta1 === null) {
        return 1; // delta1 is null, so it's considered greater than delta2
    } else if (delta2 === null) {
        return -1; // delta2 is null, so it's considered greater than delta1
    } else {
        const timeParts1 = delta1.split(':').map(Number);
        const parsed1 = timeParts1[2] + timeParts1[1] * 60 + timeParts1[0] * 60 * 60;
        const timeParts2 = delta2.split(':').map(Number);
        const parsed2 = timeParts2[2] + timeParts2[1] * 60 + timeParts2[0] * 60 * 60;

        if (parsed1 < parsed2) {
            return -1;
        } else if (parsed1 > parsed2) {
            return 1;
        } else {
            return 0;
        }
    }
};