import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';

import {
    Button,
    Card,
    Row, Typography, Divider
} from 'antd';
import dayjs, {Dayjs} from "dayjs";
import {SettingsContext} from "../../App.js";
import {convertKilometersToMiles, timezoneDateString, timezoneTimeString} from '../../utility/conversions.js';

const {Title, Paragraph, Text, Link} = Typography;


const CarMarker = (props) => {

    const {timezone, distance} = useContext(SettingsContext);
    const timestamp = new Date(props.ts);

    return (
        <div style={{whiteSpace: "pre-line"}}>
            <Row>
                <Text style={{fontWeight: 'bold', marginBottom: "8px"}}>
                    Current Location
                </Text>
            </Row>
            <Row>
                <Text>
                   GPS Date: {timezoneDateString(timestamp, timezone)}
                </Text>
            </Row>
            <Row>
                <Text>
                   GPS Time: {timezoneTimeString(timestamp, timezone)}
                </Text>
            </Row>
        </div>
    );
}

export default CarMarker;