import {Button, Card, Col, Image, notification, Row, Statistic, Typography, Divider} from 'antd';

import React, {useState, useEffect} from 'react';
import axiosInstance from "services/axios";
import {FaSearch} from "react-icons/fa";
import Logo from "../../assets/logos/v_blue_app.png";
import MobilityLogo from "../../assets/images/mobility_red.png";
import Icon, {InboxOutlined, SearchOutlined} from "@ant-design/icons";
import {useAuth} from "react-oidc-context";

const {Text} = Typography;

const DashboardCarvaloo = (props) => {
    const [data, setData] = useState(null);
    const auth = useAuth();
    const profileRoles = (auth?.user?.profile.resource_access.incident_frontend?.roles) || []

    const fleetRegion = props.fleetRegion
    const dateRange = props.dateRange

    const getDashboardValues = () => {
        axiosInstance.post(
            './api/incidents/dashboard_carvaloo',
            {
                fleet_region: fleetRegion,
                date: dateRange,
            })
            .then(res => {
                setData(res.data)
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error in getDashboardValues',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    useEffect(() => {
        getDashboardValues();
    }, [fleetRegion, dateRange]);

    return (<>
            <Card
                style={{
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8',
                    marginBottom: '8px',
                }}
                styles={{body: {padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"}}}
            >
                <Row gutter={16}>
                    <div style={{padding: "0px", width: "60px", marginLeft: "10px", marginTop: "5px"}}>
                        <Image src={Logo} preview={false}/>
                    </div>
                    <Col style={{marginLeft: "25px"}}>
                        <Statistic title="Vorfälle (Box)" value={data?.cases}/>
                    </Col>
                    <Divider style={{marginBottom: "4px", marginTop: "4px", borderLeft: '1px solid black'}}
                             type="vertical"/>
                    <Col>
                        <Statistic title="Verursacher" value={data?.cases_notified_a}/>
                    </Col>
                    <Col>
                        <Statistic title="Nachfolger" value={data?.cases_notified_b}/>
                    </Col>
                    <Col>
                        <Statistic title="Arbeitsgruppe" value={data?.cases_notified_c}/>
                    </Col>
                    <Divider style={{marginBottom: "4px", marginTop: "4px", borderLeft: '1px solid black'}}
                             type="vertical"/>
                    <Col>
                        <Statistic title="Relevant" value={data?.cases_relevant}/>
                    </Col>
                    {/*<Col>*/}
                    {/*  <Statistic title="ToDo" value={data?.cases_todo} />*/}
                    {/*</Col>*/}
                    <Col>
                        <Statistic title="in Bearbeitung" value={data?.cases_pending}/>
                    </Col>
                    <Col>
                        <Statistic title="Zugeordnet" value={data?.cases_matched}/>
                    </Col>
                    {(props.permissions?.incident === "incidentApp_admin") &&
                        <Col style={{marginLeft: 'auto'}}>
                            <Button
                                style={{marginLeft: '10px', marginTop: '16px'}}
                                onClick={props.onRelevantFilterChange}
                                icon={<SearchOutlined/>}
                            >
                                Relevant
                            </Button>
                            <Button
                                style={{marginLeft: '10px', marginTop: '16px'}}
                                onClick={props.onCarvalooFilterChange}
                                icon={<SearchOutlined/>}
                            >
                                Unbekannt
                            </Button>
                            <Button
                                style={{marginLeft: '10px', marginTop: '16px', marginRight: '10px'}}
                                onClick={props.onVerursacherFilterChange}
                                icon={<SearchOutlined/>}
                            >
                                Verursacher
                            </Button>
                        </Col>
                    }
                </Row>
            </Card>
        </>
    );
};

export default DashboardCarvaloo;