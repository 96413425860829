import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "react-oidc-context";
import * as ReactDOM from 'react-dom';

import App from './App';
import './index.css';
import {authConfig} from './authConfig';

const container = document.getElementById('root');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<App/>);
// TODO THIS FIXES FLICKERING ON GOOGLE MAP REACT https://github.com/google-map-react/google-map-react/issues/1117
ReactDOM.render(
    <AuthProvider {...authConfig}><App/></AuthProvider>, container
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
