const switzerlandCoordinates = [
    {
        lng: 8.56886005401617,
        lat: 47.80724716186535
    },
    {
        lng: 8.570187568664608,
        lat: 47.80626296997076
    },
    {
        lng: 8.572360038757267,
        lat: 47.806327819824446
    },
    {
        lng: 8.573014259338493,
        lat: 47.80598831176758
    },
    {
        lng: 8.573239326477108,
        lat: 47.80465316772461
    },
    {
        lng: 8.574493408203182,
        lat: 47.803974151611385
    },
    {
        lng: 8.575489044189453,
        lat: 47.80170822143566
    },
    {
        lng: 8.575192451477108,
        lat: 47.79971694946289
    },
    {
        lng: 8.578072547912598,
        lat: 47.80007934570318
    },
    {
        lng: 8.580849647522086,
        lat: 47.80042648315424
    },
    {
        lng: 8.584738731384334,
        lat: 47.8016738891601
    },
    {
        lng: 8.587642669677678,
        lat: 47.80268096923828
    },
    {
        lng: 8.58966159820551,
        lat: 47.80069351196312
    },
    {
        lng: 8.591552734374943,
        lat: 47.79970550537121
    },
    {
        lng: 8.59273719787592,
        lat: 47.79873657226568
    },
    {
        lng: 8.597934722900334,
        lat: 47.80221939086937
    },
    {
        lng: 8.598560333251953,
        lat: 47.80247879028332
    },
    {
        lng: 8.600819587707463,
        lat: 47.80293273925787
    },
    {
        lng: 8.604598045349178,
        lat: 47.802452087402344
    },
    {
        lng: 8.607424736023063,
        lat: 47.80240631103544
    },
    {
        lng: 8.611399650573844,
        lat: 47.80186843872093
    },
    {
        lng: 8.612594604492188,
        lat: 47.80153274536144
    },
    {
        lng: 8.61386871337885,
        lat: 47.80117797851585
    },
    {
        lng: 8.614347457885856,
        lat: 47.800731658935604
    },
    {
        lng: 8.61571216583252,
        lat: 47.79946136474632
    },
    {
        lng: 8.618583679199332,
        lat: 47.79833602905268
    },
    {
        lng: 8.618828773498649,
        lat: 47.79749298095703
    },
    {
        lng: 8.617880821228027,
        lat: 47.796451568603516
    },
    {
        lng: 8.617794036865234,
        lat: 47.79593658447277
    },
    {
        lng: 8.62038612365717,
        lat: 47.79549026489258
    },
    {
        lng: 8.622044563293457,
        lat: 47.79466247558594
    },
    {
        lng: 8.620417594909611,
        lat: 47.790786743164176
    },
    {
        lng: 8.620401382446232,
        lat: 47.78876495361328
    },
    {
        lng: 8.61833858489996,
        lat: 47.787574768066406
    },
    {
        lng: 8.618038177490291,
        lat: 47.78740310668957
    },
    {
        lng: 8.61563682556158,
        lat: 47.78705596923851
    },
    {
        lng: 8.615459442138786,
        lat: 47.786117553711165
    },
    {
        lng: 8.615367889404354,
        lat: 47.7857017517091
    },
    {
        lng: 8.615306854248047,
        lat: 47.78556442260742
    },
    {
        lng: 8.61525821685791,
        lat: 47.78543090820341
    },
    {
        lng: 8.615046501159782,
        lat: 47.785030364990234
    },
    {
        lng: 8.614998817443961,
        lat: 47.78489685058594
    },
    {
        lng: 8.614950180053654,
        lat: 47.784759521484375
    },
    {
        lng: 8.614886283874455,
        lat: 47.78433609008795
    },
    {
        lng: 8.614871025085563,
        lat: 47.784049987793026
    },
    {
        lng: 8.614881515502987,
        lat: 47.78376770019531
    },
    {
        lng: 8.61493968963623,
        lat: 47.78363418579113
    },
    {
        lng: 8.614972114562988,
        lat: 47.78350067138683
    },
    {
        lng: 8.615101814270133,
        lat: 47.783233642578125
    },
    {
        lng: 8.615173339843864,
        lat: 47.78310775756853
    },
    {
        lng: 8.61536979675293,
        lat: 47.78285980224632
    },
    {
        lng: 8.615566253662166,
        lat: 47.782604217529524
    },
    {
        lng: 8.616180419921875,
        lat: 47.78187561035173
    },
    {
        lng: 8.61686801910406,
        lat: 47.780998229980526
    },
    {
        lng: 8.617188453674316,
        lat: 47.780635833740405
    },
    {
        lng: 8.617534637451286,
        lat: 47.780292510986555
    },
    {
        lng: 8.617685317993278,
        lat: 47.780193328857536
    },
    {
        lng: 8.618077278137264,
        lat: 47.7800407409668
    },
    {
        lng: 8.618254661560059,
        lat: 47.77995300292969
    },
    {
        lng: 8.618377685546932,
        lat: 47.77985763549805
    },
    {
        lng: 8.618435859680176,
        lat: 47.779731750488224
    },
    {
        lng: 8.618467330932674,
        lat: 47.7796058654788
    },
    {
        lng: 8.618477821350098,
        lat: 47.77933502197277
    },
    {
        lng: 8.618461608886719,
        lat: 47.77905654907238
    },
    {
        lng: 8.618783950805721,
        lat: 47.778121948242415
    },
    {
        lng: 8.620009422302246,
        lat: 47.77789306640625
    },
    {
        lng: 8.62150859832775,
        lat: 47.77762603759777
    },
    {
        lng: 8.622414588928336,
        lat: 47.77746200561529
    },
    {
        lng: 8.622931480407772,
        lat: 47.77737045288086
    },
    {
        lng: 8.623332023620605,
        lat: 47.77729797363298
    },
    {
        lng: 8.621782302856445,
        lat: 47.773723602294865
    },
    {
        lng: 8.620963096618652,
        lat: 47.771835327148494
    },
    {
        lng: 8.620184898376465,
        lat: 47.771041870117415
    },
    {
        lng: 8.619297027587834,
        lat: 47.76972198486351
    },
    {
        lng: 8.619370460510368,
        lat: 47.768341064453125
    },
    {
        lng: 8.619414329528809,
        lat: 47.76767349243164
    },
    {
        lng: 8.619682312011776,
        lat: 47.76749801635742
    },
    {
        lng: 8.620708465576229,
        lat: 47.766822814941406
    },
    {
        lng: 8.62138557434082,
        lat: 47.76639938354498
    },
    {
        lng: 8.622091293335018,
        lat: 47.765926361083984
    },
    {
        lng: 8.623312950134334,
        lat: 47.76509475708019
    },
    {
        lng: 8.62443733215332,
        lat: 47.76433181762707
    },
    {
        lng: 8.625061035156364,
        lat: 47.76390838623041
    },
    {
        lng: 8.625989913940543,
        lat: 47.76163101196295
    },
    {
        lng: 8.627678871154785,
        lat: 47.75925827026367
    },
    {
        lng: 8.629607200622615,
        lat: 47.75888442993181
    },
    {
        lng: 8.631608963012809,
        lat: 47.75817871093744
    },
    {
        lng: 8.633822441101074,
        lat: 47.76028823852562
    },
    {
        lng: 8.635896682739258,
        lat: 47.76140594482433
    },
    {
        lng: 8.636831283569393,
        lat: 47.761886596679915
    },
    {
        lng: 8.637544631958121,
        lat: 47.76262283325207
    },
    {
        lng: 8.637912750244197,
        lat: 47.76283645629894
    },
    {
        lng: 8.640634536743164,
        lat: 47.7644233703615
    },
    {
        lng: 8.641663551330623,
        lat: 47.76471328735363
    },
    {
        lng: 8.642349243164062,
        lat: 47.76526260375982
    },
    {
        lng: 8.644536972045898,
        lat: 47.764301300048885
    },
    {
        lng: 8.647521972656307,
        lat: 47.76701354980469
    },
    {
        lng: 8.646021842956543,
        lat: 47.767974853515625
    },
    {
        lng: 8.645296096801815,
        lat: 47.768535614013786
    },
    {
        lng: 8.649370193481559,
        lat: 47.77043533325207
    },
    {
        lng: 8.650002479553223,
        lat: 47.77096176147484
    },
    {
        lng: 8.652971267700309,
        lat: 47.77343368530296
    },
    {
        lng: 8.652208328247184,
        lat: 47.77404022216797
    },
    {
        lng: 8.651208877563533,
        lat: 47.77433013916021
    },
    {
        lng: 8.650540351867733,
        lat: 47.77429580688471
    },
    {
        lng: 8.649885177612305,
        lat: 47.774356842041016
    },
    {
        lng: 8.6492338180542,
        lat: 47.77432250976568
    },
    {
        lng: 8.64809513092041,
        lat: 47.774089813232536
    },
    {
        lng: 8.648197174072322,
        lat: 47.77567291259777
    },
    {
        lng: 8.648427009582576,
        lat: 47.776153564453296
    },
    {
        lng: 8.648797988891715,
        lat: 47.77692794799816
    },
    {
        lng: 8.649100303650016,
        lat: 47.77756118774414
    },
    {
        lng: 8.649397850036678,
        lat: 47.77886962890648
    },
    {
        lng: 8.649582862854004,
        lat: 47.77970504760742
    },
    {
        lng: 8.649934768676871,
        lat: 47.781288146972656
    },
    {
        lng: 8.649690628051815,
        lat: 47.78246688842802
    },
    {
        lng: 8.649374008178768,
        lat: 47.783878326416186
    },
    {
        lng: 8.64935302734375,
        lat: 47.78397369384783
    },
    {
        lng: 8.647169113159293,
        lat: 47.785484313964844
    },
    {
        lng: 8.645339012145996,
        lat: 47.78723526001005
    },
    {
        lng: 8.645520210266113,
        lat: 47.79019165039091
    },
    {
        lng: 8.646493911743278,
        lat: 47.79246139526384
    },
    {
        lng: 8.646567344665584,
        lat: 47.79378128051758
    },
    {
        lng: 8.646634101867676,
        lat: 47.79396057128912
    },
    {
        lng: 8.646920204162598,
        lat: 47.79472732543945
    },
    {
        lng: 8.648762702941895,
        lat: 47.795021057128906
    },
    {
        lng: 8.648755073547363,
        lat: 47.797161102295206
    },
    {
        lng: 8.649398803710938,
        lat: 47.798492431640625
    },
    {
        lng: 8.649945259094295,
        lat: 47.79865264892595
    },
    {
        lng: 8.654255867004395,
        lat: 47.799922943115234
    },
    {
        lng: 8.656974792480412,
        lat: 47.80034637451172
    },
    {
        lng: 8.657488822937012,
        lat: 47.79945755004894
    },
    {
        lng: 8.659191131591797,
        lat: 47.79708480834984
    },
    {
        lng: 8.660260200500545,
        lat: 47.796260833740234
    },
    {
        lng: 8.659997940063533,
        lat: 47.79405212402344
    },
    {
        lng: 8.658467292785645,
        lat: 47.793197631835994
    },
    {
        lng: 8.6574964523316,
        lat: 47.791790008545036
    },
    {
        lng: 8.658972740173397,
        lat: 47.791957855224666
    },
    {
        lng: 8.660611152648983,
        lat: 47.79202270507835
    },
    {
        lng: 8.662065505981502,
        lat: 47.791843414306584
    },
    {
        lng: 8.663168907165584,
        lat: 47.79110336303711
    },
    {
        lng: 8.665226936340389,
        lat: 47.79035186767584
    },
    {
        lng: 8.6653413772583,
        lat: 47.78963851928734
    },
    {
        lng: 8.666905403137264,
        lat: 47.78839492797863
    },
    {
        lng: 8.667566299438477,
        lat: 47.78769302368181
    },
    {
        lng: 8.669960975646916,
        lat: 47.787361145019474
    },
    {
        lng: 8.671134948730469,
        lat: 47.787574768066406
    },
    {
        lng: 8.671683311462402,
        lat: 47.787384033203125
    },
    {
        lng: 8.674231529235897,
        lat: 47.78648757934582
    },
    {
        lng: 8.676127433776855,
        lat: 47.7857017517091
    },
    {
        lng: 8.67702674865734,
        lat: 47.78573226928722
    },
    {
        lng: 8.677518844604435,
        lat: 47.78626632690441
    },
    {
        lng: 8.678326606750488,
        lat: 47.78685379028349
    },
    {
        lng: 8.67951679229742,
        lat: 47.78676605224638
    },
    {
        lng: 8.680493354797477,
        lat: 47.78670120239252
    },
    {
        lng: 8.680893898010254,
        lat: 47.78667068481474
    },
    {
        lng: 8.680071830749625,
        lat: 47.78508377075218
    },
    {
        lng: 8.680710792541504,
        lat: 47.784770965576286
    },
    {
        lng: 8.681702613830623,
        lat: 47.78426742553711
    },
    {
        lng: 8.681557655334586,
        lat: 47.78350067138683
    },
    {
        lng: 8.682464599609432,
        lat: 47.78148269653326
    },
    {
        lng: 8.68292236328125,
        lat: 47.780464172363565
    },
    {
        lng: 8.682442665100154,
        lat: 47.778652191162394
    },
    {
        lng: 8.682776451110897,
        lat: 47.77716445922846
    },
    {
        lng: 8.685135841369686,
        lat: 47.77539443969738
    },
    {
        lng: 8.685580253601131,
        lat: 47.775146484375114
    },
    {
        lng: 8.688021659851131,
        lat: 47.77378082275402
    },
    {
        lng: 8.688253402709961,
        lat: 47.773651123046875
    },
    {
        lng: 8.687052726745605,
        lat: 47.77234268188488
    },
    {
        lng: 8.68686771392828,
        lat: 47.77214050292986
    },
    {
        lng: 8.683483123779354,
        lat: 47.77063369750988
    },
    {
        lng: 8.685871124267521,
        lat: 47.76707077026367
    },
    {
        lng: 8.68611812591547,
        lat: 47.76658630371094
    },
    {
        lng: 8.686346054077148,
        lat: 47.76615142822277
    },
    {
        lng: 8.686863899230957,
        lat: 47.76500701904308
    },
    {
        lng: 8.687068939208984,
        lat: 47.764236450195426
    },
    {
        lng: 8.687351226806754,
        lat: 47.76314926147484
    },
    {
        lng: 8.687575340271053,
        lat: 47.76228332519554
    },
    {
        lng: 8.688406944274846,
        lat: 47.761974334717024
    },
    {
        lng: 8.688414573669377,
        lat: 47.76128387451172
    },
    {
        lng: 8.688222885131836,
        lat: 47.76074600219738
    },
    {
        lng: 8.687939643859977,
        lat: 47.76027679443354
    },
    {
        lng: 8.687924385070858,
        lat: 47.7597389221192
    },
    {
        lng: 8.688200950622672,
        lat: 47.758926391601676
    },
    {
        lng: 8.688611030578613,
        lat: 47.75833511352539
    },
    {
        lng: 8.689438819885254,
        lat: 47.75786209106474
    },
    {
        lng: 8.690094947814998,
        lat: 47.7575302124024
    },
    {
        lng: 8.692511558532772,
        lat: 47.756309509277514
    },
    {
        lng: 8.693971633911246,
        lat: 47.75592803955095
    },
    {
        lng: 8.69509029388422,
        lat: 47.75571060180687
    },
    {
        lng: 8.695512771606559,
        lat: 47.756225585937614
    },
    {
        lng: 8.695812225341797,
        lat: 47.75673294067394
    },
    {
        lng: 8.69659614562994,
        lat: 47.756496429443416
    },
    {
        lng: 8.697192192077694,
        lat: 47.756587982177734
    },
    {
        lng: 8.697604179382381,
        lat: 47.75691604614286
    },
    {
        lng: 8.698059082031307,
        lat: 47.75750732421898
    },
    {
        lng: 8.698889732360954,
        lat: 47.758911132812784
    },
    {
        lng: 8.699052810669002,
        lat: 47.75925827026367
    },
    {
        lng: 8.699092864990234,
        lat: 47.759628295898494
    },
    {
        lng: 8.70037651062023,
        lat: 47.76020812988287
    },
    {
        lng: 8.700840950012264,
        lat: 47.76052474975597
    },
    {
        lng: 8.701320648193303,
        lat: 47.76084136962902
    },
    {
        lng: 8.701495170593262,
        lat: 47.7606315612793
    },
    {
        lng: 8.70181846618658,
        lat: 47.760337829589844
    },
    {
        lng: 8.703411102294865,
        lat: 47.761161804199276
    },
    {
        lng: 8.703922271728572,
        lat: 47.76165771484392
    },
    {
        lng: 8.704824447631836,
        lat: 47.762077331543196
    },
    {
        lng: 8.704726219177303,
        lat: 47.76234817504883
    },
    {
        lng: 8.706849098205623,
        lat: 47.76364517211914
    },
    {
        lng: 8.709758758544922,
        lat: 47.76316833496094
    },
    {
        lng: 8.711870193481502,
        lat: 47.764774322509766
    },
    {
        lng: 8.713254928588867,
        lat: 47.765022277832315
    },
    {
        lng: 8.714159965515194,
        lat: 47.76535797119146
    },
    {
        lng: 8.71663570404047,
        lat: 47.76467514038109
    },
    {
        lng: 8.718537330627498,
        lat: 47.76427841186546
    },
    {
        lng: 8.72089862823492,
        lat: 47.76324081420893
    },
    {
        lng: 8.721847534179688,
        lat: 47.76424026489258
    },
    {
        lng: 8.723546981811523,
        lat: 47.76374816894548
    },
    {
        lng: 8.723271369934025,
        lat: 47.76327514648449
    },
    {
        lng: 8.722953796386662,
        lat: 47.76282119751005
    },
    {
        lng: 8.727146148681697,
        lat: 47.76227569580101
    },
    {
        lng: 8.72873497009283,
        lat: 47.76075744628929
    },
    {
        lng: 8.730642318725643,
        lat: 47.75970458984369
    },
    {
        lng: 8.72910118103033,
        lat: 47.757919311523494
    },
    {
        lng: 8.735245704650879,
        lat: 47.75499343872082
    },
    {
        lng: 8.736553192138786,
        lat: 47.7538414001466
    },
    {
        lng: 8.739765167236385,
        lat: 47.753089904785156
    },
    {
        lng: 8.74010944366455,
        lat: 47.75278854370134
    },
    {
        lng: 8.740309715270996,
        lat: 47.75259017944353
    },
    {
        lng: 8.741048812866211,
        lat: 47.75185012817383
    },
    {
        lng: 8.741010665893668,
        lat: 47.751708984375
    },
    {
        lng: 8.740873336791992,
        lat: 47.751205444336165
    },
    {
        lng: 8.740424156189022,
        lat: 47.7495574951173
    },
    {
        lng: 8.740716934204215,
        lat: 47.74917602539074
    },
    {
        lng: 8.74142074584961,
        lat: 47.74820327758806
    },
    {
        lng: 8.740469932556266,
        lat: 47.74765014648449
    },
    {
        lng: 8.739558219909725,
        lat: 47.746532440185774
    },
    {
        lng: 8.734196662902832,
        lat: 47.74656295776373
    },
    {
        lng: 8.733128547668514,
        lat: 47.746345520019645
    },
    {
        lng: 8.730995178222656,
        lat: 47.74585723876976
    },
    {
        lng: 8.730060577392692,
        lat: 47.74585342407255
    },
    {
        lng: 8.727952003478947,
        lat: 47.74615097045921
    },
    {
        lng: 8.727287292480526,
        lat: 47.74567794799816
    },
    {
        lng: 8.726598739624023,
        lat: 47.74504852294922
    },
    {
        lng: 8.723407745361442,
        lat: 47.745532989502124
    },
    {
        lng: 8.721578598022404,
        lat: 47.743930816650504
    },
    {
        lng: 8.72069168090826,
        lat: 47.74229049682634
    },
    {
        lng: 8.720185279846191,
        lat: 47.74098587036133
    },
    {
        lng: 8.718978881835938,
        lat: 47.740608215332315
    },
    {
        lng: 8.716401100158748,
        lat: 47.739341735840014
    },
    {
        lng: 8.714459419250602,
        lat: 47.73873138427729
    },
    {
        lng: 8.714344024658203,
        lat: 47.73869705200195
    },
    {
        lng: 8.714374542236385,
        lat: 47.738544464111555
    },
    {
        lng: 8.714668273925838,
        lat: 47.73708343505882
    },
    {
        lng: 8.713401794433707,
        lat: 47.73525238037121
    },
    {
        lng: 8.712945938110352,
        lat: 47.734592437744425
    },
    {
        lng: 8.711920738220215,
        lat: 47.73086547851574
    },
    {
        lng: 8.71125698089611,
        lat: 47.73007202148443
    },
    {
        lng: 8.712104797363281,
        lat: 47.72871017456049
    },
    {
        lng: 8.713598251342773,
        lat: 47.727951049804915
    },
    {
        lng: 8.714105606079102,
        lat: 47.72716903686535
    },
    {
        lng: 8.715234756469727,
        lat: 47.725425720214844
    },
    {
        lng: 8.716571807861271,
        lat: 47.72331237792986
    },
    {
        lng: 8.717919349670467,
        lat: 47.7216224670413
    },
    {
        lng: 8.719771385192928,
        lat: 47.721569061279524
    },
    {
        lng: 8.721174240112418,
        lat: 47.7217750549317
    },
    {
        lng: 8.723325729370117,
        lat: 47.72121810913097
    },
    {
        lng: 8.724839210510368,
        lat: 47.721305847168026
    },
    {
        lng: 8.724669456481934,
        lat: 47.720138549804915
    },
    {
        lng: 8.7256240844726,
        lat: 47.72003555297874
    },
    {
        lng: 8.728583335876522,
        lat: 47.71909713745123
    },
    {
        lng: 8.73039627075201,
        lat: 47.719047546387
    },
    {
        lng: 8.73320484161377,
        lat: 47.718364715576286
    },
    {
        lng: 8.73471450805664,
        lat: 47.71749877929699
    },
    {
        lng: 8.735368728637809,
        lat: 47.71694564819336
    },
    {
        lng: 8.735566139221135,
        lat: 47.7167778015139
    },
    {
        lng: 8.736293792724666,
        lat: 47.71615219116211
    },
    {
        lng: 8.734966278076229,
        lat: 47.71528625488281
    },
    {
        lng: 8.733349800109977,
        lat: 47.71325683593773
    },
    {
        lng: 8.73332881927496,
        lat: 47.713230133056925
    },
    {
        lng: 8.732760429382267,
        lat: 47.712516784668026
    },
    {
        lng: 8.731919288635368,
        lat: 47.71146011352562
    },
    {
        lng: 8.731649398803825,
        lat: 47.7099685668947
    },
    {
        lng: 8.731584548950138,
        lat: 47.70960998535156
    },
    {
        lng: 8.730783462524528,
        lat: 47.70834350585949
    },
    {
        lng: 8.731148719787711,
        lat: 47.707664489746094
    },
    {
        lng: 8.730689048767147,
        lat: 47.70672988891613
    },
    {
        lng: 8.728780746460075,
        lat: 47.702751159668196
    },
    {
        lng: 8.728685379028434,
        lat: 47.70243453979509
    },
    {
        lng: 8.728465080261287,
        lat: 47.70188903808622
    },
    {
        lng: 8.727762222290153,
        lat: 47.700149536132926
    },
    {
        lng: 8.72642898559576,
        lat: 47.69683837890648
    },
    {
        lng: 8.726361274719238,
        lat: 47.69667053222679
    },
    {
        lng: 8.726717948913574,
        lat: 47.69524765014671
    },
    {
        lng: 8.727637290954647,
        lat: 47.693359375
    },
    {
        lng: 8.727981567382812,
        lat: 47.692680358886776
    },
    {
        lng: 8.732398986816463,
        lat: 47.69275283813499
    },
    {
        lng: 8.733223915100154,
        lat: 47.692756652831974
    },
    {
        lng: 8.7342529296875,
        lat: 47.69265747070335
    },
    {
        lng: 8.7350692749024,
        lat: 47.69253540039091
    },
    {
        lng: 8.735873222351074,
        lat: 47.692405700683594
    },
    {
        lng: 8.7364759445191,
        lat: 47.6922874450683
    },
    {
        lng: 8.737267494201774,
        lat: 47.69211959838867
    },
    {
        lng: 8.738825798034782,
        lat: 47.6917343139649
    },
    {
        lng: 8.73981857299816,
        lat: 47.691532135009766
    },
    {
        lng: 8.740421295165959,
        lat: 47.691425323486555
    },
    {
        lng: 8.74123764038086,
        lat: 47.69129562377958
    },
    {
        lng: 8.742040634155273,
        lat: 47.6911811828615
    },
    {
        lng: 8.742856979370174,
        lat: 47.691070556640625
    },
    {
        lng: 8.743659019470272,
        lat: 47.690975189208984
    },
    {
        lng: 8.744299888610783,
        lat: 47.69092178344755
    },
    {
        lng: 8.745326995849723,
        lat: 47.690868377685604
    },
    {
        lng: 8.745951652526855,
        lat: 47.690876007080135
    },
    {
        lng: 8.74720096588129,
        lat: 47.69095993041992
    },
    {
        lng: 8.747813224792594,
        lat: 47.69095993041992
    },
    {
        lng: 8.748227119445914,
        lat: 47.69093322753929
    },
    {
        lng: 8.748841285705623,
        lat: 47.690868377685604
    },
    {
        lng: 8.750151634216365,
        lat: 47.69071960449219
    },
    {
        lng: 8.752520561218319,
        lat: 47.69046401977562
    },
    {
        lng: 8.75456714630127,
        lat: 47.690082550049055
    },
    {
        lng: 8.755377769470215,
        lat: 47.68990325927729
    },
    {
        lng: 8.755949020385742,
        lat: 47.68978118896507
    },
    {
        lng: 8.757306098938045,
        lat: 47.6894226074221
    },
    {
        lng: 8.758625030517635,
        lat: 47.68901443481474
    },
    {
        lng: 8.763236999511719,
        lat: 47.68741226196312
    },
    {
        lng: 8.766699790954647,
        lat: 47.686130523681754
    },
    {
        lng: 8.767578125000114,
        lat: 47.68575668334972
    },
    {
        lng: 8.768430709838924,
        lat: 47.68534851074219
    },
    {
        lng: 8.769068717956543,
        lat: 47.6850013732913
    },
    {
        lng: 8.769941329956112,
        lat: 47.68440246582048
    },
    {
        lng: 8.770697593689022,
        lat: 47.68373107910162
    },
    {
        lng: 8.77108192443842,
        lat: 47.68338775634794
    },
    {
        lng: 8.771437644958496,
        lat: 47.68303680419916
    },
    {
        lng: 8.772143363952694,
        lat: 47.6823425292971
    },
    {
        lng: 8.772637367248535,
        lat: 47.681911468505916
    },
    {
        lng: 8.773060798644963,
        lat: 47.68159484863304
    },
    {
        lng: 8.773360252380371,
        lat: 47.68140029907238
    },
    {
        lng: 8.773686408996582,
        lat: 47.68122482299799
    },
    {
        lng: 8.774238586425895,
        lat: 47.68102645874018
    },
    {
        lng: 8.774991989135685,
        lat: 47.68078994750988
    },
    {
        lng: 8.776435852050781,
        lat: 47.68023681640625
    },
    {
        lng: 8.777540206909237,
        lat: 47.679847717285156
    },
    {
        lng: 8.778079986572322,
        lat: 47.67964172363298
    },
    {
        lng: 8.778958320617619,
        lat: 47.67926788330078
    },
    {
        lng: 8.780500411987418,
        lat: 47.67854690551752
    },
    {
        lng: 8.783485412597656,
        lat: 47.67729949951183
    },
    {
        lng: 8.784214019775447,
        lat: 47.67700576782238
    },
    {
        lng: 8.784929275512752,
        lat: 47.67673110961937
    },
    {
        lng: 8.7856702804566,
        lat: 47.67646408081055
    },
    {
        lng: 8.78659915924078,
        lat: 47.676155090332145
    },
    {
        lng: 8.78734016418457,
        lat: 47.67590332031256
    },
    {
        lng: 8.788105964660758,
        lat: 47.67567443847679
    },
    {
        lng: 8.788859367370605,
        lat: 47.675453186035156
    },
    {
        lng: 8.78963851928711,
        lat: 47.67523956298828
    },
    {
        lng: 8.790215492248592,
        lat: 47.6751060485841
    },
    {
        lng: 8.791549682617244,
        lat: 47.674900054931925
    },
    {
        lng: 8.792572975158805,
        lat: 47.67485427856451
    },
    {
        lng: 8.793609619140682,
        lat: 47.674919128418196
    },
    {
        lng: 8.794608116150016,
        lat: 47.67505264282232
    },
    {
        lng: 8.795594215393123,
        lat: 47.67523574829107
    },
    {
        lng: 8.795681953430233,
        lat: 47.67525100708019
    },
    {
        lng: 8.795712471008414,
        lat: 47.67525863647472
    },
    {
        lng: 8.79594516754156,
        lat: 47.67530441284174
    },
    {
        lng: 8.795468330383358,
        lat: 47.675994873046875
    },
    {
        lng: 8.795020103454704,
        lat: 47.67665481567383
    },
    {
        lng: 8.793426513671932,
        lat: 47.67927169799816
    },
    {
        lng: 8.793410301208553,
        lat: 47.679309844970646
    },
    {
        lng: 8.793313980102596,
        lat: 47.679916381835994
    },
    {
        lng: 8.795190811157283,
        lat: 47.684162139892805
    },
    {
        lng: 8.796027183532715,
        lat: 47.68579483032221
    },
    {
        lng: 8.796364784240836,
        lat: 47.687198638916016
    },
    {
        lng: 8.796504020690918,
        lat: 47.68777847290062
    },
    {
        lng: 8.79802131652832,
        lat: 47.689727783203125
    },
    {
        lng: 8.798772811889762,
        lat: 47.69038772583008
    },
    {
        lng: 8.79980564117443,
        lat: 47.6911048889163
    },
    {
        lng: 8.79979419708252,
        lat: 47.691436767578125
    },
    {
        lng: 8.800076484680233,
        lat: 47.69172668457037
    },
    {
        lng: 8.802978515625057,
        lat: 47.69203186035173
    },
    {
        lng: 8.803833007812443,
        lat: 47.691772460937784
    },
    {
        lng: 8.804719924926815,
        lat: 47.691596984863395
    },
    {
        lng: 8.805522918701172,
        lat: 47.69154739379883
    },
    {
        lng: 8.80612850189209,
        lat: 47.691604614257926
    },
    {
        lng: 8.806982040405387,
        lat: 47.691867828369425
    },
    {
        lng: 8.808079719543514,
        lat: 47.692218780517635
    },
    {
        lng: 8.809065818786678,
        lat: 47.69274520874046
    },
    {
        lng: 8.810068130493221,
        lat: 47.69332122802746
    },
    {
        lng: 8.80933952331543,
        lat: 47.69401168823242
    },
    {
        lng: 8.80889701843273,
        lat: 47.69461059570324
    },
    {
        lng: 8.808279037475643,
        lat: 47.69517135620117
    },
    {
        lng: 8.807645797729492,
        lat: 47.695659637451286
    },
    {
        lng: 8.806977272033691,
        lat: 47.69613647460949
    },
    {
        lng: 8.806313514709586,
        lat: 47.6964950561524
    },
    {
        lng: 8.804942131042537,
        lat: 47.69641494750982
    },
    {
        lng: 8.803780555725154,
        lat: 47.6964111328125
    },
    {
        lng: 8.80323600769043,
        lat: 47.69643783569347
    },
    {
        lng: 8.802679061889648,
        lat: 47.696464538574446
    },
    {
        lng: 8.800714492797908,
        lat: 47.69674301147484
    },
    {
        lng: 8.798645019531364,
        lat: 47.696994781494254
    },
    {
        lng: 8.797723770141715,
        lat: 47.69714736938499
    },
    {
        lng: 8.79820823669445,
        lat: 47.6979141235351
    },
    {
        lng: 8.798596382141227,
        lat: 47.69893264770536
    },
    {
        lng: 8.798607826232967,
        lat: 47.6996231079101
    },
    {
        lng: 8.798496246337947,
        lat: 47.70046997070335
    },
    {
        lng: 8.798420906066838,
        lat: 47.700942993164176
    },
    {
        lng: 8.798189163208008,
        lat: 47.70154571533203
    },
    {
        lng: 8.797928810119743,
        lat: 47.70194625854498
    },
    {
        lng: 8.797757148742733,
        lat: 47.70232391357422
    },
    {
        lng: 8.797650337219181,
        lat: 47.70286941528343
    },
    {
        lng: 8.796716690063533,
        lat: 47.70286560058588
    },
    {
        lng: 8.796253204345817,
        lat: 47.7030143737793
    },
    {
        lng: 8.795857429504451,
        lat: 47.703254699706974
    },
    {
        lng: 8.79572582244873,
        lat: 47.70351791381847
    },
    {
        lng: 8.795162200927678,
        lat: 47.70350646972656
    },
    {
        lng: 8.7945556640625,
        lat: 47.703712463379134
    },
    {
        lng: 8.794245719909668,
        lat: 47.7040176391601
    },
    {
        lng: 8.79395675659191,
        lat: 47.70422744750982
    },
    {
        lng: 8.793601989746094,
        lat: 47.70430374145502
    },
    {
        lng: 8.793036460876522,
        lat: 47.704341888427905
    },
    {
        lng: 8.792448043823299,
        lat: 47.7045707702639
    },
    {
        lng: 8.79206562042242,
        lat: 47.704910278320426
    },
    {
        lng: 8.791354179382324,
        lat: 47.70511245727539
    },
    {
        lng: 8.790836334228516,
        lat: 47.70530700683605
    },
    {
        lng: 8.789912223815975,
        lat: 47.70502853393583
    },
    {
        lng: 8.78916072845459,
        lat: 47.70486450195335
    },
    {
        lng: 8.788302421569824,
        lat: 47.704719543457145
    },
    {
        lng: 8.787335395813102,
        lat: 47.70465850830101
    },
    {
        lng: 8.786312103271541,
        lat: 47.7047004699707
    },
    {
        lng: 8.78503704071045,
        lat: 47.70490646362322
    },
    {
        lng: 8.784063339233455,
        lat: 47.70504379272472
    },
    {
        lng: 8.782615661621207,
        lat: 47.70512771606445
    },
    {
        lng: 8.781783103942928,
        lat: 47.70527267456055
    },
    {
        lng: 8.780540466308707,
        lat: 47.7055473327639
    },
    {
        lng: 8.779403686523551,
        lat: 47.70581436157238
    },
    {
        lng: 8.778094291687069,
        lat: 47.705974578857536
    },
    {
        lng: 8.777157783508358,
        lat: 47.7060432434082
    },
    {
        lng: 8.775629043579215,
        lat: 47.70592117309593
    },
    {
        lng: 8.774747848510856,
        lat: 47.705944061279354
    },
    {
        lng: 8.773877143859863,
        lat: 47.706157684326286
    },
    {
        lng: 8.773358345031795,
        lat: 47.70637512207054
    },
    {
        lng: 8.772864341735954,
        lat: 47.706413269043026
    },
    {
        lng: 8.772171020507756,
        lat: 47.706592559814396
    },
    {
        lng: 8.771421432495117,
        lat: 47.706867218017805
    },
    {
        lng: 8.770051002502555,
        lat: 47.706748962402344
    },
    {
        lng: 8.769350051879826,
        lat: 47.70687484741234
    },
    {
        lng: 8.769748687744254,
        lat: 47.70783996582031
    },
    {
        lng: 8.769632339477596,
        lat: 47.70916748046869
    },
    {
        lng: 8.771218299865836,
        lat: 47.71004867553711
    },
    {
        lng: 8.771385192871207,
        lat: 47.71213150024414
    },
    {
        lng: 8.771759986877555,
        lat: 47.712413787842024
    },
    {
        lng: 8.772800445556754,
        lat: 47.71319580078125
    },
    {
        lng: 8.771185874938908,
        lat: 47.71448135375999
    },
    {
        lng: 8.77043628692627,
        lat: 47.71507644653343
    },
    {
        lng: 8.770924568176383,
        lat: 47.715999603271484
    },
    {
        lng: 8.770351409912053,
        lat: 47.71636199951183
    },
    {
        lng: 8.770454406738224,
        lat: 47.716934204101676
    },
    {
        lng: 8.770351409912053,
        lat: 47.717361450195256
    },
    {
        lng: 8.770056724548283,
        lat: 47.717723846435774
    },
    {
        lng: 8.769832611083984,
        lat: 47.71809005737305
    },
    {
        lng: 8.769908905029354,
        lat: 47.718448638916186
    },
    {
        lng: 8.770311355590763,
        lat: 47.71902465820324
    },
    {
        lng: 8.771102905273494,
        lat: 47.71869277954124
    },
    {
        lng: 8.772189140319938,
        lat: 47.71823501586914
    },
    {
        lng: 8.774801254272404,
        lat: 47.719142913818644
    },
    {
        lng: 8.775789260864371,
        lat: 47.72011184692394
    },
    {
        lng: 8.776612281799316,
        lat: 47.720767974853516
    },
    {
        lng: 8.777348518371639,
        lat: 47.72088623046898
    },
    {
        lng: 8.776206970214844,
        lat: 47.722293853759766
    },
    {
        lng: 8.776315689086971,
        lat: 47.72271347045921
    },
    {
        lng: 8.778247833251953,
        lat: 47.722385406494425
    },
    {
        lng: 8.779395103454533,
        lat: 47.721843719482365
    },
    {
        lng: 8.78073883056652,
        lat: 47.7216987609865
    },
    {
        lng: 8.782392501831168,
        lat: 47.72332000732439
    },
    {
        lng: 8.782577514648494,
        lat: 47.724071502685604
    },
    {
        lng: 8.78341197967535,
        lat: 47.72440719604515
    },
    {
        lng: 8.783131599426383,
        lat: 47.72487640380882
    },
    {
        lng: 8.783886909484977,
        lat: 47.72493743896496
    },
    {
        lng: 8.783652305603027,
        lat: 47.72613525390619
    },
    {
        lng: 8.785023689270133,
        lat: 47.72715759277344
    },
    {
        lng: 8.786478996276912,
        lat: 47.72629928588867
    },
    {
        lng: 8.788472175598258,
        lat: 47.72782135009794
    },
    {
        lng: 8.790673255920524,
        lat: 47.72729873657232
    },
    {
        lng: 8.79182147979742,
        lat: 47.728767395019645
    },
    {
        lng: 8.798775672912598,
        lat: 47.72721099853521
    },
    {
        lng: 8.799111366271973,
        lat: 47.727134704590014
    },
    {
        lng: 8.799112319946232,
        lat: 47.72753524780302
    },
    {
        lng: 8.79908084869379,
        lat: 47.72882080078125
    },
    {
        lng: 8.797616004943848,
        lat: 47.73213577270519
    },
    {
        lng: 8.79654693603527,
        lat: 47.73457336425798
    },
    {
        lng: 8.799636840820256,
        lat: 47.73528671264671
    },
    {
        lng: 8.799747467041016,
        lat: 47.735313415527344
    },
    {
        lng: 8.799590110778922,
        lat: 47.73571014404314
    },
    {
        lng: 8.799419403076172,
        lat: 47.7361412048341
    },
    {
        lng: 8.799540519714355,
        lat: 47.73615646362299
    },
    {
        lng: 8.804633140563965,
        lat: 47.73676681518572
    },
    {
        lng: 8.804903030395508,
        lat: 47.738101959228686
    },
    {
        lng: 8.80509281158453,
        lat: 47.73812484741211
    },
    {
        lng: 8.806440353393668,
        lat: 47.73826217651367
    },
    {
        lng: 8.807443618774471,
        lat: 47.73706436157238
    },
    {
        lng: 8.806970596313477,
        lat: 47.73453903198265
    },
    {
        lng: 8.80844783782959,
        lat: 47.7310676574707
    },
    {
        lng: 8.809289932250977,
        lat: 47.73095321655285
    },
    {
        lng: 8.810832023620605,
        lat: 47.730739593505916
    },
    {
        lng: 8.811938285827637,
        lat: 47.73035430908203
    },
    {
        lng: 8.81077766418457,
        lat: 47.729751586914176
    },
    {
        lng: 8.81146335601818,
        lat: 47.729286193847884
    },
    {
        lng: 8.811460494995117,
        lat: 47.72883605957031
    },
    {
        lng: 8.811021804809627,
        lat: 47.72834014892601
    },
    {
        lng: 8.810968399047852,
        lat: 47.7282791137697
    },
    {
        lng: 8.809836387634334,
        lat: 47.727607727050895
    },
    {
        lng: 8.805829048156852,
        lat: 47.72602462768566
    },
    {
        lng: 8.804866790771598,
        lat: 47.72529602050787
    },
    {
        lng: 8.804841041564941,
        lat: 47.72449874877941
    },
    {
        lng: 8.807569503784293,
        lat: 47.72460174560558
    },
    {
        lng: 8.810823440551815,
        lat: 47.72425842285156
    },
    {
        lng: 8.812765121459961,
        lat: 47.72365951538109
    },
    {
        lng: 8.813675880432186,
        lat: 47.72269821167015
    },
    {
        lng: 8.818562507629451,
        lat: 47.71765136718773
    },
    {
        lng: 8.822952270507812,
        lat: 47.718532562255916
    },
    {
        lng: 8.82496547698986,
        lat: 47.71893310546892
    },
    {
        lng: 8.826159477233944,
        lat: 47.71791076660185
    },
    {
        lng: 8.825138092041016,
        lat: 47.71697616577171
    },
    {
        lng: 8.823093414306697,
        lat: 47.71587371826189
    },
    {
        lng: 8.822979927063102,
        lat: 47.71581268310575
    },
    {
        lng: 8.821805953979549,
        lat: 47.71487808227562
    },
    {
        lng: 8.818796157836914,
        lat: 47.713508605957145
    },
    {
        lng: 8.818818092346248,
        lat: 47.712863922119254
    },
    {
        lng: 8.822447776794547,
        lat: 47.712245941162166
    },
    {
        lng: 8.822903633117676,
        lat: 47.712162017822436
    },
    {
        lng: 8.822624206543026,
        lat: 47.71137619018555
    },
    {
        lng: 8.823390960693303,
        lat: 47.71114349365257
    },
    {
        lng: 8.823631286621207,
        lat: 47.7109107971192
    },
    {
        lng: 8.825407981872559,
        lat: 47.71098709106445
    },
    {
        lng: 8.8276624679566,
        lat: 47.711532592773665
    },
    {
        lng: 8.828925132751522,
        lat: 47.711708068847656
    },
    {
        lng: 8.830081939697266,
        lat: 47.71187973022472
    },
    {
        lng: 8.82987308502203,
        lat: 47.71234130859381
    },
    {
        lng: 8.831536293029785,
        lat: 47.71369552612305
    },
    {
        lng: 8.833621025085563,
        lat: 47.714572906494425
    },
    {
        lng: 8.834122657775936,
        lat: 47.71382141113304
    },
    {
        lng: 8.83549499511713,
        lat: 47.713863372802734
    },
    {
        lng: 8.837879180908146,
        lat: 47.71317672729515
    },
    {
        lng: 8.840691566467285,
        lat: 47.71284866333036
    },
    {
        lng: 8.841033935546818,
        lat: 47.71210479736351
    },
    {
        lng: 8.843253135681266,
        lat: 47.71213912963867
    },
    {
        lng: 8.845750808715877,
        lat: 47.7112007141115
    },
    {
        lng: 8.845763206481877,
        lat: 47.7097740173341
    },
    {
        lng: 8.845797538757381,
        lat: 47.70875549316435
    },
    {
        lng: 8.846030235290527,
        lat: 47.707580566406364
    },
    {
        lng: 8.846094131469727,
        lat: 47.70723724365246
    },
    {
        lng: 8.846569061279354,
        lat: 47.7072563171389
    },
    {
        lng: 8.846960067749023,
        lat: 47.70610809326166
    },
    {
        lng: 8.847196578979549,
        lat: 47.70566940307634
    },
    {
        lng: 8.847308158874569,
        lat: 47.70548248291027
    },
    {
        lng: 8.847902297973746,
        lat: 47.70457458496105
    },
    {
        lng: 8.84823131561285,
        lat: 47.703701019287394
    },
    {
        lng: 8.84832763671875,
        lat: 47.703453063964844
    },
    {
        lng: 8.849742889404297,
        lat: 47.70378494262695
    },
    {
        lng: 8.850420951843375,
        lat: 47.70406723022472
    },
    {
        lng: 8.850950241088867,
        lat: 47.70402908325201
    },
    {
        lng: 8.851704597473145,
        lat: 47.70412063598633
    },
    {
        lng: 8.852369308471793,
        lat: 47.70427322387724
    },
    {
        lng: 8.852992057800293,
        lat: 47.704616546630916
    },
    {
        lng: 8.853743553161735,
        lat: 47.704795837402344
    },
    {
        lng: 8.85470962524414,
        lat: 47.704879760742415
    },
    {
        lng: 8.855738639831657,
        lat: 47.70466995239269
    },
    {
        lng: 8.856499671935978,
        lat: 47.70456314086937
    },
    {
        lng: 8.857325553894043,
        lat: 47.70463562011719
    },
    {
        lng: 8.858013153076229,
        lat: 47.704608917236385
    },
    {
        lng: 8.858514785766658,
        lat: 47.70436859130888
    },
    {
        lng: 8.858940124511832,
        lat: 47.70429229736351
    },
    {
        lng: 8.860014915466365,
        lat: 47.70427322387724
    },
    {
        lng: 8.860969543457088,
        lat: 47.70415496826183
    },
    {
        lng: 8.861477851867733,
        lat: 47.704235076904354
    },
    {
        lng: 8.86208438873291,
        lat: 47.70455169677763
    },
    {
        lng: 8.862799644470215,
        lat: 47.70476531982433
    },
    {
        lng: 8.863533973693961,
        lat: 47.70494079589872
    },
    {
        lng: 8.8640718460083,
        lat: 47.70518875122076
    },
    {
        lng: 8.86452579498291,
        lat: 47.70530319213867
    },
    {
        lng: 8.865282058715934,
        lat: 47.705337524414176
    },
    {
        lng: 8.865947723388672,
        lat: 47.7054557800293
    },
    {
        lng: 8.866456985473633,
        lat: 47.705509185791016
    },
    {
        lng: 8.866989135742188,
        lat: 47.70539855957031
    },
    {
        lng: 8.867555618286246,
        lat: 47.705322265625284
    },
    {
        lng: 8.868135452270621,
        lat: 47.70535659790062
    },
    {
        lng: 8.86855792999279,
        lat: 47.70537567138672
    },
    {
        lng: 8.868844985961971,
        lat: 47.70520019531267
    },
    {
        lng: 8.869067192077694,
        lat: 47.70489501953131
    },
    {
        lng: 8.869531631469727,
        lat: 47.7047004699707
    },
    {
        lng: 8.869975090026912,
        lat: 47.70458602905296
    },
    {
        lng: 8.870468139648438,
        lat: 47.70460510253906
    },
    {
        lng: 8.870869636535758,
        lat: 47.704708099365234
    },
    {
        lng: 8.871376991271916,
        lat: 47.70495986938499
    },
    {
        lng: 8.871986389160156,
        lat: 47.70449829101585
    },
    {
        lng: 8.872719764709416,
        lat: 47.704166412353516
    },
    {
        lng: 8.872906684875545,
        lat: 47.7038650512697
    },
    {
        lng: 8.871933937072868,
        lat: 47.702999114990405
    },
    {
        lng: 8.869676589965763,
        lat: 47.701999664306754
    },
    {
        lng: 8.870165824890194,
        lat: 47.70126342773443
    },
    {
        lng: 8.871224403381348,
        lat: 47.701194763183764
    },
    {
        lng: 8.871415138244629,
        lat: 47.70053863525402
    },
    {
        lng: 8.871468544006404,
        lat: 47.700046539306754
    },
    {
        lng: 8.872100830078239,
        lat: 47.70008850097679
    },
    {
        lng: 8.872787475585994,
        lat: 47.699043273925895
    },
    {
        lng: 8.873620986938533,
        lat: 47.69831848144531
    },
    {
        lng: 8.873736381530762,
        lat: 47.698246002197266
    },
    {
        lng: 8.874322891235352,
        lat: 47.697875976562784
    },
    {
        lng: 8.87535572052002,
        lat: 47.69733047485374
    },
    {
        lng: 8.87579250335699,
        lat: 47.69710159301758
    },
    {
        lng: 8.876026153564396,
        lat: 47.69693756103527
    },
    {
        lng: 8.876140594482479,
        lat: 47.69668960571289
    },
    {
        lng: 8.87598896026617,
        lat: 47.69647216796898
    },
    {
        lng: 8.875716209411564,
        lat: 47.696186065674055
    },
    {
        lng: 8.875481605529842,
        lat: 47.69583511352545
    },
    {
        lng: 8.875151634216252,
        lat: 47.69515228271507
    },
    {
        lng: 8.87495326995844,
        lat: 47.69475936889643
    },
    {
        lng: 8.874420166015625,
        lat: 47.694370269775504
    },
    {
        lng: 8.87375736236578,
        lat: 47.69416809082054
    },
    {
        lng: 8.873004913330078,
        lat: 47.694015502929744
    },
    {
        lng: 8.871802330017147,
        lat: 47.69361877441429
    },
    {
        lng: 8.871153831481934,
        lat: 47.69353485107422
    },
    {
        lng: 8.870585441589412,
        lat: 47.693672180175895
    },
    {
        lng: 8.870188713073787,
        lat: 47.693950653076286
    },
    {
        lng: 8.869817733764648,
        lat: 47.694004058837834
    },
    {
        lng: 8.869381904601994,
        lat: 47.693843841552905
    },
    {
        lng: 8.868838310241756,
        lat: 47.69377517700224
    },
    {
        lng: 8.868254661560172,
        lat: 47.693847656250114
    },
    {
        lng: 8.867298126220703,
        lat: 47.69401168823242
    },
    {
        lng: 8.866595268249569,
        lat: 47.69396591186552
    },
    {
        lng: 8.865742683410588,
        lat: 47.69365692138683
    },
    {
        lng: 8.864448547363395,
        lat: 47.6928405761721
    },
    {
        lng: 8.863754272460938,
        lat: 47.692520141601676
    },
    {
        lng: 8.863208770752067,
        lat: 47.69252395629883
    },
    {
        lng: 8.862847328186035,
        lat: 47.69279479980486
    },
    {
        lng: 8.86273384094244,
        lat: 47.693016052246094
    },
    {
        lng: 8.862970352172965,
        lat: 47.69332885742182
    },
    {
        lng: 8.86405181884777,
        lat: 47.694190979004134
    },
    {
        lng: 8.863840103149414,
        lat: 47.694732666015625
    },
    {
        lng: 8.865105628967285,
        lat: 47.6948127746582
    },
    {
        lng: 8.866230010986385,
        lat: 47.69651794433605
    },
    {
        lng: 8.863258361816463,
        lat: 47.69849395751953
    },
    {
        lng: 8.862487792968807,
        lat: 47.69729614257824
    },
    {
        lng: 8.861704826354924,
        lat: 47.69699859619158
    },
    {
        lng: 8.860950469970646,
        lat: 47.696891784668026
    },
    {
        lng: 8.860690116882438,
        lat: 47.69624328613281
    },
    {
        lng: 8.860166549682674,
        lat: 47.696628570556754
    },
    {
        lng: 8.86016750335699,
        lat: 47.69822311401367
    },
    {
        lng: 8.859431266784668,
        lat: 47.69810485839838
    },
    {
        lng: 8.858069419860897,
        lat: 47.69879913330084
    },
    {
        lng: 8.85721588134777,
        lat: 47.69798660278332
    },
    {
        lng: 8.856721878051701,
        lat: 47.697700500488395
    },
    {
        lng: 8.85654163360607,
        lat: 47.697597503662394
    },
    {
        lng: 8.85577774047863,
        lat: 47.6972541809082
    },
    {
        lng: 8.855149269104118,
        lat: 47.697090148925895
    },
    {
        lng: 8.854288101196289,
        lat: 47.69704055786144
    },
    {
        lng: 8.853332519531307,
        lat: 47.697154998779524
    },
    {
        lng: 8.85267353057867,
        lat: 47.69739532470703
    },
    {
        lng: 8.852189064025936,
        lat: 47.69766235351574
    },
    {
        lng: 8.851730346679688,
        lat: 47.697692871094034
    },
    {
        lng: 8.851541519165153,
        lat: 47.697521209717024
    },
    {
        lng: 8.851640701294002,
        lat: 47.6972007751466
    },
    {
        lng: 8.851758956909237,
        lat: 47.69682312011719
    },
    {
        lng: 8.851644515991325,
        lat: 47.69654846191435
    },
    {
        lng: 8.850902557373047,
        lat: 47.696086883545036
    },
    {
        lng: 8.850804328918514,
        lat: 47.69533538818382
    },
    {
        lng: 8.850773811340389,
        lat: 47.69466781616234
    },
    {
        lng: 8.85267448425293,
        lat: 47.694187164306584
    },
    {
        lng: 8.853137969970646,
        lat: 47.69452667236328
    },
    {
        lng: 8.854634284973258,
        lat: 47.6945495605471
    },
    {
        lng: 8.854972839355469,
        lat: 47.6944465637207
    },
    {
        lng: 8.855527877807617,
        lat: 47.69416809082054
    },
    {
        lng: 8.85606861114502,
        lat: 47.69379806518566
    },
    {
        lng: 8.856555938720817,
        lat: 47.69344711303711
    },
    {
        lng: 8.857254981994572,
        lat: 47.69306564331055
    },
    {
        lng: 8.857565879821891,
        lat: 47.69273757934593
    },
    {
        lng: 8.857580184936467,
        lat: 47.69229888916021
    },
    {
        lng: 8.857437133789006,
        lat: 47.69181823730469
    },
    {
        lng: 8.85708045959484,
        lat: 47.69143295288109
    },
    {
        lng: 8.856829643249569,
        lat: 47.69101333618164
    },
    {
        lng: 8.856671333313045,
        lat: 47.69047546386713
    },
    {
        lng: 8.85633563995367,
        lat: 47.689994812011946
    },
    {
        lng: 8.855881690979118,
        lat: 47.68985748291027
    },
    {
        lng: 8.855997085571289,
        lat: 47.68941879272455
    },
    {
        lng: 8.856082916259822,
        lat: 47.689090728759766
    },
    {
        lng: 8.855544090270996,
        lat: 47.688892364502124
    },
    {
        lng: 8.853604316711426,
        lat: 47.688171386718864
    },
    {
        lng: 8.852767944335994,
        lat: 47.686645507812614
    },
    {
        lng: 8.852376937866325,
        lat: 47.68569946289057
    },
    {
        lng: 8.850942611694336,
        lat: 47.68561935424805
    },
    {
        lng: 8.85068607330328,
        lat: 47.68501663208019
    },
    {
        lng: 8.85051059722906,
        lat: 47.684513092041016
    },
    {
        lng: 8.850543022155819,
        lat: 47.68392944335949
    },
    {
        lng: 8.850672721862793,
        lat: 47.68283462524437
    },
    {
        lng: 8.85047531127924,
        lat: 47.68187332153343
    },
    {
        lng: 8.850583076477108,
        lat: 47.68116378784191
    },
    {
        lng: 8.851858139038029,
        lat: 47.68095397949219
    },
    {
        lng: 8.852336883545036,
        lat: 47.68093872070307
    },
    {
        lng: 8.852492332458496,
        lat: 47.68093490600609
    },
    {
        lng: 8.853169441223088,
        lat: 47.680915832519645
    },
    {
        lng: 8.854063034057674,
        lat: 47.68072891235374
    },
    {
        lng: 8.854865074157772,
        lat: 47.680744171142805
    },
    {
        lng: 8.855461120605526,
        lat: 47.68061065673828
    },
    {
        lng: 8.8560209274292,
        lat: 47.680301666259936
    },
    {
        lng: 8.85690879821783,
        lat: 47.680290222168026
    },
    {
        lng: 8.857874870300236,
        lat: 47.680473327636776
    },
    {
        lng: 8.85863399505621,
        lat: 47.68051528930664
    },
    {
        lng: 8.859479904174748,
        lat: 47.68050003051775
    },
    {
        lng: 8.860806465148926,
        lat: 47.68025207519554
    },
    {
        lng: 8.86239147186285,
        lat: 47.67943191528326
    },
    {
        lng: 8.863787651061955,
        lat: 47.678672790527344
    },
    {
        lng: 8.86666584014904,
        lat: 47.676528930664176
    },
    {
        lng: 8.86783695220953,
        lat: 47.675727844238395
    },
    {
        lng: 8.868963241577262,
        lat: 47.67387008667015
    },
    {
        lng: 8.870244026184139,
        lat: 47.67214584350609
    },
    {
        lng: 8.870647430420036,
        lat: 47.671600341796875
    },
    {
        lng: 8.872164726257438,
        lat: 47.670860290527514
    },
    {
        lng: 8.873471260070858,
        lat: 47.670219421387
    },
    {
        lng: 8.873858451843375,
        lat: 47.66865539550787
    },
    {
        lng: 8.874303817749137,
        lat: 47.666820526123274
    },
    {
        lng: 8.876020431518668,
        lat: 47.660724639892805
    },
    {
        lng: 8.875289916992244,
        lat: 47.65987014770508
    },
    {
        lng: 8.875326156616211,
        lat: 47.657680511474666
    },
    {
        lng: 8.875333786010742,
        lat: 47.65720367431646
    },
    {
        lng: 8.87535095214838,
        lat: 47.65620422363281
    },
    {
        lng: 8.875385284423885,
        lat: 47.654628753662166
    },
    {
        lng: 8.876127243041992,
        lat: 47.65459442138683
    },
    {
        lng: 8.87608432769781,
        lat: 47.65455245971697
    },
    {
        lng: 8.875791549682674,
        lat: 47.65439605712919
    },
    {
        lng: 8.875078201293945,
        lat: 47.65419769287138
    },
    {
        lng: 8.877493858337402,
        lat: 47.65439605712919
    },
    {
        lng: 8.88323116302496,
        lat: 47.6536941528322
    },
    {
        lng: 8.888111114501953,
        lat: 47.65205764770508
    },
    {
        lng: 8.898453712463322,
        lat: 47.648422241211165
    },
    {
        lng: 8.943534851074276,
        lat: 47.656501770019645
    },
    {
        lng: 8.948993682861385,
        lat: 47.65933609008789
    },
    {
        lng: 8.962954521179313,
        lat: 47.663852691650504
    },
    {
        lng: 8.96722602844244,
        lat: 47.66658401489258
    },
    {
        lng: 8.985946655273551,
        lat: 47.67407226562494
    },
    {
        lng: 9.014278411865348,
        lat: 47.685783386230696
    },
    {
        lng: 9.032221794128361,
        lat: 47.68653869628929
    },
    {
        lng: 9.045065879821891,
        lat: 47.6850280761721
    },
    {
        lng: 9.061120986938533,
        lat: 47.6823844909668
    },
    {
        lng: 9.071319580078182,
        lat: 47.67955017089855
    },
    {
        lng: 9.080574989318961,
        lat: 47.67879486083996
    },
    {
        lng: 9.09210586547863,
        lat: 47.67863082885765
    },
    {
        lng: 9.09360790252697,
        lat: 47.67860794067383
    },
    {
        lng: 9.094061851501579,
        lat: 47.67864990234369
    },
    {
        lng: 9.09777927398676,
        lat: 47.678691864013786
    },
    {
        lng: 9.102263450622672,
        lat: 47.67744064331072
    },
    {
        lng: 9.10473918914795,
        lat: 47.67667770385759
    },
    {
        lng: 9.109500885009766,
        lat: 47.67432785034208
    },
    {
        lng: 9.116483688354549,
        lat: 47.670772552490405
    },
    {
        lng: 9.121410369873104,
        lat: 47.668888092041016
    },
    {
        lng: 9.123785018921012,
        lat: 47.667980194091854
    },
    {
        lng: 9.127213478088379,
        lat: 47.66696548461914
    },
    {
        lng: 9.127683639526367,
        lat: 47.666721343994084
    },
    {
        lng: 9.12795734405529,
        lat: 47.66679763793968
    },
    {
        lng: 9.12783050537115,
        lat: 47.66659927368164
    },
    {
        lng: 9.129379272461051,
        lat: 47.66624450683588
    },
    {
        lng: 9.130779266357479,
        lat: 47.666034698486555
    },
    {
        lng: 9.132387161254883,
        lat: 47.665939331054915
    },
    {
        lng: 9.133362770080623,
        lat: 47.66577911376976
    },
    {
        lng: 9.135418891906852,
        lat: 47.665203094482365
    },
    {
        lng: 9.137154579162654,
        lat: 47.66462707519554
    },
    {
        lng: 9.13822078704834,
        lat: 47.66424179077143
    },
    {
        lng: 9.139326095581055,
        lat: 47.66400146484392
    },
    {
        lng: 9.140380859375057,
        lat: 47.664043426513786
    },
    {
        lng: 9.14159870147705,
        lat: 47.66428375244152
    },
    {
        lng: 9.14289379119873,
        lat: 47.66481399536127
    },
    {
        lng: 9.145792961120662,
        lat: 47.66651916503929
    },
    {
        lng: 9.14651107788086,
        lat: 47.66694259643555
    },
    {
        lng: 9.147346496581974,
        lat: 47.66729736328148
    },
    {
        lng: 9.14833927154541,
        lat: 47.66761016845703
    },
    {
        lng: 9.148471832275447,
        lat: 47.66762161254894
    },
    {
        lng: 9.148883819580135,
        lat: 47.66762924194347
    },
    {
        lng: 9.149310111999625,
        lat: 47.667613983154354
    },
    {
        lng: 9.149724006652946,
        lat: 47.6675834655764
    },
    {
        lng: 9.15013694763195,
        lat: 47.667545318603516
    },
    {
        lng: 9.15055084228527,
        lat: 47.66749572753929
    },
    {
        lng: 9.150951385498104,
        lat: 47.66743850708019
    },
    {
        lng: 9.151339530944881,
        lat: 47.667369842529524
    },
    {
        lng: 9.156892776489371,
        lat: 47.66619110107433
    },
    {
        lng: 9.157522201538143,
        lat: 47.66604614257824
    },
    {
        lng: 9.157709121704102,
        lat: 47.666011810302734
    },
    {
        lng: 9.157871246337947,
        lat: 47.66591262817394
    },
    {
        lng: 9.15811634063732,
        lat: 47.66571044921892
    },
    {
        lng: 9.158485412597713,
        lat: 47.6653747558596
    },
    {
        lng: 9.158840179443473,
        lat: 47.66501998901367
    },
    {
        lng: 9.15916919708252,
        lat: 47.66465377807617
    },
    {
        lng: 9.159472465515137,
        lat: 47.66427993774431
    },
    {
        lng: 9.159749031066951,
        lat: 47.66390609741228
    },
    {
        lng: 9.159914970398006,
        lat: 47.66364669799833
    },
    {
        lng: 9.159972190856934,
        lat: 47.663520812988395
    },
    {
        lng: 9.159989356994629,
        lat: 47.66337966918968
    },
    {
        lng: 9.159979820251465,
        lat: 47.66323471069347
    },
    {
        lng: 9.159908294677734,
        lat: 47.66292953491211
    },
    {
        lng: 9.15988540649414,
        lat: 47.662784576416016
    },
    {
        lng: 9.15988922119152,
        lat: 47.66265106201172
    },
    {
        lng: 9.159945487976131,
        lat: 47.662525177001896
    },
    {
        lng: 9.160041809082088,
        lat: 47.66241073608421
    },
    {
        lng: 9.160151481628532,
        lat: 47.66230392456083
    },
    {
        lng: 9.160408973693848,
        lat: 47.66209793090832
    },
    {
        lng: 9.160707473754996,
        lat: 47.661895751953296
    },
    {
        lng: 9.161005020141602,
        lat: 47.661720275878906
    },
    {
        lng: 9.16184234619135,
        lat: 47.661254882812784
    },
    {
        lng: 9.1623153686524,
        lat: 47.66096115112316
    },
    {
        lng: 9.163034439086857,
        lat: 47.66046142578131
    },
    {
        lng: 9.164608001708928,
        lat: 47.65934753417997
    },
    {
        lng: 9.165339469909611,
        lat: 47.65885162353533
    },
    {
        lng: 9.165767669677734,
        lat: 47.658569335937614
    },
    {
        lng: 9.166233062744197,
        lat: 47.65826034545904
    },
    {
        lng: 9.168195724487362,
        lat: 47.65700912475586
    },
    {
        lng: 9.169727325439567,
        lat: 47.65607452392584
    },
    {
        lng: 9.17053508758545,
        lat: 47.65485382080078
    },
    {
        lng: 9.171882629394588,
        lat: 47.65545654296898
    },
    {
        lng: 9.172905921936149,
        lat: 47.65546798706049
    },
    {
        lng: 9.17533206939703,
        lat: 47.65575790405296
    },
    {
        lng: 9.17579364776617,
        lat: 47.65393829345726
    },
    {
        lng: 9.176537513733024,
        lat: 47.65421676635765
    },
    {
        lng: 9.178181648254338,
        lat: 47.65483093261719
    },
    {
        lng: 9.18167781829834,
        lat: 47.65573501586937
    },
    {
        lng: 9.181284904480037,
        lat: 47.656513214111555
    },
    {
        lng: 9.181190490722713,
        lat: 47.65707778930687
    },
    {
        lng: 9.181138038635197,
        lat: 47.65744400024414
    },
    {
        lng: 9.181476593017635,
        lat: 47.65740966796881
    },
    {
        lng: 9.182603836059627,
        lat: 47.65743637084961
    },
    {
        lng: 9.184355735778809,
        lat: 47.65764236450218
    },
    {
        lng: 9.185763359069767,
        lat: 47.65773773193382
    },
    {
        lng: 9.18635177612299,
        lat: 47.65744400024414
    },
    {
        lng: 9.187163352966422,
        lat: 47.65682983398443
    },
    {
        lng: 9.25444698333746,
        lat: 47.65682983398443
    },
    {
        lng: 9.275901794433537,
        lat: 47.656135559081974
    },
    {
        lng: 9.336469650268668,
        lat: 47.6307754516601
    },
    {
        lng: 9.384534835815543,
        lat: 47.613166809082145
    },
    {
        lng: 9.439262390136776,
        lat: 47.58985137939476
    },
    {
        lng: 9.479713439941463,
        lat: 47.567005157470874
    },
    {
        lng: 9.50541210174572,
        lat: 47.554634094238565
    },
    {
        lng: 9.524447441101188,
        lat: 47.545116424560774
    },
    {
        lng: 9.541103363037223,
        lat: 47.54225921630871
    },
    {
        lng: 9.562519073486328,
        lat: 47.53988265991205
    },
    {
        lng: 9.560139656066951,
        lat: 47.52084350585943
    },
    {
        lng: 9.561061859130916,
        lat: 47.50240325927729
    },
    {
        lng: 9.561091423034782,
        lat: 47.50180816650419
    },
    {
        lng: 9.560992240905762,
        lat: 47.4992370605471
    },
    {
        lng: 9.561564445495605,
        lat: 47.49904251098661
    },
    {
        lng: 9.562077522277946,
        lat: 47.49873733520508
    },
    {
        lng: 9.56212425231945,
        lat: 47.498710632324276
    },
    {
        lng: 9.56220722198492,
        lat: 47.49864578247082
    },
    {
        lng: 9.562902450561467,
        lat: 47.49809646606457
    },
    {
        lng: 9.564269065856877,
        lat: 47.496662139892805
    },
    {
        lng: 9.564413070678825,
        lat: 47.49630355834984
    },
    {
        lng: 9.56456279754633,
        lat: 47.495929718017805
    },
    {
        lng: 9.565588951110954,
        lat: 47.49454498291027
    },
    {
        lng: 9.56593322753912,
        lat: 47.49417114257841
    },
    {
        lng: 9.566779136657715,
        lat: 47.493598937988565
    },
    {
        lng: 9.567513465881461,
        lat: 47.49298095703131
    },
    {
        lng: 9.568385124206543,
        lat: 47.49246215820341
    },
    {
        lng: 9.569124221801758,
        lat: 47.49185943603527
    },
    {
        lng: 9.569738388061637,
        lat: 47.49149322509777
    },
    {
        lng: 9.569993972778377,
        lat: 47.491340637206974
    },
    {
        lng: 9.570727348327637,
        lat: 47.490730285644645
    },
    {
        lng: 9.571590423584098,
        lat: 47.49020767211937
    },
    {
        lng: 9.572319984435978,
        lat: 47.48959732055664
    },
    {
        lng: 9.57319545745861,
        lat: 47.489086151123104
    },
    {
        lng: 9.573978424072266,
        lat: 47.4884986877442
    },
    {
        lng: 9.575393676757812,
        lat: 47.487857818603686
    },
    {
        lng: 9.577943801879883,
        lat: 47.48640441894531
    },
    {
        lng: 9.578309059143066,
        lat: 47.486225128173885
    },
    {
        lng: 9.580943107605094,
        lat: 47.4849281311038
    },
    {
        lng: 9.581336975097656,
        lat: 47.484336853027344
    },
    {
        lng: 9.581503868103027,
        lat: 47.48393249511719
    },
    {
        lng: 9.58161830902094,
        lat: 47.48365402221697
    },
    {
        lng: 9.581923484802303,
        lat: 47.483562469482536
    },
    {
        lng: 9.582731246948356,
        lat: 47.48331451416027
    },
    {
        lng: 9.584366798400879,
        lat: 47.482177734375114
    },
    {
        lng: 9.584713935852108,
        lat: 47.481658935547046
    },
    {
        lng: 9.585180282592887,
        lat: 47.48024368286133
    },
    {
        lng: 9.58535480499279,
        lat: 47.47982788085943
    },
    {
        lng: 9.58581256866455,
        lat: 47.47873687744152
    },
    {
        lng: 9.586799621581974,
        lat: 47.477821350097656
    },
    {
        lng: 9.586894989013615,
        lat: 47.47773361206055
    },
    {
        lng: 9.587418556213379,
        lat: 47.47713470459007
    },
    {
        lng: 9.588866233825627,
        lat: 47.47499465942394
    },
    {
        lng: 9.589214324951172,
        lat: 47.47418212890619
    },
    {
        lng: 9.589504241943416,
        lat: 47.47350692749018
    },
    {
        lng: 9.590152740478516,
        lat: 47.472763061523494
    },
    {
        lng: 9.590448379516715,
        lat: 47.4724235534668
    },
    {
        lng: 9.590941429138297,
        lat: 47.47156906127958
    },
    {
        lng: 9.59123516082775,
        lat: 47.471061706543026
    },
    {
        lng: 9.591396331787166,
        lat: 47.470890045166016
    },
    {
        lng: 9.592112541198787,
        lat: 47.47012710571289
    },
    {
        lng: 9.592576980590763,
        lat: 47.46920394897484
    },
    {
        lng: 9.59292030334484,
        lat: 47.46852111816406
    },
    {
        lng: 9.594006538391113,
        lat: 47.46691131591808
    },
    {
        lng: 9.594586372375488,
        lat: 47.46570587158209
    },
    {
        lng: 9.59658145904541,
        lat: 47.46434402465832
    },
    {
        lng: 9.59800815582281,
        lat: 47.46313095092779
    },
    {
        lng: 9.598582267761287,
        lat: 47.462791442871094
    },
    {
        lng: 9.599829673767147,
        lat: 47.46205139160179
    },
    {
        lng: 9.60060977935791,
        lat: 47.461826324463004
    },
    {
        lng: 9.60194396972662,
        lat: 47.46166610717785
    },
    {
        lng: 9.6033353805542,
        lat: 47.461650848388956
    },
    {
        lng: 9.60467338562006,
        lat: 47.4617919921875
    },
    {
        lng: 9.605464935302734,
        lat: 47.46204757690441
    },
    {
        lng: 9.60594844818121,
        lat: 47.462368011474666
    },
    {
        lng: 9.606707572936955,
        lat: 47.463157653808764
    },
    {
        lng: 9.607070922851562,
        lat: 47.463630676269645
    },
    {
        lng: 9.607416152954215,
        lat: 47.46445083618187
    },
    {
        lng: 9.607625961303768,
        lat: 47.46588897705101
    },
    {
        lng: 9.607672691345272,
        lat: 47.466209411621264
    },
    {
        lng: 9.607859611511174,
        lat: 47.46678161621111
    },
    {
        lng: 9.60872936248785,
        lat: 47.4680290222168
    },
    {
        lng: 9.608875274658203,
        lat: 47.46823883056669
    },
    {
        lng: 9.609270095825252,
        lat: 47.468803405762
    },
    {
        lng: 9.609769821167049,
        lat: 47.46918869018555
    },
    {
        lng: 9.610397338867301,
        lat: 47.46947860717802
    },
    {
        lng: 9.61111927032482,
        lat: 47.46960067749046
    },
    {
        lng: 9.611788749694881,
        lat: 47.469638824463004
    },
    {
        lng: 9.612361907959041,
        lat: 47.46949005126976
    },
    {
        lng: 9.613556861877441,
        lat: 47.46879196166992
    },
    {
        lng: 9.615213394165153,
        lat: 47.467483520507926
    },
    {
        lng: 9.615535736083984,
        lat: 47.46707534790039
    },
    {
        lng: 9.616057395935115,
        lat: 47.466392517090014
    },
    {
        lng: 9.617061614990234,
        lat: 47.465488433838004
    },
    {
        lng: 9.61771297454834,
        lat: 47.46444320678734
    },
    {
        lng: 9.618498802185059,
        lat: 47.463726043701286
    },
    {
        lng: 9.61855316162115,
        lat: 47.46367645263683
    },
    {
        lng: 9.61861801147461,
        lat: 47.46357727050804
    },
    {
        lng: 9.619160652160588,
        lat: 47.46274948120123
    },
    {
        lng: 9.619359016418457,
        lat: 47.462448120117415
    },
    {
        lng: 9.620197296142635,
        lat: 47.46168899536127
    },
    {
        lng: 9.620923042297363,
        lat: 47.46068572998047
    },
    {
        lng: 9.621312141418457,
        lat: 47.460296630859546
    },
    {
        lng: 9.621784210205192,
        lat: 47.460025787353516
    },
    {
        lng: 9.624954223632756,
        lat: 47.45851898193371
    },
    {
        lng: 9.626213073730526,
        lat: 47.45803833007835
    },
    {
        lng: 9.62667369842535,
        lat: 47.45792388916027
    },
    {
        lng: 9.627656936645451,
        lat: 47.457794189453125
    },
    {
        lng: 9.628946304321403,
        lat: 47.45776748657249
    },
    {
        lng: 9.62918663024908,
        lat: 47.457763671875114
    },
    {
        lng: 9.639114379882812,
        lat: 47.45658111572277
    },
    {
        lng: 9.64007472991949,
        lat: 47.45646667480469
    },
    {
        lng: 9.640452384948844,
        lat: 47.456451416015625
    },
    {
        lng: 9.64215469360363,
        lat: 47.45639038085949
    },
    {
        lng: 9.643679618835563,
        lat: 47.4562721252442
    },
    {
        lng: 9.64782810211193,
        lat: 47.455711364746264
    },
    {
        lng: 9.651789665222168,
        lat: 47.45496368408203
    },
    {
        lng: 9.654373168945312,
        lat: 47.45458984375
    },
    {
        lng: 9.656745910644645,
        lat: 47.45395660400385
    },
    {
        lng: 9.658702850341854,
        lat: 47.45357894897484
    },
    {
        lng: 9.659399032592887,
        lat: 47.453369140625114
    },
    {
        lng: 9.661026954650822,
        lat: 47.452564239502124
    },
    {
        lng: 9.661516189575252,
        lat: 47.452323913574446
    },
    {
        lng: 9.661724090576172,
        lat: 47.452220916748274
    },
    {
        lng: 9.662256240844727,
        lat: 47.45180511474621
    },
    {
        lng: 9.6626491546632,
        lat: 47.45129776000999
    },
    {
        lng: 9.662807464599553,
        lat: 47.450790405273665
    },
    {
        lng: 9.662805557250977,
        lat: 47.45071411132807
    },
    {
        lng: 9.662795066833553,
        lat: 47.45027160644537
    },
    {
        lng: 9.66272068023693,
        lat: 47.45008087158209
    },
    {
        lng: 9.662262916564998,
        lat: 47.44888305664085
    },
    {
        lng: 9.661582946777344,
        lat: 47.44784927368187
    },
    {
        lng: 9.661076545715446,
        lat: 47.44729995727545
    },
    {
        lng: 9.659487724304313,
        lat: 47.446220397949446
    },
    {
        lng: 9.658629417419547,
        lat: 47.445770263671875
    },
    {
        lng: 9.657877922058105,
        lat: 47.44526290893566
    },
    {
        lng: 9.65701961517334,
        lat: 47.44482421875
    },
    {
        lng: 9.655158042907772,
        lat: 47.443691253662166
    },
    {
        lng: 9.653933525085506,
        lat: 47.443061828613565
    },
    {
        lng: 9.652975082397461,
        lat: 47.442974090576286
    },
    {
        lng: 9.652483940124455,
        lat: 47.443161010742415
    },
    {
        lng: 9.652414321899528,
        lat: 47.44318771362299
    },
    {
        lng: 9.652178764343319,
        lat: 47.44327926635765
    },
    {
        lng: 9.651564598083553,
        lat: 47.44358062744146
    },
    {
        lng: 9.650968551635799,
        lat: 47.4437255859375
    },
    {
        lng: 9.650527000427246,
        lat: 47.44383239746105
    },
    {
        lng: 9.650421142578182,
        lat: 47.44320678710943
    },
    {
        lng: 9.650337219238281,
        lat: 47.44271087646479
    },
    {
        lng: 9.650148391723746,
        lat: 47.44218444824219
    },
    {
        lng: 9.649394989013729,
        lat: 47.44122695922863
    },
    {
        lng: 9.649042129516602,
        lat: 47.4403839111331
    },
    {
        lng: 9.648858070373535,
        lat: 47.43870925903343
    },
    {
        lng: 9.648630142212028,
        lat: 47.43664169311535
    },
    {
        lng: 9.648649215698299,
        lat: 47.43618011474621
    },
    {
        lng: 9.64894008636486,
        lat: 47.434902191162166
    },
    {
        lng: 9.64924621582037,
        lat: 47.43412399292015
    },
    {
        lng: 9.650227546692008,
        lat: 47.433010101318416
    },
    {
        lng: 9.650541305542049,
        lat: 47.43233871459961
    },
    {
        lng: 9.650971412658691,
        lat: 47.43141937255888
    },
    {
        lng: 9.651850700378475,
        lat: 47.43008422851591
    },
    {
        lng: 9.652670860290584,
        lat: 47.42803955078125
    },
    {
        lng: 9.653350830078239,
        lat: 47.42695236206066
    },
    {
        lng: 9.653633117675838,
        lat: 47.426353454589844
    },
    {
        lng: 9.653850555419865,
        lat: 47.42535781860374
    },
    {
        lng: 9.653923034668082,
        lat: 47.42363357543968
    },
    {
        lng: 9.653844833374137,
        lat: 47.422241210937614
    },
    {
        lng: 9.653701782226676,
        lat: 47.42148208618181
    },
    {
        lng: 9.653569221496639,
        lat: 47.42077636718744
    },
    {
        lng: 9.654123306274528,
        lat: 47.41948318481457
    },
    {
        lng: 9.654508590698242,
        lat: 47.41858291625971
    },
    {
        lng: 9.655359268188477,
        lat: 47.416049957275504
    },
    {
        lng: 9.655510902404785,
        lat: 47.41492843627958
    },
    {
        lng: 9.655547142028865,
        lat: 47.413806915283146
    },
    {
        lng: 9.655610084533748,
        lat: 47.41187667846708
    },
    {
        lng: 9.655734062194767,
        lat: 47.410751342773665
    },
    {
        lng: 9.65606784820551,
        lat: 47.40968322753935
    },
    {
        lng: 9.656546592712516,
        lat: 47.40891647338867
    },
    {
        lng: 9.65822601318365,
        lat: 47.407604217529524
    },
    {
        lng: 9.658980369567928,
        lat: 47.40683364868187
    },
    {
        lng: 9.65925407409668,
        lat: 47.40655517578148
    },
    {
        lng: 9.660017967224064,
        lat: 47.405914306640625
    },
    {
        lng: 9.66085147857666,
        lat: 47.404560089111555
    },
    {
        lng: 9.661004066467399,
        lat: 47.404312133789176
    },
    {
        lng: 9.661623954773063,
        lat: 47.403743743896655
    },
    {
        lng: 9.661914825439453,
        lat: 47.403476715088175
    },
    {
        lng: 9.662113189697266,
        lat: 47.40324401855469
    },
    {
        lng: 9.66396713256836,
        lat: 47.401069641113395
    },
    {
        lng: 9.665758132934684,
        lat: 47.39968872070335
    },
    {
        lng: 9.667279243469238,
        lat: 47.39876937866211
    },
    {
        lng: 9.667818069458065,
        lat: 47.398521423339844
    },
    {
        lng: 9.668803215026912,
        lat: 47.398269653320256
    },
    {
        lng: 9.669495582580623,
        lat: 47.398090362548885
    },
    {
        lng: 9.671063423156738,
        lat: 47.39741516113304
    },
    {
        lng: 9.67277622222906,
        lat: 47.397014617920036
    },
    {
        lng: 9.674368858337402,
        lat: 47.39635848999046
    },
    {
        lng: 9.675777435302678,
        lat: 47.39604568481474
    },
    {
        lng: 9.67649364471447,
        lat: 47.39581298828125
    },
    {
        lng: 9.677244186401424,
        lat: 47.39533615112305
    },
    {
        lng: 9.677614212036246,
        lat: 47.39490127563505
    },
    {
        lng: 9.67813873291027,
        lat: 47.393985748291016
    },
    {
        lng: 9.678427696228084,
        lat: 47.3930778503418
    },
    {
        lng: 9.678540229797363,
        lat: 47.39168167114252
    },
    {
        lng: 9.678507804870662,
        lat: 47.390766143799
    },
    {
        lng: 9.678488731384391,
        lat: 47.39022445678711
    },
    {
        lng: 9.67846488952648,
        lat: 47.389553070068644
    },
    {
        lng: 9.67831802368164,
        lat: 47.38851547241234
    },
    {
        lng: 9.678119659423828,
        lat: 47.38777923583979
    },
    {
        lng: 9.677854537963924,
        lat: 47.3867950439456
    },
    {
        lng: 9.677633285522518,
        lat: 47.38533401489269
    },
    {
        lng: 9.677506446838436,
        lat: 47.38449478149414
    },
    {
        lng: 9.67737007141119,
        lat: 47.38411331176758
    },
    {
        lng: 9.67730522155773,
        lat: 47.38393402099638
    },
    {
        lng: 9.676575660705623,
        lat: 47.382457733154354
    },
    {
        lng: 9.675081253051815,
        lat: 47.38056182861345
    },
    {
        lng: 9.674929618835506,
        lat: 47.380424499511946
    },
    {
        lng: 9.674160957336483,
        lat: 47.37972259521496
    },
    {
        lng: 9.673741340637207,
        lat: 47.37900161743187
    },
    {
        lng: 9.673488616943303,
        lat: 47.37856674194353
    },
    {
        lng: 9.673168182373047,
        lat: 47.37812805175804
    },
    {
        lng: 9.671672821044979,
        lat: 47.376892089843864
    },
    {
        lng: 9.671287536621037,
        lat: 47.37664794921881
    },
    {
        lng: 9.671135902404728,
        lat: 47.376552581787166
    },
    {
        lng: 9.670818328857365,
        lat: 47.37635040283203
    },
    {
        lng: 9.670069694519043,
        lat: 47.37573623657249
    },
    {
        lng: 9.669205665588493,
        lat: 47.37519836425781
    },
    {
        lng: 9.668674468994254,
        lat: 47.3747673034668
    },
    {
        lng: 9.668452262878532,
        lat: 47.3745880126956
    },
    {
        lng: 9.667413711547908,
        lat: 47.373935699463004
    },
    {
        lng: 9.666945457458496,
        lat: 47.37364196777355
    },
    {
        lng: 9.666408538818473,
        lat: 47.3733825683596
    },
    {
        lng: 9.665666580200195,
        lat: 47.37317657470726
    },
    {
        lng: 9.665514945983887,
        lat: 47.37313461303722
    },
    {
        lng: 9.664738655090275,
        lat: 47.37292098999029
    },
    {
        lng: 9.663625717163029,
        lat: 47.372409820556754
    },
    {
        lng: 9.663189888000602,
        lat: 47.37221145629894
    },
    {
        lng: 9.662802696228084,
        lat: 47.37210845947294
    },
    {
        lng: 9.661499977111873,
        lat: 47.37175750732416
    },
    {
        lng: 9.659938812255803,
        lat: 47.37105178833036
    },
    {
        lng: 9.658742904663143,
        lat: 47.37076950073242
    },
    {
        lng: 9.657627105713004,
        lat: 47.37050628662132
    },
    {
        lng: 9.657566070556697,
        lat: 47.37048721313505
    },
    {
        lng: 9.655684471130371,
        lat: 47.369903564453125
    },
    {
        lng: 9.654726028442496,
        lat: 47.36975479125971
    },
    {
        lng: 9.654249191284293,
        lat: 47.36968231201172
    },
    {
        lng: 9.651728630065861,
        lat: 47.369571685791016
    },
    {
        lng: 9.646328926086483,
        lat: 47.369529724121094
    },
    {
        lng: 9.643510818481445,
        lat: 47.36951065063488
    },
    {
        lng: 9.642702102661246,
        lat: 47.369461059570256
    },
    {
        lng: 9.642531394958496,
        lat: 47.36944961547874
    },
    {
        lng: 9.641105651855582,
        lat: 47.369365692138615
    },
    {
        lng: 9.640261650085563,
        lat: 47.36920928955084
    },
    {
        lng: 9.640172958374023,
        lat: 47.369182586669865
    },
    {
        lng: 9.639892578125,
        lat: 47.36909484863281
    },
    {
        lng: 9.63905143737793,
        lat: 47.36871719360357
    },
    {
        lng: 9.638591766357365,
        lat: 47.3685111999514
    },
    {
        lng: 9.636308670043945,
        lat: 47.36796569824219
    },
    {
        lng: 9.634778022766227,
        lat: 47.36745452880882
    },
    {
        lng: 9.63395214080822,
        lat: 47.36724853515648
    },
    {
        lng: 9.632632255554313,
        lat: 47.36706161499035
    },
    {
        lng: 9.630949974060115,
        lat: 47.36692047119146
    },
    {
        lng: 9.630290985107479,
        lat: 47.36689758300804
    },
    {
        lng: 9.627280235290527,
        lat: 47.36678695678734
    },
    {
        lng: 9.626303672790527,
        lat: 47.36590194702177
    },
    {
        lng: 9.62525844573986,
        lat: 47.36509323120123
    },
    {
        lng: 9.625050544738883,
        lat: 47.36493301391613
    },
    {
        lng: 9.620696067810172,
        lat: 47.361827850342024
    },
    {
        lng: 9.619199752807617,
        lat: 47.360759735107706
    },
    {
        lng: 9.615200042724666,
        lat: 47.35790634155285
    },
    {
        lng: 9.614014625549373,
        lat: 47.35694885253929
    },
    {
        lng: 9.613612174987793,
        lat: 47.35652160644531
    },
    {
        lng: 9.612962722778434,
        lat: 47.35583496093773
    },
    {
        lng: 9.612812042236442,
        lat: 47.35567474365246
    },
    {
        lng: 9.611948966980037,
        lat: 47.3550910949707
    },
    {
        lng: 9.611213684081974,
        lat: 47.354457855224894
    },
    {
        lng: 9.610335350036678,
        lat: 47.353927612304915
    },
    {
        lng: 9.60958576202404,
        lat: 47.353309631347656
    },
    {
        lng: 9.60883617401123,
        lat: 47.352863311767805
    },
    {
        lng: 9.608695030212516,
        lat: 47.35277938842768
    },
    {
        lng: 9.608476638794002,
        lat: 47.35260009765631
    },
    {
        lng: 9.607940673828239,
        lat: 47.35216140747082
    },
    {
        lng: 9.607573509216252,
        lat: 47.351940155029354
    },
    {
        lng: 9.607054710388184,
        lat: 47.35162734985346
    },
    {
        lng: 9.606324195861816,
        lat: 47.350990295410156
    },
    {
        lng: 9.605710029602108,
        lat: 47.3505668640139
    },
    {
        lng: 9.605340003967285,
        lat: 47.35018539428734
    },
    {
        lng: 9.604829788208008,
        lat: 47.34916305541992
    },
    {
        lng: 9.604740142822322,
        lat: 47.348983764648494
    },
    {
        lng: 9.604521751403752,
        lat: 47.348747253418196
    },
    {
        lng: 9.603723526001033,
        lat: 47.34788131713867
    },
    {
        lng: 9.603312492370605,
        lat: 47.34687805175787
    },
    {
        lng: 9.603053092956657,
        lat: 47.34624481201172
    },
    {
        lng: 9.602446556091309,
        lat: 47.345310211181925
    },
    {
        lng: 9.602169036865234,
        lat: 47.34488296508795
    },
    {
        lng: 9.601867675781193,
        lat: 47.34404754638683
    },
    {
        lng: 9.601416587829704,
        lat: 47.342796325683764
    },
    {
        lng: 9.600991249084473,
        lat: 47.34213256835949
    },
    {
        lng: 9.600714683532715,
        lat: 47.34170150756847
    },
    {
        lng: 9.600444793701115,
        lat: 47.34114074707054
    },
    {
        lng: 9.600317955017033,
        lat: 47.34057617187523
    },
    {
        lng: 9.599942207336426,
        lat: 47.33890533447277
    },
    {
        lng: 9.5996932983399,
        lat: 47.33844757080101
    },
    {
        lng: 9.599201202392635,
        lat: 47.33794403076183
    },
    {
        lng: 9.599037170410156,
        lat: 47.337776184082315
    },
    {
        lng: 9.598787307739315,
        lat: 47.337318420410384
    },
    {
        lng: 9.5987167358399,
        lat: 47.33701324462919
    },
    {
        lng: 9.59827518463129,
        lat: 47.335090637207145
    },
    {
        lng: 9.597702026367188,
        lat: 47.33404541015625
    },
    {
        lng: 9.597208976745605,
        lat: 47.33314514160162
    },
    {
        lng: 9.597052574157829,
        lat: 47.332553863525334
    },
    {
        lng: 9.596925735473746,
        lat: 47.33179473876976
    },
    {
        lng: 9.596796989440861,
        lat: 47.331027984619084
    },
    {
        lng: 9.596635818481445,
        lat: 47.33046722412132
    },
    {
        lng: 9.596383094787598,
        lat: 47.32994079589855
    },
    {
        lng: 9.595932960510368,
        lat: 47.32922363281244
    },
    {
        lng: 9.59570121765148,
        lat: 47.32885360717779
    },
    {
        lng: 9.595295906067008,
        lat: 47.3277854919433
    },
    {
        lng: 9.59492015838623,
        lat: 47.32679748535156
    },
    {
        lng: 9.594699859619254,
        lat: 47.32644271850597
    },
    {
        lng: 9.59423923492443,
        lat: 47.32569885253929
    },
    {
        lng: 9.593961715698356,
        lat: 47.325107574463004
    },
    {
        lng: 9.593647956848145,
        lat: 47.3236618041995
    },
    {
        lng: 9.593402862548828,
        lat: 47.322528839111555
    },
    {
        lng: 9.592911720275993,
        lat: 47.32157135009777
    },
    {
        lng: 9.592371940612907,
        lat: 47.32051849365257
    },
    {
        lng: 9.59217548370367,
        lat: 47.31970977783203
    },
    {
        lng: 9.591855049133414,
        lat: 47.31838607788097
    },
    {
        lng: 9.591723442077694,
        lat: 47.318119049072266
    },
    {
        lng: 9.591364860534725,
        lat: 47.31770324707037
    },
    {
        lng: 9.590786933898926,
        lat: 47.31725692749035
    },
    {
        lng: 9.59046649932867,
        lat: 47.316955566406364
    },
    {
        lng: 9.590096473693848,
        lat: 47.316608428955305
    },
    {
        lng: 9.589321136474723,
        lat: 47.31600952148449
    },
    {
        lng: 9.588356971740666,
        lat: 47.31449127197277
    },
    {
        lng: 9.588315963745117,
        lat: 47.314426422119254
    },
    {
        lng: 9.586807250976506,
        lat: 47.313179016113395
    },
    {
        lng: 9.58593654632574,
        lat: 47.31263732910156
    },
    {
        lng: 9.58517169952404,
        lat: 47.3120231628418
    },
    {
        lng: 9.583396911621094,
        lat: 47.310955047607536
    },
    {
        lng: 9.581966400146484,
        lat: 47.3105010986331
    },
    {
        lng: 9.581112861633358,
        lat: 47.31023025512707
    },
    {
        lng: 9.580308914184684,
        lat: 47.309711456299
    },
    {
        lng: 9.580256462097168,
        lat: 47.309677124023665
    },
    {
        lng: 9.579327583312988,
        lat: 47.30917739868187
    },
    {
        lng: 9.57870197296154,
        lat: 47.30875396728527
    },
    {
        lng: 9.578165054321346,
        lat: 47.308494567871094
    },
    {
        lng: 9.57646656036377,
        lat: 47.30802536010765
    },
    {
        lng: 9.574937820434627,
        lat: 47.30731964111345
    },
    {
        lng: 9.573860168457031,
        lat: 47.30704498291027
    },
    {
        lng: 9.57317924499523,
        lat: 47.30686950683622
    },
    {
        lng: 9.571596145629826,
        lat: 47.30614471435541
    },
    {
        lng: 9.570500373840446,
        lat: 47.305820465088175
    },
    {
        lng: 9.56991291046154,
        lat: 47.305644989013786
    },
    {
        lng: 9.569384574890137,
        lat: 47.30534744262695
    },
    {
        lng: 9.568525314331112,
        lat: 47.3045768737793
    },
    {
        lng: 9.56848239898693,
        lat: 47.30453872680664
    },
    {
        lng: 9.568424224853516,
        lat: 47.304485321045206
    },
    {
        lng: 9.566435813903809,
        lat: 47.30310440063482
    },
    {
        lng: 9.565067291259822,
        lat: 47.30236816406267
    },
    {
        lng: 9.564953804016227,
        lat: 47.302307128906364
    },
    {
        lng: 9.564605712890682,
        lat: 47.30212020874046
    },
    {
        lng: 9.564289093017635,
        lat: 47.301883697509936
    },
    {
        lng: 9.56419563293457,
        lat: 47.30181503295927
    },
    {
        lng: 9.56378078460699,
        lat: 47.30150604248058
    },
    {
        lng: 9.563244819641227,
        lat: 47.30118942260765
    },
    {
        lng: 9.562886238098088,
        lat: 47.300975799560774
    },
    {
        lng: 9.562493324279842,
        lat: 47.30066299438488
    },
    {
        lng: 9.562123298644963,
        lat: 47.300369262695426
    },
    {
        lng: 9.561773300170842,
        lat: 47.3001594543457
    },
    {
        lng: 9.561241149902287,
        lat: 47.2998428344726
    },
    {
        lng: 9.560477256774902,
        lat: 47.29923629760742
    },
    {
        lng: 9.559602737426758,
        lat: 47.29870605468744
    },
    {
        lng: 9.558105468750057,
        lat: 47.29744720459007
    },
    {
        lng: 9.557927131652775,
        lat: 47.297206878662166
    },
    {
        lng: 9.5577011108399,
        lat: 47.296653747558594
    },
    {
        lng: 9.557451248169059,
        lat: 47.29543685913109
    },
    {
        lng: 9.557386398315373,
        lat: 47.29512023925798
    },
    {
        lng: 9.557174682617244,
        lat: 47.294502258300724
    },
    {
        lng: 9.556568145751896,
        lat: 47.29347610473633
    },
    {
        lng: 9.556356430053768,
        lat: 47.29311752319353
    },
    {
        lng: 9.556002616882324,
        lat: 47.292175292968864
    },
    {
        lng: 9.555581092834473,
        lat: 47.29105377197277
    },
    {
        lng: 9.55472183227539,
        lat: 47.289680480957145
    },
    {
        lng: 9.553942680358887,
        lat: 47.28762054443371
    },
    {
        lng: 9.553083419799862,
        lat: 47.286262512207145
    },
    {
        lng: 9.55238342285162,
        lat: 47.284648895263956
    },
    {
        lng: 9.551380157470817,
        lat: 47.28353500366205
    },
    {
        lng: 9.550893783569279,
        lat: 47.282585144043196
    },
    {
        lng: 9.55058574676525,
        lat: 47.282131195068416
    },
    {
        lng: 9.549833297729549,
        lat: 47.28147506713884
    },
    {
        lng: 9.548968315124512,
        lat: 47.280437469482536
    },
    {
        lng: 9.547697067260742,
        lat: 47.279083251953125
    },
    {
        lng: 9.547009468078727,
        lat: 47.27856063842785
    },
    {
        lng: 9.545285224914664,
        lat: 47.27777481079113
    },
    {
        lng: 9.544288635253906,
        lat: 47.277469635009936
    },
    {
        lng: 9.542798995971737,
        lat: 47.27716827392578
    },
    {
        lng: 9.542593002319393,
        lat: 47.27675628662121
    },
    {
        lng: 9.542305946350154,
        lat: 47.276374816894645
    },
    {
        lng: 9.540888786316032,
        lat: 47.27533721923851
    },
    {
        lng: 9.540348052978459,
        lat: 47.27507019042969
    },
    {
        lng: 9.540102958679313,
        lat: 47.27500534057617
    },
    {
        lng: 9.53898811340332,
        lat: 47.27470779418957
    },
    {
        lng: 9.53835487365734,
        lat: 47.274482727050724
    },
    {
        lng: 9.53653430938732,
        lat: 47.27343368530268
    },
    {
        lng: 9.535688400268555,
        lat: 47.27283096313505
    },
    {
        lng: 9.533991813659782,
        lat: 47.27193832397478
    },
    {
        lng: 9.533723831176815,
        lat: 47.27172851562506
    },
    {
        lng: 9.533184051513729,
        lat: 47.27130126953125
    },
    {
        lng: 9.53242397308344,
        lat: 47.270538330078125
    },
    {
        lng: 9.531498908996639,
        lat: 47.269851684570426
    },
    {
        lng: 9.530624389648494,
        lat: 47.268863677978516
    },
    {
        lng: 9.531122207641715,
        lat: 47.26689910888672
    },
    {
        lng: 9.531178474426383,
        lat: 47.26639175415039
    },
    {
        lng: 9.53127193450922,
        lat: 47.26555633544939
    },
    {
        lng: 9.531309127807617,
        lat: 47.26321029663109
    },
    {
        lng: 9.531212806701717,
        lat: 47.26222610473633
    },
    {
        lng: 9.531055450439453,
        lat: 47.26159286499035
    },
    {
        lng: 9.530777931213379,
        lat: 47.26100540161161
    },
    {
        lng: 9.530091285705623,
        lat: 47.2599334716798
    },
    {
        lng: 9.529437065124625,
        lat: 47.2581901550293
    },
    {
        lng: 9.528420448303336,
        lat: 47.25658035278326
    },
    {
        lng: 9.527710914611816,
        lat: 47.2549934387207
    },
    {
        lng: 9.526695251464957,
        lat: 47.25387573242182
    },
    {
        lng: 9.525983810424862,
        lat: 47.2522811889649
    },
    {
        lng: 9.525094985961914,
        lat: 47.250965118408374
    },
    {
        lng: 9.524390220642204,
        lat: 47.249366760253906
    },
    {
        lng: 9.523406982421932,
        lat: 47.24822616577177
    },
    {
        lng: 9.522731781005916,
        lat: 47.24601364135742
    },
    {
        lng: 9.521755218505916,
        lat: 47.244873046875114
    },
    {
        lng: 9.521182060241756,
        lat: 47.24355316162138
    },
    {
        lng: 9.520858764648438,
        lat: 47.243019104003906
    },
    {
        lng: 9.51953506469738,
        lat: 47.24142074584978
    },
    {
        lng: 9.518751144409237,
        lat: 47.24076843261719
    },
    {
        lng: 9.518397331237736,
        lat: 47.240390777588004
    },
    {
        lng: 9.517757415771541,
        lat: 47.239215850830135
    },
    {
        lng: 9.516718864440918,
        lat: 47.238151550293196
    },
    {
        lng: 9.516075134277344,
        lat: 47.236991882324446
    },
    {
        lng: 9.515700340270996,
        lat: 47.236625671386946
    },
    {
        lng: 9.515080451965332,
        lat: 47.2362060546875
    },
    {
        lng: 9.514481544494743,
        lat: 47.23569107055664
    },
    {
        lng: 9.514350891113281,
        lat: 47.23558044433594
    },
    {
        lng: 9.513540267944336,
        lat: 47.235000610351506
    },
    {
        lng: 9.513211250305233,
        lat: 47.23456954956055
    },
    {
        lng: 9.512714385986328,
        lat: 47.23362731933622
    },
    {
        lng: 9.511678695678768,
        lat: 47.23255920410173
    },
    {
        lng: 9.51103687286377,
        lat: 47.231395721435604
    },
    {
        lng: 9.510663986206055,
        lat: 47.23102951049816
    },
    {
        lng: 9.508508682250977,
        lat: 47.22941207885759
    },
    {
        lng: 9.50817680358881,
        lat: 47.228984832763615
    },
    {
        lng: 9.507658958435059,
        lat: 47.22805786132818
    },
    {
        lng: 9.507305145263786,
        lat: 47.22768402099615
    },
    {
        lng: 9.506517410278434,
        lat: 47.22703933715832
    },
    {
        lng: 9.505125999450797,
        lat: 47.22546386718744
    },
    {
        lng: 9.503519058227539,
        lat: 47.22320556640642
    },
    {
        lng: 9.50312519073492,
        lat: 47.22230529785179
    },
    {
        lng: 9.502681732177791,
        lat: 47.22005844116228
    },
    {
        lng: 9.501627922058105,
        lat: 47.21808624267578
    },
    {
        lng: 9.501094818115234,
        lat: 47.215522766113395
    },
    {
        lng: 9.5000257492066,
        lat: 47.21355056762695
    },
    {
        lng: 9.499633789062443,
        lat: 47.21169662475586
    },
    {
        lng: 9.499421119690055,
        lat: 47.21115112304682
    },
    {
        lng: 9.499258041381836,
        lat: 47.21091079711931
    },
    {
        lng: 9.498561859130973,
        lat: 47.21026229858427
    },
    {
        lng: 9.497783660888786,
        lat: 47.208881378173885
    },
    {
        lng: 9.49692535400402,
        lat: 47.20802307128912
    },
    {
        lng: 9.49676609039318,
        lat: 47.207786560058594
    },
    {
        lng: 9.49655914306652,
        lat: 47.207248687744084
    },
    {
        lng: 9.496218681335563,
        lat: 47.20580673217796
    },
    {
        lng: 9.495942115783748,
        lat: 47.205303192138956
    },
    {
        lng: 9.495091438293514,
        lat: 47.20444107055664
    },
    {
        lng: 9.494608879089355,
        lat: 47.203491210937784
    },
    {
        lng: 9.494302749633903,
        lat: 47.203052520751896
    },
    {
        lng: 9.493412971496696,
        lat: 47.20221328735357
    },
    {
        lng: 9.492635726928768,
        lat: 47.20082473754883
    },
    {
        lng: 9.491748809814453,
        lat: 47.19996643066406
    },
    {
        lng: 9.491238594055176,
        lat: 47.19896697998047
    },
    {
        lng: 9.490899085998535,
        lat: 47.1984558105471
    },
    {
        lng: 9.49057865142828,
        lat: 47.19814682006836
    },
    {
        lng: 9.490358352661133,
        lat: 47.19798278808605
    },
    {
        lng: 9.489740371704215,
        lat: 47.19752502441429
    },
    {
        lng: 9.489684104919377,
        lat: 47.19626617431652
    },
    {
        lng: 9.489552497863826,
        lat: 47.1953697204591
    },
    {
        lng: 9.489540100097656,
        lat: 47.19528198242199
    },
    {
        lng: 9.489464759826717,
        lat: 47.195083618164176
    },
    {
        lng: 9.488494873046932,
        lat: 47.19251632690441
    },
    {
        lng: 9.488334655761832,
        lat: 47.19186782836937
    },
    {
        lng: 9.48822116851801,
        lat: 47.19073104858421
    },
    {
        lng: 9.487996101379395,
        lat: 47.18848037719732
    },
    {
        lng: 9.48753929138195,
        lat: 47.18730926513666
    },
    {
        lng: 9.486914634704647,
        lat: 47.185714721679744
    },
    {
        lng: 9.486691474914608,
        lat: 47.18476486206055
    },
    {
        lng: 9.486401557922306,
        lat: 47.18183898925781
    },
    {
        lng: 9.48683071136469,
        lat: 47.17811965942383
    },
    {
        lng: 9.487024307251033,
        lat: 47.177101135254134
    },
    {
        lng: 9.488446235656852,
        lat: 47.17281723022484
    },
    {
        lng: 9.48907661437994,
        lat: 47.17021179199219
    },
    {
        lng: 9.490234375000114,
        lat: 47.16817092895508
    },
    {
        lng: 9.490986824035588,
        lat: 47.16523742675781
    },
    {
        lng: 9.492718696594238,
        lat: 47.16134643554716
    },
    {
        lng: 9.49304485321045,
        lat: 47.160858154296875
    },
    {
        lng: 9.493952751159782,
        lat: 47.15993118286144
    },
    {
        lng: 9.494672775268555,
        lat: 47.158729553222656
    },
    {
        lng: 9.495780944824332,
        lat: 47.1576004028322
    },
    {
        lng: 9.496502876281681,
        lat: 47.15640258789074
    },
    {
        lng: 9.49760723114008,
        lat: 47.15527343749994
    },
    {
        lng: 9.49849987030035,
        lat: 47.153846740722884
    },
    {
        lng: 9.4994153976441,
        lat: 47.15290069580084
    },
    {
        lng: 9.500234603881893,
        lat: 47.151264190674055
    },
    {
        lng: 9.501190185546875,
        lat: 47.149898529052734
    },
    {
        lng: 9.502038002014217,
        lat: 47.14825820922863
    },
    {
        lng: 9.50330734252941,
        lat: 47.14697647094721
    },
    {
        lng: 9.504158973693904,
        lat: 47.1454963684082
    },
    {
        lng: 9.505089759826717,
        lat: 47.14477539062494
    },
    {
        lng: 9.50655460357666,
        lat: 47.143905639648665
    },
    {
        lng: 9.508357048034668,
        lat: 47.142646789551065
    },
    {
        lng: 9.5100612640382,
        lat: 47.14085769653332
    },
    {
        lng: 9.511313438415527,
        lat: 47.139144897461165
    },
    {
        lng: 9.511962890625057,
        lat: 47.1382598876956
    },
    {
        lng: 9.512891769409293,
        lat: 47.136184692382926
    },
    {
        lng: 9.513764381408635,
        lat: 47.13478088378912
    },
    {
        lng: 9.514041900634709,
        lat: 47.1340789794923
    },
    {
        lng: 9.514636039733887,
        lat: 47.13191604614258
    },
    {
        lng: 9.51578235626215,
        lat: 47.129814147949446
    },
    {
        lng: 9.516442298889103,
        lat: 47.12727355957048
    },
    {
        lng: 9.516649246215763,
        lat: 47.12678146362305
    },
    {
        lng: 9.517794609069938,
        lat: 47.124065399170036
    },
    {
        lng: 9.518378257751522,
        lat: 47.12149047851574
    },
    {
        lng: 9.51886177062994,
        lat: 47.120033264160156
    },
    {
        lng: 9.519094467163086,
        lat: 47.118587493896655
    },
    {
        lng: 9.51914024353033,
        lat: 47.11673355102562
    },
    {
        lng: 9.518926620483455,
        lat: 47.11526107788097
    },
    {
        lng: 9.518509864807243,
        lat: 47.11415863037132
    },
    {
        lng: 9.518481254577694,
        lat: 47.11408233642578
    },
    {
        lng: 9.518539428710938,
        lat: 47.1140022277832
    },
    {
        lng: 9.519659996032715,
        lat: 47.11244201660179
    },
    {
        lng: 9.520540237426758,
        lat: 47.11085510253906
    },
    {
        lng: 9.521401405334416,
        lat: 47.10955047607422
    },
    {
        lng: 9.52165412902832,
        lat: 47.108882904052734
    },
    {
        lng: 9.522190093994254,
        lat: 47.106475830078296
    },
    {
        lng: 9.52326774597168,
        lat: 47.10381317138689
    },
    {
        lng: 9.523428916931266,
        lat: 47.10289382934582
    },
    {
        lng: 9.523488998413086,
        lat: 47.10192108154297
    },
    {
        lng: 9.523468971252555,
        lat: 47.09577178955078
    },
    {
        lng: 9.523332595825138,
        lat: 47.09418869018555
    },
    {
        lng: 9.523034095764217,
        lat: 47.093280792236555
    },
    {
        lng: 9.522146224975586,
        lat: 47.09194183349604
    },
    {
        lng: 9.521457672119254,
        lat: 47.090221405029524
    },
    {
        lng: 9.521141052246207,
        lat: 47.08966827392578
    },
    {
        lng: 9.520397186279297,
        lat: 47.088619232177734
    },
    {
        lng: 9.520297050476017,
        lat: 47.08840179443382
    },
    {
        lng: 9.519667625427246,
        lat: 47.08703994750988
    },
    {
        lng: 9.518619537353459,
        lat: 47.08595657348633
    },
    {
        lng: 9.518121719360408,
        lat: 47.085029602050895
    },
    {
        lng: 9.517786979675407,
        lat: 47.08459472656273
    },
    {
        lng: 9.516198158264274,
        lat: 47.08340072631847
    },
    {
        lng: 9.515258789062614,
        lat: 47.08288955688471
    },
    {
        lng: 9.514374732971191,
        lat: 47.08230590820335
    },
    {
        lng: 9.510472297668457,
        lat: 47.0807456970216
    },
    {
        lng: 9.509965896606559,
        lat: 47.08047485351591
    },
    {
        lng: 9.509322166442985,
        lat: 47.0800399780274
    },
    {
        lng: 9.508352279663029,
        lat: 47.079547882080305
    },
    {
        lng: 9.507463455200309,
        lat: 47.079013824463004
    },
    {
        lng: 9.50507736206049,
        lat: 47.07834243774431
    },
    {
        lng: 9.504186630249023,
        lat: 47.07780838012718
    },
    {
        lng: 9.503214836120662,
        lat: 47.07732391357422
    },
    {
        lng: 9.50233173370367,
        lat: 47.076744079589844
    },
    {
        lng: 9.498161315917969,
        lat: 47.07508087158209
    },
    {
        lng: 9.497279167175293,
        lat: 47.07450485229492
    },
    {
        lng: 9.49631118774414,
        lat: 47.07402038574213
    },
    {
        lng: 9.495417594909725,
        lat: 47.07349014282238
    },
    {
        lng: 9.49475002288824,
        lat: 47.07327651977545
    },
    {
        lng: 9.493304252624569,
        lat: 47.0729675292971
    },
    {
        lng: 9.491657257080135,
        lat: 47.07229995727562
    },
    {
        lng: 9.489826202392578,
        lat: 47.071918487549055
    },
    {
        lng: 9.488174438476676,
        lat: 47.07125473022455
    },
    {
        lng: 9.486343383789062,
        lat: 47.07087707519554
    },
    {
        lng: 9.484686851501579,
        lat: 47.070220947265625
    },
    {
        lng: 9.482848167419434,
        lat: 47.06986236572283
    },
    {
        lng: 9.48119258880621,
        lat: 47.069225311279524
    },
    {
        lng: 9.479720115661735,
        lat: 47.068950653076286
    },
    {
        lng: 9.479024887084961,
        lat: 47.068767547607365
    },
    {
        lng: 9.47803020477295,
        lat: 47.06827926635765
    },
    {
        lng: 9.475933074951172,
        lat: 47.067523956299055
    },
    {
        lng: 9.474905014038143,
        lat: 47.066997528076286
    },
    {
        lng: 9.473756790161133,
        lat: 47.06640625
    },
    {
        lng: 9.472725868225211,
        lat: 47.06413650512707
    },
    {
        lng: 9.472480773925838,
        lat: 47.06271362304699
    },
    {
        lng: 9.472598075866813,
        lat: 47.061256408691406
    },
    {
        lng: 9.473022460937557,
        lat: 47.059799194336165
    },
    {
        lng: 9.473479270935002,
        lat: 47.057628631591854
    },
    {
        lng: 9.473747253417969,
        lat: 47.056919097900675
    },
    {
        lng: 9.475506782531852,
        lat: 47.05393981933605
    },
    {
        lng: 9.476121902465877,
        lat: 47.05338287353544
    },
    {
        lng: 9.47652721405035,
        lat: 47.053153991699276
    },
    {
        lng: 9.477802276611271,
        lat: 47.052715301513956
    },
    {
        lng: 9.479228973388729,
        lat: 47.053134918212834
    },
    {
        lng: 9.480647087097168,
        lat: 47.05337142944336
    },
    {
        lng: 9.481624603271484,
        lat: 47.05337142944336
    },
    {
        lng: 9.482522964477482,
        lat: 47.05311965942394
    },
    {
        lng: 9.483136177063102,
        lat: 47.05265426635765
    },
    {
        lng: 9.483546257019043,
        lat: 47.0520973205567
    },
    {
        lng: 9.484291076660213,
        lat: 47.050296783447266
    },
    {
        lng: 9.484723091125488,
        lat: 47.0497856140139
    },
    {
        lng: 9.48548698425293,
        lat: 47.04918670654325
    },
    {
        lng: 9.486117362976188,
        lat: 47.04881668090843
    },
    {
        lng: 9.486986160278377,
        lat: 47.04861450195341
    },
    {
        lng: 9.487909317016602,
        lat: 47.0486297607423
    },
    {
        lng: 9.488775253295898,
        lat: 47.04887771606457
    },
    {
        lng: 9.489390373230094,
        lat: 47.04932403564476
    },
    {
        lng: 9.490390777587834,
        lat: 47.050472259521484
    },
    {
        lng: 9.49141979217535,
        lat: 47.052078247070256
    },
    {
        lng: 9.492628097534237,
        lat: 47.05357360839872
    },
    {
        lng: 9.493305206298828,
        lat: 47.054058074951456
    },
    {
        lng: 9.493761062622013,
        lat: 47.054237365722656
    },
    {
        lng: 9.494722366332951,
        lat: 47.05437850952171
    },
    {
        lng: 9.495688438415584,
        lat: 47.05429458618164
    },
    {
        lng: 9.49693775177002,
        lat: 47.053966522217024
    },
    {
        lng: 9.498210906982365,
        lat: 47.0533332824707
    },
    {
        lng: 9.498941421508789,
        lat: 47.05309295654297
    },
    {
        lng: 9.50018405914318,
        lat: 47.052879333496264
    },
    {
        lng: 9.500997543335018,
        lat: 47.05295181274414
    },
    {
        lng: 9.501641273498592,
        lat: 47.053230285644474
    },
    {
        lng: 9.502702713012809,
        lat: 47.05421829223644
    },
    {
        lng: 9.50307655334484,
        lat: 47.05438995361328
    },
    {
        lng: 9.503999710083065,
        lat: 47.05461883544922
    },
    {
        lng: 9.505618095397892,
        lat: 47.054779052734375
    },
    {
        lng: 9.509734153747559,
        lat: 47.05490875244169
    },
    {
        lng: 9.511492729187125,
        lat: 47.0550231933596
    },
    {
        lng: 9.514702796936149,
        lat: 47.05552673339844
    },
    {
        lng: 9.515695571899357,
        lat: 47.05574417114269
    },
    {
        lng: 9.516166687011776,
        lat: 47.05589294433588
    },
    {
        lng: 9.517050743103084,
        lat: 47.056304931640625
    },
    {
        lng: 9.517761230468693,
        lat: 47.05679702758806
    },
    {
        lng: 9.518556594848576,
        lat: 47.05748748779297
    },
    {
        lng: 9.52037525177002,
        lat: 47.05861282348644
    },
    {
        lng: 9.5211763381958,
        lat: 47.058898925781364
    },
    {
        lng: 9.525044441223201,
        lat: 47.059913635253906
    },
    {
        lng: 9.526580810546875,
        lat: 47.060150146484375
    },
    {
        lng: 9.530340194702148,
        lat: 47.06048202514671
    },
    {
        lng: 9.533588409423885,
        lat: 47.060863494873274
    },
    {
        lng: 9.534333229065055,
        lat: 47.06119918823242
    },
    {
        lng: 9.536281585693416,
        lat: 47.061851501465014
    },
    {
        lng: 9.537259101867733,
        lat: 47.06236648559593
    },
    {
        lng: 9.539322853088322,
        lat: 47.06296920776373
    },
    {
        lng: 9.540588378906364,
        lat: 47.06352996826166
    },
    {
        lng: 9.54129505157482,
        lat: 47.06369018554716
    },
    {
        lng: 9.542059898376522,
        lat: 47.06373596191406
    },
    {
        lng: 9.542805671692008,
        lat: 47.06367111206055
    },
    {
        lng: 9.543148040771541,
        lat: 47.06359481811552
    },
    {
        lng: 9.543462753295842,
        lat: 47.06346893310558
    },
    {
        lng: 9.54441070556652,
        lat: 47.06309127807634
    },
    {
        lng: 9.546129226684627,
        lat: 47.062675476074446
    },
    {
        lng: 9.546678543090877,
        lat: 47.0624351501466
    },
    {
        lng: 9.547319412231559,
        lat: 47.062042236328296
    },
    {
        lng: 9.549157142639217,
        lat: 47.06108474731457
    },
    {
        lng: 9.551497459411564,
        lat: 47.06046295166027
    },
    {
        lng: 9.551954269409237,
        lat: 47.06022262573259
    },
    {
        lng: 9.553329467773494,
        lat: 47.05950546264671
    },
    {
        lng: 9.554951667785701,
        lat: 47.058368682861555
    },
    {
        lng: 9.555290222168082,
        lat: 47.05798721313499
    },
    {
        lng: 9.555791854858455,
        lat: 47.05713272094721
    },
    {
        lng: 9.556075096130314,
        lat: 47.056316375732536
    },
    {
        lng: 9.556622505187988,
        lat: 47.05375289916992
    },
    {
        lng: 9.55679702758789,
        lat: 47.05070877075195
    },
    {
        lng: 9.556989669799748,
        lat: 47.05012893676758
    },
    {
        lng: 9.557161331176758,
        lat: 47.04986953735363
    },
    {
        lng: 9.55850696563715,
        lat: 47.04872512817394
    },
    {
        lng: 9.559151649475211,
        lat: 47.048309326172046
    },
    {
        lng: 9.559906959533805,
        lat: 47.04797363281273
    },
    {
        lng: 9.56159782409668,
        lat: 47.04738235473661
    },
    {
        lng: 9.563658714294547,
        lat: 47.04853057861351
    },
    {
        lng: 9.566117286682186,
        lat: 47.0495223999024
    },
    {
        lng: 9.568143844604549,
        lat: 47.050464630126896
    },
    {
        lng: 9.57201290130621,
        lat: 47.05177688598644
    },
    {
        lng: 9.573045730590877,
        lat: 47.0519714355471
    },
    {
        lng: 9.576245307922306,
        lat: 47.05228805541998
    },
    {
        lng: 9.578428268432617,
        lat: 47.05258941650396
    },
    {
        lng: 9.583744049072322,
        lat: 47.052978515625114
    },
    {
        lng: 9.586412429809684,
        lat: 47.05354690551775
    },
    {
        lng: 9.588957786560115,
        lat: 47.054328918457145
    },
    {
        lng: 9.591348648071346,
        lat: 47.054843902587834
    },
    {
        lng: 9.593443870544377,
        lat: 47.05620193481445
    },
    {
        lng: 9.594378471374512,
        lat: 47.056922912597884
    },
    {
        lng: 9.59522342681879,
        lat: 47.057361602783374
    },
    {
        lng: 9.596026420593205,
        lat: 47.0575981140139
    },
    {
        lng: 9.59813213348383,
        lat: 47.058002471924055
    },
    {
        lng: 9.599795341491813,
        lat: 47.05847167968773
    },
    {
        lng: 9.60040187835699,
        lat: 47.05870056152355
    },
    {
        lng: 9.601762771606445,
        lat: 47.05936431884783
    },
    {
        lng: 9.6035413742066,
        lat: 47.05989837646479
    },
    {
        lng: 9.605137825012264,
        lat: 47.06075668334955
    },
    {
        lng: 9.6067533493042,
        lat: 47.061408996582145
    },
    {
        lng: 9.606903076171932,
        lat: 47.06145477294922
    },
    {
        lng: 9.607842445373535,
        lat: 47.06173324584961
    },
    {
        lng: 9.608528137207145,
        lat: 47.0617294311524
    },
    {
        lng: 9.608757019042969,
        lat: 47.06169509887707
    },
    {
        lng: 9.609284400940055,
        lat: 47.061508178711165
    },
    {
        lng: 9.610684394836483,
        lat: 47.060611724853686
    },
    {
        lng: 9.611675262451286,
        lat: 47.05984497070324
    },
    {
        lng: 9.615105628967285,
        lat: 47.058097839355696
    },
    {
        lng: 9.61670112609869,
        lat: 47.056838989257926
    },
    {
        lng: 9.61724662780773,
        lat: 47.056221008300895
    },
    {
        lng: 9.617585182189941,
        lat: 47.055835723877124
    },
    {
        lng: 9.618352890014705,
        lat: 47.05520629882835
    },
    {
        lng: 9.619642257690487,
        lat: 47.0544700622558
    },
    {
        lng: 9.620745658874569,
        lat: 47.05371093750023
    },
    {
        lng: 9.622980117797908,
        lat: 47.05275344848627
    },
    {
        lng: 9.62416744232172,
        lat: 47.052433013916016
    },
    {
        lng: 9.627263069152889,
        lat: 47.05187606811546
    },
    {
        lng: 9.632134437561092,
        lat: 47.05112457275385
    },
    {
        lng: 9.633834838867244,
        lat: 47.05104446411133
    },
    {
        lng: 9.634941101074276,
        lat: 47.05099105834972
    },
    {
        lng: 9.638179779052848,
        lat: 47.05104827880865
    },
    {
        lng: 9.639739036560115,
        lat: 47.051639556884936
    },
    {
        lng: 9.641791343689022,
        lat: 47.05229187011719
    },
    {
        lng: 9.643774986267204,
        lat: 47.05329132080078
    },
    {
        lng: 9.64448165893566,
        lat: 47.05371856689459
    },
    {
        lng: 9.644957542419377,
        lat: 47.05411529541021
    },
    {
        lng: 9.645080566406307,
        lat: 47.05421829223644
    },
    {
        lng: 9.646401405334473,
        lat: 47.055564880371094
    },
    {
        lng: 9.64727783203125,
        lat: 47.05707168579107
    },
    {
        lng: 9.647951126098633,
        lat: 47.05762481689453
    },
    {
        lng: 9.648662567138786,
        lat: 47.05820846557623
    },
    {
        lng: 9.649387359619197,
        lat: 47.05849075317383
    },
    {
        lng: 9.650235176086369,
        lat: 47.058551788330305
    },
    {
        lng: 9.652556419372672,
        lat: 47.058444976806925
    },
    {
        lng: 9.655085563659725,
        lat: 47.05822372436535
    },
    {
        lng: 9.657084465026855,
        lat: 47.05792236328131
    },
    {
        lng: 9.65810298919672,
        lat: 47.05776977539091
    },
    {
        lng: 9.659626007080078,
        lat: 47.0576820373538
    },
    {
        lng: 9.661140441894645,
        lat: 47.057712554931584
    },
    {
        lng: 9.662850379943904,
        lat: 47.05786895751953
    },
    {
        lng: 9.666603088378963,
        lat: 47.05821228027344
    },
    {
        lng: 9.666714668273926,
        lat: 47.05823898315441
    },
    {
        lng: 9.669474601745605,
        lat: 47.05885696411127
    },
    {
        lng: 9.672904968261776,
        lat: 47.05923461914085
    },
    {
        lng: 9.675770759582633,
        lat: 47.0599441528322
    },
    {
        lng: 9.675933837890625,
        lat: 47.059967041015625
    },
    {
        lng: 9.67770767211914,
        lat: 47.060237884521484
    },
    {
        lng: 9.679183959960938,
        lat: 47.06046295166027
    },
    {
        lng: 9.680235862731877,
        lat: 47.06053543090849
    },
    {
        lng: 9.681286811828727,
        lat: 47.06049346923828
    },
    {
        lng: 9.682300567626953,
        lat: 47.06031799316423
    },
    {
        lng: 9.68277645111084,
        lat: 47.060157775878906
    },
    {
        lng: 9.683564186096191,
        lat: 47.059692382812614
    },
    {
        lng: 9.684244155883846,
        lat: 47.059001922607536
    },
    {
        lng: 9.684895515441951,
        lat: 47.05813980102539
    },
    {
        lng: 9.685388565063533,
        lat: 47.05748748779297
    },
    {
        lng: 9.686049461364803,
        lat: 47.056907653808594
    },
    {
        lng: 9.686833381652946,
        lat: 47.05639648437506
    },
    {
        lng: 9.687697410583496,
        lat: 47.05600357055664
    },
    {
        lng: 9.68952846527111,
        lat: 47.055412292480696
    },
    {
        lng: 9.69088363647461,
        lat: 47.05497360229498
    },
    {
        lng: 9.694964408874625,
        lat: 47.05347061157238
    },
    {
        lng: 9.695949554443473,
        lat: 47.05310821533226
    },
    {
        lng: 9.697847366332951,
        lat: 47.052608489990234
    },
    {
        lng: 9.699416160583553,
        lat: 47.052352905273665
    },
    {
        lng: 9.701058387756461,
        lat: 47.052261352539176
    },
    {
        lng: 9.701169013976994,
        lat: 47.052261352539176
    },
    {
        lng: 9.701699256896973,
        lat: 47.052253723144645
    },
    {
        lng: 9.706507682800293,
        lat: 47.05220031738287
    },
    {
        lng: 9.707490921020565,
        lat: 47.05208587646479
    },
    {
        lng: 9.708374977111816,
        lat: 47.051822662353516
    },
    {
        lng: 9.708760261535758,
        lat: 47.05158996582054
    },
    {
        lng: 9.709212303161735,
        lat: 47.05104827880865
    },
    {
        lng: 9.709426879882926,
        lat: 47.05043029785156
    },
    {
        lng: 9.709445953369197,
        lat: 47.05033874511747
    },
    {
        lng: 9.70969200134283,
        lat: 47.04915237426758
    },
    {
        lng: 9.70976734161377,
        lat: 47.04878997802746
    },
    {
        lng: 9.710209846496639,
        lat: 47.04782867431652
    },
    {
        lng: 9.710601806640625,
        lat: 47.04727935791044
    },
    {
        lng: 9.712228775024414,
        lat: 47.046062469482536
    },
    {
        lng: 9.714406013488826,
        lat: 47.0453147888183
    },
    {
        lng: 9.715703010559082,
        lat: 47.044715881347884
    },
    {
        lng: 9.717228889465332,
        lat: 47.044269561767805
    },
    {
        lng: 9.718939781189079,
        lat: 47.04354476928722
    },
    {
        lng: 9.722675323486442,
        lat: 47.042823791504134
    },
    {
        lng: 9.726261138916072,
        lat: 47.042495727539176
    },
    {
        lng: 9.728842735290641,
        lat: 47.04216384887718
    },
    {
        lng: 9.731570243835506,
        lat: 47.042003631591854
    },
    {
        lng: 9.733569145202637,
        lat: 47.04188919067383
    },
    {
        lng: 9.736166954040527,
        lat: 47.041580200195426
    },
    {
        lng: 9.740907669067496,
        lat: 47.04135894775402
    },
    {
        lng: 9.743829727172795,
        lat: 47.040859222412166
    },
    {
        lng: 9.744645118713436,
        lat: 47.0406036376956
    },
    {
        lng: 9.744985580444393,
        lat: 47.040412902832315
    },
    {
        lng: 9.745441436767578,
        lat: 47.03991699218767
    },
    {
        lng: 9.746055603027287,
        lat: 47.038887023926065
    },
    {
        lng: 9.746636390686149,
        lat: 47.038330078125114
    },
    {
        lng: 9.7466917037965,
        lat: 47.03827667236334
    },
    {
        lng: 9.747408866882381,
        lat: 47.03759002685541
    },
    {
        lng: 9.748210906982536,
        lat: 47.036998748779524
    },
    {
        lng: 9.748965263366813,
        lat: 47.03631210327177
    },
    {
        lng: 9.749715805053768,
        lat: 47.03588104248047
    },
    {
        lng: 9.750602722168082,
        lat: 47.03562545776384
    },
    {
        lng: 9.753598213195914,
        lat: 47.03515243530285
    },
    {
        lng: 9.755187034606877,
        lat: 47.035018920898665
    },
    {
        lng: 9.760732650756836,
        lat: 47.035030364990234
    },
    {
        lng: 9.763382911682186,
        lat: 47.035202026367415
    },
    {
        lng: 9.764752388000488,
        lat: 47.03553390502941
    },
    {
        lng: 9.765789031982536,
        lat: 47.03607940673828
    },
    {
        lng: 9.766957283020133,
        lat: 47.03660964965843
    },
    {
        lng: 9.767143249511776,
        lat: 47.03669357299833
    },
    {
        lng: 9.769864082336426,
        lat: 47.03792572021496
    },
    {
        lng: 9.77088260650629,
        lat: 47.03844833374018
    },
    {
        lng: 9.771759033203239,
        lat: 47.03866958618181
    },
    {
        lng: 9.7727308273316,
        lat: 47.03874588012701
    },
    {
        lng: 9.773736000060978,
        lat: 47.038707733154524
    },
    {
        lng: 9.779526710510254,
        lat: 47.03804397583019
    },
    {
        lng: 9.783296585082951,
        lat: 47.03778457641624
    },
    {
        lng: 9.784234046935978,
        lat: 47.03759765625
    },
    {
        lng: 9.788413047790527,
        lat: 47.036392211914176
    },
    {
        lng: 9.789837837219352,
        lat: 47.035205841064396
    },
    {
        lng: 9.791116714477482,
        lat: 47.0339241027832
    },
    {
        lng: 9.791150093078727,
        lat: 47.03390121459978
    },
    {
        lng: 9.791967391967887,
        lat: 47.03336715698265
    },
    {
        lng: 9.792697906494084,
        lat: 47.0327606201173
    },
    {
        lng: 9.793549537658805,
        lat: 47.03225326538109
    },
    {
        lng: 9.794294357299805,
        lat: 47.03166961669916
    },
    {
        lng: 9.795317649841309,
        lat: 47.03108978271496
    },
    {
        lng: 9.79582691192627,
        lat: 47.030799865722884
    },
    {
        lng: 9.796392440795898,
        lat: 47.03055953979498
    },
    {
        lng: 9.797090530395508,
        lat: 47.03041839599632
    },
    {
        lng: 9.798584938049316,
        lat: 47.03012084960966
    },
    {
        lng: 9.79910945892334,
        lat: 47.03001785278349
    },
    {
        lng: 9.801646232604924,
        lat: 47.029205322265625
    },
    {
        lng: 9.802323341369743,
        lat: 47.02893066406256
    },
    {
        lng: 9.803232192993221,
        lat: 47.02835845947271
    },
    {
        lng: 9.80421257019043,
        lat: 47.02742767333996
    },
    {
        lng: 9.805059432983455,
        lat: 47.0268669128418
    },
    {
        lng: 9.805791854858455,
        lat: 47.0262565612793
    },
    {
        lng: 9.806462287902775,
        lat: 47.025856018066634
    },
    {
        lng: 9.806650161743221,
        lat: 47.02574539184593
    },
    {
        lng: 9.807150840759334,
        lat: 47.0253448486331
    },
    {
        lng: 9.807394027710075,
        lat: 47.025150299072266
    },
    {
        lng: 9.808257102966309,
        lat: 47.02464294433594
    },
    {
        lng: 9.80899715423584,
        lat: 47.02404785156244
    },
    {
        lng: 9.80984973907482,
        lat: 47.023532867431925
    },
    {
        lng: 9.810588836670036,
        lat: 47.02294540405285
    },
    {
        lng: 9.812367439269963,
        lat: 47.02194976806635
    },
    {
        lng: 9.812717437744084,
        lat: 47.021835327148665
    },
    {
        lng: 9.813517570495605,
        lat: 47.02169418334978
    },
    {
        lng: 9.81631946563732,
        lat: 47.02149200439459
    },
    {
        lng: 9.816696166992188,
        lat: 47.021465301513615
    },
    {
        lng: 9.820241928100586,
        lat: 47.02086257934599
    },
    {
        lng: 9.822246551513786,
        lat: 47.020576477051065
    },
    {
        lng: 9.822935104370117,
        lat: 47.020393371582315
    },
    {
        lng: 9.824206352233887,
        lat: 47.019847869873274
    },
    {
        lng: 9.8246107101441,
        lat: 47.019752502441634
    },
    {
        lng: 9.82588195800787,
        lat: 47.01944732666027
    },
    {
        lng: 9.82641506195074,
        lat: 47.01920318603521
    },
    {
        lng: 9.827913284301871,
        lat: 47.01830673217796
    },
    {
        lng: 9.828632354736385,
        lat: 47.01774978637718
    },
    {
        lng: 9.829548835754395,
        lat: 47.01721572875988
    },
    {
        lng: 9.830078125000057,
        lat: 47.01683425903332
    },
    {
        lng: 9.83036804199213,
        lat: 47.016624450683594
    },
    {
        lng: 9.831518173217773,
        lat: 47.01607131958019
    },
    {
        lng: 9.831851959228516,
        lat: 47.015911102295206
    },
    {
        lng: 9.833183288574332,
        lat: 47.015102386474666
    },
    {
        lng: 9.833751678466797,
        lat: 47.01475906372099
    },
    {
        lng: 9.834797859191951,
        lat: 47.0143203735351
    },
    {
        lng: 9.83811283111578,
        lat: 47.01321029663097
    },
    {
        lng: 9.839326858520621,
        lat: 47.01307678222679
    },
    {
        lng: 9.840103149414006,
        lat: 47.013130187988395
    },
    {
        lng: 9.840462684631461,
        lat: 47.013202667236385
    },
    {
        lng: 9.840791702270565,
        lat: 47.013313293457145
    },
    {
        lng: 9.841252326965389,
        lat: 47.013568878173885
    },
    {
        lng: 9.842461585998535,
        lat: 47.01434707641607
    },
    {
        lng: 9.84318065643322,
        lat: 47.01497268676769
    },
    {
        lng: 9.84435939788824,
        lat: 47.01577377319336
    },
    {
        lng: 9.844579696655387,
        lat: 47.015922546386946
    },
    {
        lng: 9.845351219177303,
        lat: 47.01629638671898
    },
    {
        lng: 9.846577644348145,
        lat: 47.016643524170036
    },
    {
        lng: 9.847333908081055,
        lat: 47.01678848266624
    },
    {
        lng: 9.847916603088493,
        lat: 47.01683044433611
    },
    {
        lng: 9.848461151123047,
        lat: 47.01686859130882
    },
    {
        lng: 9.850522041320744,
        lat: 47.01701736450201
    },
    {
        lng: 9.851422309875602,
        lat: 47.01713180541992
    },
    {
        lng: 9.853132247924862,
        lat: 47.01752853393555
    },
    {
        lng: 9.854230880737305,
        lat: 47.01791763305687
    },
    {
        lng: 9.85498905181879,
        lat: 47.018329620361555
    },
    {
        lng: 9.8556747436524,
        lat: 47.01879501342768
    },
    {
        lng: 9.856422424316463,
        lat: 47.0194206237793
    },
    {
        lng: 9.856574058532772,
        lat: 47.01954650878929
    },
    {
        lng: 9.857384681701717,
        lat: 47.020393371582315
    },
    {
        lng: 9.858095169067383,
        lat: 47.02087783813488
    },
    {
        lng: 9.860045433044547,
        lat: 47.02188873291027
    },
    {
        lng: 9.86081790924078,
        lat: 47.02222061157238
    },
    {
        lng: 9.861675262451229,
        lat: 47.022457122802905
    },
    {
        lng: 9.862579345703239,
        lat: 47.02257537841797
    },
    {
        lng: 9.86350345611578,
        lat: 47.02261352539085
    },
    {
        lng: 9.86489295959484,
        lat: 47.02257156372082
    },
    {
        lng: 9.86731243133545,
        lat: 47.02224731445318
    },
    {
        lng: 9.868782043457088,
        lat: 47.02214813232422
    },
    {
        lng: 9.873747825622615,
        lat: 47.022094726562614
    },
    {
        lng: 9.874665260314941,
        lat: 47.0220298767091
    },
    {
        lng: 9.87550258636486,
        lat: 47.02187728881836
    },
    {
        lng: 9.876133918762207,
        lat: 47.02162170410156
    },
    {
        lng: 9.877684593200684,
        lat: 47.02065658569347
    },
    {
        lng: 9.878707885742244,
        lat: 47.01965332031244
    },
    {
        lng: 9.87893295288086,
        lat: 47.0194549560548
    },
    {
        lng: 9.880125045776367,
        lat: 47.018421173095646
    },
    {
        lng: 9.880570411682129,
        lat: 47.01790618896513
    },
    {
        lng: 9.880707740783805,
        lat: 47.01762390136719
    },
    {
        lng: 9.880736351013184,
        lat: 47.01715469360374
    },
    {
        lng: 9.880496978759766,
        lat: 47.01672363281256
    },
    {
        lng: 9.880249023437557,
        lat: 47.01654052734381
    },
    {
        lng: 9.87967777252203,
        lat: 47.01631546020502
    },
    {
        lng: 9.878508567810115,
        lat: 47.0160026550293
    },
    {
        lng: 9.877276420593319,
        lat: 47.01548385620123
    },
    {
        lng: 9.87706470489502,
        lat: 47.015434265137
    },
    {
        lng: 9.875942230224666,
        lat: 47.015182495117415
    },
    {
        lng: 9.875310897827262,
        lat: 47.01497268676769
    },
    {
        lng: 9.874322891235352,
        lat: 47.014297485351676
    },
    {
        lng: 9.873869895935059,
        lat: 47.01398849487316
    },
    {
        lng: 9.873495101928711,
        lat: 47.01355361938499
    },
    {
        lng: 9.873272895812988,
        lat: 47.01297378540062
    },
    {
        lng: 9.873175621032772,
        lat: 47.01233673095709
    },
    {
        lng: 9.873211860656681,
        lat: 47.01065063476574
    },
    {
        lng: 9.873420715332145,
        lat: 47.00963973999018
    },
    {
        lng: 9.874081611633358,
        lat: 47.00775909423834
    },
    {
        lng: 9.87457084655756,
        lat: 47.00696182250988
    },
    {
        lng: 9.875273704528865,
        lat: 47.006465911865234
    },
    {
        lng: 9.875958442688045,
        lat: 47.006221771240234
    },
    {
        lng: 9.876984596252441,
        lat: 47.00597000122082
    },
    {
        lng: 9.877594947814998,
        lat: 47.005760192871094
    },
    {
        lng: 9.88025188446045,
        lat: 47.004253387451286
    },
    {
        lng: 9.882460594177246,
        lat: 47.00354766845726
    },
    {
        lng: 9.885117530822868,
        lat: 47.00204086303711
    },
    {
        lng: 9.885370254516602,
        lat: 47.00196838378906
    },
    {
        lng: 9.887098312377987,
        lat: 47.001468658447266
    },
    {
        lng: 9.887433052063045,
        lat: 47.00133132934593
    },
    {
        lng: 9.88792514801031,
        lat: 47.00102233886719
    },
    {
        lng: 9.888324737548828,
        lat: 47.00057983398432
    },
    {
        lng: 9.888377189636344,
        lat: 47.000461578369425
    },
    {
        lng: 9.888693809509391,
        lat: 46.999748229980526
    },
    {
        lng: 9.888878822326717,
        lat: 46.99914550781273
    },
    {
        lng: 9.889282226562614,
        lat: 46.99783325195318
    },
    {
        lng: 9.889601707458553,
        lat: 46.99586486816406
    },
    {
        lng: 9.889782905578727,
        lat: 46.99522399902355
    },
    {
        lng: 9.890073776245117,
        lat: 46.994636535644815
    },
    {
        lng: 9.890804290771484,
        lat: 46.993564605713004
    },
    {
        lng: 9.89106464385992,
        lat: 46.99306869506836
    },
    {
        lng: 9.891486167907772,
        lat: 46.992118835449276
    },
    {
        lng: 9.891533851623592,
        lat: 46.99186706542969
    },
    {
        lng: 9.891628265380916,
        lat: 46.99136734008806
    },
    {
        lng: 9.89156723022461,
        lat: 46.990859985351506
    },
    {
        lng: 9.891393661499023,
        lat: 46.990402221679744
    },
    {
        lng: 9.89103984832775,
        lat: 46.9897537231447
    },
    {
        lng: 9.890711784362736,
        lat: 46.98936080932623
    },
    {
        lng: 9.889134407043514,
        lat: 46.988178253174055
    },
    {
        lng: 9.888241767883414,
        lat: 46.98767471313505
    },
    {
        lng: 9.887776374816895,
        lat: 46.98733520507835
    },
    {
        lng: 9.887438774109,
        lat: 46.98708724975597
    },
    {
        lng: 9.886252403259277,
        lat: 46.98639678955084
    },
    {
        lng: 9.885679244995117,
        lat: 46.9858093261721
    },
    {
        lng: 9.885164260864201,
        lat: 46.9848747253418
    },
    {
        lng: 9.884854316711369,
        lat: 46.98408126831049
    },
    {
        lng: 9.884555816650447,
        lat: 46.98211288452154
    },
    {
        lng: 9.884278297424373,
        lat: 46.98120498657238
    },
    {
        lng: 9.88426399230957,
        lat: 46.981159210205135
    },
    {
        lng: 9.883923530578613,
        lat: 46.98060607910156
    },
    {
        lng: 9.883012771606559,
        lat: 46.97943115234375
    },
    {
        lng: 9.882684707641545,
        lat: 46.979087829589844
    },
    {
        lng: 9.882651329040527,
        lat: 46.97905349731451
    },
    {
        lng: 9.880000114441032,
        lat: 46.97710800170921
    },
    {
        lng: 9.879161834716797,
        lat: 46.976242065429915
    },
    {
        lng: 9.877684593200684,
        lat: 46.97491836547863
    },
    {
        lng: 9.877540588378906,
        lat: 46.97475814819336
    },
    {
        lng: 9.877159118652344,
        lat: 46.9743347167971
    },
    {
        lng: 9.876791954040584,
        lat: 46.97369003295921
    },
    {
        lng: 9.876603126525879,
        lat: 46.97301101684582
    },
    {
        lng: 9.876559257507438,
        lat: 46.972305297851676
    },
    {
        lng: 9.87646770477295,
        lat: 46.970832824706974
    },
    {
        lng: 9.876406669616642,
        lat: 46.96985244750999
    },
    {
        lng: 9.876193046569938,
        lat: 46.96882629394554
    },
    {
        lng: 9.875708580017204,
        lat: 46.96793746948242
    },
    {
        lng: 9.874479293823299,
        lat: 46.96616744995117
    },
    {
        lng: 9.873459815979118,
        lat: 46.9648628234865
    },
    {
        lng: 9.873037338256779,
        lat: 46.96385574340832
    },
    {
        lng: 9.872961997985897,
        lat: 46.963676452636946
    },
    {
        lng: 9.872923851013184,
        lat: 46.96300506591791
    },
    {
        lng: 9.873146057128906,
        lat: 46.96237182617193
    },
    {
        lng: 9.873390197753963,
        lat: 46.961963653564396
    },
    {
        lng: 9.873744964599723,
        lat: 46.961616516113395
    },
    {
        lng: 9.875093460083008,
        lat: 46.960597991943644
    },
    {
        lng: 9.875596046447697,
        lat: 46.96027755737322
    },
    {
        lng: 9.875975608825684,
        lat: 46.96003723144554
    },
    {
        lng: 9.87701320648199,
        lat: 46.95903015136719
    },
    {
        lng: 9.878113746643066,
        lat: 46.95807647705078
    },
    {
        lng: 9.878505706787223,
        lat: 46.957740783691634
    },
    {
        lng: 9.879022598266715,
        lat: 46.95717620849632
    },
    {
        lng: 9.879218101501465,
        lat: 46.95686721801758
    },
    {
        lng: 9.879243850707951,
        lat: 46.95679092407238
    },
    {
        lng: 9.879412651062125,
        lat: 46.9562873840332
    },
    {
        lng: 9.879384994506836,
        lat: 46.955665588379134
    },
    {
        lng: 9.879373550415096,
        lat: 46.95543289184582
    },
    {
        lng: 9.879135131835994,
        lat: 46.95493316650396
    },
    {
        lng: 9.879046440124625,
        lat: 46.95484542846691
    },
    {
        lng: 9.87847900390625,
        lat: 46.95427703857439
    },
    {
        lng: 9.877636909484863,
        lat: 46.95290756225609
    },
    {
        lng: 9.876761436462402,
        lat: 46.95203018188488
    },
    {
        lng: 9.876587867736816,
        lat: 46.95175933837885
    },
    {
        lng: 9.87645530700695,
        lat: 46.951553344726676
    },
    {
        lng: 9.87636756896984,
        lat: 46.951286315918196
    },
    {
        lng: 9.875760078430176,
        lat: 46.94944763183605
    },
    {
        lng: 9.8756942749024,
        lat: 46.948925018310604
    },
    {
        lng: 9.875727653503475,
        lat: 46.948398590088004
    },
    {
        lng: 9.875905990600529,
        lat: 46.947582244873104
    },
    {
        lng: 9.875956535339412,
        lat: 46.94735336303734
    },
    {
        lng: 9.87627983093256,
        lat: 46.94505310058622
    },
    {
        lng: 9.876325607299805,
        lat: 46.944732666015796
    },
    {
        lng: 9.876543998718375,
        lat: 46.94417953491222
    },
    {
        lng: 9.876618385314941,
        lat: 46.9440536499024
    },
    {
        lng: 9.877085685730037,
        lat: 46.94326782226585
    },
    {
        lng: 9.87746334075922,
        lat: 46.942848205566406
    },
    {
        lng: 9.878209114074707,
        lat: 46.942401885986555
    },
    {
        lng: 9.878730773925838,
        lat: 46.94225692749035
    },
    {
        lng: 9.87890529632574,
        lat: 46.942207336425895
    },
    {
        lng: 9.879929542541618,
        lat: 46.942028045654524
    },
    {
        lng: 9.880545616149902,
        lat: 46.94183349609369
    },
    {
        lng: 9.880940437316951,
        lat: 46.94154357910179
    },
    {
        lng: 9.881065368652457,
        lat: 46.94136047363304
    },
    {
        lng: 9.881132125854549,
        lat: 46.94096755981457
    },
    {
        lng: 9.88095664978033,
        lat: 46.94038772583019
    },
    {
        lng: 9.880699157714957,
        lat: 46.94001007080101
    },
    {
        lng: 9.880125999450627,
        lat: 46.93955230712885
    },
    {
        lng: 9.879943847656364,
        lat: 46.93940734863281
    },
    {
        lng: 9.87948322296154,
        lat: 46.93892669677746
    },
    {
        lng: 9.878794670105037,
        lat: 46.93820571899437
    },
    {
        lng: 9.877314567566032,
        lat: 46.93716430664085
    },
    {
        lng: 9.879384994506836,
        lat: 46.93611907958979
    },
    {
        lng: 9.880667686462516,
        lat: 46.93556213378906
    },
    {
        lng: 9.882473945617733,
        lat: 46.9349746704101
    },
    {
        lng: 9.884359359741211,
        lat: 46.93427276611328
    },
    {
        lng: 9.887272834777946,
        lat: 46.93334579467768
    },
    {
        lng: 9.88865756988531,
        lat: 46.93304824829124
    },
    {
        lng: 9.88985347747797,
        lat: 46.932884216308594
    },
    {
        lng: 9.894209861755485,
        lat: 46.93229293823265
    },
    {
        lng: 9.895012855529899,
        lat: 46.93212890625
    },
    {
        lng: 9.896441459655705,
        lat: 46.931697845458984
    },
    {
        lng: 9.89710903167719,
        lat: 46.931354522705305
    },
    {
        lng: 9.899701118469352,
        lat: 46.929668426513786
    },
    {
        lng: 9.900787353515625,
        lat: 46.92914581298851
    },
    {
        lng: 9.902922630310115,
        lat: 46.92798233032255
    },
    {
        lng: 9.903148651123047,
        lat: 46.92789077758812
    },
    {
        lng: 9.903895378112793,
        lat: 46.927593231201456
    },
    {
        lng: 9.904201507568416,
        lat: 46.92752456665062
    },
    {
        lng: 9.904742240905819,
        lat: 46.927398681640625
    },
    {
        lng: 9.907926559448356,
        lat: 46.92692184448242
    },
    {
        lng: 9.910513877868652,
        lat: 46.92662048339861
    },
    {
        lng: 9.912656784057674,
        lat: 46.926143646240405
    },
    {
        lng: 9.913357734680233,
        lat: 46.92593765258789
    },
    {
        lng: 9.914093971252555,
        lat: 46.92563247680687
    },
    {
        lng: 9.915222167968864,
        lat: 46.925075531005916
    },
    {
        lng: 9.91747093200695,
        lat: 46.92396163940441
    },
    {
        lng: 9.917954444885254,
        lat: 46.92366409301775
    },
    {
        lng: 9.918723106384277,
        lat: 46.92303466796898
    },
    {
        lng: 9.919595718383846,
        lat: 46.9224967956543
    },
    {
        lng: 9.920339584350529,
        lat: 46.92188644409191
    },
    {
        lng: 9.921200752258358,
        lat: 46.92136001586914
    },
    {
        lng: 9.921908378601131,
        lat: 46.92077636718756
    },
    {
        lng: 9.921941757202148,
        lat: 46.920749664306754
    },
    {
        lng: 9.922820091247672,
        lat: 46.920227050781364
    },
    {
        lng: 9.923636436462402,
        lat: 46.91962051391596
    },
    {
        lng: 9.925065994262695,
        lat: 46.91894149780296
    },
    {
        lng: 9.926361083984375,
        lat: 46.918243408203125
    },
    {
        lng: 9.927463531494197,
        lat: 46.91764831542986
    },
    {
        lng: 9.928642272949219,
        lat: 46.91724395751953
    },
    {
        lng: 9.931157112121696,
        lat: 46.91677856445318
    },
    {
        lng: 9.933073997497615,
        lat: 46.916179656982365
    },
    {
        lng: 9.935357093811035,
        lat: 46.91569137573265
    },
    {
        lng: 9.93663406372076,
        lat: 46.91513061523432
    },
    {
        lng: 9.937758445739746,
        lat: 46.91488647460949
    },
    {
        lng: 9.939067840576229,
        lat: 46.91479492187517
    },
    {
        lng: 9.942034721374512,
        lat: 46.9147262573245
    },
    {
        lng: 9.942787170410213,
        lat: 46.91470718383789
    },
    {
        lng: 9.94515609741211,
        lat: 46.914470672607365
    },
    {
        lng: 9.94626712799078,
        lat: 46.91444396972679
    },
    {
        lng: 9.951329231262264,
        lat: 46.91493988037104
    },
    {
        lng: 9.951493263244743,
        lat: 46.91497039794922
    },
    {
        lng: 9.952214241027832,
        lat: 46.915107727051065
    },
    {
        lng: 9.954554557800407,
        lat: 46.91581344604492
    },
    {
        lng: 9.956236839294377,
        lat: 46.91604614257824
    },
    {
        lng: 9.958023071289062,
        lat: 46.916172027588004
    },
    {
        lng: 9.958149909973145,
        lat: 46.91615295410156
    },
    {
        lng: 9.959322929382438,
        lat: 46.91598129272472
    },
    {
        lng: 9.960344314575195,
        lat: 46.91570663452171
    },
    {
        lng: 9.9610595703125,
        lat: 46.91539001464844
    },
    {
        lng: 9.961202621459961,
        lat: 46.91530990600609
    },
    {
        lng: 9.963077545166016,
        lat: 46.91427230834955
    },
    {
        lng: 9.963835716247672,
        lat: 46.91398239135765
    },
    {
        lng: 9.964683532714844,
        lat: 46.91382217407232
    },
    {
        lng: 9.96599197387701,
        lat: 46.91380310058588
    },
    {
        lng: 9.96684360504156,
        lat: 46.913925170898494
    },
    {
        lng: 9.968743324279785,
        lat: 46.91452026367199
    },
    {
        lng: 9.971011161804142,
        lat: 46.91506195068365
    },
    {
        lng: 9.972311973571777,
        lat: 46.91571426391624
    },
    {
        lng: 9.974181175231934,
        lat: 46.916271209717024
    },
    {
        lng: 9.97474479675293,
        lat: 46.916530609131144
    },
    {
        lng: 9.97533035278326,
        lat: 46.916797637939624
    },
    {
        lng: 9.97578048706066,
        lat: 46.9168701171875
    },
    {
        lng: 9.9759397506715,
        lat: 46.91689682006847
    },
    {
        lng: 9.976310729980469,
        lat: 46.9168701171875
    },
    {
        lng: 9.97661304473877,
        lat: 46.9168510437014
    },
    {
        lng: 9.977345466613713,
        lat: 46.9166145324707
    },
    {
        lng: 9.977999687194881,
        lat: 46.916259765625114
    },
    {
        lng: 9.97886753082281,
        lat: 46.91558456420927
    },
    {
        lng: 9.979338645935059,
        lat: 46.91506195068365
    },
    {
        lng: 9.979522705078068,
        lat: 46.91477584838873
    },
    {
        lng: 9.979766845703125,
        lat: 46.914131164550895
    },
    {
        lng: 9.979927062988281,
        lat: 46.91307830810564
    },
    {
        lng: 9.980166435241642,
        lat: 46.911499023437614
    },
    {
        lng: 9.980968475341797,
        lat: 46.9095344543457
    },
    {
        lng: 9.981501579284611,
        lat: 46.908668518066406
    },
    {
        lng: 9.981841087341309,
        lat: 46.90827941894531
    },
    {
        lng: 9.983317375183162,
        lat: 46.907279968262
    },
    {
        lng: 9.98388385772705,
        lat: 46.907024383544865
    },
    {
        lng: 9.986168861389274,
        lat: 46.90654754638666
    },
    {
        lng: 9.988078117370662,
        lat: 46.905956268310774
    },
    {
        lng: 9.990386962890568,
        lat: 46.90547561645536
    },
    {
        lng: 9.991959571838379,
        lat: 46.904773712158374
    },
    {
        lng: 9.993315696716365,
        lat: 46.904434204101676
    },
    {
        lng: 9.993946075439453,
        lat: 46.90422439575195
    },
    {
        lng: 9.99516296386713,
        lat: 46.90362548828131
    },
    {
        lng: 9.99714660644537,
        lat: 46.903079986572266
    },
    {
        lng: 9.99840164184576,
        lat: 46.9024772644043
    },
    {
        lng: 10.000679969787711,
        lat: 46.90196609497093
    },
    {
        lng: 10.002196311950627,
        lat: 46.901473999023665
    },
    {
        lng: 10.003042221069393,
        lat: 46.901275634765625
    },
    {
        lng: 10.004427909851074,
        lat: 46.90114974975603
    },
    {
        lng: 10.006306648254508,
        lat: 46.90118789672874
    },
    {
        lng: 10.008684158325252,
        lat: 46.9015350341798
    },
    {
        lng: 10.010837554931697,
        lat: 46.90185165405302
    },
    {
        lng: 10.013121604919434,
        lat: 46.902259826660384
    },
    {
        lng: 10.015426635742188,
        lat: 46.90242385864286
    },
    {
        lng: 10.016010284423942,
        lat: 46.902370452880916
    },
    {
        lng: 10.016286849975529,
        lat: 46.90229415893572
    },
    {
        lng: 10.016761779785156,
        lat: 46.90205764770519
    },
    {
        lng: 10.018281936645451,
        lat: 46.900840759277344
    },
    {
        lng: 10.0204696655274,
        lat: 46.89820480346691
    },
    {
        lng: 10.021492004394474,
        lat: 46.897434234619254
    },
    {
        lng: 10.022973060607967,
        lat: 46.89619064331072
    },
    {
        lng: 10.023691177368164,
        lat: 46.894985198974894
    },
    {
        lng: 10.023936271667537,
        lat: 46.894573211670036
    },
    {
        lng: 10.024688720703239,
        lat: 46.893924713134766
    },
    {
        lng: 10.025383949279785,
        lat: 46.89315414428711
    },
    {
        lng: 10.025881767273006,
        lat: 46.89271545410179
    },
    {
        lng: 10.026778221130485,
        lat: 46.89215850830101
    },
    {
        lng: 10.027857780456486,
        lat: 46.89164733886747
    },
    {
        lng: 10.028765678405875,
        lat: 46.89106369018555
    },
    {
        lng: 10.030897140502987,
        lat: 46.89014053344749
    },
    {
        lng: 10.032703399658203,
        lat: 46.8893585205081
    },
    {
        lng: 10.033530235290527,
        lat: 46.88875961303728
    },
    {
        lng: 10.03443717956543,
        lat: 46.88824081420921
    },
    {
        lng: 10.03551387786871,
        lat: 46.88751983642595
    },
    {
        lng: 10.036701202392578,
        lat: 46.88702392578131
    },
    {
        lng: 10.03913497924799,
        lat: 46.88586044311518
    },
    {
        lng: 10.040778160095215,
        lat: 46.88435363769537
    },
    {
        lng: 10.04501056671154,
        lat: 46.88114547729492
    },
    {
        lng: 10.045577049255428,
        lat: 46.880649566650504
    },
    {
        lng: 10.0465602874757,
        lat: 46.879638671875114
    },
    {
        lng: 10.047406196594238,
        lat: 46.87907028198265
    },
    {
        lng: 10.04887485504156,
        lat: 46.87782287597679
    },
    {
        lng: 10.049056053161678,
        lat: 46.87756729125999
    },
    {
        lng: 10.04928207397461,
        lat: 46.87697982788109
    },
    {
        lng: 10.049387931823844,
        lat: 46.87630844116222
    },
    {
        lng: 10.049416542053223,
        lat: 46.87520217895508
    },
    {
        lng: 10.049251556396484,
        lat: 46.87324905395519
    },
    {
        lng: 10.049188613891545,
        lat: 46.87299346923828
    },
    {
        lng: 10.048700332641658,
        lat: 46.87099838256836
    },
    {
        lng: 10.048689842224235,
        lat: 46.870861053467024
    },
    {
        lng: 10.048613548278809,
        lat: 46.869880676269645
    },
    {
        lng: 10.048645019531307,
        lat: 46.86913299560575
    },
    {
        lng: 10.04876804351818,
        lat: 46.86840438842796
    },
    {
        lng: 10.049430847168026,
        lat: 46.86629486083996
    },
    {
        lng: 10.049810409546012,
        lat: 46.865638732910384
    },
    {
        lng: 10.049835205078182,
        lat: 46.86561584472679
    },
    {
        lng: 10.050454139709586,
        lat: 46.86503982543974
    },
    {
        lng: 10.050852775573787,
        lat: 46.86478805542015
    },
    {
        lng: 10.051737785339355,
        lat: 46.86442947387701
    },
    {
        lng: 10.054048538208065,
        lat: 46.86392211914085
    },
    {
        lng: 10.056001663208121,
        lat: 46.86338043212896
    },
    {
        lng: 10.05698299407959,
        lat: 46.86319732666027
    },
    {
        lng: 10.060526847839355,
        lat: 46.862701416015625
    },
    {
        lng: 10.062614440918026,
        lat: 46.862590789794865
    },
    {
        lng: 10.067421913147086,
        lat: 46.86256790161144
    },
    {
        lng: 10.068091392517147,
        lat: 46.862548828125
    },
    {
        lng: 10.068123817443904,
        lat: 46.862548828125
    },
    {
        lng: 10.069536209106502,
        lat: 46.862510681152344
    },
    {
        lng: 10.070567131042424,
        lat: 46.862434387207145
    },
    {
        lng: 10.072641372680607,
        lat: 46.86216735839844
    },
    {
        lng: 10.074805259704647,
        lat: 46.862056732177905
    },
    {
        lng: 10.083642005920524,
        lat: 46.86208343505888
    },
    {
        lng: 10.08512973785406,
        lat: 46.861949920654354
    },
    {
        lng: 10.08598709106451,
        lat: 46.86172485351557
    },
    {
        lng: 10.087814331054801,
        lat: 46.86073684692383
    },
    {
        lng: 10.089468002319393,
        lat: 46.85955810546881
    },
    {
        lng: 10.090010643005428,
        lat: 46.85898971557617
    },
    {
        lng: 10.090310096740836,
        lat: 46.85833740234392
    },
    {
        lng: 10.090448379516602,
        lat: 46.85763168334955
    },
    {
        lng: 10.090642929077262,
        lat: 46.854320526123104
    },
    {
        lng: 10.09083557128912,
        lat: 46.85359954834007
    },
    {
        lng: 10.091179847717285,
        lat: 46.85292053222685
    },
    {
        lng: 10.091819763183707,
        lat: 46.85207748413097
    },
    {
        lng: 10.093453407287598,
        lat: 46.85095977783226
    },
    {
        lng: 10.095352172851562,
        lat: 46.8494491577149
    },
    {
        lng: 10.095930099487362,
        lat: 46.848945617675724
    },
    {
        lng: 10.098144531250114,
        lat: 46.84633255004894
    },
    {
        lng: 10.098981857299862,
        lat: 46.84575271606457
    },
    {
        lng: 10.099714279174862,
        lat: 46.84512710571312
    },
    {
        lng: 10.10058784484869,
        lat: 46.84460830688488
    },
    {
        lng: 10.101395606994743,
        lat: 46.84400939941406
    },
    {
        lng: 10.102861404419002,
        lat: 46.843299865722884
    },
    {
        lng: 10.104355812072754,
        lat: 46.84242630004883
    },
    {
        lng: 10.108342170715446,
        lat: 46.84281921386747
    },
    {
        lng: 10.110799789428768,
        lat: 46.84345626831066
    },
    {
        lng: 10.11319732666027,
        lat: 46.843940734863395
    },
    {
        lng: 10.114153861999569,
        lat: 46.844219207763786
    },
    {
        lng: 10.116357803344727,
        lat: 46.845092773437614
    },
    {
        lng: 10.117147445678825,
        lat: 46.84551620483421
    },
    {
        lng: 10.117415428161621,
        lat: 46.84575653076172
    },
    {
        lng: 10.118064880371094,
        lat: 46.846336364746094
    },
    {
        lng: 10.11876296997076,
        lat: 46.847431182861555
    },
    {
        lng: 10.11926078796398,
        lat: 46.8479118347168
    },
    {
        lng: 10.120017051696891,
        lat: 46.848228454590014
    },
    {
        lng: 10.120902061462402,
        lat: 46.84838485717796
    },
    {
        lng: 10.122335433959904,
        lat: 46.84841537475597
    },
    {
        lng: 10.125407218933162,
        lat: 46.848068237304915
    },
    {
        lng: 10.128251075744686,
        lat: 46.84794235229509
    },
    {
        lng: 10.132377624511776,
        lat: 46.84792709350586
    },
    {
        lng: 10.134635925292969,
        lat: 46.84791946411133
    },
    {
        lng: 10.137449264526367,
        lat: 46.848045349121094
    },
    {
        lng: 10.13850021362299,
        lat: 46.84820938110357
    },
    {
        lng: 10.140222549438477,
        lat: 46.8487358093264
    },
    {
        lng: 10.140878677368278,
        lat: 46.84909057617199
    },
    {
        lng: 10.141129493713379,
        lat: 46.8492546081543
    },
    {
        lng: 10.1414155960083,
        lat: 46.84944152832037
    },
    {
        lng: 10.143143653869743,
        lat: 46.850574493408146
    },
    {
        lng: 10.143880844116154,
        lat: 46.85089111328142
    },
    {
        lng: 10.145693778991813,
        lat: 46.85137176513683
    },
    {
        lng: 10.147198677063045,
        lat: 46.85149383544922
    },
    {
        lng: 10.147502899169922,
        lat: 46.851482391357536
    },
    {
        lng: 10.149248123169002,
        lat: 46.85140228271513
    },
    {
        lng: 10.150265693664608,
        lat: 46.85121917724621
    },
    {
        lng: 10.151278495788574,
        lat: 46.8509635925293
    },
    {
        lng: 10.15170669555664,
        lat: 46.85085296630854
    },
    {
        lng: 10.1526517868042,
        lat: 46.85052871704113
    },
    {
        lng: 10.153099060058537,
        lat: 46.85033798217785
    },
    {
        lng: 10.156203269958553,
        lat: 46.84902191162132
    },
    {
        lng: 10.158970832824707,
        lat: 46.850124359130916
    },
    {
        lng: 10.159982681274528,
        lat: 46.85052871704113
    },
    {
        lng: 10.160927772521916,
        lat: 46.850841522217024
    },
    {
        lng: 10.16286659240717,
        lat: 46.85132598876976
    },
    {
        lng: 10.165795326232967,
        lat: 46.851814270019474
    },
    {
        lng: 10.167258262634334,
        lat: 46.852180480957145
    },
    {
        lng: 10.168180465698242,
        lat: 46.852443695068644
    },
    {
        lng: 10.17098617553711,
        lat: 46.85351181030296
    },
    {
        lng: 10.171954154968319,
        lat: 46.853706359863395
    },
    {
        lng: 10.174664497375545,
        lat: 46.85403823852556
    },
    {
        lng: 10.175393104553336,
        lat: 46.85412979125982
    },
    {
        lng: 10.17713642120367,
        lat: 46.85463333129883
    },
    {
        lng: 10.177816390991325,
        lat: 46.85499191284197
    },
    {
        lng: 10.179222106933537,
        lat: 46.85600662231468
    },
    {
        lng: 10.180581092834416,
        lat: 46.85757064819347
    },
    {
        lng: 10.18072700500494,
        lat: 46.857738494873104
    },
    {
        lng: 10.182072639465332,
        lat: 46.858829498291016
    },
    {
        lng: 10.182660102844295,
        lat: 46.85941696167015
    },
    {
        lng: 10.183197021484489,
        lat: 46.85995483398449
    },
    {
        lng: 10.18404579162592,
        lat: 46.86055755615263
    },
    {
        lng: 10.18479061126709,
        lat: 46.86119079589861
    },
    {
        lng: 10.185638427734432,
        lat: 46.8617324829101
    },
    {
        lng: 10.186385154724178,
        lat: 46.86234283447283
    },
    {
        lng: 10.188831329345817,
        lat: 46.864040374755916
    },
    {
        lng: 10.189569473266545,
        lat: 46.864662170410384
    },
    {
        lng: 10.190400123596248,
        lat: 46.86521911621094
    },
    {
        lng: 10.191123008728084,
        lat: 46.86585235595709
    },
    {
        lng: 10.191948890686092,
        lat: 46.86643218994146
    },
    {
        lng: 10.19272232055664,
        lat: 46.86709976196295
    },
    {
        lng: 10.193496704101676,
        lat: 46.867519378662394
    },
    {
        lng: 10.19441127777111,
        lat: 46.86775207519537
    },
    {
        lng: 10.195398330688533,
        lat: 46.86784362792969
    },
    {
        lng: 10.196413993835563,
        lat: 46.86784362792969
    },
    {
        lng: 10.197430610656852,
        lat: 46.8677597045899
    },
    {
        lng: 10.198269844055176,
        lat: 46.86760330200195
    },
    {
        lng: 10.199867248535156,
        lat: 46.867191314697266
    },
    {
        lng: 10.20023250579834,
        lat: 46.86706542968744
    },
    {
        lng: 10.202646255493221,
        lat: 46.86622619628912
    },
    {
        lng: 10.20331954956066,
        lat: 46.86607360839855
    },
    {
        lng: 10.204230308532715,
        lat: 46.86586761474621
    },
    {
        lng: 10.205548286438045,
        lat: 46.865749359130916
    },
    {
        lng: 10.208993911743278,
        lat: 46.86608123779325
    },
    {
        lng: 10.21039199829113,
        lat: 46.866279602051065
    },
    {
        lng: 10.211198806762752,
        lat: 46.86652374267578
    },
    {
        lng: 10.211954116821289,
        lat: 46.866924285888786
    },
    {
        lng: 10.212495803833008,
        lat: 46.86721038818371
    },
    {
        lng: 10.21369838714611,
        lat: 46.86769104003906
    },
    {
        lng: 10.214667320251408,
        lat: 46.868080139160156
    },
    {
        lng: 10.215537071228084,
        lat: 46.86830139160179
    },
    {
        lng: 10.216540336608887,
        lat: 46.86841201782249
    },
    {
        lng: 10.21807670593256,
        lat: 46.86840820312494
    },
    {
        lng: 10.219085693359318,
        lat: 46.86829757690441
    },
    {
        lng: 10.219592094421387,
        lat: 46.86817932128935
    },
    {
        lng: 10.219967842101994,
        lat: 46.86809158325224
    },
    {
        lng: 10.221078872680664,
        lat: 46.86774826049805
    },
    {
        lng: 10.221514701843262,
        lat: 46.867610931396484
    },
    {
        lng: 10.22243881225586,
        lat: 46.867431640625114
    },
    {
        lng: 10.223402023315543,
        lat: 46.86731719970703
    },
    {
        lng: 10.225837707519588,
        lat: 46.86722183227539
    },
    {
        lng: 10.226749420166072,
        lat: 46.86725234985374
    },
    {
        lng: 10.227622032165641,
        lat: 46.867355346679915
    },
    {
        lng: 10.228425979614371,
        lat: 46.86758804321306
    },
    {
        lng: 10.228780746459961,
        lat: 46.86777877807634
    },
    {
        lng: 10.228886604309196,
        lat: 46.867889404296875
    },
    {
        lng: 10.229302406311092,
        lat: 46.86832427978521
    },
    {
        lng: 10.229583740234318,
        lat: 46.86893844604492
    },
    {
        lng: 10.229866981506404,
        lat: 46.8698844909668
    },
    {
        lng: 10.229988098144531,
        lat: 46.87028884887718
    },
    {
        lng: 10.230070114135856,
        lat: 46.87091827392595
    },
    {
        lng: 10.230043411254883,
        lat: 46.87201309204124
    },
    {
        lng: 10.229871749878043,
        lat: 46.87348937988287
    },
    {
        lng: 10.230380058288688,
        lat: 46.877853393554915
    },
    {
        lng: 10.231204986572209,
        lat: 46.880817413330135
    },
    {
        lng: 10.232317924499455,
        lat: 46.88366317749046
    },
    {
        lng: 10.232484817504826,
        lat: 46.88446426391613
    },
    {
        lng: 10.232466697692871,
        lat: 46.88564682006847
    },
    {
        lng: 10.232240676879996,
        lat: 46.88636779785173
    },
    {
        lng: 10.231806755065918,
        lat: 46.886932373046875
    },
    {
        lng: 10.23059463500988,
        lat: 46.88796997070318
    },
    {
        lng: 10.229660987854004,
        lat: 46.88859176635742
    },
    {
        lng: 10.227848052978572,
        lat: 46.889797210693416
    },
    {
        lng: 10.22655105590826,
        lat: 46.8908309936524
    },
    {
        lng: 10.226116180419922,
        lat: 46.89137649536144
    },
    {
        lng: 10.2255859375,
        lat: 46.89235687255865
    },
    {
        lng: 10.225359916687069,
        lat: 46.893131256103516
    },
    {
        lng: 10.225239753723201,
        lat: 46.89435195922874
    },
    {
        lng: 10.22533130645752,
        lat: 46.896003723144645
    },
    {
        lng: 10.225631713867188,
        lat: 46.89717483520508
    },
    {
        lng: 10.22642707824707,
        lat: 46.899135589599666
    },
    {
        lng: 10.22686576843256,
        lat: 46.89963912963867
    },
    {
        lng: 10.228973388671989,
        lat: 46.90157318115246
    },
    {
        lng: 10.229799270629996,
        lat: 46.902896881103516
    },
    {
        lng: 10.230619430542106,
        lat: 46.903888702392805
    },
    {
        lng: 10.230719566345215,
        lat: 46.90410614013689
    },
    {
        lng: 10.231341361999625,
        lat: 46.90546035766613
    },
    {
        lng: 10.232126235961914,
        lat: 46.906845092773665
    },
    {
        lng: 10.233090400695914,
        lat: 46.91012954711914
    },
    {
        lng: 10.233162879943904,
        lat: 46.911251068115234
    },
    {
        lng: 10.23335170745861,
        lat: 46.91417312622093
    },
    {
        lng: 10.233499526977539,
        lat: 46.91494750976557
    },
    {
        lng: 10.233781814575309,
        lat: 46.915664672851676
    },
    {
        lng: 10.234554290771541,
        lat: 46.91682052612305
    },
    {
        lng: 10.23536300659191,
        lat: 46.917518615722884
    },
    {
        lng: 10.237042427063045,
        lat: 46.918968200683594
    },
    {
        lng: 10.237758636474666,
        lat: 46.92021560668945
    },
    {
        lng: 10.237981796264648,
        lat: 46.92099380493181
    },
    {
        lng: 10.238105773925895,
        lat: 46.92223358154314
    },
    {
        lng: 10.23802852630621,
        lat: 46.92450332641607
    },
    {
        lng: 10.237975120544434,
        lat: 46.92608642578148
    },
    {
        lng: 10.2378892898559,
        lat: 46.9286346435548
    },
    {
        lng: 10.237961769104118,
        lat: 46.92980575561546
    },
    {
        lng: 10.238183021545353,
        lat: 46.93052291870134
    },
    {
        lng: 10.238715171813908,
        lat: 46.93115615844749
    },
    {
        lng: 10.239128112792969,
        lat: 46.93138885498064
    },
    {
        lng: 10.240078926086483,
        lat: 46.93164825439459
    },
    {
        lng: 10.241047859191951,
        lat: 46.93174743652344
    },
    {
        lng: 10.241119384765682,
        lat: 46.93175506591797
    },
    {
        lng: 10.241553306579704,
        lat: 46.93175506591797
    },
    {
        lng: 10.243060111999512,
        lat: 46.93175125122076
    },
    {
        lng: 10.244563102722168,
        lat: 46.93174743652344
    },
    {
        lng: 10.252919197082633,
        lat: 46.93173217773432
    },
    {
        lng: 10.256514549255371,
        lat: 46.93165969848633
    },
    {
        lng: 10.258392333984375,
        lat: 46.93158340454113
    },
    {
        lng: 10.260348320007267,
        lat: 46.93131256103544
    },
    {
        lng: 10.263134956359863,
        lat: 46.93104934692394
    },
    {
        lng: 10.26447868347168,
        lat: 46.93081283569347
    },
    {
        lng: 10.266386032104492,
        lat: 46.930194854736555
    },
    {
        lng: 10.268648147583065,
        lat: 46.929637908935774
    },
    {
        lng: 10.270283699035758,
        lat: 46.92892074584972
    },
    {
        lng: 10.272553443908691,
        lat: 46.92836761474632
    },
    {
        lng: 10.27443885803217,
        lat: 46.92773818969732
    },
    {
        lng: 10.276714324951229,
        lat: 46.92718887329113
    },
    {
        lng: 10.278363227844238,
        lat: 46.92647933959955
    },
    {
        lng: 10.28063774108881,
        lat: 46.92593383789068
    },
    {
        lng: 10.2825222015382,
        lat: 46.92530059814453
    },
    {
        lng: 10.284794807434196,
        lat: 46.92475891113304
    },
    {
        lng: 10.285953521728516,
        lat: 46.924263000488565
    },
    {
        lng: 10.28643798828125,
        lat: 46.92405700683605
    },
    {
        lng: 10.289163589477539,
        lat: 46.923374176025504
    },
    {
        lng: 10.293098449706974,
        lat: 46.92186737060547
    },
    {
        lng: 10.29421138763422,
        lat: 46.92152404785179
    },
    {
        lng: 10.295054435730094,
        lat: 46.92137908935558
    },
    {
        lng: 10.295916557312069,
        lat: 46.92136383056646
    },
    {
        lng: 10.296448707580623,
        lat: 46.92150497436535
    },
    {
        lng: 10.298237800598145,
        lat: 46.92269515991211
    },
    {
        lng: 10.298624038696403,
        lat: 46.92303466796898
    },
    {
        lng: 10.298944473266658,
        lat: 46.92331695556652
    },
    {
        lng: 10.299572944641113,
        lat: 46.92374038696295
    },
    {
        lng: 10.299752235412711,
        lat: 46.92386245727539
    },
    {
        lng: 10.300479888916016,
        lat: 46.92447280883812
    },
    {
        lng: 10.301921844482536,
        lat: 46.925327301025504
    },
    {
        lng: 10.302646636962947,
        lat: 46.925617218017805
    },
    {
        lng: 10.30401515960699,
        lat: 46.92580413818371
    },
    {
        lng: 10.304192543029785,
        lat: 46.92580795288109
    },
    {
        lng: 10.306794166565055,
        lat: 46.92586135864269
    },
    {
        lng: 10.30920219421398,
        lat: 46.92590713500971
    },
    {
        lng: 10.31248950958252,
        lat: 46.926330566406364
    },
    {
        lng: 10.313058853149471,
        lat: 46.926403045654354
    },
    {
        lng: 10.31539440155035,
        lat: 46.926864624023665
    },
    {
        lng: 10.315519332885856,
        lat: 46.92746353149414
    },
    {
        lng: 10.315830230713004,
        lat: 46.92895507812523
    },
    {
        lng: 10.315891265869084,
        lat: 46.92924880981468
    },
    {
        lng: 10.31608676910406,
        lat: 46.931179046630916
    },
    {
        lng: 10.315981864929256,
        lat: 46.93227386474638
    },
    {
        lng: 10.3155136108399,
        lat: 46.93347549438482
    },
    {
        lng: 10.315226554870662,
        lat: 46.93390274047863
    },
    {
        lng: 10.31376838684082,
        lat: 46.93518829345703
    },
    {
        lng: 10.312931060791072,
        lat: 46.93573379516624
    },
    {
        lng: 10.31219577789301,
        lat: 46.93634796142595
    },
    {
        lng: 10.31201171875,
        lat: 46.936462402344034
    },
    {
        lng: 10.31134223937994,
        lat: 46.93687820434593
    },
    {
        lng: 10.310598373413029,
        lat: 46.93748474121111
    },
    {
        lng: 10.310018539428654,
        lat: 46.93786239624029
    },
    {
        lng: 10.309555053710938,
        lat: 46.938167572021484
    },
    {
        lng: 10.308294296264592,
        lat: 46.93928146362305
    },
    {
        lng: 10.308118820190373,
        lat: 46.93954086303734
    },
    {
        lng: 10.307912826538029,
        lat: 46.94012451171869
    },
    {
        lng: 10.307838439941406,
        lat: 46.94143676757824
    },
    {
        lng: 10.308025360107536,
        lat: 46.94279861450218
    },
    {
        lng: 10.308246612548771,
        lat: 46.943500518799
    },
    {
        lng: 10.309329032898063,
        lat: 46.94595336914068
    },
    {
        lng: 10.309424400329704,
        lat: 46.94637298583996
    },
    {
        lng: 10.309844017028752,
        lat: 46.948223114013615
    },
    {
        lng: 10.310190200805721,
        lat: 46.94916915893566
    },
    {
        lng: 10.310566902160588,
        lat: 46.94971084594738
    },
    {
        lng: 10.31121921539318,
        lat: 46.950439453125
    },
    {
        lng: 10.311293601989803,
        lat: 46.95051574707054
    },
    {
        lng: 10.311605453491211,
        lat: 46.950832366943416
    },
    {
        lng: 10.31206417083746,
        lat: 46.95117568969749
    },
    {
        lng: 10.312705993652457,
        lat: 46.951450347900504
    },
    {
        lng: 10.314312934875545,
        lat: 46.95195770263672
    },
    {
        lng: 10.315183639526367,
        lat: 46.95248031616211
    },
    {
        lng: 10.317070007324332,
        lat: 46.95346069335949
    },
    {
        lng: 10.317869186401367,
        lat: 46.95367050170921
    },
    {
        lng: 10.318782806396484,
        lat: 46.95377349853521
    },
    {
        lng: 10.320263862609977,
        lat: 46.953811645507926
    },
    {
        lng: 10.320516586303654,
        lat: 46.95380783081055
    },
    {
        lng: 10.320634841918945,
        lat: 46.95380783081055
    },
    {
        lng: 10.32385158538824,
        lat: 46.953788757324276
    },
    {
        lng: 10.32534217834484,
        lat: 46.953891754150504
    },
    {
        lng: 10.326286315917912,
        lat: 46.9541015625
    },
    {
        lng: 10.326675415039062,
        lat: 46.954250335693416
    },
    {
        lng: 10.327031135559082,
        lat: 46.95443344116234
    },
    {
        lng: 10.327485084533691,
        lat: 46.954769134521484
    },
    {
        lng: 10.327630996704215,
        lat: 46.954875946045206
    },
    {
        lng: 10.328061103820914,
        lat: 46.955402374267635
    },
    {
        lng: 10.328770637512264,
        lat: 46.95705032348633
    },
    {
        lng: 10.328793525695858,
        lat: 46.957103729248274
    },
    {
        lng: 10.329082489013672,
        lat: 46.9576034545899
    },
    {
        lng: 10.329759597778263,
        lat: 46.95877456665039
    },
    {
        lng: 10.329783439636174,
        lat: 46.95884704589861
    },
    {
        lng: 10.330355644226017,
        lat: 46.96057128906267
    },
    {
        lng: 10.331453323364371,
        lat: 46.963039398193416
    },
    {
        lng: 10.331970214843864,
        lat: 46.96445846557617
    },
    {
        lng: 10.332100868225154,
        lat: 46.964817047119084
    },
    {
        lng: 10.33243370056158,
        lat: 46.965370178222884
    },
    {
        lng: 10.333366394042969,
        lat: 46.96650314331083
    },
    {
        lng: 10.333765983581657,
        lat: 46.966987609863565
    },
    {
        lng: 10.33452129364025,
        lat: 46.96767044067394
    },
    {
        lng: 10.334872245788631,
        lat: 46.968074798583984
    },
    {
        lng: 10.335136413574276,
        lat: 46.96858596801769
    },
    {
        lng: 10.335531234741325,
        lat: 46.969673156738395
    },
    {
        lng: 10.336344718933162,
        lat: 46.971912384033374
    },
    {
        lng: 10.336636543273983,
        lat: 46.97249603271496
    },
    {
        lng: 10.337088584900016,
        lat: 46.97307205200195
    },
    {
        lng: 10.338704109191951,
        lat: 46.97467803955095
    },
    {
        lng: 10.338884353637809,
        lat: 46.97485733032232
    },
    {
        lng: 10.33897972106945,
        lat: 46.97495269775396
    },
    {
        lng: 10.340268135070914,
        lat: 46.975852966308594
    },
    {
        lng: 10.341403007507438,
        lat: 46.97678375244158
    },
    {
        lng: 10.341762542724666,
        lat: 46.977218627929915
    },
    {
        lng: 10.34205532073986,
        lat: 46.97811508178722
    },
    {
        lng: 10.342246055603141,
        lat: 46.98015594482433
    },
    {
        lng: 10.342301368713493,
        lat: 46.98074340820307
    },
    {
        lng: 10.342512130737305,
        lat: 46.98133850097656
    },
    {
        lng: 10.342690467834586,
        lat: 46.981609344482365
    },
    {
        lng: 10.343218803405819,
        lat: 46.9820175170899
    },
    {
        lng: 10.345109939575252,
        lat: 46.98264312744152
    },
    {
        lng: 10.34563350677496,
        lat: 46.98292160034191
    },
    {
        lng: 10.346060752868766,
        lat: 46.983146667480526
    },
    {
        lng: 10.346896171569938,
        lat: 46.98351669311518
    },
    {
        lng: 10.347152709960994,
        lat: 46.98363113403326
    },
    {
        lng: 10.347764968872127,
        lat: 46.98400497436529
    },
    {
        lng: 10.348286628723258,
        lat: 46.9844665527346
    },
    {
        lng: 10.34849739074707,
        lat: 46.98472976684593
    },
    {
        lng: 10.348793983459586,
        lat: 46.98532867431635
    },
    {
        lng: 10.348972320556697,
        lat: 46.98613357543957
    },
    {
        lng: 10.349355697631836,
        lat: 46.98786926269531
    },
    {
        lng: 10.349625587463379,
        lat: 46.988353729248274
    },
    {
        lng: 10.349998474121094,
        lat: 46.98902511596691
    },
    {
        lng: 10.350595474243164,
        lat: 46.98964309692383
    },
    {
        lng: 10.351472854614371,
        lat: 46.99008178710949
    },
    {
        lng: 10.353166580200309,
        lat: 46.9905700683596
    },
    {
        lng: 10.353523254394645,
        lat: 46.990673065185774
    },
    {
        lng: 10.354686737060604,
        lat: 46.99132156372082
    },
    {
        lng: 10.355989456176815,
        lat: 46.992046356201286
    },
    {
        lng: 10.35611534118658,
        lat: 46.992099761962834
    },
    {
        lng: 10.356615066528434,
        lat: 46.9922981262207
    },
    {
        lng: 10.357437133789062,
        lat: 46.99245452880882
    },
    {
        lng: 10.35881137847906,
        lat: 46.992553710937614
    },
    {
        lng: 10.358980178833008,
        lat: 46.992553710937614
    },
    {
        lng: 10.361370086669979,
        lat: 46.99254989624046
    },
    {
        lng: 10.363813400268498,
        lat: 46.99254608154325
    },
    {
        lng: 10.364320755004883,
        lat: 46.99254608154325
    },
    {
        lng: 10.365734100341797,
        lat: 46.99254608154325
    },
    {
        lng: 10.369213104248104,
        lat: 46.99242782592785
    },
    {
        lng: 10.36968135833746,
        lat: 46.99237442016624
    },
    {
        lng: 10.371838569641113,
        lat: 46.992122650146484
    },
    {
        lng: 10.373124122619686,
        lat: 46.99197006225586
    },
    {
        lng: 10.374941825866813,
        lat: 46.991950988769645
    },
    {
        lng: 10.376077651977653,
        lat: 46.992046356201286
    },
    {
        lng: 10.377497673034668,
        lat: 46.99235153198242
    },
    {
        lng: 10.377595901489371,
        lat: 46.99237442016624
    },
    {
        lng: 10.378534317016715,
        lat: 46.99271011352556
    },
    {
        lng: 10.380265235900993,
        lat: 46.993808746337834
    },
    {
        lng: 10.380485534667969,
        lat: 46.993991851806754
    },
    {
        lng: 10.380992889404297,
        lat: 46.99441146850586
    },
    {
        lng: 10.381845474243164,
        lat: 46.99493408203148
    },
    {
        lng: 10.382578849792594,
        lat: 46.995540618896484
    },
    {
        lng: 10.383436203003043,
        lat: 46.996067047119425
    },
    {
        lng: 10.38366413116455,
        lat: 46.99626159667969
    },
    {
        lng: 10.384167671203727,
        lat: 46.996688842773494
    },
    {
        lng: 10.385017395019474,
        lat: 46.99726867675787
    },
    {
        lng: 10.385980606079215,
        lat: 46.99821853637695
    },
    {
        lng: 10.386551856994743,
        lat: 46.99863052368164
    },
    {
        lng: 10.38708686828619,
        lat: 46.99889373779297
    },
    {
        lng: 10.38754940032959,
        lat: 46.999118804931925
    },
    {
        lng: 10.390068054199276,
        lat: 47.000022888183594
    },
    {
        lng: 10.39084720611578,
        lat: 47.000221252441406
    },
    {
        lng: 10.39155292510992,
        lat: 47.00032043457037
    },
    {
        lng: 10.392121315002441,
        lat: 47.00040054321312
    },
    {
        lng: 10.394063949585018,
        lat: 47.000549316406534
    },
    {
        lng: 10.39470386505127,
        lat: 47.00051498413086
    },
    {
        lng: 10.396215438842887,
        lat: 47.000183105468864
    },
    {
        lng: 10.397697448730526,
        lat: 46.999481201172046
    },
    {
        lng: 10.3979873657226,
        lat: 46.99940872192383
    },
    {
        lng: 10.399036407470646,
        lat: 46.99913406372082
    },
    {
        lng: 10.399663925170842,
        lat: 46.99890518188488
    },
    {
        lng: 10.401409149169979,
        lat: 46.997852325439624
    },
    {
        lng: 10.402159690856877,
        lat: 46.997234344482536
    },
    {
        lng: 10.403005599975643,
        lat: 46.9966468811038
    },
    {
        lng: 10.405451774597282,
        lat: 46.993896484375114
    },
    {
        lng: 10.406305313110352,
        lat: 46.99331283569359
    },
    {
        lng: 10.406534194946346,
        lat: 46.99312591552746
    },
    {
        lng: 10.407061576843262,
        lat: 46.99269485473633
    },
    {
        lng: 10.407760620117188,
        lat: 46.992282867431584
    },
    {
        lng: 10.40794754028326,
        lat: 46.99217224121105
    },
    {
        lng: 10.408152580261344,
        lat: 46.99201202392578
    },
    {
        lng: 10.408713340759277,
        lat: 46.991577148437614
    },
    {
        lng: 10.409038543701229,
        lat: 46.9913787841798
    },
    {
        lng: 10.409569740295467,
        lat: 46.991058349609375
    },
    {
        lng: 10.410845756530705,
        lat: 46.990039825439624
    },
    {
        lng: 10.410894393921012,
        lat: 46.99000167846674
    },
    {
        lng: 10.411252021789664,
        lat: 46.98963165283209
    },
    {
        lng: 10.412087440490836,
        lat: 46.98828125000023
    },
    {
        lng: 10.41356086730957,
        lat: 46.98703765869135
    },
    {
        lng: 10.414407730102653,
        lat: 46.98645401000999
    },
    {
        lng: 10.415714263916072,
        lat: 46.98532104492182
    },
    {
        lng: 10.416698455810604,
        lat: 46.98478698730486
    },
    {
        lng: 10.41837310791027,
        lat: 46.98418045043968
    },
    {
        lng: 10.420872688293457,
        lat: 46.983131408691634
    },
    {
        lng: 10.420919418335018,
        lat: 46.98311233520519
    },
    {
        lng: 10.422660827636719,
        lat: 46.98201370239258
    },
    {
        lng: 10.423426628112736,
        lat: 46.981418609619084
    },
    {
        lng: 10.424944877624625,
        lat: 46.98050689697277
    },
    {
        lng: 10.425477981567496,
        lat: 46.980258941650504
    },
    {
        lng: 10.427432060241642,
        lat: 46.97969436645502
    },
    {
        lng: 10.429244041442814,
        lat: 46.9786949157716
    },
    {
        lng: 10.430059432983398,
        lat: 46.97811508178722
    },
    {
        lng: 10.430996894836483,
        lat: 46.977573394775675
    },
    {
        lng: 10.431412696838379,
        lat: 46.977226257324446
    },
    {
        lng: 10.431757926940975,
        lat: 46.97674560546869
    },
    {
        lng: 10.431962013244572,
        lat: 46.976139068603516
    },
    {
        lng: 10.432026863098258,
        lat: 46.97485733032232
    },
    {
        lng: 10.431938171386662,
        lat: 46.97420501708996
    },
    {
        lng: 10.431533813476676,
        lat: 46.97283935546898
    },
    {
        lng: 10.431085586547852,
        lat: 46.970573425293026
    },
    {
        lng: 10.43083572387701,
        lat: 46.96995925903332
    },
    {
        lng: 10.43048095703125,
        lat: 46.96942138671881
    },
    {
        lng: 10.429681777954215,
        lat: 46.968425750732536
    },
    {
        lng: 10.429403305053825,
        lat: 46.96807861328131
    },
    {
        lng: 10.428482055664176,
        lat: 46.967235565185604
    },
    {
        lng: 10.428165435791072,
        lat: 46.96676254272478
    },
    {
        lng: 10.427301406860408,
        lat: 46.964385986328296
    },
    {
        lng: 10.427228927612362,
        lat: 46.96346664428722
    },
    {
        lng: 10.427308082580623,
        lat: 46.962852478027514
    },
    {
        lng: 10.4275999069215,
        lat: 46.962074279785156
    },
    {
        lng: 10.428133964538631,
        lat: 46.96113586425798
    },
    {
        lng: 10.428556442260799,
        lat: 46.96065902709978
    },
    {
        lng: 10.429136276245174,
        lat: 46.96021652221708
    },
    {
        lng: 10.429815292358398,
        lat: 46.95986175537132
    },
    {
        lng: 10.431488037109432,
        lat: 46.959262847900675
    },
    {
        lng: 10.43315315246582,
        lat: 46.95859146118164
    },
    {
        lng: 10.433806419372672,
        lat: 46.95832443237316
    },
    {
        lng: 10.435438156127987,
        lat: 46.95734786987333
    },
    {
        lng: 10.43693923950201,
        lat: 46.95669174194359
    },
    {
        lng: 10.4402694702149,
        lat: 46.95617294311529
    },
    {
        lng: 10.44236087799078,
        lat: 46.955848693847884
    },
    {
        lng: 10.445735931396598,
        lat: 46.955028533935604
    },
    {
        lng: 10.448137283325252,
        lat: 46.954586029052734
    },
    {
        lng: 10.452489852905217,
        lat: 46.95359420776367
    },
    {
        lng: 10.453290939331055,
        lat: 46.95351028442394
    },
    {
        lng: 10.45400619506836,
        lat: 46.953437805176065
    },
    {
        lng: 10.459111213684196,
        lat: 46.95318222045893
    },
    {
        lng: 10.460035324096737,
        lat: 46.9530563354495
    },
    {
        lng: 10.460849761962834,
        lat: 46.95283126831055
    },
    {
        lng: 10.461811065673828,
        lat: 46.952365875244254
    },
    {
        lng: 10.463274955749625,
        lat: 46.95188903808605
    },
    {
        lng: 10.463976860046444,
        lat: 46.95159912109392
    },
    {
        lng: 10.46564769744873,
        lat: 46.95048904418974
    },
    {
        lng: 10.466390609741325,
        lat: 46.94987487792969
    },
    {
        lng: 10.466831207275447,
        lat: 46.94959259033209
    },
    {
        lng: 10.46809005737299,
        lat: 46.94878005981457
    },
    {
        lng: 10.46881103515625,
        lat: 46.94852066040062
    },
    {
        lng: 10.4702405929566,
        lat: 46.94800186157255
    },
    {
        lng: 10.471943855285645,
        lat: 46.94691467285162
    },
    {
        lng: 10.472321510314998,
        lat: 46.946605682373274
    },
    {
        lng: 10.47269535064703,
        lat: 46.94630050659191
    },
    {
        lng: 10.472789764404354,
        lat: 46.94623565673828
    },
    {
        lng: 10.47438812255865,
        lat: 46.94514465332031
    },
    {
        lng: 10.47497367858898,
        lat: 46.94486236572294
    },
    {
        lng: 10.478155136108455,
        lat: 46.94333267211914
    },
    {
        lng: 10.47898197174078,
        lat: 46.94272613525396
    },
    {
        lng: 10.479840278625545,
        lat: 46.94217300415039
    },
    {
        lng: 10.481032371521053,
        lat: 46.941402435302905
    },
    {
        lng: 10.48210144042963,
        lat: 46.940963745117415
    },
    {
        lng: 10.482835769653377,
        lat: 46.94065856933622
    },
    {
        lng: 10.48359584808361,
        lat: 46.940414428711165
    },
    {
        lng: 10.484563827514648,
        lat: 46.94022750854492
    },
    {
        lng: 10.486600875854606,
        lat: 46.94001007080101
    },
    {
        lng: 10.487577438354606,
        lat: 46.93990707397484
    },
    {
        lng: 10.48885822296154,
        lat: 46.93959045410173
    },
    {
        lng: 10.490350723266545,
        lat: 46.9390563964846
    },
    {
        lng: 10.491122245788688,
        lat: 46.938697814941634
    },
    {
        lng: 10.491570472717342,
        lat: 46.938339233398494
    },
    {
        lng: 10.491900444030705,
        lat: 46.937805175781534
    },
    {
        lng: 10.491974830627498,
        lat: 46.937477111816406
    },
    {
        lng: 10.491936683654842,
        lat: 46.936912536621264
    },
    {
        lng: 10.49137973785406,
        lat: 46.935115814208984
    },
    {
        lng: 10.49106502532959,
        lat: 46.93444061279314
    },
    {
        lng: 10.490016937255803,
        lat: 46.93284988403343
    },
    {
        lng: 10.489986419677848,
        lat: 46.932804107666016
    },
    {
        lng: 10.4896821975708,
        lat: 46.93194961547857
    },
    {
        lng: 10.489630699157772,
        lat: 46.9315071105957
    },
    {
        lng: 10.489790916442928,
        lat: 46.93087005615257
    },
    {
        lng: 10.490080833435172,
        lat: 46.93032073974615
    },
    {
        lng: 10.490288734436149,
        lat: 46.93007278442411
    },
    {
        lng: 10.490593910217285,
        lat: 46.929847717285156
    },
    {
        lng: 10.491057395935002,
        lat: 46.92950439453125
    },
    {
        lng: 10.492473602295036,
        lat: 46.928283691406364
    },
    {
        lng: 10.49265098571783,
        lat: 46.928012847900334
    },
    {
        lng: 10.49283504486084,
        lat: 46.927398681640625
    },
    {
        lng: 10.49283885955822,
        lat: 46.92673110961914
    },
    {
        lng: 10.49240779876709,
        lat: 46.924964904785384
    },
    {
        lng: 10.492261886596737,
        lat: 46.923831939697266
    },
    {
        lng: 10.492102622985897,
        lat: 46.91839981079107
    },
    {
        lng: 10.491997718811149,
        lat: 46.917259216308594
    },
    {
        lng: 10.491830825805778,
        lat: 46.916530609131144
    },
    {
        lng: 10.491179466247615,
        lat: 46.914821624756144
    },
    {
        lng: 10.4908065795899,
        lat: 46.91423034667969
    },
    {
        lng: 10.49019718170166,
        lat: 46.913776397705305
    },
    {
        lng: 10.488394737243652,
        lat: 46.913124084472884
    },
    {
        lng: 10.48562335968029,
        lat: 46.911609649658146
    },
    {
        lng: 10.483708381652946,
        lat: 46.91102600097679
    },
    {
        lng: 10.483665466308594,
        lat: 46.91101455688488
    },
    {
        lng: 10.483322143554688,
        lat: 46.91086578369146
    },
    {
        lng: 10.48326492309576,
        lat: 46.91084289550804
    },
    {
        lng: 10.482561111450195,
        lat: 46.91030502319353
    },
    {
        lng: 10.482179641723633,
        lat: 46.90965652465832
    },
    {
        lng: 10.482024192810115,
        lat: 46.90903091430687
    },
    {
        lng: 10.482004165649414,
        lat: 46.90895080566412
    },
    {
        lng: 10.48191070556635,
        lat: 46.907402038574446
    },
    {
        lng: 10.48184585571289,
        lat: 46.9063262939456
    },
    {
        lng: 10.481664657592887,
        lat: 46.905223846435774
    },
    {
        lng: 10.481440544128418,
        lat: 46.90463638305687
    },
    {
        lng: 10.480836868286133,
        lat: 46.90358734130882
    },
    {
        lng: 10.479716300964355,
        lat: 46.90233612060558
    },
    {
        lng: 10.478872299194336,
        lat: 46.9002227783206
    },
    {
        lng: 10.478553771972656,
        lat: 46.898864746093864
    },
    {
        lng: 10.478242874145565,
        lat: 46.89583206176758
    },
    {
        lng: 10.477536201477108,
        lat: 46.89371490478527
    },
    {
        lng: 10.477231979370174,
        lat: 46.89309310913097
    },
    {
        lng: 10.476755142211971,
        lat: 46.892578125000114
    },
    {
        lng: 10.4754514694215,
        lat: 46.89151763916033
    },
    {
        lng: 10.473876953125114,
        lat: 46.89048004150402
    },
    {
        lng: 10.472842216491642,
        lat: 46.889797210693416
    },
    {
        lng: 10.471109390258789,
        lat: 46.888545989990234
    },
    {
        lng: 10.468923568725586,
        lat: 46.886478424072266
    },
    {
        lng: 10.468464851379508,
        lat: 46.885932922363395
    },
    {
        lng: 10.468309402465877,
        lat: 46.88563919067411
    },
    {
        lng: 10.468228340148983,
        lat: 46.885330200195426
    },
    {
        lng: 10.468312263488713,
        lat: 46.88478851318371
    },
    {
        lng: 10.468897819519043,
        lat: 46.88380050659197
    },
    {
        lng: 10.469344139099121,
        lat: 46.88330459594732
    },
    {
        lng: 10.471542358398494,
        lat: 46.881797790527514
    },
    {
        lng: 10.471725463867244,
        lat: 46.881637573242415
    },
    {
        lng: 10.472063064575195,
        lat: 46.88134002685575
    },
    {
        lng: 10.472693443298454,
        lat: 46.88033676147461
    },
    {
        lng: 10.472996711731014,
        lat: 46.87940216064459
    },
    {
        lng: 10.47320938110363,
        lat: 46.875255584717024
    },
    {
        lng: 10.47364711761486,
        lat: 46.87342453002958
    },
    {
        lng: 10.473786354064941,
        lat: 46.872306823730696
    },
    {
        lng: 10.473839759826717,
        lat: 46.870399475097884
    },
    {
        lng: 10.473785400390625,
        lat: 46.868095397949446
    },
    {
        lng: 10.473652839660588,
        lat: 46.8671569824221
    },
    {
        lng: 10.473519325256461,
        lat: 46.86621856689476
    },
    {
        lng: 10.472332000732422,
        lat: 46.863334655761946
    },
    {
        lng: 10.472130775451717,
        lat: 46.862606048583984
    },
    {
        lng: 10.47199821472168,
        lat: 46.86148071289074
    },
    {
        lng: 10.471996307373104,
        lat: 46.860351562500114
    },
    {
        lng: 10.472228050231934,
        lat: 46.85888290405279
    },
    {
        lng: 10.472500801086369,
        lat: 46.85797119140625
    },
    {
        lng: 10.473406791686955,
        lat: 46.85493469238304
    },
    {
        lng: 10.473837852478141,
        lat: 46.85237884521479
    },
    {
        lng: 10.474224090576172,
        lat: 46.850929260254134
    },
    {
        lng: 10.474264144897461,
        lat: 46.84983444213867
    },
    {
        lng: 10.474053382873592,
        lat: 46.8488655090332
    },
    {
        lng: 10.473527908325309,
        lat: 46.84738159179699
    },
    {
        lng: 10.473217964172477,
        lat: 46.84675979614252
    },
    {
        lng: 10.47273921966547,
        lat: 46.846248626708984
    },
    {
        lng: 10.470650672912711,
        lat: 46.84453201293945
    },
    {
        lng: 10.469506263733024,
        lat: 46.842948913574446
    },
    {
        lng: 10.46825313568121,
        lat: 46.8419189453125
    },
    {
        lng: 10.467457771301326,
        lat: 46.841262817382926
    },
    {
        lng: 10.467063903808594,
        lat: 46.84075546264671
    },
    {
        lng: 10.466939926147575,
        lat: 46.840164184570426
    },
    {
        lng: 10.467056274414062,
        lat: 46.83958435058605
    },
    {
        lng: 10.467202186584586,
        lat: 46.83932876586914
    },
    {
        lng: 10.467749595642204,
        lat: 46.83879852294933
    },
    {
        lng: 10.467850685119629,
        lat: 46.83869934082031
    },
    {
        lng: 10.468203544616813,
        lat: 46.838169097900334
    },
    {
        lng: 10.468813896179313,
        lat: 46.83649826049822
    },
    {
        lng: 10.468855857849178,
        lat: 46.835941314697266
    },
    {
        lng: 10.468599319458121,
        lat: 46.83527755737333
    },
    {
        lng: 10.46818542480463,
        lat: 46.834865570068416
    },
    {
        lng: 10.4675874710083,
        lat: 46.834526062011946
    },
    {
        lng: 10.46384334564209,
        lat: 46.83323287963867
    },
    {
        lng: 10.46259975433361,
        lat: 46.83285140991211
    },
    {
        lng: 10.462136268615723,
        lat: 46.83256149291998
    },
    {
        lng: 10.461840629577637,
        lat: 46.83237838745123
    },
    {
        lng: 10.461029052734432,
        lat: 46.831363677978516
    },
    {
        lng: 10.460798263549862,
        lat: 46.83107376098644
    },
    {
        lng: 10.460551261901912,
        lat: 46.83084487915045
    },
    {
        lng: 10.460040092468375,
        lat: 46.83037185668945
    },
    {
        lng: 10.459371566772404,
        lat: 46.82926559448242
    },
    {
        lng: 10.459150314331168,
        lat: 46.82845306396507
    },
    {
        lng: 10.459220886230412,
        lat: 46.827766418457145
    },
    {
        lng: 10.45984268188488,
        lat: 46.82644653320307
    },
    {
        lng: 10.460533142089957,
        lat: 46.825439453125114
    },
    {
        lng: 10.46092605590826,
        lat: 46.82486724853527
    },
    {
        lng: 10.461044311523494,
        lat: 46.824546813964844
    },
    {
        lng: 10.46111011505127,
        lat: 46.82388687133789
    },
    {
        lng: 10.460944175720215,
        lat: 46.82325744628906
    },
    {
        lng: 10.460760116577148,
        lat: 46.82297515869169
    },
    {
        lng: 10.459174156189079,
        lat: 46.821685791015625
    },
    {
        lng: 10.458066940307617,
        lat: 46.82098770141613
    },
    {
        lng: 10.457720756530875,
        lat: 46.8206138610841
    },
    {
        lng: 10.45743656158453,
        lat: 46.81998825073248
    },
    {
        lng: 10.457365036010799,
        lat: 46.81930923461914
    },
    {
        lng: 10.457463264465332,
        lat: 46.818622589111555
    },
    {
        lng: 10.45789813995367,
        lat: 46.81712341308594
    },
    {
        lng: 10.458332061767692,
        lat: 46.81562805175781
    },
    {
        lng: 10.458479881286621,
        lat: 46.815120697021484
    },
    {
        lng: 10.458522796630973,
        lat: 46.81442642211937
    },
    {
        lng: 10.458406448364315,
        lat: 46.81383895874046
    },
    {
        lng: 10.457957267761174,
        lat: 46.81270980834984
    },
    {
        lng: 10.456916809082145,
        lat: 46.81106567382835
    },
    {
        lng: 10.456196784973201,
        lat: 46.80952453613281
    },
    {
        lng: 10.455203056335506,
        lat: 46.808284759521484
    },
    {
        lng: 10.454502105713004,
        lat: 46.80673980712902
    },
    {
        lng: 10.452199935913143,
        lat: 46.8030891418457
    },
    {
        lng: 10.451666831970272,
        lat: 46.80176544189459
    },
    {
        lng: 10.45130920410162,
        lat: 46.801124572753906
    },
    {
        lng: 10.450566291809025,
        lat: 46.80041122436518
    },
    {
        lng: 10.449521064758358,
        lat: 46.799850463867244
    },
    {
        lng: 10.446855545044059,
        lat: 46.79881286621094
    },
    {
        lng: 10.443265914917049,
        lat: 46.79754257202143
    },
    {
        lng: 10.441438674926815,
        lat: 46.79705047607433
    },
    {
        lng: 10.440975189209098,
        lat: 46.796977996826115
    },
    {
        lng: 10.440497398376408,
        lat: 46.796897888183594
    },
    {
        lng: 10.437060356140194,
        lat: 46.7966766357423
    },
    {
        lng: 10.435608863830566,
        lat: 46.796527862548885
    },
    {
        lng: 10.433311462402344,
        lat: 46.796100616455305
    },
    {
        lng: 10.432481765747127,
        lat: 46.7958602905274
    },
    {
        lng: 10.432107925415096,
        lat: 46.79568099975603
    },
    {
        lng: 10.431523323059082,
        lat: 46.79520416259783
    },
    {
        lng: 10.43069171905529,
        lat: 46.79422378540045
    },
    {
        lng: 10.430359840393123,
        lat: 46.79369354248047
    },
    {
        lng: 10.430110931396598,
        lat: 46.793037414551065
    },
    {
        lng: 10.429928779602164,
        lat: 46.79203033447271
    },
    {
        lng: 10.430231094360465,
        lat: 46.789443969726506
    },
    {
        lng: 10.430195808410645,
        lat: 46.78903961181652
    },
    {
        lng: 10.430023193359489,
        lat: 46.788639068603516
    },
    {
        lng: 10.429452896118221,
        lat: 46.78812789916998
    },
    {
        lng: 10.427975654602108,
        lat: 46.78731918334984
    },
    {
        lng: 10.426309585571289,
        lat: 46.78617477417015
    },
    {
        lng: 10.427692413330078,
        lat: 46.785697937011946
    },
    {
        lng: 10.428216934204102,
        lat: 46.78546524047857
    },
    {
        lng: 10.42902660369873,
        lat: 46.78489685058611
    },
    {
        lng: 10.429878234863281,
        lat: 46.78438949584955
    },
    {
        lng: 10.430583953857422,
        lat: 46.783794403076286
    },
    {
        lng: 10.431395530700684,
        lat: 46.78329086303711
    },
    {
        lng: 10.432090759277457,
        lat: 46.78269577026367
    },
    {
        lng: 10.432900428772086,
        lat: 46.78219223022484
    },
    {
        lng: 10.433592796325797,
        lat: 46.78160858154308
    },
    {
        lng: 10.434397697448787,
        lat: 46.78111267089844
    },
    {
        lng: 10.435088157653865,
        lat: 46.78053283691406
    },
    {
        lng: 10.435890197753963,
        lat: 46.780044555664176
    },
    {
        lng: 10.43657588958746,
        lat: 46.779468536377124
    },
    {
        lng: 10.436761856079045,
        lat: 46.77935409545904
    },
    {
        lng: 10.43736457824707,
        lat: 46.77898025512701
    },
    {
        lng: 10.438021659851131,
        lat: 46.778392791748104
    },
    {
        lng: 10.438563346862793,
        lat: 46.77798843383812
    },
    {
        lng: 10.438886642456112,
        lat: 46.77763366699219
    },
    {
        lng: 10.439419746398983,
        lat: 46.776618957519645
    },
    {
        lng: 10.43961143493658,
        lat: 46.776256561279524
    },
    {
        lng: 10.440276145935172,
        lat: 46.77557373046875
    },
    {
        lng: 10.440445899963436,
        lat: 46.77539825439476
    },
    {
        lng: 10.440934181213322,
        lat: 46.774452209472884
    },
    {
        lng: 10.441255569458065,
        lat: 46.7740211486817
    },
    {
        lng: 10.443322181701717,
        lat: 46.772403717041186
    },
    {
        lng: 10.443688392639217,
        lat: 46.77199554443382
    },
    {
        lng: 10.44397830963129,
        lat: 46.771133422851506
    },
    {
        lng: 10.444081306457463,
        lat: 46.76984024047863
    },
    {
        lng: 10.444096565246582,
        lat: 46.76713943481451
    },
    {
        lng: 10.444136619567814,
        lat: 46.759918212890796
    },
    {
        lng: 10.444175720214844,
        lat: 46.75877380371111
    },
    {
        lng: 10.444317817687988,
        lat: 46.754611968994254
    },
    {
        lng: 10.444308280944881,
        lat: 46.75432968139671
    },
    {
        lng: 10.444281578064079,
        lat: 46.75360107421875
    },
    {
        lng: 10.444226264953727,
        lat: 46.75334930419933
    },
    {
        lng: 10.44414138793951,
        lat: 46.75296020507824
    },
    {
        lng: 10.444004058838004,
        lat: 46.752651214599666
    },
    {
        lng: 10.443585395813045,
        lat: 46.75216293334961
    },
    {
        lng: 10.443127632141056,
        lat: 46.75188827514654
    },
    {
        lng: 10.443052291870174,
        lat: 46.751846313476676
    },
    {
        lng: 10.442643165588322,
        lat: 46.75170516967779
    },
    {
        lng: 10.442216873169002,
        lat: 46.75160980224615
    },
    {
        lng: 10.441327095031795,
        lat: 46.75151443481451
    },
    {
        lng: 10.439769744873104,
        lat: 46.75146865844749
    },
    {
        lng: 10.438510894775504,
        lat: 46.75143051147478
    },
    {
        lng: 10.437135696411076,
        lat: 46.751289367675724
    },
    {
        lng: 10.435254096984977,
        lat: 46.75078964233427
    },
    {
        lng: 10.434056282043514,
        lat: 46.75017547607422
    },
    {
        lng: 10.43246841430664,
        lat: 46.749687194824276
    },
    {
        lng: 10.431888580322266,
        lat: 46.74943923950207
    },
    {
        lng: 10.430324554443303,
        lat: 46.74831771850597
    },
    {
        lng: 10.429636001586971,
        lat: 46.747692108154354
    },
    {
        lng: 10.428056716918945,
        lat: 46.74657821655285
    },
    {
        lng: 10.426028251648006,
        lat: 46.74578857421875
    },
    {
        lng: 10.425586700439453,
        lat: 46.74548339843773
    },
    {
        lng: 10.423628807067928,
        lat: 46.74414443969721
    },
    {
        lng: 10.421601295471248,
        lat: 46.74335861206066
    },
    {
        lng: 10.419980049133358,
        lat: 46.74226379394537
    },
    {
        lng: 10.419219017028865,
        lat: 46.74164581298851
    },
    {
        lng: 10.417718887329102,
        lat: 46.740718841552734
    },
    {
        lng: 10.417012214660645,
        lat: 46.740062713623104
    },
    {
        lng: 10.416378021240348,
        lat: 46.73928070068354
    },
    {
        lng: 10.415705680847225,
        lat: 46.73859024047863
    },
    {
        lng: 10.414821624755973,
        lat: 46.73682022094732
    },
    {
        lng: 10.414621353149528,
        lat: 46.736576080322266
    },
    {
        lng: 10.414120674133414,
        lat: 46.736129760742415
    },
    {
        lng: 10.413518905639705,
        lat: 46.735771179199276
    },
    {
        lng: 10.413187026977596,
        lat: 46.73563385009777
    },
    {
        lng: 10.411870002746582,
        lat: 46.735229492187614
    },
    {
        lng: 10.41129398345953,
        lat: 46.73499298095726
    },
    {
        lng: 10.409660339355469,
        lat: 46.73391342163109
    },
    {
        lng: 10.408659934997502,
        lat: 46.733165740967024
    },
    {
        lng: 10.407375335693416,
        lat: 46.73250579834007
    },
    {
        lng: 10.406906127929801,
        lat: 46.732269287109375
    },
    {
        lng: 10.405312538146973,
        lat: 46.731452941894474
    },
    {
        lng: 10.406997680664062,
        lat: 46.730842590332145
    },
    {
        lng: 10.408248901367301,
        lat: 46.730022430419865
    },
    {
        lng: 10.409018516540641,
        lat: 46.72964477539085
    },
    {
        lng: 10.409928321838436,
        lat: 46.729198455810604
    },
    {
        lng: 10.411266326904297,
        lat: 46.72833251953131
    },
    {
        lng: 10.412485122680778,
        lat: 46.727340698242415
    },
    {
        lng: 10.413187980651855,
        lat: 46.7264862060548
    },
    {
        lng: 10.413637161254996,
        lat: 46.725284576416016
    },
    {
        lng: 10.413916587829647,
        lat: 46.724536895752124
    },
    {
        lng: 10.415284156799373,
        lat: 46.72233200073248
    },
    {
        lng: 10.415767669677791,
        lat: 46.72179794311552
    },
    {
        lng: 10.416646003723145,
        lat: 46.72108459472662
    },
    {
        lng: 10.417613983154297,
        lat: 46.7204856872558
    },
    {
        lng: 10.418930053711051,
        lat: 46.71985244750982
    },
    {
        lng: 10.4193372726441,
        lat: 46.719547271728516
    },
    {
        lng: 10.420439720153922,
        lat: 46.718730926513786
    },
    {
        lng: 10.420735359191951,
        lat: 46.718353271484375
    },
    {
        lng: 10.421142578125,
        lat: 46.71751785278343
    },
    {
        lng: 10.421263694763184,
        lat: 46.71704483032238
    },
    {
        lng: 10.421255111694336,
        lat: 46.716285705566634
    },
    {
        lng: 10.42058086395258,
        lat: 46.71268081665039
    },
    {
        lng: 10.419914245605582,
        lat: 46.71087265014654
    },
    {
        lng: 10.418967247009277,
        lat: 46.70894622802729
    },
    {
        lng: 10.417656898498478,
        lat: 46.70733261108393
    },
    {
        lng: 10.416975021362418,
        lat: 46.70683670043968
    },
    {
        lng: 10.41522216796875,
        lat: 46.70621871948259
    },
    {
        lng: 10.414092063903865,
        lat: 46.70564270019537
    },
    {
        lng: 10.4132337570191,
        lat: 46.70539474487333
    },
    {
        lng: 10.412961006164664,
        lat: 46.705329895019474
    },
    {
        lng: 10.412347793579045,
        lat: 46.70519256591797
    },
    {
        lng: 10.41151332855219,
        lat: 46.70510101318388
    },
    {
        lng: 10.408893585205135,
        lat: 46.705078125000114
    },
    {
        lng: 10.407617568969727,
        lat: 46.70500564575224
    },
    {
        lng: 10.406821250915584,
        lat: 46.70483779907238
    },
    {
        lng: 10.406288146972713,
        lat: 46.70459365844732
    },
    {
        lng: 10.405668258667049,
        lat: 46.704082489013615
    },
    {
        lng: 10.404537200927848,
        lat: 46.70280075073242
    },
    {
        lng: 10.403051376342887,
        lat: 46.700786590576286
    },
    {
        lng: 10.402158737182617,
        lat: 46.699150085449276
    },
    {
        lng: 10.401179313659668,
        lat: 46.69827270507807
    },
    {
        lng: 10.400399208068904,
        lat: 46.69697189331066
    },
    {
        lng: 10.398036956787223,
        lat: 46.69440841674805
    },
    {
        lng: 10.397103309631348,
        lat: 46.692771911621264
    },
    {
        lng: 10.396683692932072,
        lat: 46.69237136840826
    },
    {
        lng: 10.395785331726017,
        lat: 46.69193649291992
    },
    {
        lng: 10.39476013183588,
        lat: 46.691715240478516
    },
    {
        lng: 10.392093658447322,
        lat: 46.691261291503906
    },
    {
        lng: 10.389847755432129,
        lat: 46.69100952148449
    },
    {
        lng: 10.388725280761776,
        lat: 46.690715789795036
    },
    {
        lng: 10.387646675109863,
        lat: 46.690166473388615
    },
    {
        lng: 10.387100219726676,
        lat: 46.68970489501953
    },
    {
        lng: 10.386904716491756,
        lat: 46.689441680908374
    },
    {
        lng: 10.386775016784782,
        lat: 46.68913650512701
    },
    {
        lng: 10.386758804321403,
        lat: 46.68853378295921
    },
    {
        lng: 10.387160301208496,
        lat: 46.68771362304693
    },
    {
        lng: 10.387543678283691,
        lat: 46.68724441528332
    },
    {
        lng: 10.388457298278809,
        lat: 46.68647766113298
    },
    {
        lng: 10.38934135437023,
        lat: 46.68531036376953
    },
    {
        lng: 10.390052795410156,
        lat: 46.68471908569359
    },
    {
        lng: 10.390324592590332,
        lat: 46.68449783325195
    },
    {
        lng: 10.391513824462947,
        lat: 46.68313598632818
    },
    {
        lng: 10.392101287841854,
        lat: 46.682205200195426
    },
    {
        lng: 10.392670631408635,
        lat: 46.680843353271484
    },
    {
        lng: 10.392832756042537,
        lat: 46.67972183227539
    },
    {
        lng: 10.39293098449707,
        lat: 46.675987243652514
    },
    {
        lng: 10.393327713012695,
        lat: 46.67420959472685
    },
    {
        lng: 10.393448829650822,
        lat: 46.673122406005916
    },
    {
        lng: 10.39350414276123,
        lat: 46.668819427490234
    },
    {
        lng: 10.393597602844238,
        lat: 46.668109893799055
    },
    {
        lng: 10.394020080566406,
        lat: 46.66640853881859
    },
    {
        lng: 10.394280433654842,
        lat: 46.66446304321289
    },
    {
        lng: 10.394600868225098,
        lat: 46.66291046142584
    },
    {
        lng: 10.396908760070858,
        lat: 46.65969848632824
    },
    {
        lng: 10.397499084472713,
        lat: 46.658771514892805
    },
    {
        lng: 10.397968292236328,
        lat: 46.657878875732536
    },
    {
        lng: 10.398255348205566,
        lat: 46.65708541870123
    },
    {
        lng: 10.39867305755621,
        lat: 46.65515518188499
    },
    {
        lng: 10.399102210998592,
        lat: 46.653556823730526
    },
    {
        lng: 10.39940547943121,
        lat: 46.65321731567383
    },
    {
        lng: 10.400645256042537,
        lat: 46.65182113647455
    },
    {
        lng: 10.401400566101131,
        lat: 46.650867462158146
    },
    {
        lng: 10.402890205383244,
        lat: 46.64866638183605
    },
    {
        lng: 10.403349876403809,
        lat: 46.64777755737316
    },
    {
        lng: 10.403519630432243,
        lat: 46.647087097168026
    },
    {
        lng: 10.403590202331486,
        lat: 46.64640426635748
    },
    {
        lng: 10.403586387634334,
        lat: 46.64191055297846
    },
    {
        lng: 10.403956413269157,
        lat: 46.638477325439396
    },
    {
        lng: 10.410161972045842,
        lat: 46.63764190673828
    },
    {
        lng: 10.411391258239746,
        lat: 46.6376113891601
    },
    {
        lng: 10.412639617919922,
        lat: 46.63770294189476
    },
    {
        lng: 10.416539192199764,
        lat: 46.63833618164074
    },
    {
        lng: 10.418375968933162,
        lat: 46.63869857788103
    },
    {
        lng: 10.424469947814998,
        lat: 46.639621734619084
    },
    {
        lng: 10.42632961273199,
        lat: 46.6399497985841
    },
    {
        lng: 10.428465843200797,
        lat: 46.64022827148449
    },
    {
        lng: 10.428756713867188,
        lat: 46.640277862549055
    },
    {
        lng: 10.430331230163574,
        lat: 46.64055252075207
    },
    {
        lng: 10.43247222900402,
        lat: 46.6408233642581
    },
    {
        lng: 10.434342384338436,
        lat: 46.6411399841308
    },
    {
        lng: 10.436484336853141,
        lat: 46.64140319824247
    },
    {
        lng: 10.438350677490234,
        lat: 46.641727447509766
    },
    {
        lng: 10.440482139587516,
        lat: 46.64201736450201
    },
    {
        lng: 10.444808959960994,
        lat: 46.64289474487322
    },
    {
        lng: 10.445729255676326,
        lat: 46.64297866821295
    },
    {
        lng: 10.44712924957281,
        lat: 46.64299392700207
    },
    {
        lng: 10.448049545288143,
        lat: 46.64292907714872
    },
    {
        lng: 10.448941230773983,
        lat: 46.64277648925781
    },
    {
        lng: 10.450070381164608,
        lat: 46.64240264892578
    },
    {
        lng: 10.451862335205192,
        lat: 46.64163589477545
    },
    {
        lng: 10.453537940979004,
        lat: 46.64051437377941
    },
    {
        lng: 10.454272270202694,
        lat: 46.639907836914006
    },
    {
        lng: 10.455107688903865,
        lat: 46.639381408691406
    },
    {
        lng: 10.455857276916504,
        lat: 46.63878631591797
    },
    {
        lng: 10.457363128662223,
        lat: 46.63787841796898
    },
    {
        lng: 10.457889556884822,
        lat: 46.63763427734375
    },
    {
        lng: 10.459804534912166,
        lat: 46.637054443359375
    },
    {
        lng: 10.46155166625988,
        lat: 46.63602447509771
    },
    {
        lng: 10.462309837341309,
        lat: 46.635429382324446
    },
    {
        lng: 10.463156700134391,
        lat: 46.634902954101676
    },
    {
        lng: 10.463897705078182,
        lat: 46.63430404663086
    },
    {
        lng: 10.464741706848145,
        lat: 46.63377380371122
    },
    {
        lng: 10.465496063232479,
        lat: 46.633178710937614
    },
    {
        lng: 10.465874671936149,
        lat: 46.63293457031273
    },
    {
        lng: 10.465974807739258,
        lat: 46.63287353515625
    },
    {
        lng: 10.467227935791072,
        lat: 46.63207626342796
    },
    {
        lng: 10.471103668213004,
        lat: 46.63038635253906
    },
    {
        lng: 10.472833633422852,
        lat: 46.62928771972656
    },
    {
        lng: 10.475191116333065,
        lat: 46.62757492065458
    },
    {
        lng: 10.476069450378361,
        lat: 46.62705230712896
    },
    {
        lng: 10.47689437866211,
        lat: 46.62646102905268
    },
    {
        lng: 10.478513717651424,
        lat: 46.62559509277338
    },
    {
        lng: 10.479048728942871,
        lat: 46.625198364257926
    },
    {
        lng: 10.480034828185978,
        lat: 46.62427520751953
    },
    {
        lng: 10.481734275817871,
        lat: 46.62309646606468
    },
    {
        lng: 10.483569145202637,
        lat: 46.62216186523432
    },
    {
        lng: 10.487034797668457,
        lat: 46.6199836730957
    },
    {
        lng: 10.487865447998104,
        lat: 46.61955642700224
    },
    {
        lng: 10.488758087158203,
        lat: 46.61922073364258
    },
    {
        lng: 10.49230098724371,
        lat: 46.6185874938966
    },
    {
        lng: 10.49357986450201,
        lat: 46.61822128295927
    },
    {
        lng: 10.494152069091854,
        lat: 46.6179313659668
    },
    {
        lng: 10.49424076080328,
        lat: 46.617851257324446
    },
    {
        lng: 10.494593620300293,
        lat: 46.617549896240234
    },
    {
        lng: 10.494795799255428,
        lat: 46.61726760864286
    },
    {
        lng: 10.495031356811467,
        lat: 46.616664886474666
    },
    {
        lng: 10.495112419128532,
        lat: 46.616035461426065
    },
    {
        lng: 10.495087623596305,
        lat: 46.61552047729498
    },
    {
        lng: 10.49508190155035,
        lat: 46.615398406982365
    },
    {
        lng: 10.494949340820312,
        lat: 46.61476516723644
    },
    {
        lng: 10.494720458984375,
        lat: 46.6142272949221
    },
    {
        lng: 10.494320869445858,
        lat: 46.61358261108421
    },
    {
        lng: 10.493820190429744,
        lat: 46.612979888916016
    },
    {
        lng: 10.492211341858024,
        lat: 46.61178588867193
    },
    {
        lng: 10.491330146789664,
        lat: 46.611274719238395
    },
    {
        lng: 10.490544319152889,
        lat: 46.61070251464855
    },
    {
        lng: 10.489656448364258,
        lat: 46.610202789306754
    },
    {
        lng: 10.48888969421398,
        lat: 46.60968017578148
    },
    {
        lng: 10.488059997558594,
        lat: 46.609115600585994
    },
    {
        lng: 10.487746238708553,
        lat: 46.60878372192383
    },
    {
        lng: 10.48758697509777,
        lat: 46.6083869934082
    },
    {
        lng: 10.487591743469238,
        lat: 46.60824203491234
    },
    {
        lng: 10.487607955932617,
        lat: 46.60773468017578
    },
    {
        lng: 10.487784385681266,
        lat: 46.60696029663097
    },
    {
        lng: 10.488153457641658,
        lat: 46.606243133544865
    },
    {
        lng: 10.488809585571403,
        lat: 46.60557937622093
    },
    {
        lng: 10.489095687866325,
        lat: 46.60511779785162
    },
    {
        lng: 10.489365577697868,
        lat: 46.60432052612316
    },
    {
        lng: 10.48960399627697,
        lat: 46.603099822998274
    },
    {
        lng: 10.489335060119743,
        lat: 46.60155105590843
    },
    {
        lng: 10.489062309265137,
        lat: 46.60066604614269
    },
    {
        lng: 10.488069534301701,
        lat: 46.59902954101574
    },
    {
        lng: 10.487737655639762,
        lat: 46.598155975341854
    },
    {
        lng: 10.487460136413631,
        lat: 46.59678649902355
    },
    {
        lng: 10.487494468688965,
        lat: 46.59612655639643
    },
    {
        lng: 10.487751960754338,
        lat: 46.59550476074236
    },
    {
        lng: 10.48801326751709,
        lat: 46.59510803222656
    },
    {
        lng: 10.488375663757381,
        lat: 46.594772338867415
    },
    {
        lng: 10.490703582763729,
        lat: 46.59318161010765
    },
    {
        lng: 10.490942955017147,
        lat: 46.59281921386719
    },
    {
        lng: 10.490999221801758,
        lat: 46.592418670654524
    },
    {
        lng: 10.490861892700252,
        lat: 46.59176635742193
    },
    {
        lng: 10.49066257476818,
        lat: 46.59129714965826
    },
    {
        lng: 10.489091873168945,
        lat: 46.5890808105471
    },
    {
        lng: 10.487163543701115,
        lat: 46.58656692504911
    },
    {
        lng: 10.484868049621696,
        lat: 46.584003448486555
    },
    {
        lng: 10.485825538635254,
        lat: 46.58306121826166
    },
    {
        lng: 10.48715686798107,
        lat: 46.58197784423828
    },
    {
        lng: 10.487319946289062,
        lat: 46.5817108154298
    },
    {
        lng: 10.487424850463867,
        lat: 46.5811309814456
    },
    {
        lng: 10.487232208251953,
        lat: 46.580566406250114
    },
    {
        lng: 10.487005233764762,
        lat: 46.580318450927734
    },
    {
        lng: 10.486701965332031,
        lat: 46.58012771606445
    },
    {
        lng: 10.485321998596305,
        lat: 46.57969665527344
    },
    {
        lng: 10.48467922210699,
        lat: 46.579425811767805
    },
    {
        lng: 10.483082771301326,
        lat: 46.57856369018566
    },
    {
        lng: 10.482437133789176,
        lat: 46.578060150146484
    },
    {
        lng: 10.48150730133068,
        lat: 46.57674407959013
    },
    {
        lng: 10.480839729309196,
        lat: 46.5755500793457
    },
    {
        lng: 10.479968070983944,
        lat: 46.574214935302734
    },
    {
        lng: 10.479253768920898,
        lat: 46.572669982910384
    },
    {
        lng: 10.47822475433361,
        lat: 46.57147979736328
    },
    {
        lng: 10.477618217468262,
        lat: 46.57040405273443
    },
    {
        lng: 10.477104187011832,
        lat: 46.569808959961165
    },
    {
        lng: 10.47681903839117,
        lat: 46.56948089599621
    },
    {
        lng: 10.47653961181652,
        lat: 46.56890106201183
    },
    {
        lng: 10.4764280319215,
        lat: 46.56802749633795
    },
    {
        lng: 10.476411819458121,
        lat: 46.56790161132835
    },
    {
        lng: 10.476574897766113,
        lat: 46.56686019897484
    },
    {
        lng: 10.476849555969352,
        lat: 46.56624221801758
    },
    {
        lng: 10.477689743041992,
        lat: 46.56484222412132
    },
    {
        lng: 10.478404998779297,
        lat: 46.563167572021655
    },
    {
        lng: 10.479183197021484,
        lat: 46.561820983886776
    },
    {
        lng: 10.479358673095703,
        lat: 46.56113052368187
    },
    {
        lng: 10.479353904724178,
        lat: 46.56042861938505
    },
    {
        lng: 10.47902584075922,
        lat: 46.5594558715822
    },
    {
        lng: 10.477705955505314,
        lat: 46.55745315551775
    },
    {
        lng: 10.476753234863395,
        lat: 46.55615615844749
    },
    {
        lng: 10.475996971130371,
        lat: 46.55463027954124
    },
    {
        lng: 10.47518539428711,
        lat: 46.55366134643555
    },
    {
        lng: 10.474877357482853,
        lat: 46.55309295654325
    },
    {
        lng: 10.474160194396973,
        lat: 46.55073547363304
    },
    {
        lng: 10.47396183013916,
        lat: 46.548637390136946
    },
    {
        lng: 10.473700523376408,
        lat: 46.54767227172863
    },
    {
        lng: 10.47324085235607,
        lat: 46.54711532592768
    },
    {
        lng: 10.472888946533203,
        lat: 46.54690551757835
    },
    {
        lng: 10.472331047058105,
        lat: 46.546726226806584
    },
    {
        lng: 10.47207355499279,
        lat: 46.546642303467024
    },
    {
        lng: 10.469223022460994,
        lat: 46.546188354492244
    },
    {
        lng: 10.466121673584041,
        lat: 46.54604721069359
    },
    {
        lng: 10.464769363403434,
        lat: 46.5459060668947
    },
    {
        lng: 10.462761878967342,
        lat: 46.545555114746094
    },
    {
        lng: 10.461952209472656,
        lat: 46.54528427124046
    },
    {
        lng: 10.461603164672852,
        lat: 46.54507446289091
    },
    {
        lng: 10.461335182189885,
        lat: 46.54481124877941
    },
    {
        lng: 10.461028099060115,
        lat: 46.54420852661144
    },
    {
        lng: 10.460675239562931,
        lat: 46.5414848327639
    },
    {
        lng: 10.460477828979606,
        lat: 46.54092025756859
    },
    {
        lng: 10.460092544555664,
        lat: 46.54018783569347
    },
    {
        lng: 10.459773063659725,
        lat: 46.53976058959984
    },
    {
        lng: 10.459692001342887,
        lat: 46.539653778076286
    },
    {
        lng: 10.459444046020621,
        lat: 46.53944015502941
    },
    {
        lng: 10.45750808715826,
        lat: 46.537784576416016
    },
    {
        lng: 10.456625938415584,
        lat: 46.536537170410156
    },
    {
        lng: 10.456283569335994,
        lat: 46.53629302978527
    },
    {
        lng: 10.456008911132812,
        lat: 46.53609848022461
    },
    {
        lng: 10.45490646362316,
        lat: 46.535720825195426
    },
    {
        lng: 10.453857421875114,
        lat: 46.53556060791027
    },
    {
        lng: 10.45308971405035,
        lat: 46.53557968139671
    },
    {
        lng: 10.45205402374279,
        lat: 46.53577041625999
    },
    {
        lng: 10.450819969177246,
        lat: 46.536159515380916
    },
    {
        lng: 10.44960784912115,
        lat: 46.53679275512707
    },
    {
        lng: 10.447646141052303,
        lat: 46.53736877441429
    },
    {
        lng: 10.446419715881461,
        lat: 46.53796005249018
    },
    {
        lng: 10.444731712341309,
        lat: 46.53838348388683
    },
    {
        lng: 10.443183898925895,
        lat: 46.53906631469755
    },
    {
        lng: 10.441836357116756,
        lat: 46.53939437866234
    },
    {
        lng: 10.441205024719352,
        lat: 46.53961181640642
    },
    {
        lng: 10.439473152160758,
        lat: 46.540645599365234
    },
    {
        lng: 10.438031196594238,
        lat: 46.541889190673885
    },
    {
        lng: 10.437837600708065,
        lat: 46.54223251342796
    },
    {
        lng: 10.43774795532238,
        lat: 46.54239273071289
    },
    {
        lng: 10.437234878540096,
        lat: 46.54447174072271
    },
    {
        lng: 10.436977386474723,
        lat: 46.54487228393555
    },
    {
        lng: 10.436883926391658,
        lat: 46.545017242431584
    },
    {
        lng: 10.436107635498047,
        lat: 46.545673370361555
    },
    {
        lng: 10.434788703918457,
        lat: 46.54636764526367
    },
    {
        lng: 10.433976173400936,
        lat: 46.54695510864275
    },
    {
        lng: 10.43309020996088,
        lat: 46.547462463378906
    },
    {
        lng: 10.432332992553711,
        lat: 46.54804992675804
    },
    {
        lng: 10.430602073669434,
        lat: 46.549076080322266
    },
    {
        lng: 10.428336143493652,
        lat: 46.54975128173828
    },
    {
        lng: 10.42742061614996,
        lat: 46.55027770996094
    },
    {
        lng: 10.426047325134277,
        lat: 46.55093765258789
    },
    {
        lng: 10.425224304199332,
        lat: 46.5515251159668
    },
    {
        lng: 10.424275398254395,
        lat: 46.55247116088867
    },
    {
        lng: 10.422537803650016,
        lat: 46.553562164306925
    },
    {
        lng: 10.421830177307243,
        lat: 46.55377197265648
    },
    {
        lng: 10.418911933898869,
        lat: 46.554195404052734
    },
    {
        lng: 10.418395996093693,
        lat: 46.55435180664085
    },
    {
        lng: 10.417557716369686,
        lat: 46.55474472045893
    },
    {
        lng: 10.415330886840877,
        lat: 46.5542449951173
    },
    {
        lng: 10.413614273071346,
        lat: 46.553764343261946
    },
    {
        lng: 10.411615371704102,
        lat: 46.55299758911161
    },
    {
        lng: 10.41025829315197,
        lat: 46.55255889892578
    },
    {
        lng: 10.405188560485954,
        lat: 46.55067825317394
    },
    {
        lng: 10.401980400085506,
        lat: 46.54956436157255
    },
    {
        lng: 10.400941848754883,
        lat: 46.54933166503906
    },
    {
        lng: 10.399961471557674,
        lat: 46.549186706543196
    },
    {
        lng: 10.398675918579102,
        lat: 46.54921340942377
    },
    {
        lng: 10.397132873535213,
        lat: 46.54938888549816
    },
    {
        lng: 10.396413803100529,
        lat: 46.54954528808594
    },
    {
        lng: 10.394854545593262,
        lat: 46.5502243041995
    },
    {
        lng: 10.392908096313477,
        lat: 46.550781250000114
    },
    {
        lng: 10.391638755798454,
        lat: 46.55135345458996
    },
    {
        lng: 10.389081001281795,
        lat: 46.551948547363565
    },
    {
        lng: 10.386150360107479,
        lat: 46.55291366577171
    },
    {
        lng: 10.384276390075684,
        lat: 46.55362319946289
    },
    {
        lng: 10.382892608642578,
        lat: 46.55406951904291
    },
    {
        lng: 10.37766075134283,
        lat: 46.55603408813488
    },
    {
        lng: 10.374682426452694,
        lat: 46.557060241699276
    },
    {
        lng: 10.373854637146053,
        lat: 46.5572967529298
    },
    {
        lng: 10.3712739944458,
        lat: 46.5578498840332
    },
    {
        lng: 10.366068840026855,
        lat: 46.55956268310558
    },
    {
        lng: 10.365233421325684,
        lat: 46.5597381591798
    },
    {
        lng: 10.361066818237362,
        lat: 46.56034469604515
    },
    {
        lng: 10.35749721527111,
        lat: 46.560501098632926
    },
    {
        lng: 10.35546970367443,
        lat: 46.56053924560558
    },
    {
        lng: 10.35451698303234,
        lat: 46.56050491333025
    },
    {
        lng: 10.35363674163824,
        lat: 46.560371398925724
    },
    {
        lng: 10.353235244750977,
        lat: 46.5602455139163
    },
    {
        lng: 10.35287952423107,
        lat: 46.56005477905302
    },
    {
        lng: 10.352443695068416,
        lat: 46.55954360961931
    },
    {
        lng: 10.352269172668514,
        lat: 46.55895996093756
    },
    {
        lng: 10.352191925048828,
        lat: 46.55701446533226
    },
    {
        lng: 10.352029800415153,
        lat: 46.55601882934582
    },
    {
        lng: 10.35136508941656,
        lat: 46.554542541504134
    },
    {
        lng: 10.350674629211426,
        lat: 46.553722381591854
    },
    {
        lng: 10.350033760070914,
        lat: 46.553207397461165
    },
    {
        lng: 10.34933090209961,
        lat: 46.552745819092024
    },
    {
        lng: 10.348591804504395,
        lat: 46.55233764648449
    },
    {
        lng: 10.347102165222282,
        lat: 46.551673889160384
    },
    {
        lng: 10.3462495803833,
        lat: 46.55109786987316
    },
    {
        lng: 10.345353126525993,
        lat: 46.55059051513683
    },
    {
        lng: 10.344599723815975,
        lat: 46.549991607666016
    },
    {
        lng: 10.343753814697266,
        lat: 46.54946136474638
    },
    {
        lng: 10.343028068542537,
        lat: 46.54884719848633
    },
    {
        lng: 10.341608047485352,
        lat: 46.547916412353686
    },
    {
        lng: 10.340818405151424,
        lat: 46.54758453369135
    },
    {
        lng: 10.340213775634822,
        lat: 46.547470092773665
    },
    {
        lng: 10.339608192443961,
        lat: 46.54742050170904
    },
    {
        lng: 10.338849067687988,
        lat: 46.54751968383789
    },
    {
        lng: 10.338443756103516,
        lat: 46.54765319824219
    },
    {
        lng: 10.337322235107479,
        lat: 46.54824447631847
    },
    {
        lng: 10.328352928161564,
        lat: 46.5547714233399
    },
    {
        lng: 10.327256202697697,
        lat: 46.555419921875
    },
    {
        lng: 10.326457023620662,
        lat: 46.55572509765631
    },
    {
        lng: 10.326022148132324,
        lat: 46.555812835693416
    },
    {
        lng: 10.325366973877067,
        lat: 46.555828094482536
    },
    {
        lng: 10.324712753295842,
        lat: 46.55577087402355
    },
    {
        lng: 10.32402420043951,
        lat: 46.55565643310547
    },
    {
        lng: 10.323299407959098,
        lat: 46.555458068847656
    },
    {
        lng: 10.320137023925895,
        lat: 46.55432891845703
    },
    {
        lng: 10.317871093750114,
        lat: 46.553382873535384
    },
    {
        lng: 10.316937446594295,
        lat: 46.552848815918026
    },
    {
        lng: 10.316390991210938,
        lat: 46.55260086059582
    },
    {
        lng: 10.314746856689396,
        lat: 46.55214691162121
    },
    {
        lng: 10.313559532165584,
        lat: 46.551605224609375
    },
    {
        lng: 10.313236236572266,
        lat: 46.551517486572266
    },
    {
        lng: 10.312528610229492,
        lat: 46.55144119262707
    },
    {
        lng: 10.311805725097656,
        lat: 46.551490783691634
    },
    {
        lng: 10.311458587646598,
        lat: 46.55156326293945
    },
    {
        lng: 10.310894966125602,
        lat: 46.55179977416998
    },
    {
        lng: 10.30948543548584,
        lat: 46.55279541015625
    },
    {
        lng: 10.30877685546875,
        lat: 46.55353164672857
    },
    {
        lng: 10.308263778686637,
        lat: 46.55395507812523
    },
    {
        lng: 10.307624816894531,
        lat: 46.55432510376005
    },
    {
        lng: 10.306843757629451,
        lat: 46.55459976196289
    },
    {
        lng: 10.305512428283635,
        lat: 46.554817199707145
    },
    {
        lng: 10.303206443786621,
        lat: 46.555004119873274
    },
    {
        lng: 10.301232337951717,
        lat: 46.555259704590014
    },
    {
        lng: 10.301056861877498,
        lat: 46.555267333984375
    },
    {
        lng: 10.297539710998535,
        lat: 46.55542755126953
    },
    {
        lng: 10.296713829040527,
        lat: 46.55556869506836
    },
    {
        lng: 10.296332359313965,
        lat: 46.555702209472884
    },
    {
        lng: 10.295986175537166,
        lat: 46.555892944336165
    },
    {
        lng: 10.295718193054256,
        lat: 46.5561408996582
    },
    {
        lng: 10.295535087585506,
        lat: 46.55641555786144
    },
    {
        lng: 10.295305252075195,
        lat: 46.557323455810774
    },
    {
        lng: 10.29529857635498,
        lat: 46.55797195434582
    },
    {
        lng: 10.295408248901424,
        lat: 46.558975219726676
    },
    {
        lng: 10.295556068420524,
        lat: 46.55964279174816
    },
    {
        lng: 10.295998573303223,
        lat: 46.560932159424055
    },
    {
        lng: 10.296025276184196,
        lat: 46.561008453369425
    },
    {
        lng: 10.296158790588322,
        lat: 46.56197357177757
    },
    {
        lng: 10.296138763427791,
        lat: 46.56259918212902
    },
    {
        lng: 10.2960081100465,
        lat: 46.56319046020508
    },
    {
        lng: 10.295886993408203,
        lat: 46.56346511840832
    },
    {
        lng: 10.295540809631461,
        lat: 46.563888549804915
    },
    {
        lng: 10.294273376464844,
        lat: 46.56497573852545
    },
    {
        lng: 10.293259620666618,
        lat: 46.56573486328125
    },
    {
        lng: 10.291013717651367,
        lat: 46.568302154541016
    },
    {
        lng: 10.290185928344783,
        lat: 46.568897247314624
    },
    {
        lng: 10.289459228515568,
        lat: 46.569526672363224
    },
    {
        lng: 10.288632392883244,
        lat: 46.570068359375284
    },
    {
        lng: 10.287878990173454,
        lat: 46.570716857910156
    },
    {
        lng: 10.287204742431697,
        lat: 46.57130050659208
    },
    {
        lng: 10.286520004272575,
        lat: 46.572406768798885
    },
    {
        lng: 10.286136627197209,
        lat: 46.57283782959007
    },
    {
        lng: 10.28605937957775,
        lat: 46.572879791259766
    },
    {
        lng: 10.28536701202404,
        lat: 46.57324981689476
    },
    {
        lng: 10.284192085266227,
        lat: 46.573657989502124
    },
    {
        lng: 10.281869888305778,
        lat: 46.57418441772472
    },
    {
        lng: 10.279962539672965,
        lat: 46.5745201110841
    },
    {
        lng: 10.279069900512695,
        lat: 46.5748672485351
    },
    {
        lng: 10.2776136398316,
        lat: 46.57585525512707
    },
    {
        lng: 10.276411056518668,
        lat: 46.57713699340843
    },
    {
        lng: 10.274530410766658,
        lat: 46.578697204589844
    },
    {
        lng: 10.271145820617676,
        lat: 46.58126068115257
    },
    {
        lng: 10.269766807556266,
        lat: 46.58211898803734
    },
    {
        lng: 10.269017219543457,
        lat: 46.58240509033226
    },
    {
        lng: 10.268608093261776,
        lat: 46.582477569580305
    },
    {
        lng: 10.267998695373535,
        lat: 46.582473754882926
    },
    {
        lng: 10.26712799072277,
        lat: 46.58232498168951
    },
    {
        lng: 10.266584396362305,
        lat: 46.58214569091814
    },
    {
        lng: 10.265425682067871,
        lat: 46.581554412841854
    },
    {
        lng: 10.26379776000988,
        lat: 46.58108901977539
    },
    {
        lng: 10.262268066406307,
        lat: 46.58037567138683
    },
    {
        lng: 10.260604858398494,
        lat: 46.57993698120117
    },
    {
        lng: 10.259370803832951,
        lat: 46.57934951782221
    },
    {
        lng: 10.257406234741211,
        lat: 46.578788757324276
    },
    {
        lng: 10.255825996398869,
        lat: 46.57809448242199
    },
    {
        lng: 10.254711151123047,
        lat: 46.577877044677734
    },
    {
        lng: 10.253217697143555,
        lat: 46.57770156860374
    },
    {
        lng: 10.252573013305664,
        lat: 46.57765960693365
    },
    {
        lng: 10.251611709594727,
        lat: 46.57772064208979
    },
    {
        lng: 10.250065803527946,
        lat: 46.577899932861555
    },
    {
        lng: 10.249344825744686,
        lat: 46.578067779541016
    },
    {
        lng: 10.249019622802791,
        lat: 46.57819747924799
    },
    {
        lng: 10.248559951782227,
        lat: 46.57847976684593
    },
    {
        lng: 10.247591018676758,
        lat: 46.57920455932617
    },
    {
        lng: 10.247235298156681,
        lat: 46.579608917236555
    },
    {
        lng: 10.247001647949219,
        lat: 46.58012008666992
    },
    {
        lng: 10.246663093566838,
        lat: 46.581230163574446
    },
    {
        lng: 10.245650291442928,
        lat: 46.5839729309082
    },
    {
        lng: 10.245318412780762,
        lat: 46.5854949951173
    },
    {
        lng: 10.244902610778865,
        lat: 46.58682250976574
    },
    {
        lng: 10.244581222534293,
        lat: 46.58834457397478
    },
    {
        lng: 10.244160652160758,
        lat: 46.58966827392584
    },
    {
        lng: 10.24382114410406,
        lat: 46.591190338134766
    },
    {
        lng: 10.243377685546875,
        lat: 46.5925178527832
    },
    {
        lng: 10.242913246154899,
        lat: 46.5943336486817
    },
    {
        lng: 10.2427339553833,
        lat: 46.595035552978516
    },
    {
        lng: 10.24266242980957,
        lat: 46.59561538696289
    },
    {
        lng: 10.242703437805176,
        lat: 46.596187591552734
    },
    {
        lng: 10.243017196655387,
        lat: 46.59695053100586
    },
    {
        lng: 10.243819236755485,
        lat: 46.597824096679915
    },
    {
        lng: 10.2446546554566,
        lat: 46.5992050170899
    },
    {
        lng: 10.245520591735954,
        lat: 46.6000709533692
    },
    {
        lng: 10.246173858642635,
        lat: 46.60125350952177
    },
    {
        lng: 10.247186660766602,
        lat: 46.60234069824219
    },
    {
        lng: 10.2477064132691,
        lat: 46.603279113769474
    },
    {
        lng: 10.248038291931266,
        lat: 46.60371017456066
    },
    {
        lng: 10.24955081939703,
        lat: 46.60494613647478
    },
    {
        lng: 10.250396728515625,
        lat: 46.605537414551065
    },
    {
        lng: 10.25272083282482,
        lat: 46.60808181762701
    },
    {
        lng: 10.255243301391602,
        lat: 46.61008453369146
    },
    {
        lng: 10.256298065185604,
        lat: 46.611629486083984
    },
    {
        lng: 10.25831604003912,
        lat: 46.61322402954107
    },
    {
        lng: 10.258364677429256,
        lat: 46.61328506469738
    },
    {
        lng: 10.25863361358654,
        lat: 46.613616943359546
    },
    {
        lng: 10.258787155151424,
        lat: 46.61411285400402
    },
    {
        lng: 10.258763313293514,
        lat: 46.61462402343773
    },
    {
        lng: 10.258685111999512,
        lat: 46.614871978759766
    },
    {
        lng: 10.258405685424862,
        lat: 46.61528778076183
    },
    {
        lng: 10.257717132568303,
        lat: 46.61592483520519
    },
    {
        lng: 10.256976127624512,
        lat: 46.61674880981445
    },
    {
        lng: 10.256450653076229,
        lat: 46.61721038818354
    },
    {
        lng: 10.25543022155773,
        lat: 46.61796951293968
    },
    {
        lng: 10.253951072692985,
        lat: 46.618904113769645
    },
    {
        lng: 10.252560615539664,
        lat: 46.620616912842024
    },
    {
        lng: 10.251688003540096,
        lat: 46.62145233154297
    },
    {
        lng: 10.251404762268066,
        lat: 46.62189865112305
    },
    {
        lng: 10.25107192993164,
        lat: 46.62260437011719
    },
    {
        lng: 10.25076961517334,
        lat: 46.623039245605526
    },
    {
        lng: 10.249262809753532,
        lat: 46.62425613403343
    },
    {
        lng: 10.249014854431096,
        lat: 46.624393463134936
    },
    {
        lng: 10.24753379821783,
        lat: 46.62523269653326
    },
    {
        lng: 10.245601654052791,
        lat: 46.62660217285156
    },
    {
        lng: 10.245161056518498,
        lat: 46.62728500366211
    },
    {
        lng: 10.24472618103033,
        lat: 46.628166198730696
    },
    {
        lng: 10.244342803955192,
        lat: 46.628692626953296
    },
    {
        lng: 10.24200439453125,
        lat: 46.63051605224621
    },
    {
        lng: 10.241564750671444,
        lat: 46.63104248046898
    },
    {
        lng: 10.241139411926213,
        lat: 46.63177108764677
    },
    {
        lng: 10.24091720581049,
        lat: 46.63233184814453
    },
    {
        lng: 10.240895271301383,
        lat: 46.632446289062614
    },
    {
        lng: 10.240783691406364,
        lat: 46.63302230834984
    },
    {
        lng: 10.240653991699219,
        lat: 46.63651275634783
    },
    {
        lng: 10.240457534790153,
        lat: 46.637126922607536
    },
    {
        lng: 10.240267753601131,
        lat: 46.637409210205305
    },
    {
        lng: 10.239978790283146,
        lat: 46.637664794921875
    },
    {
        lng: 10.239218711853084,
        lat: 46.63796997070341
    },
    {
        lng: 10.238395690917969,
        lat: 46.6380615234375
    },
    {
        lng: 10.237582206726131,
        lat: 46.638011932373104
    },
    {
        lng: 10.236838340759277,
        lat: 46.63782501220703
    },
    {
        lng: 10.235589027404842,
        lat: 46.63724136352562
    },
    {
        lng: 10.234043121338004,
        lat: 46.636795043945426
    },
    {
        lng: 10.232366561889762,
        lat: 46.636043548583984
    },
    {
        lng: 10.230781555175781,
        lat: 46.63562774658209
    },
    {
        lng: 10.229121208190918,
        lat: 46.63492965698265
    },
    {
        lng: 10.227123260498104,
        lat: 46.63436508178734
    },
    {
        lng: 10.225097656250057,
        lat: 46.63333511352539
    },
    {
        lng: 10.224202156066895,
        lat: 46.63275527954096
    },
    {
        lng: 10.223447799682617,
        lat: 46.631992340087834
    },
    {
        lng: 10.22198486328125,
        lat: 46.63006973266613
    },
    {
        lng: 10.220474243164062,
        lat: 46.6277770996096
    },
    {
        lng: 10.219737052917424,
        lat: 46.62623596191406
    },
    {
        lng: 10.218622207641602,
        lat: 46.62502288818354
    },
    {
        lng: 10.21762561798107,
        lat: 46.62359619140648
    },
    {
        lng: 10.216776847839412,
        lat: 46.62220764160173
    },
    {
        lng: 10.214481353759766,
        lat: 46.622486114502124
    },
    {
        lng: 10.21195316314703,
        lat: 46.62295913696295
    },
    {
        lng: 10.202561378479004,
        lat: 46.625278472900675
    },
    {
        lng: 10.197342872619743,
        lat: 46.62591552734381
    },
    {
        lng: 10.194444656372127,
        lat: 46.624847412109375
    },
    {
        lng: 10.19218921661377,
        lat: 46.62418365478521
    },
    {
        lng: 10.191427230834961,
        lat: 46.623859405517805
    },
    {
        lng: 10.19071102142334,
        lat: 46.623470306396484
    },
    {
        lng: 10.18936729431158,
        lat: 46.62260818481474
    },
    {
        lng: 10.188138008117676,
        lat: 46.621688842773665
    },
    {
        lng: 10.187604904174805,
        lat: 46.62121582031244
    },
    {
        lng: 10.186677932739371,
        lat: 46.62018966674805
    },
    {
        lng: 10.186083793640194,
        lat: 46.619724273681754
    },
    {
        lng: 10.185732841491813,
        lat: 46.619335174560604
    },
    {
        lng: 10.18544769287115,
        lat: 46.61877822875988
    },
    {
        lng: 10.185126304626465,
        lat: 46.61814880371105
    },
    {
        lng: 10.184296607971248,
        lat: 46.6172790527346
    },
    {
        lng: 10.184152603149471,
        lat: 46.61706161499035
    },
    {
        lng: 10.183958053588867,
        lat: 46.616573333740405
    },
    {
        lng: 10.18365478515625,
        lat: 46.61525726318371
    },
    {
        lng: 10.183461189270076,
        lat: 46.614768981933764
    },
    {
        lng: 10.183319091796932,
        lat: 46.61455154418951
    },
    {
        lng: 10.182645797729492,
        lat: 46.6138801574707
    },
    {
        lng: 10.182485580444336,
        lat: 46.61365509033226
    },
    {
        lng: 10.181788444519043,
        lat: 46.61203765869152
    },
    {
        lng: 10.180889129638672,
        lat: 46.61070632934593
    },
    {
        lng: 10.180186271667537,
        lat: 46.60909652709955
    },
    {
        lng: 10.179167747497672,
        lat: 46.60797882080084
    },
    {
        lng: 10.178462028503475,
        lat: 46.60636520385748
    },
    {
        lng: 10.177559852600098,
        lat: 46.6050376892091
    },
    {
        lng: 10.176838874817008,
        lat: 46.60342788696289
    },
    {
        lng: 10.176675796508846,
        lat: 46.603206634521484
    },
    {
        lng: 10.175984382629451,
        lat: 46.60254669189453
    },
    {
        lng: 10.175703048706168,
        lat: 46.60206985473633
    },
    {
        lng: 10.175470352172852,
        lat: 46.60125350952177
    },
    {
        lng: 10.175251960754451,
        lat: 46.6000213623048
    },
    {
        lng: 10.17543983459484,
        lat: 46.59822082519554
    },
    {
        lng: 10.175335884094181,
        lat: 46.597099304199276
    },
    {
        lng: 10.175153732299918,
        lat: 46.59659576416027
    },
    {
        lng: 10.175011634826774,
        lat: 46.596374511718864
    },
    {
        lng: 10.174358367919865,
        lat: 46.595703125
    },
    {
        lng: 10.174218177795467,
        lat: 46.595485687255916
    },
    {
        lng: 10.17402172088623,
        lat: 46.59500122070318
    },
    {
        lng: 10.173705101013184,
        lat: 46.593681335449276
    },
    {
        lng: 10.173503875732479,
        lat: 46.59318923950201
    },
    {
        lng: 10.173188209533691,
        lat: 46.592792510986555
    },
    {
        lng: 10.172664642333984,
        lat: 46.5923118591308
    },
    {
        lng: 10.171792030334586,
        lat: 46.590976715087834
    },
    {
        lng: 10.171326637268123,
        lat: 46.590652465820426
    },
    {
        lng: 10.170514106750602,
        lat: 46.590385437011946
    },
    {
        lng: 10.17047214508051,
        lat: 46.588745117187614
    },
    {
        lng: 10.170230865478516,
        lat: 46.58786773681658
    },
    {
        lng: 10.169842720031738,
        lat: 46.58739471435558
    },
    {
        lng: 10.16936397552496,
        lat: 46.58714675903332
    },
    {
        lng: 10.168845176696891,
        lat: 46.58697891235346
    },
    {
        lng: 10.168803215026799,
        lat: 46.585342407226676
    },
    {
        lng: 10.168561935424805,
        lat: 46.58446121215849
    },
    {
        lng: 10.168172836303711,
        lat: 46.583992004394815
    },
    {
        lng: 10.167695045471248,
        lat: 46.58374023437523
    },
    {
        lng: 10.16717624664318,
        lat: 46.58357620239275
    },
    {
        lng: 10.167202949523926,
        lat: 46.58132171630888
    },
    {
        lng: 10.16714096069336,
        lat: 46.58071517944353
    },
    {
        lng: 10.166997909545898,
        lat: 46.58015060424822
    },
    {
        lng: 10.16671466827404,
        lat: 46.57965469360357
    },
    {
        lng: 10.166496276855469,
        lat: 46.5794486999514
    },
    {
        lng: 10.166015625000114,
        lat: 46.57920074462902
    },
    {
        lng: 10.165495872497559,
        lat: 46.57903671264671
    },
    {
        lng: 10.165497779846305,
        lat: 46.576622009277514
    },
    {
        lng: 10.16541576385498,
        lat: 46.57576751708996
    },
    {
        lng: 10.165278434753475,
        lat: 46.57524871826166
    },
    {
        lng: 10.165019989013615,
        lat: 46.57479858398443
    },
    {
        lng: 10.16431999206543,
        lat: 46.5741615295413
    },
    {
        lng: 10.164180755615291,
        lat: 46.573974609375
    },
    {
        lng: 10.163456916809196,
        lat: 46.57233810424805
    },
    {
        lng: 10.16326904296875,
        lat: 46.57208633422863
    },
    {
        lng: 10.162796020507926,
        lat: 46.571605682373274
    },
    {
        lng: 10.162247657775993,
        lat: 46.5711555480957
    },
    {
        lng: 10.16135215759283,
        lat: 46.57050323486328
    },
    {
        lng: 10.159776687622184,
        lat: 46.56949996948265
    },
    {
        lng: 10.159130096435547,
        lat: 46.56915283203125
    },
    {
        lng: 10.157670021057243,
        lat: 46.56851577758806
    },
    {
        lng: 10.1570405960083,
        lat: 46.5680580139163
    },
    {
        lng: 10.156551361084041,
        lat: 46.56777191162138
    },
    {
        lng: 10.154835700988826,
        lat: 46.56704711914057
    },
    {
        lng: 10.153904914856014,
        lat: 46.56673049926786
    },
    {
        lng: 10.153159141540584,
        lat: 46.56664657592796
    },
    {
        lng: 10.152777671814022,
        lat: 46.566665649414006
    },
    {
        lng: 10.152400970459098,
        lat: 46.56673049926786
    },
    {
        lng: 10.151764869690055,
        lat: 46.56697082519537
    },
    {
        lng: 10.15126800537115,
        lat: 46.56732177734398
    },
    {
        lng: 10.150931358337516,
        lat: 46.567764282226676
    },
    {
        lng: 10.150840759277344,
        lat: 46.568023681640625
    },
    {
        lng: 10.150847434997559,
        lat: 46.56854629516624
    },
    {
        lng: 10.151048660278263,
        lat: 46.56903076171898
    },
    {
        lng: 10.151823043823299,
        lat: 46.570087432861555
    },
    {
        lng: 10.15244388580328,
        lat: 46.571510314941634
    },
    {
        lng: 10.15274620056158,
        lat: 46.57191467285179
    },
    {
        lng: 10.153593063354606,
        lat: 46.572483062744084
    },
    {
        lng: 10.154389381408748,
        lat: 46.57309341430681
    },
    {
        lng: 10.156154632568416,
        lat: 46.573867797851676
    },
    {
        lng: 10.157092094421444,
        lat: 46.574405670166016
    },
    {
        lng: 10.158317565918026,
        lat: 46.5752067565918
    },
    {
        lng: 10.160951614379826,
        lat: 46.57709884643572
    },
    {
        lng: 10.161704063415527,
        lat: 46.57773208618187
    },
    {
        lng: 10.16248703002941,
        lat: 46.578510284423885
    },
    {
        lng: 10.162715911865178,
        lat: 46.57866287231468
    },
    {
        lng: 10.163262367248535,
        lat: 46.578884124755916
    },
    {
        lng: 10.163858413696289,
        lat: 46.57903671264671
    },
    {
        lng: 10.16382980346691,
        lat: 46.58129119873058
    },
    {
        lng: 10.163890838623047,
        lat: 46.58189773559593
    },
    {
        lng: 10.164033889770508,
        lat: 46.582462310791016
    },
    {
        lng: 10.164317131042537,
        lat: 46.58295822143566
    },
    {
        lng: 10.164534568786621,
        lat: 46.583164215087834
    },
    {
        lng: 10.16501712799078,
        lat: 46.58341217041044
    },
    {
        lng: 10.165535926818848,
        lat: 46.58357238769537
    },
    {
        lng: 10.165536880493107,
        lat: 46.58510971069353
    },
    {
        lng: 10.165589332580623,
        lat: 46.585712432861555
    },
    {
        lng: 10.165716171264705,
        lat: 46.58630371093744
    },
    {
        lng: 10.16582202911377,
        lat: 46.586589813232365
    },
    {
        lng: 10.166131973266602,
        lat: 46.58709335327154
    },
    {
        lng: 10.16652774810791,
        lat: 46.58752059936552
    },
    {
        lng: 10.167215347290153,
        lat: 46.588111877441634
    },
    {
        lng: 10.167298316955566,
        lat: 46.58921051025408
    },
    {
        lng: 10.167510032653922,
        lat: 46.590011596679915
    },
    {
        lng: 10.167640686035156,
        lat: 46.59026336669933
    },
    {
        lng: 10.167989730834961,
        lat: 46.590709686279354
    },
    {
        lng: 10.168883323669547,
        lat: 46.591514587402344
    },
    {
        lng: 10.16889762878418,
        lat: 46.596813201904354
    },
    {
        lng: 10.168966293335018,
        lat: 46.59776306152344
    },
    {
        lng: 10.169159889221191,
        lat: 46.598636627197266
    },
    {
        lng: 10.169426918029842,
        lat: 46.59913635253906
    },
    {
        lng: 10.170075416565055,
        lat: 46.599807739257926
    },
    {
        lng: 10.170220375061092,
        lat: 46.60003280639671
    },
    {
        lng: 10.170475959777889,
        lat: 46.6008377075197
    },
    {
        lng: 10.17070674896246,
        lat: 46.60261535644554
    },
    {
        lng: 10.17085075378418,
        lat: 46.60316848754894
    },
    {
        lng: 10.171107292175293,
        lat: 46.603656768799055
    },
    {
        lng: 10.171755790710506,
        lat: 46.60433578491205
    },
    {
        lng: 10.171896934509391,
        lat: 46.60455322265631
    },
    {
        lng: 10.172095298767204,
        lat: 46.6050376892091
    },
    {
        lng: 10.17242336273199,
        lat: 46.606349945068416
    },
    {
        lng: 10.17262268066412,
        lat: 46.60683822631836
    },
    {
        lng: 10.173565864563102,
        lat: 46.607944488525504
    },
    {
        lng: 10.173765182495174,
        lat: 46.60843276977562
    },
    {
        lng: 10.174083709716854,
        lat: 46.609752655029524
    },
    {
        lng: 10.174282073974666,
        lat: 46.61023712158226
    },
    {
        lng: 10.175217628479118,
        lat: 46.611351013183594
    },
    {
        lng: 10.175411224365291,
        lat: 46.61183929443388
    },
    {
        lng: 10.175724029541016,
        lat: 46.613159179687614
    },
    {
        lng: 10.175926208496207,
        lat: 46.6136474609375
    },
    {
        lng: 10.17607307434082,
        lat: 46.613864898681584
    },
    {
        lng: 10.176913261413631,
        lat: 46.61473846435547
    },
    {
        lng: 10.177255630493221,
        lat: 46.61542510986334
    },
    {
        lng: 10.17739200592041,
        lat: 46.615699768066406
    },
    {
        lng: 10.177692413330135,
        lat: 46.616142272949446
    },
    {
        lng: 10.178401947021484,
        lat: 46.61677932739258
    },
    {
        lng: 10.17856693267828,
        lat: 46.61699295043968
    },
    {
        lng: 10.179050445556697,
        lat: 46.61794662475609
    },
    {
        lng: 10.179340362548828,
        lat: 46.61839675903332
    },
    {
        lng: 10.180032730102482,
        lat: 46.61905288696306
    },
    {
        lng: 10.180194854736385,
        lat: 46.6192741394043
    },
    {
        lng: 10.180889129638672,
        lat: 46.620891571045206
    },
    {
        lng: 10.181831359863338,
        lat: 46.622222900390625
    },
    {
        lng: 10.182420730590877,
        lat: 46.62318801879911
    },
    {
        lng: 10.183871269226017,
        lat: 46.62484359741234
    },
    {
        lng: 10.184296607971248,
        lat: 46.62520980834984
    },
    {
        lng: 10.184556007385368,
        lat: 46.62535858154308
    },
    {
        lng: 10.187712669372615,
        lat: 46.62673568725609
    },
    {
        lng: 10.188249588012695,
        lat: 46.62701416015648
    },
    {
        lng: 10.1903915405274,
        lat: 46.628940582275334
    },
    {
        lng: 10.18863391876232,
        lat: 46.62925338745123
    },
    {
        lng: 10.187266349792594,
        lat: 46.62934112548834
    },
    {
        lng: 10.18600845336914,
        lat: 46.629249572753906
    },
    {
        lng: 10.183464050292969,
        lat: 46.62883758544916
    },
    {
        lng: 10.182638168334961,
        lat: 46.628589630127124
    },
    {
        lng: 10.181640625000057,
        lat: 46.62807846069359
    },
    {
        lng: 10.177694320678711,
        lat: 46.62638473510748
    },
    {
        lng: 10.176727294921989,
        lat: 46.62587356567394
    },
    {
        lng: 10.17479038238531,
        lat: 46.6253013610841
    },
    {
        lng: 10.173089981079158,
        lat: 46.62459182739275
    },
    {
        lng: 10.171154022216797,
        lat: 46.624019622802905
    },
    {
        lng: 10.169828414916935,
        lat: 46.62334442138689
    },
    {
        lng: 10.164828300476074,
        lat: 46.62118911743187
    },
    {
        lng: 10.159025192260742,
        lat: 46.62002944946289
    },
    {
        lng: 10.152874946594238,
        lat: 46.61919021606457
    },
    {
        lng: 10.151570320129451,
        lat: 46.61894226074219
    },
    {
        lng: 10.150112152099723,
        lat: 46.61852645874046
    },
    {
        lng: 10.148769378662223,
        lat: 46.61788940429693
    },
    {
        lng: 10.146866798400879,
        lat: 46.617301940918196
    },
    {
        lng: 10.145159721374455,
        lat: 46.61654281616205
    },
    {
        lng: 10.143342971801815,
        lat: 46.61607742309576
    },
    {
        lng: 10.141820907592773,
        lat: 46.61591720581066
    },
    {
        lng: 10.13762283325201,
        lat: 46.61580276489258
    },
    {
        lng: 10.136178970336914,
        lat: 46.61556243896507
    },
    {
        lng: 10.13547134399414,
        lat: 46.61526489257841
    },
    {
        lng: 10.135018348693848,
        lat: 46.61492156982416
    },
    {
        lng: 10.134605407714844,
        lat: 46.61435317993187
    },
    {
        lng: 10.133988380432243,
        lat: 46.613147735595874
    },
    {
        lng: 10.133527755737362,
        lat: 46.612617492675895
    },
    {
        lng: 10.132935523986816,
        lat: 46.61213302612316
    },
    {
        lng: 10.132235527038574,
        lat: 46.61170959472673
    },
    {
        lng: 10.131397247314567,
        lat: 46.611366271972884
    },
    {
        lng: 10.129797935485783,
        lat: 46.610931396484375
    },
    {
        lng: 10.128952980041504,
        lat: 46.61076354980486
    },
    {
        lng: 10.124827384948787,
        lat: 46.61022949218773
    },
    {
        lng: 10.122159004211426,
        lat: 46.61012268066435
    },
    {
        lng: 10.120014190673828,
        lat: 46.61018753051769
    },
    {
        lng: 10.117383956909123,
        lat: 46.6104850769043
    },
    {
        lng: 10.113298416137695,
        lat: 46.61075592041033
    },
    {
        lng: 10.112408638000488,
        lat: 46.610927581787166
    },
    {
        lng: 10.11168384552002,
        lat: 46.61122512817383
    },
    {
        lng: 10.110235214233455,
        lat: 46.61207580566406
    },
    {
        lng: 10.109204292297363,
        lat: 46.61284255981451
    },
    {
        lng: 10.10805416107172,
        lat: 46.613533020019645
    },
    {
        lng: 10.107351303100643,
        lat: 46.61384963989269
    },
    {
        lng: 10.10649204254156,
        lat: 46.614055633544865
    },
    {
        lng: 10.10365962982172,
        lat: 46.6144905090332
    },
    {
        lng: 10.102317810058707,
        lat: 46.61457443237316
    },
    {
        lng: 10.100374221801758,
        lat: 46.614597320556925
    },
    {
        lng: 10.099948883056754,
        lat: 46.61258316040039
    },
    {
        lng: 10.099399566650334,
        lat: 46.61079025268583
    },
    {
        lng: 10.099219322204647,
        lat: 46.60932159423834
    },
    {
        lng: 10.099304199218693,
        lat: 46.606708526611555
    },
    {
        lng: 10.09942817687994,
        lat: 46.605987548828296
    },
    {
        lng: 10.099834442138672,
        lat: 46.604557037353686
    },
    {
        lng: 10.099920272827205,
        lat: 46.60385131835932
    },
    {
        lng: 10.099907875060978,
        lat: 46.60315704345703
    },
    {
        lng: 10.0997829437257,
        lat: 46.602497100830305
    },
    {
        lng: 10.09949970245367,
        lat: 46.60191345214872
    },
    {
        lng: 10.098686218261832,
        lat: 46.60097885131859
    },
    {
        lng: 10.098052978515682,
        lat: 46.59989929199219
    },
    {
        lng: 10.096955299377498,
        lat: 46.59875869750988
    },
    {
        lng: 10.096349716186637,
        lat: 46.59769058227556
    },
    {
        lng: 10.095664978027457,
        lat: 46.59679412841791
    },
    {
        lng: 10.09557247161871,
        lat: 46.59651947021507
    },
    {
        lng: 10.095611572265682,
        lat: 46.595932006836165
    },
    {
        lng: 10.0958633422851,
        lat: 46.59544754028343
    },
    {
        lng: 10.096806526184082,
        lat: 46.594242095947266
    },
    {
        lng: 10.097752571105957,
        lat: 46.59333419799805
    },
    {
        lng: 10.0980863571167,
        lat: 46.59275817871122
    },
    {
        lng: 10.09826564788824,
        lat: 46.59209442138672
    },
    {
        lng: 10.09836864471447,
        lat: 46.59102249145525
    },
    {
        lng: 10.09835243225109,
        lat: 46.589912414550895
    },
    {
        lng: 10.098220825195312,
        lat: 46.58880996704107
    },
    {
        lng: 10.098032951354924,
        lat: 46.58809280395536
    },
    {
        lng: 10.097011566162166,
        lat: 46.585624694824446
    },
    {
        lng: 10.096437454223746,
        lat: 46.583904266357536
    },
    {
        lng: 10.096111297607536,
        lat: 46.58329772949236
    },
    {
        lng: 10.094914436340332,
        lat: 46.581737518310774
    },
    {
        lng: 10.094388961792049,
        lat: 46.58126449584961
    },
    {
        lng: 10.092779159545842,
        lat: 46.58006286621094
    },
    {
        lng: 10.09221363067627,
        lat: 46.57981109619152
    },
    {
        lng: 10.09128475189209,
        lat: 46.579593658447266
    },
    {
        lng: 10.090280532836971,
        lat: 46.579490661621094
    },
    {
        lng: 10.088697433471737,
        lat: 46.5794486999514
    },
    {
        lng: 10.080618858337402,
        lat: 46.57958221435558
    },
    {
        lng: 10.07920932769781,
        lat: 46.579414367676065
    },
    {
        lng: 10.078780174255428,
        lat: 46.57927703857422
    },
    {
        lng: 10.078391075134334,
        lat: 46.57906723022484
    },
    {
        lng: 10.07809066772461,
        lat: 46.578800201416016
    },
    {
        lng: 10.077890396118221,
        lat: 46.57849884033203
    },
    {
        lng: 10.077693939208984,
        lat: 46.57785415649431
    },
    {
        lng: 10.077705383300895,
        lat: 46.57682800292969
    },
    {
        lng: 10.077963829040527,
        lat: 46.5758972167971
    },
    {
        lng: 10.078545570373478,
        lat: 46.574474334717024
    },
    {
        lng: 10.07887077331543,
        lat: 46.57388305664091
    },
    {
        lng: 10.079345703125,
        lat: 46.573406219482706
    },
    {
        lng: 10.081330299377498,
        lat: 46.571784973144815
    },
    {
        lng: 10.081645965576286,
        lat: 46.57122421264671
    },
    {
        lng: 10.081770896911621,
        lat: 46.57059097290039
    },
    {
        lng: 10.081744194030819,
        lat: 46.56994247436535
    },
    {
        lng: 10.081562995910645,
        lat: 46.56932449340843
    },
    {
        lng: 10.081401824951229,
        lat: 46.56904602050804
    },
    {
        lng: 10.080595016479492,
        lat: 46.568119049072266
    },
    {
        lng: 10.07976436614996,
        lat: 46.56677246093756
    },
    {
        lng: 10.077959060669059,
        lat: 46.56520843505882
    },
    {
        lng: 10.07699012756359,
        lat: 46.564682006835994
    },
    {
        lng: 10.075668334960938,
        lat: 46.564350128174055
    },
    {
        lng: 10.072723388671932,
        lat: 46.56399917602562
    },
    {
        lng: 10.071869850158635,
        lat: 46.56373596191412
    },
    {
        lng: 10.070896148681697,
        lat: 46.563156127929744
    },
    {
        lng: 10.070138931274528,
        lat: 46.56232833862316
    },
    {
        lng: 10.069021224975643,
        lat: 46.560688018799
    },
    {
        lng: 10.068275451660156,
        lat: 46.55802154541027
    },
    {
        lng: 10.067391395568904,
        lat: 46.55675125122093
    },
    {
        lng: 10.066803932189998,
        lat: 46.554912567138786
    },
    {
        lng: 10.066466331481877,
        lat: 46.554359436035384
    },
    {
        lng: 10.064981460571403,
        lat: 46.553108215332145
    },
    {
        lng: 10.060002326965332,
        lat: 46.54973602294922
    },
    {
        lng: 10.059464454650822,
        lat: 46.54947662353527
    },
    {
        lng: 10.057415008545036,
        lat: 46.54870986938499
    },
    {
        lng: 10.056049346923942,
        lat: 46.5484313964846
    },
    {
        lng: 10.054018020629883,
        lat: 46.54832839965843
    },
    {
        lng: 10.048331260681266,
        lat: 46.54838562011736
    },
    {
        lng: 10.046845436096305,
        lat: 46.548282623291016
    },
    {
        lng: 10.045905113220215,
        lat: 46.548072814941634
    },
    {
        lng: 10.04563236236578,
        lat: 46.5479545593264
    },
    {
        lng: 10.045161247253532,
        lat: 46.54775238037138
    },
    {
        lng: 10.044281005859432,
        lat: 46.547100067138786
    },
    {
        lng: 10.042984008789176,
        lat: 46.54544448852539
    },
    {
        lng: 10.042496681213322,
        lat: 46.544666290283374
    },
    {
        lng: 10.042169570922852,
        lat: 46.54370880126959
    },
    {
        lng: 10.042124748230094,
        lat: 46.54301452636713
    },
    {
        lng: 10.042400360107422,
        lat: 46.542076110839844
    },
    {
        lng: 10.042805671691895,
        lat: 46.54131698608404
    },
    {
        lng: 10.044119834900016,
        lat: 46.53961563110363
    },
    {
        lng: 10.044831275939941,
        lat: 46.53894042968756
    },
    {
        lng: 10.045424461364803,
        lat: 46.53855133056646
    },
    {
        lng: 10.0468111038208,
        lat: 46.53789138793951
    },
    {
        lng: 10.048847198486271,
        lat: 46.536613464355696
    },
    {
        lng: 10.049204826354924,
        lat: 46.53621673584007
    },
    {
        lng: 10.04936504364025,
        lat: 46.53591537475586
    },
    {
        lng: 10.049528121948242,
        lat: 46.53492736816412
    },
    {
        lng: 10.049435615539664,
        lat: 46.53424453735374
    },
    {
        lng: 10.048983573913688,
        lat: 46.53240966796898
    },
    {
        lng: 10.048790931701603,
        lat: 46.5292510986331
    },
    {
        lng: 10.04887580871582,
        lat: 46.526893615722884
    },
    {
        lng: 10.048989295959473,
        lat: 46.52613067626976
    },
    {
        lng: 10.049450874328556,
        lat: 46.524307250976676
    },
    {
        lng: 10.049590110778865,
        lat: 46.52282714843767
    },
    {
        lng: 10.049576759338379,
        lat: 46.51982498168957
    },
    {
        lng: 10.04928207397461,
        lat: 46.518379211426065
    },
    {
        lng: 10.048768997192496,
        lat: 46.51734924316429
    },
    {
        lng: 10.048342704773006,
        lat: 46.51672363281267
    },
    {
        lng: 10.047850608825684,
        lat: 46.51624679565447
    },
    {
        lng: 10.047272682189941,
        lat: 46.51584243774414
    },
    {
        lng: 10.044920921325627,
        lat: 46.514617919921875
    },
    {
        lng: 10.041220664978084,
        lat: 46.513156890869084
    },
    {
        lng: 10.04057693481451,
        lat: 46.512802124023494
    },
    {
        lng: 10.040143013000488,
        lat: 46.5124130249024
    },
    {
        lng: 10.039916038513297,
        lat: 46.512100219726676
    },
    {
        lng: 10.039633750915527,
        lat: 46.51142120361351
    },
    {
        lng: 10.039495468139705,
        lat: 46.51071166992193
    },
    {
        lng: 10.039435386657715,
        lat: 46.50961303710949
    },
    {
        lng: 10.039520263671932,
        lat: 46.5085182189942
    },
    {
        lng: 10.039697647094727,
        lat: 46.50782394409174
    },
    {
        lng: 10.040053367614803,
        lat: 46.50718307495123
    },
    {
        lng: 10.040669441223088,
        lat: 46.50663757324219
    },
    {
        lng: 10.042263031005916,
        lat: 46.50577926635742
    },
    {
        lng: 10.042941093444824,
        lat: 46.50551223754894
    },
    {
        lng: 10.044061660766602,
        lat: 46.50525283813499
    },
    {
        lng: 10.044769287109375,
        lat: 46.504993438720874
    },
    {
        lng: 10.045167922973746,
        lat: 46.50469207763672
    },
    {
        lng: 10.04543304443365,
        lat: 46.504104614257756
    },
    {
        lng: 10.045378684997615,
        lat: 46.50333023071312
    },
    {
        lng: 10.04503536224371,
        lat: 46.50245666503929
    },
    {
        lng: 10.044578552246094,
        lat: 46.50197982788109
    },
    {
        lng: 10.04245662689209,
        lat: 46.50040054321289
    },
    {
        lng: 10.04220962524414,
        lat: 46.500152587890625
    },
    {
        lng: 10.041422843933105,
        lat: 46.49892425537138
    },
    {
        lng: 10.041192054748535,
        lat: 46.49822998046892
    },
    {
        lng: 10.041075706482047,
        lat: 46.49750137329113
    },
    {
        lng: 10.041101455688533,
        lat: 46.4956512451173
    },
    {
        lng: 10.041256904601994,
        lat: 46.49493789672874
    },
    {
        lng: 10.041498184204158,
        lat: 46.4943656921389
    },
    {
        lng: 10.042152404785156,
        lat: 46.49336624145525
    },
    {
        lng: 10.044402122497559,
        lat: 46.49156570434582
    },
    {
        lng: 10.044614791870174,
        lat: 46.491344451904354
    },
    {
        lng: 10.044857978820914,
        lat: 46.490806579589844
    },
    {
        lng: 10.044848442077637,
        lat: 46.49023056030285
    },
    {
        lng: 10.044589996337947,
        lat: 46.48969268798851
    },
    {
        lng: 10.042099952697697,
        lat: 46.487689971924055
    },
    {
        lng: 10.041605949401855,
        lat: 46.48692703247093
    },
    {
        lng: 10.04132175445568,
        lat: 46.48614883422874
    },
    {
        lng: 10.041344642639103,
        lat: 46.4852294921875
    },
    {
        lng: 10.041878700256461,
        lat: 46.482910156250114
    },
    {
        lng: 10.042277336120662,
        lat: 46.48189163208008
    },
    {
        lng: 10.043169021606502,
        lat: 46.480319976806925
    },
    {
        lng: 10.043713569641227,
        lat: 46.47956085205078
    },
    {
        lng: 10.044672966003418,
        lat: 46.478622436523665
    },
    {
        lng: 10.045193672180233,
        lat: 46.47769165039085
    },
    {
        lng: 10.045518875122184,
        lat: 46.477268218994425
    },
    {
        lng: 10.04799842834484,
        lat: 46.47527694702171
    },
    {
        lng: 10.048764228820858,
        lat: 46.473976135253906
    },
    {
        lng: 10.04900264739996,
        lat: 46.47300720214838
    },
    {
        lng: 10.049221038818473,
        lat: 46.470283508300895
    },
    {
        lng: 10.049675941467342,
        lat: 46.468559265136776
    },
    {
        lng: 10.049783706665039,
        lat: 46.46789169311529
    },
    {
        lng: 10.04983997344965,
        lat: 46.466865539550895
    },
    {
        lng: 10.049780845642147,
        lat: 46.46583938598644
    },
    {
        lng: 10.049522399902287,
        lat: 46.46483993530279
    },
    {
        lng: 10.049358367919979,
        lat: 46.464519500732365
    },
    {
        lng: 10.048883438110408,
        lat: 46.46391677856474
    },
    {
        lng: 10.046051979064998,
        lat: 46.461322784424
    },
    {
        lng: 10.045164108276424,
        lat: 46.460750579834155
    },
    {
        lng: 10.044390678405875,
        lat: 46.46014022827143
    },
    {
        lng: 10.043518066406307,
        lat: 46.45961761474621
    },
    {
        lng: 10.041987419128418,
        lat: 46.458408355713004
    },
    {
        lng: 10.041648864746207,
        lat: 46.457981109619254
    },
    {
        lng: 10.041107177734489,
        lat: 46.457057952881144
    },
    {
        lng: 10.039994239807243,
        lat: 46.45601654052746
    },
    {
        lng: 10.03889179229742,
        lat: 46.454654693603516
    },
    {
        lng: 10.038529396057129,
        lat: 46.454109191894645
    },
    {
        lng: 10.038272857666072,
        lat: 46.45345687866205
    },
    {
        lng: 10.038141250610352,
        lat: 46.452774047851676
    },
    {
        lng: 10.038064956665096,
        lat: 46.45066833496122
    },
    {
        lng: 10.03818607330328,
        lat: 46.44927978515631
    },
    {
        lng: 10.038663864135742,
        lat: 46.44753646850597
    },
    {
        lng: 10.038875579834041,
        lat: 46.446144104003906
    },
    {
        lng: 10.042434692382926,
        lat: 46.44626617431652
    },
    {
        lng: 10.04404258728033,
        lat: 46.44622802734381
    },
    {
        lng: 10.045092582702694,
        lat: 46.4461402893067
    },
    {
        lng: 10.046510696411076,
        lat: 46.44586181640631
    },
    {
        lng: 10.048053741455192,
        lat: 46.4453506469726
    },
    {
        lng: 10.051076889038143,
        lat: 46.444499969482365
    },
    {
        lng: 10.051340103149414,
        lat: 46.444423675537166
    },
    {
        lng: 10.052252769470272,
        lat: 46.44433593750006
    },
    {
        lng: 10.054035186767578,
        lat: 46.44434738159197
    },
    {
        lng: 10.054471015930176,
        lat: 46.44428253173851
    },
    {
        lng: 10.055175781250057,
        lat: 46.44403839111328
    },
    {
        lng: 10.055642127990666,
        lat: 46.443710327148665
    },
    {
        lng: 10.056052207946777,
        lat: 46.44312667846708
    },
    {
        lng: 10.056499481201172,
        lat: 46.44169998168945
    },
    {
        lng: 10.056685447692928,
        lat: 46.441108703613395
    },
    {
        lng: 10.056831359863281,
        lat: 46.440021514892805
    },
    {
        lng: 10.056858062744084,
        lat: 46.43665695190441
    },
    {
        lng: 10.057061195373592,
        lat: 46.43557357788103
    },
    {
        lng: 10.057521820068416,
        lat: 46.43452072143566
    },
    {
        lng: 10.057908058166618,
        lat: 46.43386077880871
    },
    {
        lng: 10.058427810669002,
        lat: 46.433284759521655
    },
    {
        lng: 10.059041976928711,
        lat: 46.43277359008812
    },
    {
        lng: 10.060154914855957,
        lat: 46.43207550048851
    },
    {
        lng: 10.061326026916618,
        lat: 46.4315948486331
    },
    {
        lng: 10.063459396362305,
        lat: 46.43110275268566
    },
    {
        lng: 10.064804077148438,
        lat: 46.43100357055664
    },
    {
        lng: 10.065705299377555,
        lat: 46.431030273437614
    },
    {
        lng: 10.066584587097168,
        lat: 46.431129455566634
    },
    {
        lng: 10.069223403930607,
        lat: 46.43161773681635
    },
    {
        lng: 10.070255279541016,
        lat: 46.43190765380882
    },
    {
        lng: 10.071191787719727,
        lat: 46.43235015869169
    },
    {
        lng: 10.071864128112907,
        lat: 46.43250274658209
    },
    {
        lng: 10.072966575622502,
        lat: 46.43252182006853
    },
    {
        lng: 10.073666572570744,
        lat: 46.432373046875114
    },
    {
        lng: 10.075618743896484,
        lat: 46.43138122558605
    },
    {
        lng: 10.075956344604606,
        lat: 46.431209564208984
    },
    {
        lng: 10.07676029205328,
        lat: 46.43054962158226
    },
    {
        lng: 10.077129364013672,
        lat: 46.430057525634766
    },
    {
        lng: 10.077375411987305,
        lat: 46.42957687377941
    },
    {
        lng: 10.077790260314941,
        lat: 46.42863845825224
    },
    {
        lng: 10.078226089477539,
        lat: 46.42724609375
    },
    {
        lng: 10.078560829162598,
        lat: 46.426727294921875
    },
    {
        lng: 10.07901382446289,
        lat: 46.426364898681754
    },
    {
        lng: 10.079295158386344,
        lat: 46.42621612548834
    },
    {
        lng: 10.079709053039608,
        lat: 46.4260711669923
    },
    {
        lng: 10.080606460571403,
        lat: 46.42590332031244
    },
    {
        lng: 10.082545280456657,
        lat: 46.42581939697271
    },
    {
        lng: 10.088714599609432,
        lat: 46.42590713500999
    },
    {
        lng: 10.091810226440373,
        lat: 46.42589187622093
    },
    {
        lng: 10.094327926635742,
        lat: 46.42576980590849
    },
    {
        lng: 10.096729278564396,
        lat: 46.42546844482433
    },
    {
        lng: 10.097579002380371,
        lat: 46.42545318603521
    },
    {
        lng: 10.098407745361442,
        lat: 46.42552947998047
    },
    {
        lng: 10.099172592163143,
        lat: 46.425739288330135
    },
    {
        lng: 10.099688529968319,
        lat: 46.42602539062506
    },
    {
        lng: 10.100088119506836,
        lat: 46.42641830444353
    },
    {
        lng: 10.100643157959041,
        lat: 46.42730331420927
    },
    {
        lng: 10.101045608520621,
        lat: 46.42769241333019
    },
    {
        lng: 10.101678848266602,
        lat: 46.42796325683622
    },
    {
        lng: 10.103983879089355,
        lat: 46.428428649902344
    },
    {
        lng: 10.104679107666072,
        lat: 46.42868423461914
    },
    {
        lng: 10.106741905212402,
        lat: 46.430335998535156
    },
    {
        lng: 10.107430458068904,
        lat: 46.430770874023665
    },
    {
        lng: 10.108182907104606,
        lat: 46.431114196777344
    },
    {
        lng: 10.108927726745605,
        lat: 46.43127441406267
    },
    {
        lng: 10.109574317932243,
        lat: 46.43126296997076
    },
    {
        lng: 10.10986709594738,
        lat: 46.431205749512
    },
    {
        lng: 10.111053466796932,
        lat: 46.43070220947283
    },
    {
        lng: 10.112657546997184,
        lat: 46.43025588989258
    },
    {
        lng: 10.113820075988826,
        lat: 46.42975234985363
    },
    {
        lng: 10.11441707611084,
        lat: 46.4296760559082
    },
    {
        lng: 10.115058898925838,
        lat: 46.42974090576172
    },
    {
        lng: 10.115728378295898,
        lat: 46.4299888610841
    },
    {
        lng: 10.11628532409668,
        lat: 46.43034362792969
    },
    {
        lng: 10.117195129394645,
        lat: 46.43127441406267
    },
    {
        lng: 10.11800670623785,
        lat: 46.43189620971691
    },
    {
        lng: 10.118764877319336,
        lat: 46.43259811401373
    },
    {
        lng: 10.119235038757324,
        lat: 46.43291473388683
    },
    {
        lng: 10.119818687439022,
        lat: 46.433177947998104
    },
    {
        lng: 10.121823310852108,
        lat: 46.43371200561546
    },
    {
        lng: 10.125979423523006,
        lat: 46.434169769287394
    },
    {
        lng: 10.127971649170036,
        lat: 46.4346923828125
    },
    {
        lng: 10.128800392150936,
        lat: 46.43485260009777
    },
    {
        lng: 10.130102157592887,
        lat: 46.43490219116222
    },
    {
        lng: 10.131381034851188,
        lat: 46.434715270996264
    },
    {
        lng: 10.13222503662115,
        lat: 46.4344100952149
    },
    {
        lng: 10.132385253906307,
        lat: 46.4343338012697
    },
    {
        lng: 10.134160041809082,
        lat: 46.43344879150396
    },
    {
        lng: 10.135008811950684,
        lat: 46.43313598632824
    },
    {
        lng: 10.135467529296932,
        lat: 46.433029174804915
    },
    {
        lng: 10.136915206909237,
        lat: 46.43286132812523
    },
    {
        lng: 10.140331268310604,
        lat: 46.43277359008812
    },
    {
        lng: 10.141649246215934,
        lat: 46.43261718750017
    },
    {
        lng: 10.142038345337028,
        lat: 46.43250274658209
    },
    {
        lng: 10.142630577087402,
        lat: 46.43214797973633
    },
    {
        lng: 10.143548965454215,
        lat: 46.43090057373064
    },
    {
        lng: 10.14553165435791,
        lat: 46.429233551025334
    },
    {
        lng: 10.146024703979492,
        lat: 46.42859268188482
    },
    {
        lng: 10.146757125854435,
        lat: 46.42649841308594
    },
    {
        lng: 10.146906852722168,
        lat: 46.42586135864258
    },
    {
        lng: 10.14719295501709,
        lat: 46.42354965209978
    },
    {
        lng: 10.147575378417969,
        lat: 46.42182540893555
    },
    {
        lng: 10.147620201110897,
        lat: 46.42105865478521
    },
    {
        lng: 10.147729873657283,
        lat: 46.41922378540062
    },
    {
        lng: 10.147934913635368,
        lat: 46.418655395507756
    },
    {
        lng: 10.148344039916992,
        lat: 46.4182243347168
    },
    {
        lng: 10.14859485626232,
        lat: 46.41808319091791
    },
    {
        lng: 10.149188041687012,
        lat: 46.41792297363298
    },
    {
        lng: 10.149764060974178,
        lat: 46.41790771484375
    },
    {
        lng: 10.150835990905875,
        lat: 46.41800689697271
    },
    {
        lng: 10.151150703430176,
        lat: 46.41806411743187
    },
    {
        lng: 10.151557922363224,
        lat: 46.41814422607422
    },
    {
        lng: 10.152218818664664,
        lat: 46.41835784912132
    },
    {
        lng: 10.153140068054313,
        lat: 46.41888427734392
    },
    {
        lng: 10.153693199157715,
        lat: 46.419113159179915
    },
    {
        lng: 10.154934883117619,
        lat: 46.41950225830101
    },
    {
        lng: 10.155707359314079,
        lat: 46.41966629028349
    },
    {
        lng: 10.156621932983512,
        lat: 46.41976928710966
    },
    {
        lng: 10.157550811767692,
        lat: 46.41979598999046
    },
    {
        lng: 10.158467292785701,
        lat: 46.41975021362305
    },
    {
        lng: 10.159342765808162,
        lat: 46.41960906982416
    },
    {
        lng: 10.160046577453727,
        lat: 46.41936492919933
    },
    {
        lng: 10.16054725646984,
        lat: 46.41906356811529
    },
    {
        lng: 10.162052154541072,
        lat: 46.417747497558594
    },
    {
        lng: 10.162864685058594,
        lat: 46.41637420654297
    },
    {
        lng: 10.163949012756461,
        lat: 46.41533279418945
    },
    {
        lng: 10.165192604064941,
        lat: 46.413726806640796
    },
    {
        lng: 10.16532421112072,
        lat: 46.41337585449219
    },
    {
        lng: 10.165518760681152,
        lat: 46.41285705566412
    },
    {
        lng: 10.165535926818848,
        lat: 46.41225051879894
    },
    {
        lng: 10.16540431976324,
        lat: 46.411651611328125
    },
    {
        lng: 10.165090560913086,
        lat: 46.41106414794939
    },
    {
        lng: 10.163876533508414,
        lat: 46.4097251892091
    },
    {
        lng: 10.163346290588436,
        lat: 46.408969879150504
    },
    {
        lng: 10.16272258758545,
        lat: 46.40777587890619
    },
    {
        lng: 10.16252326965332,
        lat: 46.40710830688471
    },
    {
        lng: 10.162420272827148,
        lat: 46.40642547607433
    },
    {
        lng: 10.162364959716797,
        lat: 46.40502929687506
    },
    {
        lng: 10.162432670593319,
        lat: 46.40362930297863
    },
    {
        lng: 10.162589073181266,
        lat: 46.402606964111555
    },
    {
        lng: 10.163068771362362,
        lat: 46.40085983276384
    },
    {
        lng: 10.163242340088004,
        lat: 46.39835357666021
    },
    {
        lng: 10.163248062133732,
        lat: 46.39438629150402
    },
    {
        lng: 10.163066864013786,
        lat: 46.39337539672846
    },
    {
        lng: 10.162760734558105,
        lat: 46.3927574157716
    },
    {
        lng: 10.162177085876579,
        lat: 46.39220428466797
    },
    {
        lng: 10.160348892212028,
        lat: 46.39107894897472
    },
    {
        lng: 10.158122062683049,
        lat: 46.390213012695426
    },
    {
        lng: 10.156864166259766,
        lat: 46.38988113403326
    },
    {
        lng: 10.15588569641119,
        lat: 46.389766693115234
    },
    {
        lng: 10.15529632568365,
        lat: 46.3897399902346
    },
    {
        lng: 10.154371261596623,
        lat: 46.38970184326189
    },
    {
        lng: 10.150769233703613,
        lat: 46.3897399902346
    },
    {
        lng: 10.148745536804313,
        lat: 46.389850616455305
    },
    {
        lng: 10.146289825439567,
        lat: 46.39017105102556
    },
    {
        lng: 10.144961357116642,
        lat: 46.39022445678734
    },
    {
        lng: 10.143297195434627,
        lat: 46.39008712768566
    },
    {
        lng: 10.141019821166935,
        lat: 46.38971710205078
    },
    {
        lng: 10.140290260315055,
        lat: 46.38953399658203
    },
    {
        lng: 10.139720916748104,
        lat: 46.38927841186546
    },
    {
        lng: 10.137070655822868,
        lat: 46.38764953613304
    },
    {
        lng: 10.13340854644781,
        lat: 46.386058807373104
    },
    {
        lng: 10.132546424865723,
        lat: 46.385475158691406
    },
    {
        lng: 10.131645202636776,
        lat: 46.38496017456083
    },
    {
        lng: 10.13086986541748,
        lat: 46.384368896484375
    },
    {
        lng: 10.130002975463867,
        lat: 46.38384628295893
    },
    {
        lng: 10.12843227386486,
        lat: 46.38264083862316
    },
    {
        lng: 10.127949714660701,
        lat: 46.382038116455305
    },
    {
        lng: 10.127328872680721,
        lat: 46.380874633789176
    },
    {
        lng: 10.126517295837516,
        lat: 46.37801361083996
    },
    {
        lng: 10.126334190368766,
        lat: 46.37625122070324
    },
    {
        lng: 10.1263170242309,
        lat: 46.37337112426758
    },
    {
        lng: 10.126425743103027,
        lat: 46.37158966064453
    },
    {
        lng: 10.12664699554449,
        lat: 46.3705596923831
    },
    {
        lng: 10.127835273742619,
        lat: 46.366760253906364
    },
    {
        lng: 10.127845764160213,
        lat: 46.36591339111351
    },
    {
        lng: 10.127670288086051,
        lat: 46.36538696289074
    },
    {
        lng: 10.127507209777832,
        lat: 46.365150451660384
    },
    {
        lng: 10.127123832702694,
        lat: 46.364814758300895
    },
    {
        lng: 10.126095771789664,
        lat: 46.36412429809576
    },
    {
        lng: 10.12551212310791,
        lat: 46.36387252807617
    },
    {
        lng: 10.123204231262264,
        lat: 46.36341476440441
    },
    {
        lng: 10.121273040771541,
        lat: 46.36286163330101
    },
    {
        lng: 10.119732856750545,
        lat: 46.36252212524431
    },
    {
        lng: 10.119048118591365,
        lat: 46.362293243408146
    },
    {
        lng: 10.118515014648551,
        lat: 46.361942291259936
    },
    {
        lng: 10.117755889892578,
        lat: 46.36086654663109
    },
    {
        lng: 10.117410659790096,
        lat: 46.36051177978533
    },
    {
        lng: 10.116138458252067,
        lat: 46.359424591064396
    },
    {
        lng: 10.11529636383068,
        lat: 46.35883712768566
    },
    {
        lng: 10.114523887634391,
        lat: 46.35813522338884
    },
    {
        lng: 10.11396598815918,
        lat: 46.35774230957037
    },
    {
        lng: 10.113299369811955,
        lat: 46.35741424560558
    },
    {
        lng: 10.112520217895451,
        lat: 46.357181549072436
    },
    {
        lng: 10.110429763793945,
        lat: 46.35692214965849
    },
    {
        lng: 10.109649658203125,
        lat: 46.3567733764649
    },
    {
        lng: 10.108961105346793,
        lat: 46.35647964477562
    },
    {
        lng: 10.108692169189567,
        lat: 46.35624313354492
    },
    {
        lng: 10.108371734619084,
        lat: 46.3556938171389
    },
    {
        lng: 10.108233451843319,
        lat: 46.35507965087902
    },
    {
        lng: 10.108127593994254,
        lat: 46.35273742675804
    },
    {
        lng: 10.107949256896973,
        lat: 46.35172653198248
    },
    {
        lng: 10.107687950134277,
        lat: 46.35112380981468
    },
    {
        lng: 10.106895446777457,
        lat: 46.349746704101676
    },
    {
        lng: 10.106211662292594,
        lat: 46.348007202148494
    },
    {
        lng: 10.105534553527775,
        lat: 46.346923828125114
    },
    {
        lng: 10.105258941650504,
        lat: 46.34634017944359
    },
    {
        lng: 10.104738235473633,
        lat: 46.34377670288097
    },
    {
        lng: 10.104303359985295,
        lat: 46.34243774414085
    },
    {
        lng: 10.103920936584473,
        lat: 46.340923309326115
    },
    {
        lng: 10.103461265564079,
        lat: 46.339584350586165
    },
    {
        lng: 10.103281021118221,
        lat: 46.33861923217785
    },
    {
        lng: 10.103154182434139,
        lat: 46.337074279785156
    },
    {
        lng: 10.103179931640625,
        lat: 46.33638381958025
    },
    {
        lng: 10.103363037109375,
        lat: 46.335990905761776
    },
    {
        lng: 10.103691101074162,
        lat: 46.33566284179699
    },
    {
        lng: 10.104536056518612,
        lat: 46.33514404296892
    },
    {
        lng: 10.105314254760856,
        lat: 46.33457183837908
    },
    {
        lng: 10.106210708618107,
        lat: 46.33407211303711
    },
    {
        lng: 10.10699462890625,
        lat: 46.33349609375023
    },
    {
        lng: 10.107876777648926,
        lat: 46.33298110961914
    },
    {
        lng: 10.10921764373785,
        lat: 46.33192062377941
    },
    {
        lng: 10.109586715698242,
        lat: 46.33153152465832
    },
    {
        lng: 10.110333442687988,
        lat: 46.32995223999046
    },
    {
        lng: 10.111171722412223,
        lat: 46.32860565185558
    },
    {
        lng: 10.111960411071834,
        lat: 46.326557159423885
    },
    {
        lng: 10.112927436828727,
        lat: 46.32490158081049
    },
    {
        lng: 10.113581657409725,
        lat: 46.323143005371094
    },
    {
        lng: 10.114419937133732,
        lat: 46.32179260253923
    },
    {
        lng: 10.115015029907227,
        lat: 46.320457458496264
    },
    {
        lng: 10.11534500122076,
        lat: 46.31999588012695
    },
    {
        lng: 10.116851806640568,
        lat: 46.318759918213004
    },
    {
        lng: 10.117713928222713,
        lat: 46.31823348999018
    },
    {
        lng: 10.118487358093262,
        lat: 46.31763839721691
    },
    {
        lng: 10.12026119232172,
        lat: 46.31653594970709
    },
    {
        lng: 10.124225616455078,
        lat: 46.31481933593756
    },
    {
        lng: 10.124418258667106,
        lat: 46.31469726562494
    },
    {
        lng: 10.125982284545898,
        lat: 46.31369781494152
    },
    {
        lng: 10.126744270324707,
        lat: 46.31309127807617
    },
    {
        lng: 10.127606391906852,
        lat: 46.312557220458984
    },
    {
        lng: 10.128363609314022,
        lat: 46.31195068359381
    },
    {
        lng: 10.130027770996094,
        lat: 46.310955047607706
    },
    {
        lng: 10.13012504577648,
        lat: 46.31089782714844
    },
    {
        lng: 10.130758285522461,
        lat: 46.31067276000999
    },
    {
        lng: 10.132426261901855,
        lat: 46.31023025512695
    },
    {
        lng: 10.133737564086914,
        lat: 46.30968475341808
    },
    {
        lng: 10.135213851928768,
        lat: 46.30938720703125
    },
    {
        lng: 10.135981559753532,
        lat: 46.30917739868187
    },
    {
        lng: 10.136343955993652,
        lat: 46.30902862548828
    },
    {
        lng: 10.136982917785758,
        lat: 46.308635711670036
    },
    {
        lng: 10.13747596740734,
        lat: 46.30813217163086
    },
    {
        lng: 10.137658119201603,
        lat: 46.307819366455305
    },
    {
        lng: 10.137848854064885,
        lat: 46.307163238525334
    },
    {
        lng: 10.137884140014705,
        lat: 46.30648803710949
    },
    {
        lng: 10.137851715087947,
        lat: 46.30621337890648
    },
    {
        lng: 10.137804985046444,
        lat: 46.30581283569347
    },
    {
        lng: 10.137602806091422,
        lat: 46.30514526367199
    },
    {
        lng: 10.137467384338322,
        lat: 46.30490493774414
    },
    {
        lng: 10.137273788452148,
        lat: 46.30455780029325
    },
    {
        lng: 10.13687801361084,
        lat: 46.30405426025408
    },
    {
        lng: 10.138327598571891,
        lat: 46.30343627929699
    },
    {
        lng: 10.138861656189022,
        lat: 46.3032112121582
    },
    {
        lng: 10.140681266784725,
        lat: 46.30253982543957
    },
    {
        lng: 10.14152717590332,
        lat: 46.30200958251976
    },
    {
        lng: 10.142413139343319,
        lat: 46.30154800415062
    },
    {
        lng: 10.143161773681697,
        lat: 46.3009986877442
    },
    {
        lng: 10.14514064788824,
        lat: 46.29986953735363
    },
    {
        lng: 10.147111892700252,
        lat: 46.299201965332145
    },
    {
        lng: 10.1478910446167,
        lat: 46.298999786377124
    },
    {
        lng: 10.148769378662223,
        lat: 46.29887008667015
    },
    {
        lng: 10.150959968566895,
        lat: 46.29869079589844
    },
    {
        lng: 10.151753425598145,
        lat: 46.29852676391613
    },
    {
        lng: 10.152108192443961,
        lat: 46.298393249511946
    },
    {
        lng: 10.152585029602164,
        lat: 46.29808425903343
    },
    {
        lng: 10.153655052185059,
        lat: 46.29710006713884
    },
    {
        lng: 10.154720306396428,
        lat: 46.29590606689476
    },
    {
        lng: 10.155425071716309,
        lat: 46.29525375366234
    },
    {
        lng: 10.155700683593807,
        lat: 46.29472732543957
    },
    {
        lng: 10.155831336975098,
        lat: 46.29413986206066
    },
    {
        lng: 10.155858993530387,
        lat: 46.29369735717796
    },
    {
        lng: 10.155871391296387,
        lat: 46.29351043701183
    },
    {
        lng: 10.155769348144474,
        lat: 46.29117584228521
    },
    {
        lng: 10.15585041046154,
        lat: 46.29079055786133
    },
    {
        lng: 10.155960083007756,
        lat: 46.29060363769537
    },
    {
        lng: 10.156504631042537,
        lat: 46.29015731811552
    },
    {
        lng: 10.15802192687994,
        lat: 46.28934097290062
    },
    {
        lng: 10.159589767456055,
        lat: 46.28890609741211
    },
    {
        lng: 10.160185813903809,
        lat: 46.2886390686038
    },
    {
        lng: 10.16042327880865,
        lat: 46.28844451904297
    },
    {
        lng: 10.160747528076229,
        lat: 46.287979125976676
    },
    {
        lng: 10.161496162414608,
        lat: 46.285911560058594
    },
    {
        lng: 10.16157531738287,
        lat: 46.285011291503906
    },
    {
        lng: 10.161660194397086,
        lat: 46.28404617309582
    },
    {
        lng: 10.161817550659123,
        lat: 46.28224945068388
    },
    {
        lng: 10.162186622619686,
        lat: 46.280887603759936
    },
    {
        lng: 10.162485122680607,
        lat: 46.27896499633789
    },
    {
        lng: 10.162845611572322,
        lat: 46.278030395507926
    },
    {
        lng: 10.163536071777457,
        lat: 46.27724838256836
    },
    {
        lng: 10.164422988891602,
        lat: 46.276573181152344
    },
    {
        lng: 10.165399551391602,
        lat: 46.27600479125982
    },
    {
        lng: 10.166263580322322,
        lat: 46.275611877441406
    },
    {
        lng: 10.166836738586483,
        lat: 46.27535247802746
    },
    {
        lng: 10.167684555053825,
        lat: 46.274776458740405
    },
    {
        lng: 10.168587684631348,
        lat: 46.27426910400385
    },
    {
        lng: 10.16934776306158,
        lat: 46.27367782592796
    },
    {
        lng: 10.170209884643498,
        lat: 46.273155212402514
    },
    {
        lng: 10.171710968017578,
        lat: 46.27194976806669
    },
    {
        lng: 10.172034263610897,
        lat: 46.27151489257818
    },
    {
        lng: 10.172676086425838,
        lat: 46.270370483398494
    },
    {
        lng: 10.173514366149902,
        lat: 46.269477844238395
    },
    {
        lng: 10.174225807189998,
        lat: 46.26788330078148
    },
    {
        lng: 10.175062179565487,
        lat: 46.266532897949446
    },
    {
        lng: 10.175287246704102,
        lat: 46.265899658203125
    },
    {
        lng: 10.175411224365291,
        lat: 46.26524353027355
    },
    {
        lng: 10.175468444824219,
        lat: 46.26430511474621
    },
    {
        lng: 10.175514221191463,
        lat: 46.263557434082315
    },
    {
        lng: 10.17550373077404,
        lat: 46.26299285888683
    },
    {
        lng: 10.175482749939022,
        lat: 46.26189041137701
    },
    {
        lng: 10.17533969879156,
        lat: 46.260955810547046
    },
    {
        lng: 10.175111770629883,
        lat: 46.260398864746094
    },
    {
        lng: 10.174930572509709,
        lat: 46.26015472412104
    },
    {
        lng: 10.174319267273063,
        lat: 46.25965499877941
    },
    {
        lng: 10.173440933227596,
        lat: 46.25893783569336
    },
    {
        lng: 10.172575950622559,
        lat: 46.258430480956974
    },
    {
        lng: 10.17181491851801,
        lat: 46.25784683227562
    },
    {
        lng: 10.170918464660758,
        lat: 46.25734329223644
    },
    {
        lng: 10.17008113861084,
        lat: 46.25675964355486
    },
    {
        lng: 10.16862678527832,
        lat: 46.25608062744146
    },
    {
        lng: 10.167920112609863,
        lat: 46.25569152832037
    },
    {
        lng: 10.163724899291935,
        lat: 46.25295639038097
    },
    {
        lng: 10.162667274475098,
        lat: 46.252357482910156
    },
    {
        lng: 10.161214828491325,
        lat: 46.25167465209961
    },
    {
        lng: 10.160381317138786,
        lat: 46.251083374023665
    },
    {
        lng: 10.159494400024414,
        lat: 46.25057220458996
    },
    {
        lng: 10.158740043640137,
        lat: 46.24997329711914
    },
    {
        lng: 10.157876014709416,
        lat: 46.24945449829124
    },
    {
        lng: 10.157127380371094,
        lat: 46.24885177612305
    },
    {
        lng: 10.156257629394588,
        lat: 46.2483291625976
    },
    {
        lng: 10.155502319335994,
        lat: 46.24772644042997
    },
    {
        lng: 10.154630661010742,
        lat: 46.247203826904354
    },
    {
        lng: 10.15387058258051,
        lat: 46.24660873413109
    },
    {
        lng: 10.152994155883789,
        lat: 46.24608993530285
    },
    {
        lng: 10.152235984802303,
        lat: 46.24549865722673
    },
    {
        lng: 10.15117359161377,
        lat: 46.24483108520525
    },
    {
        lng: 10.150048255920524,
        lat: 46.24392318725609
    },
    {
        lng: 10.149681091308594,
        lat: 46.24353408813499
    },
    {
        lng: 10.14896202087408,
        lat: 46.24193954467768
    },
    {
        lng: 10.148122787475586,
        lat: 46.24060058593773
    },
    {
        lng: 10.147445678710994,
        lat: 46.23898315429682
    },
    {
        lng: 10.146615028381461,
        lat: 46.23809051513672
    },
    {
        lng: 10.145920753479004,
        lat: 46.236984252929915
    },
    {
        lng: 10.14551162719738,
        lat: 46.23658370971691
    },
    {
        lng: 10.144931793213004,
        lat: 46.23630523681635
    },
    {
        lng: 10.143369674682617,
        lat: 46.235816955566634
    },
    {
        lng: 10.140729904174862,
        lat: 46.234287261963004
    },
    {
        lng: 10.138488769531307,
        lat: 46.23359298706055
    },
    {
        lng: 10.136746406555233,
        lat: 46.23255157470703
    },
    {
        lng: 10.135665893554744,
        lat: 46.23182678222679
    },
    {
        lng: 10.135098457336369,
        lat: 46.231609344482706
    },
    {
        lng: 10.133349418640137,
        lat: 46.23093414306669
    },
    {
        lng: 10.132482528686523,
        lat: 46.23073196411133
    },
    {
        lng: 10.131517410278377,
        lat: 46.230625152588004
    },
    {
        lng: 10.127561569213924,
        lat: 46.23051834106445
    },
    {
        lng: 10.126210212707576,
        lat: 46.23038482666027
    },
    {
        lng: 10.125455856323242,
        lat: 46.23018646240246
    },
    {
        lng: 10.12426853179943,
        lat: 46.229629516601506
    },
    {
        lng: 10.123696327209586,
        lat: 46.22948074340832
    },
    {
        lng: 10.122827529907227,
        lat: 46.22933578491222
    },
    {
        lng: 10.121937751769963,
        lat: 46.22937011718756
    },
    {
        lng: 10.121067047119197,
        lat: 46.22956466674805
    },
    {
        lng: 10.12052440643322,
        lat: 46.22975540161133
    },
    {
        lng: 10.11963939666748,
        lat: 46.23027420043974
    },
    {
        lng: 10.119094848632926,
        lat: 46.23049926757835
    },
    {
        lng: 10.117854118347282,
        lat: 46.23086547851585
    },
    {
        lng: 10.117085456848201,
        lat: 46.23102569580078
    },
    {
        lng: 10.115217208862362,
        lat: 46.23116683959984
    },
    {
        lng: 10.109873771667424,
        lat: 46.23120880126953
    },
    {
        lng: 10.108941078186092,
        lat: 46.23127365112305
    },
    {
        lng: 10.108050346374569,
        lat: 46.23140335083019
    },
    {
        lng: 10.106191635131836,
        lat: 46.23194885253906
    },
    {
        lng: 10.105015754699707,
        lat: 46.232601165771484
    },
    {
        lng: 10.104461669921818,
        lat: 46.23282241821306
    },
    {
        lng: 10.103219985961914,
        lat: 46.23318481445318
    },
    {
        lng: 10.102436065673942,
        lat: 46.23334121704113
    },
    {
        lng: 10.101119041442928,
        lat: 46.23345565795921
    },
    {
        lng: 10.10099029541027,
        lat: 46.23346710205078
    },
    {
        lng: 10.096466064453125,
        lat: 46.233596801757926
    },
    {
        lng: 10.09319019317627,
        lat: 46.233940124511776
    },
    {
        lng: 10.092383384704704,
        lat: 46.233924865722884
    },
    {
        lng: 10.091635704040641,
        lat: 46.23382568359369
    },
    {
        lng: 10.091048240661735,
        lat: 46.23363876342796
    },
    {
        lng: 10.090331077575684,
        lat: 46.233329772949446
    },
    {
        lng: 10.089620590210018,
        lat: 46.23315429687523
    },
    {
        lng: 10.087036132812557,
        lat: 46.232868194580305
    },
    {
        lng: 10.086153030395621,
        lat: 46.23271179199219
    },
    {
        lng: 10.083197593689022,
        lat: 46.23176574707048
    },
    {
        lng: 10.082459449768123,
        lat: 46.23147964477556
    },
    {
        lng: 10.080989837646541,
        lat: 46.23066329956066
    },
    {
        lng: 10.078609466552678,
        lat: 46.2290153503418
    },
    {
        lng: 10.077056884765625,
        lat: 46.22780990600597
    },
    {
        lng: 10.075725555420036,
        lat: 46.226562500000114
    },
    {
        lng: 10.073966979980469,
        lat: 46.2255973815918
    },
    {
        lng: 10.071221351623592,
        lat: 46.22383117675787
    },
    {
        lng: 10.07048511505127,
        lat: 46.22345733642584
    },
    {
        lng: 10.06965637207037,
        lat: 46.2232017517091
    },
    {
        lng: 10.068905830383244,
        lat: 46.22313690185558
    },
    {
        lng: 10.068157196044922,
        lat: 46.22322082519554
    },
    {
        lng: 10.067741394043026,
        lat: 46.223335266113224
    },
    {
        lng: 10.06698989868164,
        lat: 46.22366333007824
    },
    {
        lng: 10.064601898193416,
        lat: 46.225139617920036
    },
    {
        lng: 10.0624418258667,
        lat: 46.22621154785156
    },
    {
        lng: 10.061997413635254,
        lat: 46.22654342651367
    },
    {
        lng: 10.061239242553768,
        lat: 46.22726058959961
    },
    {
        lng: 10.060397148132381,
        lat: 46.22789001464872
    },
    {
        lng: 10.059443473815975,
        lat: 46.22887420654291
    },
    {
        lng: 10.05891132354742,
        lat: 46.22929763793957
    },
    {
        lng: 10.057295799255485,
        lat: 46.23020935058588
    },
    {
        lng: 10.056486129760856,
        lat: 46.23082351684582
    },
    {
        lng: 10.054698944091854,
        lat: 46.23189544677763
    },
    {
        lng: 10.053655624389762,
        lat: 46.23220062255882
    },
    {
        lng: 10.053108215332145,
        lat: 46.23229980468744
    },
    {
        lng: 10.051042556762809,
        lat: 46.23266983032249
    },
    {
        lng: 10.048904418945426,
        lat: 46.23298645019537
    },
    {
        lng: 10.047634124755916,
        lat: 46.233329772949446
    },
    {
        lng: 10.046483993530273,
        lat: 46.23389816284208
    },
    {
        lng: 10.045470237732047,
        lat: 46.23458099365246
    },
    {
        lng: 10.044636726379508,
        lat: 46.23535156249994
    },
    {
        lng: 10.044269561767521,
        lat: 46.23595046997076
    },
    {
        lng: 10.044176101684684,
        lat: 46.23650360107416
    },
    {
        lng: 10.044299125671387,
        lat: 46.23704910278343
    },
    {
        lng: 10.044730186462346,
        lat: 46.2376518249514
    },
    {
        lng: 10.045332908630371,
        lat: 46.238166809082145
    },
    {
        lng: 10.046027183532829,
        lat: 46.238616943359375
    },
    {
        lng: 10.046771049499512,
        lat: 46.23900604248047
    },
    {
        lng: 10.0483016967774,
        lat: 46.239597320556754
    },
    {
        lng: 10.04924678802496,
        lat: 46.24008178710949
    },
    {
        lng: 10.051103591918888,
        lat: 46.240661621094034
    },
    {
        lng: 10.051614761352653,
        lat: 46.24106597900402
    },
    {
        lng: 10.051781654358024,
        lat: 46.2413330078125
    },
    {
        lng: 10.051980018615836,
        lat: 46.241924285888786
    },
    {
        lng: 10.052216529846135,
        lat: 46.2442741394043
    },
    {
        lng: 10.052368164062614,
        lat: 46.24494552612316
    },
    {
        lng: 10.0530748367309,
        lat: 46.24673843383806
    },
    {
        lng: 10.053852081298828,
        lat: 46.248210906982536
    },
    {
        lng: 10.05419731140148,
        lat: 46.24872207641607
    },
    {
        lng: 10.055530548095646,
        lat: 46.25034332275396
    },
    {
        lng: 10.056635856628532,
        lat: 46.25138854980469
    },
    {
        lng: 10.057491302490234,
        lat: 46.25280380249018
    },
    {
        lng: 10.058533668518066,
        lat: 46.25378417968756
    },
    {
        lng: 10.059386253357047,
        lat: 46.255214691162166
    },
    {
        lng: 10.0587673187257,
        lat: 46.25687789916992
    },
    {
        lng: 10.058375358581486,
        lat: 46.25891113281273
    },
    {
        lng: 10.058279037475586,
        lat: 46.25941467285156
    },
    {
        lng: 10.057053565979004,
        lat: 46.262397766113395
    },
    {
        lng: 10.056535720825252,
        lat: 46.264900207519645
    },
    {
        lng: 10.055448532104492,
        lat: 46.267589569092024
    },
    {
        lng: 10.054971694946289,
        lat: 46.269760131836165
    },
    {
        lng: 10.054758071899414,
        lat: 46.2703742980957
    },
    {
        lng: 10.05436992645258,
        lat: 46.27097320556652
    },
    {
        lng: 10.05380725860607,
        lat: 46.27152252197271
    },
    {
        lng: 10.053149223327694,
        lat: 46.272014617920036
    },
    {
        lng: 10.052436828613281,
        lat: 46.27246093750023
    },
    {
        lng: 10.049302101135368,
        lat: 46.27392196655285
    },
    {
        lng: 10.047616958618278,
        lat: 46.274345397949446
    },
    {
        lng: 10.046008110046387,
        lat: 46.27500152587919
    },
    {
        lng: 10.044127464294547,
        lat: 46.27539443969749
    },
    {
        lng: 10.043359756469783,
        lat: 46.27560806274437
    },
    {
        lng: 10.041069030761776,
        lat: 46.27648925781256
    },
    {
        lng: 10.039088249206657,
        lat: 46.2777099609375
    },
    {
        lng: 10.03832912445074,
        lat: 46.278297424316406
    },
    {
        lng: 10.037449836730957,
        lat: 46.278812408447266
    },
    {
        lng: 10.036608695984,
        lat: 46.27938842773466
    },
    {
        lng: 10.032976150512638,
        lat: 46.28094100952171
    },
    {
        lng: 10.031517982482967,
        lat: 46.28174209594738
    },
    {
        lng: 10.029208183288574,
        lat: 46.28261566162121
    },
    {
        lng: 10.028423309326286,
        lat: 46.28282928466808
    },
    {
        lng: 10.02595138549816,
        lat: 46.28328323364269
    },
    {
        lng: 10.023599624633903,
        lat: 46.283939361572436
    },
    {
        lng: 10.021117210388184,
        lat: 46.284385681152344
    },
    {
        lng: 10.018780708312988,
        lat: 46.28504943847679
    },
    {
        lng: 10.016308784484863,
        lat: 46.2855033874514
    },
    {
        lng: 10.01438140869152,
        lat: 46.286079406738395
    },
    {
        lng: 10.011507034301815,
        lat: 46.286621093750114
    },
    {
        lng: 10.010576248169002,
        lat: 46.286888122558764
    },
    {
        lng: 10.00956821441656,
        lat: 46.287178039550895
    },
    {
        lng: 10.008694648742676,
        lat: 46.287330627441634
    },
    {
        lng: 10.005555152893066,
        lat: 46.28767013549816
    },
    {
        lng: 10.002737045288029,
        lat: 46.28835296630888
    },
    {
        lng: 9.999588012695312,
        lat: 46.2886657714846
    },
    {
        lng: 9.998706817626953,
        lat: 46.288818359375
    },
    {
        lng: 9.99792289733898,
        lat: 46.289043426513615
    },
    {
        lng: 9.995897293090877,
        lat: 46.289775848388956
    },
    {
        lng: 9.995100021362418,
        lat: 46.29024124145508
    },
    {
        lng: 9.994873046875057,
        lat: 46.290466308593864
    },
    {
        lng: 9.994695663452262,
        lat: 46.29064178466825
    },
    {
        lng: 9.994135856628418,
        lat: 46.29153823852539
    },
    {
        lng: 9.993802070617676,
        lat: 46.29229736328148
    },
    {
        lng: 9.993233680725211,
        lat: 46.294792175293026
    },
    {
        lng: 9.992950439453182,
        lat: 46.29536819458008
    },
    {
        lng: 9.992242813110408,
        lat: 46.29643249511736
    },
    {
        lng: 9.991314888000488,
        lat: 46.298404693603516
    },
    {
        lng: 9.990462303161678,
        lat: 46.29967880249035
    },
    {
        lng: 9.990291595458928,
        lat: 46.30023193359375
    },
    {
        lng: 9.990288734436035,
        lat: 46.30077743530296
    },
    {
        lng: 9.990486145019588,
        lat: 46.3012886047365
    },
    {
        lng: 9.990899085998592,
        lat: 46.3016815185548
    },
    {
        lng: 9.991670608520565,
        lat: 46.30203247070318
    },
    {
        lng: 9.993646621704215,
        lat: 46.302700042724666
    },
    {
        lng: 9.99413108825695,
        lat: 46.302951812744254
    },
    {
        lng: 9.99511432647705,
        lat: 46.30372619628929
    },
    {
        lng: 9.995361328124943,
        lat: 46.30387878417969
    },
    {
        lng: 9.995963096618652,
        lat: 46.30425262451189
    },
    {
        lng: 9.996693611145076,
        lat: 46.304832458496094
    },
    {
        lng: 9.997520446777344,
        lat: 46.30533218383812
    },
    {
        lng: 9.998734474182186,
        lat: 46.30632400512701
    },
    {
        lng: 9.999025344848576,
        lat: 46.306713104248104
    },
    {
        lng: 9.999139785766658,
        lat: 46.307193756103516
    },
    {
        lng: 9.998986244201774,
        lat: 46.30792999267601
    },
    {
        lng: 9.998694419860897,
        lat: 46.3083190917971
    },
    {
        lng: 9.998021125793457,
        lat: 46.308967590332145
    },
    {
        lng: 9.997288703918514,
        lat: 46.31008148193371
    },
    {
        lng: 9.996233940124512,
        lat: 46.3110733032226
    },
    {
        lng: 9.995994567871094,
        lat: 46.31147003173828
    },
    {
        lng: 9.99576377868658,
        lat: 46.3120689392091
    },
    {
        lng: 9.995750427246094,
        lat: 46.312480926513956
    },
    {
        lng: 9.9960355758667,
        lat: 46.313270568847884
    },
    {
        lng: 9.996283531188908,
        lat: 46.313640594482536
    },
    {
        lng: 9.996940612792969,
        lat: 46.314258575439624
    },
    {
        lng: 9.997323036193848,
        lat: 46.31501388549805
    },
    {
        lng: 9.997550964355526,
        lat: 46.31647109985363
    },
    {
        lng: 9.997563362121525,
        lat: 46.317237854003906
    },
    {
        lng: 9.997456550598201,
        lat: 46.317661285400334
    },
    {
        lng: 9.997076988220215,
        lat: 46.31813812255854
    },
    {
        lng: 9.996807098388672,
        lat: 46.31834030151373
    },
    {
        lng: 9.996149063110465,
        lat: 46.318664550781364
    },
    {
        lng: 9.995388984680233,
        lat: 46.318855285644645
    },
    {
        lng: 9.993411064148006,
        lat: 46.31914901733427
    },
    {
        lng: 9.99185657501215,
        lat: 46.3195495605471
    },
    {
        lng: 9.991296768188533,
        lat: 46.31976318359398
    },
    {
        lng: 9.989521980285758,
        lat: 46.32081604003906
    },
    {
        lng: 9.988759994506893,
        lat: 46.32140731811529
    },
    {
        lng: 9.987900733947868,
        lat: 46.32192611694359
    },
    {
        lng: 9.987144470214787,
        lat: 46.322521209717024
    },
    {
        lng: 9.986277580261174,
        lat: 46.32304000854509
    },
    {
        lng: 9.985949516296387,
        lat: 46.32329559326172
    },
    {
        lng: 9.98550987243658,
        lat: 46.32363128662138
    },
    {
        lng: 9.984636306762752,
        lat: 46.324150085449446
    },
    {
        lng: 9.983862876892204,
        lat: 46.32473754882818
    },
    {
        lng: 9.982982635498104,
        lat: 46.32524108886719
    },
    {
        lng: 9.982204437255916,
        lat: 46.32581710815441
    },
    {
        lng: 9.98132610321045,
        lat: 46.32632064819359
    },
    {
        lng: 9.97999191284174,
        lat: 46.32734680175781
    },
    {
        lng: 9.979619026184196,
        lat: 46.32775878906273
    },
    {
        lng: 9.979482650756779,
        lat: 46.32802581787138
    },
    {
        lng: 9.979289054870662,
        lat: 46.328899383545036
    },
    {
        lng: 9.979342460632381,
        lat: 46.32982254028332
    },
    {
        lng: 9.979523658752555,
        lat: 46.330425262451286
    },
    {
        lng: 9.980291366577148,
        lat: 46.33174133300781
    },
    {
        lng: 9.98101902008068,
        lat: 46.33371353149431
    },
    {
        lng: 9.981908798217887,
        lat: 46.33531570434576
    },
    {
        lng: 9.98246955871582,
        lat: 46.3370170593264
    },
    {
        lng: 9.98323059082037,
        lat: 46.338317871094034
    },
    {
        lng: 9.983877182006836,
        lat: 46.339855194092024
    },
    {
        lng: 9.98420333862299,
        lat: 46.34024810791027
    },
    {
        lng: 9.984914779663143,
        lat: 46.34089279174833
    },
    {
        lng: 9.985650062560978,
        lat: 46.34172821044933
    },
    {
        lng: 9.987642288208008,
        lat: 46.34345626831055
    },
    {
        lng: 9.988416671753043,
        lat: 46.34426498413109
    },
    {
        lng: 9.990939140319881,
        lat: 46.346134185791016
    },
    {
        lng: 9.99125862121582,
        lat: 46.34650802612305
    },
    {
        lng: 9.991742134094295,
        lat: 46.34734344482422
    },
    {
        lng: 9.99201583862299,
        lat: 46.348213195800895
    },
    {
        lng: 9.9921035766601,
        lat: 46.34923934936529
    },
    {
        lng: 9.992100715637207,
        lat: 46.35289382934582
    },
    {
        lng: 9.992099761962947,
        lat: 46.35382843017578
    },
    {
        lng: 9.99193191528326,
        lat: 46.355102539062784
    },
    {
        lng: 9.991610527038517,
        lat: 46.35566329956055
    },
    {
        lng: 9.991316795349064,
        lat: 46.355907440185774
    },
    {
        lng: 9.99098014831543,
        lat: 46.35607147216808
    },
    {
        lng: 9.990227699279728,
        lat: 46.35623931884777
    },
    {
        lng: 9.989033699035645,
        lat: 46.35624694824247
    },
    {
        lng: 9.986747741699332,
        lat: 46.355907440185774
    },
    {
        lng: 9.984841346740836,
        lat: 46.35579681396507
    },
    {
        lng: 9.982981681823844,
        lat: 46.35586166381836
    },
    {
        lng: 9.98214340209961,
        lat: 46.35600662231445
    },
    {
        lng: 9.981767654419002,
        lat: 46.35612106323248
    },
    {
        lng: 9.97893238067627,
        lat: 46.35747528076172
    },
    {
        lng: 9.975707054138184,
        lat: 46.3594589233399
    },
    {
        lng: 9.973516464233512,
        lat: 46.36053466796875
    },
    {
        lng: 9.972666740417537,
        lat: 46.361118316650675
    },
    {
        lng: 9.971775054931754,
        lat: 46.36163711547857
    },
    {
        lng: 9.971014976501522,
        lat: 46.3622398376466
    },
    {
        lng: 9.970156669616756,
        lat: 46.36281204223644
    },
    {
        lng: 9.969158172607422,
        lat: 46.363739013671875
    },
    {
        lng: 9.96829891204834,
        lat: 46.36431884765625
    },
    {
        lng: 9.96689605712902,
        lat: 46.36497116088867
    },
    {
        lng: 9.96595573425293,
        lat: 46.36549377441406
    },
    {
        lng: 9.963686943054256,
        lat: 46.36617660522484
    },
    {
        lng: 9.961883544921932,
        lat: 46.36718750000017
    },
    {
        lng: 9.961034774780273,
        lat: 46.367759704590014
    },
    {
        lng: 9.960568428039494,
        lat: 46.36799240112316
    },
    {
        lng: 9.959673881530819,
        lat: 46.36843872070324
    },
    {
        lng: 9.959087371826172,
        lat: 46.36881256103527
    },
    {
        lng: 9.957065582275504,
        lat: 46.37060546875
    },
    {
        lng: 9.956739425659293,
        lat: 46.37104415893566
    },
    {
        lng: 9.956216812133846,
        lat: 46.371971130371094
    },
    {
        lng: 9.955368041992188,
        lat: 46.37282562255871
    },
    {
        lng: 9.954655647277775,
        lat: 46.37393951416021
    },
    {
        lng: 9.954316139221305,
        lat: 46.37435531616211
    },
    {
        lng: 9.952257156372184,
        lat: 46.375942230224666
    },
    {
        lng: 9.95191574096691,
        lat: 46.37634277343767
    },
    {
        lng: 9.951800346374512,
        lat: 46.37659072875971
    },
    {
        lng: 9.951695442199764,
        lat: 46.37739944458025
    },
    {
        lng: 9.951942443847713,
        lat: 46.37822341918968
    },
    {
        lng: 9.953379631042537,
        lat: 46.380435943603516
    },
    {
        lng: 9.954356193542537,
        lat: 46.3814697265625
    },
    {
        lng: 9.954688072204704,
        lat: 46.38182067871105
    },
    {
        lng: 9.947984695434627,
        lat: 46.38198471069336
    },
    {
        lng: 9.944955825805721,
        lat: 46.38190841674833
    },
    {
        lng: 9.941081047058105,
        lat: 46.38143539428734
    },
    {
        lng: 9.93727111816412,
        lat: 46.38072586059576
    },
    {
        lng: 9.934902191162166,
        lat: 46.38038253784208
    },
    {
        lng: 9.932600021362305,
        lat: 46.37976455688482
    },
    {
        lng: 9.930504798889274,
        lat: 46.37930679321306
    },
    {
        lng: 9.929839134216365,
        lat: 46.37901306152361
    },
    {
        lng: 9.928433418273983,
        lat: 46.37820053100609
    },
    {
        lng: 9.926861763000488,
        lat: 46.37706756591791
    },
    {
        lng: 9.926508903503475,
        lat: 46.376720428466854
    },
    {
        lng: 9.926216125488338,
        lat: 46.37622070312523
    },
    {
        lng: 9.926057815551815,
        lat: 46.375568389892635
    },
    {
        lng: 9.925890922546444,
        lat: 46.37318801879883
    },
    {
        lng: 9.92578506469738,
        lat: 46.37286758422874
    },
    {
        lng: 9.92543792724615,
        lat: 46.37236022949219
    },
    {
        lng: 9.924986839294434,
        lat: 46.37202453613304
    },
    {
        lng: 9.924605369567871,
        lat: 46.37185287475586
    },
    {
        lng: 9.923812866211051,
        lat: 46.37164306640648
    },
    {
        lng: 9.922919273376465,
        lat: 46.37157058715826
    },
    {
        lng: 9.922321319580135,
        lat: 46.37165832519537
    },
    {
        lng: 9.921680450439453,
        lat: 46.37184906005865
    },
    {
        lng: 9.921236038208008,
        lat: 46.37208557128935
    },
    {
        lng: 9.921010971069393,
        lat: 46.37220382690424
    },
    {
        lng: 9.920584678649902,
        lat: 46.372692108154524
    },
    {
        lng: 9.920273780822754,
        lat: 46.37318420410162
    },
    {
        lng: 9.919962882995605,
        lat: 46.373680114746264
    },
    {
        lng: 9.91839408874523,
        lat: 46.37487792968773
    },
    {
        lng: 9.917663574218807,
        lat: 46.37518692016607
    },
    {
        lng: 9.916672706604004,
        lat: 46.375492095947436
    },
    {
        lng: 9.914141654968375,
        lat: 46.37603759765631
    },
    {
        lng: 9.91345500946045,
        lat: 46.376293182373274
    },
    {
        lng: 9.912970542907715,
        lat: 46.376571655273665
    },
    {
        lng: 9.91215610504156,
        lat: 46.377204895019645
    },
    {
        lng: 9.91130733489996,
        lat: 46.37776184082037
    },
    {
        lng: 9.910566329956168,
        lat: 46.3783721923831
    },
    {
        lng: 9.909742355346737,
        lat: 46.378921508789176
    },
    {
        lng: 9.908203125,
        lat: 46.380157470703296
    },
    {
        lng: 9.90602111816412,
        lat: 46.38260269165062
    },
    {
        lng: 9.905779838561955,
        lat: 46.3828697204591
    },
    {
        lng: 9.904196739196777,
        lat: 46.38413238525385
    },
    {
        lng: 9.903227806091309,
        lat: 46.38478851318382
    },
    {
        lng: 9.902399063110408,
        lat: 46.38555908203148
    },
    {
        lng: 9.898997306823787,
        lat: 46.3842391967774
    },
    {
        lng: 9.896294593811092,
        lat: 46.382579803467024
    },
    {
        lng: 9.894192695617619,
        lat: 46.381877899170036
    },
    {
        lng: 9.89196491241455,
        lat: 46.38053894042986
    },
    {
        lng: 9.891341209411735,
        lat: 46.380233764648494
    },
    {
        lng: 9.88951492309576,
        lat: 46.379703521728516
    },
    {
        lng: 9.888201713562125,
        lat: 46.37914657592796
    },
    {
        lng: 9.886046409606877,
        lat: 46.378707885742244
    },
    {
        lng: 9.884222030639705,
        lat: 46.37815856933605
    },
    {
        lng: 9.882516860961914,
        lat: 46.37775421142584
    },
    {
        lng: 9.881768226623535,
        lat: 46.37744522094749
    },
    {
        lng: 9.8809690475465,
        lat: 46.37672805786144
    },
    {
        lng: 9.880143165588493,
        lat: 46.37579345703125
    },
    {
        lng: 9.879327774047908,
        lat: 46.374660491943644
    },
    {
        lng: 9.878616333007756,
        lat: 46.37399673461937
    },
    {
        lng: 9.877090454101506,
        lat: 46.37310028076189
    },
    {
        lng: 9.873122215270996,
        lat: 46.37030410766596
    },
    {
        lng: 9.869975090026855,
        lat: 46.36861038208008
    },
    {
        lng: 9.868761062622013,
        lat: 46.36814117431658
    },
    {
        lng: 9.8677978515625,
        lat: 46.36799621582054
    },
    {
        lng: 9.866336822509766,
        lat: 46.36804199218756
    },
    {
        lng: 9.863528251648006,
        lat: 46.36872482299816
    },
    {
        lng: 9.862073898315487,
        lat: 46.368831634521484
    },
    {
        lng: 9.858095169067383,
        lat: 46.36853027343767
    },
    {
        lng: 9.850778579712028,
        lat: 46.368480682373274
    },
    {
        lng: 9.849286079406795,
        lat: 46.36834716796875
    },
    {
        lng: 9.8465194702149,
        lat: 46.36787033081055
    },
    {
        lng: 9.845746994018668,
        lat: 46.36765289306646
    },
    {
        lng: 9.844791412353516,
        lat: 46.367214202881144
    },
    {
        lng: 9.844018936157227,
        lat: 46.36699295043951
    },
    {
        lng: 9.841252326965389,
        lat: 46.366512298583984
    },
    {
        lng: 9.840302467346135,
        lat: 46.366405487060604
    },
    {
        lng: 9.83831977844244,
        lat: 46.366336822509936
    },
    {
        lng: 9.835787773132267,
        lat: 46.36636734008812
    },
    {
        lng: 9.833753585815373,
        lat: 46.36645889282255
    },
    {
        lng: 9.830698013305721,
        lat: 46.366714477539176
    },
    {
        lng: 9.830237388610897,
        lat: 46.3655624389649
    },
    {
        lng: 9.829279899597282,
        lat: 46.36365890502947
    },
    {
        lng: 9.827842712402457,
        lat: 46.36180114746105
    },
    {
        lng: 9.82616901397705,
        lat: 46.360408782958984
    },
    {
        lng: 9.825368881225529,
        lat: 46.35982131958008
    },
    {
        lng: 9.824425697326774,
        lat: 46.358783721923885
    },
    {
        lng: 9.823198318481445,
        lat: 46.357700347900504
    },
    {
        lng: 9.821787834167537,
        lat: 46.3566703796389
    },
    {
        lng: 9.820617675781364,
        lat: 46.35600662231445
    },
    {
        lng: 9.819510459899902,
        lat: 46.3556175231933
    },
    {
        lng: 9.817825317382812,
        lat: 46.35522460937523
    },
    {
        lng: 9.817300796508789,
        lat: 46.35516738891607
    },
    {
        lng: 9.816447257995662,
        lat: 46.35507965087902
    },
    {
        lng: 9.813150405883846,
        lat: 46.354938507080305
    },
    {
        lng: 9.811795234680119,
        lat: 46.35471343994152
    },
    {
        lng: 9.810990333557129,
        lat: 46.35441970825207
    },
    {
        lng: 9.80839633941656,
        lat: 46.35315704345709
    },
    {
        lng: 9.806558609008903,
        lat: 46.35185623168945
    },
    {
        lng: 9.805661201477164,
        lat: 46.35109710693388
    },
    {
        lng: 9.804225921630916,
        lat: 46.35012435913103
    },
    {
        lng: 9.803689002990666,
        lat: 46.349887847900334
    },
    {
        lng: 9.802478790283203,
        lat: 46.34952163696289
    },
    {
        lng: 9.801717758178654,
        lat: 46.349369049072266
    },
    {
        lng: 9.800329208374137,
        lat: 46.34924697875982
    },
    {
        lng: 9.796522140503043,
        lat: 46.34906768798845
    },
    {
        lng: 9.793127059936467,
        lat: 46.348487854003906
    },
    {
        lng: 9.792464256286678,
        lat: 46.348278045654524
    },
    {
        lng: 9.79124832153326,
        lat: 46.34768676757824
    },
    {
        lng: 9.7896347045899,
        lat: 46.34718704223661
    },
    {
        lng: 9.789102554321346,
        lat: 46.34691619873058
    },
    {
        lng: 9.788352012634391,
        lat: 46.346397399902514
    },
    {
        lng: 9.787735939025879,
        lat: 46.346061706543196
    },
    {
        lng: 9.785897254943961,
        lat: 46.345317840576115
    },
    {
        lng: 9.784706115722713,
        lat: 46.34436798095726
    },
    {
        lng: 9.783569335937557,
        lat: 46.34328842163109
    },
    {
        lng: 9.781821250915584,
        lat: 46.34215545654308
    },
    {
        lng: 9.779062271118278,
        lat: 46.34098434448242
    },
    {
        lng: 9.778349876403865,
        lat: 46.340740203857365
    },
    {
        lng: 9.777596473693848,
        lat: 46.340591430664176
    },
    {
        lng: 9.776469230651799,
        lat: 46.3405647277832
    },
    {
        lng: 9.774586677551383,
        lat: 46.34077072143572
    },
    {
        lng: 9.771457672119197,
        lat: 46.34094238281256
    },
    {
        lng: 9.77022933959961,
        lat: 46.341098785400504
    },
    {
        lng: 9.769534111023006,
        lat: 46.34130859375023
    },
    {
        lng: 9.768032073974666,
        lat: 46.34219741821295
    },
    {
        lng: 9.767109870910758,
        lat: 46.34292221069359
    },
    {
        lng: 9.766242027282829,
        lat: 46.34343338012695
    },
    {
        lng: 9.765425682067928,
        lat: 46.34401702880871
    },
    {
        lng: 9.762348175048942,
        lat: 46.34537506103544
    },
    {
        lng: 9.761582374572754,
        lat: 46.34580612182623
    },
    {
        lng: 9.761199951171875,
        lat: 46.34625244140648
    },
    {
        lng: 9.760680198669547,
        lat: 46.347206115722884
    },
    {
        lng: 9.759828567504996,
        lat: 46.34807968139671
    },
    {
        lng: 9.75914573669445,
        lat: 46.34921646118187
    },
    {
        lng: 9.758740425109977,
        lat: 46.349636077880916
    },
    {
        lng: 9.758148193359375,
        lat: 46.34992218017584
    },
    {
        lng: 9.756546974182243,
        lat: 46.350433349609375
    },
    {
        lng: 9.753875732421818,
        lat: 46.35203170776384
    },
    {
        lng: 9.751579284668082,
        lat: 46.3527526855471
    },
    {
        lng: 9.748940467834416,
        lat: 46.354377746581974
    },
    {
        lng: 9.748308181762752,
        lat: 46.3546142578125
    },
    {
        lng: 9.746952056884766,
        lat: 46.35498428344738
    },
    {
        lng: 9.745466232299805,
        lat: 46.355724334716854
    },
    {
        lng: 9.743940353393555,
        lat: 46.35608291625999
    },
    {
        lng: 9.74329662322998,
        lat: 46.35612869262707
    },
    {
        lng: 9.740403175354004,
        lat: 46.355995178222884
    },
    {
        lng: 9.739152908325252,
        lat: 46.35585784912121
    },
    {
        lng: 9.738408088684082,
        lat: 46.355655670166186
    },
    {
        lng: 9.737435340881404,
        lat: 46.35515594482422
    },
    {
        lng: 9.735937118530273,
        lat: 46.35449218750006
    },
    {
        lng: 9.734453201294059,
        lat: 46.3536186218264
    },
    {
        lng: 9.727833747863883,
        lat: 46.349170684814624
    },
    {
        lng: 9.726873397827205,
        lat: 46.34839630126959
    },
    {
        lng: 9.726366043090877,
        lat: 46.347835540771655
    },
    {
        lng: 9.72608661651617,
        lat: 46.34727478027355
    },
    {
        lng: 9.726011276245117,
        lat: 46.346763610839844
    },
    {
        lng: 9.726387977600211,
        lat: 46.3444633483889
    },
    {
        lng: 9.726487159729004,
        lat: 46.341991424560604
    },
    {
        lng: 9.726408004760742,
        lat: 46.3392028808596
    },
    {
        lng: 9.726312637329102,
        lat: 46.33855056762718
    },
    {
        lng: 9.7261257171632,
        lat: 46.337944030762
    },
    {
        lng: 9.725778579711971,
        lat: 46.33741760253906
    },
    {
        lng: 9.724443435669002,
        lat: 46.33638763427746
    },
    {
        lng: 9.723346710205135,
        lat: 46.33576202392601
    },
    {
        lng: 9.722549438476676,
        lat: 46.33520889282221
    },
    {
        lng: 9.721648216247559,
        lat: 46.334728240967024
    },
    {
        lng: 9.719997406005973,
        lat: 46.33358764648432
    },
    {
        lng: 9.719478607177734,
        lat: 46.33300781249994
    },
    {
        lng: 9.718948364257926,
        lat: 46.332145690918196
    },
    {
        lng: 9.718682289123535,
        lat: 46.33121871948242
    },
    {
        lng: 9.718653678894157,
        lat: 46.330257415771484
    },
    {
        lng: 9.718892097473258,
        lat: 46.3293075561524
    },
    {
        lng: 9.719281196594181,
        lat: 46.328716278076115
    },
    {
        lng: 9.71984767913824,
        lat: 46.32820892334013
    },
    {
        lng: 9.720521926879996,
        lat: 46.32777404785179
    },
    {
        lng: 9.721264839172363,
        lat: 46.32741546630865
    },
    {
        lng: 9.72292709350586,
        lat: 46.32678604126005
    },
    {
        lng: 9.72363185882574,
        lat: 46.326389312744254
    },
    {
        lng: 9.724511146545524,
        lat: 46.32564926147472
    },
    {
        lng: 9.724698066711426,
        lat: 46.32536697387718
    },
    {
        lng: 9.724905967712402,
        lat: 46.325050354003906
    },
    {
        lng: 9.725111961364746,
        lat: 46.32439041137695
    },
    {
        lng: 9.725218772888297,
        lat: 46.32336044311535
    },
    {
        lng: 9.72518634796154,
        lat: 46.321250915527344
    },
    {
        lng: 9.725049018859863,
        lat: 46.319862365722656
    },
    {
        lng: 9.724961280822754,
        lat: 46.319557189941634
    },
    {
        lng: 9.724764823913517,
        lat: 46.31887435913109
    },
    {
        lng: 9.723953247070312,
        lat: 46.317600250244254
    },
    {
        lng: 9.721435546875114,
        lat: 46.31564712524437
    },
    {
        lng: 9.720375061035156,
        lat: 46.31414031982422
    },
    {
        lng: 9.718310356140137,
        lat: 46.31248855590832
    },
    {
        lng: 9.717817306518555,
        lat: 46.31192016601585
    },
    {
        lng: 9.717157363891602,
        lat: 46.31055450439453
    },
    {
        lng: 9.716313362121639,
        lat: 46.30922317504894
    },
    {
        lng: 9.716036796569824,
        lat: 46.30854415893572
    },
    {
        lng: 9.71539306640625,
        lat: 46.30641937255865
    },
    {
        lng: 9.71524620056158,
        lat: 46.30570220947294
    },
    {
        lng: 9.715096473693904,
        lat: 46.302772521972884
    },
    {
        lng: 9.715000152587947,
        lat: 46.302066802978516
    },
    {
        lng: 9.7147798538208,
        lat: 46.30139923095703
    },
    {
        lng: 9.714591979980412,
        lat: 46.301090240478516
    },
    {
        lng: 9.71422195434576,
        lat: 46.30070877075195
    },
    {
        lng: 9.712154388427848,
        lat: 46.299480438232536
    },
    {
        lng: 9.711400032043514,
        lat: 46.29922866821312
    },
    {
        lng: 9.709878921508732,
        lat: 46.298831939697266
    },
    {
        lng: 9.708879470825309,
        lat: 46.29841232299805
    },
    {
        lng: 9.708474159240836,
        lat: 46.298297882080305
    },
    {
        lng: 9.707563400268612,
        lat: 46.29816436767578
    },
    {
        lng: 9.707209587097282,
        lat: 46.29814910888689
    },
    {
        lng: 9.706060409545955,
        lat: 46.298095703125114
    },
    {
        lng: 9.704491615295524,
        lat: 46.298133850097656
    },
    {
        lng: 9.703450202941838,
        lat: 46.298210144043196
    },
    {
        lng: 9.701378822326717,
        lat: 46.29850769042969
    },
    {
        lng: 9.697753906250114,
        lat: 46.298847198486555
    },
    {
        lng: 9.695273399353141,
        lat: 46.299373626708984
    },
    {
        lng: 9.692864418029728,
        lat: 46.30011367797846
    },
    {
        lng: 9.689977645874023,
        lat: 46.300704956054744
    },
    {
        lng: 9.687371253967285,
        lat: 46.30167007446306
    },
    {
        lng: 9.686067581176758,
        lat: 46.302402496337834
    },
    {
        lng: 9.684564590454102,
        lat: 46.3031082153322
    },
    {
        lng: 9.683045387268123,
        lat: 46.304027557373274
    },
    {
        lng: 9.68161678314209,
        lat: 46.30507278442377
    },
    {
        lng: 9.680990219116211,
        lat: 46.305637359619084
    },
    {
        lng: 9.680487632751465,
        lat: 46.3062362670899
    },
    {
        lng: 9.679744720459098,
        lat: 46.30776977539068
    },
    {
        lng: 9.678981781005973,
        lat: 46.308784484863224
    },
    {
        lng: 9.678806304931754,
        lat: 46.309230804443644
    },
    {
        lng: 9.678673744201717,
        lat: 46.30995178222656
    },
    {
        lng: 9.6769437789917,
        lat: 46.30963897705101
    },
    {
        lng: 9.676155090332088,
        lat: 46.30943679809582
    },
    {
        lng: 9.674707412719783,
        lat: 46.308792114257756
    },
    {
        lng: 9.672775268554801,
        lat: 46.30825424194347
    },
    {
        lng: 9.672096252441406,
        lat: 46.30794143676758
    },
    {
        lng: 9.670549392700309,
        lat: 46.30703735351591
    },
    {
        lng: 9.670124053955078,
        lat: 46.306713104248104
    },
    {
        lng: 9.669721603393498,
        lat: 46.30625534057634
    },
    {
        lng: 9.668519020080566,
        lat: 46.304485321045036
    },
    {
        lng: 9.66803073883068,
        lat: 46.304039001464844
    },
    {
        lng: 9.667490005493278,
        lat: 46.30374908447271
    },
    {
        lng: 9.667049407958984,
        lat: 46.3036003112793
    },
    {
        lng: 9.666097640991154,
        lat: 46.30343246459984
    },
    {
        lng: 9.663082122802791,
        lat: 46.3031845092774
    },
    {
        lng: 9.660634040832576,
        lat: 46.30282592773449
    },
    {
        lng: 9.65976810455328,
        lat: 46.30264282226574
    },
    {
        lng: 9.659063339233398,
        lat: 46.302341461181754
    },
    {
        lng: 9.656721115112418,
        lat: 46.300987243652344
    },
    {
        lng: 9.654466629028377,
        lat: 46.30030822753929
    },
    {
        lng: 9.653153419494743,
        lat: 46.299720764160384
    },
    {
        lng: 9.651973724365234,
        lat: 46.29942321777355
    },
    {
        lng: 9.651241302490234,
        lat: 46.29917144775396
    },
    {
        lng: 9.648584365844783,
        lat: 46.29764175415039
    },
    {
        lng: 9.646379470825195,
        lat: 46.296928405762
    },
    {
        lng: 9.643720626831112,
        lat: 46.29539489746105
    },
    {
        lng: 9.642942428588924,
        lat: 46.29513168334972
    },
    {
        lng: 9.641152381897086,
        lat: 46.29473876953125
    },
    {
        lng: 9.639209747314453,
        lat: 46.29418563842785
    },
    {
        lng: 9.638199806213379,
        lat: 46.29403686523443
    },
    {
        lng: 9.637155532837028,
        lat: 46.293979644775675
    },
    {
        lng: 9.635067939758358,
        lat: 46.294067382812784
    },
    {
        lng: 9.629462242126579,
        lat: 46.29491806030285
    },
    {
        lng: 9.627918243408203,
        lat: 46.29507827758795
    },
    {
        lng: 9.624575614929256,
        lat: 46.295158386230696
    },
    {
        lng: 9.620726585388127,
        lat: 46.29502868652372
    },
    {
        lng: 9.618797302246207,
        lat: 46.2953720092774
    },
    {
        lng: 9.618421554565373,
        lat: 46.29548263549816
    },
    {
        lng: 9.61771297454834,
        lat: 46.29579925537121
    },
    {
        lng: 9.61711502075201,
        lat: 46.29618453979515
    },
    {
        lng: 9.616114616394043,
        lat: 46.29711151123058
    },
    {
        lng: 9.615277290344295,
        lat: 46.29769134521513
    },
    {
        lng: 9.614555358886776,
        lat: 46.29832077026373
    },
    {
        lng: 9.613721847534237,
        lat: 46.2988662719726
    },
    {
        lng: 9.613001823425236,
        lat: 46.2994956970216
    },
    {
        lng: 9.61217117309576,
        lat: 46.300075531006144
    },
    {
        lng: 9.611436843872184,
        lat: 46.300792694091854
    },
    {
        lng: 9.610902786254883,
        lat: 46.3012046813966
    },
    {
        lng: 9.610245704650993,
        lat: 46.30155944824219
    },
    {
        lng: 9.609873771667537,
        lat: 46.301704406738565
    },
    {
        lng: 9.609013557434196,
        lat: 46.30191040039074
    },
    {
        lng: 9.605805397033748,
        lat: 46.302238464355526
    },
    {
        lng: 9.601375579834098,
        lat: 46.30308151245123
    },
    {
        lng: 9.600459098815918,
        lat: 46.30316162109398
    },
    {
        lng: 9.599102973938102,
        lat: 46.30315780639643
    },
    {
        lng: 9.598257064819336,
        lat: 46.30307388305687
    },
    {
        lng: 9.597517013549862,
        lat: 46.30288314819359
    },
    {
        lng: 9.59656906127941,
        lat: 46.302425384521484
    },
    {
        lng: 9.59461784362793,
        lat: 46.30186462402355
    },
    {
        lng: 9.59337520599371,
        lat: 46.30129623413103
    },
    {
        lng: 9.593021392822266,
        lat: 46.30118942260748
    },
    {
        lng: 9.592220306396598,
        lat: 46.30105590820335
    },
    {
        lng: 9.590889930725098,
        lat: 46.301006317138786
    },
    {
        lng: 9.589974403381404,
        lat: 46.30105209350597
    },
    {
        lng: 9.589065551757926,
        lat: 46.301166534424055
    },
    {
        lng: 9.588125228881836,
        lat: 46.30135726928734
    },
    {
        lng: 9.585547447204647,
        lat: 46.301883697509766
    },
    {
        lng: 9.584635734558105,
        lat: 46.30201339721674
    },
    {
        lng: 9.581846237182617,
        lat: 46.30226898193388
    },
    {
        lng: 9.580559730529785,
        lat: 46.3025550842288
    },
    {
        lng: 9.578533172607422,
        lat: 46.303337097168196
    },
    {
        lng: 9.578006744384822,
        lat: 46.303596496582145
    },
    {
        lng: 9.575493812561092,
        lat: 46.30531692504883
    },
    {
        lng: 9.574963569641113,
        lat: 46.305572509765625
    },
    {
        lng: 9.572542190551701,
        lat: 46.30648040771496
    },
    {
        lng: 9.570027351379451,
        lat: 46.30693054199219
    },
    {
        lng: 9.569225311279297,
        lat: 46.30714416503929
    },
    {
        lng: 9.566615104675407,
        lat: 46.30815887451183
    },
    {
        lng: 9.565718650817928,
        lat: 46.30873870849621
    },
    {
        lng: 9.564373016357536,
        lat: 46.309440612793196
    },
    {
        lng: 9.563832283019963,
        lat: 46.309860229492244
    },
    {
        lng: 9.563407897949219,
        lat: 46.31034469604498
    },
    {
        lng: 9.56325531005865,
        lat: 46.31060409545893
    },
    {
        lng: 9.562838554382267,
        lat: 46.311653137206974
    },
    {
        lng: 9.562554359436149,
        lat: 46.312118530273665
    },
    {
        lng: 9.56112003326416,
        lat: 46.313369750976506
    },
    {
        lng: 9.560644149780273,
        lat: 46.31361389160156
    },
    {
        lng: 9.560363769531307,
        lat: 46.31369400024431
    },
    {
        lng: 9.559473991394043,
        lat: 46.31376266479498
    },
    {
        lng: 9.557804107666072,
        lat: 46.31371307373075
    },
    {
        lng: 9.556509971618652,
        lat: 46.31359481811535
    },
    {
        lng: 9.555718421936149,
        lat: 46.31343078613281
    },
    {
        lng: 9.555117607116756,
        lat: 46.31316757202171
    },
    {
        lng: 9.55361366271984,
        lat: 46.31221771240229
    },
    {
        lng: 9.552906990051326,
        lat: 46.311588287353686
    },
    {
        lng: 9.552108764648438,
        lat: 46.3110466003418
    },
    {
        lng: 9.551623344421387,
        lat: 46.310596466064396
    },
    {
        lng: 9.551216125488338,
        lat: 46.310329437255916
    },
    {
        lng: 9.550689697265682,
        lat: 46.31016921997082
    },
    {
        lng: 9.550115585327262,
        lat: 46.31015777587908
    },
    {
        lng: 9.549275398254451,
        lat: 46.310310363769474
    },
    {
        lng: 9.548737525939941,
        lat: 46.31050872802729
    },
    {
        lng: 9.547104835510368,
        lat: 46.31160736083996
    },
    {
        lng: 9.546380996704215,
        lat: 46.312232971191406
    },
    {
        lng: 9.54553508758545,
        lat: 46.31277465820324
    },
    {
        lng: 9.54479789733881,
        lat: 46.313392639160156
    },
    {
        lng: 9.543930053710938,
        lat: 46.31392669677746
    },
    {
        lng: 9.54318809509283,
        lat: 46.314540863037166
    },
    {
        lng: 9.542322158813533,
        lat: 46.315071105957145
    },
    {
        lng: 9.54157829284668,
        lat: 46.31568145751953
    },
    {
        lng: 9.540713310241642,
        lat: 46.3162078857423
    },
    {
        lng: 9.539970397949276,
        lat: 46.31681823730469
    },
    {
        lng: 9.539105415344238,
        lat: 46.31734466552763
    },
    {
        lng: 9.538361549377555,
        lat: 46.317958831787166
    },
    {
        lng: 9.537496566772575,
        lat: 46.318485260009766
    },
    {
        lng: 9.536754608154297,
        lat: 46.31909561157249
    },
    {
        lng: 9.535891532898063,
        lat: 46.31962203979492
    },
    {
        lng: 9.535149574279785,
        lat: 46.32023239135765
    },
    {
        lng: 9.534283638000431,
        lat: 46.320755004882926
    },
    {
        lng: 9.533540725708065,
        lat: 46.321361541748274
    },
    {
        lng: 9.532671928405875,
        lat: 46.321884155273494
    },
    {
        lng: 9.531929969787598,
        lat: 46.32249069213867
    },
    {
        lng: 9.531069755554256,
        lat: 46.32301330566412
    },
    {
        lng: 9.529589653015194,
        lat: 46.324234008789006
    },
    {
        lng: 9.528647422790527,
        lat: 46.32581710815441
    },
    {
        lng: 9.527167320251522,
        lat: 46.327030181884766
    },
    {
        lng: 9.526304244995117,
        lat: 46.327548980713175
    },
    {
        lng: 9.52556228637701,
        lat: 46.32814788818382
    },
    {
        lng: 9.524700164795036,
        lat: 46.32866668701189
    },
    {
        lng: 9.5239896774292,
        lat: 46.32928848266596
    },
    {
        lng: 9.523402214050293,
        lat: 46.329715728759936
    },
    {
        lng: 9.523053169250488,
        lat: 46.33008575439459
    },
    {
        lng: 9.52258682250988,
        lat: 46.33101654052757
    },
    {
        lng: 9.522282600402832,
        lat: 46.33144760131836
    },
    {
        lng: 9.520799636840934,
        lat: 46.33263397216791
    },
    {
        lng: 9.519946098327637,
        lat: 46.33314132690447
    },
    {
        lng: 9.518486976623649,
        lat: 46.33433914184593
    },
    {
        lng: 9.518202781677303,
        lat: 46.334827423095646
    },
    {
        lng: 9.518070220947266,
        lat: 46.33539199829096
    },
    {
        lng: 9.517948150634822,
        lat: 46.338230133056925
    },
    {
        lng: 9.517835617065487,
        lat: 46.338874816894645
    },
    {
        lng: 9.517463684082088,
        lat: 46.340141296386946
    },
    {
        lng: 9.515900611877441,
        lat: 46.34072494506847
    },
    {
        lng: 9.515250205993596,
        lat: 46.34116744995117
    },
    {
        lng: 9.51385307312023,
        lat: 46.342727661132926
    },
    {
        lng: 9.51243114471447,
        lat: 46.344966888427905
    },
    {
        lng: 9.512089729309139,
        lat: 46.345851898193644
    },
    {
        lng: 9.511675834655875,
        lat: 46.34835433959972
    },
    {
        lng: 9.510744094848576,
        lat: 46.351058959960994
    },
    {
        lng: 9.510316848754996,
        lat: 46.35356521606445
    },
    {
        lng: 9.51007270812994,
        lat: 46.354141235351676
    },
    {
        lng: 9.50947093963623,
        lat: 46.35521316528332
    },
    {
        lng: 9.508875846862793,
        lat: 46.35691833496094
    },
    {
        lng: 9.508582115173283,
        lat: 46.35746002197283
    },
    {
        lng: 9.507349014282227,
        lat: 46.35902023315458
    },
    {
        lng: 9.504631996154785,
        lat: 46.361114501953125
    },
    {
        lng: 9.502502441406193,
        lat: 46.36359786987333
    },
    {
        lng: 9.501803398132267,
        lat: 46.364261627197266
    },
    {
        lng: 9.501512527465877,
        lat: 46.364719390869254
    },
    {
        lng: 9.500802993774528,
        lat: 46.36629104614252
    },
    {
        lng: 9.499505043029785,
        lat: 46.36825561523449
    },
    {
        lng: 9.498245239257926,
        lat: 46.36989212036161
    },
    {
        lng: 9.497580528259277,
        lat: 46.37094497680687
    },
    {
        lng: 9.49559116363531,
        lat: 46.37142181396507
    },
    {
        lng: 9.493354797363281,
        lat: 46.371898651123274
    },
    {
        lng: 9.490920066833553,
        lat: 46.372421264648494
    },
    {
        lng: 9.488812446594295,
        lat: 46.37294769287132
    },
    {
        lng: 9.485522270202694,
        lat: 46.37410354614269
    },
    {
        lng: 9.48484992980957,
        lat: 46.374401092529524
    },
    {
        lng: 9.484215736389103,
        lat: 46.37477874755854
    },
    {
        lng: 9.48267459869379,
        lat: 46.37605667114275
    },
    {
        lng: 9.481325149536246,
        lat: 46.37686538696312
    },
    {
        lng: 9.480692863464355,
        lat: 46.37711334228533
    },
    {
        lng: 9.479421615600586,
        lat: 46.3773193359375
    },
    {
        lng: 9.476569175720215,
        lat: 46.377456665039006
    },
    {
        lng: 9.475129127502498,
        lat: 46.37759399414074
    },
    {
        lng: 9.472621917724723,
        lat: 46.378089904785156
    },
    {
        lng: 9.471042633056697,
        lat: 46.37856292724638
    },
    {
        lng: 9.470473289489746,
        lat: 46.37879943847656
    },
    {
        lng: 9.469101905822868,
        lat: 46.379619598388956
    },
    {
        lng: 9.46866703033453,
        lat: 46.379878997802905
    },
    {
        lng: 9.467003822326774,
        lat: 46.38106918334984
    },
    {
        lng: 9.46536731719982,
        lat: 46.3828125
    },
    {
        lng: 9.465003967285213,
        lat: 46.383316040039176
    },
    {
        lng: 9.464361190795898,
        lat: 46.38451385498041
    },
    {
        lng: 9.464155197143555,
        lat: 46.38505172729492
    },
    {
        lng: 9.464035987854004,
        lat: 46.3856582641601
    },
    {
        lng: 9.464030265808049,
        lat: 46.38657379150419
    },
    {
        lng: 9.464587211609,
        lat: 46.38978576660162
    },
    {
        lng: 9.464792251586914,
        lat: 46.390384674072266
    },
    {
        lng: 9.465146064758358,
        lat: 46.390914916992244
    },
    {
        lng: 9.465896606445312,
        lat: 46.39154434204124
    },
    {
        lng: 9.467153549194279,
        lat: 46.392269134521655
    },
    {
        lng: 9.46754360198986,
        lat: 46.392623901367415
    },
    {
        lng: 9.46825218200695,
        lat: 46.3934707641601
    },
    {
        lng: 9.468411445617733,
        lat: 46.39375305175804
    },
    {
        lng: 9.468684196472168,
        lat: 46.394233703613224
    },
    {
        lng: 9.468717575073242,
        lat: 46.39498519897484
    },
    {
        lng: 9.468523979187069,
        lat: 46.395503997802734
    },
    {
        lng: 9.467847824096737,
        lat: 46.3965225219726
    },
    {
        lng: 9.467749595642033,
        lat: 46.39670562744152
    },
    {
        lng: 9.46715259552002,
        lat: 46.397827148437784
    },
    {
        lng: 9.466827392578068,
        lat: 46.398284912109546
    },
    {
        lng: 9.466143608093205,
        lat: 46.39897155761747
    },
    {
        lng: 9.465727806091309,
        lat: 46.399803161621264
    },
    {
        lng: 9.46552753448492,
        lat: 46.40076065063488
    },
    {
        lng: 9.465188980102539,
        lat: 46.40347290039085
    },
    {
        lng: 9.46457576751709,
        lat: 46.405517578125
    },
    {
        lng: 9.46410846710205,
        lat: 46.40853500366234
    },
    {
        lng: 9.463561058044377,
        lat: 46.410221099853516
    },
    {
        lng: 9.46312046051031,
        lat: 46.41213989257841
    },
    {
        lng: 9.462774276733342,
        lat: 46.4130744934082
    },
    {
        lng: 9.461898803710938,
        lat: 46.414436340332145
    },
    {
        lng: 9.46114444732666,
        lat: 46.416156768799055
    },
    {
        lng: 9.460955619812125,
        lat: 46.41643142700224
    },
    {
        lng: 9.46059513092041,
        lat: 46.416961669921875
    },
    {
        lng: 9.459223747253532,
        lat: 46.418476104736385
    },
    {
        lng: 9.45834827423107,
        lat: 46.41911697387707
    },
    {
        lng: 9.457263946533203,
        lat: 46.42015075683622
    },
    {
        lng: 9.455859184265137,
        lat: 46.421249389648494
    },
    {
        lng: 9.454889297485352,
        lat: 46.423244476318416
    },
    {
        lng: 9.454538345336914,
        lat: 46.42453384399414
    },
    {
        lng: 9.454025268554801,
        lat: 46.42642211914074
    },
    {
        lng: 9.453905105590763,
        lat: 46.428352355957315
    },
    {
        lng: 9.454051971435604,
        lat: 46.43032455444347
    },
    {
        lng: 9.454725265503043,
        lat: 46.43298721313482
    },
    {
        lng: 9.455194473266715,
        lat: 46.43692398071289
    },
    {
        lng: 9.455718994140739,
        lat: 46.43882369995117
    },
    {
        lng: 9.456208229064941,
        lat: 46.44200515747082
    },
    {
        lng: 9.45681476593029,
        lat: 46.44429779052757
    },
    {
        lng: 9.457262992858944,
        lat: 46.4482421875
    },
    {
        lng: 9.45779800415039,
        lat: 46.45010757446312
    },
    {
        lng: 9.458327293396053,
        lat: 46.45263290405279
    },
    {
        lng: 9.458868026733455,
        lat: 46.45450210571312
    },
    {
        lng: 9.459075927734375,
        lat: 46.45650482177757
    },
    {
        lng: 9.459134101867619,
        lat: 46.457893371582145
    },
    {
        lng: 9.459295272827262,
        lat: 46.46178054809576
    },
    {
        lng: 9.459516525268668,
        lat: 46.46337509155268
    },
    {
        lng: 9.460591316223201,
        lat: 46.46746063232433
    },
    {
        lng: 9.460665702819824,
        lat: 46.467746734619254
    },
    {
        lng: 9.461367607116642,
        lat: 46.46914672851574
    },
    {
        lng: 9.462517738342285,
        lat: 46.47082519531273
    },
    {
        lng: 9.46334457397461,
        lat: 46.4723739624024
    },
    {
        lng: 9.464359283447322,
        lat: 46.47369384765631
    },
    {
        lng: 9.464691162109489,
        lat: 46.47473144531244
    },
    {
        lng: 9.464856147766056,
        lat: 46.47625732421869
    },
    {
        lng: 9.464843750000057,
        lat: 46.478622436523665
    },
    {
        lng: 9.464402198791504,
        lat: 46.48128509521507
    },
    {
        lng: 9.46432113647461,
        lat: 46.484428405762
    },
    {
        lng: 9.464055061340446,
        lat: 46.485542297363395
    },
    {
        lng: 9.463595390319881,
        lat: 46.48620605468767
    },
    {
        lng: 9.462903022766227,
        lat: 46.48673629760765
    },
    {
        lng: 9.462079048156795,
        lat: 46.48712158203119
    },
    {
        lng: 9.459011077880916,
        lat: 46.48827743530296
    },
    {
        lng: 9.458625793456974,
        lat: 46.48563003540045
    },
    {
        lng: 9.458361625671387,
        lat: 46.48206710815424
    },
    {
        lng: 9.458065986633358,
        lat: 46.48081970214855
    },
    {
        lng: 9.457238197326717,
        lat: 46.479053497314624
    },
    {
        lng: 9.456241607665959,
        lat: 46.47732925415039
    },
    {
        lng: 9.45523738861084,
        lat: 46.47595977783226
    },
    {
        lng: 9.45442008972168,
        lat: 46.47433090209984
    },
    {
        lng: 9.454045295715332,
        lat: 46.47391891479492
    },
    {
        lng: 9.45324897766119,
        lat: 46.47321319580078
    },
    {
        lng: 9.452092170715332,
        lat: 46.4717903137207
    },
    {
        lng: 9.451707839965877,
        lat: 46.47123336792009
    },
    {
        lng: 9.451442718505973,
        lat: 46.47061157226585
    },
    {
        lng: 9.45105361938488,
        lat: 46.4690513610841
    },
    {
        lng: 9.450827598571777,
        lat: 46.46849060058594
    },
    {
        lng: 9.44982624053955,
        lat: 46.46726989746105
    },
    {
        lng: 9.449020385742244,
        lat: 46.46501159667969
    },
    {
        lng: 9.448857307434082,
        lat: 46.4647674560548
    },
    {
        lng: 9.448170661926326,
        lat: 46.46405029296869
    },
    {
        lng: 9.447873115539494,
        lat: 46.4635086059572
    },
    {
        lng: 9.447648048400879,
        lat: 46.46258163452177
    },
    {
        lng: 9.44755554199213,
        lat: 46.46158981323248
    },
    {
        lng: 9.447429656982536,
        lat: 46.456439971923885
    },
    {
        lng: 9.44730091094965,
        lat: 46.45477676391613
    },
    {
        lng: 9.447171211242676,
        lat: 46.45415115356451
    },
    {
        lng: 9.446947097778377,
        lat: 46.453582763671875
    },
    {
        lng: 9.446783065795898,
        lat: 46.45333480834961
    },
    {
        lng: 9.44611740112316,
        lat: 46.45262145996094
    },
    {
        lng: 9.44575119018549,
        lat: 46.4518280029298
    },
    {
        lng: 9.445592880249137,
        lat: 46.45092010498064
    },
    {
        lng: 9.445422172546444,
        lat: 46.44902801513672
    },
    {
        lng: 9.445219993591422,
        lat: 46.44814682006836
    },
    {
        lng: 9.444951057434139,
        lat: 46.44763565063499
    },
    {
        lng: 9.444302558898926,
        lat: 46.44692611694347
    },
    {
        lng: 9.44404220581049,
        lat: 46.44643783569353
    },
    {
        lng: 9.44388675689703,
        lat: 46.44589233398466
    },
    {
        lng: 9.443617820739803,
        lat: 46.44414138793945
    },
    {
        lng: 9.443465232849178,
        lat: 46.4435920715332
    },
    {
        lng: 9.443203926086483,
        lat: 46.44310379028332
    },
    {
        lng: 9.442558288574332,
        lat: 46.44240951538086
    },
    {
        lng: 9.442404747009277,
        lat: 46.442169189453125
    },
    {
        lng: 9.441676139831657,
        lat: 46.43996429443371
    },
    {
        lng: 9.440724372863826,
        lat: 46.43880844116228
    },
    {
        lng: 9.440514564514274,
        lat: 46.43827438354515
    },
    {
        lng: 9.440229415893668,
        lat: 46.437145233154524
    },
    {
        lng: 9.440015792846737,
        lat: 46.436618804931754
    },
    {
        lng: 9.439863204956168,
        lat: 46.43638610839838
    },
    {
        lng: 9.439040184021053,
        lat: 46.43549728393583
    },
    {
        lng: 9.438324928283748,
        lat: 46.4339332580567
    },
    {
        lng: 9.437628746032658,
        lat: 46.43289566040056
    },
    {
        lng: 9.437347412109432,
        lat: 46.43231964111334
    },
    {
        lng: 9.437188148498592,
        lat: 46.431694030761946
    },
    {
        lng: 9.437091827392635,
        lat: 46.4307289123538
    },
    {
        lng: 9.43709754943859,
        lat: 46.42875671386713
    },
    {
        lng: 9.43729305267334,
        lat: 46.42448043823242
    },
    {
        lng: 9.437248229980582,
        lat: 46.423671722412394
    },
    {
        lng: 9.437118530273438,
        lat: 46.423152923583984
    },
    {
        lng: 9.436809539794922,
        lat: 46.42264556884777
    },
    {
        lng: 9.436303138733024,
        lat: 46.42220687866211
    },
    {
        lng: 9.435633659362793,
        lat: 46.421913146972656
    },
    {
        lng: 9.4349622726441,
        lat: 46.42184066772478
    },
    {
        lng: 9.434638977050781,
        lat: 46.42187118530296
    },
    {
        lng: 9.434089660644531,
        lat: 46.422065734863395
    },
    {
        lng: 9.43281459808361,
        lat: 46.422988891601676
    },
    {
        lng: 9.43253231048584,
        lat: 46.42336654663103
    },
    {
        lng: 9.432038307189998,
        lat: 46.42438125610374
    },
    {
        lng: 9.431280136108512,
        lat: 46.42518234252941
    },
    {
        lng: 9.431003570556697,
        lat: 46.42568588256859
    },
    {
        lng: 9.43081092834484,
        lat: 46.42657470703148
    },
    {
        lng: 9.4307279586792,
        lat: 46.42819213867182
    },
    {
        lng: 9.430706024169922,
        lat: 46.43351364135765
    },
    {
        lng: 9.430897712707463,
        lat: 46.43511962890625
    },
    {
        lng: 9.431112289428654,
        lat: 46.435733795166016
    },
    {
        lng: 9.431759834289608,
        lat: 46.436798095703125
    },
    {
        lng: 9.432028770446834,
        lat: 46.43737792968767
    },
    {
        lng: 9.432237625122127,
        lat: 46.43833160400408
    },
    {
        lng: 9.4324245452882,
        lat: 46.439918518066634
    },
    {
        lng: 9.43269062042242,
        lat: 46.4407768249514
    },
    {
        lng: 9.432849884033203,
        lat: 46.441020965576286
    },
    {
        lng: 9.433657646179313,
        lat: 46.441890716552734
    },
    {
        lng: 9.43414497375494,
        lat: 46.44282150268572
    },
    {
        lng: 9.434440612792969,
        lat: 46.443256378174055
    },
    {
        lng: 9.43495082855219,
        lat: 46.44374465942383
    },
    {
        lng: 9.435273170471248,
        lat: 46.444152832031364
    },
    {
        lng: 9.435495376586971,
        lat: 46.444679260254134
    },
    {
        lng: 9.435797691345272,
        lat: 46.44580459594755
    },
    {
        lng: 9.43600940704357,
        lat: 46.44633102416998
    },
    {
        lng: 9.436158180236816,
        lat: 46.446567535400675
    },
    {
        lng: 9.436794281005803,
        lat: 46.44725418090843
    },
    {
        lng: 9.437068939209041,
        lat: 46.4477729797365
    },
    {
        lng: 9.43729209899908,
        lat: 46.449001312255916
    },
    {
        lng: 9.437374114990234,
        lat: 46.45233917236334
    },
    {
        lng: 9.437514305114803,
        lat: 46.453670501708984
    },
    {
        lng: 9.437824249267635,
        lat: 46.45462417602539
    },
    {
        lng: 9.43867111206066,
        lat: 46.45600509643555
    },
    {
        lng: 9.438902854919547,
        lat: 46.456630706787166
    },
    {
        lng: 9.43910217285162,
        lat: 46.45793914794916
    },
    {
        lng: 9.439267158508414,
        lat: 46.46089172363304
    },
    {
        lng: 9.439471244812012,
        lat: 46.46179199218767
    },
    {
        lng: 9.439748764038086,
        lat: 46.46231460571312
    },
    {
        lng: 9.440405845642147,
        lat: 46.463031768799
    },
    {
        lng: 9.440565109252987,
        lat: 46.46327972412104
    },
    {
        lng: 9.440792083740178,
        lat: 46.46383285522484
    },
    {
        lng: 9.441301345825309,
        lat: 46.465690612793026
    },
    {
        lng: 9.441609382629395,
        lat: 46.46629333496105
    },
    {
        lng: 9.442354202270565,
        lat: 46.46741485595709
    },
    {
        lng: 9.443139076232967,
        lat: 46.46907424926786
    },
    {
        lng: 9.444222450256348,
        lat: 46.47024536132835
    },
    {
        lng: 9.444775581359977,
        lat: 46.47122192382818
    },
    {
        lng: 9.445115089416447,
        lat: 46.47168350219749
    },
    {
        lng: 9.445924758911133,
        lat: 46.47237777709978
    },
    {
        lng: 9.446290016174316,
        lat: 46.472782135009766
    },
    {
        lng: 9.447486877441463,
        lat: 46.47429656982433
    },
    {
        lng: 9.447817802429142,
        lat: 46.47489166259794
    },
    {
        lng: 9.448339462280273,
        lat: 46.47676086425787
    },
    {
        lng: 9.448567390441951,
        lat: 46.47732162475586
    },
    {
        lng: 9.448726654052791,
        lat: 46.47757339477562
    },
    {
        lng: 9.449397087097168,
        lat: 46.47830581665039
    },
    {
        lng: 9.44979476928711,
        lat: 46.47912216186546
    },
    {
        lng: 9.450235366821403,
        lat: 46.48100662231468
    },
    {
        lng: 9.450780868530273,
        lat: 46.48270416259794
    },
    {
        lng: 9.450941085815373,
        lat: 46.48400878906256
    },
    {
        lng: 9.451027870178166,
        lat: 46.48598861694359
    },
    {
        lng: 9.450525283813477,
        lat: 46.487064361572436
    },
    {
        lng: 9.450419425964412,
        lat: 46.48769378662104
    },
    {
        lng: 9.450546264648494,
        lat: 46.48833084106474
    },
    {
        lng: 9.450729370117244,
        lat: 46.48863983154308
    },
    {
        lng: 9.451015472412166,
        lat: 46.488929748535384
    },
    {
        lng: 9.451395034790153,
        lat: 46.48916625976574
    },
    {
        lng: 9.45226860046398,
        lat: 46.489463806152344
    },
    {
        lng: 9.459429740905875,
        lat: 46.49047851562506
    },
    {
        lng: 9.460397720336857,
        lat: 46.49069976806652
    },
    {
        lng: 9.46084976196289,
        lat: 46.49086761474615
    },
    {
        lng: 9.461406707763672,
        lat: 46.491184234619254
    },
    {
        lng: 9.461901664734,
        lat: 46.491653442382926
    },
    {
        lng: 9.462775230407829,
        lat: 46.49303817749046
    },
    {
        lng: 9.463445663452205,
        lat: 46.49390029907221
    },
    {
        lng: 9.463582992553711,
        lat: 46.49407958984398
    },
    {
        lng: 9.46388244628912,
        lat: 46.4951057434082
    },
    {
        lng: 9.463998794555778,
        lat: 46.496231079101676
    },
    {
        lng: 9.464194297790527,
        lat: 46.50252532958996
    },
    {
        lng: 9.464119911193904,
        lat: 46.50406646728544
    },
    {
        lng: 9.463671684265137,
        lat: 46.50625228881847
    },
    {
        lng: 9.463631629943848,
        lat: 46.508491516113224
    },
    {
        lng: 9.46383094787592,
        lat: 46.5103645324707
    },
    {
        lng: 9.46436882019043,
        lat: 46.51262664794933
    },
    {
        lng: 9.464389801025504,
        lat: 46.514347076416186
    },
    {
        lng: 9.461768150329647,
        lat: 46.51419448852562
    },
    {
        lng: 9.461070060730037,
        lat: 46.51405715942394
    },
    {
        lng: 9.46043968200695,
        lat: 46.51382827758812
    },
    {
        lng: 9.459932327270565,
        lat: 46.513523101806754
    },
    {
        lng: 9.45912075042736,
        lat: 46.512832641601676
    },
    {
        lng: 9.458234786987305,
        lat: 46.51221466064476
    },
    {
        lng: 9.45745944976818,
        lat: 46.51153564453119
    },
    {
        lng: 9.456565856933594,
        lat: 46.510929107666016
    },
    {
        lng: 9.455768585205135,
        lat: 46.51026535034208
    },
    {
        lng: 9.453919410705566,
        lat: 46.50907516479498
    },
    {
        lng: 9.45326137542719,
        lat: 46.5088005065918
    },
    {
        lng: 9.451850891113338,
        lat: 46.50835037231457
    },
    {
        lng: 9.450201034545898,
        lat: 46.50754165649437
    },
    {
        lng: 9.448426246643123,
        lat: 46.506980895996094
    },
    {
        lng: 9.446777343750114,
        lat: 46.506168365478516
    },
    {
        lng: 9.445012092590275,
        lat: 46.50559616088867
    },
    {
        lng: 9.443368911743278,
        lat: 46.50478744506836
    },
    {
        lng: 9.441594123840332,
        lat: 46.50423049926775
    },
    {
        lng: 9.439901351928711,
        lat: 46.50343322753929
    },
    {
        lng: 9.437974929809627,
        lat: 46.50286483764643
    },
    {
        lng: 9.437211036682186,
        lat: 46.50254440307617
    },
    {
        lng: 9.436240196228141,
        lat: 46.50191879272461
    },
    {
        lng: 9.43441200256359,
        lat: 46.50018692016596
    },
    {
        lng: 9.433451652526912,
        lat: 46.49875640869135
    },
    {
        lng: 9.432994842529297,
        lat: 46.498348236083984
    },
    {
        lng: 9.429430007934627,
        lat: 46.49623870849621
    },
    {
        lng: 9.427473068237418,
        lat: 46.49523544311518
    },
    {
        lng: 9.426180839538631,
        lat: 46.49424743652344
    },
    {
        lng: 9.425772666931266,
        lat: 46.49388504028332
    },
    {
        lng: 9.425577163696346,
        lat: 46.49361801147484
    },
    {
        lng: 9.425244331359863,
        lat: 46.49272537231474
    },
    {
        lng: 9.4249267578125,
        lat: 46.4904136657716
    },
    {
        lng: 9.42441654205328,
        lat: 46.48864746093744
    },
    {
        lng: 9.424287796020621,
        lat: 46.487945556640625
    },
    {
        lng: 9.423976898193473,
        lat: 46.48225021362316
    },
    {
        lng: 9.423698425293082,
        lat: 46.48124313354498
    },
    {
        lng: 9.423043251037598,
        lat: 46.48011398315441
    },
    {
        lng: 9.42270278930664,
        lat: 46.479701995849666
    },
    {
        lng: 9.421699523925838,
        lat: 46.47882461547846
    },
    {
        lng: 9.41932487487793,
        lat: 46.47621917724638
    },
    {
        lng: 9.418457984924316,
        lat: 46.47560501098633
    },
    {
        lng: 9.417695045471191,
        lat: 46.47496414184582
    },
    {
        lng: 9.41683101654047,
        lat: 46.474403381347656
    },
    {
        lng: 9.416083335876465,
        lat: 46.473762512207145
    },
    {
        lng: 9.415240287780762,
        lat: 46.473152160644645
    },
    {
        lng: 9.414311408996525,
        lat: 46.47219848632824
    },
    {
        lng: 9.41385459899908,
        lat: 46.47182083129883
    },
    {
        lng: 9.412895202636719,
        lat: 46.47121047973633
    },
    {
        lng: 9.412715911865348,
        lat: 46.47113037109375
    },
    {
        lng: 9.412132263183594,
        lat: 46.4708786010745
    },
    {
        lng: 9.41120910644537,
        lat: 46.470638275146484
    },
    {
        lng: 9.410246849060115,
        lat: 46.47050094604492
    },
    {
        lng: 9.408773422241325,
        lat: 46.47040557861328
    },
    {
        lng: 9.40681076049816,
        lat: 46.47041702270519
    },
    {
        lng: 9.405860900878906,
        lat: 46.47050857543968
    },
    {
        lng: 9.403754234314022,
        lat: 46.470893859863395
    },
    {
        lng: 9.401495933532772,
        lat: 46.47150421142578
    },
    {
        lng: 9.399218559265137,
        lat: 46.47191238403332
    },
    {
        lng: 9.397610664367733,
        lat: 46.472499847412394
    },
    {
        lng: 9.39562034606945,
        lat: 46.47293853759771
    },
    {
        lng: 9.394362449645996,
        lat: 46.47342300415045
    },
    {
        lng: 9.39268016815197,
        lat: 46.473770141601506
    },
    {
        lng: 9.392123222351017,
        lat: 46.47401046752941
    },
    {
        lng: 9.390366554260197,
        lat: 46.474765777588004
    },
    {
        lng: 9.389505386352539,
        lat: 46.475299835205305
    },
    {
        lng: 9.389048576355094,
        lat: 46.475749969482706
    },
    {
        lng: 9.3887357711792,
        lat: 46.47626113891624
    },
    {
        lng: 9.388359069824276,
        lat: 46.47713851928711
    },
    {
        lng: 9.388162612915039,
        lat: 46.47759628295921
    },
    {
        lng: 9.387975692749137,
        lat: 46.477882385254134
    },
    {
        lng: 9.387344360351562,
        lat: 46.4788436889649
    },
    {
        lng: 9.386658668518123,
        lat: 46.48034667968773
    },
    {
        lng: 9.385870933532772,
        lat: 46.48119735717796
    },
    {
        lng: 9.385083198547477,
        lat: 46.48255920410156
    },
    {
        lng: 9.384660720825138,
        lat: 46.483009338379134
    },
    {
        lng: 9.383740425109977,
        lat: 46.483531951904354
    },
    {
        lng: 9.382957458496094,
        lat: 46.48374938964844
    },
    {
        lng: 9.380013465881461,
        lat: 46.484016418457145
    },
    {
        lng: 9.379295349121094,
        lat: 46.48418045043945
    },
    {
        lng: 9.377461433410758,
        lat: 46.48506164550798
    },
    {
        lng: 9.376689910888786,
        lat: 46.48559188842796
    },
    {
        lng: 9.375835418701229,
        lat: 46.486072540283146
    },
    {
        lng: 9.37424564361578,
        lat: 46.4871826171875
    },
    {
        lng: 9.371732711792049,
        lat: 46.48965072631847
    },
    {
        lng: 9.369299888610954,
        lat: 46.49129486084013
    },
    {
        lng: 9.368953704833984,
        lat: 46.49164962768572
    },
    {
        lng: 9.368536949157829,
        lat: 46.49223709106445
    },
    {
        lng: 9.368296623229924,
        lat: 46.49265289306635
    },
    {
        lng: 9.368098258972111,
        lat: 46.49322509765619
    },
    {
        lng: 9.367980003357047,
        lat: 46.494426727295036
    },
    {
        lng: 9.368160247802734,
        lat: 46.49562072753929
    },
    {
        lng: 9.368783950805778,
        lat: 46.49730300903349
    },
    {
        lng: 9.36903095245367,
        lat: 46.49777603149414
    },
    {
        lng: 9.369367599487305,
        lat: 46.4981575012207
    },
    {
        lng: 9.370089530944881,
        lat: 46.49879837036144
    },
    {
        lng: 9.371397972107047,
        lat: 46.50035095214844
    },
    {
        lng: 9.371551513671932,
        lat: 46.50058364868164
    },
    {
        lng: 9.372874259948674,
        lat: 46.50260543823248
    },
    {
        lng: 9.371085166931152,
        lat: 46.50326919555664
    },
    {
        lng: 9.369050025939941,
        lat: 46.503852844238395
    },
    {
        lng: 9.3681383132934,
        lat: 46.50433731079113
    },
    {
        lng: 9.366658210754395,
        lat: 46.504840850830305
    },
    {
        lng: 9.36568832397461,
        lat: 46.505283355713004
    },
    {
        lng: 9.364430427551326,
        lat: 46.505981445312784
    },
    {
        lng: 9.363228797912711,
        lat: 46.50674819946312
    },
    {
        lng: 9.362419128418026,
        lat: 46.507362365722656
    },
    {
        lng: 9.36125373840332,
        lat: 46.508598327636776
    },
    {
        lng: 9.359105110168514,
        lat: 46.506816864013786
    },
    {
        lng: 9.35672855377203,
        lat: 46.50528717041033
    },
    {
        lng: 9.355653762817496,
        lat: 46.50471115112316
    },
    {
        lng: 9.35544490814209,
        lat: 46.50462341308605
    },
    {
        lng: 9.354887962341309,
        lat: 46.50439453125023
    },
    {
        lng: 9.35447692871088,
        lat: 46.504272460937614
    },
    {
        lng: 9.353694915771484,
        lat: 46.50415420532238
    },
    {
        lng: 9.352641105651799,
        lat: 46.504203796386946
    },
    {
        lng: 9.350828170776367,
        lat: 46.5045242309572
    },
    {
        lng: 9.350440025329704,
        lat: 46.504570007324446
    },
    {
        lng: 9.349493980407658,
        lat: 46.50468826293951
    },
    {
        lng: 9.347699165344352,
        lat: 46.50478363037115
    },
    {
        lng: 9.344201087951603,
        lat: 46.504562377929915
    },
    {
        lng: 9.343702316284293,
        lat: 46.50450134277344
    },
    {
        lng: 9.343413352966309,
        lat: 46.504463195800895
    },
    {
        lng: 9.341596603393612,
        lat: 46.504100799560774
    },
    {
        lng: 9.340435981750602,
        lat: 46.504096984863224
    },
    {
        lng: 9.339691162109432,
        lat: 46.50421905517584
    },
    {
        lng: 9.339032173156738,
        lat: 46.504451751708984
    },
    {
        lng: 9.33675575256359,
        lat: 46.50600433349604
    },
    {
        lng: 9.336181640624943,
        lat: 46.50630569458008
    },
    {
        lng: 9.335405349731559,
        lat: 46.5065460205081
    },
    {
        lng: 9.334095954895076,
        lat: 46.50677108764671
    },
    {
        lng: 9.331793785095215,
        lat: 46.50692749023466
    },
    {
        lng: 9.329415321350154,
        lat: 46.50682830810547
    },
    {
        lng: 9.327976226806697,
        lat: 46.5068626403808
    },
    {
        lng: 9.321076393127555,
        lat: 46.50745391845709
    },
    {
        lng: 9.31964015960699,
        lat: 46.507503509521484
    },
    {
        lng: 9.317244529724235,
        lat: 46.50744247436518
    },
    {
        lng: 9.311992645263786,
        lat: 46.50777816772472
    },
    {
        lng: 9.311078071594352,
        lat: 46.50775146484375
    },
    {
        lng: 9.309897422790527,
        lat: 46.50753784179682
    },
    {
        lng: 9.308039665222168,
        lat: 46.506984710693416
    },
    {
        lng: 9.306902885437012,
        lat: 46.50645828247099
    },
    {
        lng: 9.305029869079704,
        lat: 46.506072998046875
    },
    {
        lng: 9.303559303283805,
        lat: 46.505527496337834
    },
    {
        lng: 9.301973342895565,
        lat: 46.50523376464855
    },
    {
        lng: 9.300539016723746,
        lat: 46.50462341308605
    },
    {
        lng: 9.298316955566463,
        lat: 46.50398254394554
    },
    {
        lng: 9.297096252441406,
        lat: 46.503822326660384
    },
    {
        lng: 9.294018745422363,
        lat: 46.50380325317394
    },
    {
        lng: 9.292833328247127,
        lat: 46.503669738769645
    },
    {
        lng: 9.290372848510742,
        lat: 46.50354766845703
    },
    {
        lng: 9.288879394531193,
        lat: 46.50358963012724
    },
    {
        lng: 9.287236213684196,
        lat: 46.503822326660384
    },
    {
        lng: 9.285234451294002,
        lat: 46.50425338745117
    },
    {
        lng: 9.284689903259277,
        lat: 46.50318527221691
    },
    {
        lng: 9.28387260437006,
        lat: 46.50212478637695
    },
    {
        lng: 9.282931327819881,
        lat: 46.501422882080305
    },
    {
        lng: 9.281990051269645,
        lat: 46.50050354003906
    },
    {
        lng: 9.280584335327262,
        lat: 46.499340057373274
    },
    {
        lng: 9.279858589172363,
        lat: 46.498531341552734
    },
    {
        lng: 9.279487609863395,
        lat: 46.49767684936552
    },
    {
        lng: 9.278817176818848,
        lat: 46.495494842529524
    },
    {
        lng: 9.278488159179744,
        lat: 46.49399566650385
    },
    {
        lng: 9.277757644653377,
        lat: 46.491912841796875
    },
    {
        lng: 9.277376174926815,
        lat: 46.491203308105696
    },
    {
        lng: 9.276743888854924,
        lat: 46.490547180175895
    },
    {
        lng: 9.27658939361578,
        lat: 46.49030303955084
    },
    {
        lng: 9.276331901550407,
        lat: 46.48942947387701
    },
    {
        lng: 9.27629470825201,
        lat: 46.488121032715014
    },
    {
        lng: 9.276454925537166,
        lat: 46.48711395263683
    },
    {
        lng: 9.276691436767635,
        lat: 46.48646163940424
    },
    {
        lng: 9.277523994445914,
        lat: 46.485057830810604
    },
    {
        lng: 9.277839660644531,
        lat: 46.48406600952171
    },
    {
        lng: 9.277956962585506,
        lat: 46.4830436706543
    },
    {
        lng: 9.277924537658748,
        lat: 46.481681823730696
    },
    {
        lng: 9.277821540832576,
        lat: 46.48101425170921
    },
    {
        lng: 9.277400016784725,
        lat: 46.47967910766624
    },
    {
        lng: 9.277031898498649,
        lat: 46.47818374633795
    },
    {
        lng: 9.276206016540641,
        lat: 46.4757156372072
    },
    {
        lng: 9.27596378326416,
        lat: 46.47514724731457
    },
    {
        lng: 9.27513599395752,
        lat: 46.47381591796892
    },
    {
        lng: 9.274909019470329,
        lat: 46.47317886352562
    },
    {
        lng: 9.274758338928336,
        lat: 46.472171783447266
    },
    {
        lng: 9.274759292602653,
        lat: 46.47114562988298
    },
    {
        lng: 9.274925231933707,
        lat: 46.47013092041027
    },
    {
        lng: 9.275362014770621,
        lat: 46.469196319580305
    },
    {
        lng: 9.276715278625545,
        lat: 46.46755599975597
    },
    {
        lng: 9.277687072753906,
        lat: 46.46672439575218
    },
    {
        lng: 9.278040885925407,
        lat: 46.46614837646479
    },
    {
        lng: 9.278189659118652,
        lat: 46.46590805053711
    },
    {
        lng: 9.278326034545842,
        lat: 46.46549606323242
    },
    {
        lng: 9.27833461761486,
        lat: 46.465072631836165
    },
    {
        lng: 9.278137207031364,
        lat: 46.46446228027361
    },
    {
        lng: 9.2779159545899,
        lat: 46.46406555175781
    },
    {
        lng: 9.277615547180176,
        lat: 46.46377182006836
    },
    {
        lng: 9.276909828186035,
        lat: 46.463085174560774
    },
    {
        lng: 9.27637863159174,
        lat: 46.46218490600597
    },
    {
        lng: 9.276043891906738,
        lat: 46.46176528930687
    },
    {
        lng: 9.273798942565975,
        lat: 46.460021972656364
    },
    {
        lng: 9.272821426391658,
        lat: 46.45846939086914
    },
    {
        lng: 9.272065162658805,
        lat: 46.45784378051752
    },
    {
        lng: 9.271344184875545,
        lat: 46.45711898803728
    },
    {
        lng: 9.270500183105526,
        lat: 46.456539154052734
    },
    {
        lng: 9.269431114196777,
        lat: 46.45613479614252
    },
    {
        lng: 9.267257690429744,
        lat: 46.45565032958979
    },
    {
        lng: 9.265971183776912,
        lat: 46.455108642578125
    },
    {
        lng: 9.264279365539608,
        lat: 46.45469665527338
    },
    {
        lng: 9.262759208679313,
        lat: 46.45399856567411
    },
    {
        lng: 9.261226654052791,
        lat: 46.45354461669933
    },
    {
        lng: 9.258454322814998,
        lat: 46.45296859741234
    },
    {
        lng: 9.255428314208984,
        lat: 46.4525260925293
    },
    {
        lng: 9.254411697387695,
        lat: 46.45221328735374
    },
    {
        lng: 9.252815246581974,
        lat: 46.45161819458008
    },
    {
        lng: 9.252134323120174,
        lat: 46.45126724243187
    },
    {
        lng: 9.250945091247559,
        lat: 46.450523376464844
    },
    {
        lng: 9.25032711029047,
        lat: 46.45005416870117
    },
    {
        lng: 9.250065803527946,
        lat: 46.44975662231451
    },
    {
        lng: 9.249717712402457,
        lat: 46.4490394592288
    },
    {
        lng: 9.24952602386486,
        lat: 46.44785690307623
    },
    {
        lng: 9.249583244323787,
        lat: 46.43938446044933
    },
    {
        lng: 9.249659538268986,
        lat: 46.43770599365246
    },
    {
        lng: 9.249883651733512,
        lat: 46.43649673461914
    },
    {
        lng: 9.25036716461176,
        lat: 46.43513488769554
    },
    {
        lng: 9.250628471374512,
        lat: 46.43451309204107
    },
    {
        lng: 9.250802993774414,
        lat: 46.43425369262695
    },
    {
        lng: 9.251003265380803,
        lat: 46.43395614624035
    },
    {
        lng: 9.25247859954834,
        lat: 46.432662963867415
    },
    {
        lng: 9.253295898437557,
        lat: 46.43205642700207
    },
    {
        lng: 9.255722045898438,
        lat: 46.429599761963004
    },
    {
        lng: 9.256086349487305,
        lat: 46.42939758300781
    },
    {
        lng: 9.256273269653434,
        lat: 46.42929077148449
    },
    {
        lng: 9.258247375488338,
        lat: 46.42867660522461
    },
    {
        lng: 9.259105682373104,
        lat: 46.42813110351574
    },
    {
        lng: 9.260045051574707,
        lat: 46.427639007568416
    },
    {
        lng: 9.260884284973201,
        lat: 46.4270401000976
    },
    {
        lng: 9.262495040893498,
        lat: 46.426212310791016
    },
    {
        lng: 9.263079643249569,
        lat: 46.42577743530302
    },
    {
        lng: 9.263186454772892,
        lat: 46.42569732666027
    },
    {
        lng: 9.264117240905875,
        lat: 46.424762725830305
    },
    {
        lng: 9.264966011047306,
        lat: 46.424121856689624
    },
    {
        lng: 9.265972137451229,
        lat: 46.423141479492415
    },
    {
        lng: 9.266895294189453,
        lat: 46.422538757324446
    },
    {
        lng: 9.267580986022892,
        lat: 46.42223358154308
    },
    {
        lng: 9.267658233642578,
        lat: 46.42220687866211
    },
    {
        lng: 9.270585060119572,
        lat: 46.42121124267601
    },
    {
        lng: 9.275401115417424,
        lat: 46.420433044433594
    },
    {
        lng: 9.276191711425838,
        lat: 46.42030334472662
    },
    {
        lng: 9.276375770568848,
        lat: 46.420272827148665
    },
    {
        lng: 9.277164459228516,
        lat: 46.42007064819347
    },
    {
        lng: 9.277807235717773,
        lat: 46.41976547241211
    },
    {
        lng: 9.278252601623535,
        lat: 46.4193878173831
    },
    {
        lng: 9.278590202331657,
        lat: 46.418834686279524
    },
    {
        lng: 9.278962135315055,
        lat: 46.417404174804915
    },
    {
        lng: 9.27917575836176,
        lat: 46.416877746582145
    },
    {
        lng: 9.279512405395565,
        lat: 46.41646957397461
    },
    {
        lng: 9.280789375305119,
        lat: 46.415359497070426
    },
    {
        lng: 9.28161716461193,
        lat: 46.4147567749024
    },
    {
        lng: 9.28196811676031,
        lat: 46.41437530517584
    },
    {
        lng: 9.282690048217887,
        lat: 46.41329574584984
    },
    {
        lng: 9.283480644226074,
        lat: 46.41158294677746
    },
    {
        lng: 9.28366470336914,
        lat: 46.410923004150334
    },
    {
        lng: 9.28380012512207,
        lat: 46.40990447998047
    },
    {
        lng: 9.283902168273926,
        lat: 46.40684127807623
    },
    {
        lng: 9.283749580383358,
        lat: 46.406356811523665
    },
    {
        lng: 9.282684326171932,
        lat: 46.404827117919865
    },
    {
        lng: 9.282511711120605,
        lat: 46.40401077270519
    },
    {
        lng: 9.2826766967774,
        lat: 46.40323638916033
    },
    {
        lng: 9.283507347106877,
        lat: 46.40215682983393
    },
    {
        lng: 9.2836332321167,
        lat: 46.401679992675724
    },
    {
        lng: 9.283602714538517,
        lat: 46.40118789672863
    },
    {
        lng: 9.283520698547363,
        lat: 46.40095138549816
    },
    {
        lng: 9.283228874206486,
        lat: 46.40057373046892
    },
    {
        lng: 9.28263378143322,
        lat: 46.400081634521484
    },
    {
        lng: 9.282011985778809,
        lat: 46.39957046508812
    },
    {
        lng: 9.281232833862305,
        lat: 46.399017333984375
    },
    {
        lng: 9.280178070068473,
        lat: 46.3975181579591
    },
    {
        lng: 9.279391288757438,
        lat: 46.39696502685547
    },
    {
        lng: 9.277971267700252,
        lat: 46.39580154418957
    },
    {
        lng: 9.277826309204215,
        lat: 46.395580291748274
    },
    {
        lng: 9.277689933776799,
        lat: 46.39509582519554
    },
    {
        lng: 9.277811050415153,
        lat: 46.39434814453131
    },
    {
        lng: 9.278084754943848,
        lat: 46.39393997192394
    },
    {
        lng: 9.278736114501953,
        lat: 46.39324188232433
    },
    {
        lng: 9.279368400573844,
        lat: 46.39193725585949
    },
    {
        lng: 9.279707908630371,
        lat: 46.39139938354498
    },
    {
        lng: 9.281072616577148,
        lat: 46.38978576660162
    },
    {
        lng: 9.281689643859977,
        lat: 46.389301300048885
    },
    {
        lng: 9.282130241394043,
        lat: 46.388954162597656
    },
    {
        lng: 9.283044815063477,
        lat: 46.3880310058596
    },
    {
        lng: 9.28318309783947,
        lat: 46.387969970703125
    },
    {
        lng: 9.284863471984863,
        lat: 46.386329650879134
    },
    {
        lng: 9.285248756408805,
        lat: 46.38573074340832
    },
    {
        lng: 9.285370826721248,
        lat: 46.38521575927746
    },
    {
        lng: 9.285227775573787,
        lat: 46.38444137573242
    },
    {
        lng: 9.284539222717285,
        lat: 46.38315200805687
    },
    {
        lng: 9.283893585205135,
        lat: 46.3814506530764
    },
    {
        lng: 9.283060073852539,
        lat: 46.380157470703296
    },
    {
        lng: 9.282375335693416,
        lat: 46.37860107421869
    },
    {
        lng: 9.281416893005314,
        lat: 46.37751388549833
    },
    {
        lng: 9.280721664428768,
        lat: 46.37596130371111
    },
    {
        lng: 9.280487060546818,
        lat: 46.375591278076286
    },
    {
        lng: 9.280059814453239,
        lat: 46.3749237060548
    },
    {
        lng: 9.279788017272892,
        lat: 46.374324798583984
    },
    {
        lng: 9.27956581115717,
        lat: 46.372970581054915
    },
    {
        lng: 9.279571533203125,
        lat: 46.37085342407238
    },
    {
        lng: 9.279714584350586,
        lat: 46.36837387084972
    },
    {
        lng: 9.279795646667424,
        lat: 46.36770629882824
    },
    {
        lng: 9.280057907104435,
        lat: 46.36553955078148
    },
    {
        lng: 9.280739784240723,
        lat: 46.36491394042969
    },
    {
        lng: 9.281655311584416,
        lat: 46.36386871337902
    },
    {
        lng: 9.282359123230037,
        lat: 46.363201141357536
    },
    {
        lng: 9.28269290924078,
        lat: 46.362655639648665
    },
    {
        lng: 9.283221244811955,
        lat: 46.36142349243187
    },
    {
        lng: 9.28351020812994,
        lat: 46.36096954345709
    },
    {
        lng: 9.28464126586914,
        lat: 46.3591880798341
    },
    {
        lng: 9.285902976989803,
        lat: 46.35773468017584
    },
    {
        lng: 9.286455154418945,
        lat: 46.357280731201286
    },
    {
        lng: 9.287165641784725,
        lat: 46.35697174072271
    },
    {
        lng: 9.288611412048454,
        lat: 46.35652160644531
    },
    {
        lng: 9.28988075256342,
        lat: 46.35594940185547
    },
    {
        lng: 9.291402816772518,
        lat: 46.35555648803722
    },
    {
        lng: 9.293033599853516,
        lat: 46.35487365722662
    },
    {
        lng: 9.294917106628418,
        lat: 46.35436630249046
    },
    {
        lng: 9.295833587646428,
        lat: 46.35392379760742
    },
    {
        lng: 9.297567367553825,
        lat: 46.353317260742415
    },
    {
        lng: 9.298130035400334,
        lat: 46.352874755859375
    },
    {
        lng: 9.298308372497615,
        lat: 46.352596282959155
    },
    {
        lng: 9.298526763916016,
        lat: 46.35198593139643
    },
    {
        lng: 9.298674583435115,
        lat: 46.351264953613395
    },
    {
        lng: 9.298866271972713,
        lat: 46.35032653808605
    },
    {
        lng: 9.299912452697868,
        lat: 46.34647750854492
    },
    {
        lng: 9.300271987915096,
        lat: 46.344352722168196
    },
    {
        lng: 9.30072116851801,
        lat: 46.34260559082031
    },
    {
        lng: 9.30076789855957,
        lat: 46.3419075012207
    },
    {
        lng: 9.300685882568416,
        lat: 46.34122085571312
    },
    {
        lng: 9.300374984741268,
        lat: 46.34044647216791
    },
    {
        lng: 9.299889564514217,
        lat: 46.33968734741234
    },
    {
        lng: 9.29938983917242,
        lat: 46.33923339843756
    },
    {
        lng: 9.298098564147892,
        lat: 46.33829116821306
    },
    {
        lng: 9.297241210937614,
        lat: 46.337799072265625
    },
    {
        lng: 9.295674324035588,
        lat: 46.33664321899437
    },
    {
        lng: 9.295477867126579,
        lat: 46.3364143371582
    },
    {
        lng: 9.295239448547477,
        lat: 46.33612823486328
    },
    {
        lng: 9.294816017150822,
        lat: 46.33541870117193
    },
    {
        lng: 9.294590950012207,
        lat: 46.33485412597662
    },
    {
        lng: 9.294460296630973,
        lat: 46.33415985107416
    },
    {
        lng: 9.29444599151617,
        lat: 46.33308410644554
    },
    {
        lng: 9.29454517364502,
        lat: 46.33237075805664
    },
    {
        lng: 9.294750213623047,
        lat: 46.331768035888615
    },
    {
        lng: 9.295229911804142,
        lat: 46.330360412597656
    },
    {
        lng: 9.295514106750488,
        lat: 46.32976913452171
    },
    {
        lng: 9.295674324035588,
        lat: 46.32955169677763
    },
    {
        lng: 9.296666145324707,
        lat: 46.32860565185558
    },
    {
        lng: 9.298842430114803,
        lat: 46.326206207275675
    },
    {
        lng: 9.299544334411621,
        lat: 46.32554244995117
    },
    {
        lng: 9.299718856811523,
        lat: 46.32526779174816
    },
    {
        lng: 9.299917221069336,
        lat: 46.32464981079107
    },
    {
        lng: 9.299924850463867,
        lat: 46.32364273071312
    },
    {
        lng: 9.299748420715389,
        lat: 46.322967529296875
    },
    {
        lng: 9.299408912658691,
        lat: 46.322353363037166
    },
    {
        lng: 9.298296928405705,
        lat: 46.321014404296875
    },
    {
        lng: 9.297830581665153,
        lat: 46.32006072998047
    },
    {
        lng: 9.297622680664176,
        lat: 46.3189964294433
    },
    {
        lng: 9.29739761352539,
        lat: 46.31684875488281
    },
    {
        lng: 9.297103881835938,
        lat: 46.3158073425293
    },
    {
        lng: 9.296922683715763,
        lat: 46.31547546386719
    },
    {
        lng: 9.296496391296444,
        lat: 46.31495285034208
    },
    {
        lng: 9.295676231384391,
        lat: 46.31428146362305
    },
    {
        lng: 9.294920921325797,
        lat: 46.31383895874018
    },
    {
        lng: 9.292937278747615,
        lat: 46.312847137451286
    },
    {
        lng: 9.292176246643066,
        lat: 46.31259155273449
    },
    {
        lng: 9.290868759155387,
        lat: 46.31230545043957
    },
    {
        lng: 9.290260314941406,
        lat: 46.31225585937517
    },
    {
        lng: 9.28891658782959,
        lat: 46.31227493286144
    },
    {
        lng: 9.286484718322754,
        lat: 46.310218811035384
    },
    {
        lng: 9.285504341125602,
        lat: 46.30919647216797
    },
    {
        lng: 9.284996986389217,
        lat: 46.30820465087902
    },
    {
        lng: 9.28431510925293,
        lat: 46.30649185180687
    },
    {
        lng: 9.284106254577694,
        lat: 46.30579757690458
    },
    {
        lng: 9.283846855163574,
        lat: 46.30400848388683
    },
    {
        lng: 9.283774375915584,
        lat: 46.30220794677757
    },
    {
        lng: 9.283820152282772,
        lat: 46.30150604248075
    },
    {
        lng: 9.283906936645621,
        lat: 46.300994873046875
    },
    {
        lng: 9.284117698669434,
        lat: 46.29975891113287
    },
    {
        lng: 9.284164428710938,
        lat: 46.297576904297046
    },
    {
        lng: 9.284177780151424,
        lat: 46.2969207763673
    },
    {
        lng: 9.284403800964355,
        lat: 46.295219421387
    },
    {
        lng: 9.284244537353516,
        lat: 46.29422760009771
    },
    {
        lng: 9.283905029296818,
        lat: 46.293613433838004
    },
    {
        lng: 9.283309936523551,
        lat: 46.293083190918026
    },
    {
        lng: 9.278915405273551,
        lat: 46.290424346924
    },
    {
        lng: 9.27811145782465,
        lat: 46.28984832763683
    },
    {
        lng: 9.277224540710506,
        lat: 46.28933715820307
    },
    {
        lng: 9.276424407958984,
        lat: 46.28875732421869
    },
    {
        lng: 9.275533676147461,
        lat: 46.288246154785156
    },
    {
        lng: 9.274726867675895,
        lat: 46.28767776489286
    },
    {
        lng: 9.274116516113338,
        lat: 46.28731155395519
    },
    {
        lng: 9.272015571594181,
        lat: 46.28604888916027
    },
    {
        lng: 9.271369934082031,
        lat: 46.285541534423885
    },
    {
        lng: 9.27090835571289,
        lat: 46.28496932983404
    },
    {
        lng: 9.270305633544922,
        lat: 46.28400421142578
    },
    {
        lng: 9.270258903503361,
        lat: 46.28389739990257
    },
    {
        lng: 9.269449234008903,
        lat: 46.28200912475586
    },
    {
        lng: 9.268573760986328,
        lat: 46.28066635131836
    },
    {
        lng: 9.267758369445858,
        lat: 46.27905654907232
    },
    {
        lng: 9.267054557800293,
        lat: 46.278274536132756
    },
    {
        lng: 9.266181945800724,
        lat: 46.2776908874514
    },
    {
        lng: 9.26419925689703,
        lat: 46.276676177978516
    },
    {
        lng: 9.262372016906795,
        lat: 46.276107788085994
    },
    {
        lng: 9.26172924041748,
        lat: 46.27579879760765
    },
    {
        lng: 9.260301589965763,
        lat: 46.274936676025334
    },
    {
        lng: 9.258758544921875,
        lat: 46.273719787597656
    },
    {
        lng: 9.258137702941895,
        lat: 46.27289962768555
    },
    {
        lng: 9.257636070251465,
        lat: 46.27191162109381
    },
    {
        lng: 9.2574462890625,
        lat: 46.27122879028326
    },
    {
        lng: 9.256962776184082,
        lat: 46.26879119873047
    },
    {
        lng: 9.256709098815861,
        lat: 46.268123626708984
    },
    {
        lng: 9.256329536438102,
        lat: 46.26747512817394
    },
    {
        lng: 9.255704879760742,
        lat: 46.26666259765625
    },
    {
        lng: 9.2535018920899,
        lat: 46.264812469482536
    },
    {
        lng: 9.252616882324332,
        lat: 46.2635383605957
    },
    {
        lng: 9.252328872680664,
        lat: 46.26324462890642
    },
    {
        lng: 9.251669883728027,
        lat: 46.262569427490405
    },
    {
        lng: 9.25114727020275,
        lat: 46.261852264404524
    },
    {
        lng: 9.251070022583065,
        lat: 46.26047515869152
    },
    {
        lng: 9.250843048095703,
        lat: 46.25863265991234
    },
    {
        lng: 9.25060939788824,
        lat: 46.257347106933594
    },
    {
        lng: 9.250352859497184,
        lat: 46.256473541259936
    },
    {
        lng: 9.249935150146484,
        lat: 46.25571060180681
    },
    {
        lng: 9.24988079071045,
        lat: 46.255649566650504
    },
    {
        lng: 9.24913120269781,
        lat: 46.25481414794916
    },
    {
        lng: 9.248270034790153,
        lat: 46.25328445434593
    },
    {
        lng: 9.24753379821783,
        lat: 46.25224685668945
    },
    {
        lng: 9.247214317321891,
        lat: 46.25167465209961
    },
    {
        lng: 9.246990203857536,
        lat: 46.251064300537394
    },
    {
        lng: 9.246802330017147,
        lat: 46.25014877319336
    },
    {
        lng: 9.246781349182129,
        lat: 46.249267578125114
    },
    {
        lng: 9.246886253356877,
        lat: 46.248756408691406
    },
    {
        lng: 9.247194290161133,
        lat: 46.24782943725597
    },
    {
        lng: 9.247240066528377,
        lat: 46.2474632263183
    },
    {
        lng: 9.247263908386287,
        lat: 46.24726867675804
    },
    {
        lng: 9.247201919555664,
        lat: 46.24638748168945
    },
    {
        lng: 9.247054100036735,
        lat: 46.24580764770508
    },
    {
        lng: 9.246793746948299,
        lat: 46.24526977539057
    },
    {
        lng: 9.24660491943365,
        lat: 46.24502944946306
    },
    {
        lng: 9.246400833129883,
        lat: 46.2448616027832
    },
    {
        lng: 9.245829582214299,
        lat: 46.24439239501976
    },
    {
        lng: 9.245030403137264,
        lat: 46.24359130859392
    },
    {
        lng: 9.244343757629508,
        lat: 46.242927551269645
    },
    {
        lng: 9.245109558105526,
        lat: 46.24092102050798
    },
    {
        lng: 9.245637893676758,
        lat: 46.23933029174805
    },
    {
        lng: 9.245979309082031,
        lat: 46.236976623535384
    },
    {
        lng: 9.246221542358455,
        lat: 46.233497619628906
    },
    {
        lng: 9.246305465698242,
        lat: 46.23229598999046
    },
    {
        lng: 9.246186256408691,
        lat: 46.230796813964844
    },
    {
        lng: 9.245678901672477,
        lat: 46.228637695312614
    },
    {
        lng: 9.245392799377555,
        lat: 46.22794723510748
    },
    {
        lng: 9.24464225769043,
        lat: 46.227100372314624
    },
    {
        lng: 9.243990898132324,
        lat: 46.22663497924816
    },
    {
        lng: 9.243248939514217,
        lat: 46.22626876831083
    },
    {
        lng: 9.242842674255485,
        lat: 46.22613525390631
    },
    {
        lng: 9.242081642150936,
        lat: 46.22602081298828
    },
    {
        lng: 9.240491867065487,
        lat: 46.22607803344755
    },
    {
        lng: 9.238472938537654,
        lat: 46.226238250732536
    },
    {
        lng: 9.23494815826416,
        lat: 46.22670745849615
    },
    {
        lng: 9.233455657958928,
        lat: 46.22668075561518
    },
    {
        lng: 9.232320785522575,
        lat: 46.226478576660384
    },
    {
        lng: 9.231118202209473,
        lat: 46.22616958618164
    },
    {
        lng: 9.230337142944336,
        lat: 46.22589111328148
    },
    {
        lng: 9.228344917297306,
        lat: 46.224967956543196
    },
    {
        lng: 9.227196693420467,
        lat: 46.224250793457145
    },
    {
        lng: 9.225587844848576,
        lat: 46.2236022949221
    },
    {
        lng: 9.225354194641113,
        lat: 46.22346115112305
    },
    {
        lng: 9.224229812622184,
        lat: 46.22278213500982
    },
    {
        lng: 9.22275257110607,
        lat: 46.2220191955567
    },
    {
        lng: 9.220624923706112,
        lat: 46.22079086303711
    },
    {
        lng: 9.220791816711483,
        lat: 46.218757629394474
    },
    {
        lng: 9.220814704895076,
        lat: 46.21820068359375
    },
    {
        lng: 9.220723152160645,
        lat: 46.21752548217768
    },
    {
        lng: 9.220099449157772,
        lat: 46.215545654296875
    },
    {
        lng: 9.219799041748047,
        lat: 46.21498107910173
    },
    {
        lng: 9.219049453735408,
        lat: 46.214000701904524
    },
    {
        lng: 9.218799591064453,
        lat: 46.2133903503418
    },
    {
        lng: 9.21840667724615,
        lat: 46.21207046508812
    },
    {
        lng: 9.218181610107536,
        lat: 46.211315155029524
    },
    {
        lng: 9.217935562133903,
        lat: 46.20873641967785
    },
    {
        lng: 9.217802047729549,
        lat: 46.20800781250006
    },
    {
        lng: 9.217524528503475,
        lat: 46.20730590820324
    },
    {
        lng: 9.217329025268555,
        lat: 46.207008361816634
    },
    {
        lng: 9.216838836670036,
        lat: 46.206474304199276
    },
    {
        lng: 9.216266632080192,
        lat: 46.206005096435604
    },
    {
        lng: 9.214683532714787,
        lat: 46.20508575439453
    },
    {
        lng: 9.213900566101131,
        lat: 46.20449447631847
    },
    {
        lng: 9.211947441101074,
        lat: 46.20320892334007
    },
    {
        lng: 9.210478782653809,
        lat: 46.2025527954101
    },
    {
        lng: 9.20959854125988,
        lat: 46.20221710205078
    },
    {
        lng: 9.208816528320312,
        lat: 46.20204544067411
    },
    {
        lng: 9.206917762756348,
        lat: 46.20177459716808
    },
    {
        lng: 9.205937385559139,
        lat: 46.20148468017601
    },
    {
        lng: 9.20508003234869,
        lat: 46.20095825195318
    },
    {
        lng: 9.2036972045899,
        lat: 46.20023345947294
    },
    {
        lng: 9.202557563781852,
        lat: 46.199462890625284
    },
    {
        lng: 9.201234817504883,
        lat: 46.19709777832054
    },
    {
        lng: 9.200341224670524,
        lat: 46.19501495361351
    },
    {
        lng: 9.199505805969352,
        lat: 46.19361877441423
    },
    {
        lng: 9.199218749999943,
        lat: 46.192920684814396
    },
    {
        lng: 9.198568344116325,
        lat: 46.19078445434599
    },
    {
        lng: 9.197424888610897,
        lat: 46.188701629638786
    },
    {
        lng: 9.197224617004508,
        lat: 46.18817138671881
    },
    {
        lng: 9.19689655303955,
        lat: 46.18730545043951
    },
    {
        lng: 9.195688247680664,
        lat: 46.18449401855469
    },
    {
        lng: 9.195526123046932,
        lat: 46.183780670166016
    },
    {
        lng: 9.195519447326717,
        lat: 46.18270492553734
    },
    {
        lng: 9.195648193359375,
        lat: 46.18211364746122
    },
    {
        lng: 9.196497917175293,
        lat: 46.17958068847662
    },
    {
        lng: 9.196719169616756,
        lat: 46.178192138671875
    },
    {
        lng: 9.196776390075684,
        lat: 46.17716598510765
    },
    {
        lng: 9.196350097656364,
        lat: 46.17582321167015
    },
    {
        lng: 9.195289611816406,
        lat: 46.174156188964844
    },
    {
        lng: 9.194456100463867,
        lat: 46.17242431640625
    },
    {
        lng: 9.193828582763672,
        lat: 46.16954040527355
    },
    {
        lng: 9.193484306335506,
        lat: 46.16873931884777
    },
    {
        lng: 9.192998886108455,
        lat: 46.1679382324221
    },
    {
        lng: 9.192511558532829,
        lat: 46.16745376586937
    },
    {
        lng: 9.191251754760799,
        lat: 46.16643142700195
    },
    {
        lng: 9.190410614013672,
        lat: 46.165889739990234
    },
    {
        lng: 9.18965816497797,
        lat: 46.165287017822266
    },
    {
        lng: 9.188950538635197,
        lat: 46.1649284362793
    },
    {
        lng: 9.187173843383846,
        lat: 46.1640357971192
    },
    {
        lng: 9.186397552490234,
        lat: 46.1636962890625
    },
    {
        lng: 9.185535430908317,
        lat: 46.16345214843744
    },
    {
        lng: 9.184770584106388,
        lat: 46.16310501098644
    },
    {
        lng: 9.18392562866211,
        lat: 46.162841796875284
    },
    {
        lng: 9.18317985534668,
        lat: 46.162475585937614
    },
    {
        lng: 9.18155479431158,
        lat: 46.16184616088867
    },
    {
        lng: 9.181058883666935,
        lat: 46.16175079345703
    },
    {
        lng: 9.180332183837947,
        lat: 46.161777496338004
    },
    {
        lng: 9.179409980773983,
        lat: 46.1620140075683
    },
    {
        lng: 9.17704868316656,
        lat: 46.1630401611331
    },
    {
        lng: 9.175630569457951,
        lat: 46.16378784179699
    },
    {
        lng: 9.174736022949276,
        lat: 46.16440200805687
    },
    {
        lng: 9.173496246337947,
        lat: 46.16533660888683
    },
    {
        lng: 9.170142173767147,
        lat: 46.166709899902344
    },
    {
        lng: 9.168734550476188,
        lat: 46.16728973388672
    },
    {
        lng: 9.16488170623785,
        lat: 46.16919326782232
    },
    {
        lng: 9.163489341735954,
        lat: 46.167877197265796
    },
    {
        lng: 9.162848472595272,
        lat: 46.16749954223644
    },
    {
        lng: 9.161845207214412,
        lat: 46.16735839843773
    },
    {
        lng: 9.158367156982422,
        lat: 46.16719436645508
    },
    {
        lng: 9.15829944610607,
        lat: 46.16399002075195
    },
    {
        lng: 9.158197402954158,
        lat: 46.16334152221691
    },
    {
        lng: 9.157979011535645,
        lat: 46.162746429443644
    },
    {
        lng: 9.15764236450201,
        lat: 46.16225814819336
    },
    {
        lng: 9.156572341918945,
        lat: 46.16108322143555
    },
    {
        lng: 9.15471076965332,
        lat: 46.1599578857423
    },
    {
        lng: 9.154204368591309,
        lat: 46.15976333618181
    },
    {
        lng: 9.153532981872615,
        lat: 46.15964889526373
    },
    {
        lng: 9.151020050048942,
        lat: 46.159702301025675
    },
    {
        lng: 9.148531913757438,
        lat: 46.1595573425293
    },
    {
        lng: 9.145832061767635,
        lat: 46.15887832641624
    },
    {
        lng: 9.143912315368652,
        lat: 46.15777587890625
    },
    {
        lng: 9.143198966980094,
        lat: 46.157199859619254
    },
    {
        lng: 9.14237976074213,
        lat: 46.15671157836931
    },
    {
        lng: 9.14166641235363,
        lat: 46.15613555908209
    },
    {
        lng: 9.140214920043945,
        lat: 46.15527725219732
    },
    {
        lng: 9.139701843261832,
        lat: 46.15505218505854
    },
    {
        lng: 9.137790679931697,
        lat: 46.154586791992415
    },
    {
        lng: 9.135307312011832,
        lat: 46.15322113037132
    },
    {
        lng: 9.134796142578125,
        lat: 46.152805328369254
    },
    {
        lng: 9.133895874023494,
        lat: 46.151809692382756
    },
    {
        lng: 9.133111953735352,
        lat: 46.15120697021513
    },
    {
        lng: 9.131747245788688,
        lat: 46.14990615844749
    },
    {
        lng: 9.130852699279842,
        lat: 46.14825057983427
    },
    {
        lng: 9.128789901733342,
        lat: 46.14636993408226
    },
    {
        lng: 9.127883911132756,
        lat: 46.144714355468864
    },
    {
        lng: 9.127222061157227,
        lat: 46.14403533935564
    },
    {
        lng: 9.126938819885368,
        lat: 46.14362335205078
    },
    {
        lng: 9.126241683960018,
        lat: 46.142227172851676
    },
    {
        lng: 9.125679016113338,
        lat: 46.14071273803711
    },
    {
        lng: 9.125144004821891,
        lat: 46.13899612426758
    },
    {
        lng: 9.124267578124943,
        lat: 46.13784790039074
    },
    {
        lng: 9.124143600463924,
        lat: 46.13755798339844
    },
    {
        lng: 9.123737335205192,
        lat: 46.136619567871094
    },
    {
        lng: 9.12286186218273,
        lat: 46.13550949096674
    },
    {
        lng: 9.122753143310604,
        lat: 46.135280609131144
    },
    {
        lng: 9.122277259826717,
        lat: 46.13428115844732
    },
    {
        lng: 9.122088432312012,
        lat: 46.1340446472168
    },
    {
        lng: 9.121657371521053,
        lat: 46.13371276855469
    },
    {
        lng: 9.12102222442627,
        lat: 46.13346099853527
    },
    {
        lng: 9.12062740325922,
        lat: 46.13338470459007
    },
    {
        lng: 9.119791984558105,
        lat: 46.133308410644474
    },
    {
        lng: 9.118215560913143,
        lat: 46.13325881958025
    },
    {
        lng: 9.117613792419434,
        lat: 46.133243560791016
    },
    {
        lng: 9.116523742675838,
        lat: 46.1331024169923
    },
    {
        lng: 9.11480617523199,
        lat: 46.13279724121094
    },
    {
        lng: 9.1143798828125,
        lat: 46.132678985595874
    },
    {
        lng: 9.112934112548828,
        lat: 46.13228225708008
    },
    {
        lng: 9.110522270202694,
        lat: 46.13193511962902
    },
    {
        lng: 9.109760284423885,
        lat: 46.13175201416044
    },
    {
        lng: 9.107530593872013,
        lat: 46.13097381591808
    },
    {
        lng: 9.106381416320858,
        lat: 46.1303825378418
    },
    {
        lng: 9.104222297668457,
        lat: 46.12978363037132
    },
    {
        lng: 9.10278701782238,
        lat: 46.128913879394645
    },
    {
        lng: 9.101223945617733,
        lat: 46.127571105957145
    },
    {
        lng: 9.100315093994254,
        lat: 46.12591934204113
    },
    {
        lng: 9.10016918182373,
        lat: 46.125789642333984
    },
    {
        lng: 9.099917411804142,
        lat: 46.12557220459007
    },
    {
        lng: 9.099386215210075,
        lat: 46.12530136108421
    },
    {
        lng: 9.098855972290096,
        lat: 46.125156402588004
    },
    {
        lng: 9.098151206970215,
        lat: 46.124961853027514
    },
    {
        lng: 9.097082138061637,
        lat: 46.12478256225597
    },
    {
        lng: 9.094408988952637,
        lat: 46.1246795654298
    },
    {
        lng: 9.09310531616211,
        lat: 46.12454223632807
    },
    {
        lng: 9.091235160827694,
        lat: 46.12405014038097
    },
    {
        lng: 9.08939266204834,
        lat: 46.12369155883789
    },
    {
        lng: 9.088541984558105,
        lat: 46.123348236083984
    },
    {
        lng: 9.086095809936523,
        lat: 46.121746063232706
    },
    {
        lng: 9.084242820739746,
        lat: 46.1209716796875
    },
    {
        lng: 9.083489418029785,
        lat: 46.12071228027355
    },
    {
        lng: 9.082698822021541,
        lat: 46.12053680419916
    },
    {
        lng: 9.080087661743164,
        lat: 46.120201110839844
    },
    {
        lng: 9.07602977752697,
        lat: 46.118148803711165
    },
    {
        lng: 9.07348823547369,
        lat: 46.11684799194353
    },
    {
        lng: 9.074486732483024,
        lat: 46.11407470703125
    },
    {
        lng: 9.074795722961369,
        lat: 46.113491058349666
    },
    {
        lng: 9.076194763183537,
        lat: 46.11193084716808
    },
    {
        lng: 9.077364921569938,
        lat: 46.11092758178711
    },
    {
        lng: 9.07801342010498,
        lat: 46.109989166259936
    },
    {
        lng: 9.079032897949332,
        lat: 46.10893249511736
    },
    {
        lng: 9.080776214599666,
        lat: 46.105041503906364
    },
    {
        lng: 9.082255363464355,
        lat: 46.10203552246088
    },
    {
        lng: 9.082876205444336,
        lat: 46.10041809082054
    },
    {
        lng: 9.083801269531364,
        lat: 46.09920883178722
    },
    {
        lng: 9.08447265625,
        lat: 46.097602844238395
    },
    {
        lng: 9.086791992187557,
        lat: 46.09385681152344
    },
    {
        lng: 9.087534904479924,
        lat: 46.091796875
    },
    {
        lng: 9.08833217620861,
        lat: 46.09038925170921
    },
    {
        lng: 9.08909797668457,
        lat: 46.088359832763956
    },
    {
        lng: 9.089913368225211,
        lat: 46.086994171142635
    },
    {
        lng: 9.09013366699213,
        lat: 46.086364746094034
    },
    {
        lng: 9.09019756317133,
        lat: 46.085998535156364
    },
    {
        lng: 9.09024524688715,
        lat: 46.085727691650334
    },
    {
        lng: 9.090277671814079,
        lat: 46.08477783203148
    },
    {
        lng: 9.090217590332088,
        lat: 46.0841712951663
    },
    {
        lng: 9.090069770812988,
        lat: 46.083610534668196
    },
    {
        lng: 9.08978652954113,
        lat: 46.08313369750999
    },
    {
        lng: 9.08897876739502,
        lat: 46.0823783874514
    },
    {
        lng: 9.088180541992301,
        lat: 46.08115768432617
    },
    {
        lng: 9.087347030639705,
        lat: 46.08042907714855
    },
    {
        lng: 9.086738586425781,
        lat: 46.079387664795036
    },
    {
        lng: 9.085749626159725,
        lat: 46.078479766845646
    },
    {
        lng: 9.08503341674816,
        lat: 46.07748031616222
    },
    {
        lng: 9.084589958190975,
        lat: 46.07717895507824
    },
    {
        lng: 9.084481239318848,
        lat: 46.077140808105696
    },
    {
        lng: 9.082988739013672,
        lat: 46.076606750488395
    },
    {
        lng: 9.081418037414664,
        lat: 46.07608032226574
    },
    {
        lng: 9.080971717834586,
        lat: 46.075786590576286
    },
    {
        lng: 9.080649375915584,
        lat: 46.07536697387707
    },
    {
        lng: 9.080140113830623,
        lat: 46.074424743652344
    },
    {
        lng: 9.079326629638786,
        lat: 46.0732231140139
    },
    {
        lng: 9.079051017761287,
        lat: 46.07234954834007
    },
    {
        lng: 9.07894611358654,
        lat: 46.07142639160179
    },
    {
        lng: 9.078985214233512,
        lat: 46.06820678710943
    },
    {
        lng: 9.078828811645565,
        lat: 46.06695938110357
    },
    {
        lng: 9.078624725341797,
        lat: 46.06632614135742
    },
    {
        lng: 9.07851600646984,
        lat: 46.065990447998274
    },
    {
        lng: 9.07808589935297,
        lat: 46.06534957885759
    },
    {
        lng: 9.077096939086971,
        lat: 46.064300537109546
    },
    {
        lng: 9.076454162597656,
        lat: 46.063915252685604
    },
    {
        lng: 9.074667930603027,
        lat: 46.063293457031364
    },
    {
        lng: 9.073721885681152,
        lat: 46.06283187866205
    },
    {
        lng: 9.072957038879395,
        lat: 46.062572479248104
    },
    {
        lng: 9.07161998748785,
        lat: 46.06222152709972
    },
    {
        lng: 9.070677757263184,
        lat: 46.062065124511946
    },
    {
        lng: 9.067741394043026,
        lat: 46.06176376342796
    },
    {
        lng: 9.06243991851818,
        lat: 46.0610160827639
    },
    {
        lng: 9.060565948486385,
        lat: 46.06095123291021
    },
    {
        lng: 9.059646606445312,
        lat: 46.06102752685541
    },
    {
        lng: 9.056011199951229,
        lat: 46.061614990234375
    },
    {
        lng: 9.051201820373649,
        lat: 46.062938690185774
    },
    {
        lng: 9.049826622009391,
        lat: 46.06113052368164
    },
    {
        lng: 9.049470901489315,
        lat: 46.06076431274414
    },
    {
        lng: 9.047812461853027,
        lat: 46.05957031250023
    },
    {
        lng: 9.047304153442383,
        lat: 46.05931472778343
    },
    {
        lng: 9.046213150024471,
        lat: 46.0590438842774
    },
    {
        lng: 9.043110847473258,
        lat: 46.05884552001959
    },
    {
        lng: 9.042007446289176,
        lat: 46.05867385864275
    },
    {
        lng: 9.040726661682243,
        lat: 46.058380126953125
    },
    {
        lng: 9.039143562316895,
        lat: 46.057758331299055
    },
    {
        lng: 9.037460327148551,
        lat: 46.05736541748075
    },
    {
        lng: 9.036922454834041,
        lat: 46.05713653564459
    },
    {
        lng: 9.035395622253475,
        lat: 46.05630111694347
    },
    {
        lng: 9.034623146057243,
        lat: 46.055751800537394
    },
    {
        lng: 9.032861709594783,
        lat: 46.05473709106468
    },
    {
        lng: 9.032315254211426,
        lat: 46.054512023926065
    },
    {
        lng: 9.030241966247559,
        lat: 46.05383682251005
    },
    {
        lng: 9.029425621032829,
        lat: 46.05365753173851
    },
    {
        lng: 9.026844024658317,
        lat: 46.05336380004883
    },
    {
        lng: 9.023280143737793,
        lat: 46.052791595458984
    },
    {
        lng: 9.021220207214355,
        lat: 46.05251693725597
    },
    {
        lng: 9.019363403320256,
        lat: 46.051471710205305
    },
    {
        lng: 9.018833160400504,
        lat: 46.0509948730471
    },
    {
        lng: 9.018515586853084,
        lat: 46.05041503906273
    },
    {
        lng: 9.018037796020621,
        lat: 46.04900360107416
    },
    {
        lng: 9.018248558044434,
        lat: 46.04730987548828
    },
    {
        lng: 9.018299102783317,
        lat: 46.045978546142635
    },
    {
        lng: 9.018175125122127,
        lat: 46.04503250122076
    },
    {
        lng: 9.017807960510368,
        lat: 46.04412841796898
    },
    {
        lng: 9.017058372497502,
        lat: 46.043292999267805
    },
    {
        lng: 9.015569686889648,
        lat: 46.04206085205084
    },
    {
        lng: 9.014728546142578,
        lat: 46.04127502441429
    },
    {
        lng: 9.013641357421818,
        lat: 46.04057693481445
    },
    {
        lng: 9.011810302734432,
        lat: 46.03927993774437
    },
    {
        lng: 9.010683059692383,
        lat: 46.03800964355469
    },
    {
        lng: 9.010178565979118,
        lat: 46.037193298339844
    },
    {
        lng: 9.00993442535406,
        lat: 46.03624725341797
    },
    {
        lng: 9.009675025939941,
        lat: 46.03398132324236
    },
    {
        lng: 9.009226799011287,
        lat: 46.032062530517635
    },
    {
        lng: 9.009228706359863,
        lat: 46.029907226562784
    },
    {
        lng: 9.009786605834904,
        lat: 46.02869033813471
    },
    {
        lng: 9.010112762451115,
        lat: 46.02820205688499
    },
    {
        lng: 9.011521339416618,
        lat: 46.02701950073259
    },
    {
        lng: 9.0123291015625,
        lat: 46.026508331299055
    },
    {
        lng: 9.01302719116211,
        lat: 46.02592086792015
    },
    {
        lng: 9.01381587982172,
        lat: 46.02535247802729
    },
    {
        lng: 9.016063690185547,
        lat: 46.022735595703125
    },
    {
        lng: 9.017629623413086,
        lat: 46.021568298339844
    },
    {
        lng: 9.018701553344727,
        lat: 46.02098464965826
    },
    {
        lng: 9.018890380859432,
        lat: 46.02088165283226
    },
    {
        lng: 9.019673347473088,
        lat: 46.02027511596708
    },
    {
        lng: 9.020810127258244,
        lat: 46.018871307373274
    },
    {
        lng: 9.022244453430233,
        lat: 46.01750183105497
    },
    {
        lng: 9.022603034973145,
        lat: 46.01073074340849
    },
    {
        lng: 9.02388000488287,
        lat: 46.00442123413097
    },
    {
        lng: 9.023876190185547,
        lat: 46.00367736816406
    },
    {
        lng: 9.023863792419547,
        lat: 46.00107574462902
    },
    {
        lng: 9.025085449218693,
        lat: 45.99890899658203
    },
    {
        lng: 9.02540588378912,
        lat: 45.9985618591308
    },
    {
        lng: 9.025656700134277,
        lat: 45.99828720092779
    },
    {
        lng: 9.02597904205328,
        lat: 45.99793624877941
    },
    {
        lng: 9.02643680572504,
        lat: 45.99704742431646
    },
    {
        lng: 9.026851654052848,
        lat: 45.99564361572283
    },
    {
        lng: 9.026892662048397,
        lat: 45.995090484619425
    },
    {
        lng: 9.026685714721737,
        lat: 45.9944801330567
    },
    {
        lng: 9.026350975036735,
        lat: 45.99411773681658
    },
    {
        lng: 9.025227546691895,
        lat: 45.99352645874029
    },
    {
        lng: 9.02442932128912,
        lat: 45.993022918701115
    },
    {
        lng: 9.023537635803336,
        lat: 45.992595672607536
    },
    {
        lng: 9.022772789001408,
        lat: 45.99208068847679
    },
    {
        lng: 9.021917343139705,
        lat: 45.99163055419939
    },
    {
        lng: 9.021185874939022,
        lat: 45.99108123779297
    },
    {
        lng: 9.020353317260799,
        lat: 45.99058151245134
    },
    {
        lng: 9.019359588623104,
        lat: 45.98976516723644
    },
    {
        lng: 9.018415451049805,
        lat: 45.98929595947277
    },
    {
        lng: 9.017610549926815,
        lat: 45.989093780517805
    },
    {
        lng: 9.016710281372184,
        lat: 45.98900985717785
    },
    {
        lng: 9.013976097107047,
        lat: 45.98901367187523
    },
    {
        lng: 9.013135910034237,
        lat: 45.98897171020519
    },
    {
        lng: 9.012390136718807,
        lat: 45.988815307617415
    },
    {
        lng: 9.011916160583496,
        lat: 45.98859024047863
    },
    {
        lng: 9.010932922363395,
        lat: 45.98797225952171
    },
    {
        lng: 9.010581970214787,
        lat: 45.98758316040062
    },
    {
        lng: 9.010335922241325,
        lat: 45.98677825927746
    },
    {
        lng: 9.010160446166935,
        lat: 45.985038757324276
    },
    {
        lng: 9.009969711303654,
        lat: 45.98451995849621
    },
    {
        lng: 9.009806632995662,
        lat: 45.98429489135759
    },
    {
        lng: 9.00838947296154,
        lat: 45.98319625854515
    },
    {
        lng: 9.00662803649908,
        lat: 45.982345581054915
    },
    {
        lng: 9.005559921264762,
        lat: 45.982154846191634
    },
    {
        lng: 9.003276824951172,
        lat: 45.98212432861328
    },
    {
        lng: 9.002596855163688,
        lat: 45.982181549072266
    },
    {
        lng: 9.000699043273983,
        lat: 45.98244476318354
    },
    {
        lng: 8.999464035034293,
        lat: 45.982612609863395
    },
    {
        lng: 8.996955871582088,
        lat: 45.98276519775402
    },
    {
        lng: 8.996005058288574,
        lat: 45.982696533203125
    },
    {
        lng: 8.995551109313965,
        lat: 45.982604980469034
    },
    {
        lng: 8.994814872741813,
        lat: 45.98232650756847
    },
    {
        lng: 8.994244575500545,
        lat: 45.98193740844755
    },
    {
        lng: 8.994037628173942,
        lat: 45.981708526611385
    },
    {
        lng: 8.99389553070074,
        lat: 45.98146057128935
    },
    {
        lng: 8.993789672851676,
        lat: 45.98096466064453
    },
    {
        lng: 8.993966102600211,
        lat: 45.980278015136776
    },
    {
        lng: 8.994902610778922,
        lat: 45.97927856445335
    },
    {
        lng: 8.99514198303234,
        lat: 45.97855758666992
    },
    {
        lng: 8.99507045745861,
        lat: 45.977779388427734
    },
    {
        lng: 8.994519233703613,
        lat: 45.97675323486345
    },
    {
        lng: 8.993933677673454,
        lat: 45.97613143920921
    },
    {
        lng: 8.992994308471623,
        lat: 45.97559356689453
    },
    {
        lng: 8.991870880126953,
        lat: 45.97521209716797
    },
    {
        lng: 8.989735603332463,
        lat: 45.97464370727545
    },
    {
        lng: 8.989384651184082,
        lat: 45.97431564331066
    },
    {
        lng: 8.98914623260498,
        lat: 45.973899841308764
    },
    {
        lng: 8.989153861999512,
        lat: 45.97341537475603
    },
    {
        lng: 8.989267349243107,
        lat: 45.973182678222884
    },
    {
        lng: 8.990617752075138,
        lat: 45.97187805175787
    },
    {
        lng: 8.991499900817814,
        lat: 45.97125244140625
    },
    {
        lng: 8.992602348327637,
        lat: 45.97025680541998
    },
    {
        lng: 8.993576049804801,
        lat: 45.969612121582145
    },
    {
        lng: 8.99439716339117,
        lat: 45.969226837158374
    },
    {
        lng: 8.996892929077205,
        lat: 45.96821975708019
    },
    {
        lng: 8.999627113342342,
        lat: 45.967430114746094
    },
    {
        lng: 9.001494407653865,
        lat: 45.96661758422874
    },
    {
        lng: 9.003826141357365,
        lat: 45.96597671508806
    },
    {
        lng: 9.004608154296932,
        lat: 45.96570205688488
    },
    {
        lng: 9.005783081054801,
        lat: 45.96528625488281
    },
    {
        lng: 9.007575035095215,
        lat: 45.964839935302905
    },
    {
        lng: 9.008362770080566,
        lat: 45.964584350586165
    },
    {
        lng: 9.011080741882324,
        lat: 45.96320343017578
    },
    {
        lng: 9.01205062866211,
        lat: 45.962520599365234
    },
    {
        lng: 9.012865066528377,
        lat: 45.9617271423341
    },
    {
        lng: 9.013251304626465,
        lat: 45.96111297607439
    },
    {
        lng: 9.013497352600098,
        lat: 45.960445404052905
    },
    {
        lng: 9.014044761657715,
        lat: 45.958740234375114
    },
    {
        lng: 9.01445674896246,
        lat: 45.957019805908374
    },
    {
        lng: 9.014886856079102,
        lat: 45.95562744140648
    },
    {
        lng: 9.015337944030819,
        lat: 45.953529357910384
    },
    {
        lng: 9.015582084655875,
        lat: 45.95287322998041
    },
    {
        lng: 9.016231536865234,
        lat: 45.95168304443371
    },
    {
        lng: 9.016276359558162,
        lat: 45.95159912109375
    },
    {
        lng: 9.016317367553711,
        lat: 45.951381683349666
    },
    {
        lng: 9.016459465026912,
        lat: 45.95063781738281
    },
    {
        lng: 9.01636886596691,
        lat: 45.95001220703119
    },
    {
        lng: 9.01624965667736,
        lat: 45.94972229003929
    },
    {
        lng: 9.014882087707633,
        lat: 45.94771957397484
    },
    {
        lng: 9.014161109924373,
        lat: 45.946731567382926
    },
    {
        lng: 9.013774871826172,
        lat: 45.94629287719721
    },
    {
        lng: 9.013727188110352,
        lat: 45.94623565673845
    },
    {
        lng: 9.01282787322998,
        lat: 45.945743560791016
    },
    {
        lng: 9.012778282165584,
        lat: 45.94499969482433
    },
    {
        lng: 9.012836456298828,
        lat: 45.94452667236345
    },
    {
        lng: 9.013045310974064,
        lat: 45.944091796875114
    },
    {
        lng: 9.013367652893123,
        lat: 45.94378662109375
    },
    {
        lng: 9.014237403869572,
        lat: 45.943119049072266
    },
    {
        lng: 9.014675140380973,
        lat: 45.94287872314459
    },
    {
        lng: 9.015506744384766,
        lat: 45.94262313842785
    },
    {
        lng: 9.016967773437557,
        lat: 45.94217300415062
    },
    {
        lng: 9.017642974853572,
        lat: 45.941864013671875
    },
    {
        lng: 9.018488883972111,
        lat: 45.9411811828615
    },
    {
        lng: 9.019199371337947,
        lat: 45.94072341918957
    },
    {
        lng: 9.020248413085994,
        lat: 45.939804077148665
    },
    {
        lng: 9.020526885986385,
        lat: 45.939426422119084
    },
    {
        lng: 9.020623207092342,
        lat: 45.939178466797046
    },
    {
        lng: 9.020726203918514,
        lat: 45.938354492187784
    },
    {
        lng: 9.020554542541504,
        lat: 45.937183380127124
    },
    {
        lng: 9.020019531250057,
        lat: 45.936019897460994
    },
    {
        lng: 9.019271850585994,
        lat: 45.93439483642578
    },
    {
        lng: 9.018707275390682,
        lat: 45.93237304687523
    },
    {
        lng: 9.018035888671818,
        lat: 45.93041610717785
    },
    {
        lng: 9.020001411438102,
        lat: 45.93088150024431
    },
    {
        lng: 9.020977973938102,
        lat: 45.93106079101568
    },
    {
        lng: 9.022363662719783,
        lat: 45.931175231933764
    },
    {
        lng: 9.023267745971793,
        lat: 45.93115997314453
    },
    {
        lng: 9.024157524109,
        lat: 45.93104934692383
    },
    {
        lng: 9.025828361511287,
        lat: 45.9307098388673
    },
    {
        lng: 9.029226303100529,
        lat: 45.929584503174055
    },
    {
        lng: 9.031777381896916,
        lat: 45.929565429687614
    },
    {
        lng: 9.033912658691406,
        lat: 45.92964172363298
    },
    {
        lng: 9.035468101501522,
        lat: 45.92978286743187
    },
    {
        lng: 9.037553787231445,
        lat: 45.93012619018572
    },
    {
        lng: 9.03856468200695,
        lat: 45.9302406311038
    },
    {
        lng: 9.039982795715389,
        lat: 45.93033981323242
    },
    {
        lng: 9.040876388549748,
        lat: 45.93030929565447
    },
    {
        lng: 9.041630744934082,
        lat: 45.9300537109375
    },
    {
        lng: 9.043191909790096,
        lat: 45.92906951904314
    },
    {
        lng: 9.044710159301815,
        lat: 45.927947998046875
    },
    {
        lng: 9.046854019165153,
        lat: 45.925811767578125
    },
    {
        lng: 9.047766685485783,
        lat: 45.924800872802734
    },
    {
        lng: 9.048618316650504,
        lat: 45.924282073974666
    },
    {
        lng: 9.049330711364746,
        lat: 45.924083709717024
    },
    {
        lng: 9.049966812133789,
        lat: 45.92404174804716
    },
    {
        lng: 9.05466365814209,
        lat: 45.92430877685564
    },
    {
        lng: 9.056202888488826,
        lat: 45.92439651489275
    },
    {
        lng: 9.056784629821777,
        lat: 45.924373626708984
    },
    {
        lng: 9.05735301971447,
        lat: 45.92422866821289
    },
    {
        lng: 9.057792663574276,
        lat: 45.92394256591797
    },
    {
        lng: 9.058115005493278,
        lat: 45.92341613769554
    },
    {
        lng: 9.058202743530387,
        lat: 45.92253494262718
    },
    {
        lng: 9.05809020996088,
        lat: 45.920936584472884
    },
    {
        lng: 9.058168411254883,
        lat: 45.92071151733427
    },
    {
        lng: 9.058247566223145,
        lat: 45.920482635498104
    },
    {
        lng: 9.05866813659668,
        lat: 45.92001342773449
    },
    {
        lng: 9.059602737426871,
        lat: 45.919429779052734
    },
    {
        lng: 9.060405731201229,
        lat: 45.91912841796875
    },
    {
        lng: 9.064904212951717,
        lat: 45.9177131652832
    },
    {
        lng: 9.065974235534611,
        lat: 45.917190551757926
    },
    {
        lng: 9.06742000579834,
        lat: 45.916355133056584
    },
    {
        lng: 9.067955970764217,
        lat: 45.91614532470726
    },
    {
        lng: 9.068907737732047,
        lat: 45.916004180908374
    },
    {
        lng: 9.071041107177791,
        lat: 45.916099548340014
    },
    {
        lng: 9.071275711059684,
        lat: 45.916107177734546
    },
    {
        lng: 9.072756767273006,
        lat: 45.91600799560558
    },
    {
        lng: 9.073540687561149,
        lat: 45.915828704833984
    },
    {
        lng: 9.074588775634709,
        lat: 45.915481567382926
    },
    {
        lng: 9.075325012207088,
        lat: 45.91517257690458
    },
    {
        lng: 9.075642585754451,
        lat: 45.914958953857536
    },
    {
        lng: 9.075880050659237,
        lat: 45.91469573974638
    },
    {
        lng: 9.076068878173942,
        lat: 45.914230346679915
    },
    {
        lng: 9.0761137008667,
        lat: 45.914119720458984
    },
    {
        lng: 9.076084136963004,
        lat: 45.913219451904354
    },
    {
        lng: 9.075867652893066,
        lat: 45.91265487670904
    },
    {
        lng: 9.075698852539062,
        lat: 45.91240310668945
    },
    {
        lng: 9.075060844421444,
        lat: 45.91176605224632
    },
    {
        lng: 9.074758529663143,
        lat: 45.91123580932634
    },
    {
        lng: 9.07460021972662,
        lat: 45.910621643066634
    },
    {
        lng: 9.074579238891602,
        lat: 45.91007614135742
    },
    {
        lng: 9.074563026428223,
        lat: 45.909641265869425
    },
    {
        lng: 9.07467079162609,
        lat: 45.90898132324247
    },
    {
        lng: 9.075312614440861,
        lat: 45.90672302246088
    },
    {
        lng: 9.075697898864803,
        lat: 45.90602111816406
    },
    {
        lng: 9.076302528381404,
        lat: 45.90536499023449
    },
    {
        lng: 9.076642036438045,
        lat: 45.9051628112793
    },
    {
        lng: 9.07684230804449,
        lat: 45.90509414672846
    },
    {
        lng: 9.077055931091365,
        lat: 45.90502166748047
    },
    {
        lng: 9.07750034332281,
        lat: 45.90494537353527
    },
    {
        lng: 9.077615737915039,
        lat: 45.90494537353527
    },
    {
        lng: 9.078430175781307,
        lat: 45.90493392944336
    },
    {
        lng: 9.07936096191412,
        lat: 45.905075073242415
    },
    {
        lng: 9.08120059967041,
        lat: 45.90567016601585
    },
    {
        lng: 9.082139968872184,
        lat: 45.90589523315447
    },
    {
        lng: 9.08433628082281,
        lat: 45.906234741211165
    },
    {
        lng: 9.0852890014649,
        lat: 45.90626525878912
    },
    {
        lng: 9.08628082275402,
        lat: 45.90610122680664
    },
    {
        lng: 9.086612701416016,
        lat: 45.90593338012718
    },
    {
        lng: 9.086981773376522,
        lat: 45.90575027465843
    },
    {
        lng: 9.087194442749137,
        lat: 45.90546035766613
    },
    {
        lng: 9.087306976318473,
        lat: 45.905155181884766
    },
    {
        lng: 9.087333679199219,
        lat: 45.904514312744254
    },
    {
        lng: 9.087043762207145,
        lat: 45.903533935546875
    },
    {
        lng: 9.086874008178711,
        lat: 45.90322494506853
    },
    {
        lng: 9.08640003204357,
        lat: 45.902355194091854
    },
    {
        lng: 9.085929870605412,
        lat: 45.90171813964855
    },
    {
        lng: 9.083575248718262,
        lat: 45.899326324463004
    },
    {
        lng: 9.082725524902344,
        lat: 45.898689270019645
    },
    {
        lng: 9.081673622131404,
        lat: 45.89768600463867
    },
    {
        lng: 9.080724716186467,
        lat: 45.89704513549816
    },
    {
        lng: 9.080017089843693,
        lat: 45.896705627441634
    },
    {
        lng: 9.077976226806754,
        lat: 45.89610671997099
    },
    {
        lng: 9.077618598938102,
        lat: 45.895999908447266
    },
    {
        lng: 9.076963424682617,
        lat: 45.89570617675781
    },
    {
        lng: 9.076692581176758,
        lat: 45.895484924316406
    },
    {
        lng: 9.076505661010856,
        lat: 45.895206451416016
    },
    {
        lng: 9.076399803161621,
        lat: 45.894573211670206
    },
    {
        lng: 9.076891899108887,
        lat: 45.892856597900675
    },
    {
        lng: 9.076864242553825,
        lat: 45.891792297363395
    },
    {
        lng: 9.076637268066406,
        lat: 45.89116287231457
    },
    {
        lng: 9.076355934143123,
        lat: 45.89076614379911
    },
    {
        lng: 9.075937271118164,
        lat: 45.890174865722656
    },
    {
        lng: 9.075721740722713,
        lat: 45.889541625976676
    },
    {
        lng: 9.075320243835392,
        lat: 45.888362884521484
    },
    {
        lng: 9.075038909912166,
        lat: 45.88779449462902
    },
    {
        lng: 9.074585914611873,
        lat: 45.88733291626005
    },
    {
        lng: 9.073207855224723,
        lat: 45.88646697998075
    },
    {
        lng: 9.071520805358887,
        lat: 45.88607025146496
    },
    {
        lng: 9.071407318115234,
        lat: 45.886043548583984
    },
    {
        lng: 9.071092605590763,
        lat: 45.88597106933611
    },
    {
        lng: 9.069450378418026,
        lat: 45.88492965698242
    },
    {
        lng: 9.069238662719727,
        lat: 45.88465881347656
    },
    {
        lng: 9.069049835205021,
        lat: 45.884418487548885
    },
    {
        lng: 9.068369865417537,
        lat: 45.88331222534191
    },
    {
        lng: 9.06765174865734,
        lat: 45.88264846801758
    },
    {
        lng: 9.065180778503532,
        lat: 45.87977981567383
    },
    {
        lng: 9.064708709716797,
        lat: 45.87945556640625
    },
    {
        lng: 9.064039230346737,
        lat: 45.87918853759771
    },
    {
        lng: 9.062253952026367,
        lat: 45.878864288330305
    },
    {
        lng: 9.059797286987305,
        lat: 45.87881851196306
    },
    {
        lng: 9.0565567016601,
        lat: 45.878757476806754
    },
    {
        lng: 9.055342674255485,
        lat: 45.87862777709978
    },
    {
        lng: 9.054023742675895,
        lat: 45.878219604492244
    },
    {
        lng: 9.0534410476684,
        lat: 45.877758026123104
    },
    {
        lng: 9.053106307983398,
        lat: 45.877166748046875
    },
    {
        lng: 9.052551269531193,
        lat: 45.87561416625999
    },
    {
        lng: 9.051636695861816,
        lat: 45.874370574951286
    },
    {
        lng: 9.050814628601131,
        lat: 45.87215805053711
    },
    {
        lng: 9.049794197082633,
        lat: 45.870990753174
    },
    {
        lng: 9.04896259307867,
        lat: 45.86965560913103
    },
    {
        lng: 9.048307418823185,
        lat: 45.86900329589844
    },
    {
        lng: 9.047971725463867,
        lat: 45.868431091308594
    },
    {
        lng: 9.047741889953613,
        lat: 45.86776351928711
    },
    {
        lng: 9.047362327575797,
        lat: 45.86631774902361
    },
    {
        lng: 9.046866416931152,
        lat: 45.864841461181754
    },
    {
        lng: 9.046681404113826,
        lat: 45.863685607910384
    },
    {
        lng: 9.046698570251522,
        lat: 45.86275100708002
    },
    {
        lng: 9.046710014343205,
        lat: 45.86210250854498
    },
    {
        lng: 9.047489166259709,
        lat: 45.85943222045904
    },
    {
        lng: 9.047610282898063,
        lat: 45.859016418457145
    },
    {
        lng: 9.047720909118766,
        lat: 45.85826110839855
    },
    {
        lng: 9.047613143920898,
        lat: 45.857154846191406
    },
    {
        lng: 9.047187805175895,
        lat: 45.85627746582054
    },
    {
        lng: 9.046906471252441,
        lat: 45.85585403442411
    },
    {
        lng: 9.045963287353459,
        lat: 45.854438781738395
    },
    {
        lng: 9.045447349548454,
        lat: 45.853794097900334
    },
    {
        lng: 9.044954299926871,
        lat: 45.85333633422857
    },
    {
        lng: 9.043163299560547,
        lat: 45.85240173339861
    },
    {
        lng: 9.04191875457775,
        lat: 45.851284027099894
    },
    {
        lng: 9.04142856597906,
        lat: 45.85095977783209
    },
    {
        lng: 9.041110038757381,
        lat: 45.850753784179744
    },
    {
        lng: 9.040481567382926,
        lat: 45.850486755371264
    },
    {
        lng: 9.038987159729118,
        lat: 45.8500213623048
    },
    {
        lng: 9.03813362121582,
        lat: 45.84956359863281
    },
    {
        lng: 9.036465644836369,
        lat: 45.84817123413109
    },
    {
        lng: 9.036115646362305,
        lat: 45.84767532348644
    },
    {
        lng: 9.035511016845703,
        lat: 45.846530914306754
    },
    {
        lng: 9.035053253173942,
        lat: 45.84602737426758
    },
    {
        lng: 9.033867835998649,
        lat: 45.84512710571295
    },
    {
        lng: 9.030809402465934,
        lat: 45.84309005737316
    },
    {
        lng: 9.032185554504451,
        lat: 45.84203720092796
    },
    {
        lng: 9.033434867858944,
        lat: 45.84109115600609
    },
    {
        lng: 9.033872604370117,
        lat: 45.84075927734392
    },
    {
        lng: 9.034309387207031,
        lat: 45.840091705322436
    },
    {
        lng: 9.034476280212402,
        lat: 45.8396568298341
    },
    {
        lng: 9.034546852111873,
        lat: 45.83922576904297
    },
    {
        lng: 9.03440093994152,
        lat: 45.838645935058594
    },
    {
        lng: 9.03409481048584,
        lat: 45.838104248046875
    },
    {
        lng: 9.033293724060059,
        lat: 45.83744430541992
    },
    {
        lng: 9.033000946044865,
        lat: 45.837051391601676
    },
    {
        lng: 9.03246784210205,
        lat: 45.8358154296875
    },
    {
        lng: 9.031693458557186,
        lat: 45.83430480957031
    },
    {
        lng: 9.030800819397086,
        lat: 45.832817077636946
    },
    {
        lng: 9.02991867065441,
        lat: 45.83161163330095
    },
    {
        lng: 9.02961254119873,
        lat: 45.83076095581055
    },
    {
        lng: 9.02939224243164,
        lat: 45.82942962646496
    },
    {
        lng: 9.029449462890739,
        lat: 45.82880020141596
    },
    {
        lng: 9.029713630676326,
        lat: 45.82821273803722
    },
    {
        lng: 9.029956817626896,
        lat: 45.8278503417971
    },
    {
        lng: 9.03077602386469,
        lat: 45.827159881592024
    },
    {
        lng: 9.031000137329215,
        lat: 45.826797485351506
    },
    {
        lng: 9.031007766723746,
        lat: 45.82654571533209
    },
    {
        lng: 9.03101444244379,
        lat: 45.826332092285384
    },
    {
        lng: 9.03089523315424,
        lat: 45.826087951660156
    },
    {
        lng: 9.030466079711857,
        lat: 45.8254280090332
    },
    {
        lng: 9.029554367065487,
        lat: 45.824382781982706
    },
    {
        lng: 9.027546882629395,
        lat: 45.822654724121094
    },
    {
        lng: 9.026850700378532,
        lat: 45.8223495483399
    },
    {
        lng: 9.02612590789795,
        lat: 45.82226181030285
    },
    {
        lng: 9.025433540344238,
        lat: 45.82236099243181
    },
    {
        lng: 9.024888992309684,
        lat: 45.82263565063482
    },
    {
        lng: 9.024231910705623,
        lat: 45.8231315612793
    },
    {
        lng: 9.023163795471305,
        lat: 45.823463439941634
    },
    {
        lng: 9.022930145263672,
        lat: 45.823471069336165
    },
    {
        lng: 9.021072387695256,
        lat: 45.82352447509794
    },
    {
        lng: 9.01998233795166,
        lat: 45.82344818115257
    },
    {
        lng: 9.019400596618652,
        lat: 45.82330322265631
    },
    {
        lng: 9.018637657165527,
        lat: 45.822772979736555
    },
    {
        lng: 9.017821311950627,
        lat: 45.821773529052734
    },
    {
        lng: 9.016526222228947,
        lat: 45.82048416137718
    },
    {
        lng: 9.0145845413208,
        lat: 45.81792068481457
    },
    {
        lng: 9.01422977447504,
        lat: 45.817646026611555
    },
    {
        lng: 9.013430595398006,
        lat: 45.817283630371094
    },
    {
        lng: 9.012569427490348,
        lat: 45.817100524902344
    },
    {
        lng: 9.011681556701717,
        lat: 45.81705856323265
    },
    {
        lng: 9.01037311553955,
        lat: 45.81726074218744
    },
    {
        lng: 9.008504867553711,
        lat: 45.81807327270519
    },
    {
        lng: 9.005670547485295,
        lat: 45.818794250488224
    },
    {
        lng: 9.00378322601324,
        lat: 45.81955718994135
    },
    {
        lng: 9.002935409545898,
        lat: 45.81973266601574
    },
    {
        lng: 9.000816345214957,
        lat: 45.81996154785156
    },
    {
        lng: 9.0000581741333,
        lat: 45.820152282714844
    },
    {
        lng: 8.999481201171989,
        lat: 45.82047653198242
    },
    {
        lng: 8.998556137084961,
        lat: 45.82117843627924
    },
    {
        lng: 8.996686935424805,
        lat: 45.822132110595646
    },
    {
        lng: 8.995958328247013,
        lat: 45.82241058349604
    },
    {
        lng: 8.994784355163688,
        lat: 45.82255935668945
    },
    {
        lng: 8.992755889892521,
        lat: 45.822486877441634
    },
    {
        lng: 8.99222564697277,
        lat: 45.82265853881847
    },
    {
        lng: 8.991791725158691,
        lat: 45.82305145263672
    },
    {
        lng: 8.991392135620174,
        lat: 45.823921203613395
    },
    {
        lng: 8.991229057312012,
        lat: 45.82487869262718
    },
    {
        lng: 8.991180419921875,
        lat: 45.8251647949221
    },
    {
        lng: 8.991733551025447,
        lat: 45.82915115356474
    },
    {
        lng: 8.992086410522461,
        lat: 45.830383300781364
    },
    {
        lng: 8.99295520782482,
        lat: 45.832092285156364
    },
    {
        lng: 8.993798255920524,
        lat: 45.83449172973661
    },
    {
        lng: 8.994603157043514,
        lat: 45.836032867431754
    },
    {
        lng: 8.99487590789795,
        lat: 45.83701705932617
    },
    {
        lng: 8.994963645935059,
        lat: 45.837684631347656
    },
    {
        lng: 8.995050430297852,
        lat: 45.83833694458002
    },
    {
        lng: 8.993586540222225,
        lat: 45.838535308837834
    },
    {
        lng: 8.991683959961051,
        lat: 45.83893966674805
    },
    {
        lng: 8.989454269409237,
        lat: 45.83961486816423
    },
    {
        lng: 8.988733291625977,
        lat: 45.8399467468264
    },
    {
        lng: 8.988166809082088,
        lat: 45.84032058715843
    },
    {
        lng: 8.98698711395275,
        lat: 45.84144973754883
    },
    {
        lng: 8.986528396606502,
        lat: 45.84172821044922
    },
    {
        lng: 8.985830307006893,
        lat: 45.841960906982365
    },
    {
        lng: 8.985380172729492,
        lat: 45.8420295715332
    },
    {
        lng: 8.984444618225211,
        lat: 45.84204483032249
    },
    {
        lng: 8.983977317810115,
        lat: 45.84198760986334
    },
    {
        lng: 8.983100891113395,
        lat: 45.84176254272472
    },
    {
        lng: 8.980863571167049,
        lat: 45.84074401855469
    },
    {
        lng: 8.980065345764274,
        lat: 45.84049224853527
    },
    {
        lng: 8.979160308838004,
        lat: 45.84037399291998
    },
    {
        lng: 8.97730636596674,
        lat: 45.840450286865234
    },
    {
        lng: 8.976362228393668,
        lat: 45.840435028076115
    },
    {
        lng: 8.975420951843262,
        lat: 45.84031677246122
    },
    {
        lng: 8.974967002868652,
        lat: 45.84020233154297
    },
    {
        lng: 8.974270820617619,
        lat: 45.839912414550895
    },
    {
        lng: 8.97369289398199,
        lat: 45.839534759521484
    },
    {
        lng: 8.972285270691032,
        lat: 45.83799362182634
    },
    {
        lng: 8.971387863159293,
        lat: 45.83726119995117
    },
    {
        lng: 8.970690727233944,
        lat: 45.83684158325195
    },
    {
        lng: 8.970453262329102,
        lat: 45.836742401123104
    },
    {
        lng: 8.969869613647575,
        lat: 45.8365020751956
    },
    {
        lng: 8.969372749328613,
        lat: 45.83634948730469
    },
    {
        lng: 8.96887111663824,
        lat: 45.83624267578148
    },
    {
        lng: 8.967893600463924,
        lat: 45.83619308471691
    },
    {
        lng: 8.967021942138672,
        lat: 45.83637237548845
    },
    {
        lng: 8.965024948120117,
        lat: 45.83769607543951
    },
    {
        lng: 8.96367263793951,
        lat: 45.838279724121264
    },
    {
        lng: 8.962895393371582,
        lat: 45.8386802673341
    },
    {
        lng: 8.962739944458121,
        lat: 45.838760375976676
    },
    {
        lng: 8.961253166198674,
        lat: 45.839164733887
    },
    {
        lng: 8.960543632507324,
        lat: 45.839450836181925
    },
    {
        lng: 8.960029602050895,
        lat: 45.839908599853686
    },
    {
        lng: 8.959571838378963,
        lat: 45.84055709838873
    },
    {
        lng: 8.95947360992443,
        lat: 45.840805053711165
    },
    {
        lng: 8.959234237670842,
        lat: 45.84140396118164
    },
    {
        lng: 8.958872795105037,
        lat: 45.842311859131144
    },
    {
        lng: 8.95869255065918,
        lat: 45.842609405517805
    },
    {
        lng: 8.958227157592887,
        lat: 45.843112945556584
    },
    {
        lng: 8.957631111145133,
        lat: 45.84355545043945
    },
    {
        lng: 8.956950187683162,
        lat: 45.843933105469034
    },
    {
        lng: 8.954809188842887,
        lat: 45.84481048584007
    },
    {
        lng: 8.952935218811092,
        lat: 45.84599685668945
    },
    {
        lng: 8.951149940490723,
        lat: 45.846099853515796
    },
    {
        lng: 8.949575424194336,
        lat: 45.84605407714855
    },
    {
        lng: 8.941176414489746,
        lat: 45.84509658813499
    },
    {
        lng: 8.940746307373047,
        lat: 45.84504699707037
    },
    {
        lng: 8.938921928405875,
        lat: 45.844661712646484
    },
    {
        lng: 8.938102722167912,
        lat: 45.84442520141613
    },
    {
        lng: 8.936518669128418,
        lat: 45.843864440918196
    },
    {
        lng: 8.934703826904354,
        lat: 45.84328842163097
    },
    {
        lng: 8.932068824768066,
        lat: 45.84220504760742
    },
    {
        lng: 8.931213378906364,
        lat: 45.84161376953131
    },
    {
        lng: 8.930592536926213,
        lat: 45.8412628173831
    },
    {
        lng: 8.930299758911246,
        lat: 45.84109497070324
    },
    {
        lng: 8.929537773132381,
        lat: 45.8404655456543
    },
    {
        lng: 8.92866516113287,
        lat: 45.83987426757835
    },
    {
        lng: 8.927531242370605,
        lat: 45.838642120361385
    },
    {
        lng: 8.925457954406681,
        lat: 45.83672714233421
    },
    {
        lng: 8.924891471862793,
        lat: 45.83620071411144
    },
    {
        lng: 8.924395561218375,
        lat: 45.83584213256847
    },
    {
        lng: 8.924021720886344,
        lat: 45.835678100586165
    },
    {
        lng: 8.923177719116154,
        lat: 45.835483551025334
    },
    {
        lng: 8.922261238098145,
        lat: 45.83540344238281
    },
    {
        lng: 8.916819572448674,
        lat: 45.83549880981445
    },
    {
        lng: 8.915246009826603,
        lat: 45.83542633056658
    },
    {
        lng: 8.91493988037115,
        lat: 45.835411071777344
    },
    {
        lng: 8.914423942565975,
        lat: 45.8353157043457
    },
    {
        lng: 8.914115905761719,
        lat: 45.83525848388672
    },
    {
        lng: 8.91376113891613,
        lat: 45.83513641357433
    },
    {
        lng: 8.912823677063102,
        lat: 45.83462142944359
    },
    {
        lng: 8.911986351013184,
        lat: 45.83430862426786
    },
    {
        lng: 8.911122322082633,
        lat: 45.83406066894531
    },
    {
        lng: 8.910411834716797,
        lat: 45.83396530151367
    },
    {
        lng: 8.910339355468807,
        lat: 45.833969116210994
    },
    {
        lng: 8.9095973968507,
        lat: 45.83401870727539
    },
    {
        lng: 8.909200668335018,
        lat: 45.83411026001005
    },
    {
        lng: 8.908838272094727,
        lat: 45.834243774414006
    },
    {
        lng: 8.908555030822868,
        lat: 45.83443832397484
    },
    {
        lng: 8.908372879028434,
        lat: 45.83466720581066
    },
    {
        lng: 8.90816688537609,
        lat: 45.83547973632835
    },
    {
        lng: 8.908235549926758,
        lat: 45.83770370483404
    },
    {
        lng: 8.908407211303768,
        lat: 45.8393440246582
    },
    {
        lng: 8.908551216125545,
        lat: 45.839984893798885
    },
    {
        lng: 8.908799171447754,
        lat: 45.840610504150504
    },
    {
        lng: 8.909672737121582,
        lat: 45.84193801879911
    },
    {
        lng: 8.910408973693904,
        lat: 45.843585968017805
    },
    {
        lng: 8.910746574401855,
        lat: 45.844120025634766
    },
    {
        lng: 8.911663055420036,
        lat: 45.84529876708979
    },
    {
        lng: 8.912448883056584,
        lat: 45.84630584716797
    },
    {
        lng: 8.913866996765194,
        lat: 45.84786224365257
    },
    {
        lng: 8.914656639099121,
        lat: 45.848514556884766
    },
    {
        lng: 8.915019035339412,
        lat: 45.848884582519645
    },
    {
        lng: 8.915574073791618,
        lat: 45.849773406982536
    },
    {
        lng: 8.915919303894043,
        lat: 45.85018920898443
    },
    {
        lng: 8.918255805969238,
        lat: 45.852001190185604
    },
    {
        lng: 8.919340133667106,
        lat: 45.85351181030296
    },
    {
        lng: 8.919756889343319,
        lat: 45.853836059570426
    },
    {
        lng: 8.921671867370662,
        lat: 45.855342864990234
    },
    {
        lng: 8.922750473022575,
        lat: 45.856864929199276
    },
    {
        lng: 8.925069808960075,
        lat: 45.85869216918957
    },
    {
        lng: 8.926134109497013,
        lat: 45.86021423339861
    },
    {
        lng: 8.927693367004508,
        lat: 45.861423492431584
    },
    {
        lng: 8.928570747375488,
        lat: 45.86195755004911
    },
    {
        lng: 8.929336547851506,
        lat: 45.86256408691429
    },
    {
        lng: 8.930213928222713,
        lat: 45.863136291504134
    },
    {
        lng: 8.93126296997076,
        lat: 45.864051818847656
    },
    {
        lng: 8.932192802429256,
        lat: 45.86463546752958
    },
    {
        lng: 8.932874679565543,
        lat: 45.86493301391624
    },
    {
        lng: 8.935413360595703,
        lat: 45.86588287353527
    },
    {
        lng: 8.93819522857666,
        lat: 45.866817474365234
    },
    {
        lng: 8.9401473999024,
        lat: 45.86777496337919
    },
    {
        lng: 8.943170547485352,
        lat: 45.870223999023494
    },
    {
        lng: 8.941583633422795,
        lat: 45.87234115600586
    },
    {
        lng: 8.94027042388916,
        lat: 45.87242126464861
    },
    {
        lng: 8.939403533935547,
        lat: 45.87228012084972
    },
    {
        lng: 8.938595771789664,
        lat: 45.871963500976676
    },
    {
        lng: 8.93711090087902,
        lat: 45.87100601196289
    },
    {
        lng: 8.93687629699707,
        lat: 45.8709411621096
    },
    {
        lng: 8.936335563659668,
        lat: 45.87078857421881
    },
    {
        lng: 8.935483932495174,
        lat: 45.87075424194347
    },
    {
        lng: 8.934658050537166,
        lat: 45.87093353271507
    },
    {
        lng: 8.934057235717717,
        lat: 45.871318817138786
    },
    {
        lng: 8.933357238769531,
        lat: 45.87208557128929
    },
    {
        lng: 8.93259334564209,
        lat: 45.873096466064624
    },
    {
        lng: 8.93204402923584,
        lat: 45.874095916748274
    },
    {
        lng: 8.931295394897518,
        lat: 45.87730026245117
    },
    {
        lng: 8.930998802185172,
        lat: 45.87970733642601
    },
    {
        lng: 8.930650711059627,
        lat: 45.8814125061038
    },
    {
        lng: 8.930579185485897,
        lat: 45.884498596191634
    },
    {
        lng: 8.930448532104492,
        lat: 45.8866081237793
    },
    {
        lng: 8.930359840393066,
        lat: 45.88805389404297
    },
    {
        lng: 8.929638862609806,
        lat: 45.88950347900402
    },
    {
        lng: 8.929037094116268,
        lat: 45.89091110229498
    },
    {
        lng: 8.928013801574764,
        lat: 45.89199829101591
    },
    {
        lng: 8.927189826965332,
        lat: 45.893112182617244
    },
    {
        lng: 8.926899909973201,
        lat: 45.89344787597679
    },
    {
        lng: 8.925789833068848,
        lat: 45.894733428955135
    },
    {
        lng: 8.924611091613826,
        lat: 45.89463043212896
    },
    {
        lng: 8.924139976501579,
        lat: 45.89468383789074
    },
    {
        lng: 8.923830986023063,
        lat: 45.89472961425781
    },
    {
        lng: 8.923424720764274,
        lat: 45.89484786987305
    },
    {
        lng: 8.923021316528377,
        lat: 45.89503860473633
    },
    {
        lng: 8.922500610351562,
        lat: 45.89548873901373
    },
    {
        lng: 8.92230319976801,
        lat: 45.89583587646496
    },
    {
        lng: 8.922025680542106,
        lat: 45.89632415771507
    },
    {
        lng: 8.92193984985363,
        lat: 45.897338867187784
    },
    {
        lng: 8.922324180603027,
        lat: 45.899139404296875
    },
    {
        lng: 8.922577857971248,
        lat: 45.900291442871094
    },
    {
        lng: 8.923272132873535,
        lat: 45.902561187744254
    },
    {
        lng: 8.923638343810978,
        lat: 45.90375900268549
    },
    {
        lng: 8.923751831054801,
        lat: 45.904785156250284
    },
    {
        lng: 8.92368030548107,
        lat: 45.90546417236351
    },
    {
        lng: 8.92315483093273,
        lat: 45.906791687011946
    },
    {
        lng: 8.922988891601676,
        lat: 45.907051086425895
    },
    {
        lng: 8.922559738159293,
        lat: 45.90772247314453
    },
    {
        lng: 8.920991897582951,
        lat: 45.90939331054682
    },
    {
        lng: 8.920051574707031,
        lat: 45.91005325317377
    },
    {
        lng: 8.918774604797306,
        lat: 45.91139984130882
    },
    {
        lng: 8.917136192321777,
        lat: 45.91363525390625
    },
    {
        lng: 8.916690826416016,
        lat: 45.91436004638672
    },
    {
        lng: 8.91615104675293,
        lat: 45.91567611694359
    },
    {
        lng: 8.912262916564941,
        lat: 45.91461181640648
    },
    {
        lng: 8.911529541015682,
        lat: 45.914310455322266
    },
    {
        lng: 8.910601615905819,
        lat: 45.91371917724621
    },
    {
        lng: 8.909579277038517,
        lat: 45.912784576416016
    },
    {
        lng: 8.908588409423942,
        lat: 45.912250518799055
    },
    {
        lng: 8.907675743103084,
        lat: 45.91167068481468
    },
    {
        lng: 8.906438827514648,
        lat: 45.91095352172863
    },
    {
        lng: 8.904644012451115,
        lat: 45.909122467041016
    },
    {
        lng: 8.903013229370117,
        lat: 45.90680313110363
    },
    {
        lng: 8.902356147766227,
        lat: 45.90549087524414
    },
    {
        lng: 8.901886940002385,
        lat: 45.90501785278326
    },
    {
        lng: 8.901534080505371,
        lat: 45.90481948852562
    },
    {
        lng: 8.90073394775385,
        lat: 45.90455627441412
    },
    {
        lng: 8.899882316589355,
        lat: 45.904418945312614
    },
    {
        lng: 8.899029731750545,
        lat: 45.90438079833996
    },
    {
        lng: 8.8982191085816,
        lat: 45.904445648193416
    },
    {
        lng: 8.897580146789664,
        lat: 45.904632568359375
    },
    {
        lng: 8.8969087600708,
        lat: 45.904964447021484
    },
    {
        lng: 8.895859718322754,
        lat: 45.90536880493187
    },
    {
        lng: 8.895261764526424,
        lat: 45.905693054199276
    },
    {
        lng: 8.894756317138672,
        lat: 45.9061164855957
    },
    {
        lng: 8.894403457641658,
        lat: 45.90665435791021
    },
    {
        lng: 8.894280433654785,
        lat: 45.90711212158203
    },
    {
        lng: 8.89423942565918,
        lat: 45.90726852417015
    },
    {
        lng: 8.894242286682243,
        lat: 45.90823745727562
    },
    {
        lng: 8.894493103027344,
        lat: 45.909217834472656
    },
    {
        lng: 8.894825935363826,
        lat: 45.909828186035156
    },
    {
        lng: 8.897213935851994,
        lat: 45.91300201416027
    },
    {
        lng: 8.897553443908691,
        lat: 45.913600921630916
    },
    {
        lng: 8.897759437561035,
        lat: 45.914237976074446
    },
    {
        lng: 8.89788818359375,
        lat: 45.915203094482365
    },
    {
        lng: 8.897839546203613,
        lat: 45.91614913940441
    },
    {
        lng: 8.897253036498967,
        lat: 45.91827392578131
    },
    {
        lng: 8.89688873291027,
        lat: 45.91910552978527
    },
    {
        lng: 8.896123886108398,
        lat: 45.919898986816406
    },
    {
        lng: 8.894784927368221,
        lat: 45.92083358764671
    },
    {
        lng: 8.890077590942496,
        lat: 45.92371368408203
    },
    {
        lng: 8.88915920257574,
        lat: 45.924446105957145
    },
    {
        lng: 8.888692855834961,
        lat: 45.924995422363565
    },
    {
        lng: 8.88842678070074,
        lat: 45.92554855346697
    },
    {
        lng: 8.888015747070312,
        lat: 45.926967620849666
    },
    {
        lng: 8.887843132019043,
        lat: 45.927425384521484
    },
    {
        lng: 8.887025833129996,
        lat: 45.92958831787121
    },
    {
        lng: 8.886732101440543,
        lat: 45.93104171752924
    },
    {
        lng: 8.886372566223145,
        lat: 45.932338714599894
    },
    {
        lng: 8.886305809021053,
        lat: 45.93365478515642
    },
    {
        lng: 8.886445999145621,
        lat: 45.93569183349621
    },
    {
        lng: 8.886590957641658,
        lat: 45.93671417236328
    },
    {
        lng: 8.88675308227539,
        lat: 45.937385559082145
    },
    {
        lng: 8.88753795623785,
        lat: 45.93993377685547
    },
    {
        lng: 8.88782119750988,
        lat: 45.940509796142635
    },
    {
        lng: 8.888496398925895,
        lat: 45.941593170166016
    },
    {
        lng: 8.889128684997559,
        lat: 45.942924499512
    },
    {
        lng: 8.889535903930664,
        lat: 45.943397521972656
    },
    {
        lng: 8.89033031463623,
        lat: 45.943851470947436
    },
    {
        lng: 8.891839981079102,
        lat: 45.94443893432617
    },
    {
        lng: 8.893851280212402,
        lat: 45.94503402709984
    },
    {
        lng: 8.893855094909725,
        lat: 45.947261810302734
    },
    {
        lng: 8.891647338867244,
        lat: 45.948333740234546
    },
    {
        lng: 8.89116382598877,
        lat: 45.94861984252947
    },
    {
        lng: 8.890776634216252,
        lat: 45.94903945922874
    },
    {
        lng: 8.890105247497615,
        lat: 45.950138092041016
    },
    {
        lng: 8.889471054077148,
        lat: 45.95077896118187
    },
    {
        lng: 8.889230728149471,
        lat: 45.95127487182617
    },
    {
        lng: 8.889122962951603,
        lat: 45.95184326171881
    },
    {
        lng: 8.889056205749569,
        lat: 45.95457458496105
    },
    {
        lng: 8.888882637023926,
        lat: 45.95512008666992
    },
    {
        lng: 8.888714790344295,
        lat: 45.95536804199219
    },
    {
        lng: 8.88846588134777,
        lat: 45.955589294433764
    },
    {
        lng: 8.888133049011287,
        lat: 45.95576477050781
    },
    {
        lng: 8.887758255004996,
        lat: 45.955875396728686
    },
    {
        lng: 8.887358665466252,
        lat: 45.95594787597656
    },
    {
        lng: 8.886508941650334,
        lat: 45.95600509643566
    },
    {
        lng: 8.88467025756836,
        lat: 45.955963134765796
    },
    {
        lng: 8.884009361267147,
        lat: 45.95592880249046
    },
    {
        lng: 8.876893997192496,
        lat: 45.95555114746088
    },
    {
        lng: 8.875513076782283,
        lat: 45.95559310913097
    },
    {
        lng: 8.874638557434139,
        lat: 45.95574951171875
    },
    {
        lng: 8.874260902404785,
        lat: 45.955886840820426
    },
    {
        lng: 8.873623847961426,
        lat: 45.95626449584961
    },
    {
        lng: 8.87317180633545,
        lat: 45.956760406494425
    },
    {
        lng: 8.873043060302734,
        lat: 45.957035064697266
    },
    {
        lng: 8.872942924499455,
        lat: 45.95760345458996
    },
    {
        lng: 8.873002052307186,
        lat: 45.9581832885745
    },
    {
        lng: 8.873494148254451,
        lat: 45.959636688232536
    },
    {
        lng: 8.86868953704834,
        lat: 45.961341857910384
    },
    {
        lng: 8.866373062133846,
        lat: 45.96202468872093
    },
    {
        lng: 8.864604949951172,
        lat: 45.96208953857422
    },
    {
        lng: 8.863746643066406,
        lat: 45.96219253540039
    },
    {
        lng: 8.862567901611385,
        lat: 45.962520599365234
    },
    {
        lng: 8.86150264739996,
        lat: 45.96298217773432
    },
    {
        lng: 8.860792160034293,
        lat: 45.963058471679915
    },
    {
        lng: 8.860102653503475,
        lat: 45.96320343017578
    },
    {
        lng: 8.859796524047852,
        lat: 45.96331024169933
    },
    {
        lng: 8.859253883361873,
        lat: 45.96360015869158
    },
    {
        lng: 8.85881710052496,
        lat: 45.963966369628906
    },
    {
        lng: 8.858649253845272,
        lat: 45.96417617797863
    },
    {
        lng: 8.858413696289062,
        lat: 45.96465301513683
    },
    {
        lng: 8.858274459838924,
        lat: 45.96514511108427
    },
    {
        lng: 8.857545852661133,
        lat: 45.965827941894645
    },
    {
        lng: 8.857199668884391,
        lat: 45.96633911132818
    },
    {
        lng: 8.857121467590446,
        lat: 45.96658325195341
    },
    {
        lng: 8.857089996338004,
        lat: 45.96683120727545
    },
    {
        lng: 8.85716342926031,
        lat: 45.96732711791992
    },
    {
        lng: 8.857495307922477,
        lat: 45.96797180175781
    },
    {
        lng: 8.85751056671154,
        lat: 45.96910858154297
    },
    {
        lng: 8.856727600097656,
        lat: 45.96949005126953
    },
    {
        lng: 8.855669975280819,
        lat: 45.97000885009794
    },
    {
        lng: 8.854961395263729,
        lat: 45.970783233642805
    },
    {
        lng: 8.854291915893668,
        lat: 45.971851348876896
    },
    {
        lng: 8.852657318115291,
        lat: 45.97187805175787
    },
    {
        lng: 8.852422714233342,
        lat: 45.97188186645508
    },
    {
        lng: 8.851579666137695,
        lat: 45.97198867797863
    },
    {
        lng: 8.85087680816656,
        lat: 45.97223281860346
    },
    {
        lng: 8.850500106811637,
        lat: 45.97253799438482
    },
    {
        lng: 8.850234031677303,
        lat: 45.97287750244169
    },
    {
        lng: 8.849394798278752,
        lat: 45.97315216064453
    },
    {
        lng: 8.848908424377441,
        lat: 45.97342681884777
    },
    {
        lng: 8.846804618835563,
        lat: 45.97521591186529
    },
    {
        lng: 8.84624004364008,
        lat: 45.9756965637207
    },
    {
        lng: 8.844971656799373,
        lat: 45.976951599121264
    },
    {
        lng: 8.84333610534668,
        lat: 45.978115081787394
    },
    {
        lng: 8.84241867065441,
        lat: 45.978656768798885
    },
    {
        lng: 8.842018127441406,
        lat: 45.97898864746105
    },
    {
        lng: 8.841558456420842,
        lat: 45.9796981811524
    },
    {
        lng: 8.841512680053825,
        lat: 45.979839324951456
    },
    {
        lng: 8.841098785400504,
        lat: 45.98112487792969
    },
    {
        lng: 8.840321540832576,
        lat: 45.982807159424055
    },
    {
        lng: 8.839946746826286,
        lat: 45.98332595825195
    },
    {
        lng: 8.838951110839844,
        lat: 45.98443984985346
    },
    {
        lng: 8.838361740112305,
        lat: 45.9848518371582
    },
    {
        lng: 8.838241577148494,
        lat: 45.984935760498104
    },
    {
        lng: 8.836744308471793,
        lat: 45.98545455932617
    },
    {
        lng: 8.834774017334098,
        lat: 45.985935211181925
    },
    {
        lng: 8.833289146423397,
        lat: 45.98641204834013
    },
    {
        lng: 8.832799911499023,
        lat: 45.98667144775408
    },
    {
        lng: 8.831350326538143,
        lat: 45.98766708374035
    },
    {
        lng: 8.830634117126522,
        lat: 45.988368988037166
    },
    {
        lng: 8.83011627197277,
        lat: 45.98876953125017
    },
    {
        lng: 8.829472541809196,
        lat: 45.9891166687014
    },
    {
        lng: 8.828779220580998,
        lat: 45.989337921142635
    },
    {
        lng: 8.828643798828125,
        lat: 45.98938369750988
    },
    {
        lng: 8.82716083526617,
        lat: 45.9895668029788
    },
    {
        lng: 8.824543952941838,
        lat: 45.98957443237316
    },
    {
        lng: 8.822831153869686,
        lat: 45.989444732666186
    },
    {
        lng: 8.822428703308105,
        lat: 45.98941421508789
    },
    {
        lng: 8.820452690124625,
        lat: 45.989147186279354
    },
    {
        lng: 8.819235801696891,
        lat: 45.98897933959972
    },
    {
        lng: 8.815346717834473,
        lat: 45.98976516723644
    },
    {
        lng: 8.814794540405387,
        lat: 45.99003219604492
    },
    {
        lng: 8.812656402588004,
        lat: 45.99129867553722
    },
    {
        lng: 8.812049865722656,
        lat: 45.99153518676775
    },
    {
        lng: 8.811649322509822,
        lat: 45.99161911010748
    },
    {
        lng: 8.810297966003475,
        lat: 45.99174880981445
    },
    {
        lng: 8.806827545166016,
        lat: 45.991798400878906
    },
    {
        lng: 8.805352210998649,
        lat: 45.99188613891596
    },
    {
        lng: 8.800918579101676,
        lat: 45.99264144897455
    },
    {
        lng: 8.797270774841422,
        lat: 45.99304199218756
    },
    {
        lng: 8.794938087463379,
        lat: 45.99366760253935
    },
    {
        lng: 8.794100761413631,
        lat: 45.99378204345726
    },
    {
        lng: 8.793245315551758,
        lat: 45.99379348754877
    },
    {
        lng: 8.793162345886287,
        lat: 45.99378204345726
    },
    {
        lng: 8.792401313781795,
        lat: 45.99369430542015
    },
    {
        lng: 8.791996002197322,
        lat: 45.99359130859398
    },
    {
        lng: 8.791287422180233,
        lat: 45.993286132812784
    },
    {
        lng: 8.790478706359863,
        lat: 45.992668151855526
    },
    {
        lng: 8.789820671081543,
        lat: 45.99184036254894
    },
    {
        lng: 8.789323806762752,
        lat: 45.99135589599621
    },
    {
        lng: 8.788718223571891,
        lat: 45.99089813232422
    },
    {
        lng: 8.788003921508903,
        lat: 45.99053955078148
    },
    {
        lng: 8.787597656250114,
        lat: 45.990417480469034
    },
    {
        lng: 8.787143707275334,
        lat: 45.99037551879883
    },
    {
        lng: 8.786954879760856,
        lat: 45.99035644531273
    },
    {
        lng: 8.785510063171444,
        lat: 45.990417480469034
    },
    {
        lng: 8.78513336181635,
        lat: 45.99049758911144
    },
    {
        lng: 8.784415245056152,
        lat: 45.99065399169922
    },
    {
        lng: 8.784235000610295,
        lat: 45.99075317382818
    },
    {
        lng: 8.78385066986084,
        lat: 45.990959167480526
    },
    {
        lng: 8.783564567565918,
        lat: 45.991336822509766
    },
    {
        lng: 8.783529281616325,
        lat: 45.99154281616228
    },
    {
        lng: 8.78360652923584,
        lat: 45.9919052124024
    },
    {
        lng: 8.783907890319824,
        lat: 45.99244689941429
    },
    {
        lng: 8.784752845764274,
        lat: 45.99317932128906
    },
    {
        lng: 8.785079956054744,
        lat: 45.99360275268549
    },
    {
        lng: 8.785176277160701,
        lat: 45.99379730224632
    },
    {
        lng: 8.786205291748047,
        lat: 45.99591827392584
    },
    {
        lng: 8.786905288696232,
        lat: 45.99757003784208
    },
    {
        lng: 8.787564277648926,
        lat: 46.000312805175895
    },
    {
        lng: 8.787928581237793,
        lat: 46.00114440917969
    },
    {
        lng: 8.788588523864746,
        lat: 46.00265121459984
    },
    {
        lng: 8.789264678955078,
        lat: 46.00463867187523
    },
    {
        lng: 8.790322303771916,
        lat: 46.00695800781273
    },
    {
        lng: 8.790887832641715,
        lat: 46.00849533081055
    },
    {
        lng: 8.790924072265625,
        lat: 46.00859069824219
    },
    {
        lng: 8.790987968444824,
        lat: 46.00870132446306
    },
    {
        lng: 8.79148006439209,
        lat: 46.00955581665045
    },
    {
        lng: 8.792705535888672,
        lat: 46.01087951660156
    },
    {
        lng: 8.793328285217399,
        lat: 46.011329650879134
    },
    {
        lng: 8.793708801269645,
        lat: 46.01167297363281
    },
    {
        lng: 8.7945556640625,
        lat: 46.01282501220703
    },
    {
        lng: 8.795189857482967,
        lat: 46.01379776000988
    },
    {
        lng: 8.796803474426326,
        lat: 46.01482391357433
    },
    {
        lng: 8.797193527221737,
        lat: 46.015182495117244
    },
    {
        lng: 8.799146652221737,
        lat: 46.01732254028343
    },
    {
        lng: 8.80007362365717,
        lat: 46.01813507080078
    },
    {
        lng: 8.800389289855957,
        lat: 46.01856994628929
    },
    {
        lng: 8.800890922546387,
        lat: 46.019500732421875
    },
    {
        lng: 8.801880836486873,
        lat: 46.02055358886747
    },
    {
        lng: 8.80236721038824,
        lat: 46.021484375000114
    },
    {
        lng: 8.802679061889648,
        lat: 46.021926879882756
    },
    {
        lng: 8.803438186645565,
        lat: 46.022560119628906
    },
    {
        lng: 8.804165840148926,
        lat: 46.023281097412166
    },
    {
        lng: 8.805024147033691,
        lat: 46.02386856079113
    },
    {
        lng: 8.805732727050724,
        lat: 46.024177551269815
    },
    {
        lng: 8.806123733520621,
        lat: 46.02429199218773
    },
    {
        lng: 8.808176994323787,
        lat: 46.02467346191429
    },
    {
        lng: 8.808978080749569,
        lat: 46.024875640869084
    },
    {
        lng: 8.81385326385498,
        lat: 46.026420593261776
    },
    {
        lng: 8.816416740417537,
        lat: 46.026866912842024
    },
    {
        lng: 8.817413330078125,
        lat: 46.027099609375
    },
    {
        lng: 8.81991004943842,
        lat: 46.027969360351676
    },
    {
        lng: 8.820317268371696,
        lat: 46.02905654907238
    },
    {
        lng: 8.820748329162654,
        lat: 46.02974700927729
    },
    {
        lng: 8.822242736816463,
        lat: 46.030990600586165
    },
    {
        lng: 8.823023796081543,
        lat: 46.03157043457037
    },
    {
        lng: 8.823348999023438,
        lat: 46.03200149536133
    },
    {
        lng: 8.823848724365291,
        lat: 46.03293228149431
    },
    {
        lng: 8.824863433838004,
        lat: 46.03401565551769
    },
    {
        lng: 8.825545310974064,
        lat: 46.035221099853686
    },
    {
        lng: 8.826064109802303,
        lat: 46.03572845459007
    },
    {
        lng: 8.82664680480957,
        lat: 46.03612136840832
    },
    {
        lng: 8.827268600463867,
        lat: 46.03787612915039
    },
    {
        lng: 8.827701568603572,
        lat: 46.04082870483404
    },
    {
        lng: 8.828166007995605,
        lat: 46.04178237915045
    },
    {
        lng: 8.828834533691406,
        lat: 46.04227828979509
    },
    {
        lng: 8.83067417144781,
        lat: 46.04289627075218
    },
    {
        lng: 8.831949234008789,
        lat: 46.04341506958025
    },
    {
        lng: 8.832887649536133,
        lat: 46.043922424316406
    },
    {
        lng: 8.835890769958496,
        lat: 46.045551300049055
    },
    {
        lng: 8.834027290344295,
        lat: 46.046440124511946
    },
    {
        lng: 8.83354663848877,
        lat: 46.04666900634777
    },
    {
        lng: 8.830974578857536,
        lat: 46.04739379882835
    },
    {
        lng: 8.830344200134277,
        lat: 46.04779052734381
    },
    {
        lng: 8.829975128173885,
        lat: 46.04828643798845
    },
    {
        lng: 8.829792976379451,
        lat: 46.048862457275504
    },
    {
        lng: 8.829931259155273,
        lat: 46.0494384765625
    },
    {
        lng: 8.83000469207775,
        lat: 46.0495491027832
    },
    {
        lng: 8.830427169799862,
        lat: 46.05017471313482
    },
    {
        lng: 8.831922531127987,
        lat: 46.051265716552734
    },
    {
        lng: 8.833236694335938,
        lat: 46.05206298828119
    },
    {
        lng: 8.833745956420898,
        lat: 46.05232620239269
    },
    {
        lng: 8.834282875061092,
        lat: 46.052455902099666
    },
    {
        lng: 8.834862709045467,
        lat: 46.0524635314942
    },
    {
        lng: 8.836002349853516,
        lat: 46.05228042602545
    },
    {
        lng: 8.838718414306697,
        lat: 46.05141067504883
    },
    {
        lng: 8.841079711914062,
        lat: 46.051021575927734
    },
    {
        lng: 8.841705322265682,
        lat: 46.05095672607439
    },
    {
        lng: 8.842333793640194,
        lat: 46.05096817016613
    },
    {
        lng: 8.842915534973145,
        lat: 46.05105590820324
    },
    {
        lng: 8.843672752380314,
        lat: 46.051269531250284
    },
    {
        lng: 8.844260215759277,
        lat: 46.051643371582315
    },
    {
        lng: 8.845086097717285,
        lat: 46.0527801513673
    },
    {
        lng: 8.847189903259334,
        lat: 46.05444335937523
    },
    {
        lng: 8.847388267517147,
        lat: 46.05466461181646
    },
    {
        lng: 8.848194122314453,
        lat: 46.05587768554682
    },
    {
        lng: 8.849139213562012,
        lat: 46.05669784545927
    },
    {
        lng: 8.85031986236578,
        lat: 46.058097839355526
    },
    {
        lng: 8.851199150085392,
        lat: 46.05974578857422
    },
    {
        lng: 8.852179527282772,
        lat: 46.06087112426769
    },
    {
        lng: 8.85310077667242,
        lat: 46.06333923339844
    },
    {
        lng: 8.85323524475109,
        lat: 46.064365386962834
    },
    {
        lng: 8.85323524475109,
        lat: 46.06468200683611
    },
    {
        lng: 8.85323619842535,
        lat: 46.0654106140139
    },
    {
        lng: 8.853062629699764,
        lat: 46.066795349121094
    },
    {
        lng: 8.852580070495605,
        lat: 46.06849670410173
    },
    {
        lng: 8.85224723815918,
        lat: 46.07125854492193
    },
    {
        lng: 8.851720809936523,
        lat: 46.07329940795927
    },
    {
        lng: 8.851653099060172,
        lat: 46.074348449707315
    },
    {
        lng: 8.851561546325684,
        lat: 46.07574844360363
    },
    {
        lng: 8.850521087646484,
        lat: 46.07626724243187
    },
    {
        lng: 8.849776268005314,
        lat: 46.07654953002941
    },
    {
        lng: 8.84892940521246,
        lat: 46.07674789428722
    },
    {
        lng: 8.848418235778922,
        lat: 46.07682037353544
    },
    {
        lng: 8.848312377929688,
        lat: 46.07681274414074
    },
    {
        lng: 8.848221778869686,
        lat: 46.07680892944336
    },
    {
        lng: 8.84635925292963,
        lat: 46.077114105224894
    },
    {
        lng: 8.845310211181584,
        lat: 46.07745742797857
    },
    {
        lng: 8.844840049743652,
        lat: 46.077743530273494
    },
    {
        lng: 8.844093322753906,
        lat: 46.078407287597656
    },
    {
        lng: 8.843292236328239,
        lat: 46.079017639160156
    },
    {
        lng: 8.842393875122184,
        lat: 46.07997512817411
    },
    {
        lng: 8.841884613037053,
        lat: 46.08038711547846
    },
    {
        lng: 8.839642524719238,
        lat: 46.08166885375988
    },
    {
        lng: 8.839106559753475,
        lat: 46.08191680908226
    },
    {
        lng: 8.837171554565373,
        lat: 46.08248138427757
    },
    {
        lng: 8.835927009582576,
        lat: 46.083049774169865
    },
    {
        lng: 8.833785057067871,
        lat: 46.08361434936535
    },
    {
        lng: 8.83312797546398,
        lat: 46.08393096923828
    },
    {
        lng: 8.83232784271246,
        lat: 46.08453750610363
    },
    {
        lng: 8.83165454864502,
        lat: 46.08527374267578
    },
    {
        lng: 8.830949783325309,
        lat: 46.085918426513615
    },
    {
        lng: 8.83006381988531,
        lat: 46.08748245239275
    },
    {
        lng: 8.829352378845329,
        lat: 46.08811950683594
    },
    {
        lng: 8.828683853149357,
        lat: 46.088714599609546
    },
    {
        lng: 8.828530311584473,
        lat: 46.088821411132926
    },
    {
        lng: 8.827866554260368,
        lat: 46.08927536010748
    },
    {
        lng: 8.827118873596135,
        lat: 46.08993530273443
    },
    {
        lng: 8.826671600341797,
        lat: 46.090236663818416
    },
    {
        lng: 8.826128005981502,
        lat: 46.090488433837834
    },
    {
        lng: 8.823526382446232,
        lat: 46.091411590576456
    },
    {
        lng: 8.821608543395996,
        lat: 46.09260559082037
    },
    {
        lng: 8.819939613342285,
        lat: 46.09397888183605
    },
    {
        lng: 8.817848205566463,
        lat: 46.09648513793968
    },
    {
        lng: 8.81705284118658,
        lat: 46.09707260131859
    },
    {
        lng: 8.81652641296398,
        lat: 46.09753036499035
    },
    {
        lng: 8.816349983215275,
        lat: 46.09768295288097
    },
    {
        lng: 8.815534591674862,
        lat: 46.09820938110374
    },
    {
        lng: 8.814826965332088,
        lat: 46.09880065917969
    },
    {
        lng: 8.814010620117188,
        lat: 46.099319458007926
    },
    {
        lng: 8.813297271728516,
        lat: 46.09990310668945
    },
    {
        lng: 8.812773704528752,
        lat: 46.1002311706543
    },
    {
        lng: 8.811649322509822,
        lat: 46.10093688964844
    },
    {
        lng: 8.81105709075922,
        lat: 46.1011657714846
    },
    {
        lng: 8.809770584106559,
        lat: 46.101539611816634
    },
    {
        lng: 8.808157920837516,
        lat: 46.10238647460966
    },
    {
        lng: 8.806303977966309,
        lat: 46.10300827026373
    },
    {
        lng: 8.804865837097111,
        lat: 46.102340698242244
    },
    {
        lng: 8.803182601928825,
        lat: 46.101097106933594
    },
    {
        lng: 8.802162170410156,
        lat: 46.09959793090832
    },
    {
        lng: 8.801497459411735,
        lat: 46.0989532470706
    },
    {
        lng: 8.801133155822868,
        lat: 46.09843063354498
    },
    {
        lng: 8.80085754394537,
        lat: 46.09783172607416
    },
    {
        lng: 8.800436973571777,
        lat: 46.09653472900408
    },
    {
        lng: 8.786526679992733,
        lat: 46.09706878662104
    },
    {
        lng: 8.784900665283203,
        lat: 46.09722137451183
    },
    {
        lng: 8.783430099487305,
        lat: 46.09753036499035
    },
    {
        lng: 8.782246589660645,
        lat: 46.09792327880882
    },
    {
        lng: 8.781019210815487,
        lat: 46.09856033325195
    },
    {
        lng: 8.779026031494197,
        lat: 46.09935379028343
    },
    {
        lng: 8.77662277221691,
        lat: 46.10090637207031
    },
    {
        lng: 8.77468299865734,
        lat: 46.101707458496094
    },
    {
        lng: 8.772501945495605,
        lat: 46.10313034057617
    },
    {
        lng: 8.771991729736385,
        lat: 46.1033935546875
    },
    {
        lng: 8.769923210144157,
        lat: 46.103961944580135
    },
    {
        lng: 8.76817321777338,
        lat: 46.1045837402346
    },
    {
        lng: 8.765859603881836,
        lat: 46.10509109497093
    },
    {
        lng: 8.765170097351188,
        lat: 46.105297088623104
    },
    {
        lng: 8.762319564819393,
        lat: 46.10641479492182
    },
    {
        lng: 8.761487007141113,
        lat: 46.106830596923885
    },
    {
        lng: 8.759831428527889,
        lat: 46.10779190063488
    },
    {
        lng: 8.758456230163688,
        lat: 46.10889053344749
    },
    {
        lng: 8.757257461547908,
        lat: 46.1101341247558
    },
    {
        lng: 8.756521224975586,
        lat: 46.11074447631853
    },
    {
        lng: 8.756209373474178,
        lat: 46.111152648926065
    },
    {
        lng: 8.755697250366325,
        lat: 46.11201477050781
    },
    {
        lng: 8.754693984985352,
        lat: 46.11300277709955
    },
    {
        lng: 8.754035949707145,
        lat: 46.11406707763672
    },
    {
        lng: 8.753011703491325,
        lat: 46.1150474548341
    },
    {
        lng: 8.752322196960506,
        lat: 46.116104125976676
    },
    {
        lng: 8.751200675964412,
        lat: 46.11705780029308
    },
    {
        lng: 8.749815940857047,
        lat: 46.118503570556925
    },
    {
        lng: 8.748955726623535,
        lat: 46.119598388671875
    },
    {
        lng: 8.747885704040641,
        lat: 46.12078475952177
    },
    {
        lng: 8.746988296508903,
        lat: 46.12189483642578
    },
    {
        lng: 8.743835449218807,
        lat: 46.1248893737793
    },
    {
        lng: 8.74178695678711,
        lat: 46.12313461303711
    },
    {
        lng: 8.74064731597906,
        lat: 46.12200164794933
    },
    {
        lng: 8.739821434021053,
        lat: 46.121479034424055
    },
    {
        lng: 8.739110946655387,
        lat: 46.120918273925724
    },
    {
        lng: 8.738267898559513,
        lat: 46.12044525146507
    },
    {
        lng: 8.737549781799316,
        lat: 46.11989593505865
    },
    {
        lng: 8.736700057983398,
        lat: 46.11942291259783
    },
    {
        lng: 8.735977172851562,
        lat: 46.118873596191406
    },
    {
        lng: 8.735124588012752,
        lat: 46.1183967590332
    },
    {
        lng: 8.73439979553234,
        lat: 46.1178436279298
    },
    {
        lng: 8.733547210693303,
        lat: 46.1173667907716
    },
    {
        lng: 8.732819557189998,
        lat: 46.116813659668196
    },
    {
        lng: 8.731964111328125,
        lat: 46.11633682250999
    },
    {
        lng: 8.731232643127441,
        lat: 46.11578369140625
    },
    {
        lng: 8.7303724288941,
        lat: 46.11531066894537
    },
    {
        lng: 8.729638099670524,
        lat: 46.114761352539176
    },
    {
        lng: 8.728774070739803,
        lat: 46.11428451538097
    },
    {
        lng: 8.728045463562012,
        lat: 46.11371994018566
    },
    {
        lng: 8.727199554443473,
        lat: 46.1131858825683
    },
    {
        lng: 8.725012779235954,
        lat: 46.110870361328125
    },
    {
        lng: 8.723980903625602,
        lat: 46.11018753051758
    },
    {
        lng: 8.723276138305721,
        lat: 46.109619140625114
    },
    {
        lng: 8.722495079040584,
        lat: 46.109100341796875
    },
    {
        lng: 8.722201347351131,
        lat: 46.108722686767635
    },
    {
        lng: 8.721905708313102,
        lat: 46.10811614990246
    },
    {
        lng: 8.721641540527344,
        lat: 46.107742309570426
    },
    {
        lng: 8.720769882202262,
        lat: 46.107036590576286
    },
    {
        lng: 8.719232559204102,
        lat: 46.105140686035156
    },
    {
        lng: 8.71860313415533,
        lat: 46.104530334472656
    },
    {
        lng: 8.718398094177246,
        lat: 46.10347366333008
    },
    {
        lng: 8.718291282653865,
        lat: 46.10336685180687
    },
    {
        lng: 8.717754364013672,
        lat: 46.10283660888689
    },
    {
        lng: 8.717451095581055,
        lat: 46.10266494751005
    },
    {
        lng: 8.716573715210075,
        lat: 46.10227966308594
    },
    {
        lng: 8.716138839721737,
        lat: 46.10207748413097
    },
    {
        lng: 8.715343475341854,
        lat: 46.101806640625284
    },
    {
        lng: 8.714157104492301,
        lat: 46.10156250000023
    },
    {
        lng: 8.712238311767578,
        lat: 46.10165023803734
    },
    {
        lng: 8.708990097045842,
        lat: 46.10105133056669
    },
    {
        lng: 8.706321716308707,
        lat: 46.100833892822436
    },
    {
        lng: 8.704331398010254,
        lat: 46.10057067871094
    },
    {
        lng: 8.701643943786621,
        lat: 46.100341796875
    },
    {
        lng: 8.698506355285645,
        lat: 46.09989929199247
    },
    {
        lng: 8.697806358337402,
        lat: 46.100002288818644
    },
    {
        lng: 8.696439743041992,
        lat: 46.10034561157232
    },
    {
        lng: 8.695245742797908,
        lat: 46.10060882568382
    },
    {
        lng: 8.693488121032772,
        lat: 46.10047912597685
    },
    {
        lng: 8.686956405639705,
        lat: 46.100002288818644
    },
    {
        lng: 8.685338973998967,
        lat: 46.10003280639643
    },
    {
        lng: 8.683568954467887,
        lat: 46.1003227233889
    },
    {
        lng: 8.68301868438715,
        lat: 46.100547790527514
    },
    {
        lng: 8.681709289550895,
        lat: 46.10130691528332
    },
    {
        lng: 8.681303024292106,
        lat: 46.10162734985374
    },
    {
        lng: 8.680059432983398,
        lat: 46.10326004028332
    },
    {
        lng: 8.679167747497615,
        lat: 46.10483932495134
    },
    {
        lng: 8.677778244018555,
        lat: 46.10688018798851
    },
    {
        lng: 8.676952362060547,
        lat: 46.1075325012207
    },
    {
        lng: 8.676238059997559,
        lat: 46.107822418213175
    },
    {
        lng: 8.67535305023199,
        lat: 46.10796356201172
    },
    {
        lng: 8.675144195556754,
        lat: 46.10796356201172
    },
    {
        lng: 8.674423217773494,
        lat: 46.10796737670904
    },
    {
        lng: 8.6720352172851,
        lat: 46.107719421387
    },
    {
        lng: 8.669203758239746,
        lat: 46.10721206665045
    },
    {
        lng: 8.666130065917912,
        lat: 46.10717773437494
    },
    {
        lng: 8.66490745544445,
        lat: 46.107360839843864
    },
    {
        lng: 8.660132408142204,
        lat: 46.10864257812506
    },
    {
        lng: 8.657677650451717,
        lat: 46.108909606933594
    },
    {
        lng: 8.656927108764592,
        lat: 46.10912322998064
    },
    {
        lng: 8.65600681304943,
        lat: 46.10965347290062
    },
    {
        lng: 8.654350280761719,
        lat: 46.11128997802757
    },
    {
        lng: 8.654066085815373,
        lat: 46.111774444580135
    },
    {
        lng: 8.65364074707037,
        lat: 46.11281967163086
    },
    {
        lng: 8.65271282196045,
        lat: 46.11395263671898
    },
    {
        lng: 8.65227031707758,
        lat: 46.11497879028343
    },
    {
        lng: 8.651971817016658,
        lat: 46.115440368652344
    },
    {
        lng: 8.650530815124625,
        lat: 46.11667251586914
    },
    {
        lng: 8.649704933166618,
        lat: 46.117244720458984
    },
    {
        lng: 8.648487091064396,
        lat: 46.11861419677746
    },
    {
        lng: 8.647820472717399,
        lat: 46.11925125122082
    },
    {
        lng: 8.646688461303711,
        lat: 46.12032699584984
    },
    {
        lng: 8.644954681396541,
        lat: 46.12010955810558
    },
    {
        lng: 8.635845184326286,
        lat: 46.11896896362305
    },
    {
        lng: 8.63494777679449,
        lat: 46.118858337402514
    },
    {
        lng: 8.63134956359869,
        lat: 46.11828231811535
    },
    {
        lng: 8.628351211547965,
        lat: 46.11749649047857
    },
    {
        lng: 8.624457359314022,
        lat: 46.116809844970646
    },
    {
        lng: 8.623032569885197,
        lat: 46.11646652221697
    },
    {
        lng: 8.620003700256348,
        lat: 46.11549758911133
    },
    {
        lng: 8.6193141937257,
        lat: 46.115188598632756
    },
    {
        lng: 8.618281364441032,
        lat: 46.114559173584155
    },
    {
        lng: 8.617459297180176,
        lat: 46.11422729492199
    },
    {
        lng: 8.615530967712516,
        lat: 46.11376953125023
    },
    {
        lng: 8.612969398498535,
        lat: 46.113384246826115
    },
    {
        lng: 8.611491203308105,
        lat: 46.11325454711914
    },
    {
        lng: 8.61058139801031,
        lat: 46.11327743530296
    },
    {
        lng: 8.6101589202882,
        lat: 46.11334991455078
    },
    {
        lng: 8.60959243774414,
        lat: 46.11359405517584
    },
    {
        lng: 8.609174728393612,
        lat: 46.11410140991222
    },
    {
        lng: 8.608993530273494,
        lat: 46.11470413208019
    },
    {
        lng: 8.60862922668457,
        lat: 46.117919921875
    },
    {
        lng: 8.608647346496525,
        lat: 46.11951446533209
    },
    {
        lng: 8.609086036682243,
        lat: 46.124454498291016
    },
    {
        lng: 8.609013557434025,
        lat: 46.12553787231457
    },
    {
        lng: 8.608770370483455,
        lat: 46.12619400024431
    },
    {
        lng: 8.608247756958008,
        lat: 46.12673950195335
    },
    {
        lng: 8.607377052307243,
        lat: 46.1272544860841
    },
    {
        lng: 8.606635093688965,
        lat: 46.1278152465822
    },
    {
        lng: 8.605795860290641,
        lat: 46.12827682495117
    },
    {
        lng: 8.60508728027338,
        lat: 46.12884521484381
    },
    {
        lng: 8.604285240173454,
        lat: 46.12933349609375
    },
    {
        lng: 8.603105545043945,
        lat: 46.13039779663103
    },
    {
        lng: 8.602798461914006,
        lat: 46.130794525146484
    },
    {
        lng: 8.602177619934082,
        lat: 46.132415771484375
    },
    {
        lng: 8.601559638977164,
        lat: 46.13311004638683
    },
    {
        lng: 8.601028442382926,
        lat: 46.13346481323242
    },
    {
        lng: 8.600069046020565,
        lat: 46.133949279785156
    },
    {
        lng: 8.597758293151855,
        lat: 46.135555267333984
    },
    {
        lng: 8.596319198608398,
        lat: 46.136360168456974
    },
    {
        lng: 8.59584045410162,
        lat: 46.13655853271479
    },
    {
        lng: 8.594627380371094,
        lat: 46.13683700561518
    },
    {
        lng: 8.59406757354742,
        lat: 46.13710784912121
    },
    {
        lng: 8.59370040893566,
        lat: 46.137626647949276
    },
    {
        lng: 8.593568801879883,
        lat: 46.13826751708979
    },
    {
        lng: 8.593637466430778,
        lat: 46.139347076416016
    },
    {
        lng: 8.593942642211914,
        lat: 46.14048385620117
    },
    {
        lng: 8.594319343567008,
        lat: 46.1412467956543
    },
    {
        lng: 8.594879150390682,
        lat: 46.141944885253906
    },
    {
        lng: 8.595570564270076,
        lat: 46.14252853393583
    },
    {
        lng: 8.596351623535156,
        lat: 46.143016815185774
    },
    {
        lng: 8.598590850830135,
        lat: 46.14407348632835
    },
    {
        lng: 8.600629806518668,
        lat: 46.1452331542971
    },
    {
        lng: 8.601157188415584,
        lat: 46.14573287963873
    },
    {
        lng: 8.60137081146246,
        lat: 46.14603042602556
    },
    {
        lng: 8.602239608764648,
        lat: 46.14788818359398
    },
    {
        lng: 8.602833747863826,
        lat: 46.148662567138615
    },
    {
        lng: 8.604426383972168,
        lat: 46.1499595642091
    },
    {
        lng: 8.60649299621582,
        lat: 46.15104675292963
    },
    {
        lng: 8.6053981781007,
        lat: 46.15233612060558
    },
    {
        lng: 8.604719161987305,
        lat: 46.15266036987316
    },
    {
        lng: 8.603439331054688,
        lat: 46.152973175048885
    },
    {
        lng: 8.602482795715446,
        lat: 46.15304183959972
    },
    {
        lng: 8.6014661788941,
        lat: 46.1530113220216
    },
    {
        lng: 8.5977401733399,
        lat: 46.1526794433596
    },
    {
        lng: 8.596176147460938,
        lat: 46.152622222900504
    },
    {
        lng: 8.594690322875977,
        lat: 46.152793884277344
    },
    {
        lng: 8.593407630920467,
        lat: 46.15312576293968
    },
    {
        lng: 8.592570304870549,
        lat: 46.153686523437784
    },
    {
        lng: 8.592218399047852,
        lat: 46.15419769287132
    },
    {
        lng: 8.592046737671012,
        lat: 46.154884338378906
    },
    {
        lng: 8.592000007629508,
        lat: 46.15663528442394
    },
    {
        lng: 8.591833114624137,
        lat: 46.15724182128929
    },
    {
        lng: 8.590738296508846,
        lat: 46.15869522094732
    },
    {
        lng: 8.590272903442383,
        lat: 46.159088134765625
    },
    {
        lng: 8.589670181274528,
        lat: 46.15939712524414
    },
    {
        lng: 8.588861465454158,
        lat: 46.15958023071306
    },
    {
        lng: 8.587890625000114,
        lat: 46.15959548950195
    },
    {
        lng: 8.586886405944824,
        lat: 46.15948486328142
    },
    {
        lng: 8.58551025390625,
        lat: 46.15919113159197
    },
    {
        lng: 8.585328102111816,
        lat: 46.159152984619084
    },
    {
        lng: 8.584319114685059,
        lat: 46.159000396728686
    },
    {
        lng: 8.581315040588379,
        lat: 46.158969879150334
    },
    {
        lng: 8.58004379272461,
        lat: 46.159156799316634
    },
    {
        lng: 8.578379631042537,
        lat: 46.160076141357706
    },
    {
        lng: 8.57732295989996,
        lat: 46.161064147949446
    },
    {
        lng: 8.576971054077262,
        lat: 46.16156768798828
    },
    {
        lng: 8.576311111450309,
        lat: 46.165912628174055
    },
    {
        lng: 8.57635879516613,
        lat: 46.16711425781267
    },
    {
        lng: 8.57655334472662,
        lat: 46.16837692260742
    },
    {
        lng: 8.57680892944336,
        lat: 46.170040130615234
    },
    {
        lng: 8.576825141906738,
        lat: 46.172008514404524
    },
    {
        lng: 8.576827049255314,
        lat: 46.17386245727539
    },
    {
        lng: 8.575534820556697,
        lat: 46.174949645996094
    },
    {
        lng: 8.57494831085205,
        lat: 46.175506591797046
    },
    {
        lng: 8.57443809509283,
        lat: 46.176151275634766
    },
    {
        lng: 8.57381629943859,
        lat: 46.17722320556658
    },
    {
        lng: 8.57343769073492,
        lat: 46.17768478393555
    },
    {
        lng: 8.571009635925236,
        lat: 46.1795768737793
    },
    {
        lng: 8.570045471191406,
        lat: 46.18095016479492
    },
    {
        lng: 8.569399833679256,
        lat: 46.18148040771507
    },
    {
        lng: 8.568252563476676,
        lat: 46.1821861267091
    },
    {
        lng: 8.567631721496525,
        lat: 46.18244171142584
    },
    {
        lng: 8.56724643707281,
        lat: 46.182502746582145
    },
    {
        lng: 8.565130233764762,
        lat: 46.18283843994146
    },
    {
        lng: 8.563014984130973,
        lat: 46.18329620361328
    },
    {
        lng: 8.561328887939453,
        lat: 46.18350982666033
    },
    {
        lng: 8.560559272766227,
        lat: 46.18370056152361
    },
    {
        lng: 8.559941291809139,
        lat: 46.18407440185547
    },
    {
        lng: 8.559432029724235,
        lat: 46.18488311767601
    },
    {
        lng: 8.558806419372615,
        lat: 46.18656158447283
    },
    {
        lng: 8.55837440490734,
        lat: 46.18838119506853
    },
    {
        lng: 8.558335304260368,
        lat: 46.18854522705084
    },
    {
        lng: 8.557586669921818,
        lat: 46.19051361083996
    },
    {
        lng: 8.557292938232536,
        lat: 46.19105911254883
    },
    {
        lng: 8.55682468414318,
        lat: 46.19151687622076
    },
    {
        lng: 8.556166648864803,
        lat: 46.19181060791027
    },
    {
        lng: 8.554431915283317,
        lat: 46.19223785400402
    },
    {
        lng: 8.55338287353527,
        lat: 46.19267272949236
    },
    {
        lng: 8.553248405456657,
        lat: 46.19269561767578
    },
    {
        lng: 8.55153846740717,
        lat: 46.19301605224604
    },
    {
        lng: 8.550842285156307,
        lat: 46.19319915771479
    },
    {
        lng: 8.547884941101188,
        lat: 46.19466781616228
    },
    {
        lng: 8.545409202575684,
        lat: 46.195236206054744
    },
    {
        lng: 8.54371356964117,
        lat: 46.19618225097662
    },
    {
        lng: 8.543052673339957,
        lat: 46.19673156738304
    },
    {
        lng: 8.541604995727653,
        lat: 46.19852828979515
    },
    {
        lng: 8.540843009948844,
        lat: 46.200458526611555
    },
    {
        lng: 8.539751052856445,
        lat: 46.202247619629134
    },
    {
        lng: 8.53883361816412,
        lat: 46.204486846923885
    },
    {
        lng: 8.537873268127498,
        lat: 46.20595169067383
    },
    {
        lng: 8.536938667297363,
        lat: 46.208179473876896
    },
    {
        lng: 8.535832405090332,
        lat: 46.20995330810575
    },
    {
        lng: 8.535588264465275,
        lat: 46.21062088012724
    },
    {
        lng: 8.535119056701603,
        lat: 46.21190643310547
    },
    {
        lng: 8.53383636474615,
        lat: 46.21496963500982
    },
    {
        lng: 8.533596038818416,
        lat: 46.216163635254134
    },
    {
        lng: 8.53309631347662,
        lat: 46.21987152099638
    },
    {
        lng: 8.531959533691463,
        lat: 46.220115661621094
    },
    {
        lng: 8.531073570251465,
        lat: 46.220306396484375
    },
    {
        lng: 8.529648780822754,
        lat: 46.22088623046875
    },
    {
        lng: 8.528754234314079,
        lat: 46.221103668213004
    },
    {
        lng: 8.527475357055778,
        lat: 46.22130966186552
    },
    {
        lng: 8.524580955505314,
        lat: 46.22150802612316
    },
    {
        lng: 8.523039817810002,
        lat: 46.221828460693416
    },
    {
        lng: 8.522271156311092,
        lat: 46.221916198730696
    },
    {
        lng: 8.521489143371582,
        lat: 46.221824645996094
    },
    {
        lng: 8.520786285400447,
        lat: 46.22158050537121
    },
    {
        lng: 8.519272804260197,
        lat: 46.22085952758812
    },
    {
        lng: 8.518081665039176,
        lat: 46.2200736999514
    },
    {
        lng: 8.517344474792537,
        lat: 46.219764709472656
    },
    {
        lng: 8.516508102417106,
        lat: 46.21954345703125
    },
    {
        lng: 8.51563835144043,
        lat: 46.219402313232706
    },
    {
        lng: 8.514386177063102,
        lat: 46.21936416625982
    },
    {
        lng: 8.514017105102539,
        lat: 46.219413757324446
    },
    {
        lng: 8.512683868408146,
        lat: 46.21979141235363
    },
    {
        lng: 8.510802268982047,
        lat: 46.21998596191429
    },
    {
        lng: 8.510059356689453,
        lat: 46.22015380859375
    },
    {
        lng: 8.509112358093319,
        lat: 46.220569610595646
    },
    {
        lng: 8.507966041564998,
        lat: 46.221275329590014
    },
    {
        lng: 8.507332801818848,
        lat: 46.221446990966854
    },
    {
        lng: 8.505982398986816,
        lat: 46.22165679931658
    },
    {
        lng: 8.505444526672306,
        lat: 46.22184371948248
    },
    {
        lng: 8.50389289855957,
        lat: 46.22256851196312
    },
    {
        lng: 8.503108024597111,
        lat: 46.22306442260742
    },
    {
        lng: 8.501302719116211,
        lat: 46.223884582519815
    },
    {
        lng: 8.500657081604004,
        lat: 46.22402191162115
    },
    {
        lng: 8.498938560485897,
        lat: 46.224239349365405
    },
    {
        lng: 8.497625350952262,
        lat: 46.22463989257841
    },
    {
        lng: 8.495858192443848,
        lat: 46.22481918334961
    },
    {
        lng: 8.494552612304744,
        lat: 46.22522354125999
    },
    {
        lng: 8.492476463317985,
        lat: 46.22547912597673
    },
    {
        lng: 8.490800857544002,
        lat: 46.22593307495117
    },
    {
        lng: 8.49002838134777,
        lat: 46.22598648071312
    },
    {
        lng: 8.487234115600586,
        lat: 46.22599411010765
    },
    {
        lng: 8.48620414733881,
        lat: 46.225936889648494
    },
    {
        lng: 8.484597206115723,
        lat: 46.22574234008789
    },
    {
        lng: 8.478568077087516,
        lat: 46.224788665771484
    },
    {
        lng: 8.477564811706486,
        lat: 46.22468566894531
    },
    {
        lng: 8.475346565246582,
        lat: 46.224582672119084
    },
    {
        lng: 8.47182559967041,
        lat: 46.2242546081543
    },
    {
        lng: 8.470433235168514,
        lat: 46.22430038452171
    },
    {
        lng: 8.467902183532829,
        lat: 46.22474670410179
    },
    {
        lng: 8.466622352600211,
        lat: 46.2251815795899
    },
    {
        lng: 8.464644432067985,
        lat: 46.225509643554915
    },
    {
        lng: 8.464351654052791,
        lat: 46.22564315795904
    },
    {
        lng: 8.463932037353572,
        lat: 46.22608184814476
    },
    {
        lng: 8.463721275329533,
        lat: 46.226646423339844
    },
    {
        lng: 8.463470458984432,
        lat: 46.22824859619152
    },
    {
        lng: 8.463093757629508,
        lat: 46.229625701904524
    },
    {
        lng: 8.4628067016601,
        lat: 46.231224060058594
    },
    {
        lng: 8.462411880493278,
        lat: 46.232608795166016
    },
    {
        lng: 8.462064743042049,
        lat: 46.23498916625982
    },
    {
        lng: 8.461856842041072,
        lat: 46.23561859130882
    },
    {
        lng: 8.461092948913631,
        lat: 46.23695755004894
    },
    {
        lng: 8.460922241210938,
        lat: 46.237514495849894
    },
    {
        lng: 8.460822105407829,
        lat: 46.23838806152355
    },
    {
        lng: 8.45939922332775,
        lat: 46.23863220214861
    },
    {
        lng: 8.457247734069881,
        lat: 46.2388267517091
    },
    {
        lng: 8.455162048339787,
        lat: 46.2391242980957
    },
    {
        lng: 8.452636718750114,
        lat: 46.23917770385765
    },
    {
        lng: 8.450855255127067,
        lat: 46.23957061767578
    },
    {
        lng: 8.448301315307617,
        lat: 46.23958969116222
    },
    {
        lng: 8.446133613586369,
        lat: 46.239768981933594
    },
    {
        lng: 8.442763328552246,
        lat: 46.23955535888666
    },
    {
        lng: 8.440540313720817,
        lat: 46.23966598510742
    },
    {
        lng: 8.439899444580135,
        lat: 46.2398376464846
    },
    {
        lng: 8.439416885375977,
        lat: 46.240123748779524
    },
    {
        lng: 8.439043045043945,
        lat: 46.240585327148665
    },
    {
        lng: 8.438544273376408,
        lat: 46.24154281616222
    },
    {
        lng: 8.437681198120174,
        lat: 46.24231719970726
    },
    {
        lng: 8.43740463256836,
        lat: 46.242839813232536
    },
    {
        lng: 8.43730831146246,
        lat: 46.243812561035156
    },
    {
        lng: 8.437513351440487,
        lat: 46.244911193847656
    },
    {
        lng: 8.437868118286076,
        lat: 46.24568176269531
    },
    {
        lng: 8.438447952270508,
        lat: 46.24644470214844
    },
    {
        lng: 8.439550399780273,
        lat: 46.2475395202639
    },
    {
        lng: 8.439985275268612,
        lat: 46.24792861938499
    },
    {
        lng: 8.441532135009709,
        lat: 46.24932098388689
    },
    {
        lng: 8.442599296569938,
        lat: 46.25040435791027
    },
    {
        lng: 8.443940162658635,
        lat: 46.25228500366211
    },
    {
        lng: 8.445565223693904,
        lat: 46.25455856323259
    },
    {
        lng: 8.446378707885742,
        lat: 46.255481719970646
    },
    {
        lng: 8.447061538696289,
        lat: 46.25605010986351
    },
    {
        lng: 8.448673248291072,
        lat: 46.257087707519645
    },
    {
        lng: 8.449727058410758,
        lat: 46.257904052734546
    },
    {
        lng: 8.451310157775936,
        lat: 46.25889968872082
    },
    {
        lng: 8.451962471008358,
        lat: 46.25945281982422
    },
    {
        lng: 8.452472686767578,
        lat: 46.26008605957037
    },
    {
        lng: 8.452644348144645,
        lat: 46.260421752929915
    },
    {
        lng: 8.452753067016602,
        lat: 46.260753631591854
    },
    {
        lng: 8.452818870544547,
        lat: 46.26138305664085
    },
    {
        lng: 8.452693939209041,
        lat: 46.261940002441634
    },
    {
        lng: 8.452556610107536,
        lat: 46.26217651367193
    },
    {
        lng: 8.451988220214844,
        lat: 46.26272583007835
    },
    {
        lng: 8.45172214508068,
        lat: 46.26298522949247
    },
    {
        lng: 8.451004981994572,
        lat: 46.26461029052757
    },
    {
        lng: 8.450035095214844,
        lat: 46.26590347290045
    },
    {
        lng: 8.448541641235295,
        lat: 46.268230438232706
    },
    {
        lng: 8.44783020019537,
        lat: 46.270381927490234
    },
    {
        lng: 8.446565628051815,
        lat: 46.272071838379134
    },
    {
        lng: 8.446213722229118,
        lat: 46.272441864013786
    },
    {
        lng: 8.445189476013297,
        lat: 46.27318954467802
    },
    {
        lng: 8.442918777465877,
        lat: 46.27568435668957
    },
    {
        lng: 8.442031860351562,
        lat: 46.27618789672857
    },
    {
        lng: 8.44126033782959,
        lat: 46.276748657226506
    },
    {
        lng: 8.440340995788688,
        lat: 46.27717208862316
    },
    {
        lng: 8.439554214477653,
        lat: 46.27770233154291
    },
    {
        lng: 8.438632011413688,
        lat: 46.278118133545036
    },
    {
        lng: 8.437856674194393,
        lat: 46.27867507934576
    },
    {
        lng: 8.436964035034293,
        lat: 46.279170989990405
    },
    {
        lng: 8.434684753418026,
        lat: 46.28165054321306
    },
    {
        lng: 8.432133674621639,
        lat: 46.28315734863287
    },
    {
        lng: 8.431735038757438,
        lat: 46.28346252441423
    },
    {
        lng: 8.431012153625602,
        lat: 46.28480911254894
    },
    {
        lng: 8.430889129638672,
        lat: 46.2855033874514
    },
    {
        lng: 8.43082141876232,
        lat: 46.288143157958984
    },
    {
        lng: 8.43065452575695,
        lat: 46.28873062133789
    },
    {
        lng: 8.430205345153809,
        lat: 46.28965377807634
    },
    {
        lng: 8.429897308349553,
        lat: 46.290039062500114
    },
    {
        lng: 8.428944587707633,
        lat: 46.2907829284668
    },
    {
        lng: 8.428442955017033,
        lat: 46.291732788085994
    },
    {
        lng: 8.428093910217399,
        lat: 46.292133331299
    },
    {
        lng: 8.425470352172795,
        lat: 46.293453216552734
    },
    {
        lng: 8.425275802612362,
        lat: 46.29365539550787
    },
    {
        lng: 8.425045013427791,
        lat: 46.294174194336165
    },
    {
        lng: 8.42494201660162,
        lat: 46.295066833496094
    },
    {
        lng: 8.425035476684684,
        lat: 46.295871734619254
    },
    {
        lng: 8.42535209655773,
        lat: 46.29645538330078
    },
    {
        lng: 8.425903320312557,
        lat: 46.297027587890625
    },
    {
        lng: 8.426624298095817,
        lat: 46.29755401611328
    },
    {
        lng: 8.429574966430721,
        lat: 46.29949188232422
    },
    {
        lng: 8.4308500289917,
        lat: 46.30033111572271
    },
    {
        lng: 8.432460784912166,
        lat: 46.30157470703142
    },
    {
        lng: 8.435065269470329,
        lat: 46.30308151245123
    },
    {
        lng: 8.437075614929313,
        lat: 46.30460739135748
    },
    {
        lng: 8.43883037567133,
        lat: 46.305732727050724
    },
    {
        lng: 8.440201759338436,
        lat: 46.30660629272461
    },
    {
        lng: 8.438893318176213,
        lat: 46.308601379394474
    },
    {
        lng: 8.438879966735954,
        lat: 46.308887481689396
    },
    {
        lng: 8.438837051391602,
        lat: 46.309860229492244
    },
    {
        lng: 8.439042091369629,
        lat: 46.310676574707145
    },
    {
        lng: 8.43981742858898,
        lat: 46.311859130859375
    },
    {
        lng: 8.44050312042242,
        lat: 46.31331253051775
    },
    {
        lng: 8.440620422363395,
        lat: 46.3138885498048
    },
    {
        lng: 8.44072628021246,
        lat: 46.31591033935547
    },
    {
        lng: 8.441140174865723,
        lat: 46.31744003295927
    },
    {
        lng: 8.441557884216422,
        lat: 46.3181724548341
    },
    {
        lng: 8.44226455688488,
        lat: 46.318759918213004
    },
    {
        lng: 8.443518638610783,
        lat: 46.31980133056652
    },
    {
        lng: 8.444658279419002,
        lat: 46.32041168212902
    },
    {
        lng: 8.44526481628418,
        lat: 46.32059860229515
    },
    {
        lng: 8.446162223815975,
        lat: 46.32087707519554
    },
    {
        lng: 8.448921203613281,
        lat: 46.32139587402344
    },
    {
        lng: 8.449993133544922,
        lat: 46.321636199951456
    },
    {
        lng: 8.450881004333553,
        lat: 46.32193374633812
    },
    {
        lng: 8.451839447021428,
        lat: 46.32243728637695
    },
    {
        lng: 8.452532768249625,
        lat: 46.32301712036133
    },
    {
        lng: 8.453826904296818,
        lat: 46.3246231079101
    },
    {
        lng: 8.454524993896484,
        lat: 46.32608413696306
    },
    {
        lng: 8.454855918884334,
        lat: 46.3265190124514
    },
    {
        lng: 8.456541061401424,
        lat: 46.32775497436535
    },
    {
        lng: 8.458280563354549,
        lat: 46.32869338989286
    },
    {
        lng: 8.45887756347662,
        lat: 46.32895278930681
    },
    {
        lng: 8.46033573150629,
        lat: 46.32940673828125
    },
    {
        lng: 8.460991859436092,
        lat: 46.329696655273494
    },
    {
        lng: 8.4614515304566,
        lat: 46.330074310302734
    },
    {
        lng: 8.462266921997013,
        lat: 46.33108520507824
    },
    {
        lng: 8.463302612304744,
        lat: 46.33189392089844
    },
    {
        lng: 8.464923858642635,
        lat: 46.333660125732536
    },
    {
        lng: 8.465609550476074,
        lat: 46.334995269775504
    },
    {
        lng: 8.46573352813732,
        lat: 46.33568572998041
    },
    {
        lng: 8.46566295623785,
        lat: 46.33614730834972
    },
    {
        lng: 8.46514797210699,
        lat: 46.337604522705135
    },
    {
        lng: 8.464568138122615,
        lat: 46.34040451049805
    },
    {
        lng: 8.464924812316951,
        lat: 46.34310150146496
    },
    {
        lng: 8.46577358245861,
        lat: 46.34575271606445
    },
    {
        lng: 8.465476036071777,
        lat: 46.34619903564453
    },
    {
        lng: 8.464932441711483,
        lat: 46.34701156616222
    },
    {
        lng: 8.464216232299862,
        lat: 46.34860610961914
    },
    {
        lng: 8.462660789489803,
        lat: 46.35129165649414
    },
    {
        lng: 8.462457656860295,
        lat: 46.35248565673828
    },
    {
        lng: 8.46265888214117,
        lat: 46.35370635986351
    },
    {
        lng: 8.46277046203619,
        lat: 46.35391616821289
    },
    {
        lng: 8.46296501159668,
        lat: 46.354282379150334
    },
    {
        lng: 8.463738441467399,
        lat: 46.35533523559593
    },
    {
        lng: 8.464627265930119,
        lat: 46.35686492919922
    },
    {
        lng: 8.465065956115836,
        lat: 46.357261657714844
    },
    {
        lng: 8.466625213623104,
        lat: 46.35834884643555
    },
    {
        lng: 8.467637062072754,
        lat: 46.358894348144645
    },
    {
        lng: 8.469763755798397,
        lat: 46.36028671264671
    },
    {
        lng: 8.471031188964844,
        lat: 46.36160278320324
    },
    {
        lng: 8.471403121948242,
        lat: 46.36213302612322
    },
    {
        lng: 8.471597671508903,
        lat: 46.36269760131836
    },
    {
        lng: 8.471615791320858,
        lat: 46.3632698059082
    },
    {
        lng: 8.471341133117676,
        lat: 46.364093780517805
    },
    {
        lng: 8.4701442718507,
        lat: 46.365501403808594
    },
    {
        lng: 8.469416618347168,
        lat: 46.36659240722656
    },
    {
        lng: 8.469153404235897,
        lat: 46.367122650146484
    },
    {
        lng: 8.468973159790039,
        lat: 46.367771148681754
    },
    {
        lng: 8.468811035156307,
        lat: 46.369819641113224
    },
    {
        lng: 8.468817710876522,
        lat: 46.37821960449213
    },
    {
        lng: 8.468755722045955,
        lat: 46.379253387451286
    },
    {
        lng: 8.468544006347656,
        lat: 46.380241394043026
    },
    {
        lng: 8.468499183654899,
        lat: 46.380332946777344
    },
    {
        lng: 8.468280792236328,
        lat: 46.380764007568644
    },
    {
        lng: 8.467699050903377,
        lat: 46.38156509399414
    },
    {
        lng: 8.467333793640194,
        lat: 46.38191604614269
    },
    {
        lng: 8.466287612914982,
        lat: 46.382671356201456
    },
    {
        lng: 8.465446472168082,
        lat: 46.38368988037121
    },
    {
        lng: 8.464632987976074,
        lat: 46.3841629028322
    },
    {
        lng: 8.462525367736873,
        lat: 46.38539505004883
    },
    {
        lng: 8.46231555938732,
        lat: 46.3856086730957
    },
    {
        lng: 8.462062835693416,
        lat: 46.38611221313488
    },
    {
        lng: 8.461995124816895,
        lat: 46.3869514465332
    },
    {
        lng: 8.462265014648438,
        lat: 46.387783050537166
    },
    {
        lng: 8.462449073791447,
        lat: 46.388038635254134
    },
    {
        lng: 8.463425636291504,
        lat: 46.38896179199219
    },
    {
        lng: 8.463854789733887,
        lat: 46.38954544067394
    },
    {
        lng: 8.464459419250488,
        lat: 46.390369415283374
    },
    {
        lng: 8.465576171875057,
        lat: 46.39125442504911
    },
    {
        lng: 8.46811294555664,
        lat: 46.393699645996264
    },
    {
        lng: 8.470270156860465,
        lat: 46.39510345459007
    },
    {
        lng: 8.471219062805233,
        lat: 46.39563369751005
    },
    {
        lng: 8.471563339233398,
        lat: 46.395965576171875
    },
    {
        lng: 8.471711158752498,
        lat: 46.39634704589844
    },
    {
        lng: 8.471660614013615,
        lat: 46.3967094421389
    },
    {
        lng: 8.47137546539318,
        lat: 46.39726257324247
    },
    {
        lng: 8.470257759094295,
        lat: 46.39816665649414
    },
    {
        lng: 8.46979808807373,
        lat: 46.39875411987322
    },
    {
        lng: 8.469438552856502,
        lat: 46.39921188354498
    },
    {
        lng: 8.468997955322209,
        lat: 46.399539947509766
    },
    {
        lng: 8.46615982055664,
        lat: 46.40110778808588
    },
    {
        lng: 8.46468257904047,
        lat: 46.40254211425804
    },
    {
        lng: 8.464365005493107,
        lat: 46.40328216552757
    },
    {
        lng: 8.46442794799799,
        lat: 46.404022216796875
    },
    {
        lng: 8.464668273925895,
        lat: 46.40478897094755
    },
    {
        lng: 8.465043067932186,
        lat: 46.40597915649414
    },
    {
        lng: 8.46530723571783,
        lat: 46.40658187866228
    },
    {
        lng: 8.466075897216854,
        lat: 46.407798767089844
    },
    {
        lng: 8.466683387756348,
        lat: 46.40856170654297
    },
    {
        lng: 8.468338012695256,
        lat: 46.41019439697271
    },
    {
        lng: 8.469430923461971,
        lat: 46.41107940673828
    },
    {
        lng: 8.469791412353516,
        lat: 46.41160583496094
    },
    {
        lng: 8.469979286193904,
        lat: 46.41218566894531
    },
    {
        lng: 8.470034599304256,
        lat: 46.41279220581066
    },
    {
        lng: 8.46996116638195,
        lat: 46.41341018676752
    },
    {
        lng: 8.469731330871696,
        lat: 46.41400909423834
    },
    {
        lng: 8.467888832092342,
        lat: 46.416152954101676
    },
    {
        lng: 8.46686172485363,
        lat: 46.41691970825218
    },
    {
        lng: 8.464067459106502,
        lat: 46.41837692260742
    },
    {
        lng: 8.463871955871525,
        lat: 46.418476104736385
    },
    {
        lng: 8.461580276489258,
        lat: 46.419464111328125
    },
    {
        lng: 8.459205627441406,
        lat: 46.4202995300293
    },
    {
        lng: 8.458634376525822,
        lat: 46.42064285278332
    },
    {
        lng: 8.456463813781738,
        lat: 46.4233093261721
    },
    {
        lng: 8.456149101257438,
        lat: 46.42420959472656
    },
    {
        lng: 8.456183433532772,
        lat: 46.42514419555687
    },
    {
        lng: 8.456591606140137,
        lat: 46.42603683471697
    },
    {
        lng: 8.457091331481934,
        lat: 46.42649841308594
    },
    {
        lng: 8.457468986511287,
        lat: 46.42675399780268
    },
    {
        lng: 8.45987796783453,
        lat: 46.42838668823265
    },
    {
        lng: 8.46031188964838,
        lat: 46.42892837524437
    },
    {
        lng: 8.460539817810059,
        lat: 46.42952728271479
    },
    {
        lng: 8.460617065429744,
        lat: 46.43046951293968
    },
    {
        lng: 8.460510253906193,
        lat: 46.43109512329113
    },
    {
        lng: 8.459113121032829,
        lat: 46.433734893798885
    },
    {
        lng: 8.458820343017635,
        lat: 46.434715270996264
    },
    {
        lng: 8.458792686462402,
        lat: 46.436790466308594
    },
    {
        lng: 8.459005355834961,
        lat: 46.43780517578148
    },
    {
        lng: 8.459310531616154,
        lat: 46.43845367431669
    },
    {
        lng: 8.459541320800895,
        lat: 46.438758850097884
    },
    {
        lng: 8.460135459899902,
        lat: 46.43925476074213
    },
    {
        lng: 8.461974143982047,
        lat: 46.44032287597662
    },
    {
        lng: 8.464928627014103,
        lat: 46.441543579101506
    },
    {
        lng: 8.46591663360607,
        lat: 46.44203948974615
    },
    {
        lng: 8.466823577880916,
        lat: 46.44258499145502
    },
    {
        lng: 8.467520713806266,
        lat: 46.44313049316406
    },
    {
        lng: 8.468280792236328,
        lat: 46.44389343261719
    },
    {
        lng: 8.468942642211857,
        lat: 46.44462585449236
    },
    {
        lng: 8.469360351562557,
        lat: 46.445232391357706
    },
    {
        lng: 8.46990871429449,
        lat: 46.44641113281273
    },
    {
        lng: 8.47005653381359,
        lat: 46.44689178466797
    },
    {
        lng: 8.470051765441951,
        lat: 46.44730377197283
    },
    {
        lng: 8.469615936279297,
        lat: 46.44781112670921
    },
    {
        lng: 8.468190193176326,
        lat: 46.448448181152514
    },
    {
        lng: 8.467639923095817,
        lat: 46.44882965087908
    },
    {
        lng: 8.46730041503912,
        lat: 46.44931030273449
    },
    {
        lng: 8.467258453369254,
        lat: 46.4495925903322
    },
    {
        lng: 8.467438697814941,
        lat: 46.45019149780302
    },
    {
        lng: 8.467926025390739,
        lat: 46.450809478759766
    },
    {
        lng: 8.468693733215332,
        lat: 46.45138931274414
    },
    {
        lng: 8.46934032440197,
        lat: 46.451969146728686
    },
    {
        lng: 8.464392662048397,
        lat: 46.45253753662115
    },
    {
        lng: 8.461178779602164,
        lat: 46.45278930664057
    },
    {
        lng: 8.460105895996207,
        lat: 46.452873229980696
    },
    {
        lng: 8.457788467407283,
        lat: 46.45297622680687
    },
    {
        lng: 8.45557880401617,
        lat: 46.453125000000114
    },
    {
        lng: 8.453354835510254,
        lat: 46.453205108642635
    },
    {
        lng: 8.45240306854248,
        lat: 46.453578948974666
    },
    {
        lng: 8.451587677002067,
        lat: 46.453918457031364
    },
    {
        lng: 8.450262069702205,
        lat: 46.45479202270502
    },
    {
        lng: 8.450128555297852,
        lat: 46.45496368408226
    },
    {
        lng: 8.450004577636832,
        lat: 46.455375671386946
    },
    {
        lng: 8.450119018554744,
        lat: 46.45704269409191
    },
    {
        lng: 8.450092315673942,
        lat: 46.457508087158374
    },
    {
        lng: 8.449924468994084,
        lat: 46.45794296264671
    },
    {
        lng: 8.4495592117309,
        lat: 46.45830535888683
    },
    {
        lng: 8.44906044006359,
        lat: 46.458591461181754
    },
    {
        lng: 8.44782543182373,
        lat: 46.4590301513673
    },
    {
        lng: 8.447113037109489,
        lat: 46.45942306518555
    },
    {
        lng: 8.44631576538086,
        lat: 46.459762573242415
    },
    {
        lng: 8.445616722106877,
        lat: 46.46017456054699
    },
    {
        lng: 8.445158958435115,
        lat: 46.46033477783226
    },
    {
        lng: 8.444568634033317,
        lat: 46.46042251586937
    },
    {
        lng: 8.443538665771541,
        lat: 46.460388183594034
    },
    {
        lng: 8.441182136535588,
        lat: 46.46006774902344
    },
    {
        lng: 8.439314842224064,
        lat: 46.459953308105696
    },
    {
        lng: 8.438006401062069,
        lat: 46.46000671386747
    },
    {
        lng: 8.436155319213924,
        lat: 46.460384368896484
    },
    {
        lng: 8.435378074645996,
        lat: 46.46046829223644
    },
    {
        lng: 8.434413909912166,
        lat: 46.460388183594034
    },
    {
        lng: 8.433355331421012,
        lat: 46.4601936340332
    },
    {
        lng: 8.431018829345817,
        lat: 46.459484100341854
    },
    {
        lng: 8.428002357482967,
        lat: 46.45879745483393
    },
    {
        lng: 8.425946235656738,
        lat: 46.45817565917986
    },
    {
        lng: 8.423330307006836,
        lat: 46.45762634277344
    },
    {
        lng: 8.420830726623649,
        lat: 46.45691680908209
    },
    {
        lng: 8.417814254760799,
        lat: 46.456249237060604
    },
    {
        lng: 8.414959907531795,
        lat: 46.45523452758789
    },
    {
        lng: 8.41364765167242,
        lat: 46.45462799072271
    },
    {
        lng: 8.412931442260856,
        lat: 46.4544029235841
    },
    {
        lng: 8.409337043762207,
        lat: 46.453678131103516
    },
    {
        lng: 8.405419349670353,
        lat: 46.45324325561552
    },
    {
        lng: 8.404030799865836,
        lat: 46.4529838562014
    },
    {
        lng: 8.403301239013729,
        lat: 46.4527206420899
    },
    {
        lng: 8.402775764465389,
        lat: 46.45243453979498
    },
    {
        lng: 8.40193080902094,
        lat: 46.451786041259936
    },
    {
        lng: 8.40104961395275,
        lat: 46.45120239257818
    },
    {
        lng: 8.400285720825138,
        lat: 46.45054626464861
    },
    {
        lng: 8.398751258850098,
        lat: 46.44948959350603
    },
    {
        lng: 8.398118972778377,
        lat: 46.449172973632756
    },
    {
        lng: 8.39683914184576,
        lat: 46.44885635376005
    },
    {
        lng: 8.395919799804688,
        lat: 46.448738098144645
    },
    {
        lng: 8.39453983306879,
        lat: 46.44866180419922
    },
    {
        lng: 8.393675804138297,
        lat: 46.44870376586914
    },
    {
        lng: 8.392921447753963,
        lat: 46.44886016845726
    },
    {
        lng: 8.391199111938477,
        lat: 46.449726104736555
    },
    {
        lng: 8.390484809875488,
        lat: 46.45024490356445
    },
    {
        lng: 8.389662742614803,
        lat: 46.450691223144474
    },
    {
        lng: 8.388943672180176,
        lat: 46.45122528076183
    },
    {
        lng: 8.388033866882381,
        lat: 46.45176315307617
    },
    {
        lng: 8.387391090393123,
        lat: 46.45238876342796
    },
    {
        lng: 8.385212898254451,
        lat: 46.4521675109865
    },
    {
        lng: 8.383246421813908,
        lat: 46.451980590820426
    },
    {
        lng: 8.38211727142334,
        lat: 46.452037811279524
    },
    {
        lng: 8.379107475280875,
        lat: 46.4525604248048
    },
    {
        lng: 8.37843227386486,
        lat: 46.452766418457315
    },
    {
        lng: 8.377718925476131,
        lat: 46.453121185302905
    },
    {
        lng: 8.377126693725586,
        lat: 46.45333862304716
    },
    {
        lng: 8.376365661621207,
        lat: 46.45347213745117
    },
    {
        lng: 8.375536918640137,
        lat: 46.45351409912121
    },
    {
        lng: 8.374684333801326,
        lat: 46.453468322754134
    },
    {
        lng: 8.371044158935604,
        lat: 46.45301818847673
    },
    {
        lng: 8.369014739990348,
        lat: 46.452518463134766
    },
    {
        lng: 8.368440628051701,
        lat: 46.452308654785384
    },
    {
        lng: 8.367735862732047,
        lat: 46.45192718505882
    },
    {
        lng: 8.36718463897705,
        lat: 46.451698303222656
    },
    {
        lng: 8.365477561950797,
        lat: 46.45127868652344
    },
    {
        lng: 8.363907814025936,
        lat: 46.450599670410384
    },
    {
        lng: 8.362177848815918,
        lat: 46.45019912719738
    },
    {
        lng: 8.36089897155756,
        lat: 46.44964599609398
    },
    {
        lng: 8.358883857727108,
        lat: 46.44913482666044
    },
    {
        lng: 8.35833644866949,
        lat: 46.4488792419433
    },
    {
        lng: 8.357096672058162,
        lat: 46.44804763793951
    },
    {
        lng: 8.356719017028752,
        lat: 46.44767761230486
    },
    {
        lng: 8.356086730957088,
        lat: 46.44651412963873
    },
    {
        lng: 8.355100631713924,
        lat: 46.44543457031273
    },
    {
        lng: 8.354501724243107,
        lat: 46.444248199463004
    },
    {
        lng: 8.353498458862305,
        lat: 46.44317626953131
    },
    {
        lng: 8.352778434753532,
        lat: 46.4420280456543
    },
    {
        lng: 8.35232162475586,
        lat: 46.44166564941412
    },
    {
        lng: 8.350489616394157,
        lat: 46.440834045410156
    },
    {
        lng: 8.348670959472713,
        lat: 46.44009017944347
    },
    {
        lng: 8.34781455993658,
        lat: 46.43952178955101
    },
    {
        lng: 8.346908569335994,
        lat: 46.43901824951183
    },
    {
        lng: 8.346142768859977,
        lat: 46.438430786132926
    },
    {
        lng: 8.345247268676815,
        lat: 46.43792343139671
    },
    {
        lng: 8.34440135955822,
        lat: 46.437335968017805
    },
    {
        lng: 8.34293460845953,
        lat: 46.436683654785384
    },
    {
        lng: 8.340537071228027,
        lat: 46.43540191650419
    },
    {
        lng: 8.338726997375602,
        lat: 46.43458175659174
    },
    {
        lng: 8.337882041931152,
        lat: 46.433994293213004
    },
    {
        lng: 8.33698654174816,
        lat: 46.4334831237793
    },
    {
        lng: 8.336222648620549,
        lat: 46.43289566040056
    },
    {
        lng: 8.335324287414494,
        lat: 46.432392120361555
    },
    {
        lng: 8.334473609924316,
        lat: 46.43181610107416
    },
    {
        lng: 8.330772399902457,
        lat: 46.430240631103516
    },
    {
        lng: 8.330287933349723,
        lat: 46.42992782592779
    },
    {
        lng: 8.329431533813533,
        lat: 46.4291725158692
    },
    {
        lng: 8.328874588012752,
        lat: 46.42877960205078
    },
    {
        lng: 8.32826137542736,
        lat: 46.42843246459972
    },
    {
        lng: 8.327184677124023,
        lat: 46.42793655395525
    },
    {
        lng: 8.326260566711483,
        lat: 46.427383422851676
    },
    {
        lng: 8.32536983489996,
        lat: 46.42707061767578
    },
    {
        lng: 8.323661804199276,
        lat: 46.426673889160156
    },
    {
        lng: 8.321490287780875,
        lat: 46.42643356323242
    },
    {
        lng: 8.319576263427791,
        lat: 46.42615127563505
    },
    {
        lng: 8.316314697265568,
        lat: 46.425926208496094
    },
    {
        lng: 8.315645217895508,
        lat: 46.42594146728533
    },
    {
        lng: 8.313331604003906,
        lat: 46.42619705200195
    },
    {
        lng: 8.309594154357967,
        lat: 46.426826477051065
    },
    {
        lng: 8.30735778808588,
        lat: 46.42595672607422
    },
    {
        lng: 8.306503295898494,
        lat: 46.42538070678722
    },
    {
        lng: 8.3056001663208,
        lat: 46.424869537353516
    },
    {
        lng: 8.30407905578619,
        lat: 46.42366409301769
    },
    {
        lng: 8.303756713867188,
        lat: 46.423229217529524
    },
    {
        lng: 8.303268432617244,
        lat: 46.42229080200201
    },
    {
        lng: 8.302255630493107,
        lat: 46.42121887207054
    },
    {
        lng: 8.301641464233398,
        lat: 46.42003631591797
    },
    {
        lng: 8.300621032714957,
        lat: 46.41895294189459
    },
    {
        lng: 8.30011081695568,
        lat: 46.41797256469738
    },
    {
        lng: 8.29981613159174,
        lat: 46.417587280273665
    },
    {
        lng: 8.298271179199276,
        lat: 46.41638946533203
    },
    {
        lng: 8.2973890304566,
        lat: 46.415878295898665
    },
    {
        lng: 8.296630859375114,
        lat: 46.41527938842802
    },
    {
        lng: 8.29575252532959,
        lat: 46.41476440429693
    },
    {
        lng: 8.29500675201416,
        lat: 46.41414642334007
    },
    {
        lng: 8.294157028198242,
        lat: 46.41356277465849
    },
    {
        lng: 8.293794631958065,
        lat: 46.413185119628906
    },
    {
        lng: 8.291594505310115,
        lat: 46.41050720214844
    },
    {
        lng: 8.291331291198787,
        lat: 46.41018676757818
    },
    {
        lng: 8.292286872863826,
        lat: 46.409706115722656
    },
    {
        lng: 8.296191215515137,
        lat: 46.40811538696289
    },
    {
        lng: 8.297363281250057,
        lat: 46.40746307373047
    },
    {
        lng: 8.2979993820191,
        lat: 46.407249450683764
    },
    {
        lng: 8.299349784851131,
        lat: 46.40690994262707
    },
    {
        lng: 8.300557136535758,
        lat: 46.4063072204591
    },
    {
        lng: 8.301188468933105,
        lat: 46.406097412109546
    },
    {
        lng: 8.302533149719238,
        lat: 46.40575790405285
    },
    {
        lng: 8.303770065307674,
        lat: 46.40518188476585
    },
    {
        lng: 8.304124832153434,
        lat: 46.40507507324247
    },
    {
        lng: 8.305360794067383,
        lat: 46.404930114746094
    },
    {
        lng: 8.306702613830623,
        lat: 46.4049644470216
    },
    {
        lng: 8.308002471923942,
        lat: 46.405174255371094
    },
    {
        lng: 8.309770584106502,
        lat: 46.40569686889654
    },
    {
        lng: 8.310404777526912,
        lat: 46.405731201171875
    },
    {
        lng: 8.310995101928711,
        lat: 46.405635833740234
    },
    {
        lng: 8.312683105468693,
        lat: 46.40469741821289
    },
    {
        lng: 8.313392639160213,
        lat: 46.40408706665039
    },
    {
        lng: 8.314231872558594,
        lat: 46.40351104736334
    },
    {
        lng: 8.315178871154899,
        lat: 46.402511596679915
    },
    {
        lng: 8.316267967224235,
        lat: 46.40154647827177
    },
    {
        lng: 8.316755294799862,
        lat: 46.40111541748047
    },
    {
        lng: 8.317211151123047,
        lat: 46.4006042480471
    },
    {
        lng: 8.317391395568904,
        lat: 46.40032577514671
    },
    {
        lng: 8.317607879638672,
        lat: 46.399726867675895
    },
    {
        lng: 8.317653656005916,
        lat: 46.39923477172863
    },
    {
        lng: 8.317666053771916,
        lat: 46.39910888671898
    },
    {
        lng: 8.317593574523926,
        lat: 46.39849472045927
    },
    {
        lng: 8.317416191101131,
        lat: 46.39794921875023
    },
    {
        lng: 8.317286491394157,
        lat: 46.397659301757926
    },
    {
        lng: 8.316617012023869,
        lat: 46.39615249633812
    },
    {
        lng: 8.316307067871037,
        lat: 46.39545440673851
    },
    {
        lng: 8.31602668762207,
        lat: 46.394252777099666
    },
    {
        lng: 8.315869331359806,
        lat: 46.39300537109398
    },
    {
        lng: 8.31606674194336,
        lat: 46.392368316650504
    },
    {
        lng: 8.31641769409174,
        lat: 46.39174652099621
    },
    {
        lng: 8.316767692565861,
        lat: 46.39140319824219
    },
    {
        lng: 8.317548751831168,
        lat: 46.3907928466798
    },
    {
        lng: 8.318577766418457,
        lat: 46.389560699462834
    },
    {
        lng: 8.318867683410758,
        lat: 46.389045715332145
    },
    {
        lng: 8.318914413452262,
        lat: 46.388591766357536
    },
    {
        lng: 8.318861007690487,
        lat: 46.38837051391613
    },
    {
        lng: 8.318606376648006,
        lat: 46.38799285888672
    },
    {
        lng: 8.317822456359863,
        lat: 46.387329101562614
    },
    {
        lng: 8.317396163940543,
        lat: 46.38696670532249
    },
    {
        lng: 8.316583633422852,
        lat: 46.38638687133789
    },
    {
        lng: 8.316123008728027,
        lat: 46.38578033447271
    },
    {
        lng: 8.31575965881359,
        lat: 46.385108947753906
    },
    {
        lng: 8.315556526184139,
        lat: 46.384563446045036
    },
    {
        lng: 8.315201759338322,
        lat: 46.38228225708036
    },
    {
        lng: 8.314949035644645,
        lat: 46.38164901733421
    },
    {
        lng: 8.314776420593319,
        lat: 46.38138198852539
    },
    {
        lng: 8.314331054687557,
        lat: 46.38089370727562
    },
    {
        lng: 8.313523292541504,
        lat: 46.380264282226676
    },
    {
        lng: 8.312926292419434,
        lat: 46.37992095947277
    },
    {
        lng: 8.311850547790641,
        lat: 46.379417419433594
    },
    {
        lng: 8.310956001281738,
        lat: 46.37886810302757
    },
    {
        lng: 8.310326576232967,
        lat: 46.378643035888786
    },
    {
        lng: 8.309000968933105,
        lat: 46.37828445434599
    },
    {
        lng: 8.307472229003963,
        lat: 46.377552032470646
    },
    {
        lng: 8.305225372314453,
        lat: 46.377010345459155
    },
    {
        lng: 8.303371429443416,
        lat: 46.37635421752941
    },
    {
        lng: 8.301128387451286,
        lat: 46.37581634521507
    },
    {
        lng: 8.300573348999137,
        lat: 46.375568389892635
    },
    {
        lng: 8.299905776977653,
        lat: 46.37518692016607
    },
    {
        lng: 8.298333168029842,
        lat: 46.374656677246094
    },
    {
        lng: 8.29773235321045,
        lat: 46.37437438964872
    },
    {
        lng: 8.296493530273438,
        lat: 46.37321853637695
    },
    {
        lng: 8.29568862915039,
        lat: 46.37260055541992
    },
    {
        lng: 8.294766426086483,
        lat: 46.37206268310558
    },
    {
        lng: 8.293497085571289,
        lat: 46.371528625488395
    },
    {
        lng: 8.292784690857047,
        lat: 46.37115478515619
    },
    {
        lng: 8.29111480712902,
        lat: 46.37002182006859
    },
    {
        lng: 8.28952503204357,
        lat: 46.36877822875971
    },
    {
        lng: 8.288020133972168,
        lat: 46.367500305176065
    },
    {
        lng: 8.287211418151799,
        lat: 46.36674118041992
    },
    {
        lng: 8.286211967468375,
        lat: 46.36568069458019
    },
    {
        lng: 8.285638809204215,
        lat: 46.36537170410162
    },
    {
        lng: 8.28490352630621,
        lat: 46.36521911621122
    },
    {
        lng: 8.284527778625602,
        lat: 46.36520385742199
    },
    {
        lng: 8.283873558044377,
        lat: 46.36527633666998
    },
    {
        lng: 8.283371925354004,
        lat: 46.365447998046875
    },
    {
        lng: 8.282483100891056,
        lat: 46.365875244140625
    },
    {
        lng: 8.281208992004395,
        lat: 46.36619567871088
    },
    {
        lng: 8.280603408813533,
        lat: 46.36639785766624
    },
    {
        lng: 8.279426574707088,
        lat: 46.36698532104498
    },
    {
        lng: 8.277828216552848,
        lat: 46.36738586425798
    },
    {
        lng: 8.276667594909782,
        lat: 46.36790466308605
    },
    {
        lng: 8.276356697082633,
        lat: 46.3679847717288
    },
    {
        lng: 8.275674819946346,
        lat: 46.36804199218756
    },
    {
        lng: 8.274976730346737,
        lat: 46.3679847717288
    },
    {
        lng: 8.274333953857479,
        lat: 46.36780548095726
    },
    {
        lng: 8.273186683654899,
        lat: 46.36719894409191
    },
    {
        lng: 8.271591186523494,
        lat: 46.366683959961165
    },
    {
        lng: 8.270412445068473,
        lat: 46.36602401733404
    },
    {
        lng: 8.268857955932674,
        lat: 46.36545944213873
    },
    {
        lng: 8.268255233764648,
        lat: 46.365158081054915
    },
    {
        lng: 8.267655372619743,
        lat: 46.36459732055664
    },
    {
        lng: 8.266617774963379,
        lat: 46.3634300231933
    },
    {
        lng: 8.265949249267635,
        lat: 46.36257553100609
    },
    {
        lng: 8.265085220336914,
        lat: 46.36182022094749
    },
    {
        lng: 8.264860153198299,
        lat: 46.361461639404354
    },
    {
        lng: 8.264815330505371,
        lat: 46.36125564575201
    },
    {
        lng: 8.264847755432072,
        lat: 46.36099243164074
    },
    {
        lng: 8.265124320983887,
        lat: 46.36051177978533
    },
    {
        lng: 8.266467094421387,
        lat: 46.359046936035384
    },
    {
        lng: 8.267204284668026,
        lat: 46.35842514038097
    },
    {
        lng: 8.26756477355957,
        lat: 46.35799789428734
    },
    {
        lng: 8.26764106750494,
        lat: 46.35781478881859
    },
    {
        lng: 8.26780796051031,
        lat: 46.35741043090843
    },
    {
        lng: 8.267941474914664,
        lat: 46.35675048828148
    },
    {
        lng: 8.268020629882926,
        lat: 46.35499191284191
    },
    {
        lng: 8.26796722412115,
        lat: 46.354282379150334
    },
    {
        lng: 8.267829895019645,
        lat: 46.353576660156534
    },
    {
        lng: 8.267547607421875,
        lat: 46.35289764404297
    },
    {
        lng: 8.267354011535701,
        lat: 46.352607727050895
    },
    {
        lng: 8.266873359680176,
        lat: 46.35208129882807
    },
    {
        lng: 8.266321182251033,
        lat: 46.351600646972884
    },
    {
        lng: 8.264219284057617,
        lat: 46.35016250610374
    },
    {
        lng: 8.263937950134334,
        lat: 46.34977340698265
    },
    {
        lng: 8.263875961303711,
        lat: 46.349552154541186
    },
    {
        lng: 8.263899803161621,
        lat: 46.349105834961165
    },
    {
        lng: 8.264687538146973,
        lat: 46.34763336181646
    },
    {
        lng: 8.264857292175407,
        lat: 46.346759796142635
    },
    {
        lng: 8.264790534973145,
        lat: 46.34616470336937
    },
    {
        lng: 8.264678001403865,
        lat: 46.345867156982536
    },
    {
        lng: 8.264281272888184,
        lat: 46.345352172851676
    },
    {
        lng: 8.263704299926871,
        lat: 46.344966888427905
    },
    {
        lng: 8.263352394104004,
        lat: 46.34484100341791
    },
    {
        lng: 8.262701034545898,
        lat: 46.344783782959155
    },
    {
        lng: 8.262398719787598,
        lat: 46.3448295593264
    },
    {
        lng: 8.261906623840332,
        lat: 46.345050811767635
    },
    {
        lng: 8.261248588562125,
        lat: 46.3457489013673
    },
    {
        lng: 8.260764122009391,
        lat: 46.34615707397484
    },
    {
        lng: 8.260169982910213,
        lat: 46.34650802612305
    },
    {
        lng: 8.259826660156364,
        lat: 46.34663772583019
    },
    {
        lng: 8.25944995880127,
        lat: 46.34672164917015
    },
    {
        lng: 8.258836746215877,
        lat: 46.34673309326183
    },
    {
        lng: 8.258699417114315,
        lat: 46.34671401977539
    },
    {
        lng: 8.257884979248047,
        lat: 46.346599578857536
    },
    {
        lng: 8.257223129272518,
        lat: 46.34642028808594
    },
    {
        lng: 8.256520271301383,
        lat: 46.346126556396484
    },
    {
        lng: 8.255582809448356,
        lat: 46.345611572265625
    },
    {
        lng: 8.255076408386287,
        lat: 46.34530258178722
    },
    {
        lng: 8.254635810851994,
        lat: 46.34493255615257
    },
    {
        lng: 8.254319190979118,
        lat: 46.34439086914068
    },
    {
        lng: 8.254046440124512,
        lat: 46.34294128417969
    },
    {
        lng: 8.253977775573844,
        lat: 46.34257507324219
    },
    {
        lng: 8.253741264343319,
        lat: 46.34196090698265
    },
    {
        lng: 8.253329277038631,
        lat: 46.34142684936529
    },
    {
        lng: 8.252764701843319,
        lat: 46.34097290039074
    },
    {
        lng: 8.252434730529728,
        lat: 46.34078216552746
    },
    {
        lng: 8.25180435180664,
        lat: 46.34051513671875
    },
    {
        lng: 8.250519752502555,
        lat: 46.340118408203125
    },
    {
        lng: 8.249311447143498,
        lat: 46.33988571167015
    },
    {
        lng: 8.247949600219727,
        lat: 46.339775085449446
    },
    {
        lng: 8.242826461791935,
        lat: 46.339572906494254
    },
    {
        lng: 8.241475105285588,
        lat: 46.339408874511946
    },
    {
        lng: 8.240413665771541,
        lat: 46.339134216308594
    },
    {
        lng: 8.239504814148063,
        lat: 46.33882141113304
    },
    {
        lng: 8.238954544067383,
        lat: 46.338573455810604
    },
    {
        lng: 8.237825393676758,
        lat: 46.33783721923828
    },
    {
        lng: 8.234294891357479,
        lat: 46.33607482910156
    },
    {
        lng: 8.233483314514217,
        lat: 46.335449218750114
    },
    {
        lng: 8.233411788940487,
        lat: 46.33540725708008
    },
    {
        lng: 8.23258972167963,
        lat: 46.334915161132756
    },
    {
        lng: 8.231781959533748,
        lat: 46.33434295654291
    },
    {
        lng: 8.230296134948787,
        lat: 46.33378601074219
    },
    {
        lng: 8.229754447937125,
        lat: 46.333461761474894
    },
    {
        lng: 8.229557037353572,
        lat: 46.33323669433611
    },
    {
        lng: 8.22933387756359,
        lat: 46.3327331542971
    },
    {
        lng: 8.229255676269588,
        lat: 46.33219528198259
    },
    {
        lng: 8.22925090789795,
        lat: 46.331230163574276
    },
    {
        lng: 8.229131698608398,
        lat: 46.33076858520519
    },
    {
        lng: 8.228825569152889,
        lat: 46.33027267456055
    },
    {
        lng: 8.227871894836483,
        lat: 46.32904052734392
    },
    {
        lng: 8.227739334106445,
        lat: 46.32878112792997
    },
    {
        lng: 8.227639198303166,
        lat: 46.328277587890796
    },
    {
        lng: 8.227800369262752,
        lat: 46.32727432250999
    },
    {
        lng: 8.228013992309684,
        lat: 46.3266487121582
    },
    {
        lng: 8.22807598114008,
        lat: 46.326465606689624
    },
    {
        lng: 8.228372573852596,
        lat: 46.326011657714844
    },
    {
        lng: 8.229030609130973,
        lat: 46.32539367675781
    },
    {
        lng: 8.229269027710075,
        lat: 46.324935913085994
    },
    {
        lng: 8.229339599609318,
        lat: 46.32442474365229
    },
    {
        lng: 8.229312896728516,
        lat: 46.324161529541016
    },
    {
        lng: 8.22910404205328,
        lat: 46.32366943359369
    },
    {
        lng: 8.228764533996582,
        lat: 46.32332229614269
    },
    {
        lng: 8.227622032165527,
        lat: 46.32239151001005
    },
    {
        lng: 8.22589302062994,
        lat: 46.3214950561524
    },
    {
        lng: 8.224220275878906,
        lat: 46.32049179077177
    },
    {
        lng: 8.222233772277832,
        lat: 46.319301605224666
    },
    {
        lng: 8.22183704376215,
        lat: 46.31889724731468
    },
    {
        lng: 8.221125602722225,
        lat: 46.317947387695256
    },
    {
        lng: 8.22062969207758,
        lat: 46.31710433959961
    },
    {
        lng: 8.220444679260254,
        lat: 46.316436767578125
    },
    {
        lng: 8.22035884857172,
        lat: 46.315170288086165
    },
    {
        lng: 8.220284461975154,
        lat: 46.31407165527355
    },
    {
        lng: 8.220050811767692,
        lat: 46.31316375732433
    },
    {
        lng: 8.219751358032283,
        lat: 46.31270980834961
    },
    {
        lng: 8.219114303588924,
        lat: 46.31198883056652
    },
    {
        lng: 8.218495368957576,
        lat: 46.31087493896496
    },
    {
        lng: 8.217718124389648,
        lat: 46.308887481689396
    },
    {
        lng: 8.217202186584473,
        lat: 46.3071022033692
    },
    {
        lng: 8.217041015625057,
        lat: 46.30681228637695
    },
    {
        lng: 8.21657752990734,
        lat: 46.306289672851676
    },
    {
        lng: 8.215962409973145,
        lat: 46.30586242675787
    },
    {
        lng: 8.2156019210816,
        lat: 46.30569458007824
    },
    {
        lng: 8.215212821960506,
        lat: 46.30555725097656
    },
    {
        lng: 8.214403152465877,
        lat: 46.305393218994254
    },
    {
        lng: 8.212735176086426,
        lat: 46.305290222168026
    },
    {
        lng: 8.210837364196777,
        lat: 46.305377960205135
    },
    {
        lng: 8.20959377288824,
        lat: 46.3042564392091
    },
    {
        lng: 8.208630561828727,
        lat: 46.30349349975597
    },
    {
        lng: 8.20566177368164,
        lat: 46.30174255371094
    },
    {
        lng: 8.204147338867301,
        lat: 46.30126953125006
    },
    {
        lng: 8.203104019165039,
        lat: 46.30112838745117
    },
    {
        lng: 8.202375411987418,
        lat: 46.30113983154308
    },
    {
        lng: 8.20048999786377,
        lat: 46.30132675170921
    },
    {
        lng: 8.200328826904354,
        lat: 46.301330566406364
    },
    {
        lng: 8.198678016662598,
        lat: 46.301368713378906
    },
    {
        lng: 8.19690132141119,
        lat: 46.30127716064459
    },
    {
        lng: 8.195708274841422,
        lat: 46.30105590820335
    },
    {
        lng: 8.195109367370605,
        lat: 46.300800323486385
    },
    {
        lng: 8.194171905517578,
        lat: 46.300254821777344
    },
    {
        lng: 8.193433761596623,
        lat: 46.299903869629134
    },
    {
        lng: 8.190793991088867,
        lat: 46.298645019531534
    },
    {
        lng: 8.18801212310791,
        lat: 46.29672622680664
    },
    {
        lng: 8.187264442443848,
        lat: 46.296394348144474
    },
    {
        lng: 8.185909271240348,
        lat: 46.295959472656364
    },
    {
        lng: 8.181347846984977,
        lat: 46.29516601562506
    },
    {
        lng: 8.179754257202148,
        lat: 46.29501724243164
    },
    {
        lng: 8.174897193908748,
        lat: 46.29471588134783
    },
    {
        lng: 8.171219825744629,
        lat: 46.29418945312523
    },
    {
        lng: 8.169595718383846,
        lat: 46.294078826904524
    },
    {
        lng: 8.167417526245174,
        lat: 46.29401397705101
    },
    {
        lng: 8.165816307067871,
        lat: 46.2940559387207
    },
    {
        lng: 8.164793968200797,
        lat: 46.294174194336165
    },
    {
        lng: 8.162936210632381,
        lat: 46.294616699218864
    },
    {
        lng: 8.161527633667106,
        lat: 46.295234680175895
    },
    {
        lng: 8.15959835052496,
        lat: 46.295783996582315
    },
    {
        lng: 8.158930778503475,
        lat: 46.29609680175804
    },
    {
        lng: 8.156586647033748,
        lat: 46.29745101928711
    },
    {
        lng: 8.155939102172965,
        lat: 46.297672271728686
    },
    {
        lng: 8.154950141906738,
        lat: 46.29790115356468
    },
    {
        lng: 8.154574394226131,
        lat: 46.29798889160179
    },
    {
        lng: 8.15295219421398,
        lat: 46.2986297607423
    },
    {
        lng: 8.152161598205566,
        lat: 46.298740386963175
    },
    {
        lng: 8.151297569274846,
        lat: 46.29878234863304
    },
    {
        lng: 8.147146224975586,
        lat: 46.29878234863304
    },
    {
        lng: 8.14581298828125,
        lat: 46.29893875122099
    },
    {
        lng: 8.14482498168951,
        lat: 46.299194335937614
    },
    {
        lng: 8.144526481628361,
        lat: 46.29904937744152
    },
    {
        lng: 8.142889022827092,
        lat: 46.29825973510742
    },
    {
        lng: 8.142066001892147,
        lat: 46.29763031005882
    },
    {
        lng: 8.141199111938533,
        lat: 46.29707336425787
    },
    {
        lng: 8.14046764373785,
        lat: 46.29643249511736
    },
    {
        lng: 8.1396226882934,
        lat: 46.29586410522472
    },
    {
        lng: 8.13891315460205,
        lat: 46.29519653320324
    },
    {
        lng: 8.138101577758846,
        lat: 46.29457092285179
    },
    {
        lng: 8.136238098144645,
        lat: 46.29231262207037
    },
    {
        lng: 8.13562202453619,
        lat: 46.291744232177734
    },
    {
        lng: 8.134963989257812,
        lat: 46.291454315185604
    },
    {
        lng: 8.132696151733455,
        lat: 46.290832519531534
    },
    {
        lng: 8.130689620971623,
        lat: 46.28999328613304
    },
    {
        lng: 8.130155563354492,
        lat: 46.28973007202154
    },
    {
        lng: 8.129338264465332,
        lat: 46.28910446166992
    },
    {
        lng: 8.128475189209041,
        lat: 46.28855133056652
    },
    {
        lng: 8.12720775604248,
        lat: 46.287425994873274
    },
    {
        lng: 8.126855850219783,
        lat: 46.28701400756836
    },
    {
        lng: 8.126207351684513,
        lat: 46.28532409667963
    },
    {
        lng: 8.125426292419434,
        lat: 46.28389739990257
    },
    {
        lng: 8.124699592590446,
        lat: 46.281780242920036
    },
    {
        lng: 8.124171257019043,
        lat: 46.28095245361328
    },
    {
        lng: 8.123140335082951,
        lat: 46.27960205078119
    },
    {
        lng: 8.121616363525504,
        lat: 46.2782936096192
    },
    {
        lng: 8.120772361755485,
        lat: 46.27770614624046
    },
    {
        lng: 8.119977951049918,
        lat: 46.277027130126896
    },
    {
        lng: 8.1194944381715,
        lat: 46.27672958374046
    },
    {
        lng: 8.118792533874455,
        lat: 46.27645111084007
    },
    {
        lng: 8.116458892822322,
        lat: 46.27595138549805
    },
    {
        lng: 8.114569664001522,
        lat: 46.275405883789006
    },
    {
        lng: 8.113782882690487,
        lat: 46.275066375732536
    },
    {
        lng: 8.113155364990234,
        lat: 46.27467346191406
    },
    {
        lng: 8.112631797790527,
        lat: 46.274238586426065
    },
    {
        lng: 8.111892700195312,
        lat: 46.27347946167015
    },
    {
        lng: 8.111075401306152,
        lat: 46.27285385131836
    },
    {
        lng: 8.110364913940487,
        lat: 46.272171020507756
    },
    {
        lng: 8.109563827514648,
        lat: 46.27152633666992
    },
    {
        lng: 8.107207298278922,
        lat: 46.26865005493164
    },
    {
        lng: 8.10566616058361,
        lat: 46.267349243164176
    },
    {
        lng: 8.104830741882438,
        lat: 46.266780853271484
    },
    {
        lng: 8.104089736938477,
        lat: 46.266147613525504
    },
    {
        lng: 8.103225708007926,
        lat: 46.26558303833002
    },
    {
        lng: 8.102400779724178,
        lat: 46.26494598388672
    },
    {
        lng: 8.100572586059627,
        lat: 46.26395797729515
    },
    {
        lng: 8.09828853607172,
        lat: 46.26253890991205
    },
    {
        lng: 8.097082138061637,
        lat: 46.26195907592768
    },
    {
        lng: 8.095410346984863,
        lat: 46.26143264770525
    },
    {
        lng: 8.09470653533947,
        lat: 46.261054992675895
    },
    {
        lng: 8.094449043273926,
        lat: 46.260795593261946
    },
    {
        lng: 8.094118118286246,
        lat: 46.26019287109392
    },
    {
        lng: 8.093207359314022,
        lat: 46.25751113891607
    },
    {
        lng: 8.092890739440918,
        lat: 46.256908416748274
    },
    {
        lng: 8.092748641967773,
        lat: 46.25678253173828
    },
    {
        lng: 8.092358589172363,
        lat: 46.25643157959007
    },
    {
        lng: 8.09021282196045,
        lat: 46.25543594360363
    },
    {
        lng: 8.089896202087346,
        lat: 46.2552223205567
    },
    {
        lng: 8.089645385742244,
        lat: 46.254947662353686
    },
    {
        lng: 8.089353561401367,
        lat: 46.254302978515625
    },
    {
        lng: 8.089278221130485,
        lat: 46.25325775146513
    },
    {
        lng: 8.089448928833008,
        lat: 46.25255203247076
    },
    {
        lng: 8.089835166931266,
        lat: 46.25191497802763
    },
    {
        lng: 8.090374946594352,
        lat: 46.25139617919922
    },
    {
        lng: 8.091004371643123,
        lat: 46.25095748901367
    },
    {
        lng: 8.092719078064079,
        lat: 46.25014495849632
    },
    {
        lng: 8.093545913696403,
        lat: 46.24961090087902
    },
    {
        lng: 8.095015525817985,
        lat: 46.248844146728686
    },
    {
        lng: 8.095727920532227,
        lat: 46.24858093261719
    },
    {
        lng: 8.096602439880371,
        lat: 46.2484588623048
    },
    {
        lng: 8.100625038146916,
        lat: 46.24840927124035
    },
    {
        lng: 8.104139328003043,
        lat: 46.24811172485374
    },
    {
        lng: 8.105414390564022,
        lat: 46.2479133605957
    },
    {
        lng: 8.106424331665039,
        lat: 46.24763107299833
    },
    {
        lng: 8.107165336608887,
        lat: 46.247318267822436
    },
    {
        lng: 8.108569145202694,
        lat: 46.24618530273449
    },
    {
        lng: 8.109745025634822,
        lat: 46.2454071044923
    },
    {
        lng: 8.110748291015625,
        lat: 46.24527359008812
    },
    {
        lng: 8.111377716064396,
        lat: 46.24518966674805
    },
    {
        lng: 8.114217758178768,
        lat: 46.24503326416027
    },
    {
        lng: 8.115301132202148,
        lat: 46.24485015869152
    },
    {
        lng: 8.116012573242301,
        lat: 46.24468231201183
    },
    {
        lng: 8.116765975952092,
        lat: 46.24441528320335
    },
    {
        lng: 8.118091583251953,
        lat: 46.24348831176758
    },
    {
        lng: 8.118513107299805,
        lat: 46.24298477172874
    },
    {
        lng: 8.11875438690197,
        lat: 46.242023468017805
    },
    {
        lng: 8.118732452392635,
        lat: 46.24060058593773
    },
    {
        lng: 8.11867904663086,
        lat: 46.240207672119254
    },
    {
        lng: 8.118446350097713,
        lat: 46.2384948730471
    },
    {
        lng: 8.118689537048283,
        lat: 46.23685073852539
    },
    {
        lng: 8.119061470031738,
        lat: 46.23598098754894
    },
    {
        lng: 8.11966609954834,
        lat: 46.23532104492216
    },
    {
        lng: 8.12039947509777,
        lat: 46.234035491943416
    },
    {
        lng: 8.120863914489803,
        lat: 46.23359298706055
    },
    {
        lng: 8.122286796569824,
        lat: 46.232765197754134
    },
    {
        lng: 8.122639656066838,
        lat: 46.23266220092796
    },
    {
        lng: 8.123431205749512,
        lat: 46.23254776000988
    },
    {
        lng: 8.125568389892635,
        lat: 46.23244857788086
    },
    {
        lng: 8.126234054565487,
        lat: 46.2323570251466
    },
    {
        lng: 8.12684535980236,
        lat: 46.23217010498047
    },
    {
        lng: 8.127346038818473,
        lat: 46.231872558593864
    },
    {
        lng: 8.128071784973201,
        lat: 46.23112487792997
    },
    {
        lng: 8.12916469573986,
        lat: 46.229431152343864
    },
    {
        lng: 8.129815101623535,
        lat: 46.227825164794865
    },
    {
        lng: 8.130093574523869,
        lat: 46.22735977172857
    },
    {
        lng: 8.131469726562614,
        lat: 46.22597885131836
    },
    {
        lng: 8.131974220275936,
        lat: 46.22557067871105
    },
    {
        lng: 8.132579803466797,
        lat: 46.225215911865234
    },
    {
        lng: 8.13322734832775,
        lat: 46.224948883056754
    },
    {
        lng: 8.13419628143322,
        lat: 46.22465133667015
    },
    {
        lng: 8.136058807373104,
        lat: 46.22408676147484
    },
    {
        lng: 8.138939857483024,
        lat: 46.2236785888673
    },
    {
        lng: 8.140844345092773,
        lat: 46.2232093811038
    },
    {
        lng: 8.142970085144157,
        lat: 46.22282791137724
    },
    {
        lng: 8.143142700195312,
        lat: 46.22279357910156
    },
    {
        lng: 8.14376544952404,
        lat: 46.222537994384766
    },
    {
        lng: 8.144412994384766,
        lat: 46.22200775146479
    },
    {
        lng: 8.145163536071891,
        lat: 46.22067642211937
    },
    {
        lng: 8.145674705505428,
        lat: 46.21861648559582
    },
    {
        lng: 8.146559715270996,
        lat: 46.21618270874018
    },
    {
        lng: 8.147062301635856,
        lat: 46.21421813964861
    },
    {
        lng: 8.147093772888297,
        lat: 46.2140998840332
    },
    {
        lng: 8.148016929626579,
        lat: 46.211673736572266
    },
    {
        lng: 8.148436546325627,
        lat: 46.210205078125
    },
    {
        lng: 8.148672103881836,
        lat: 46.209632873535156
    },
    {
        lng: 8.15004920959467,
        lat: 46.2077903747558
    },
    {
        lng: 8.151180267334098,
        lat: 46.20681762695335
    },
    {
        lng: 8.152958869934196,
        lat: 46.20483398437494
    },
    {
        lng: 8.153373718261832,
        lat: 46.20436859130882
    },
    {
        lng: 8.154337882995662,
        lat: 46.20357513427757
    },
    {
        lng: 8.155369758606014,
        lat: 46.202255249023665
    },
    {
        lng: 8.155694961547908,
        lat: 46.201725006103686
    },
    {
        lng: 8.155978202819767,
        lat: 46.20076370239258
    },
    {
        lng: 8.15618801116949,
        lat: 46.1980552673341
    },
    {
        lng: 8.15654277801508,
        lat: 46.19634628295893
    },
    {
        lng: 8.15661430358881,
        lat: 46.19600677490246
    },
    {
        lng: 8.156853675842399,
        lat: 46.1926078796389
    },
    {
        lng: 8.157478332519531,
        lat: 46.19039535522472
    },
    {
        lng: 8.157705307006893,
        lat: 46.18983078002958
    },
    {
        lng: 8.158294677734432,
        lat: 46.18908691406273
    },
    {
        lng: 8.158820152282772,
        lat: 46.188686370849894
    },
    {
        lng: 8.160292625427246,
        lat: 46.187805175781364
    },
    {
        lng: 8.161133766174316,
        lat: 46.187000274658146
    },
    {
        lng: 8.162878036499137,
        lat: 46.18552017211931
    },
    {
        lng: 8.163163185119743,
        lat: 46.185279846191634
    },
    {
        lng: 8.164130210876465,
        lat: 46.184295654296875
    },
    {
        lng: 8.165694236755428,
        lat: 46.18316268920927
    },
    {
        lng: 8.166534423828239,
        lat: 46.182674407958984
    },
    {
        lng: 8.168069839477596,
        lat: 46.18153762817383
    },
    {
        lng: 8.168402671814022,
        lat: 46.181159973144815
    },
    {
        lng: 8.168910980224666,
        lat: 46.18030548095726
    },
    {
        lng: 8.169120788574219,
        lat: 46.17976760864258
    },
    {
        lng: 8.169240951538086,
        lat: 46.17913055419939
    },
    {
        lng: 8.169226646423283,
        lat: 46.17780685424816
    },
    {
        lng: 8.169137954711914,
        lat: 46.1774597167971
    },
    {
        lng: 8.168979644775334,
        lat: 46.176841735840014
    },
    {
        lng: 8.168327331542969,
        lat: 46.17571258544922
    },
    {
        lng: 8.167756080627385,
        lat: 46.17513656616222
    },
    {
        lng: 8.166482925415039,
        lat: 46.17426681518572
    },
    {
        lng: 8.165962219238395,
        lat: 46.17400360107422
    },
    {
        lng: 8.164427757263297,
        lat: 46.17345046997082
    },
    {
        lng: 8.163933753967228,
        lat: 46.173091888427734
    },
    {
        lng: 8.163018226623535,
        lat: 46.17176055908209
    },
    {
        lng: 8.162193298339787,
        lat: 46.17085647583008
    },
    {
        lng: 8.161584854126033,
        lat: 46.169651031494084
    },
    {
        lng: 8.160633087158203,
        lat: 46.16848754882835
    },
    {
        lng: 8.159990310668945,
        lat: 46.167160034179915
    },
    {
        lng: 8.159596443176383,
        lat: 46.16658782959007
    },
    {
        lng: 8.15874195098877,
        lat: 46.165748596191406
    },
    {
        lng: 8.155777931213379,
        lat: 46.1633567810058
    },
    {
        lng: 8.155628204345646,
        lat: 46.16318130493164
    },
    {
        lng: 8.155022621154785,
        lat: 46.1624641418457
    },
    {
        lng: 8.15472602844244,
        lat: 46.161785125732536
    },
    {
        lng: 8.154597282409782,
        lat: 46.16110992431652
    },
    {
        lng: 8.154525756836051,
        lat: 46.16073608398449
    },
    {
        lng: 8.154457092285213,
        lat: 46.15930557250988
    },
    {
        lng: 8.1544828414917,
        lat: 46.15641403198248
    },
    {
        lng: 8.154643058776799,
        lat: 46.15501403808605
    },
    {
        lng: 8.155435562133789,
        lat: 46.15261459350597
    },
    {
        lng: 8.155709266662711,
        lat: 46.15214920043945
    },
    {
        lng: 8.156338691711483,
        lat: 46.15148162841797
    },
    {
        lng: 8.156615257263297,
        lat: 46.15102005004883
    },
    {
        lng: 8.1581325531007,
        lat: 46.14728164672863
    },
    {
        lng: 8.15827560424816,
        lat: 46.14664840698265
    },
    {
        lng: 8.158314704894963,
        lat: 46.145690917968864
    },
    {
        lng: 8.158231735229549,
        lat: 46.14506912231445
    },
    {
        lng: 8.158034324645996,
        lat: 46.14449691772461
    },
    {
        lng: 8.15707969665533,
        lat: 46.14332199096674
    },
    {
        lng: 8.156553268432674,
        lat: 46.14238357543945
    },
    {
        lng: 8.156216621398869,
        lat: 46.141944885253906
    },
    {
        lng: 8.154006958007756,
        lat: 46.1400146484375
    },
    {
        lng: 8.15368556976324,
        lat: 46.13954925537121
    },
    {
        lng: 8.153025627136287,
        lat: 46.13819122314459
    },
    {
        lng: 8.152297019958496,
        lat: 46.13730621337902
    },
    {
        lng: 8.149770736694336,
        lat: 46.134845733642805
    },
    {
        lng: 8.149230957031193,
        lat: 46.134521484375
    },
    {
        lng: 8.147962570190487,
        lat: 46.13392639160173
    },
    {
        lng: 8.14722824096674,
        lat: 46.1336288452149
    },
    {
        lng: 8.146396636962947,
        lat: 46.133403778076115
    },
    {
        lng: 8.145507812500057,
        lat: 46.13327407836914
    },
    {
        lng: 8.144622802734489,
        lat: 46.133232116699276
    },
    {
        lng: 8.143771171569767,
        lat: 46.13326263427746
    },
    {
        lng: 8.14299297332775,
        lat: 46.13336944580078
    },
    {
        lng: 8.1413316726684,
        lat: 46.13396072387707
    },
    {
        lng: 8.140077590942496,
        lat: 46.134117126465014
    },
    {
        lng: 8.13866996765148,
        lat: 46.134147644043196
    },
    {
        lng: 8.136715888977164,
        lat: 46.13406753540062
    },
    {
        lng: 8.13525581359869,
        lat: 46.13391494750982
    },
    {
        lng: 8.134318351745662,
        lat: 46.13372421264654
    },
    {
        lng: 8.132367134094181,
        lat: 46.13308715820341
    },
    {
        lng: 8.130365371704215,
        lat: 46.13258361816423
    },
    {
        lng: 8.129799842834416,
        lat: 46.13239288330095
    },
    {
        lng: 8.129595756530819,
        lat: 46.132324218750284
    },
    {
        lng: 8.12951946258545,
        lat: 46.13227844238287
    },
    {
        lng: 8.128611564636287,
        lat: 46.13175582885742
    },
    {
        lng: 8.12808799743658,
        lat: 46.13130569458002
    },
    {
        lng: 8.127082824707145,
        lat: 46.13022994995117
    },
    {
        lng: 8.126171112060604,
        lat: 46.12963104248058
    },
    {
        lng: 8.125426292419434,
        lat: 46.12932968139654
    },
    {
        lng: 8.124520301818848,
        lat: 46.129089355469034
    },
    {
        lng: 8.123080253601131,
        lat: 46.12870407104492
    },
    {
        lng: 8.122266769409293,
        lat: 46.1283416748048
    },
    {
        lng: 8.121174812316895,
        lat: 46.12763977050781
    },
    {
        lng: 8.12055492401123,
        lat: 46.12709426879894
    },
    {
        lng: 8.120067596435604,
        lat: 46.12648010253923
    },
    {
        lng: 8.119670867919979,
        lat: 46.12540817260742
    },
    {
        lng: 8.119392395019588,
        lat: 46.122886657714844
    },
    {
        lng: 8.118969917297477,
        lat: 46.121006011962834
    },
    {
        lng: 8.118871688842773,
        lat: 46.12026977539068
    },
    {
        lng: 8.118715286254996,
        lat: 46.116542816162166
    },
    {
        lng: 8.118601799011174,
        lat: 46.115825653076456
    },
    {
        lng: 8.118372917175407,
        lat: 46.11514663696289
    },
    {
        lng: 8.117843627929744,
        lat: 46.11442565917963
    },
    {
        lng: 8.116500854492244,
        lat: 46.1133384704591
    },
    {
        lng: 8.115729331970272,
        lat: 46.1125297546389
    },
    {
        lng: 8.11368179321289,
        lat: 46.110626220703125
    },
    {
        lng: 8.112921714782829,
        lat: 46.1098175048831
    },
    {
        lng: 8.11262321472168,
        lat: 46.1094970703125
    },
    {
        lng: 8.112138748168945,
        lat: 46.10914230346691
    },
    {
        lng: 8.111450195312614,
        lat: 46.108802795410384
    },
    {
        lng: 8.109602928161621,
        lat: 46.10823440551752
    },
    {
        lng: 8.106739997863826,
        lat: 46.10780715942394
    },
    {
        lng: 8.105477333068904,
        lat: 46.107479095458984
    },
    {
        lng: 8.10300540924078,
        lat: 46.10622787475609
    },
    {
        lng: 8.100751876831055,
        lat: 46.10494613647472
    },
    {
        lng: 8.099446296691895,
        lat: 46.10443878173834
    },
    {
        lng: 8.097016334533691,
        lat: 46.10391235351591
    },
    {
        lng: 8.093547821044979,
        lat: 46.103069305420036
    },
    {
        lng: 8.09110546112072,
        lat: 46.10257339477539
    },
    {
        lng: 8.089083671569824,
        lat: 46.10194778442383
    },
    {
        lng: 8.088194847106934,
        lat: 46.101726531982536
    },
    {
        lng: 8.086673736572322,
        lat: 46.101543426513786
    },
    {
        lng: 8.085139274597225,
        lat: 46.101528167724894
    },
    {
        lng: 8.084142684936467,
        lat: 46.10161209106445
    },
    {
        lng: 8.080215454101562,
        lat: 46.10223388671875
    },
    {
        lng: 8.078687667846737,
        lat: 46.102268218994254
    },
    {
        lng: 8.07714939117426,
        lat: 46.10214996337896
    },
    {
        lng: 8.076154708862305,
        lat: 46.10196304321289
    },
    {
        lng: 8.073722839355469,
        lat: 46.10124969482433
    },
    {
        lng: 8.071331024169922,
        lat: 46.1008415222168
    },
    {
        lng: 8.070461273193416,
        lat: 46.10063934326183
    },
    {
        lng: 8.068723678588867,
        lat: 46.09989547729492
    },
    {
        lng: 8.066824913024902,
        lat: 46.09928131103521
    },
    {
        lng: 8.06553173065197,
        lat: 46.09863662719738
    },
    {
        lng: 8.064003944396916,
        lat: 46.098148345947266
    },
    {
        lng: 8.062331199646053,
        lat: 46.09735107421898
    },
    {
        lng: 8.060894012451172,
        lat: 46.09695434570335
    },
    {
        lng: 8.059616088867188,
        lat: 46.09681701660162
    },
    {
        lng: 8.056624412536678,
        lat: 46.09701919555681
    },
    {
        lng: 8.054080009460563,
        lat: 46.096973419189396
    },
    {
        lng: 8.052700996398926,
        lat: 46.09676742553722
    },
    {
        lng: 8.0523042678833,
        lat: 46.09664535522461
    },
    {
        lng: 8.050933837890682,
        lat: 46.09603118896507
    },
    {
        lng: 8.050200462341422,
        lat: 46.095817565918196
    },
    {
        lng: 8.049487113952694,
        lat: 46.095611572265625
    },
    {
        lng: 8.048656463623047,
        lat: 46.095443725586165
    },
    {
        lng: 8.04705715179449,
        lat: 46.09529876708996
    },
    {
        lng: 8.042573928833065,
        lat: 46.09520721435564
    },
    {
        lng: 8.040096282958984,
        lat: 46.095073699951115
    },
    {
        lng: 8.039134979248047,
        lat: 46.09397125244152
    },
    {
        lng: 8.038413047790527,
        lat: 46.09299087524414
    },
    {
        lng: 8.037321090698299,
        lat: 46.091865539550895
    },
    {
        lng: 8.036538124084416,
        lat: 46.09026718139677
    },
    {
        lng: 8.035615921020508,
        lat: 46.08893585205078
    },
    {
        lng: 8.034887313842887,
        lat: 46.08745193481445
    },
    {
        lng: 8.034681320190487,
        lat: 46.08686828613304
    },
    {
        lng: 8.034552574157829,
        lat: 46.08617782592796
    },
    {
        lng: 8.034331321716422,
        lat: 46.082794189453125
    },
    {
        lng: 8.034297943115348,
        lat: 46.08228302001976
    },
    {
        lng: 8.034014701843319,
        lat: 46.08135986328131
    },
    {
        lng: 8.033410072326717,
        lat: 46.08012771606451
    },
    {
        lng: 8.033064842224121,
        lat: 46.0796165466308
    },
    {
        lng: 8.031708717346305,
        lat: 46.07801055908203
    },
    {
        lng: 8.031244277954102,
        lat: 46.077579498291016
    },
    {
        lng: 8.030581474304256,
        lat: 46.076969146728516
    },
    {
        lng: 8.030020713806152,
        lat: 46.076049804687614
    },
    {
        lng: 8.029680252075138,
        lat: 46.07563018798834
    },
    {
        lng: 8.028895378112907,
        lat: 46.07505416870117
    },
    {
        lng: 8.027425765991268,
        lat: 46.073814392089844
    },
    {
        lng: 8.027230262756348,
        lat: 46.07354736328131
    },
    {
        lng: 8.026985168457031,
        lat: 46.0729331970216
    },
    {
        lng: 8.026787757873649,
        lat: 46.07117080688488
    },
    {
        lng: 8.026802062988281,
        lat: 46.06703567504877
    },
    {
        lng: 8.0269193649292,
        lat: 46.06594085693371
    },
    {
        lng: 8.027078628540039,
        lat: 46.06531143188471
    },
    {
        lng: 8.02800273895258,
        lat: 46.06362915039068
    },
    {
        lng: 8.028660774230957,
        lat: 46.06183242797863
    },
    {
        lng: 8.029661178588867,
        lat: 46.060165405273665
    },
    {
        lng: 8.030366897583065,
        lat: 46.05852127075218
    },
    {
        lng: 8.030791282653809,
        lat: 46.05799102783203
    },
    {
        lng: 8.031318664550895,
        lat: 46.05733108520508
    },
    {
        lng: 8.031896591186523,
        lat: 46.055328369140625
    },
    {
        lng: 8.032156944274902,
        lat: 46.05481338500988
    },
    {
        lng: 8.032924652099723,
        lat: 46.05385208129894
    },
    {
        lng: 8.033615112304801,
        lat: 46.05158233642601
    },
    {
        lng: 8.03456783294672,
        lat: 46.050392150878906
    },
    {
        lng: 8.03527736663824,
        lat: 46.048751831054744
    },
    {
        lng: 8.036294937133846,
        lat: 46.0470733642581
    },
    {
        lng: 8.036991119384709,
        lat: 46.04528427124018
    },
    {
        lng: 8.037804603576717,
        lat: 46.04393386840832
    },
    {
        lng: 8.037977218627873,
        lat: 46.04307556152355
    },
    {
        lng: 8.037895202636719,
        lat: 46.0425186157226
    },
    {
        lng: 8.037784576416016,
        lat: 46.04225540161133
    },
    {
        lng: 8.037433624267578,
        lat: 46.04182052612333
    },
    {
        lng: 8.035948753356934,
        lat: 46.040885925293196
    },
    {
        lng: 8.033649444580135,
        lat: 46.0402565002442
    },
    {
        lng: 8.030900001525936,
        lat: 46.038810729980696
    },
    {
        lng: 8.029272079467773,
        lat: 46.038352966308594
    },
    {
        lng: 8.028574943542424,
        lat: 46.03815460205078
    },
    {
        lng: 8.027614593505973,
        lat: 46.037651062011946
    },
    {
        lng: 8.02649974822998,
        lat: 46.037181854248274
    },
    {
        lng: 8.025865554809513,
        lat: 46.036846160888786
    },
    {
        lng: 8.02529335021967,
        lat: 46.036460876465014
    },
    {
        lng: 8.024259567260685,
        lat: 46.035560607910384
    },
    {
        lng: 8.02337741851801,
        lat: 46.03501510620117
    },
    {
        lng: 8.022597312927246,
        lat: 46.034427642822266
    },
    {
        lng: 8.021704673767147,
        lat: 46.03392791748058
    },
    {
        lng: 8.020915985107536,
        lat: 46.03334808349621
    },
    {
        lng: 8.020007133483887,
        lat: 46.03280258178734
    },
    {
        lng: 8.018914222717342,
        lat: 46.03184509277338
    },
    {
        lng: 8.017347335815543,
        lat: 46.0306282043457
    },
    {
        lng: 8.016796112060547,
        lat: 46.030101776123274
    },
    {
        lng: 8.016581535339355,
        lat: 46.02981567382835
    },
    {
        lng: 8.016352653503361,
        lat: 46.029270172119084
    },
    {
        lng: 8.016307830810604,
        lat: 46.0287322998048
    },
    {
        lng: 8.016439437866154,
        lat: 46.028236389160156
    },
    {
        lng: 8.016719818115348,
        lat: 46.02787017822283
    },
    {
        lng: 8.017430305480957,
        lat: 46.0272598266601
    },
    {
        lng: 8.018671035766602,
        lat: 46.025798797607706
    },
    {
        lng: 8.019033432006893,
        lat: 46.024971008300724
    },
    {
        lng: 8.019083976745605,
        lat: 46.024085998535156
    },
    {
        lng: 8.018866539001522,
        lat: 46.02324295043968
    },
    {
        lng: 8.018521308898926,
        lat: 46.02275466918974
    },
    {
        lng: 8.017120361328125,
        lat: 46.021751403808594
    },
    {
        lng: 8.015995979309196,
        lat: 46.02114105224621
    },
    {
        lng: 8.01459598541254,
        lat: 46.02017211914091
    },
    {
        lng: 8.014212608337402,
        lat: 46.019779205322266
    },
    {
        lng: 8.014075279235897,
        lat: 46.01952743530285
    },
    {
        lng: 8.013893127441463,
        lat: 46.01871490478533
    },
    {
        lng: 8.013993263244743,
        lat: 46.01791381835932
    },
    {
        lng: 8.01423263549816,
        lat: 46.017448425293196
    },
    {
        lng: 8.015007972717285,
        lat: 46.01660537719732
    },
    {
        lng: 8.015210151672477,
        lat: 46.016078948974894
    },
    {
        lng: 8.015327453613224,
        lat: 46.015197753906364
    },
    {
        lng: 8.015265464782829,
        lat: 46.014289855956974
    },
    {
        lng: 8.01508617401123,
        lat: 46.01369476318388
    },
    {
        lng: 8.014928817749023,
        lat: 46.013408660888956
    },
    {
        lng: 8.014481544494686,
        lat: 46.01294326782249
    },
    {
        lng: 8.01390266418457,
        lat: 46.01256179809593
    },
    {
        lng: 8.013826370239371,
        lat: 46.012596130371094
    },
    {
        lng: 8.013158798217887,
        lat: 46.01239013671869
    },
    {
        lng: 8.010052680969295,
        lat: 46.01194000244146
    },
    {
        lng: 8.008619308471623,
        lat: 46.01183700561529
    },
    {
        lng: 8.005721092224235,
        lat: 46.01175308227556
    },
    {
        lng: 8.005405426025447,
        lat: 46.01172256469721
    },
    {
        lng: 8.004781723022575,
        lat: 46.01166152954113
    },
    {
        lng: 8.003878593444881,
        lat: 46.01145935058594
    },
    {
        lng: 8.003495216369743,
        lat: 46.01131439209007
    },
    {
        lng: 8.002825736999455,
        lat: 46.01094055175804
    },
    {
        lng: 8.002305984497127,
        lat: 46.0104789733889
    },
    {
        lng: 8.002115249633846,
        lat: 46.01022338867193
    },
    {
        lng: 8.001359939575252,
        lat: 46.00862503051769
    },
    {
        lng: 8.000481605529728,
        lat: 46.00735473632818
    },
    {
        lng: 7.999713897705135,
        lat: 46.00583648681646
    },
    {
        lng: 7.998681068420467,
        lat: 46.00475692749046
    },
    {
        lng: 7.99802303314209,
        lat: 46.003517150879134
    },
    {
        lng: 7.997675895690861,
        lat: 46.00301361084013
    },
    {
        lng: 7.996318817138786,
        lat: 46.00148391723633
    },
    {
        lng: 7.995368957519531,
        lat: 46.00068283081055
    },
    {
        lng: 7.994339466094971,
        lat: 45.99918365478527
    },
    {
        lng: 7.992812156677189,
        lat: 45.99802017211931
    },
    {
        lng: 7.992029190063533,
        lat: 45.997718811035156
    },
    {
        lng: 7.991429805755672,
        lat: 45.99766159057617
    },
    {
        lng: 7.990691184997672,
        lat: 45.997680664062614
    },
    {
        lng: 7.989544868469238,
        lat: 45.99776077270536
    },
    {
        lng: 7.988715171814022,
        lat: 45.997875213623274
    },
    {
        lng: 7.987919807434139,
        lat: 45.998062133789176
    },
    {
        lng: 7.9864449501038735,
        lat: 45.99853134155302
    },
    {
        lng: 7.9842119216918945,
        lat: 45.99901199340843
    },
    {
        lng: 7.98296499252325,
        lat: 45.99951553344721
    },
    {
        lng: 7.982635974883976,
        lat: 45.99959945678734
    },
    {
        lng: 7.98149299621582,
        lat: 45.99970626831049
    },
    {
        lng: 7.980255126953125,
        lat: 45.99962234497076
    },
    {
        lng: 7.97630500793457,
        lat: 45.998882293701286
    },
    {
        lng: 7.972565174102783,
        lat: 45.99853134155302
    },
    {
        lng: 7.968101978302116,
        lat: 45.99772262573248
    },
    {
        lng: 7.9643750190736,
        lat: 45.99739837646507
    },
    {
        lng: 7.962409019470272,
        lat: 45.99712371826166
    },
    {
        lng: 7.960995197296086,
        lat: 45.9970397949221
    },
    {
        lng: 7.9585700035095215,
        lat: 45.99710845947277
    },
    {
        lng: 7.956558227539176,
        lat: 45.997356414795036
    },
    {
        lng: 7.953124046325627,
        lat: 45.99763107299805
    },
    {
        lng: 7.950396060943717,
        lat: 45.99792861938488
    },
    {
        lng: 7.947546005249137,
        lat: 45.99799728393555
    },
    {
        lng: 7.94468879699707,
        lat: 45.997901916503906
    },
    {
        lng: 7.94137620925909,
        lat: 45.99753952026373
    },
    {
        lng: 7.937345027923698,
        lat: 45.99730300903343
    },
    {
        lng: 7.933990001678467,
        lat: 45.99697494506847
    },
    {
        lng: 7.93164682388317,
        lat: 45.996967315673885
    },
    {
        lng: 7.928965091705322,
        lat: 45.99718856811552
    },
    {
        lng: 7.927947998046989,
        lat: 45.99721527099632
    },
    {
        lng: 7.9269251823424725,
        lat: 45.99718475341797
    },
    {
        lng: 7.92478084564209,
        lat: 45.99696350097656
    },
    {
        lng: 7.923003196716252,
        lat: 45.99686050415039
    },
    {
        lng: 7.91875791549694,
        lat: 45.99681472778349
    },
    {
        lng: 7.916968822479362,
        lat: 45.996887207031364
    },
    {
        lng: 7.915821075439567,
        lat: 45.99700546264677
    },
    {
        lng: 7.912858009338436,
        lat: 45.997497558593864
    },
    {
        lng: 7.910478115081844,
        lat: 45.99799728393555
    },
    {
        lng: 7.9100379943848225,
        lat: 45.99645614624018
    },
    {
        lng: 7.909691810607853,
        lat: 45.995559692382926
    },
    {
        lng: 7.908786296844539,
        lat: 45.99415588378929
    },
    {
        lng: 7.908656120300293,
        lat: 45.99395370483427
    },
    {
        lng: 7.907867908477726,
        lat: 45.99198913574219
    },
    {
        lng: 7.907157897949332,
        lat: 45.99090957641613
    },
    {
        lng: 7.906771183013859,
        lat: 45.99050521850597
    },
    {
        lng: 7.906288146972656,
        lat: 45.99018478393555
    },
    {
        lng: 7.905660152435416,
        lat: 45.98993301391613
    },
    {
        lng: 7.90423488616949,
        lat: 45.989601135254134
    },
    {
        lng: 7.903594017028809,
        lat: 45.98939514160179
    },
    {
        lng: 7.901855945587215,
        lat: 45.98833465576183
    },
    {
        lng: 7.901501178741455,
        lat: 45.987918853759936
    },
    {
        lng: 7.900798797607365,
        lat: 45.986831665039006
    },
    {
        lng: 7.899930000305176,
        lat: 45.98599624633789
    },
    {
        lng: 7.899248123168945,
        lat: 45.984870910644645
    },
    {
        lng: 7.89816522598278,
        lat: 45.98384857177757
    },
    {
        lng: 7.8973078727723305,
        lat: 45.98262786865229
    },
    {
        lng: 7.8966450691223145,
        lat: 45.98151397705078
    },
    {
        lng: 7.896006107330379,
        lat: 45.980712890625114
    },
    {
        lng: 7.895586967468375,
        lat: 45.98034286499046
    },
    {
        lng: 7.894758224487305,
        lat: 45.979923248291016
    },
    {
        lng: 7.893383979797306,
        lat: 45.979385375976506
    },
    {
        lng: 7.892601013183651,
        lat: 45.979141235351676
    },
    {
        lng: 7.88963079452526,
        lat: 45.97863006591791
    },
    {
        lng: 7.888021945953369,
        lat: 45.97820281982433
    },
    {
        lng: 7.887166976928711,
        lat: 45.97803115844749
    },
    {
        lng: 7.884501934051514,
        lat: 45.977771759033146
    },
    {
        lng: 7.883697032928524,
        lat: 45.97763061523449
    },
    {
        lng: 7.883058071136588,
        lat: 45.977401733398494
    },
    {
        lng: 7.881631851196403,
        lat: 45.976688385009766
    },
    {
        lng: 7.881162166595459,
        lat: 45.97639083862316
    },
    {
        lng: 7.88104963302618,
        lat: 45.97627258300787
    },
    {
        lng: 7.880805969238338,
        lat: 45.9760169982913
    },
    {
        lng: 7.8806071281433105,
        lat: 45.97556304931652
    },
    {
        lng: 7.880578994750977,
        lat: 45.97509765625023
    },
    {
        lng: 7.880970001220646,
        lat: 45.97369766235357
    },
    {
        lng: 7.881278038024902,
        lat: 45.97296905517578
    },
    {
        lng: 7.882214069366398,
        lat: 45.97187423706055
    },
    {
        lng: 7.882408142089844,
        lat: 45.971317291259936
    },
    {
        lng: 7.882518768310547,
        lat: 45.970050811767805
    },
    {
        lng: 7.882466793060303,
        lat: 45.9692001342774
    },
    {
        lng: 7.882397174835148,
        lat: 45.96805953979492
    },
    {
        lng: 7.88220310211193,
        lat: 45.96711730957037
    },
    {
        lng: 7.88195610046381,
        lat: 45.966552734375114
    },
    {
        lng: 7.881773948669547,
        lat: 45.96630477905285
    },
    {
        lng: 7.880908966064567,
        lat: 45.965469360351506
    },
    {
        lng: 7.880168914795036,
        lat: 45.96437072753906
    },
    {
        lng: 7.878977775573844,
        lat: 45.963397979736385
    },
    {
        lng: 7.878028869628906,
        lat: 45.96237182617199
    },
    {
        lng: 7.877628803253117,
        lat: 45.961894989013786
    },
    {
        lng: 7.877363204956168,
        lat: 45.96140289306652
    },
    {
        lng: 7.8772339820862385,
        lat: 45.96083831787121
    },
    {
        lng: 7.8773460388183025,
        lat: 45.960086822509766
    },
    {
        lng: 7.878182888031006,
        lat: 45.959003448486385
    },
    {
        lng: 7.8784332275390625,
        lat: 45.95814895629883
    },
    {
        lng: 7.87851190567028,
        lat: 45.95718765258789
    },
    {
        lng: 7.878496170043945,
        lat: 45.95586013793945
    },
    {
        lng: 7.878396034240836,
        lat: 45.954898834228516
    },
    {
        lng: 7.878231048584041,
        lat: 45.95428848266613
    },
    {
        lng: 7.8779120445251465,
        lat: 45.95373153686518
    },
    {
        lng: 7.877657890319938,
        lat: 45.95348358154314
    },
    {
        lng: 7.876849174499569,
        lat: 45.95305252075218
    },
    {
        lng: 7.87650203704834,
        lat: 45.952922821045036
    },
    {
        lng: 7.875494956970158,
        lat: 45.952548980713004
    },
    {
        lng: 7.87473297119152,
        lat: 45.95232772827154
    },
    {
        lng: 7.872365951538143,
        lat: 45.95188903808622
    },
    {
        lng: 7.871997833251896,
        lat: 45.95176315307623
    },
    {
        lng: 7.871855735778922,
        lat: 45.95168685913086
    },
    {
        lng: 7.871456146240234,
        lat: 45.951473236083984
    },
    {
        lng: 7.871010780334473,
        lat: 45.95105743408226
    },
    {
        lng: 7.870700836181641,
        lat: 45.95054626464855
    },
    {
        lng: 7.86999607086193,
        lat: 45.948787689208984
    },
    {
        lng: 7.86972284317028,
        lat: 45.94754028320307
    },
    {
        lng: 7.869510173797664,
        lat: 45.943668365478686
    },
    {
        lng: 7.869538784027213,
        lat: 45.94271850585949
    },
    {
        lng: 7.869708061218262,
        lat: 45.94179534912121
    },
    {
        lng: 7.869960784912109,
        lat: 45.941242218017805
    },
    {
        lng: 7.870464324951115,
        lat: 45.94045639038109
    },
    {
        lng: 7.870595932006893,
        lat: 45.940250396728516
    },
    {
        lng: 7.87113714218151,
        lat: 45.938999176025504
    },
    {
        lng: 7.8714380264282795,
        lat: 45.93856430053734
    },
    {
        lng: 7.872168064117432,
        lat: 45.93795776367199
    },
    {
        lng: 7.874341011047363,
        lat: 45.93561172485346
    },
    {
        lng: 7.875261783599967,
        lat: 45.934871673583984
    },
    {
        lng: 7.875539779663086,
        lat: 45.934509277343864
    },
    {
        lng: 7.875867843627873,
        lat: 45.93372344970709
    },
    {
        lng: 7.87589883804327,
        lat: 45.93331146240257
    },
    {
        lng: 7.875474929809627,
        lat: 45.931537628173885
    },
    {
        lng: 7.87523078918457,
        lat: 45.93098831176769
    },
    {
        lng: 7.874930858612117,
        lat: 45.930549621582145
    },
    {
        lng: 7.874354839325065,
        lat: 45.92971038818382
    },
    {
        lng: 7.873587131500301,
        lat: 45.92811965942394
    },
    {
        lng: 7.873393058776912,
        lat: 45.927864074707315
    },
    {
        lng: 7.87287092208868,
        lat: 45.927413940429915
    },
    {
        lng: 7.872207164764404,
        lat: 45.927055358886776
    },
    {
        lng: 7.8714098930359455,
        lat: 45.92680740356474
    },
    {
        lng: 7.870547771453801,
        lat: 45.92667388916021
    },
    {
        lng: 7.8688511848450275,
        lat: 45.92650604248058
    },
    {
        lng: 7.868080139160099,
        lat: 45.92636108398432
    },
    {
        lng: 7.86773777008051,
        lat: 45.926246643066634
    },
    {
        lng: 7.865984916687069,
        lat: 45.92520523071312
    },
    {
        lng: 7.865066051483268,
        lat: 45.92433929443382
    },
    {
        lng: 7.864502906799316,
        lat: 45.923984527587834
    },
    {
        lng: 7.863793849945182,
        lat: 45.923725128173885
    },
    {
        lng: 7.863072872161922,
        lat: 45.923648834228516
    },
    {
        lng: 7.862357139587402,
        lat: 45.923728942871264
    },
    {
        lng: 7.861990928649902,
        lat: 45.923843383789176
    },
    {
        lng: 7.861401081085319,
        lat: 45.924186706543026
    },
    {
        lng: 7.861172199249324,
        lat: 45.92439270019537
    },
    {
        lng: 7.860854148864746,
        lat: 45.92485427856451
    },
    {
        lng: 7.860520839691219,
        lat: 45.925819396972656
    },
    {
        lng: 7.860236167907658,
        lat: 45.92628479003912
    },
    {
        lng: 7.859823226928825,
        lat: 45.926612854003906
    },
    {
        lng: 7.85955810546875,
        lat: 45.92674636840843
    },
    {
        lng: 7.858736991882381,
        lat: 45.92697906494158
    },
    {
        lng: 7.857349872589168,
        lat: 45.9271049499514
    },
    {
        lng: 7.852901935577393,
        lat: 45.92715835571295
    },
    {
        lng: 7.850424766540584,
        lat: 45.927425384521484
    },
    {
        lng: 7.848968029022217,
        lat: 45.927505493164006
    },
    {
        lng: 7.846467971801815,
        lat: 45.927520751953125
    },
    {
        lng: 7.84041881561285,
        lat: 45.927425384521484
    },
    {
        lng: 7.838980197906494,
        lat: 45.92745590209978
    },
    {
        lng: 7.838076114654655,
        lat: 45.92753219604498
    },
    {
        lng: 7.837255954742375,
        lat: 45.92770385742182
    },
    {
        lng: 7.836656093597469,
        lat: 45.92798614501976
    },
    {
        lng: 7.834926128387451,
        lat: 45.929378509521484
    },
    {
        lng: 7.833514213562012,
        lat: 45.930198669433594
    },
    {
        lng: 7.832788944244385,
        lat: 45.93048095703131
    },
    {
        lng: 7.832551002502498,
        lat: 45.930557250976506
    },
    {
        lng: 7.83067178726202,
        lat: 45.93116760253923
    },
    {
        lng: 7.829487800598088,
        lat: 45.93148040771513
    },
    {
        lng: 7.826990127563477,
        lat: 45.931892395019474
    },
    {
        lng: 7.8254499435424805,
        lat: 45.93232727050781
    },
    {
        lng: 7.82459211349493,
        lat: 45.93250656127941
    },
    {
        lng: 7.823646068573055,
        lat: 45.932605743408374
    },
    {
        lng: 7.822202205657959,
        lat: 45.93265151977562
    },
    {
        lng: 7.820790767669791,
        lat: 45.93260192871105
    },
    {
        lng: 7.8195161819459145,
        lat: 45.93239974975586
    },
    {
        lng: 7.818898200988826,
        lat: 45.932155609131144
    },
    {
        lng: 7.816501140594539,
        lat: 45.930812835693644
    },
    {
        lng: 7.8158869743348305,
        lat: 45.9305992126466
    },
    {
        lng: 7.814233779907283,
        lat: 45.93014526367216
    },
    {
        lng: 7.8132882118226235,
        lat: 45.92965316772472
    },
    {
        lng: 7.811769008636418,
        lat: 45.929023742675895
    },
    {
        lng: 7.811026096344051,
        lat: 45.92863845825218
    },
    {
        lng: 7.80956506729126,
        lat: 45.927761077881144
    },
    {
        lng: 7.805975914001522,
        lat: 45.92541122436546
    },
    {
        lng: 7.804472923278865,
        lat: 45.92459487915056
    },
    {
        lng: 7.803631782531795,
        lat: 45.92430114746111
    },
    {
        lng: 7.802795886993408,
        lat: 45.9241638183596
    },
    {
        lng: 7.801979064941463,
        lat: 45.92414474487333
    },
    {
        lng: 7.801222801208553,
        lat: 45.92423248291044
    },
    {
        lng: 7.80101203918457,
        lat: 45.924285888671875
    },
    {
        lng: 7.800887107849235,
        lat: 45.92432022094738
    },
    {
        lng: 7.799685001373348,
        lat: 45.924819946289176
    },
    {
        lng: 7.798364162445182,
        lat: 45.92513275146507
    },
    {
        lng: 7.797729015350399,
        lat: 45.92533493041992
    },
    {
        lng: 7.79683780670166,
        lat: 45.92583084106457
    },
    {
        lng: 7.795807838440055,
        lat: 45.92629623413086
    },
    {
        lng: 7.795231819152889,
        lat: 45.92664718627924
    },
    {
        lng: 7.7947478294373695,
        lat: 45.927070617675895
    },
    {
        lng: 7.794403076171932,
        lat: 45.92756271362316
    },
    {
        lng: 7.793934822082576,
        lat: 45.9288902282716
    },
    {
        lng: 7.793727874755916,
        lat: 45.92927551269531
    },
    {
        lng: 7.7936601638793945,
        lat: 45.929401397705305
    },
    {
        lng: 7.793330192565975,
        lat: 45.9297904968264
    },
    {
        lng: 7.79273176193243,
        lat: 45.93050384521496
    },
    {
        lng: 7.79213476181036,
        lat: 45.930999755859375
    },
    {
        lng: 7.79159688949585,
        lat: 45.93122482299799
    },
    {
        lng: 7.7899022102356525,
        lat: 45.93167495727539
    },
    {
        lng: 7.78866815567028,
        lat: 45.93219757080084
    },
    {
        lng: 7.78667593002325,
        lat: 45.93266296386713
    },
    {
        lng: 7.785453796386832,
        lat: 45.93318939208996
    },
    {
        lng: 7.784029960632438,
        lat: 45.933521270751896
    },
    {
        lng: 7.783782005310059,
        lat: 45.93357849121122
    },
    {
        lng: 7.78323411941534,
        lat: 45.93379974365229
    },
    {
        lng: 7.782310962677116,
        lat: 45.93428421020502
    },
    {
        lng: 7.780735969543514,
        lat: 45.934955596924055
    },
    {
        lng: 7.777256011962891,
        lat: 45.93698883056652
    },
    {
        lng: 7.7758049964904785,
        lat: 45.9377326965332
    },
    {
        lng: 7.775394916534367,
        lat: 45.9380798339846
    },
    {
        lng: 7.775331974029655,
        lat: 45.938182830810774
    },
    {
        lng: 7.774744033813477,
        lat: 45.93913650512718
    },
    {
        lng: 7.774404048919735,
        lat: 45.9394721984865
    },
    {
        lng: 7.773151874542236,
        lat: 45.9404411315918
    },
    {
        lng: 7.77144098281866,
        lat: 45.94141006469749
    },
    {
        lng: 7.770779132843131,
        lat: 45.94161224365246
    },
    {
        lng: 7.768854141235408,
        lat: 45.94196701049805
    },
    {
        lng: 7.767652988433838,
        lat: 45.942295074462834
    },
    {
        lng: 7.7673258781433105,
        lat: 45.94238662719749
    },
    {
        lng: 7.766482830047607,
        lat: 45.94255447387718
    },
    {
        lng: 7.762814998626823,
        lat: 45.94289779663103
    },
    {
        lng: 7.761981964111328,
        lat: 45.94306564331055
    },
    {
        lng: 7.7604289054870605,
        lat: 45.943481445312614
    },
    {
        lng: 7.7595410346986,
        lat: 45.94362640380882
    },
    {
        lng: 7.756765842437687,
        lat: 45.943851470947436
    },
    {
        lng: 7.7550339698790935,
        lat: 45.94408798217796
    },
    {
        lng: 7.754855155944767,
        lat: 45.944114685058594
    },
    {
        lng: 7.753992080688533,
        lat: 45.9441833496096
    },
    {
        lng: 7.752664089202881,
        lat: 45.94415664672863
    },
    {
        lng: 7.751798152923584,
        lat: 45.94400024414085
    },
    {
        lng: 7.750980854034367,
        lat: 45.943679809570426
    },
    {
        lng: 7.749979972839412,
        lat: 45.943023681640625
    },
    {
        lng: 7.7497100830078125,
        lat: 45.94277191162104
    },
    {
        lng: 7.749314785003719,
        lat: 45.94223785400408
    },
    {
        lng: 7.748648166656551,
        lat: 45.940620422363395
    },
    {
        lng: 7.747817039489803,
        lat: 45.939357757568416
    },
    {
        lng: 7.7476549148559,
        lat: 45.93901443481474
    },
    {
        lng: 7.747228145599365,
        lat: 45.938106536865234
    },
    {
        lng: 7.746883869171199,
        lat: 45.93767166137707
    },
    {
        lng: 7.746653079986629,
        lat: 45.9375267028808
    },
    {
        lng: 7.745193958282471,
        lat: 45.93660736083996
    },
    {
        lng: 7.744559764862174,
        lat: 45.93640899658209
    },
    {
        lng: 7.743537902831974,
        lat: 45.936191558837834
    },
    {
        lng: 7.7428941726684,
        lat: 45.93600082397455
    },
    {
        lng: 7.7409510612487225,
        lat: 45.935035705566634
    },
    {
        lng: 7.7404961585998535,
        lat: 45.93472671508789
    },
    {
        lng: 7.739970207214469,
        lat: 45.93413543701183
    },
    {
        lng: 7.7394652366637615,
        lat: 45.93327713012707
    },
    {
        lng: 7.738761901855412,
        lat: 45.93141937255882
    },
    {
        lng: 7.738406181335563,
        lat: 45.93088912963884
    },
    {
        lng: 7.737624168395996,
        lat: 45.930183410644474
    },
    {
        lng: 7.737000942230168,
        lat: 45.9297637939456
    },
    {
        lng: 7.736313819885197,
        lat: 45.929389953613565
    },
    {
        lng: 7.735557079315299,
        lat: 45.92908477783203
    },
    {
        lng: 7.735205173492375,
        lat: 45.92898941040039
    },
    {
        lng: 7.7348461151123615,
        lat: 45.9288902282716
    },
    {
        lng: 7.733466148376465,
        lat: 45.92861938476591
    },
    {
        lng: 7.732370853424129,
        lat: 45.92852783203125
    },
    {
        lng: 7.731626987457275,
        lat: 45.92855453491205
    },
    {
        lng: 7.7291932106018635,
        lat: 45.92877197265631
    },
    {
        lng: 7.72471809387207,
        lat: 45.928859710693416
    },
    {
        lng: 7.723746776580924,
        lat: 45.928939819336165
    },
    {
        lng: 7.722814083099365,
        lat: 45.929111480713004
    },
    {
        lng: 7.7215919494629475,
        lat: 45.929584503174055
    },
    {
        lng: 7.7205028533936115,
        lat: 45.930194854736385
    },
    {
        lng: 7.717412948608398,
        lat: 45.932247161865234
    },
    {
        lng: 7.715836048126221,
        lat: 45.933441162109375
    },
    {
        lng: 7.715115070343131,
        lat: 45.93421173095703
    },
    {
        lng: 7.714519023895377,
        lat: 45.93545150756836
    },
    {
        lng: 7.714228153228817,
        lat: 45.93587875366234
    },
    {
        lng: 7.713926792144832,
        lat: 45.93614196777344
    },
    {
        lng: 7.713338851928825,
        lat: 45.936656951904524
    },
    {
        lng: 7.712070941924992,
        lat: 45.93814468383789
    },
    {
        lng: 7.711756229400692,
        lat: 45.93861770629894
    },
    {
        lng: 7.711337089538688,
        lat: 45.939529418945256
    },
    {
        lng: 7.711130142212028,
        lat: 45.9402313232423
    },
    {
        lng: 7.711119174957389,
        lat: 45.94073486328125
    },
    {
        lng: 7.711377143859977,
        lat: 45.94201278686546
    },
    {
        lng: 7.711451053619328,
        lat: 45.94237518310558
    },
    {
        lng: 7.711729049682674,
        lat: 45.94549560546875
    },
    {
        lng: 7.71263504028326,
        lat: 45.948688507080305
    },
    {
        lng: 7.713016033172551,
        lat: 45.949676513672046
    },
    {
        lng: 7.713220119476318,
        lat: 45.95059585571312
    },
    {
        lng: 7.713251113891545,
        lat: 45.951202392578296
    },
    {
        lng: 7.713188171386832,
        lat: 45.95178604125988
    },
    {
        lng: 7.712988853454533,
        lat: 45.95232009887701
    },
    {
        lng: 7.712636947631836,
        lat: 45.952732086181754
    },
    {
        lng: 7.711345195770264,
        lat: 45.9537467956543
    },
    {
        lng: 7.710463047027588,
        lat: 45.954246520996264
    },
    {
        lng: 7.709634780883732,
        lat: 45.95482635498064
    },
    {
        lng: 7.708249092102051,
        lat: 45.95546340942383
    },
    {
        lng: 7.706235885620174,
        lat: 45.95652389526373
    },
    {
        lng: 7.705015182495117,
        lat: 45.95700836181646
    },
    {
        lng: 7.703658103942814,
        lat: 45.95726013183588
    },
    {
        lng: 7.700850963592643,
        lat: 45.95750808715832
    },
    {
        lng: 7.699575901031494,
        lat: 45.95769500732422
    },
    {
        lng: 7.698910236358586,
        lat: 45.95779418945324
    },
    {
        lng: 7.696716785430851,
        lat: 45.95804214477545
    },
    {
        lng: 7.693085193634147,
        lat: 45.95861053466797
    },
    {
        lng: 7.692240238189697,
        lat: 45.95877075195324
    },
    {
        lng: 7.690656185150203,
        lat: 45.95920562744158
    },
    {
        lng: 7.6898131370545,
        lat: 45.95938491821295
    },
    {
        lng: 7.687088966369743,
        lat: 45.9596786499024
    },
    {
        lng: 7.686210155487004,
        lat: 45.95983505249018
    },
    {
        lng: 7.685791015625,
        lat: 45.95995330810547
    },
    {
        lng: 7.685085773468074,
        lat: 45.96027755737305
    },
    {
        lng: 7.684523105621338,
        lat: 45.96069335937494
    },
    {
        lng: 7.684301853179932,
        lat: 45.96092987060564
    },
    {
        lng: 7.683989048004207,
        lat: 45.961429595947266
    },
    {
        lng: 7.683640956878662,
        lat: 45.96218872070341
    },
    {
        lng: 7.683546066284293,
        lat: 45.96239471435558
    },
    {
        lng: 7.682638168334904,
        lat: 45.963516235351676
    },
    {
        lng: 7.681982994079647,
        lat: 45.96508407592796
    },
    {
        lng: 7.681167125701961,
        lat: 45.96638870239258
    },
    {
        lng: 7.68048620223999,
        lat: 45.96796035766607
    },
    {
        lng: 7.679521083831844,
        lat: 45.969058990478516
    },
    {
        lng: 7.679032802581787,
        lat: 45.969974517822266
    },
    {
        lng: 7.678716182708683,
        lat: 45.97040176391624
    },
    {
        lng: 7.676527023315543,
        lat: 45.972198486328125
    },
    {
        lng: 7.675633907318229,
        lat: 45.9735183715822
    },
    {
        lng: 7.675205230712891,
        lat: 45.973888397216854
    },
    {
        lng: 7.671881198883,
        lat: 45.97575759887718
    },
    {
        lng: 7.670001983642521,
        lat: 45.976608276367415
    },
    {
        lng: 7.669040203094539,
        lat: 45.97712707519531
    },
    {
        lng: 7.668357849121207,
        lat: 45.97734832763672
    },
    {
        lng: 7.666514873504639,
        lat: 45.9777526855471
    },
    {
        lng: 7.666425228118953,
        lat: 45.977771759033146
    },
    {
        lng: 7.664887905120793,
        lat: 45.97824478149437
    },
    {
        lng: 7.6640172004700275,
        lat: 45.97843551635765
    },
    {
        lng: 7.662087917327881,
        lat: 45.97860336303734
    },
    {
        lng: 7.660641193389893,
        lat: 45.9785919189456
    },
    {
        lng: 7.659722805023307,
        lat: 45.97852325439476
    },
    {
        lng: 7.658885002136344,
        lat: 45.97837829589855
    },
    {
        lng: 7.658514022827205,
        lat: 45.97826766967779
    },
    {
        lng: 7.657227039337272,
        lat: 45.97771453857439
    },
    {
        lng: 7.655229091644287,
        lat: 45.97716140747099
    },
    {
        lng: 7.653923034667969,
        lat: 45.976596832275675
    },
    {
        lng: 7.651603221893367,
        lat: 45.97610855102539
    },
    {
        lng: 7.649673938751221,
        lat: 45.975532531738395
    },
    {
        lng: 7.647357940673828,
        lat: 45.97505187988281
    },
    {
        lng: 7.645690917968693,
        lat: 45.97440338134794
    },
    {
        lng: 7.642291069030875,
        lat: 45.973834991455305
    },
    {
        lng: 7.640289783477783,
        lat: 45.97368240356451
    },
    {
        lng: 7.63507699966425,
        lat: 45.97368621826172
    },
    {
        lng: 7.631992816925049,
        lat: 45.97380828857433
    },
    {
        lng: 7.6273698806762695,
        lat: 45.9744606018067
    },
    {
        lng: 7.623457908630428,
        lat: 45.975242614746094
    },
    {
        lng: 7.6222100257873535,
        lat: 45.97532653808605
    },
    {
        lng: 7.621414184570426,
        lat: 45.975273132324446
    },
    {
        lng: 7.620704174041805,
        lat: 45.97512817382818
    },
    {
        lng: 7.619734764099064,
        lat: 45.974716186523665
    },
    {
        lng: 7.6177620887757485,
        lat: 45.97420501709013
    },
    {
        lng: 7.61649322509777,
        lat: 45.97367477417015
    },
    {
        lng: 7.615805149078312,
        lat: 45.97349929809576
    },
    {
        lng: 7.614587783813533,
        lat: 45.973373413086165
    },
    {
        lng: 7.613513946533317,
        lat: 45.97326278686546
    },
    {
        lng: 7.612524032592773,
        lat: 45.97324371337902
    },
    {
        lng: 7.608107089996395,
        lat: 45.97357559204096
    },
    {
        lng: 7.601622104644832,
        lat: 45.974521636962834
    },
    {
        lng: 7.598330974578971,
        lat: 45.974811553955305
    },
    {
        lng: 7.597412109375114,
        lat: 45.97493362426758
    },
    {
        lng: 7.5938568115234375,
        lat: 45.975605010986385
    },
    {
        lng: 7.590751171112117,
        lat: 45.97594070434593
    },
    {
        lng: 7.589961051940975,
        lat: 45.97609710693371
    },
    {
        lng: 7.5893502235413735,
        lat: 45.97634124755854
    },
    {
        lng: 7.587824821472168,
        lat: 45.97726440429716
    },
    {
        lng: 7.58745908737194,
        lat: 45.97761917114275
    },
    {
        lng: 7.5865797996521565,
        lat: 45.97870635986351
    },
    {
        lng: 7.586130142211971,
        lat: 45.97953414916992
    },
    {
        lng: 7.585990905761662,
        lat: 45.98017120361328
    },
    {
        lng: 7.585943222045842,
        lat: 45.980823516845646
    },
    {
        lng: 7.5859441757203285,
        lat: 45.98408889770508
    },
    {
        lng: 7.585824012756291,
        lat: 45.984676361084155
    },
    {
        lng: 7.5855631828308105,
        lat: 45.98519515991222
    },
    {
        lng: 7.584948062896842,
        lat: 45.985843658447266
    },
    {
        lng: 7.584179878234977,
        lat: 45.98712158203148
    },
    {
        lng: 7.583813190460262,
        lat: 45.987472534179915
    },
    {
        lng: 7.582612991332951,
        lat: 45.98831176757824
    },
    {
        lng: 7.582135200500488,
        lat: 45.9885826110841
    },
    {
        lng: 7.580501079559326,
        lat: 45.989265441894645
    },
    {
        lng: 7.579333782196045,
        lat: 45.989822387695426
    },
    {
        lng: 7.578711986541805,
        lat: 45.99012374877941
    },
    {
        lng: 7.575485229492301,
        lat: 45.989727020263786
    },
    {
        lng: 7.573794841766357,
        lat: 45.98912811279297
    },
    {
        lng: 7.571476936340332,
        lat: 45.98871231079124
    },
    {
        lng: 7.569529056549186,
        lat: 45.988189697265625
    },
    {
        lng: 7.566565036773795,
        lat: 45.987728118896655
    },
    {
        lng: 7.564979076385555,
        lat: 45.98732757568365
    },
    {
        lng: 7.564085006713924,
        lat: 45.98716735839855
    },
    {
        lng: 7.562056064605713,
        lat: 45.98706436157238
    },
    {
        lng: 7.5547032356262775,
        lat: 45.9873161315918
    },
    {
        lng: 7.553716182708797,
        lat: 45.98730850219721
    },
    {
        lng: 7.552792072296256,
        lat: 45.98723602294922
    },
    {
        lng: 7.552368164062614,
        lat: 45.98715972900402
    },
    {
        lng: 7.551722049713192,
        lat: 45.986919403076115
    },
    {
        lng: 7.550280094146672,
        lat: 45.98598480224615
    },
    {
        lng: 7.549953937530461,
        lat: 45.985561370849894
    },
    {
        lng: 7.549324035644645,
        lat: 45.98446655273443
    },
    {
        lng: 7.548497200012321,
        lat: 45.98363494873047
    },
    {
        lng: 7.547861099243278,
        lat: 45.982509613037394
    },
    {
        lng: 7.546997070312557,
        lat: 45.981700897216854
    },
    {
        lng: 7.546162128448486,
        lat: 45.98039627075207
    },
    {
        lng: 7.545335769653377,
        lat: 45.979572296142805
    },
    {
        lng: 7.544973850250301,
        lat: 45.9788055419923
    },
    {
        lng: 7.544881820678825,
        lat: 45.977928161621094
    },
    {
        lng: 7.544993877410889,
        lat: 45.97702407836914
    },
    {
        lng: 7.5461578369140625,
        lat: 45.97413635253929
    },
    {
        lng: 7.546566963195858,
        lat: 45.97171020507824
    },
    {
        lng: 7.546904087066764,
        lat: 45.97055053710943
    },
    {
        lng: 7.547802925109863,
        lat: 45.96746444702143
    },
    {
        lng: 7.5480971336365315,
        lat: 45.96467208862305
    },
    {
        lng: 7.5480690002441975,
        lat: 45.964492797851676
    },
    {
        lng: 7.548033237457332,
        lat: 45.96426010131853
    },
    {
        lng: 7.547679901123104,
        lat: 45.96346664428705
    },
    {
        lng: 7.547409057617301,
        lat: 45.96308517456049
    },
    {
        lng: 7.546579837799129,
        lat: 45.96225738525408
    },
    {
        lng: 7.545963764190674,
        lat: 45.96110534667969
    },
    {
        lng: 7.545609951019344,
        lat: 45.960720062255916
    },
    {
        lng: 7.544503211975098,
        lat: 45.95979309082031
    },
    {
        lng: 7.5437989234924885,
        lat: 45.95935440063499
    },
    {
        lng: 7.543176174163932,
        lat: 45.959175109863224
    },
    {
        lng: 7.542123794555778,
        lat: 45.95912551879883
    },
    {
        lng: 7.541201114654541,
        lat: 45.959228515625
    },
    {
        lng: 7.5397448539734455,
        lat: 45.95939254760748
    },
    {
        lng: 7.535802841186523,
        lat: 45.95966720581055
    },
    {
        lng: 7.533347129821777,
        lat: 45.96005630493164
    },
    {
        lng: 7.531145095825195,
        lat: 45.960338592529354
    },
    {
        lng: 7.527513980865535,
        lat: 45.960990905761946
    },
    {
        lng: 7.526673793792725,
        lat: 45.96121597290056
    },
    {
        lng: 7.525849819183293,
        lat: 45.96156692504883
    },
    {
        lng: 7.524724960327092,
        lat: 45.962226867675724
    },
    {
        lng: 7.522595882415828,
        lat: 45.963603973388786
    },
    {
        lng: 7.521845817565918,
        lat: 45.96397781372082
    },
    {
        lng: 7.52127313613903,
        lat: 45.96417236328142
    },
    {
        lng: 7.521102905273551,
        lat: 45.96422958374018
    },
    {
        lng: 7.519765853881779,
        lat: 45.964492797851676
    },
    {
        lng: 7.51881313323986,
        lat: 45.96452331542969
    },
    {
        lng: 7.517911911010742,
        lat: 45.964389801025675
    },
    {
        lng: 7.517055988311824,
        lat: 45.96416091918951
    },
    {
        lng: 7.516110897064266,
        lat: 45.96373748779308
    },
    {
        lng: 7.514936923980713,
        lat: 45.963497161865405
    },
    {
        lng: 7.513076782226676,
        lat: 45.96338653564453
    },
    {
        lng: 7.512639045715446,
        lat: 45.96338272094732
    },
    {
        lng: 7.509284973144645,
        lat: 45.963325500488395
    },
    {
        lng: 7.508364200592041,
        lat: 45.9634704589846
    },
    {
        lng: 7.507462024688834,
        lat: 45.96370697021496
    },
    {
        lng: 7.506284236908073,
        lat: 45.9642868041995
    },
    {
        lng: 7.50434684753418,
        lat: 45.96487808227562
    },
    {
        lng: 7.503162860870418,
        lat: 45.96543121337919
    },
    {
        lng: 7.502486228942871,
        lat: 45.96559143066412
    },
    {
        lng: 7.501747131347656,
        lat: 45.96564102172874
    },
    {
        lng: 7.501017093658504,
        lat: 45.965564727783146
    },
    {
        lng: 7.50042104721075,
        lat: 45.96535873413097
    },
    {
        lng: 7.49904823303234,
        lat: 45.96440505981457
    },
    {
        lng: 7.498737812042293,
        lat: 45.96397018432623
    },
    {
        lng: 7.498198032379207,
        lat: 45.96272659301758
    },
    {
        lng: 7.497861862182617,
        lat: 45.96221160888683
    },
    {
        lng: 7.496929168701229,
        lat: 45.96106338500999
    },
    {
        lng: 7.496481895446891,
        lat: 45.96069717407249
    },
    {
        lng: 7.496157169342041,
        lat: 45.960536956787166
    },
    {
        lng: 7.495036125183219,
        lat: 45.960250854492244
    },
    {
        lng: 7.492411136627254,
        lat: 45.959899902344034
    },
    {
        lng: 7.490602016448975,
        lat: 45.95992660522484
    },
    {
        lng: 7.4881892204285805,
        lat: 45.96014404296875
    },
    {
        lng: 7.487175941467399,
        lat: 45.959533691406364
    },
    {
        lng: 7.486012935638485,
        lat: 45.95896148681652
    },
    {
        lng: 7.485194206237793,
        lat: 45.95834350585943
    },
    {
        lng: 7.48452806472784,
        lat: 45.957931518554915
    },
    {
        lng: 7.483442783355656,
        lat: 45.95726394653343
    },
    {
        lng: 7.481200218200797,
        lat: 45.956565856933594
    },
    {
        lng: 7.479465007782039,
        lat: 45.955493927002124
    },
    {
        lng: 7.47786903381359,
        lat: 45.95420837402338
    },
    {
        lng: 7.477686882019157,
        lat: 45.954063415527514
    },
    {
        lng: 7.476210117340088,
        lat: 45.95211410522484
    },
    {
        lng: 7.47558689117426,
        lat: 45.95058822631859
    },
    {
        lng: 7.47547817230236,
        lat: 45.95006561279297
    },
    {
        lng: 7.47547817230236,
        lat: 45.94951248168974
    },
    {
        lng: 7.4757280349731445,
        lat: 45.948440551757926
    },
    {
        lng: 7.475877761840877,
        lat: 45.947795867920206
    },
    {
        lng: 7.47630691528326,
        lat: 45.94456863403332
    },
    {
        lng: 7.476902961731014,
        lat: 45.94279098510748
    },
    {
        lng: 7.477579116821289,
        lat: 45.940425872802905
    },
    {
        lng: 7.477666854858398,
        lat: 45.939632415771484
    },
    {
        lng: 7.47753810882574,
        lat: 45.939083099365405
    },
    {
        lng: 7.477132797241268,
        lat: 45.938449859619425
    },
    {
        lng: 7.476551055908317,
        lat: 45.93789672851585
    },
    {
        lng: 7.475853919982967,
        lat: 45.93741226196312
    },
    {
        lng: 7.475047111511174,
        lat: 45.937011718750284
    },
    {
        lng: 7.474143028259391,
        lat: 45.936756134033146
    },
    {
        lng: 7.473209857940731,
        lat: 45.93664932250999
    },
    {
        lng: 7.471787929534969,
        lat: 45.936660766601506
    },
    {
        lng: 7.470849990844727,
        lat: 45.936759948730696
    },
    {
        lng: 7.469942092895508,
        lat: 45.93694686889643
    },
    {
        lng: 7.467978954315299,
        lat: 45.93762207031244
    },
    {
        lng: 7.4670968055726235,
        lat: 45.937835693359375
    },
    {
        lng: 7.465897083282584,
        lat: 45.93805313110363
    },
    {
        lng: 7.463978767395076,
        lat: 45.93839645385748
    },
    {
        lng: 7.463125228881836,
        lat: 45.93847274780268
    },
    {
        lng: 7.462275981903133,
        lat: 45.938453674316634
    },
    {
        lng: 7.461465835571403,
        lat: 45.93832015991228
    },
    {
        lng: 7.4608101844788735,
        lat: 45.93806838989286
    },
    {
        lng: 7.460340023040715,
        lat: 45.93774414062506
    },
    {
        lng: 7.459611892700195,
        lat: 45.93695068359398
    },
    {
        lng: 7.459112167358342,
        lat: 45.93651580810564
    },
    {
        lng: 7.4584932327271645,
        lat: 45.936138153076286
    },
    {
        lng: 7.45775413513195,
        lat: 45.935855865478516
    },
    {
        lng: 7.45693922042841,
        lat: 45.935676574707315
    },
    {
        lng: 7.456441879272404,
        lat: 45.935600280761946
    },
    {
        lng: 7.454203128814697,
        lat: 45.93524932861334
    },
    {
        lng: 7.4511451721191975,
        lat: 45.93492889404308
    },
    {
        lng: 7.4485578536987305,
        lat: 45.93447875976585
    },
    {
        lng: 7.447862148284912,
        lat: 45.93425369262724
    },
    {
        lng: 7.447566986084098,
        lat: 45.93408584594721
    },
    {
        lng: 7.447145938873291,
        lat: 45.93365478515642
    },
    {
        lng: 7.446832180023307,
        lat: 45.93310928344755
    },
    {
        lng: 7.446485996246338,
        lat: 45.93250656127941
    },
    {
        lng: 7.445702075958366,
        lat: 45.93159484863304
    },
    {
        lng: 7.445251941680965,
        lat: 45.93060684204113
    },
    {
        lng: 7.444948196411133,
        lat: 45.93014907836914
    },
    {
        lng: 7.443469047546444,
        lat: 45.92887878417986
    },
    {
        lng: 7.442612171173209,
        lat: 45.92833709716797
    },
    {
        lng: 7.441858768463192,
        lat: 45.92771911621094
    },
    {
        lng: 7.440998077392578,
        lat: 45.9271812438966
    },
    {
        lng: 7.439426898956356,
        lat: 45.92592620849621
    },
    {
        lng: 7.437924861908016,
        lat: 45.924079895019474
    },
    {
        lng: 7.437075138092098,
        lat: 45.9228630065918
    },
    {
        lng: 7.436587810516471,
        lat: 45.922374725341854
    },
    {
        lng: 7.436018943786735,
        lat: 45.921955108642805
    },
    {
        lng: 7.434682846069279,
        lat: 45.921230316162166
    },
    {
        lng: 7.433869838714713,
        lat: 45.92061233520508
    },
    {
        lng: 7.432995796203613,
        lat: 45.92007827758812
    },
    {
        lng: 7.432248115539551,
        lat: 45.91945648193371
    },
    {
        lng: 7.431371212005615,
        lat: 45.91891860961937
    },
    {
        lng: 7.430534839630184,
        lat: 45.91831588745117
    },
    {
        lng: 7.42799520492548,
        lat: 45.91726303100597
    },
    {
        lng: 7.425378799438533,
        lat: 45.91656494140631
    },
    {
        lng: 7.424826145172233,
        lat: 45.916271209716854
    },
    {
        lng: 7.423647880554256,
        lat: 45.91534423828142
    },
    {
        lng: 7.4226632118226235,
        lat: 45.9148216247558
    },
    {
        lng: 7.42067813873291,
        lat: 45.91421127319347
    },
    {
        lng: 7.418982982635441,
        lat: 45.913772583007756
    },
    {
        lng: 7.41752481460577,
        lat: 45.913234710693416
    },
    {
        lng: 7.417043209075871,
        lat: 45.912952423095874
    },
    {
        lng: 7.416834831237907,
        lat: 45.91275787353521
    },
    {
        lng: 7.416058063507023,
        lat: 45.911705017089844
    },
    {
        lng: 7.41548490524292,
        lat: 45.911350250244254
    },
    {
        lng: 7.4151039123535725,
        lat: 45.91122436523443
    },
    {
        lng: 7.414247035980338,
        lat: 45.911079406738565
    },
    {
        lng: 7.413312911987418,
        lat: 45.9110221862793
    },
    {
        lng: 7.411838054656926,
        lat: 45.9110221862793
    },
    {
        lng: 7.410337924957389,
        lat: 45.91110610961937
    },
    {
        lng: 7.408882141113338,
        lat: 45.91130447387718
    },
    {
        lng: 7.406505107879582,
        lat: 45.91202163696289
    },
    {
        lng: 7.405280113220272,
        lat: 45.91217803955084
    },
    {
        lng: 7.404111862182674,
        lat: 45.9120826721192
    },
    {
        lng: 7.403498172760067,
        lat: 45.91186141967796
    },
    {
        lng: 7.40186977386486,
        lat: 45.91093063354498
    },
    {
        lng: 7.401436805725098,
        lat: 45.91058349609375
    },
    {
        lng: 7.401085853576717,
        lat: 45.91016006469749
    },
    {
        lng: 7.400379180908203,
        lat: 45.90874099731445
    },
    {
        lng: 7.399952888488713,
        lat: 45.90724945068371
    },
    {
        lng: 7.399716854095459,
        lat: 45.90666961669933
    },
    {
        lng: 7.39916896820074,
        lat: 45.90588378906256
    },
    {
        lng: 7.398083209991569,
        lat: 45.904613494873274
    },
    {
        lng: 7.397229194641227,
        lat: 45.9040336608889
    },
    {
        lng: 7.39647102355957,
        lat: 45.90343475341808
    },
    {
        lng: 7.395602226257381,
        lat: 45.902919769287166
    },
    {
        lng: 7.394846916198787,
        lat: 45.90233230590843
    },
    {
        lng: 7.393989086151237,
        lat: 45.90181350708019
    },
    {
        lng: 7.393235206604004,
        lat: 45.901226043701286
    },
    {
        lng: 7.391442775726318,
        lat: 45.900199890137
    },
    {
        lng: 7.390703201294059,
        lat: 45.89998245239275
    },
    {
        lng: 7.389424800872803,
        lat: 45.89982223510742
    },
    {
        lng: 7.386588096618709,
        lat: 45.899669647217024
    },
    {
        lng: 7.385643005371151,
        lat: 45.899753570556584
    },
    {
        lng: 7.384440898895264,
        lat: 45.900032043457145
    },
    {
        lng: 7.3829622268677895,
        lat: 45.90071105957054
    },
    {
        lng: 7.381646156311092,
        lat: 45.9010658264163
    },
    {
        lng: 7.381031990051383,
        lat: 45.901290893554915
    },
    {
        lng: 7.378200054168758,
        lat: 45.90303802490246
    },
    {
        lng: 7.375068187713737,
        lat: 45.90440368652372
    },
    {
        lng: 7.374811172485408,
        lat: 45.904518127441634
    },
    {
        lng: 7.373360157012996,
        lat: 45.90537261962902
    },
    {
        lng: 7.371416091919059,
        lat: 45.90615081787104
    },
    {
        lng: 7.37064981460577,
        lat: 45.90638351440441
    },
    {
        lng: 7.369828224182186,
        lat: 45.906494140625284
    },
    {
        lng: 7.368644237518254,
        lat: 45.9064559936524
    },
    {
        lng: 7.367972850799617,
        lat: 45.9062957763673
    },
    {
        lng: 7.367072105407715,
        lat: 45.905876159668026
    },
    {
        lng: 7.3660778999328045,
        lat: 45.905696868896484
    },
    {
        lng: 7.3653578758240315,
        lat: 45.90572357177746
    },
    {
        lng: 7.36468076705944,
        lat: 45.90588378906256
    },
    {
        lng: 7.362913131713924,
        lat: 45.90692901611328
    },
    {
        lng: 7.361310958862305,
        lat: 45.908222198486555
    },
    {
        lng: 7.3609490394592285,
        lat: 45.90862655639671
    },
    {
        lng: 7.360045909881535,
        lat: 45.90986633300804
    },
    {
        lng: 7.359707832336369,
        lat: 45.910442352294865
    },
    {
        lng: 7.359485149383602,
        lat: 45.911087036132926
    },
    {
        lng: 7.359059810638371,
        lat: 45.913036346435604
    },
    {
        lng: 7.358724117279053,
        lat: 45.9136314392091
    },
    {
        lng: 7.358291149139518,
        lat: 45.914035797119425
    },
    {
        lng: 7.357723236084098,
        lat: 45.91435241699213
    },
    {
        lng: 7.356440067291317,
        lat: 45.91477966308594
    },
    {
        lng: 7.355793952941951,
        lat: 45.91495132446295
    },
    {
        lng: 7.35347414016735,
        lat: 45.91540527343773
    },
    {
        lng: 7.353128910064697,
        lat: 45.915561676025675
    },
    {
        lng: 7.352167129516715,
        lat: 45.916004180908374
    },
    {
        lng: 7.350166797637883,
        lat: 45.91659927368164
    },
    {
        lng: 7.349172115325928,
        lat: 45.91706848144531
    },
    {
        lng: 7.348567008972282,
        lat: 45.917282104492415
    },
    {
        lng: 7.347402095794791,
        lat: 45.91741180419922
    },
    {
        lng: 7.346167087554932,
        lat: 45.9172935485841
    },
    {
        lng: 7.344282150268498,
        lat: 45.9167175292971
    },
    {
        lng: 7.342009067535457,
        lat: 45.916183471679744
    },
    {
        lng: 7.340391159057674,
        lat: 45.915462493896484
    },
    {
        lng: 7.338111877441463,
        lat: 45.914943695068416
    },
    {
        lng: 7.3361949920654865,
        lat: 45.91432952880871
    },
    {
        lng: 7.3358030319215,
        lat: 45.91424942016596
    },
    {
        lng: 7.335354804992676,
        lat: 45.91415786743187
    },
    {
        lng: 7.332166194915715,
        lat: 45.913684844970646
    },
    {
        lng: 7.330844879150504,
        lat: 45.913593292236555
    },
    {
        lng: 7.32951021194458,
        lat: 45.91364669799833
    },
    {
        lng: 7.326302051544303,
        lat: 45.914123535156534
    },
    {
        lng: 7.325446128845215,
        lat: 45.91427993774414
    },
    {
        lng: 7.324633121490592,
        lat: 45.91451644897484
    },
    {
        lng: 7.324168205261344,
        lat: 45.9147186279298
    },
    {
        lng: 7.322899818420467,
        lat: 45.91526794433622
    },
    {
        lng: 7.3220820426942055,
        lat: 45.91572952270519
    },
    {
        lng: 7.321664810180778,
        lat: 45.91612625122099
    },
    {
        lng: 7.320678234100399,
        lat: 45.9173202514649
    },
    {
        lng: 7.319606781005973,
        lat: 45.91878890991222
    },
    {
        lng: 7.31876993179327,
        lat: 45.91949081420921
    },
    {
        lng: 7.318057060241813,
        lat: 45.91984939575195
    },
    {
        lng: 7.316831111908016,
        lat: 45.92016220092768
    },
    {
        lng: 7.313629150390568,
        lat: 45.92066955566406
    },
    {
        lng: 7.309855937957764,
        lat: 45.920944213867244
    },
    {
        lng: 7.307872772216797,
        lat: 45.921234130859375
    },
    {
        lng: 7.305069923400822,
        lat: 45.921474456787394
    },
    {
        lng: 7.303728103637809,
        lat: 45.92170715332037
    },
    {
        lng: 7.30278396606451,
        lat: 45.922027587890625
    },
    {
        lng: 7.30070781707775,
        lat: 45.92289733886747
    },
    {
        lng: 7.299802780151481,
        lat: 45.92351150512718
    },
    {
        lng: 7.2985730171203045,
        lat: 45.924095153808594
    },
    {
        lng: 7.297874927520866,
        lat: 45.92448425292969
    },
    {
        lng: 7.296175956726074,
        lat: 45.92567825317394
    },
    {
        lng: 7.294442176818848,
        lat: 45.92403411865246
    },
    {
        lng: 7.293282985687313,
        lat: 45.92248535156256
    },
    {
        lng: 7.292809009552002,
        lat: 45.92196273803734
    },
    {
        lng: 7.292037010192814,
        lat: 45.921115875244084
    },
    {
        lng: 7.291611194610539,
        lat: 45.920776367187614
    },
    {
        lng: 7.291480064392033,
        lat: 45.92070770263672
    },
    {
        lng: 7.291323184967041,
        lat: 45.92062377929699
    },
    {
        lng: 7.2900519371032715,
        lat: 45.92018890380865
    },
    {
        lng: 7.289451122284049,
        lat: 45.91991043090826
    },
    {
        lng: 7.288839817047233,
        lat: 45.91936874389677
    },
    {
        lng: 7.287838935852051,
        lat: 45.918170928955305
    },
    {
        lng: 7.287527084350643,
        lat: 45.91761779785156
    },
    {
        lng: 7.2869739532470135,
        lat: 45.91625595092796
    },
    {
        lng: 7.286038875579834,
        lat: 45.915054321289176
    },
    {
        lng: 7.285605907440242,
        lat: 45.91400146484392
    },
    {
        lng: 7.285354137420654,
        lat: 45.91338729858421
    },
    {
        lng: 7.284516811370963,
        lat: 45.91201400756836
    },
    {
        lng: 7.283826828002873,
        lat: 45.910346984863224
    },
    {
        lng: 7.282900810241756,
        lat: 45.90914535522478
    },
    {
        lng: 7.282340049743652,
        lat: 45.90744781494169
    },
    {
        lng: 7.28173923492443,
        lat: 45.905971527099666
    },
    {
        lng: 7.2813482284547035,
        lat: 45.90527725219738
    },
    {
        lng: 7.280868053436393,
        lat: 45.90465164184576
    },
    {
        lng: 7.2804279327393715,
        lat: 45.90428543090832
    },
    {
        lng: 7.279764175415039,
        lat: 45.904006958007926
    },
    {
        lng: 7.277511119842529,
        lat: 45.903556823730696
    },
    {
        lng: 7.275814056396484,
        lat: 45.90312576293951
    },
    {
        lng: 7.2750749588012695,
        lat: 45.902847290039176
    },
    {
        lng: 7.274343013763541,
        lat: 45.902450561523494
    },
    {
        lng: 7.273412227630558,
        lat: 45.901744842529524
    },
    {
        lng: 7.27243089675909,
        lat: 45.90071868896507
    },
    {
        lng: 7.271597862243766,
        lat: 45.900108337402344
    },
    {
        lng: 7.270866870880127,
        lat: 45.89946365356468
    },
    {
        lng: 7.270021915436018,
        lat: 45.89891815185547
    },
    {
        lng: 7.2692818641663735,
        lat: 45.89830017089872
    },
    {
        lng: 7.268432140350399,
        lat: 45.89775466918968
    },
    {
        lng: 7.267695903778076,
        lat: 45.89711761474615
    },
    {
        lng: 7.26686000823986,
        lat: 45.89650726318382
    },
    {
        lng: 7.265618801117,
        lat: 45.89516830444347
    },
    {
        lng: 7.264369964599609,
        lat: 45.89409255981445
    },
    {
        lng: 7.262650012969971,
        lat: 45.8928031921389
    },
    {
        lng: 7.261526107788086,
        lat: 45.892131805419865
    },
    {
        lng: 7.260677814483643,
        lat: 45.891799926757926
    },
    {
        lng: 7.260251045227108,
        lat: 45.891704559326286
    },
    {
        lng: 7.259384155273494,
        lat: 45.89162445068388
    },
    {
        lng: 7.258528232574577,
        lat: 45.89167785644531
    },
    {
        lng: 7.258118152618465,
        lat: 45.89175796508789
    },
    {
        lng: 7.257431030273494,
        lat: 45.8920135498048
    },
    {
        lng: 7.256941795349235,
        lat: 45.89233398437506
    },
    {
        lng: 7.255539894104004,
        lat: 45.893749237060774
    },
    {
        lng: 7.255445957183895,
        lat: 45.89394760131859
    },
    {
        lng: 7.254991054535026,
        lat: 45.894920349121094
    },
    {
        lng: 7.2546730041504475,
        lat: 45.895370483398665
    },
    {
        lng: 7.254232883453369,
        lat: 45.89566421508812
    },
    {
        lng: 7.25395584106451,
        lat: 45.89575958251976
    },
    {
        lng: 7.2533721923828125,
        lat: 45.895828247070426
    },
    {
        lng: 7.252451896667594,
        lat: 45.89574050903332
    },
    {
        lng: 7.2523541450501625,
        lat: 45.895721435546875
    },
    {
        lng: 7.251791000366211,
        lat: 45.89560317993181
    },
    {
        lng: 7.249955177307129,
        lat: 45.89496994018566
    },
    {
        lng: 7.247052192687988,
        lat: 45.89434051513672
    },
    {
        lng: 7.245498180389461,
        lat: 45.893833160400504
    },
    {
        lng: 7.245145797729492,
        lat: 45.893745422363224
    },
    {
        lng: 7.244680881500244,
        lat: 45.89363098144554
    },
    {
        lng: 7.243402004241943,
        lat: 45.89345169067394
    },
    {
        lng: 7.243188858032283,
        lat: 45.8934326171875
    },
    {
        lng: 7.241495132446516,
        lat: 45.89329147338867
    },
    {
        lng: 7.240516185760498,
        lat: 45.89333724975586
    },
    {
        lng: 7.239141941070557,
        lat: 45.89354324340843
    },
    {
        lng: 7.238658905029354,
        lat: 45.8936195373538
    },
    {
        lng: 7.2382569313049885,
        lat: 45.893680572509766
    },
    {
        lng: 7.237587928771973,
        lat: 45.89388656616228
    },
    {
        lng: 7.236614227295036,
        lat: 45.89435577392595
    },
    {
        lng: 7.23587608337408,
        lat: 45.894554138183764
    },
    {
        lng: 7.234591960907096,
        lat: 45.89467620849638
    },
    {
        lng: 7.233234882354793,
        lat: 45.89462661743164
    },
    {
        lng: 7.232343196868953,
        lat: 45.894477844238565
    },
    {
        lng: 7.230414867401123,
        lat: 45.89385986328131
    },
    {
        lng: 7.228639125824088,
        lat: 45.89342117309582
    },
    {
        lng: 7.228319168090877,
        lat: 45.89334487915062
    },
    {
        lng: 7.227556228637752,
        lat: 45.89319610595703
    },
    {
        lng: 7.225624084472713,
        lat: 45.8929557800293
    },
    {
        lng: 7.225403785705566,
        lat: 45.89292526245134
    },
    {
        lng: 7.224389076232853,
        lat: 45.89271926879883
    },
    {
        lng: 7.224304199218864,
        lat: 45.89270019531273
    },
    {
        lng: 7.222943782806453,
        lat: 45.892330169677734
    },
    {
        lng: 7.221920967102278,
        lat: 45.89184188842796
    },
    {
        lng: 7.221097946166992,
        lat: 45.891139984131144
    },
    {
        lng: 7.219895839691276,
        lat: 45.889373779296875
    },
    {
        lng: 7.219567775726318,
        lat: 45.888816833496094
    },
    {
        lng: 7.219034194946403,
        lat: 45.88741302490246
    },
    {
        lng: 7.218733787536678,
        lat: 45.886932373046875
    },
    {
        lng: 7.21728086471569,
        lat: 45.88563919067377
    },
    {
        lng: 7.215767860412711,
        lat: 45.884681701660384
    },
    {
        lng: 7.215256214141959,
        lat: 45.884422302246094
    },
    {
        lng: 7.213407039642391,
        lat: 45.88389968872099
    },
    {
        lng: 7.21142578125,
        lat: 45.88354492187523
    },
    {
        lng: 7.211050033569393,
        lat: 45.883441925049055
    },
    {
        lng: 7.2104249000549885,
        lat: 45.883163452148665
    },
    {
        lng: 7.209983825683651,
        lat: 45.88281250000006
    },
    {
        lng: 7.2097868919373695,
        lat: 45.88257217407238
    },
    {
        lng: 7.209129810333479,
        lat: 45.88133621215843
    },
    {
        lng: 7.20878887176508,
        lat: 45.88095474243187
    },
    {
        lng: 7.2074980735779945,
        lat: 45.879863739013786
    },
    {
        lng: 7.207042217254639,
        lat: 45.879581451416186
    },
    {
        lng: 7.206632137298698,
        lat: 45.87933349609398
    },
    {
        lng: 7.205838203430176,
        lat: 45.87873458862316
    },
    {
        lng: 7.204895019531364,
        lat: 45.87818527221691
    },
    {
        lng: 7.204294204711971,
        lat: 45.87765502929693
    },
    {
        lng: 7.203972816467228,
        lat: 45.877136230468864
    },
    {
        lng: 7.202508926391602,
        lat: 45.873569488525504
    },
    {
        lng: 7.2023220062257,
        lat: 45.873023986816406
    },
    {
        lng: 7.202211856842041,
        lat: 45.872390747070256
    },
    {
        lng: 7.202179908752669,
        lat: 45.87143707275385
    },
    {
        lng: 7.202242851257381,
        lat: 45.870803833007926
    },
    {
        lng: 7.202794075012207,
        lat: 45.86893463134777
    },
    {
        lng: 7.203084945678825,
        lat: 45.86734008789085
    },
    {
        lng: 7.203100204467887,
        lat: 45.866916656494425
    },
    {
        lng: 7.202911853790226,
        lat: 45.866310119628906
    },
    {
        lng: 7.202701091766414,
        lat: 45.86590576171869
    },
    {
        lng: 7.202395915985221,
        lat: 45.86553573608404
    },
    {
        lng: 7.200743198394889,
        lat: 45.864391326904354
    },
    {
        lng: 7.2001948356629555,
        lat: 45.864143371582145
    },
    {
        lng: 7.198136806488151,
        lat: 45.86339950561546
    },
    {
        lng: 7.197868824005184,
        lat: 45.86334228515631
    },
    {
        lng: 7.196782112121696,
        lat: 45.86312103271507
    },
    {
        lng: 7.194797039031982,
        lat: 45.862991333007926
    },
    {
        lng: 7.192938804626522,
        lat: 45.86300659179716
    },
    {
        lng: 7.192326068878117,
        lat: 45.86301040649414
    },
    {
        lng: 7.191398143768424,
        lat: 45.86308288574236
    },
    {
        lng: 7.1909499168396,
        lat: 45.863155364990234
    },
    {
        lng: 7.190554141998405,
        lat: 45.863220214843864
    },
    {
        lng: 7.190182209015006,
        lat: 45.863330841064396
    },
    {
        lng: 7.188926219940299,
        lat: 45.863864898681925
    },
    {
        lng: 7.187617778778133,
        lat: 45.86420059204107
    },
    {
        lng: 7.187014102935848,
        lat: 45.864414215088004
    },
    {
        lng: 7.184703826904524,
        lat: 45.86580276489252
    },
    {
        lng: 7.183922767639274,
        lat: 45.8661460876466
    },
    {
        lng: 7.182363033294735,
        lat: 45.8665466308596
    },
    {
        lng: 7.1794519424439045,
        lat: 45.86693954467785
    },
    {
        lng: 7.178190231323242,
        lat: 45.86724853515625
    },
    {
        lng: 7.177328109741268,
        lat: 45.86755371093756
    },
    {
        lng: 7.175613880157584,
        lat: 45.86858749389654
    },
    {
        lng: 7.174883842468262,
        lat: 45.869163513183594
    },
    {
        lng: 7.174048900604362,
        lat: 45.8696746826173
    },
    {
        lng: 7.1733078956603435,
        lat: 45.8702507019043
    },
    {
        lng: 7.171572208404598,
        lat: 45.871322631836165
    },
    {
        lng: 7.170076847076643,
        lat: 45.8719940185548
    },
    {
        lng: 7.168951034546012,
        lat: 45.872642517089844
    },
    {
        lng: 7.167932987213135,
        lat: 45.87340927124052
    },
    {
        lng: 7.167398929596004,
        lat: 45.87397766113281
    },
    {
        lng: 7.167094230651912,
        lat: 45.87461090087896
    },
    {
        lng: 7.167134761810416,
        lat: 45.8753089904788
    },
    {
        lng: 7.167502880096492,
        lat: 45.8762779235841
    },
    {
        lng: 7.167797088623161,
        lat: 45.876724243164176
    },
    {
        lng: 7.168357849121151,
        lat: 45.87757873535173
    },
    {
        lng: 7.168543815612736,
        lat: 45.87815093994158
    },
    {
        lng: 7.1685738563538735,
        lat: 45.87872695922863
    },
    {
        lng: 7.168437957763672,
        lat: 45.87927627563482
    },
    {
        lng: 7.168291091919059,
        lat: 45.87952804565441
    },
    {
        lng: 7.166973114013729,
        lat: 45.880649566650675
    },
    {
        lng: 7.166197776794434,
        lat: 45.88106918334955
    },
    {
        lng: 7.1637701988220215,
        lat: 45.88194274902344
    },
    {
        lng: 7.162930965423698,
        lat: 45.88210296630871
    },
    {
        lng: 7.160161972045955,
        lat: 45.88238906860363
    },
    {
        lng: 7.157534122467155,
        lat: 45.88274002075207
    },
    {
        lng: 7.155013084411678,
        lat: 45.88197708129894
    },
    {
        lng: 7.153744220733756,
        lat: 45.88133621215843
    },
    {
        lng: 7.151737213134879,
        lat: 45.88077926635765
    },
    {
        lng: 7.150197982788143,
        lat: 45.8800621032716
    },
    {
        lng: 7.148500919342098,
        lat: 45.87962722778343
    },
    {
        lng: 7.146949768066406,
        lat: 45.8789138793947
    },
    {
        lng: 7.145246982574577,
        lat: 45.878486633300724
    },
    {
        lng: 7.143994808197078,
        lat: 45.87789916992199
    },
    {
        lng: 7.14251184463501,
        lat: 45.877506256103516
    },
    {
        lng: 7.141993045806885,
        lat: 45.87737274169922
    },
    {
        lng: 7.141425132751465,
        lat: 45.87712097167997
    },
    {
        lng: 7.140697956085205,
        lat: 45.87667465209955
    },
    {
        lng: 7.139811992645377,
        lat: 45.87613296508789
    },
    {
        lng: 7.1392412185668945,
        lat: 45.87557983398449
    },
    {
        lng: 7.139059066772404,
        lat: 45.875267028808594
    },
    {
        lng: 7.138844966888485,
        lat: 45.8745956420899
    },
    {
        lng: 7.1384949684143635,
        lat: 45.87211990356445
    },
    {
        lng: 7.138192176818848,
        lat: 45.87146377563482
    },
    {
        lng: 7.137618064880371,
        lat: 45.87089538574236
    },
    {
        lng: 7.135770797729606,
        lat: 45.869792938232536
    },
    {
        lng: 7.133484840393123,
        lat: 45.86911392211914
    },
    {
        lng: 7.132159233093262,
        lat: 45.868450164795206
    },
    {
        lng: 7.130338191986198,
        lat: 45.867717742919865
    },
    {
        lng: 7.127949237823543,
        lat: 45.86675643920893
    },
    {
        lng: 7.127051830291805,
        lat: 45.8661537170413
    },
    {
        lng: 7.126183032989616,
        lat: 45.865303039551065
    },
    {
        lng: 7.1260600090026855,
        lat: 45.86518478393566
    },
    {
        lng: 7.125236034393424,
        lat: 45.864574432373274
    },
    {
        lng: 7.12451696395874,
        lat: 45.86391830444336
    },
    {
        lng: 7.123678207397518,
        lat: 45.86336135864275
    },
    {
        lng: 7.122879981994743,
        lat: 45.86273956298851
    },
    {
        lng: 7.122085094451904,
        lat: 45.86236572265648
    },
    {
        lng: 7.121170043945256,
        lat: 45.86217880249018
    },
    {
        lng: 7.120201110839957,
        lat: 45.86212921142595
    },
    {
        lng: 7.118496894836426,
        lat: 45.86227416992182
    },
    {
        lng: 7.117790222167969,
        lat: 45.862335205078125
    },
    {
        lng: 7.116406917572135,
        lat: 45.8623046875
    },
    {
        lng: 7.115598201751936,
        lat: 45.862144470214844
    },
    {
        lng: 7.11459684371971,
        lat: 45.86175918579113
    },
    {
        lng: 7.1137690544129555,
        lat: 45.86157608032255
    },
    {
        lng: 7.112827777862549,
        lat: 45.86148834228544
    },
    {
        lng: 7.1118321418762775,
        lat: 45.86147689819336
    },
    {
        lng: 7.110329151153792,
        lat: 45.86164474487322
    },
    {
        lng: 7.108418941497916,
        lat: 45.86219024658226
    },
    {
        lng: 7.107316017150879,
        lat: 45.86245346069359
    },
    {
        lng: 7.106627941131592,
        lat: 45.862617492675895
    },
    {
        lng: 7.105835914611873,
        lat: 45.8629417419433
    },
    {
        lng: 7.105521202087402,
        lat: 45.86317062377947
    },
    {
        lng: 7.104246139526424,
        lat: 45.864658355712834
    },
    {
        lng: 7.103940010070744,
        lat: 45.86523818969721
    },
    {
        lng: 7.103484153747615,
        lat: 45.866474151611555
    },
    {
        lng: 7.102603912353516,
        lat: 45.867729187011776
    },
    {
        lng: 7.102139949798527,
        lat: 45.869609832763786
    },
    {
        lng: 7.101870059966984,
        lat: 45.8701744079591
    },
    {
        lng: 7.101254940033016,
        lat: 45.870864868164006
    },
    {
        lng: 7.100663185119629,
        lat: 45.87192916870117
    },
    {
        lng: 7.100297927856445,
        lat: 45.87243270874035
    },
    {
        lng: 7.098357200622615,
        lat: 45.87403869628912
    },
    {
        lng: 7.097961902618522,
        lat: 45.874542236328125
    },
    {
        lng: 7.097798824310303,
        lat: 45.875160217285384
    },
    {
        lng: 7.097867965698242,
        lat: 45.87613296508789
    },
    {
        lng: 7.098094940185604,
        lat: 45.87673568725586
    },
    {
        lng: 7.098893165588379,
        lat: 45.8776473999024
    },
    {
        lng: 7.099136829376334,
        lat: 45.87818527221691
    },
    {
        lng: 7.099165916442985,
        lat: 45.878765106201286
    },
    {
        lng: 7.098952770233154,
        lat: 45.879314422607706
    },
    {
        lng: 7.097611904144401,
        lat: 45.88054275512695
    },
    {
        lng: 7.096785068512077,
        lat: 45.881118774414176
    },
    {
        lng: 7.09578990936285,
        lat: 45.88212966918951
    },
    {
        lng: 7.094872951507625,
        lat: 45.882915496826286
    },
    {
        lng: 7.093142986297607,
        lat: 45.88421249389671
    },
    {
        lng: 7.091310977935848,
        lat: 45.88547134399414
    },
    {
        lng: 7.090164184570369,
        lat: 45.88616180419939
    },
    {
        lng: 7.089358806610107,
        lat: 45.886562347412394
    },
    {
        lng: 7.087250232696761,
        lat: 45.88726806640619
    },
    {
        lng: 7.086308002471924,
        lat: 45.88758468627947
    },
    {
        lng: 7.08561897277832,
        lat: 45.887943267822266
    },
    {
        lng: 7.085361957550049,
        lat: 45.888179779052734
    },
    {
        lng: 7.084537029266301,
        lat: 45.88946533203148
    },
    {
        lng: 7.083716869354248,
        lat: 45.8904075622558
    },
    {
        lng: 7.083118915557918,
        lat: 45.89197921752947
    },
    {
        lng: 7.082414150238037,
        lat: 45.8933563232423
    },
    {
        lng: 7.082207202911377,
        lat: 45.89442062377924
    },
    {
        lng: 7.082100868225041,
        lat: 45.89656829833996
    },
    {
        lng: 7.0818829536439125,
        lat: 45.89722824096691
    },
    {
        lng: 7.081473827362117,
        lat: 45.89772796630888
    },
    {
        lng: 7.08096981048584,
        lat: 45.898044586181584
    },
    {
        lng: 7.08054876327526,
        lat: 45.89820480346708
    },
    {
        lng: 7.077807903289909,
        lat: 45.89882659912115
    },
    {
        lng: 7.074317932128906,
        lat: 45.90025711059576
    },
    {
        lng: 7.072554111480827,
        lat: 45.90142059326189
    },
    {
        lng: 7.070847988128719,
        lat: 45.90319442749018
    },
    {
        lng: 7.0702161788941,
        lat: 45.90404891967796
    },
    {
        lng: 7.069976806640852,
        lat: 45.90475463867199
    },
    {
        lng: 7.06988620758051,
        lat: 45.905860900879134
    },
    {
        lng: 7.07006311416626,
        lat: 45.90867996215832
    },
    {
        lng: 7.070100784301701,
        lat: 45.909290313720646
    },
    {
        lng: 7.070057868957576,
        lat: 45.91040420532255
    },
    {
        lng: 7.069522857666129,
        lat: 45.91247558593767
    },
    {
        lng: 7.069264888763485,
        lat: 45.91307830810547
    },
    {
        lng: 7.069065093994141,
        lat: 45.913345336914176
    },
    {
        lng: 7.067557811737117,
        lat: 45.91456604003906
    },
    {
        lng: 7.066681861877441,
        lat: 45.91508102416992
    },
    {
        lng: 7.0659031867981525,
        lat: 45.915664672851676
    },
    {
        lng: 7.064080238342342,
        lat: 45.916732788086165
    },
    {
        lng: 7.061387062072811,
        lat: 45.91769790649414
    },
    {
        lng: 7.05916881561285,
        lat: 45.91819763183611
    },
    {
        lng: 7.058404922485408,
        lat: 45.918510437012
    },
    {
        lng: 7.057912826538313,
        lat: 45.918983459472656
    },
    {
        lng: 7.057264804840145,
        lat: 45.91999435424805
    },
    {
        lng: 7.056611061096248,
        lat: 45.92065048217796
    },
    {
        lng: 7.055895805358944,
        lat: 45.9219970703125
    },
    {
        lng: 7.055461883544865,
        lat: 45.9224739074707
    },
    {
        lng: 7.053626060485783,
        lat: 45.92402267456072
    },
    {
        lng: 7.052640914917106,
        lat: 45.924537658691634
    },
    {
        lng: 7.051020145416317,
        lat: 45.92519760131859
    },
    {
        lng: 7.050404071807975,
        lat: 45.92559814453119
    },
    {
        lng: 7.049919128418026,
        lat: 45.92608642578148
    },
    {
        lng: 7.049570083618164,
        lat: 45.9267387390139
    },
    {
        lng: 7.048982143402213,
        lat: 45.928844451904524
    },
    {
        lng: 7.048735141754264,
        lat: 45.93138504028349
    },
    {
        lng: 7.048567771911678,
        lat: 45.932079315185774
    },
    {
        lng: 7.048248767852783,
        lat: 45.93275070190424
    },
    {
        lng: 7.047697067260685,
        lat: 45.93358612060575
    },
    {
        lng: 7.046771049499569,
        lat: 45.93448257446289
    },
    {
        lng: 7.046308994293213,
        lat: 45.93528366088867
    },
    {
        lng: 7.045918941497803,
        lat: 45.93577575683594
    },
    {
        lng: 7.0435810089112465,
        lat: 45.937564849853686
    },
    {
        lng: 7.043229103088379,
        lat: 45.93811035156256
    },
    {
        lng: 7.043088912963981,
        lat: 45.938652038574446
    },
    {
        lng: 7.042984962463493,
        lat: 45.9390563964846
    },
    {
        lng: 7.042869091033879,
        lat: 45.9398307800293
    },
    {
        lng: 7.042933940887565,
        lat: 45.940525054931754
    },
    {
        lng: 7.043435096740723,
        lat: 45.94271469116228
    },
    {
        lng: 7.0435662269592285,
        lat: 45.943286895752124
    },
    {
        lng: 7.044312953948975,
        lat: 45.94513702392601
    },
    {
        lng: 7.044614791870231,
        lat: 45.946262359619425
    },
    {
        lng: 7.045995235443172,
        lat: 45.953704833984375
    },
    {
        lng: 7.046276092529411,
        lat: 45.95524978637707
    },
    {
        lng: 7.046147823333797,
        lat: 45.955959320068416
    },
    {
        lng: 7.04583215713501,
        lat: 45.9565162658692
    },
    {
        lng: 7.045103073120174,
        lat: 45.95732498168974
    },
    {
        lng: 7.04453802108759,
        lat: 45.957683563232536
    },
    {
        lng: 7.0440759658814045,
        lat: 45.95798110961914
    },
    {
        lng: 7.041631221771354,
        lat: 45.958278656006144
    },
    {
        lng: 7.03923320770258,
        lat: 45.958728790283374
    },
    {
        lng: 7.038379192352238,
        lat: 45.958812713623104
    },
    {
        lng: 7.036182880401611,
        lat: 45.95903015136719
    },
    {
        lng: 7.033453941345215,
        lat: 45.95960235595703
    },
    {
        lng: 7.0301861763001625,
        lat: 45.96006011962896
    },
    {
        lng: 7.029353141784668,
        lat: 45.96029281616234
    },
    {
        lng: 7.026960849762077,
        lat: 45.96112823486328
    },
    {
        lng: 7.02597808837902,
        lat: 45.961681365966854
    },
    {
        lng: 7.023983001709098,
        lat: 45.96252441406256
    },
    {
        lng: 7.021551132202262,
        lat: 45.96379852294922
    },
    {
        lng: 7.020212173461971,
        lat: 45.96450042724621
    },
    {
        lng: 7.019817829132137,
        lat: 45.964935302734546
    },
    {
        lng: 7.019083023071346,
        lat: 45.96606063842779
    },
    {
        lng: 7.018124103546199,
        lat: 45.96687316894554
    },
    {
        lng: 7.015988826751709,
        lat: 45.96921539306646
    },
    {
        lng: 7.015214920043945,
        lat: 45.969867706298885
    },
    {
        lng: 7.0148401260376545,
        lat: 45.970302581787394
    },
    {
        lng: 7.014527797698975,
        lat: 45.97119522094749
    },
    {
        lng: 7.014468193054199,
        lat: 45.97251129150402
    },
    {
        lng: 7.014661788940543,
        lat: 45.97350692749029
    },
    {
        lng: 7.015005111694393,
        lat: 45.97414398193382
    },
    {
        lng: 7.015247821808089,
        lat: 45.9744110107423
    },
    {
        lng: 7.015867233276367,
        lat: 45.974876403808594
    },
    {
        lng: 7.01661491394043,
        lat: 45.97523117065441
    },
    {
        lng: 7.0174388885498615,
        lat: 45.97546386718773
    },
    {
        lng: 7.019017219543571,
        lat: 45.975803375244254
    },
    {
        lng: 7.020456790924072,
        lat: 45.976356506347656
    },
    {
        lng: 7.021246910095215,
        lat: 45.97654342651373
    },
    {
        lng: 7.023769855499268,
        lat: 45.9768829345706
    },
    {
        lng: 7.024361133575553,
        lat: 45.97707748413109
    },
    {
        lng: 7.024254798889274,
        lat: 45.97791671752941
    },
    {
        lng: 7.023817062378043,
        lat: 45.97837066650396
    },
    {
        lng: 7.023305892944336,
        lat: 45.97888946533226
    },
    {
        lng: 7.022736072540283,
        lat: 45.979469299316634
    },
    {
        lng: 7.021137237548771,
        lat: 45.981094360351676
    },
    {
        lng: 7.020367145538387,
        lat: 45.982357025146484
    },
    {
        lng: 7.020194053650016,
        lat: 45.982643127441406
    },
    {
        lng: 7.019632816314811,
        lat: 45.983863830566634
    },
    {
        lng: 7.017913818359432,
        lat: 45.98614883422863
    },
    {
        lng: 7.017106056213379,
        lat: 45.986770629882926
    },
    {
        lng: 7.016563892364445,
        lat: 45.98707962036161
    },
    {
        lng: 7.015873908996582,
        lat: 45.987384796142805
    },
    {
        lng: 7.012292385101375,
        lat: 45.992496490478686
    },
    {
        lng: 7.011977195739803,
        lat: 45.99342727661133
    },
    {
        lng: 7.011534214019889,
        lat: 45.99515914917009
    },
    {
        lng: 7.011198997497615,
        lat: 45.99744796752947
    },
    {
        lng: 7.0109429359436035,
        lat: 45.99807357788109
    },
    {
        lng: 7.010742187499943,
        lat: 45.99836730957054
    },
    {
        lng: 7.009941101074332,
        lat: 45.999130249023665
    },
    {
        lng: 7.009306907653865,
        lat: 45.99954605102556
    },
    {
        lng: 7.007284164428711,
        lat: 46.00052261352539
    },
    {
        lng: 7.0067181587220375,
        lat: 46.00070190429699
    },
    {
        lng: 7.006401062011719,
        lat: 46.000747680664006
    },
    {
        lng: 7.0057997703552815,
        lat: 46.000751495361555
    },
    {
        lng: 7.0052938461305985,
        lat: 46.000656127929915
    },
    {
        lng: 7.004459857940617,
        lat: 46.00004577636719
    },
    {
        lng: 7.003889083862305,
        lat: 45.99987411499035
    },
    {
        lng: 7.003121852874813,
        lat: 45.99988174438488
    },
    {
        lng: 7.002690792083797,
        lat: 45.99996948242199
    },
    {
        lng: 7.002102851867676,
        lat: 46.0001335144043
    },
    {
        lng: 7.001474857330379,
        lat: 46.00041198730486
    },
    {
        lng: 7.000904083252067,
        lat: 46.000770568847656
    },
    {
        lng: 7.000399112701359,
        lat: 46.001182556152344
    },
    {
        lng: 6.999659061431885,
        lat: 46.00189208984369
    },
    {
        lng: 6.998083114624137,
        lat: 46.0030059814456
    },
    {
        lng: 6.997539997100944,
        lat: 46.00323867797874
    },
    {
        lng: 6.99604606628418,
        lat: 46.00367736816406
    },
    {
        lng: 6.994800090789852,
        lat: 46.00421142578125
    },
    {
        lng: 6.993401050567684,
        lat: 46.00450897216808
    },
    {
        lng: 6.99265813827509,
        lat: 46.00471878051758
    },
    {
        lng: 6.991973876953182,
        lat: 46.005046844482365
    },
    {
        lng: 6.991396903991699,
        lat: 46.00547409057617
    },
    {
        lng: 6.991161823272705,
        lat: 46.00572204589844
    },
    {
        lng: 6.990819931030387,
        lat: 46.00626754760765
    },
    {
        lng: 6.990771770477352,
        lat: 46.00637817382835
    },
    {
        lng: 6.989142894744873,
        lat: 46.01011276245123
    },
    {
        lng: 6.988838195800781,
        lat: 46.011089324951286
    },
    {
        lng: 6.988321781158504,
        lat: 46.013065338134766
    },
    {
        lng: 6.9879240989686195,
        lat: 46.0140266418457
    },
    {
        lng: 6.987343788146973,
        lat: 46.01485443115246
    },
    {
        lng: 6.986196041107178,
        lat: 46.016181945800895
    },
    {
        lng: 6.985486030578556,
        lat: 46.01684951782238
    },
    {
        lng: 6.985134124755859,
        lat: 46.017269134521484
    },
    {
        lng: 6.984710216522217,
        lat: 46.01811218261713
    },
    {
        lng: 6.9840412139893715,
        lat: 46.0196075439456
    },
    {
        lng: 6.983689785003719,
        lat: 46.02016067504883
    },
    {
        lng: 6.983489036560059,
        lat: 46.020416259765625
    },
    {
        lng: 6.98303413391119,
        lat: 46.02081298828142
    },
    {
        lng: 6.981987953186035,
        lat: 46.021297454834155
    },
    {
        lng: 6.98189306259178,
        lat: 46.02133941650402
    },
    {
        lng: 6.981463909149397,
        lat: 46.02158355712908
    },
    {
        lng: 6.980082988739014,
        lat: 46.022544860839844
    },
    {
        lng: 6.979049205780086,
        lat: 46.023532867431754
    },
    {
        lng: 6.977812767028865,
        lat: 46.02449798584007
    },
    {
        lng: 6.976805210113639,
        lat: 46.0251350402832
    },
    {
        lng: 6.975681781768799,
        lat: 46.025691986083984
    },
    {
        lng: 6.97522687911993,
        lat: 46.025981903076286
    },
    {
        lng: 6.973859786987418,
        lat: 46.0270233154298
    },
    {
        lng: 6.9727802276612465,
        lat: 46.028560638427734
    },
    {
        lng: 6.971014022827092,
        lat: 46.02998352050781
    },
    {
        lng: 6.970170021057129,
        lat: 46.03035354614269
    },
    {
        lng: 6.968386173248291,
        lat: 46.03076553344755
    },
    {
        lng: 6.96776914596569,
        lat: 46.030998229980526
    },
    {
        lng: 6.96728515625,
        lat: 46.031280517578125
    },
    {
        lng: 6.965696811676082,
        lat: 46.03255462646496
    },
    {
        lng: 6.965419769287223,
        lat: 46.03290939331055
    },
    {
        lng: 6.96535682678234,
        lat: 46.03299331665062
    },
    {
        lng: 6.965151309967041,
        lat: 46.033344268799055
    },
    {
        lng: 6.96481895446783,
        lat: 46.03391265869169
    },
    {
        lng: 6.963802814483756,
        lat: 46.03498458862299
    },
    {
        lng: 6.9631171226501465,
        lat: 46.03613662719721
    },
    {
        lng: 6.962080955505314,
        lat: 46.0372047424317
    },
    {
        lng: 6.961382865905875,
        lat: 46.038349151611385
    },
    {
        lng: 6.960513114929199,
        lat: 46.03919982910162
    },
    {
        lng: 6.960193157196045,
        lat: 46.03963470459013
    },
    {
        lng: 6.959650993347282,
        lat: 46.04055404663103
    },
    {
        lng: 6.9586548805237385,
        lat: 46.041667938232365
    },
    {
        lng: 6.958409786224422,
        lat: 46.042201995849894
    },
    {
        lng: 6.957964897155875,
        lat: 46.04370117187517
    },
    {
        lng: 6.957699775695801,
        lat: 46.04430770874052
    },
    {
        lng: 6.956798076629639,
        lat: 46.045631408691406
    },
    {
        lng: 6.955883979797477,
        lat: 46.04720687866211
    },
    {
        lng: 6.955539226532039,
        lat: 46.04760742187506
    },
    {
        lng: 6.954988002777043,
        lat: 46.04808807373064
    },
    {
        lng: 6.953812122344914,
        lat: 46.04942321777361
    },
    {
        lng: 6.95276594161993,
        lat: 46.050277709960994
    },
    {
        lng: 6.952092170715446,
        lat: 46.05083084106445
    },
    {
        lng: 6.951038837432975,
        lat: 46.050998687744254
    },
    {
        lng: 6.948548793792725,
        lat: 46.051452636719034
    },
    {
        lng: 6.947793006897086,
        lat: 46.05166244506859
    },
    {
        lng: 6.947451114654598,
        lat: 46.051807403564624
    },
    {
        lng: 6.946547031402588,
        lat: 46.052371978759766
    },
    {
        lng: 6.94553184509283,
        lat: 46.05292129516596
    },
    {
        lng: 6.944943904876823,
        lat: 46.05331420898443
    },
    {
        lng: 6.944169044494743,
        lat: 46.05400466918957
    },
    {
        lng: 6.943435192108268,
        lat: 46.054878234863395
    },
    {
        lng: 6.942952156066838,
        lat: 46.05533599853533
    },
    {
        lng: 6.941226005554199,
        lat: 46.056587219238395
    },
    {
        lng: 6.941019058227539,
        lat: 46.05683898925781
    },
    {
        lng: 6.940738201141357,
        lat: 46.05744934082031
    },
    {
        lng: 6.9406228065493,
        lat: 46.0579833984375
    },
    {
        lng: 6.940606117248535,
        lat: 46.05858230590832
    },
    {
        lng: 6.940793991088924,
        lat: 46.0594596862793
    },
    {
        lng: 6.941587924957275,
        lat: 46.060779571533374
    },
    {
        lng: 6.94185400009161,
        lat: 46.06143951416033
    },
    {
        lng: 6.942032814025936,
        lat: 46.06247711181646
    },
    {
        lng: 6.941965103149414,
        lat: 46.06352233886719
    },
    {
        lng: 6.94172906875616,
        lat: 46.064193725585994
    },
    {
        lng: 6.9413099288941,
        lat: 46.06477737426775
    },
    {
        lng: 6.940480232238713,
        lat: 46.065559387207145
    },
    {
        lng: 6.939071178436393,
        lat: 46.0666618347168
    },
    {
        lng: 6.937636852264404,
        lat: 46.06658554077154
    },
    {
        lng: 6.93343114852911,
        lat: 46.066356658935774
    },
    {
        lng: 6.931670188904036,
        lat: 46.066627502441634
    },
    {
        lng: 6.929442882537842,
        lat: 46.06719589233427
    },
    {
        lng: 6.928803920745963,
        lat: 46.06539535522484
    },
    {
        lng: 6.928298950195426,
        lat: 46.06423568725586
    },
    {
        lng: 6.927784919738826,
        lat: 46.06344223022478
    },
    {
        lng: 6.92208909988409,
        lat: 46.06279754638672
    },
    {
        lng: 6.909534454345817,
        lat: 46.051998138427905
    },
    {
        lng: 6.906318664550838,
        lat: 46.05079269409208
    },
    {
        lng: 6.904950141906852,
        lat: 46.05076599121111
    },
    {
        lng: 6.903531074523926,
        lat: 46.05065917968756
    },
    {
        lng: 6.902297973632869,
        lat: 46.05035018920921
    },
    {
        lng: 6.900957107543945,
        lat: 46.04981994628906
    },
    {
        lng: 6.90015888214117,
        lat: 46.049388885498274
    },
    {
        lng: 6.899781227111816,
        lat: 46.04898834228527
    },
    {
        lng: 6.899506092071533,
        lat: 46.04855346679693
    },
    {
        lng: 6.899339199066162,
        lat: 46.04817199707037
    },
    {
        lng: 6.893345832824821,
        lat: 46.04592132568388
    },
    {
        lng: 6.893043041229305,
        lat: 46.04606246948242
    },
    {
        lng: 6.890999794006348,
        lat: 46.046699523925895
    },
    {
        lng: 6.889264106750545,
        lat: 46.04714584350597
    },
    {
        lng: 6.888020038604793,
        lat: 46.04753112792969
    },
    {
        lng: 6.8874292373657795,
        lat: 46.04774093627958
    },
    {
        lng: 6.886930942535628,
        lat: 46.04801177978527
    },
    {
        lng: 6.886321067810172,
        lat: 46.04844665527344
    },
    {
        lng: 6.885439872741813,
        lat: 46.04897308349638
    },
    {
        lng: 6.884697914123535,
        lat: 46.049598693847656
    },
    {
        lng: 6.883838176727409,
        lat: 46.05018615722673
    },
    {
        lng: 6.882850170135498,
        lat: 46.051200866699446
    },
    {
        lng: 6.880599021911735,
        lat: 46.05314254760759
    },
    {
        lng: 6.880195140838623,
        lat: 46.05367279052757
    },
    {
        lng: 6.880068778991756,
        lat: 46.05395126342796
    },
    {
        lng: 6.88001918792736,
        lat: 46.054943084716854
    },
    {
        lng: 6.880036354064998,
        lat: 46.05524826049822
    },
    {
        lng: 6.880128860473576,
        lat: 46.05691528320335
    },
    {
        lng: 6.880377769470272,
        lat: 46.058177947998104
    },
    {
        lng: 6.88067102432251,
        lat: 46.05870819091825
    },
    {
        lng: 6.881501197814941,
        lat: 46.05957412719738
    },
    {
        lng: 6.882122993469238,
        lat: 46.06074142456049
    },
    {
        lng: 6.8829388618470375,
        lat: 46.061611175537166
    },
    {
        lng: 6.88309478759777,
        lat: 46.061855316162394
    },
    {
        lng: 6.883304119110221,
        lat: 46.06240463256847
    },
    {
        lng: 6.883622169494686,
        lat: 46.06394958496094
    },
    {
        lng: 6.883840084075985,
        lat: 46.064567565918026
    },
    {
        lng: 6.884665966034163,
        lat: 46.065914154052734
    },
    {
        lng: 6.885337829589901,
        lat: 46.067523956298885
    },
    {
        lng: 6.886244773864803,
        lat: 46.06864929199213
    },
    {
        lng: 6.886767864227295,
        lat: 46.07051849365246
    },
    {
        lng: 6.887166976928768,
        lat: 46.07122802734398
    },
    {
        lng: 6.887567996978817,
        lat: 46.07158279418974
    },
    {
        lng: 6.888182163238525,
        lat: 46.071910858154354
    },
    {
        lng: 6.890911102294922,
        lat: 46.07289123535173
    },
    {
        lng: 6.892691135406608,
        lat: 46.073909759521484
    },
    {
        lng: 6.893536090850887,
        lat: 46.07446670532221
    },
    {
        lng: 6.895315170288086,
        lat: 46.07527923583996
    },
    {
        lng: 6.897318840026799,
        lat: 46.076324462890796
    },
    {
        lng: 6.896587848663387,
        lat: 46.07770919799799
    },
    {
        lng: 6.896273136138916,
        lat: 46.07851791381853
    },
    {
        lng: 6.895792007446289,
        lat: 46.080558776855696
    },
    {
        lng: 6.89575290679926,
        lat: 46.080722808838004
    },
    {
        lng: 6.8957409858703045,
        lat: 46.0812721252442
    },
    {
        lng: 6.89581823348999,
        lat: 46.08158493041992
    },
    {
        lng: 6.896110057831038,
        lat: 46.082065582275675
    },
    {
        lng: 6.896478176116943,
        lat: 46.08238220214838
    },
    {
        lng: 6.897595882415828,
        lat: 46.083053588867415
    },
    {
        lng: 6.897952079772949,
        lat: 46.083408355713175
    },
    {
        lng: 6.8981389999390785,
        lat: 46.08370590209984
    },
    {
        lng: 6.898406982421875,
        lat: 46.08470916748064
    },
    {
        lng: 6.898407936096305,
        lat: 46.08541870117199
    },
    {
        lng: 6.898280143737736,
        lat: 46.08612823486334
    },
    {
        lng: 6.897793769836426,
        lat: 46.08715057373041
    },
    {
        lng: 6.897159099579085,
        lat: 46.087985992431925
    },
    {
        lng: 6.894848823547477,
        lat: 46.08986282348644
    },
    {
        lng: 6.893772125244141,
        lat: 46.091411590576456
    },
    {
        lng: 6.891788959503174,
        lat: 46.09307861328125
    },
    {
        lng: 6.891326904296989,
        lat: 46.09358596801775
    },
    {
        lng: 6.891026973724365,
        lat: 46.094196319580305
    },
    {
        lng: 6.890585899353027,
        lat: 46.09564208984398
    },
    {
        lng: 6.8905229568481445,
        lat: 46.09622955322271
    },
    {
        lng: 6.8905658721924965,
        lat: 46.09657669067394
    },
    {
        lng: 6.890674114227522,
        lat: 46.09692001342785
    },
    {
        lng: 6.891025066375789,
        lat: 46.09749221801769
    },
    {
        lng: 6.89264011383068,
        lat: 46.099311828613395
    },
    {
        lng: 6.89362812042242,
        lat: 46.10025024414068
    },
    {
        lng: 6.893832206726188,
        lat: 46.10052108764654
    },
    {
        lng: 6.894499778747672,
        lat: 46.1017951965332
    },
    {
        lng: 6.89494085311901,
        lat: 46.102439880371094
    },
    {
        lng: 6.897016048431624,
        lat: 46.10444641113287
    },
    {
        lng: 6.898047924041862,
        lat: 46.1055603027346
    },
    {
        lng: 6.898853778839111,
        lat: 46.10626602172863
    },
    {
        lng: 6.899746894836483,
        lat: 46.10770034790056
    },
    {
        lng: 6.900595188140869,
        lat: 46.10870742797874
    },
    {
        lng: 6.900770187378043,
        lat: 46.109012603759766
    },
    {
        lng: 6.900987148285026,
        lat: 46.109691619873104
    },
    {
        lng: 6.9010810852053055,
        lat: 46.11080551147484
    },
    {
        lng: 6.901019096374569,
        lat: 46.11156845092796
    },
    {
        lng: 6.900667190551815,
        lat: 46.11310195922874
    },
    {
        lng: 6.900589942932186,
        lat: 46.11388778686529
    },
    {
        lng: 6.900623798370418,
        lat: 46.115089416504134
    },
    {
        lng: 6.900860786437988,
        lat: 46.116268157958984
    },
    {
        lng: 6.9015588760376545,
        lat: 46.118137359619254
    },
    {
        lng: 6.901881217956657,
        lat: 46.11880493164074
    },
    {
        lng: 6.902091979980526,
        lat: 46.11910247802757
    },
    {
        lng: 6.90285587310791,
        lat: 46.11987304687523
    },
    {
        lng: 6.903299808502311,
        lat: 46.12046432495117
    },
    {
        lng: 6.903509140014648,
        lat: 46.12078857421875
    },
    {
        lng: 6.903962135314941,
        lat: 46.121498107910156
    },
    {
        lng: 6.904920101165715,
        lat: 46.123340606689624
    },
    {
        lng: 6.905746936798039,
        lat: 46.125259399414176
    },
    {
        lng: 6.906120777130241,
        lat: 46.12699127197294
    },
    {
        lng: 6.904205799102897,
        lat: 46.12705230712908
    },
    {
        lng: 6.9029011726379395,
        lat: 46.127216339111555
    },
    {
        lng: 6.9022798538209145,
        lat: 46.12744522094738
    },
    {
        lng: 6.901585102081356,
        lat: 46.1278152465822
    },
    {
        lng: 6.900760173797664,
        lat: 46.128120422363395
    },
    {
        lng: 6.899463176727352,
        lat: 46.128494262695426
    },
    {
        lng: 6.89872598648077,
        lat: 46.128566741943416
    },
    {
        lng: 6.898343086242676,
        lat: 46.12853240966808
    },
    {
        lng: 6.897602081298828,
        lat: 46.12834930419916
    },
    {
        lng: 6.897449016571045,
        lat: 46.128299713134936
    },
    {
        lng: 6.895476818084717,
        lat: 46.12762832641613
    },
    {
        lng: 6.8939051628112225,
        lat: 46.12697982788086
    },
    {
        lng: 6.893015861511287,
        lat: 46.12670516967802
    },
    {
        lng: 6.891545772552604,
        lat: 46.12646484375
    },
    {
        lng: 6.890069961547852,
        lat: 46.12636947631836
    },
    {
        lng: 6.888665199279899,
        lat: 46.12641525268555
    },
    {
        lng: 6.887828826904297,
        lat: 46.12655639648443
    },
    {
        lng: 6.887205123901595,
        lat: 46.12680053710966
    },
    {
        lng: 6.886256217956486,
        lat: 46.12731933593756
    },
    {
        lng: 6.8843379020692055,
        lat: 46.12807464599632
    },
    {
        lng: 6.883567810058594,
        lat: 46.128311157226676
    },
    {
        lng: 6.8826708793640705,
        lat: 46.12845993041992
    },
    {
        lng: 6.881731986999512,
        lat: 46.12851333618164
    },
    {
        lng: 6.880299091339111,
        lat: 46.128475189208984
    },
    {
        lng: 6.879355907440299,
        lat: 46.128387451172046
    },
    {
        lng: 6.8773980140686035,
        lat: 46.12808227539068
    },
    {
        lng: 6.875621795654297,
        lat: 46.128013610840014
    },
    {
        lng: 6.872219085693359,
        lat: 46.12831497192383
    },
    {
        lng: 6.865035057067871,
        lat: 46.12841415405302
    },
    {
        lng: 6.860370159149284,
        lat: 46.12898254394531
    },
    {
        lng: 6.856567859649658,
        lat: 46.12966918945324
    },
    {
        lng: 6.854969024658317,
        lat: 46.130355834961165
    },
    {
        lng: 6.853632926941032,
        lat: 46.130706787109375
    },
    {
        lng: 6.852999210357666,
        lat: 46.13093566894554
    },
    {
        lng: 6.850607872009505,
        lat: 46.13236999511719
    },
    {
        lng: 6.849833011627425,
        lat: 46.13317489624035
    },
    {
        lng: 6.848343849182186,
        lat: 46.13471984863304
    },
    {
        lng: 6.846315860748291,
        lat: 46.134864807128906
    },
    {
        lng: 6.843857765197868,
        lat: 46.13515853881853
    },
    {
        lng: 6.842833995819092,
        lat: 46.135192871094034
    },
    {
        lng: 6.841808795928955,
        lat: 46.13515090942383
    },
    {
        lng: 6.835686206817684,
        lat: 46.13432312011719
    },
    {
        lng: 6.8304510116578285,
        lat: 46.13401794433605
    },
    {
        lng: 6.8283729553223225,
        lat: 46.13373947143566
    },
    {
        lng: 6.824768066406193,
        lat: 46.133407592773665
    },
    {
        lng: 6.821975231170768,
        lat: 46.132961273193416
    },
    {
        lng: 6.821884155273551,
        lat: 46.13294982910156
    },
    {
        lng: 6.820940017700252,
        lat: 46.13290023803711
    },
    {
        lng: 6.820473194122428,
        lat: 46.13294219970703
    },
    {
        lng: 6.819241046905631,
        lat: 46.133235931396484
    },
    {
        lng: 6.818881034851188,
        lat: 46.1333923339846
    },
    {
        lng: 6.818308830261344,
        lat: 46.13378524780268
    },
    {
        lng: 6.817316055297908,
        lat: 46.13460540771496
    },
    {
        lng: 6.816983222961483,
        lat: 46.135189056396484
    },
    {
        lng: 6.816553115844783,
        lat: 46.135940551757926
    },
    {
        lng: 6.816127777099609,
        lat: 46.13642120361328
    },
    {
        lng: 6.815113067627067,
        lat: 46.13724136352539
    },
    {
        lng: 6.814287185669059,
        lat: 46.13768386840843
    },
    {
        lng: 6.813007831573486,
        lat: 46.13806533813499
    },
    {
        lng: 6.81181621551525,
        lat: 46.13823318481445
    },
    {
        lng: 6.810510158538875,
        lat: 46.13822174072277
    },
    {
        lng: 6.810383796692008,
        lat: 46.13820648193365
    },
    {
        lng: 6.807847023010254,
        lat: 46.137893676757756
    },
    {
        lng: 6.807036876678694,
        lat: 46.137901306152344
    },
    {
        lng: 6.806275844573975,
        lat: 46.13800811767584
    },
    {
        lng: 6.80565977096569,
        lat: 46.1382293701173
    },
    {
        lng: 6.8040008544921875,
        lat: 46.139152526855526
    },
    {
        lng: 6.80355978012085,
        lat: 46.139499664306754
    },
    {
        lng: 6.803202152252197,
        lat: 46.13992309570318
    },
    {
        lng: 6.802502155303955,
        lat: 46.1413650512697
    },
    {
        lng: 6.802423954009953,
        lat: 46.1416778564456
    },
    {
        lng: 6.802340030670393,
        lat: 46.14201736450195
    },
    {
        lng: 6.802104949951229,
        lat: 46.14404296875
    },
    {
        lng: 6.8019537925720215,
        lat: 46.144702911377124
    },
    {
        lng: 6.80049276351923,
        lat: 46.14864730834972
    },
    {
        lng: 6.800217151641959,
        lat: 46.14913940429682
    },
    {
        lng: 6.799444198608455,
        lat: 46.150104522705305
    },
    {
        lng: 6.7987451553345295,
        lat: 46.15239334106468
    },
    {
        lng: 6.7977709770203205,
        lat: 46.15355300903326
    },
    {
        lng: 6.797146797180233,
        lat: 46.1547546386721
    },
    {
        lng: 6.796378135681152,
        lat: 46.15564727783203
    },
    {
        lng: 6.79622125625616,
        lat: 46.156017303467024
    },
    {
        lng: 6.796163082122916,
        lat: 46.15615463256836
    },
    {
        lng: 6.796109199523926,
        lat: 46.15671920776384
    },
    {
        lng: 6.796198844909782,
        lat: 46.15730667114258
    },
    {
        lng: 6.7964382171632,
        lat: 46.15788269042997
    },
    {
        lng: 6.797101974487305,
        lat: 46.158988952636776
    },
    {
        lng: 6.79742622375494,
        lat: 46.16002655029297
    },
    {
        lng: 6.797561168670768,
        lat: 46.1611213684082
    },
    {
        lng: 6.797638893127498,
        lat: 46.16299057006836
    },
    {
        lng: 6.7976861000061035,
        lat: 46.164104461670036
    },
    {
        lng: 6.797961235046387,
        lat: 46.16555023193354
    },
    {
        lng: 6.7984771728515625,
        lat: 46.16646957397461
    },
    {
        lng: 6.799829006195125,
        lat: 46.16807937622082
    },
    {
        lng: 6.801506042480412,
        lat: 46.16933059692383
    },
    {
        lng: 6.802436828613395,
        lat: 46.16987228393572
    },
    {
        lng: 6.803318977356071,
        lat: 46.17049789428734
    },
    {
        lng: 6.8048372268677895,
        lat: 46.17123794555664
    },
    {
        lng: 6.805954933166504,
        lat: 46.171928405761946
    },
    {
        lng: 6.807291984558049,
        lat: 46.17297744750999
    },
    {
        lng: 6.80836296081543,
        lat: 46.17404556274414
    },
    {
        lng: 6.8092718124390785,
        lat: 46.17465972900402
    },
    {
        lng: 6.810099124908447,
        lat: 46.17531204223644
    },
    {
        lng: 6.811092853546143,
        lat: 46.17589950561535
    },
    {
        lng: 6.811356544494686,
        lat: 46.17610168457031
    },
    {
        lng: 6.811544895172119,
        lat: 46.176246643066406
    },
    {
        lng: 6.812085151672477,
        lat: 46.17691040039068
    },
    {
        lng: 6.812632083892879,
        lat: 46.17786407470709
    },
    {
        lng: 6.813427925109977,
        lat: 46.179809570312614
    },
    {
        lng: 6.814269065856934,
        lat: 46.18077087402372
    },
    {
        lng: 6.814948081970272,
        lat: 46.1819801330567
    },
    {
        lng: 6.815292835235709,
        lat: 46.182437896728516
    },
    {
        lng: 6.816985130310059,
        lat: 46.18372726440458
    },
    {
        lng: 6.819441795349121,
        lat: 46.18500137329124
    },
    {
        lng: 6.8185291290282635,
        lat: 46.18704605102556
    },
    {
        lng: 6.817855834961051,
        lat: 46.189323425293026
    },
    {
        lng: 6.816793918609733,
        lat: 46.19136428833036
    },
    {
        lng: 6.816319942474422,
        lat: 46.19351196289068
    },
    {
        lng: 6.816020965576229,
        lat: 46.194461822509766
    },
    {
        lng: 6.8155622482299805,
        lat: 46.19546890258812
    },
    {
        lng: 6.8144989013671875,
        lat: 46.19780349731451
    },
    {
        lng: 6.813967227935848,
        lat: 46.19873046874994
    },
    {
        lng: 6.813422203064022,
        lat: 46.20040893554699
    },
    {
        lng: 6.812915802001953,
        lat: 46.20232009887695
    },
    {
        lng: 6.812654018402213,
        lat: 46.202865600585994
    },
    {
        lng: 6.812276840210075,
        lat: 46.20326614379883
    },
    {
        lng: 6.810396194458065,
        lat: 46.20471954345726
    },
    {
        lng: 6.810046672821159,
        lat: 46.205123901367415
    },
    {
        lng: 6.8098368644714355,
        lat: 46.20592498779308
    },
    {
        lng: 6.810001850128231,
        lat: 46.20679473876953
    },
    {
        lng: 6.810321807861442,
        lat: 46.20733642578125
    },
    {
        lng: 6.811570167541561,
        lat: 46.209056854248104
    },
    {
        lng: 6.813846111297721,
        lat: 46.21128082275402
    },
    {
        lng: 6.814192771911678,
        lat: 46.21181488037115
    },
    {
        lng: 6.814781188964901,
        lat: 46.2131462097168
    },
    {
        lng: 6.815835952758903,
        lat: 46.214298248291016
    },
    {
        lng: 6.817285060882568,
        lat: 46.21629333496094
    },
    {
        lng: 6.817936897277889,
        lat: 46.216796875000114
    },
    {
        lng: 6.819025993347225,
        lat: 46.21735382080084
    },
    {
        lng: 6.81987905502325,
        lat: 46.21794891357433
    },
    {
        lng: 6.820324897766113,
        lat: 46.21843338012707
    },
    {
        lng: 6.820524215698185,
        lat: 46.21874618530296
    },
    {
        lng: 6.821278095245418,
        lat: 46.220386505126896
    },
    {
        lng: 6.821990966796932,
        lat: 46.22125244140625
    },
    {
        lng: 6.822665214538802,
        lat: 46.221904754638615
    },
    {
        lng: 6.824390888214225,
        lat: 46.22358322143566
    },
    {
        lng: 6.826033115386963,
        lat: 46.22475051879894
    },
    {
        lng: 6.827144145965633,
        lat: 46.2254638671875
    },
    {
        lng: 6.827499866485709,
        lat: 46.225841522216854
    },
    {
        lng: 6.8276839256288895,
        lat: 46.22615432739258
    },
    {
        lng: 6.827900886535758,
        lat: 46.22682571411144
    },
    {
        lng: 6.827983856201172,
        lat: 46.22753906249994
    },
    {
        lng: 6.827958106994856,
        lat: 46.228645324707315
    },
    {
        lng: 6.827853202819938,
        lat: 46.22938156127947
    },
    {
        lng: 6.827652931213322,
        lat: 46.23011398315424
    },
    {
        lng: 6.826886177063045,
        lat: 46.231971740722656
    },
    {
        lng: 6.826121807098389,
        lat: 46.23337936401367
    },
    {
        lng: 6.826430797576904,
        lat: 46.2335472106933
    },
    {
        lng: 6.83025693893444,
        lat: 46.23561859130882
    },
    {
        lng: 6.831209182739315,
        lat: 46.23593521118187
    },
    {
        lng: 6.832418918609733,
        lat: 46.23624420166021
    },
    {
        lng: 6.8350000381470295,
        lat: 46.23652648925781
    },
    {
        lng: 6.83610200881958,
        lat: 46.23679733276367
    },
    {
        lng: 6.8378520011901855,
        lat: 46.237800598144815
    },
    {
        lng: 6.8394060134887695,
        lat: 46.23899459838873
    },
    {
        lng: 6.840373039245662,
        lat: 46.24001693725597
    },
    {
        lng: 6.842082023620662,
        lat: 46.24160003662121
    },
    {
        lng: 6.842720985412541,
        lat: 46.24242782592779
    },
    {
        lng: 6.84329795837408,
        lat: 46.24365615844721
    },
    {
        lng: 6.843479156494368,
        lat: 46.24421691894554
    },
    {
        lng: 6.843742847442741,
        lat: 46.246505737304915
    },
    {
        lng: 6.843886852264518,
        lat: 46.2471466064456
    },
    {
        lng: 6.84440994262701,
        lat: 46.24835586547857
    },
    {
        lng: 6.8449468612672035,
        lat: 46.2491188049317
    },
    {
        lng: 6.84585809707653,
        lat: 46.24984359741234
    },
    {
        lng: 6.847004890442008,
        lat: 46.250370025634936
    },
    {
        lng: 6.847811222076359,
        lat: 46.250556945800895
    },
    {
        lng: 6.8498358726501465,
        lat: 46.25086975097656
    },
    {
        lng: 6.8516559600831215,
        lat: 46.25138473510765
    },
    {
        lng: 6.853415966034049,
        lat: 46.252433776855696
    },
    {
        lng: 6.85436487197876,
        lat: 46.25315856933594
    },
    {
        lng: 6.856058120727653,
        lat: 46.25418090820324
    },
    {
        lng: 6.857922077179069,
        lat: 46.25479125976574
    },
    {
        lng: 6.858451843261946,
        lat: 46.2550964355471
    },
    {
        lng: 6.859630107879752,
        lat: 46.256385803222656
    },
    {
        lng: 6.860116004943848,
        lat: 46.25720214843773
    },
    {
        lng: 6.860451221466121,
        lat: 46.258422851562614
    },
    {
        lng: 6.860937118530387,
        lat: 46.260936737060604
    },
    {
        lng: 6.86130094528221,
        lat: 46.26184463500999
    },
    {
        lng: 6.862049102783317,
        lat: 46.26306533813488
    },
    {
        lng: 6.8625340461731525,
        lat: 46.26352691650402
    },
    {
        lng: 6.864560127258244,
        lat: 46.26511383056635
    },
    {
        lng: 6.864960193634033,
        lat: 46.2656364440918
    },
    {
        lng: 6.865313053131217,
        lat: 46.266349792480696
    },
    {
        lng: 6.865468025207633,
        lat: 46.26725006103533
    },
    {
        lng: 6.865005016326961,
        lat: 46.269748687744425
    },
    {
        lng: 6.864807128906364,
        lat: 46.272804260253906
    },
    {
        lng: 6.864771842956543,
        lat: 46.274700164795036
    },
    {
        lng: 6.864912033081168,
        lat: 46.27611541748058
    },
    {
        lng: 6.865138053894043,
        lat: 46.27673721313499
    },
    {
        lng: 6.865323066711369,
        lat: 46.277004241943644
    },
    {
        lng: 6.867446899414176,
        lat: 46.27885437011719
    },
    {
        lng: 6.867804050445557,
        lat: 46.2793998718264
    },
    {
        lng: 6.868665218353385,
        lat: 46.28174209594738
    },
    {
        lng: 6.869141101837158,
        lat: 46.28419494628906
    },
    {
        lng: 6.869059085846004,
        lat: 46.28486633300787
    },
    {
        lng: 6.868747234344539,
        lat: 46.28544998168945
    },
    {
        lng: 6.868048191070784,
        lat: 46.286102294921875
    },
    {
        lng: 6.866889953613395,
        lat: 46.286884307861385
    },
    {
        lng: 6.8664660453796955,
        lat: 46.287422180175724
    },
    {
        lng: 6.865777015686149,
        lat: 46.288764953613224
    },
    {
        lng: 6.864953041076717,
        lat: 46.29036712646507
    },
    {
        lng: 6.8641772270203205,
        lat: 46.2920494079591
    },
    {
        lng: 6.863599777221793,
        lat: 46.2926597595216
    },
    {
        lng: 6.862703800201416,
        lat: 46.29329681396496
    },
    {
        lng: 6.861968040466309,
        lat: 46.29359817504911
    },
    {
        lng: 6.861615180969295,
        lat: 46.29367446899414
    },
    {
        lng: 6.860895156860352,
        lat: 46.293731689453125
    },
    {
        lng: 6.859917163848991,
        lat: 46.29359817504911
    },
    {
        lng: 6.858767986297607,
        lat: 46.29323577880882
    },
    {
        lng: 6.858297824859676,
        lat: 46.29296112060541
    },
    {
        lng: 6.857742786407471,
        lat: 46.29251861572277
    },
    {
        lng: 6.856326103210449,
        lat: 46.291568756103686
    },
    {
        lng: 6.855847835540885,
        lat: 46.29135131835943
    },
    {
        lng: 6.855356693267879,
        lat: 46.29186630249035
    },
    {
        lng: 6.853334903717155,
        lat: 46.29113388061535
    },
    {
        lng: 6.850886344909725,
        lat: 46.29024887084961
    },
    {
        lng: 6.831572532653922,
        lat: 46.30044174194347
    },
    {
        lng: 6.822672843933162,
        lat: 46.31268310546898
    },
    {
        lng: 6.822231769561824,
        lat: 46.31285476684599
    },
    {
        lng: 6.8011360168459305,
        lat: 46.32103347778332
    },
    {
        lng: 6.796401023864803,
        lat: 46.330791473388956
    },
    {
        lng: 6.786917686462459,
        lat: 46.33254241943382
    },
    {
        lng: 6.774673938751334,
        lat: 46.34728240966825
    },
    {
        lng: 6.7745027542115395,
        lat: 46.347930908203296
    },
    {
        lng: 6.771278381347713,
        lat: 46.360141754150504
    },
    {
        lng: 6.782236099243278,
        lat: 46.36663818359375
    },
    {
        lng: 6.791872978210449,
        lat: 46.36720275878906
    },
    {
        lng: 6.800902843475399,
        lat: 46.375129699707315
    },
    {
        lng: 6.806129455566406,
        lat: 46.38004302978521
    },
    {
        lng: 6.80182075500494,
        lat: 46.388317108154524
    },
    {
        lng: 6.8050737380981445,
        lat: 46.39386367797874
    },
    {
        lng: 6.809544086456299,
        lat: 46.39518737792963
    },
    {
        lng: 6.809614181518668,
        lat: 46.395324707031364
    },
    {
        lng: 6.810090065002555,
        lat: 46.39696502685547
    },
    {
        lng: 6.8110108375549885,
        lat: 46.39862060546869
    },
    {
        lng: 6.811644077301139,
        lat: 46.40038299560564
    },
    {
        lng: 6.8125767707825275,
        lat: 46.40204238891624
    },
    {
        lng: 6.813224792480526,
        lat: 46.40379714965826
    },
    {
        lng: 6.813521862030257,
        lat: 46.40436172485357
    },
    {
        lng: 6.815731048583984,
        lat: 46.40773773193371
    },
    {
        lng: 6.816515922546387,
        lat: 46.409778594970646
    },
    {
        lng: 6.817443847656477,
        lat: 46.41143417358404
    },
    {
        lng: 6.8180742263793945,
        lat: 46.41319656372082
    },
    {
        lng: 6.819013118743953,
        lat: 46.414867401123104
    },
    {
        lng: 6.819645881652889,
        lat: 46.41661071777344
    },
    {
        lng: 6.820448875427303,
        lat: 46.41798019409174
    },
    {
        lng: 6.821206092834473,
        lat: 46.42002868652361
    },
    {
        lng: 6.821992874145508,
        lat: 46.42140579223644
    },
    {
        lng: 6.822751998901424,
        lat: 46.42345046997076
    },
    {
        lng: 6.824036121368636,
        lat: 46.42526245117193
    },
    {
        lng: 6.824449062347469,
        lat: 46.42573547363281
    },
    {
        lng: 6.8250250816345215,
        lat: 46.426189422607365
    },
    {
        lng: 6.826391220092887,
        lat: 46.42697143554693
    },
    {
        lng: 6.8243088722229,
        lat: 46.428077697754134
    },
    {
        lng: 6.822631835937614,
        lat: 46.428878784179744
    },
    {
        lng: 6.818925857543945,
        lat: 46.43014144897472
    },
    {
        lng: 6.816021919250545,
        lat: 46.43071746826172
    },
    {
        lng: 6.813636779785213,
        lat: 46.43138885498058
    },
    {
        lng: 6.811161994934025,
        lat: 46.431854248047046
    },
    {
        lng: 6.809199810028133,
        lat: 46.43244552612333
    },
    {
        lng: 6.806284904480094,
        lat: 46.433013916015625
    },
    {
        lng: 6.804333209991569,
        lat: 46.43360137939476
    },
    {
        lng: 6.801421165466422,
        lat: 46.434177398681754
    },
    {
        lng: 6.799469947814941,
        lat: 46.43476867675804
    },
    {
        lng: 6.796559810638541,
        lat: 46.435340881347884
    },
    {
        lng: 6.79459381103527,
        lat: 46.435905456543196
    },
    {
        lng: 6.793087959289551,
        lat: 46.43613052368164
    },
    {
        lng: 6.789968013763428,
        lat: 46.4364013671875
    },
    {
        lng: 6.787220954895076,
        lat: 46.436809539795036
    },
    {
        lng: 6.785949230194035,
        lat: 46.437236785888786
    },
    {
        lng: 6.784089088440169,
        lat: 46.437744140625
    },
    {
        lng: 6.781634807586784,
        lat: 46.43828964233421
    },
    {
        lng: 6.778684139251709,
        lat: 46.43879318237305
    },
    {
        lng: 6.7762250900268555,
        lat: 46.43944549560541
    },
    {
        lng: 6.7737531661987305,
        lat: 46.439846038818416
    },
    {
        lng: 6.770253181457576,
        lat: 46.440559387207315
    },
    {
        lng: 6.76777982711792,
        lat: 46.44095611572277
    },
    {
        lng: 6.7648248672487625,
        lat: 46.44168472290039
    },
    {
        lng: 6.761789798736515,
        lat: 46.44203567504883
    },
    {
        lng: 6.760804176330623,
        lat: 46.44220733642601
    },
    {
        lng: 6.758336067199707,
        lat: 46.44284057617216
    },
    {
        lng: 6.755863189697266,
        lat: 46.44323348999046
    },
    {
        lng: 6.7523608207703205,
        lat: 46.44393920898443
    },
    {
        lng: 6.7498888969421955,
        lat: 46.44433593750006
    },
    {
        lng: 6.7469501495362465,
        lat: 46.44509124755865
    },
    {
        lng: 6.744479179382381,
        lat: 46.445491790771655
    },
    {
        lng: 6.740966796875057,
        lat: 46.44618606567411
    },
    {
        lng: 6.737969875335693,
        lat: 46.44661331176758
    },
    {
        lng: 6.734457015991325,
        lat: 46.44731140136719
    },
    {
        lng: 6.731984138488883,
        lat: 46.44770812988281
    },
    {
        lng: 6.72904109954834,
        lat: 46.448459625244254
    },
    {
        lng: 6.726568222045955,
        lat: 46.44885635376005
    },
    {
        lng: 6.7230610847473145,
        lat: 46.44956588745123
    },
    {
        lng: 6.720583915710506,
        lat: 46.44995880126953
    },
    {
        lng: 6.717624187469482,
        lat: 46.450683593750114
    },
    {
        lng: 6.714084148407096,
        lat: 46.45111083984392
    },
    {
        lng: 6.711122989654541,
        lat: 46.45183563232416
    },
    {
        lng: 6.708645820617733,
        lat: 46.452228546142805
    },
    {
        lng: 6.705135822296256,
        lat: 46.45293426513683
    },
    {
        lng: 6.7026591300964355,
        lat: 46.45333099365263
    },
    {
        lng: 6.699713230133,
        lat: 46.45408630371105
    },
    {
        lng: 6.697237968444767,
        lat: 46.45448303222685
    },
    {
        lng: 6.6937298774720375,
        lat: 46.455196380615234
    },
    {
        lng: 6.691256999969482,
        lat: 46.45559310913103
    },
    {
        lng: 6.688291072845459,
        lat: 46.45631408691429
    },
    {
        lng: 6.6867499351501465,
        lat: 46.45647430419922
    },
    {
        lng: 6.683602809906006,
        lat: 46.45664215087908
    },
    {
        lng: 6.680975914001465,
        lat: 46.45694732666027
    },
    {
        lng: 6.679306983947754,
        lat: 46.45704269409191
    },
    {
        lng: 6.661258220672721,
        lat: 46.45703506469738
    },
    {
        lng: 6.60427904129034,
        lat: 46.4569625854495
    },
    {
        lng: 6.552217960357666,
        lat: 46.45694351196289
    },
    {
        lng: 6.523421764373836,
        lat: 46.456981658935774
    },
    {
        lng: 6.5211777687072185,
        lat: 46.456882476806754
    },
    {
        lng: 6.520123004913444,
        lat: 46.45673370361334
    },
    {
        lng: 6.51916313171381,
        lat: 46.45649337768566
    },
    {
        lng: 6.5179839134217445,
        lat: 46.45606994628923
    },
    {
        lng: 6.515336990356502,
        lat: 46.45449066162121
    },
    {
        lng: 6.513160228729362,
        lat: 46.45373916625999
    },
    {
        lng: 6.51047420501709,
        lat: 46.452209472656364
    },
    {
        lng: 6.5085749626160805,
        lat: 46.45163345336937
    },
    {
        lng: 6.507244110107536,
        lat: 46.451045989990234
    },
    {
        lng: 6.504983901977539,
        lat: 46.450340270996094
    },
    {
        lng: 6.504909515380916,
        lat: 46.4502983093264
    },
    {
        lng: 6.504006862640608,
        lat: 46.44978713989269
    },
    {
        lng: 6.502294063568115,
        lat: 46.448810577392635
    },
    {
        lng: 6.50009393692028,
        lat: 46.44806671142595
    },
    {
        lng: 6.497404098510856,
        lat: 46.44653701782238
    },
    {
        lng: 6.495502948761043,
        lat: 46.44596099853533
    },
    {
        lng: 6.494173049926815,
        lat: 46.44537353515625
    },
    {
        lng: 6.491910934448242,
        lat: 46.4446716308596
    },
    {
        lng: 6.489212989807356,
        lat: 46.44313812255882
    },
    {
        lng: 6.4870147705078125,
        lat: 46.44239807128929
    },
    {
        lng: 6.484360218048209,
        lat: 46.4408531188966
    },
    {
        lng: 6.482067108154411,
        lat: 46.44015502929699
    },
    {
        lng: 6.481135845184383,
        lat: 46.43962860107416
    },
    {
        lng: 6.477387905120793,
        lat: 46.43808746337902
    },
    {
        lng: 6.476189136505241,
        lat: 46.43744277954096
    },
    {
        lng: 6.473898887634334,
        lat: 46.43674468994169
    },
    {
        lng: 6.471285820007324,
        lat: 46.435184478759936
    },
    {
        lng: 6.468995094299544,
        lat: 46.43449020385765
    },
    {
        lng: 6.468060970306453,
        lat: 46.43396377563488
    },
    {
        lng: 6.4643077850342365,
        lat: 46.43242263793957
    },
    {
        lng: 6.463108062744141,
        lat: 46.431770324707145
    },
    {
        lng: 6.460815906524658,
        lat: 46.43107604980469
    },
    {
        lng: 6.458189964294434,
        lat: 46.429515838623274
    },
    {
        lng: 6.455925941467285,
        lat: 46.428810119628906
    },
    {
        lng: 6.453296184539852,
        lat: 46.42723846435541
    },
    {
        lng: 6.452665805816764,
        lat: 46.42701721191435
    },
    {
        lng: 6.451296806335506,
        lat: 46.42667770385748
    },
    {
        lng: 6.449734210968074,
        lat: 46.42597961425804
    },
    {
        lng: 6.448365211486873,
        lat: 46.42564010620117
    },
    {
        lng: 6.447733879089469,
        lat: 46.42541885375971
    },
    {
        lng: 6.445104122161865,
        lat: 46.42384719848644
    },
    {
        lng: 6.442841053008976,
        lat: 46.423141479492415
    },
    {
        lng: 6.440208911895752,
        lat: 46.4215736389163
    },
    {
        lng: 6.439578056335449,
        lat: 46.421348571777344
    },
    {
        lng: 6.438209056854362,
        lat: 46.42100906372099
    },
    {
        lng: 6.436648845672607,
        lat: 46.42031097412121
    },
    {
        lng: 6.434964179992733,
        lat: 46.4198646545413
    },
    {
        lng: 6.434438228607178,
        lat: 46.41961669921875
    },
    {
        lng: 6.432288169860897,
        lat: 46.418315887451286
    },
    {
        lng: 6.431694984435978,
        lat: 46.41806411743187
    },
    {
        lng: 6.430902004241943,
        lat: 46.41790771484375
    },
    {
        lng: 6.428204059600944,
        lat: 46.417663574219034
    },
    {
        lng: 6.422109127044791,
        lat: 46.4168395996096
    },
    {
        lng: 6.418309211730957,
        lat: 46.41655731201166
    },
    {
        lng: 6.4121880531311035,
        lat: 46.41572189331055
    },
    {
        lng: 6.408536911010685,
        lat: 46.41539764404308
    },
    {
        lng: 6.4039411544799805,
        lat: 46.41460037231445
    },
    {
        lng: 6.400304794311637,
        lat: 46.41427230834984
    },
    {
        lng: 6.3975257873535725,
        lat: 46.41387557983421
    },
    {
        lng: 6.394610881805534,
        lat: 46.41357803344738
    },
    {
        lng: 6.389986991882324,
        lat: 46.41285705566412
    },
    {
        lng: 6.383822917938232,
        lat: 46.41204452514677
    },
    {
        lng: 6.381760120391959,
        lat: 46.41171646118164
    },
    {
        lng: 6.379703044891414,
        lat: 46.41148757934582
    },
    {
        lng: 6.377624988555908,
        lat: 46.41118240356445
    },
    {
        lng: 6.373960971832275,
        lat: 46.41087722778332
    },
    {
        lng: 6.369375228881893,
        lat: 46.41006851196312
    },
    {
        lng: 6.365724086761702,
        lat: 46.40974044799816
    },
    {
        lng: 6.361136913299674,
        lat: 46.40892791748041
    },
    {
        lng: 6.3574700355529785,
        lat: 46.408622741699446
    },
    {
        lng: 6.355389118194523,
        lat: 46.40831756591825
    },
    {
        lng: 6.353329181671143,
        lat: 46.408084869384766
    },
    {
        lng: 6.351261138916129,
        lat: 46.40775299072294
    },
    {
        lng: 6.349205017089901,
        lat: 46.40750885009771
    },
    {
        lng: 6.347135066986141,
        lat: 46.4071807861331
    },
    {
        lng: 6.345067977905273,
        lat: 46.40695571899431
    },
    {
        lng: 6.3429741859436035,
        lat: 46.40665817260748
    },
    {
        lng: 6.339828968048209,
        lat: 46.406387329101676
    },
    {
        lng: 6.338426113128719,
        lat: 46.406093597412166
    },
    {
        lng: 6.336184024811018,
        lat: 46.40524291992199
    },
    {
        lng: 6.3331971168518635,
        lat: 46.40345382690458
    },
    {
        lng: 6.330974102020264,
        lat: 46.40272140502924
    },
    {
        lng: 6.329208850860596,
        lat: 46.40167617797874
    },
    {
        lng: 6.328427791595516,
        lat: 46.40108871459984
    },
    {
        lng: 6.326661109924316,
        lat: 46.40004348754894
    },
    {
        lng: 6.3244271278382485,
        lat: 46.39931488037115
    },
    {
        lng: 6.321533203125227,
        lat: 46.39760208129883
    },
    {
        lng: 6.31780481338501,
        lat: 46.39598464965826
    },
    {
        lng: 6.316965103149471,
        lat: 46.395397186279354
    },
    {
        lng: 6.316040039062614,
        lat: 46.39490127563471
    },
    {
        lng: 6.315187931060848,
        lat: 46.39435958862305
    },
    {
        lng: 6.312899112701473,
        lat: 46.39366912841791
    },
    {
        lng: 6.3111510276795,
        lat: 46.392616271972884
    },
    {
        lng: 6.3103938102723305,
        lat: 46.39201354980469
    },
    {
        lng: 6.3086419105529785,
        lat: 46.39096069335966
    },
    {
        lng: 6.308010101318359,
        lat: 46.390739440918026
    },
    {
        lng: 6.306646823883057,
        lat: 46.3903923034668
    },
    {
        lng: 6.306110858917293,
        lat: 46.39014053344721
    },
    {
        lng: 6.3054938316345215,
        lat: 46.38972473144531
    },
    {
        lng: 6.3045911788941,
        lat: 46.389213562011776
    },
    {
        lng: 6.303836822509766,
        lat: 46.38861083984398
    },
    {
        lng: 6.302089214325065,
        lat: 46.3875541687014
    },
    {
        lng: 6.299801826476994,
        lat: 46.38685989379911
    },
    {
        lng: 6.298948764801139,
        lat: 46.38631057739269
    },
    {
        lng: 6.298023223877067,
        lat: 46.385810852051065
    },
    {
        lng: 6.29718112945568,
        lat: 46.38521575927746
    },
    {
        lng: 6.293474197387695,
        lat: 46.38360977172863
    },
    {
        lng: 6.292377948761043,
        lat: 46.38288116455101
    },
    {
        lng: 6.291452884674072,
        lat: 46.382377624512
    },
    {
        lng: 6.290602207183838,
        lat: 46.38182830810558
    },
    {
        lng: 6.288318157196102,
        lat: 46.38112258911144
    },
    {
        lng: 6.286573886871281,
        lat: 46.380050659179744
    },
    {
        lng: 6.285820960998535,
        lat: 46.379436492920036
    },
    {
        lng: 6.284080028534049,
        lat: 46.378360748291016
    },
    {
        lng: 6.281794071197567,
        lat: 46.377639770507756
    },
    {
        lng: 6.279161930084285,
        lat: 46.376033782958984
    },
    {
        lng: 6.2768821716308025,
        lat: 46.375301361084155
    },
    {
        lng: 6.275147914886588,
        lat: 46.37420654296869
    },
    {
        lng: 6.2743978500368485,
        lat: 46.373584747314624
    },
    {
        lng: 6.272668838500977,
        lat: 46.3724822998048
    },
    {
        lng: 6.270407199859619,
        lat: 46.371715545654524
    },
    {
        lng: 6.269567012786979,
        lat: 46.37114334106468
    },
    {
        lng: 6.268651962280501,
        lat: 46.37061309814453
    },
    {
        lng: 6.2678241729737465,
        lat: 46.369995117187614
    },
    {
        lng: 6.264164924621582,
        lat: 46.36828231811546
    },
    {
        lng: 6.263083934784049,
        lat: 46.36752319335949
    },
    {
        lng: 6.262174129486084,
        lat: 46.36699676513689
    },
    {
        lng: 6.261336803436336,
        lat: 46.366428375244254
    },
    {
        lng: 6.259084224700871,
        lat: 46.36567306518566
    },
    {
        lng: 6.258238792419547,
        lat: 46.3651123046875
    },
    {
        lng: 6.257321834564323,
        lat: 46.36458969116211
    },
    {
        lng: 6.256507873535213,
        lat: 46.363975524902344
    },
    {
        lng: 6.255169868469181,
        lat: 46.36324310302757
    },
    {
        lng: 6.254621028900374,
        lat: 46.362815856933594
    },
    {
        lng: 6.254179954529036,
        lat: 46.36231994628912
    },
    {
        lng: 6.253459930419922,
        lat: 46.36074066162132
    },
    {
        lng: 6.252502918243408,
        lat: 46.359603881836165
    },
    {
        lng: 6.2519330978393555,
        lat: 46.3583984375
    },
    {
        lng: 6.2511048316955,
        lat: 46.3575134277346
    },
    {
        lng: 6.250364780426025,
        lat: 46.356094360351506
    },
    {
        lng: 6.249534130096492,
        lat: 46.35521316528332
    },
    {
        lng: 6.249249935150203,
        lat: 46.35476303100609
    },
    {
        lng: 6.24879789352417,
        lat: 46.3537979125976
    },
    {
        lng: 6.247830867767277,
        lat: 46.35266876220703
    },
    {
        lng: 6.247171878814811,
        lat: 46.35104751586914
    },
    {
        lng: 6.245701789856014,
        lat: 46.348716735839844
    },
    {
        lng: 6.244029998779411,
        lat: 46.346416473388786
    },
    {
        lng: 6.2429308891296955,
        lat: 46.34533691406256
    },
    {
        lng: 6.241683959961051,
        lat: 46.342987060546875
    },
    {
        lng: 6.240959167480469,
        lat: 46.341110229492415
    },
    {
        lng: 6.24002122879034,
        lat: 46.33996582031273
    },
    {
        lng: 6.23927021026617,
        lat: 46.33855819702154
    },
    {
        lng: 6.238461017608586,
        lat: 46.33766555786144
    },
    {
        lng: 6.2378621101379395,
        lat: 46.33647918701189
    },
    {
        lng: 6.236892223358211,
        lat: 46.335369110107706
    },
    {
        lng: 6.236268043518123,
        lat: 46.33417510986328
    },
    {
        lng: 6.235128879547119,
        lat: 46.33293533325195
    },
    {
        lng: 6.234395980834904,
        lat: 46.33147048950218
    },
    {
        lng: 6.233201026916504,
        lat: 46.329524993896655
    },
    {
        lng: 6.232161045074577,
        lat: 46.32841110229515
    },
    {
        lng: 6.231560230255127,
        lat: 46.32720947265631
    },
    {
        lng: 6.2305688858032795,
        lat: 46.326110839844034
    },
    {
        lng: 6.229976177215633,
        lat: 46.32490539550804
    },
    {
        lng: 6.228981971740723,
        lat: 46.323799133300895
    },
    {
        lng: 6.228383064270247,
        lat: 46.32259368896507
    },
    {
        lng: 6.227385997772274,
        lat: 46.32148742675787
    },
    {
        lng: 6.2267827987672035,
        lat: 46.320285797119254
    },
    {
        lng: 6.225783824920654,
        lat: 46.31917572021507
    },
    {
        lng: 6.225181102752629,
        lat: 46.31797409057623
    },
    {
        lng: 6.22417593002325,
        lat: 46.316871643066634
    },
    {
        lng: 6.223560810089225,
        lat: 46.315673828125
    },
    {
        lng: 6.222516059875602,
        lat: 46.31457138061552
    },
    {
        lng: 6.22156906127924,
        lat: 46.31297683715843
    },
    {
        lng: 6.224191188812313,
        lat: 46.31208038330078
    },
    {
        lng: 6.226325035095272,
        lat: 46.311599731445426
    },
    {
        lng: 6.227972030639648,
        lat: 46.31093978881847
    },
    {
        lng: 6.2296648025512695,
        lat: 46.31055450439453
    },
    {
        lng: 6.231229782104549,
        lat: 46.30990982055687
    },
    {
        lng: 6.2329258918762775,
        lat: 46.3095245361331
    },
    {
        lng: 6.234491825103817,
        lat: 46.308879852294865
    },
    {
        lng: 6.236180782318229,
        lat: 46.30847930908226
    },
    {
        lng: 6.237700939178524,
        lat: 46.30776596069336
    },
    {
        lng: 6.2403888702393715,
        lat: 46.30677032470726
    },
    {
        lng: 6.241765975952205,
        lat: 46.306381225586165
    },
    {
        lng: 6.243684768676758,
        lat: 46.305747985839844
    },
    {
        lng: 6.2441606521606445,
        lat: 46.305641174316634
    },
    {
        lng: 6.241373062133789,
        lat: 46.30455780029325
    },
    {
        lng: 6.246891498565674,
        lat: 46.30157470703142
    },
    {
        lng: 6.248378276824951,
        lat: 46.30076980590843
    },
    {
        lng: 6.251881122589111,
        lat: 46.291667938232536
    },
    {
        lng: 6.251214981079158,
        lat: 46.288711547851676
    },
    {
        lng: 6.251104354858626,
        lat: 46.288219451904354
    },
    {
        lng: 6.243082523346004,
        lat: 46.28440856933611
    },
    {
        lng: 6.241847515106315,
        lat: 46.28382110595703
    },
    {
        lng: 6.241272926330623,
        lat: 46.28355026245134
    },
    {
        lng: 6.238650798797607,
        lat: 46.28230285644531
    },
    {
        lng: 6.238822460174617,
        lat: 46.275321960449446
    },
    {
        lng: 6.24932336807251,
        lat: 46.2646598815918
    },
    {
        lng: 6.251730918884391,
        lat: 46.262214660644815
    },
    {
        lng: 6.26133584976202,
        lat: 46.25246429443371
    },
    {
        lng: 6.264499664306641,
        lat: 46.24975204467768
    },
    {
        lng: 6.269220352172852,
        lat: 46.24852371215843
    },
    {
        lng: 6.278200626373518,
        lat: 46.251617431640625
    },
    {
        lng: 6.286317348480225,
        lat: 46.25793075561552
    },
    {
        lng: 6.2874488830566975,
        lat: 46.25881195068354
    },
    {
        lng: 6.294883251190242,
        lat: 46.26459121704113
    },
    {
        lng: 6.294915676116943,
        lat: 46.25802993774437
    },
    {
        lng: 6.29491662979126,
        lat: 46.257812500000114
    },
    {
        lng: 6.308534145355338,
        lat: 46.25502014160156
    },
    {
        lng: 6.309154033661002,
        lat: 46.24249267578148
    },
    {
        lng: 6.296162605285758,
        lat: 46.22695541381859
    },
    {
        lng: 6.295825958251953,
        lat: 46.2265510559082
    },
    {
        lng: 6.2944707870484535,
        lat: 46.22492980957031
    },
    {
        lng: 6.27637434005743,
        lat: 46.21524047851585
    },
    {
        lng: 6.254478931427059,
        lat: 46.20793151855486
    },
    {
        lng: 6.251537799835262,
        lat: 46.20695114135748
    },
    {
        lng: 6.248983383178768,
        lat: 46.20609664916992
    },
    {
        lng: 6.247524738311824,
        lat: 46.20608901977539
    },
    {
        lng: 6.244217872619629,
        lat: 46.20606994628929
    },
    {
        lng: 6.235509395599422,
        lat: 46.206016540527514
    },
    {
        lng: 6.233842372894287,
        lat: 46.206005096435604
    },
    {
        lng: 6.2301158905029865,
        lat: 46.20355987548828
    },
    {
        lng: 6.227468490600643,
        lat: 46.20193099975586
    },
    {
        lng: 6.214252471923885,
        lat: 46.19379043579107
    },
    {
        lng: 6.2120447158814045,
        lat: 46.192569732666186
    },
    {
        lng: 6.208336830139103,
        lat: 46.19051742553734
    },
    {
        lng: 6.188821792602596,
        lat: 46.179718017578125
    },
    {
        lng: 6.186107635498047,
        lat: 46.17821884155285
    },
    {
        lng: 6.188333988189754,
        lat: 46.16809844970726
    },
    {
        lng: 6.176934719085807,
        lat: 46.15949630737316
    },
    {
        lng: 6.175299167633284,
        lat: 46.158260345458984
    },
    {
        lng: 6.152770519256592,
        lat: 46.15191268920893
    },
    {
        lng: 6.147714138031233,
        lat: 46.147304534912394
    },
    {
        lng: 6.147051334381047,
        lat: 46.146701812744254
    },
    {
        lng: 6.135412216186467,
        lat: 46.14132690429682
    },
    {
        lng: 6.129157066345215,
        lat: 46.14070892333996
    },
    {
        lng: 6.126624584197998,
        lat: 46.14046096801752
    },
    {
        lng: 6.114717483520451,
        lat: 46.14249801635748
    },
    {
        lng: 6.101649761200065,
        lat: 46.144733428955305
    },
    {
        lng: 6.097824096679801,
        lat: 46.1453857421875
    },
    {
        lng: 6.092995166778678,
        lat: 46.14961242675804
    },
    {
        lng: 6.091658592224178,
        lat: 46.15077972412115
    },
    {
        lng: 6.081097602844295,
        lat: 46.15088272094732
    },
    {
        lng: 6.0777430534362225,
        lat: 46.150917053222656
    },
    {
        lng: 6.057043552398909,
        lat: 46.15111541748047
    },
    {
        lng: 6.049066066742,
        lat: 46.14778900146496
    },
    {
        lng: 6.0478167533876785,
        lat: 46.140937805175724
    },
    {
        lng: 6.047559261321965,
        lat: 46.1408386230471
    },
    {
        lng: 6.04108905792242,
        lat: 46.138359069824446
    },
    {
        lng: 6.036899566650504,
        lat: 46.13675308227562
    },
    {
        lng: 6.035353660583496,
        lat: 46.13616180419933
    },
    {
        lng: 6.017931938171444,
        lat: 46.142292022705135
    },
    {
        lng: 6.01243352890026,
        lat: 46.14264297485374
    },
    {
        lng: 6.003605842590332,
        lat: 46.143207550049055
    },
    {
        lng: 5.993515014648551,
        lat: 46.14384841918957
    },
    {
        lng: 5.985215663909969,
        lat: 46.142471313476506
    },
    {
        lng: 5.983912467956543,
        lat: 46.14225387573265
    },
    {
        lng: 5.980376243591309,
        lat: 46.1358909606933
    },
    {
        lng: 5.98029708862299,
        lat: 46.135845184326286
    },
    {
        lng: 5.973081111908016,
        lat: 46.13336944580078
    },
    {
        lng: 5.957291603088493,
        lat: 46.127948760986555
    },
    {
        lng: 5.956063270568848,
        lat: 46.13208770751959
    },
    {
        lng: 5.957645416259766,
        lat: 46.13303756713867
    },
    {
        lng: 5.964531898498649,
        lat: 46.13716506958019
    },
    {
        lng: 5.96649789810192,
        lat: 46.138339996338175
    },
    {
        lng: 5.964700698852596,
        lat: 46.14466094970726
    },
    {
        lng: 5.973349094390983,
        lat: 46.15616607666044
    },
    {
        lng: 5.982170104980469,
        lat: 46.17221069335949
    },
    {
        lng: 5.990266799926815,
        lat: 46.174880981445426
    },
    {
        lng: 5.993235588073674,
        lat: 46.18482208251953
    },
    {
        lng: 5.963675975799674,
        lat: 46.196968078613395
    },
    {
        lng: 5.964200496673698,
        lat: 46.198028564453125
    },
    {
        lng: 5.971386432647819,
        lat: 46.202274322509766
    },
    {
        lng: 5.974412441253605,
        lat: 46.21474838256859
    },
    {
        lng: 5.986148357391357,
        lat: 46.21630096435547
    },
    {
        lng: 5.992717742919922,
        lat: 46.21717071533209
    },
    {
        lng: 5.993168830871639,
        lat: 46.218509674072436
    },
    {
        lng: 5.9938182830811115,
        lat: 46.22043991088867
    },
    {
        lng: 6.001992225646973,
        lat: 46.222354888916016
    },
    {
        lng: 6.002932548523063,
        lat: 46.22257614135765
    },
    {
        lng: 6.0059380531311035,
        lat: 46.2245979309082
    },
    {
        lng: 6.016684055328312,
        lat: 46.23181915283226
    },
    {
        lng: 6.034514427185172,
        lat: 46.23723983764671
    },
    {
        lng: 6.036984920501766,
        lat: 46.23594284057623
    },
    {
        lng: 6.04240083694458,
        lat: 46.23309707641607
    },
    {
        lng: 6.046946525573958,
        lat: 46.23194122314453
    },
    {
        lng: 6.049978256225586,
        lat: 46.234554290771484
    },
    {
        lng: 6.061954021453971,
        lat: 46.244884490967024
    },
    {
        lng: 6.065193176269588,
        lat: 46.24369049072277
    },
    {
        lng: 6.0704803466796875,
        lat: 46.241741180419865
    },
    {
        lng: 6.073695659637451,
        lat: 46.24270629882835
    },
    {
        lng: 6.088143825531006,
        lat: 46.247039794921875
    },
    {
        lng: 6.089349746704045,
        lat: 46.24637985229492
    },
    {
        lng: 6.103463649749983,
        lat: 46.23865890502958
    },
    {
        lng: 6.104496002197266,
        lat: 46.239135742187784
    },
    {
        lng: 6.115883350372428,
        lat: 46.24442672729492
    },
    {
        lng: 6.122535228729362,
        lat: 46.253337860107536
    },
    {
        lng: 6.12010765075695,
        lat: 46.26362609863298
    },
    {
        lng: 6.116039276123161,
        lat: 46.26565551757841
    },
    {
        lng: 6.102365970611686,
        lat: 46.28484725952143
    },
    {
        lng: 6.119469165802059,
        lat: 46.29528427124035
    },
    {
        lng: 6.119098663330192,
        lat: 46.307712554931925
    },
    {
        lng: 6.124377727508488,
        lat: 46.31726455688488
    },
    {
        lng: 6.137695312500114,
        lat: 46.3331184387207
    },
    {
        lng: 6.140283584594727,
        lat: 46.33989715576172
    },
    {
        lng: 6.149210453033447,
        lat: 46.343074798583984
    },
    {
        lng: 6.1575517654418945,
        lat: 46.351863861083984
    },
    {
        lng: 6.160508155822754,
        lat: 46.35581588745117
    },
    {
        lng: 6.164780616760311,
        lat: 46.36153030395502
    },
    {
        lng: 6.164861679077205,
        lat: 46.36163711547857
    },
    {
        lng: 6.166736125946045,
        lat: 46.3641433715822
    },
    {
        lng: 6.168451309204215,
        lat: 46.36745834350586
    },
    {
        lng: 6.166675567626953,
        lat: 46.37074279785185
    },
    {
        lng: 6.160984039306641,
        lat: 46.37655258178722
    },
    {
        lng: 6.155076026916618,
        lat: 46.37794876098633
    },
    {
        lng: 6.150522708892822,
        lat: 46.37902450561535
    },
    {
        lng: 6.147123813629264,
        lat: 46.37982940673851
    },
    {
        lng: 6.120400905609188,
        lat: 46.395954132080305
    },
    {
        lng: 6.106503009796143,
        lat: 46.39984130859398
    },
    {
        lng: 6.097525596618652,
        lat: 46.40828323364258
    },
    {
        lng: 6.0640087127686115,
        lat: 46.41622543334972
    },
    {
        lng: 6.078283786773795,
        lat: 46.43350982666027
    },
    {
        lng: 6.08312654495262,
        lat: 46.43936920166021
    },
    {
        lng: 6.086247444152889,
        lat: 46.443149566650504
    },
    {
        lng: 6.074868679046688,
        lat: 46.45427703857433
    },
    {
        lng: 6.073783874511776,
        lat: 46.46440887451183
    },
    {
        lng: 6.097317218780461,
        lat: 46.482456207275334
    },
    {
        lng: 6.099166870117301,
        lat: 46.485168457031364
    },
    {
        lng: 6.1092047691345215,
        lat: 46.499889373779524
    },
    {
        lng: 6.118255138397274,
        lat: 46.513164520263615
    },
    {
        lng: 6.120829582214469,
        lat: 46.516941070556925
    },
    {
        lng: 6.127887725830192,
        lat: 46.5224609375
    },
    {
        lng: 6.129816055297852,
        lat: 46.52396774291998
    },
    {
        lng: 6.13350439071678,
        lat: 46.52685165405285
    },
    {
        lng: 6.13717794418335,
        lat: 46.52972412109375
    },
    {
        lng: 6.146312236785832,
        lat: 46.53028869628906
    },
    {
        lng: 6.146652221679801,
        lat: 46.530311584472884
    },
    {
        lng: 6.153519153594971,
        lat: 46.542160034179915
    },
    {
        lng: 6.154117107391471,
        lat: 46.5431938171389
    },
    {
        lng: 6.155314922332764,
        lat: 46.54526138305664
    },
    {
        lng: 6.155487060546818,
        lat: 46.545558929443644
    },
    {
        lng: 6.138108730316162,
        lat: 46.55765914916992
    },
    {
        lng: 6.112953662872371,
        lat: 46.57511901855469
    },
    {
        lng: 6.113297939300537,
        lat: 46.57862472534197
    },
    {
        lng: 6.137713909149284,
        lat: 46.596374511718864
    },
    {
        lng: 6.161274909973258,
        lat: 46.6097030639649
    },
    {
        lng: 6.1650428771973225,
        lat: 46.61086654663086
    },
    {
        lng: 6.1675868034362225,
        lat: 46.611652374267805
    },
    {
        lng: 6.175137042999211,
        lat: 46.613979339599666
    },
    {
        lng: 6.183326721191349,
        lat: 46.6197776794433
    },
    {
        lng: 6.190768241882381,
        lat: 46.62504196166998
    },
    {
        lng: 6.204285144805908,
        lat: 46.63460922241222
    },
    {
        lng: 6.207554817199764,
        lat: 46.63692474365257
    },
    {
        lng: 6.21279239654541,
        lat: 46.640632629394815
    },
    {
        lng: 6.257272720336971,
        lat: 46.66978073120117
    },
    {
        lng: 6.267798423767033,
        lat: 46.67675399780285
    },
    {
        lng: 6.270490646362305,
        lat: 46.68319320678734
    },
    {
        lng: 6.282410144806022,
        lat: 46.68804931640648
    },
    {
        lng: 6.2982172966004555,
        lat: 46.694484710693416
    },
    {
        lng: 6.301445007324219,
        lat: 46.69566726684599
    },
    {
        lng: 6.305851936340446,
        lat: 46.69728469848633
    },
    {
        lng: 6.310794830322379,
        lat: 46.6990966796875
    },
    {
        lng: 6.311542510986442,
        lat: 46.69937133789074
    },
    {
        lng: 6.3426527976990315,
        lat: 46.71078109741211
    },
    {
        lng: 6.342758178711051,
        lat: 46.71084594726574
    },
    {
        lng: 6.356679916381836,
        lat: 46.719379425049
    },
    {
        lng: 6.35744237899786,
        lat: 46.7198486328125
    },
    {
        lng: 6.357984542846793,
        lat: 46.72047424316429
    },
    {
        lng: 6.360237121582088,
        lat: 46.72308349609375
    },
    {
        lng: 6.360758304596004,
        lat: 46.72299575805664
    },
    {
        lng: 6.365231513977051,
        lat: 46.72225952148449
    },
    {
        lng: 6.367698669433651,
        lat: 46.724479675293196
    },
    {
        lng: 6.373147487640438,
        lat: 46.72938537597656
    },
    {
        lng: 6.37426853179943,
        lat: 46.73039627075207
    },
    {
        lng: 6.380760192871094,
        lat: 46.73237228393555
    },
    {
        lng: 6.387842655181942,
        lat: 46.73453140258795
    },
    {
        lng: 6.387937068939266,
        lat: 46.73456192016613
    },
    {
        lng: 6.392616271972656,
        lat: 46.74288558959984
    },
    {
        lng: 6.395194053650016,
        lat: 46.74747085571312
    },
    {
        lng: 6.418425559997672,
        lat: 46.7531356811524
    },
    {
        lng: 6.424077033996809,
        lat: 46.754516601562614
    },
    {
        lng: 6.425817012786865,
        lat: 46.755447387695426
    },
    {
        lng: 6.428805351257381,
        lat: 46.757041931152344
    },
    {
        lng: 6.436129570007267,
        lat: 46.76095962524431
    },
    {
        lng: 6.436354160308838,
        lat: 46.761177062988565
    },
    {
        lng: 6.440823554992733,
        lat: 46.76551055908226
    },
    {
        lng: 6.442205429077205,
        lat: 46.766849517822266
    },
    {
        lng: 6.449013233184871,
        lat: 46.773448944091854
    },
    {
        lng: 6.450819492340088,
        lat: 46.77519989013672
    },
    {
        lng: 6.451745510101546,
        lat: 46.77712631225597
    },
    {
        lng: 6.457247257232666,
        lat: 46.78857040405285
    },
    {
        lng: 6.441690921783561,
        lat: 46.79775238037115
    },
    {
        lng: 6.437233924865723,
        lat: 46.80061721801758
    },
    {
        lng: 6.434491157531738,
        lat: 46.80238342285185
    },
    {
        lng: 6.430998802185115,
        lat: 46.81232070922874
    },
    {
        lng: 6.439805030822811,
        lat: 46.81600570678722
    },
    {
        lng: 6.441891670226994,
        lat: 46.83004760742199
    },
    {
        lng: 6.444070339202881,
        lat: 46.83267593383806
    },
    {
        lng: 6.451859474182243,
        lat: 46.84206390380871
    },
    {
        lng: 6.459761142730656,
        lat: 46.85159301757841
    },
    {
        lng: 6.4613184928893475,
        lat: 46.86217880249052
    },
    {
        lng: 6.462949275970573,
        lat: 46.87622451782249
    },
    {
        lng: 6.46453857421875,
        lat: 46.88991165161133
    },
    {
        lng: 6.447444915771484,
        lat: 46.91110229492216
    },
    {
        lng: 6.43301439285284,
        lat: 46.92781829834007
    },
    {
        lng: 6.444749355316276,
        lat: 46.933406829833984
    },
    {
        lng: 6.465325832367057,
        lat: 46.9507865905764
    },
    {
        lng: 6.4725170135498615,
        lat: 46.95685958862305
    },
    {
        lng: 6.4744257926942055,
        lat: 46.95825576782232
    },
    {
        lng: 6.485274791717586,
        lat: 46.966194152832145
    },
    {
        lng: 6.491347789764461,
        lat: 46.97063827514671
    },
    {
        lng: 6.496685981750488,
        lat: 46.97418975830084
    },
    {
        lng: 6.500184059143066,
        lat: 46.97114944458025
    },
    {
        lng: 6.505959033966178,
        lat: 46.9661293029788
    },
    {
        lng: 6.562451362609863,
        lat: 46.97931289672846
    },
    {
        lng: 6.584228992462158,
        lat: 46.98759841918968
    },
    {
        lng: 6.58823394775402,
        lat: 46.989253997802734
    },
    {
        lng: 6.596061706543082,
        lat: 46.992488861083984
    },
    {
        lng: 6.596133708953857,
        lat: 46.99251937866228
    },
    {
        lng: 6.596406936645565,
        lat: 46.992488861083984
    },
    {
        lng: 6.6112060546875,
        lat: 46.99099349975603
    },
    {
        lng: 6.6339111328125,
        lat: 46.998569488525504
    },
    {
        lng: 6.654189586639404,
        lat: 47.022373199463004
    },
    {
        lng: 6.677932262420768,
        lat: 47.035221099853516
    },
    {
        lng: 6.697948932647648,
        lat: 47.03836441040045
    },
    {
        lng: 6.701315879821891,
        lat: 47.04072952270502
    },
    {
        lng: 6.715973854064998,
        lat: 47.05102920532221
    },
    {
        lng: 6.715209484100342,
        lat: 47.051631927490234
    },
    {
        lng: 6.700486183166504,
        lat: 47.06324386596674
    },
    {
        lng: 6.696344375610408,
        lat: 47.06650924682617
    },
    {
        lng: 6.71090221405035,
        lat: 47.085025787353686
    },
    {
        lng: 6.743571281433219,
        lat: 47.09371566772472
    },
    {
        lng: 6.743018150329647,
        lat: 47.096153259277344
    },
    {
        lng: 6.740549564361629,
        lat: 47.10703659057623
    },
    {
        lng: 6.744020938873405,
        lat: 47.109523773193416
    },
    {
        lng: 6.744133949279785,
        lat: 47.109603881835994
    },
    {
        lng: 6.764214515686092,
        lat: 47.11981201171875
    },
    {
        lng: 6.802145957946834,
        lat: 47.129459381103686
    },
    {
        lng: 6.803709506988525,
        lat: 47.13027954101557
    },
    {
        lng: 6.817468643188533,
        lat: 47.13750076293945
    },
    {
        lng: 6.826475620269832,
        lat: 47.144168853759766
    },
    {
        lng: 6.8529558181762695,
        lat: 47.15993118286144
    },
    {
        lng: 6.855004310607967,
        lat: 47.16307067871105
    },
    {
        lng: 6.852770805358944,
        lat: 47.16415405273443
    },
    {
        lng: 6.851585388183821,
        lat: 47.164730072021484
    },
    {
        lng: 6.843406677246207,
        lat: 47.16870117187523
    },
    {
        lng: 6.844374179840145,
        lat: 47.172069549560604
    },
    {
        lng: 6.861405372619743,
        lat: 47.179344177246264
    },
    {
        lng: 6.871970176697005,
        lat: 47.18561553955078
    },
    {
        lng: 6.88216066360485,
        lat: 47.20095825195335
    },
    {
        lng: 6.892735958099365,
        lat: 47.20755386352545
    },
    {
        lng: 6.893103122711295,
        lat: 47.20778274536161
    },
    {
        lng: 6.895277023315543,
        lat: 47.20878219604492
    },
    {
        lng: 6.907331466674805,
        lat: 47.21432876586914
    },
    {
        lng: 6.921872138977108,
        lat: 47.22101974487322
    },
    {
        lng: 6.9243078231810955,
        lat: 47.22364807128929
    },
    {
        lng: 6.929815292358342,
        lat: 47.22959136962896
    },
    {
        lng: 6.932035446167049,
        lat: 47.22994232177757
    },
    {
        lng: 6.939467430114803,
        lat: 47.23110961914068
    },
    {
        lng: 6.941004276275862,
        lat: 47.23426818847673
    },
    {
        lng: 6.942584514618034,
        lat: 47.23746490478527
    },
    {
        lng: 6.949076175689811,
        lat: 47.2404136657716
    },
    {
        lng: 6.955122947692928,
        lat: 47.243160247802734
    },
    {
        lng: 6.954730987548942,
        lat: 47.24357986450218
    },
    {
        lng: 6.947221279144287,
        lat: 47.25163269042986
    },
    {
        lng: 6.9509954452515785,
        lat: 47.267856597900504
    },
    {
        lng: 6.94091892242443,
        lat: 47.28663253784197
    },
    {
        lng: 6.944645881652946,
        lat: 47.288856506347884
    },
    {
        lng: 6.947139739990234,
        lat: 47.29088211059593
    },
    {
        lng: 6.951220512390364,
        lat: 47.29107284545921
    },
    {
        lng: 6.967930316925106,
        lat: 47.29186248779297
    },
    {
        lng: 6.972093105316276,
        lat: 47.29205703735357
    },
    {
        lng: 6.978549957275504,
        lat: 47.29512023925798
    },
    {
        lng: 6.980483055114803,
        lat: 47.296035766601506
    },
    {
        lng: 6.983271598815975,
        lat: 47.29617691040039
    },
    {
        lng: 6.995777606964168,
        lat: 47.29680633544922
    },
    {
        lng: 7.000206470489502,
        lat: 47.29888153076183
    },
    {
        lng: 7.0023975372313885,
        lat: 47.299907684326456
    },
    {
        lng: 7.008409500122127,
        lat: 47.30272674560547
    },
    {
        lng: 7.011118412017822,
        lat: 47.30765533447277
    },
    {
        lng: 7.015481472015381,
        lat: 47.31559371948242
    },
    {
        lng: 7.009734630584717,
        lat: 47.32435607910179
    },
    {
        lng: 7.016757965087891,
        lat: 47.32542419433594
    },
    {
        lng: 7.034060478210563,
        lat: 47.32805633544933
    },
    {
        lng: 7.0439343452453045,
        lat: 47.32695770263672
    },
    {
        lng: 7.048021793365535,
        lat: 47.329509735107365
    },
    {
        lng: 7.054788112640381,
        lat: 47.333736419677905
    },
    {
        lng: 7.05291843414301,
        lat: 47.33689117431658
    },
    {
        lng: 7.056473255157471,
        lat: 47.33928298950195
    },
    {
        lng: 7.059208393096924,
        lat: 47.3411216735841
    },
    {
        lng: 7.0602641105651855,
        lat: 47.34188461303722
    },
    {
        lng: 7.051705837249756,
        lat: 47.350330352783374
    },
    {
        lng: 7.0504045486450195,
        lat: 47.357334136963004
    },
    {
        lng: 7.048191547393799,
        lat: 47.36053085327154
    },
    {
        lng: 7.032421588897762,
        lat: 47.368045806884766
    },
    {
        lng: 7.031337738037166,
        lat: 47.368560791015625
    },
    {
        lng: 7.012157917022762,
        lat: 47.371429443359375
    },
    {
        lng: 7.011354446411076,
        lat: 47.37155151367199
    },
    {
        lng: 7.007050991058293,
        lat: 47.36915969848644
    },
    {
        lng: 7.004202365875301,
        lat: 47.367576599121094
    },
    {
        lng: 7.003519058227653,
        lat: 47.36719512939453
    },
    {
        lng: 6.999339103698844,
        lat: 47.36487197875999
    },
    {
        lng: 6.9895501136779785,
        lat: 47.363468170166016
    },
    {
        lng: 6.976454257965202,
        lat: 47.36204910278326
    },
    {
        lng: 6.936696529388485,
        lat: 47.35773468017601
    },
    {
        lng: 6.935257434845084,
        lat: 47.35757827758806
    },
    {
        lng: 6.920492649078426,
        lat: 47.3555641174317
    },
    {
        lng: 6.9079585075379555,
        lat: 47.357620239257926
    },
    {
        lng: 6.90101528167736,
        lat: 47.35876083374046
    },
    {
        lng: 6.879798889160213,
        lat: 47.352436065674
    },
    {
        lng: 6.883793830871696,
        lat: 47.371337890625284
    },
    {
        lng: 6.887073516845703,
        lat: 47.37437057495134
    },
    {
        lng: 6.892227172851619,
        lat: 47.37913513183605
    },
    {
        lng: 6.894958972930965,
        lat: 47.381660461425895
    },
    {
        lng: 6.897334575653133,
        lat: 47.38234710693382
    },
    {
        lng: 6.9068870544433025,
        lat: 47.38510513305687
    },
    {
        lng: 6.908352375030518,
        lat: 47.385528564453296
    },
    {
        lng: 6.912147998809871,
        lat: 47.38662719726557
    },
    {
        lng: 6.912003517150879,
        lat: 47.387153625488395
    },
    {
        lng: 6.90952730178833,
        lat: 47.39617919921875
    },
    {
        lng: 6.915802001953125,
        lat: 47.39976119995117
    },
    {
        lng: 6.917294025421086,
        lat: 47.405460357666186
    },
    {
        lng: 6.92116022109991,
        lat: 47.40569305419933
    },
    {
        lng: 6.937510490417594,
        lat: 47.40668106079107
    },
    {
        lng: 6.9390883445740315,
        lat: 47.408596038818416
    },
    {
        lng: 6.94000339508051,
        lat: 47.4097061157226
    },
    {
        lng: 6.939178943634033,
        lat: 47.43370056152355
    },
    {
        lng: 6.944364547729492,
        lat: 47.43405914306646
    },
    {
        lng: 6.962571620941276,
        lat: 47.43531799316406
    },
    {
        lng: 6.963840007781982,
        lat: 47.437248229980696
    },
    {
        lng: 6.965622425079459,
        lat: 47.43996047973633
    },
    {
        lng: 6.97028303146368,
        lat: 47.44705200195324
    },
    {
        lng: 6.973242282867432,
        lat: 47.447208404541016
    },
    {
        lng: 6.989852428436279,
        lat: 47.448097229003906
    },
    {
        lng: 6.998209476471004,
        lat: 47.45164108276367
    },
    {
        lng: 6.998239994049129,
        lat: 47.451854705810774
    },
    {
        lng: 7.000036239624023,
        lat: 47.464347839355696
    },
    {
        lng: 6.997643947601375,
        lat: 47.46494674682617
    },
    {
        lng: 6.991764068603459,
        lat: 47.46641921997099
    },
    {
        lng: 6.992032527923698,
        lat: 47.47179794311518
    },
    {
        lng: 6.988495826721191,
        lat: 47.473037719726506
    },
    {
        lng: 6.988581180572453,
        lat: 47.48558807373075
    },
    {
        lng: 6.9828972816467285,
        lat: 47.49425506591797
    },
    {
        lng: 6.9898934364319985,
        lat: 47.495933532714844
    },
    {
        lng: 7.0243945121765705,
        lat: 47.50421524047846
    },
    {
        lng: 7.03623819351202,
        lat: 47.499000549316406
    },
    {
        lng: 7.039888858795109,
        lat: 47.49814605712896
    },
    {
        lng: 7.046338081359806,
        lat: 47.496639251708984
    },
    {
        lng: 7.078799724578971,
        lat: 47.48905944824213
    },
    {
        lng: 7.079005718231144,
        lat: 47.489128112793196
    },
    {
        lng: 7.096725940704346,
        lat: 47.495136260986555
    },
    {
        lng: 7.110619544982967,
        lat: 47.49489974975586
    },
    {
        lng: 7.11585187911993,
        lat: 47.49705505371105
    },
    {
        lng: 7.116924762725887,
        lat: 47.49749755859375
    },
    {
        lng: 7.121239185333366,
        lat: 47.49927520751959
    },
    {
        lng: 7.130345821380729,
        lat: 47.503025054931754
    },
    {
        lng: 7.148515224456844,
        lat: 47.49786376953142
    },
    {
        lng: 7.1571455001831055,
        lat: 47.492168426513956
    },
    {
        lng: 7.159130096435604,
        lat: 47.49085998535179
    },
    {
        lng: 7.168905258178768,
        lat: 47.489501953125
    },
    {
        lng: 7.198164939880485,
        lat: 47.493579864502124
    },
    {
        lng: 7.2005443572998615,
        lat: 47.49250030517578
    },
    {
        lng: 7.20231294631958,
        lat: 47.491699218750284
    },
    {
        lng: 7.194831371307487,
        lat: 47.48943710327171
    },
    {
        lng: 7.193308353424129,
        lat: 47.4889755249024
    },
    {
        lng: 7.187729835510368,
        lat: 47.483463287353686
    },
    {
        lng: 7.177992820739689,
        lat: 47.468109130859546
    },
    {
        lng: 7.1780552864075275,
        lat: 47.46569442749018
    },
    {
        lng: 7.178251266479549,
        lat: 47.458137512207145
    },
    {
        lng: 7.172162055969352,
        lat: 47.44550323486334
    },
    {
        lng: 7.172656536102238,
        lat: 47.4449577331543
    },
    {
        lng: 7.174731731414909,
        lat: 47.44266891479492
    },
    {
        lng: 7.194690227508602,
        lat: 47.43774032592796
    },
    {
        lng: 7.206223011016846,
        lat: 47.434890747070256
    },
    {
        lng: 7.223327636718977,
        lat: 47.4395103454591
    },
    {
        lng: 7.235901832580623,
        lat: 47.43687820434582
    },
    {
        lng: 7.237950801849365,
        lat: 47.43036651611328
    },
    {
        lng: 7.2471423149109455,
        lat: 47.42113494873058
    },
    {
        lng: 7.2474141120910645,
        lat: 47.42121505737333
    },
    {
        lng: 7.303975582122803,
        lat: 47.43825531005865
    },
    {
        lng: 7.324561119079647,
        lat: 47.43895339965849
    },
    {
        lng: 7.329733848571834,
        lat: 47.43953704834007
    },
    {
        lng: 7.338911056518668,
        lat: 47.44057083129883
    },
    {
        lng: 7.351147174835262,
        lat: 47.43482208251959
    },
    {
        lng: 7.38629770278942,
        lat: 47.43196868896496
    },
    {
        lng: 7.387094974517879,
        lat: 47.43216323852556
    },
    {
        lng: 7.40034389495861,
        lat: 47.4353866577149
    },
    {
        lng: 7.42254638671875,
        lat: 47.44775390625023
    },
    {
        lng: 7.424055099487305,
        lat: 47.450065612793026
    },
    {
        lng: 7.425215244293327,
        lat: 47.451843261718864
    },
    {
        lng: 7.4300665855407715,
        lat: 47.45927810668968
    },
    {
        lng: 7.444630146026725,
        lat: 47.461914062500114
    },
    {
        lng: 7.448073863983211,
        lat: 47.46644973754877
    },
    {
        lng: 7.451877593994141,
        lat: 47.47145843505871
    },
    {
        lng: 7.453484535217285,
        lat: 47.473575592041016
    },
    {
        lng: 7.431159496307487,
        lat: 47.48146057128923
    },
    {
        lng: 7.421224594116268,
        lat: 47.48017120361351
    },
    {
        lng: 7.4260945320129395,
        lat: 47.48657226562494
    },
    {
        lng: 7.42765283584589,
        lat: 47.48862075805681
    },
    {
        lng: 7.427948951721191,
        lat: 47.489009857177734
    },
    {
        lng: 7.4314637184143635,
        lat: 47.49362945556635
    },
    {
        lng: 7.433419704437313,
        lat: 47.496200561523665
    },
    {
        lng: 7.434762954712028,
        lat: 47.49796676635759
    },
    {
        lng: 7.454618930816764,
        lat: 47.4894256591798
    },
    {
        lng: 7.4703149795532795,
        lat: 47.48262023925781
    },
    {
        lng: 7.475515842437801,
        lat: 47.48036575317394
    },
    {
        lng: 7.4855761528015705,
        lat: 47.48186492919922
    },
    {
        lng: 7.492657661437931,
        lat: 47.48611450195318
    },
    {
        lng: 7.495184898376408,
        lat: 47.48763275146507
    },
    {
        lng: 7.50092887878418,
        lat: 47.491081237793026
    },
    {
        lng: 7.503333568572998,
        lat: 47.49285125732433
    },
    {
        lng: 7.503836631774959,
        lat: 47.493221282958984
    },
    {
        lng: 7.507921218872127,
        lat: 47.4962272644043
    },
    {
        lng: 7.508714675903377,
        lat: 47.49994277954107
    },
    {
        lng: 7.509630680084342,
        lat: 47.5042343139649
    },
    {
        lng: 7.510120868682861,
        lat: 47.50653076171881
    },
    {
        lng: 7.504426002502441,
        lat: 47.51332092285173
    },
    {
        lng: 7.501793384551945,
        lat: 47.51646041870134
    },
    {
        lng: 7.497798442840633,
        lat: 47.521224975585994
    },
    {
        lng: 7.50227165222168,
        lat: 47.51672744750982
    },
    {
        lng: 7.503467082977352,
        lat: 47.51552581787121
    },
    {
        lng: 7.518510341644344,
        lat: 47.516204833984375
    },
    {
        lng: 7.523452281951961,
        lat: 47.51704025268572
    },
    {
        lng: 7.524703979492244,
        lat: 47.518810272217024
    },
    {
        lng: 7.530015468597526,
        lat: 47.52632522583019
    },
    {
        lng: 7.529332637786979,
        lat: 47.52973175048828
    },
    {
        lng: 7.5291619300842285,
        lat: 47.52980804443365
    },
    {
        lng: 7.529027462005615,
        lat: 47.529865264892805
    },
    {
        lng: 7.520962715148983,
        lat: 47.53339385986351
    },
    {
        lng: 7.502446651458854,
        lat: 47.52884674072271
    },
    {
        lng: 7.501858711242676,
        lat: 47.529945373535384
    },
    {
        lng: 7.499031066894531,
        lat: 47.53523254394531
    },
    {
        lng: 7.504502773284912,
        lat: 47.542964935302734
    },
    {
        lng: 7.507875919342098,
        lat: 47.544754028320256
    },
    {
        lng: 7.517362594604549,
        lat: 47.54576873779297
    },
    {
        lng: 7.551769256591797,
        lat: 47.56338119506853
    },
    {
        lng: 7.5551562309265705,
        lat: 47.564517974853516
    },
    {
        lng: 7.556335926055851,
        lat: 47.56491470336931
    },
    {
        lng: 7.560994625091666,
        lat: 47.57422637939476
    },
    {
        lng: 7.574802398681754,
        lat: 47.57613754272461
    },
    {
        lng: 7.584266185760555,
        lat: 47.575714111328125
    },
    {
        lng: 7.588920116424674,
        lat: 47.58903884887707
    },
    {
        lng: 7.591418266296387,
        lat: 47.596191406250114
    },
    {
        lng: 7.592329502105827,
        lat: 47.59621429443388
    },
    {
        lng: 7.59223842620861,
        lat: 47.59561920166027
    },
    {
        lng: 7.589193344116154,
        lat: 47.589729309082315
    },
    {
        lng: 7.5903539657592205,
        lat: 47.58943557739269
    },
    {
        lng: 7.592270374298096,
        lat: 47.58898162841808
    },
    {
        lng: 7.593413829803524,
        lat: 47.58871078491205
    },
    {
        lng: 7.595802783966178,
        lat: 47.58812332153332
    },
    {
        lng: 7.598578453063965,
        lat: 47.58744430541992
    },
    {
        lng: 7.601454734802246,
        lat: 47.586299896240234
    },
    {
        lng: 7.602306842804012,
        lat: 47.585960388183594
    },
    {
        lng: 7.6035356521606445,
        lat: 47.585407257080135
    },
    {
        lng: 7.604350566864014,
        lat: 47.585067749023665
    },
    {
        lng: 7.604925155639705,
        lat: 47.58482742309576
    },
    {
        lng: 7.604626655578613,
        lat: 47.58355712890648
    },
    {
        lng: 7.604048252105713,
        lat: 47.5813941955567
    },
    {
        lng: 7.6051721572876545,
        lat: 47.580127716064396
    },
    {
        lng: 7.6053595542907715,
        lat: 47.579872131347656
    },
    {
        lng: 7.604484081268367,
        lat: 47.577789306640796
    },
    {
        lng: 7.606385707855225,
        lat: 47.57799530029297
    },
    {
        lng: 7.6100316047669025,
        lat: 47.578144073486385
    },
    {
        lng: 7.612784862518254,
        lat: 47.57810974121105
    },
    {
        lng: 7.615464210510311,
        lat: 47.577964782714844
    },
    {
        lng: 7.616853713989315,
        lat: 47.577697753906364
    },
    {
        lng: 7.617547512054557,
        lat: 47.57756423950218
    },
    {
        lng: 7.619266033172664,
        lat: 47.576877593994254
    },
    {
        lng: 7.625244140625114,
        lat: 47.57982635498058
    },
    {
        lng: 7.627862453460807,
        lat: 47.581199645996094
    },
    {
        lng: 7.628526687622184,
        lat: 47.58166503906273
    },
    {
        lng: 7.634116649627799,
        lat: 47.58592605590843
    },
    {
        lng: 7.636561870574951,
        lat: 47.588451385498104
    },
    {
        lng: 7.639497280120963,
        lat: 47.59033584594749
    },
    {
        lng: 7.643163681030217,
        lat: 47.59130477905279
    },
    {
        lng: 7.642914772033691,
        lat: 47.591705322265796
    },
    {
        lng: 7.642526626586914,
        lat: 47.5922164916995
    },
    {
        lng: 7.64190769195551,
        lat: 47.593036651611385
    },
    {
        lng: 7.641907215118465,
        lat: 47.593479156494084
    },
    {
        lng: 7.641905784606934,
        lat: 47.59417724609375
    },
    {
        lng: 7.645096778869743,
        lat: 47.596462249755916
    },
    {
        lng: 7.645777225494442,
        lat: 47.59695053100603
    },
    {
        lng: 7.647312641143856,
        lat: 47.59660339355497
    },
    {
        lng: 7.648457527160758,
        lat: 47.59634399414085
    },
    {
        lng: 7.649076938629207,
        lat: 47.59621047973633
    },
    {
        lng: 7.649232387542838,
        lat: 47.596176147460994
    },
    {
        lng: 7.649817943572998,
        lat: 47.59603881835949
    },
    {
        lng: 7.6512060165404705,
        lat: 47.59570693969732
    },
    {
        lng: 7.651532173156681,
        lat: 47.59569931030279
    },
    {
        lng: 7.652430534362736,
        lat: 47.5956840515139
    },
    {
        lng: 7.655509948730526,
        lat: 47.59529876708979
    },
    {
        lng: 7.657022476196289,
        lat: 47.59488677978544
    },
    {
        lng: 7.661497592926082,
        lat: 47.59380340576189
    },
    {
        lng: 7.66205024719244,
        lat: 47.593669891357365
    },
    {
        lng: 7.664093971252441,
        lat: 47.59303283691406
    },
    {
        lng: 7.667247772216854,
        lat: 47.59204483032249
    },
    {
        lng: 7.6685662269592285,
        lat: 47.59204483032249
    },
    {
        lng: 7.671302318573112,
        lat: 47.592041015625114
    },
    {
        lng: 7.6750245094299885,
        lat: 47.592041015625114
    },
    {
        lng: 7.675480842590389,
        lat: 47.59310150146507
    },
    {
        lng: 7.677553653717041,
        lat: 47.59500122070335
    },
    {
        lng: 7.678737163543701,
        lat: 47.59584045410185
    },
    {
        lng: 7.679722785949821,
        lat: 47.596446990967024
    },
    {
        lng: 7.680698871612606,
        lat: 47.59722137451172
    },
    {
        lng: 7.681461811065731,
        lat: 47.59740829467779
    },
    {
        lng: 7.682943820953312,
        lat: 47.59856414794922
    },
    {
        lng: 7.684775829315242,
        lat: 47.59882736206049
    },
    {
        lng: 7.6875433921813965,
        lat: 47.59872055053734
    },
    {
        lng: 7.688526630401668,
        lat: 47.59874725341797
    },
    {
        lng: 7.689326286315975,
        lat: 47.59891128540062
    },
    {
        lng: 7.690503597259635,
        lat: 47.599239349365405
    },
    {
        lng: 7.6916942596436115,
        lat: 47.59964752197294
    },
    {
        lng: 7.692990779876823,
        lat: 47.600669860840014
    },
    {
        lng: 7.693596363067684,
        lat: 47.60053253173851
    },
    {
        lng: 7.692561149597168,
        lat: 47.599243164062614
    },
    {
        lng: 7.691634178161564,
        lat: 47.598529815674055
    },
    {
        lng: 7.690035343170223,
        lat: 47.59757232666027
    },
    {
        lng: 7.688018321991024,
        lat: 47.597164154052734
    },
    {
        lng: 7.684669971466178,
        lat: 47.59666061401373
    },
    {
        lng: 7.6838154792785645,
        lat: 47.59622192382841
    },
    {
        lng: 7.683027267455998,
        lat: 47.595546722412394
    },
    {
        lng: 7.681400775909424,
        lat: 47.593837738037394
    },
    {
        lng: 7.680425643920898,
        lat: 47.593040466308764
    },
    {
        lng: 7.679657936096135,
        lat: 47.59236526489258
    },
    {
        lng: 7.677460193633976,
        lat: 47.59127426147484
    },
    {
        lng: 7.676116466522217,
        lat: 47.590358734130916
    },
    {
        lng: 7.671741485595703,
        lat: 47.58736038208019
    },
    {
        lng: 7.672003746032715,
        lat: 47.585075378418196
    },
    {
        lng: 7.680698394775334,
        lat: 47.58269119262718
    },
    {
        lng: 7.681828022003231,
        lat: 47.57754135131859
    },
    {
        lng: 7.683711528778133,
        lat: 47.57666397094755
    },
    {
        lng: 7.6837306022643475,
        lat: 47.57397460937517
    },
    {
        lng: 7.685845375061092,
        lat: 47.57300567626953
    },
    {
        lng: 7.688841819763184,
        lat: 47.57241058349604
    },
    {
        lng: 7.689521789550895,
        lat: 47.571483612060604
    },
    {
        lng: 7.689373493194523,
        lat: 47.571235656738565
    },
    {
        lng: 7.6888861656188965,
        lat: 47.570449829101676
    },
    {
        lng: 7.687995910644645,
        lat: 47.56927490234381
    },
    {
        lng: 7.6873726844788735,
        lat: 47.568550109863395
    },
    {
        lng: 7.687188148498592,
        lat: 47.5682945251466
    },
    {
        lng: 7.687029838562012,
        lat: 47.56803512573248
    },
    {
        lng: 7.686840057373047,
        lat: 47.567634582519474
    },
    {
        lng: 7.685943126678524,
        lat: 47.56563568115257
    },
    {
        lng: 7.6837968826293945,
        lat: 47.56823348999029
    },
    {
        lng: 7.683409214019832,
        lat: 47.5685577392581
    },
    {
        lng: 7.683449745178336,
        lat: 47.568996429443416
    },
    {
        lng: 7.683583259582463,
        lat: 47.57045364379883
    },
    {
        lng: 7.683414459228516,
        lat: 47.57125854492182
    },
    {
        lng: 7.681582927703857,
        lat: 47.570697784424055
    },
    {
        lng: 7.680347442626953,
        lat: 47.570167541503906
    },
    {
        lng: 7.678302764892635,
        lat: 47.56903076171875
    },
    {
        lng: 7.677689075469971,
        lat: 47.56776428222662
    },
    {
        lng: 7.675127983093205,
        lat: 47.56550979614258
    },
    {
        lng: 7.677222251892147,
        lat: 47.565029144287394
    },
    {
        lng: 7.677264213562012,
        lat: 47.56379318237305
    },
    {
        lng: 7.676258563995361,
        lat: 47.56355285644537
    },
    {
        lng: 7.675675868988094,
        lat: 47.56348800659191
    },
    {
        lng: 7.674681186676082,
        lat: 47.56347656250017
    },
    {
        lng: 7.672325134277401,
        lat: 47.563770294189624
    },
    {
        lng: 7.672292232513428,
        lat: 47.56552124023449
    },
    {
        lng: 7.670133113861027,
        lat: 47.565872192382926
    },
    {
        lng: 7.665574073791504,
        lat: 47.56507110595726
    },
    {
        lng: 7.664237976074219,
        lat: 47.565380096435604
    },
    {
        lng: 7.658840656280631,
        lat: 47.564491271972884
    },
    {
        lng: 7.656792640686035,
        lat: 47.56311798095703
    },
    {
        lng: 7.656188011169434,
        lat: 47.56231689453125
    },
    {
        lng: 7.6546807289123535,
        lat: 47.56241226196289
    },
    {
        lng: 7.650559902191276,
        lat: 47.560829162597656
    },
    {
        lng: 7.648129463195801,
        lat: 47.55989074707054
    },
    {
        lng: 7.6442818641663735,
        lat: 47.56142807006847
    },
    {
        lng: 7.641333103180045,
        lat: 47.561187744140796
    },
    {
        lng: 7.638939380645695,
        lat: 47.56284332275419
    },
    {
        lng: 7.638027667999381,
        lat: 47.56330108642578
    },
    {
        lng: 7.636398792266959,
        lat: 47.56411743164085
    },
    {
        lng: 7.636097431182918,
        lat: 47.563705444336165
    },
    {
        lng: 7.635932922363395,
        lat: 47.56349563598644
    },
    {
        lng: 7.6348743438720135,
        lat: 47.56214904785173
    },
    {
        lng: 7.63445425033575,
        lat: 47.56159973144531
    },
    {
        lng: 7.634097099304313,
        lat: 47.56111526489258
    },
    {
        lng: 7.636385440826473,
        lat: 47.560295104980696
    },
    {
        lng: 7.637898921966496,
        lat: 47.559650421142635
    },
    {
        lng: 7.63894891738903,
        lat: 47.55899429321306
    },
    {
        lng: 7.640276432037467,
        lat: 47.55791473388666
    },
    {
        lng: 7.642195224761963,
        lat: 47.55607986450207
    },
    {
        lng: 7.643590927124023,
        lat: 47.554546356201456
    },
    {
        lng: 7.645500183105412,
        lat: 47.55214309692383
    },
    {
        lng: 7.646503448486442,
        lat: 47.55082702636747
    },
    {
        lng: 7.646775245666561,
        lat: 47.55048370361328
    },
    {
        lng: 7.647367000579891,
        lat: 47.54973602294922
    },
    {
        lng: 7.648127079009953,
        lat: 47.548984527588004
    },
    {
        lng: 7.649228572845459,
        lat: 47.548156738281534
    },
    {
        lng: 7.650357723236084,
        lat: 47.5475883483889
    },
    {
        lng: 7.651653289795036,
        lat: 47.54705047607422
    },
    {
        lng: 7.652683258056641,
        lat: 47.546737670898665
    },
    {
        lng: 7.6539626121521565,
        lat: 47.546485900878906
    },
    {
        lng: 7.656806468963737,
        lat: 47.546184539794865
    },
    {
        lng: 7.657653331756592,
        lat: 47.54609680175781
    },
    {
        lng: 7.658801555633659,
        lat: 47.545925140380916
    },
    {
        lng: 7.659701824188289,
        lat: 47.545661926269645
    },
    {
        lng: 7.660654544830379,
        lat: 47.54523086547846
    },
    {
        lng: 7.6614446640015785,
        lat: 47.544681549072266
    },
    {
        lng: 7.662158966064567,
        lat: 47.54398727417015
    },
    {
        lng: 7.662585258483887,
        lat: 47.54317092895525
    },
    {
        lng: 7.6632847785950275,
        lat: 47.5412483215332
    },
    {
        lng: 7.663774013519287,
        lat: 47.54006195068382
    },
    {
        lng: 7.664271354675236,
        lat: 47.53932189941429
    },
    {
        lng: 7.664603233337402,
        lat: 47.538825988769645
    },
    {
        lng: 7.665502071380672,
        lat: 47.537849426269815
    },
    {
        lng: 7.666476726531926,
        lat: 47.53701782226585
    },
    {
        lng: 7.6676182746887775,
        lat: 47.53622055053722
    },
    {
        lng: 7.668870449066276,
        lat: 47.535594940185774
    },
    {
        lng: 7.668920040130672,
        lat: 47.53556823730497
    },
    {
        lng: 7.670427799224967,
        lat: 47.53501129150402
    },
    {
        lng: 7.675140380859432,
        lat: 47.53367614746105
    },
    {
        lng: 7.678216457367057,
        lat: 47.53295898437517
    },
    {
        lng: 7.680171966552734,
        lat: 47.53266525268555
    },
    {
        lng: 7.682666778564453,
        lat: 47.532531738281364
    },
    {
        lng: 7.685575962066764,
        lat: 47.53252029418968
    },
    {
        lng: 7.687771320343074,
        lat: 47.532463073730526
    },
    {
        lng: 7.690432548522949,
        lat: 47.532360076904524
    },
    {
        lng: 7.692624568939323,
        lat: 47.532360076904524
    },
    {
        lng: 7.694431304931584,
        lat: 47.532463073730526
    },
    {
        lng: 7.696352958679199,
        lat: 47.5327720642091
    },
    {
        lng: 7.698179721832389,
        lat: 47.53327560424805
    },
    {
        lng: 7.6997065544129555,
        lat: 47.53385543823265
    },
    {
        lng: 7.701058864593563,
        lat: 47.53454971313471
    },
    {
        lng: 7.702326774597225,
        lat: 47.535236358642635
    },
    {
        lng: 7.703643321991024,
        lat: 47.53581237792969
    },
    {
        lng: 7.704879283905143,
        lat: 47.5363311767581
    },
    {
        lng: 7.707914352417049,
        lat: 47.537052154541016
    },
    {
        lng: 7.709486007690543,
        lat: 47.53762054443365
    },
    {
        lng: 7.709753036499023,
        lat: 47.537761688232706
    },
    {
        lng: 7.7106733322143555,
        lat: 47.53824996948265
    },
    {
        lng: 7.711907386779728,
        lat: 47.53879547119152
    },
    {
        lng: 7.713150024414119,
        lat: 47.539199829101676
    },
    {
        lng: 7.714263916015682,
        lat: 47.53962707519531
    },
    {
        lng: 7.715457916259766,
        lat: 47.54014205932617
    },
    {
        lng: 7.716266632080135,
        lat: 47.540538787842024
    },
    {
        lng: 7.717058181762809,
        lat: 47.54092407226574
    },
    {
        lng: 7.71804141998291,
        lat: 47.541435241699276
    },
    {
        lng: 7.719118595123291,
        lat: 47.54177856445335
    },
    {
        lng: 7.719997882843074,
        lat: 47.541885375976506
    },
    {
        lng: 7.7212171554566,
        lat: 47.54194641113281
    },
    {
        lng: 7.722396373748893,
        lat: 47.541976928711165
    },
    {
        lng: 7.724067687988395,
        lat: 47.542247772217024
    },
    {
        lng: 7.725576877594051,
        lat: 47.54240036010759
    },
    {
        lng: 7.727382183074951,
        lat: 47.54253005981474
    },
    {
        lng: 7.729863166809139,
        lat: 47.54265213012718
    },
    {
        lng: 7.731544017791805,
        lat: 47.5427513122558
    },
    {
        lng: 7.737673759460563,
        lat: 47.543224334717024
    },
    {
        lng: 7.745689868927116,
        lat: 47.54388427734398
    },
    {
        lng: 7.748747825622615,
        lat: 47.54424667358427
    },
    {
        lng: 7.749879837036076,
        lat: 47.54436111450218
    },
    {
        lng: 7.7510523796080975,
        lat: 47.54450607299822
    },
    {
        lng: 7.752050399780387,
        lat: 47.544757843017805
    },
    {
        lng: 7.753500461578483,
        lat: 47.54522323608393
    },
    {
        lng: 7.754359245300293,
        lat: 47.54570007324213
    },
    {
        lng: 7.754962444305363,
        lat: 47.54623031616211
    },
    {
        lng: 7.756569862365723,
        lat: 47.54766845703125
    },
    {
        lng: 7.757138729095459,
        lat: 47.54805374145536
    },
    {
        lng: 7.75771427154541,
        lat: 47.5483283996582
    },
    {
        lng: 7.758451461792049,
        lat: 47.548530578613565
    },
    {
        lng: 7.7591166496276855,
        lat: 47.54868698120117
    },
    {
        lng: 7.7592015266419025,
        lat: 47.548706054687614
    },
    {
        lng: 7.761153221130428,
        lat: 47.54919815063505
    },
    {
        lng: 7.762121677398682,
        lat: 47.54945373535162
    },
    {
        lng: 7.764045715332088,
        lat: 47.549972534179915
    },
    {
        lng: 7.765015125274601,
        lat: 47.550220489502124
    },
    {
        lng: 7.7659850120543865,
        lat: 47.55045318603527
    },
    {
        lng: 7.767534732818547,
        lat: 47.5507698059082
    },
    {
        lng: 7.769862651824951,
        lat: 47.551315307617415
    },
    {
        lng: 7.771564006805363,
        lat: 47.55181503295921
    },
    {
        lng: 7.773307323455867,
        lat: 47.55231857299822
    },
    {
        lng: 7.774981021881047,
        lat: 47.552558898925895
    },
    {
        lng: 7.77749681472784,
        lat: 47.552822113037394
    },
    {
        lng: 7.7808189392089275,
        lat: 47.55298995971691
    },
    {
        lng: 7.782534599304199,
        lat: 47.55323410034174
    },
    {
        lng: 7.7841973304748535,
        lat: 47.55367279052746
    },
    {
        lng: 7.785520076751823,
        lat: 47.554164886474894
    },
    {
        lng: 7.786730289459285,
        lat: 47.55439376831055
    },
    {
        lng: 7.787818908691406,
        lat: 47.554534912109375
    },
    {
        lng: 7.788856506347713,
        lat: 47.55484771728527
    },
    {
        lng: 7.78980922698986,
        lat: 47.555355072021484
    },
    {
        lng: 7.7908220291137695,
        lat: 47.55592346191412
    },
    {
        lng: 7.792143344879207,
        lat: 47.55644226074219
    },
    {
        lng: 7.793723583221492,
        lat: 47.5568542480471
    },
    {
        lng: 7.79488563537592,
        lat: 47.55719757080078
    },
    {
        lng: 7.795547008514404,
        lat: 47.55744171142601
    },
    {
        lng: 7.796121120452824,
        lat: 47.5577392578125
    },
    {
        lng: 7.7964019775390625,
        lat: 47.55800247192377
    },
    {
        lng: 7.796574115753117,
        lat: 47.55845642089872
    },
    {
        lng: 7.797659873962516,
        lat: 47.5596923828125
    },
    {
        lng: 7.798200130462703,
        lat: 47.56061935424816
    },
    {
        lng: 7.798943042755241,
        lat: 47.56169128417997
    },
    {
        lng: 7.799863815307674,
        lat: 47.56259918212896
    },
    {
        lng: 7.801116943359489,
        lat: 47.563602447509766
    },
    {
        lng: 7.802702426910514,
        lat: 47.56469726562506
    },
    {
        lng: 7.804089546203727,
        lat: 47.56555938720703
    },
    {
        lng: 7.805690765380859,
        lat: 47.566371917724894
    },
    {
        lng: 7.807178020477409,
        lat: 47.5669517517091
    },
    {
        lng: 7.808619976043701,
        lat: 47.56758499145525
    },
    {
        lng: 7.809807777404785,
        lat: 47.5682449340822
    },
    {
        lng: 7.810575485229606,
        lat: 47.56886291503929
    },
    {
        lng: 7.811172008514404,
        lat: 47.569534301757926
    },
    {
        lng: 7.811679363250732,
        lat: 47.57045745849621
    },
    {
        lng: 7.812410831451416,
        lat: 47.571590423584155
    },
    {
        lng: 7.81274509429943,
        lat: 47.572425842285156
    },
    {
        lng: 7.8130173683165935,
        lat: 47.57363128662132
    },
    {
        lng: 7.813567161560059,
        lat: 47.57672882080084
    },
    {
        lng: 7.813870906829891,
        lat: 47.578136444092024
    },
    {
        lng: 7.81422948837286,
        lat: 47.579315185547046
    },
    {
        lng: 7.814641952514592,
        lat: 47.58026885986345
    },
    {
        lng: 7.816218852996826,
        lat: 47.5831031799317
    },
    {
        lng: 7.817198276519889,
        lat: 47.58449935913097
    },
    {
        lng: 7.818054676055908,
        lat: 47.58583450317394
    },
    {
        lng: 7.818646907806453,
        lat: 47.586589813232706
    },
    {
        lng: 7.8192481994629475,
        lat: 47.58717727661144
    },
    {
        lng: 7.820034980773983,
        lat: 47.58745193481468
    },
    {
        lng: 7.822076797485408,
        lat: 47.587928771972884
    },
    {
        lng: 7.823085308074951,
        lat: 47.588012695312784
    },
    {
        lng: 7.824352741241512,
        lat: 47.58798599243181
    },
    {
        lng: 7.8316669464112465,
        lat: 47.586936950683764
    },
    {
        lng: 7.832737445831356,
        lat: 47.58665084838884
    },
    {
        lng: 7.833658218383789,
        lat: 47.586246490478516
    },
    {
        lng: 7.834297180175895,
        lat: 47.585891723632926
    },
    {
        lng: 7.834874153137207,
        lat: 47.58536148071312
    },
    {
        lng: 7.835329055786076,
        lat: 47.58474349975603
    },
    {
        lng: 7.835827827453613,
        lat: 47.58409881591797
    },
    {
        lng: 7.836210250854492,
        lat: 47.58377456665039
    },
    {
        lng: 7.836446762084961,
        lat: 47.58357238769554
    },
    {
        lng: 7.837313652038574,
        lat: 47.58313751220703
    },
    {
        lng: 7.838134288787899,
        lat: 47.58278656005882
    },
    {
        lng: 7.839419841766471,
        lat: 47.58242034912132
    },
    {
        lng: 7.840825557708854,
        lat: 47.58216857910173
    },
    {
        lng: 7.841670036315918,
        lat: 47.58216094970703
    },
    {
        lng: 7.842805862426758,
        lat: 47.58221435546898
    },
    {
        lng: 7.844363689422664,
        lat: 47.582283020019645
    },
    {
        lng: 7.846509456634578,
        lat: 47.58239364624052
    },
    {
        lng: 7.8483123779296875,
        lat: 47.58260726928705
    },
    {
        lng: 7.849860668182316,
        lat: 47.58284378051775
    },
    {
        lng: 7.851732730865535,
        lat: 47.583347320556925
    },
    {
        lng: 7.853430271148795,
        lat: 47.58396148681646
    },
    {
        lng: 7.8552069664001465,
        lat: 47.58465957641613
    },
    {
        lng: 7.856563091278133,
        lat: 47.58535003662138
    },
    {
        lng: 7.858078002929744,
        lat: 47.58621215820335
    },
    {
        lng: 7.859262466430721,
        lat: 47.586956024170036
    },
    {
        lng: 7.8604211807251545,
        lat: 47.587383270263615
    },
    {
        lng: 7.861833095550594,
        lat: 47.58781814575195
    },
    {
        lng: 7.863383293151799,
        lat: 47.588027954101676
    },
    {
        lng: 7.8657026290893555,
        lat: 47.58820724487305
    },
    {
        lng: 7.869603157043457,
        lat: 47.58876800537121
    },
    {
        lng: 7.871376514434871,
        lat: 47.58875656127947
    },
    {
        lng: 7.873280048370361,
        lat: 47.588657379150504
    },
    {
        lng: 7.874979496002197,
        lat: 47.58844375610357
    },
    {
        lng: 7.876542091369629,
        lat: 47.58842468261713
    },
    {
        lng: 7.880869388580322,
        lat: 47.58878326416044
    },
    {
        lng: 7.883015632629451,
        lat: 47.58889389038097
    },
    {
        lng: 7.8851709365845295,
        lat: 47.588832855224666
    },
    {
        lng: 7.887508392333984,
        lat: 47.58851623535179
    },
    {
        lng: 7.889682769775504,
        lat: 47.58808517456066
    },
    {
        lng: 7.89135217666626,
        lat: 47.58763885498075
    },
    {
        lng: 7.892772674560661,
        lat: 47.58710098266624
    },
    {
        lng: 7.893119335174674,
        lat: 47.5869140625
    },
    {
        lng: 7.894107341766414,
        lat: 47.58637619018566
    },
    {
        lng: 7.895288467407227,
        lat: 47.58579254150408
    },
    {
        lng: 7.896597862243709,
        lat: 47.584964752197266
    },
    {
        lng: 7.898038864135742,
        lat: 47.5840301513673
    },
    {
        lng: 7.899187088012809,
        lat: 47.58305740356445
    },
    {
        lng: 7.900328636169434,
        lat: 47.58220291137724
    },
    {
        lng: 7.901720523834285,
        lat: 47.58140563964861
    },
    {
        lng: 7.903303623199463,
        lat: 47.5805282592774
    },
    {
        lng: 7.90383243560791,
        lat: 47.580131530761946
    },
    {
        lng: 7.904424667358512,
        lat: 47.579574584960994
    },
    {
        lng: 7.906427383422965,
        lat: 47.57708740234398
    },
    {
        lng: 7.9079627990723225,
        lat: 47.575180053711165
    },
    {
        lng: 7.909641265869141,
        lat: 47.57269668579096
    },
    {
        lng: 7.91004753112793,
        lat: 47.572116851806584
    },
    {
        lng: 7.9108576774598305,
        lat: 47.570960998535384
    },
    {
        lng: 7.911186218261662,
        lat: 47.570064544677905
    },
    {
        lng: 7.911453723907584,
        lat: 47.56897354125999
    },
    {
        lng: 7.911583423614559,
        lat: 47.56783294677729
    },
    {
        lng: 7.9113545417785645,
        lat: 47.566829681396484
    },
    {
        lng: 7.911049842834473,
        lat: 47.56591415405296
    },
    {
        lng: 7.91020059585577,
        lat: 47.56465530395536
    },
    {
        lng: 7.908275604248047,
        lat: 47.56241989135742
    },
    {
        lng: 7.907979488372746,
        lat: 47.56204605102556
    },
    {
        lng: 7.907813549041862,
        lat: 47.56183624267584
    },
    {
        lng: 7.907433986663875,
        lat: 47.56101989746094
    },
    {
        lng: 7.907330513000488,
        lat: 47.56030273437523
    },
    {
        lng: 7.907327651977596,
        lat: 47.56014633178728
    },
    {
        lng: 7.907312393188533,
        lat: 47.559299468994254
    },
    {
        lng: 7.9081010818481445,
        lat: 47.55631637573242
    },
    {
        lng: 7.908421993255615,
        lat: 47.55556488037121
    },
    {
        lng: 7.909201622009334,
        lat: 47.55410385131859
    },
    {
        lng: 7.909968852996883,
        lat: 47.55288314819336
    },
    {
        lng: 7.910500049591178,
        lat: 47.55213546752947
    },
    {
        lng: 7.911458492279053,
        lat: 47.551300048828125
    },
    {
        lng: 7.912557125091496,
        lat: 47.55046844482416
    },
    {
        lng: 7.913515567779541,
        lat: 47.54963302612305
    },
    {
        lng: 7.915183544158992,
        lat: 47.54867553710943
    },
    {
        lng: 7.916124343872127,
        lat: 47.548171997070426
    },
    {
        lng: 7.917130470275822,
        lat: 47.54776763916044
    },
    {
        lng: 7.918837070465202,
        lat: 47.54742813110374
    },
    {
        lng: 7.920400142669678,
        lat: 47.54713058471674
    },
    {
        lng: 7.922031402587891,
        lat: 47.5468826293947
    },
    {
        lng: 7.923160552978516,
        lat: 47.546813964844034
    },
    {
        lng: 7.924784660339469,
        lat: 47.54671096801769
    },
    {
        lng: 7.925549030303955,
        lat: 47.54676818847662
    },
    {
        lng: 7.92660665512085,
        lat: 47.54684829711937
    },
    {
        lng: 7.9289159774779705,
        lat: 47.547092437744254
    },
    {
        lng: 7.930747985839901,
        lat: 47.54704284667963
    },
    {
        lng: 7.932509899139404,
        lat: 47.54698944091808
    },
    {
        lng: 7.933997631072998,
        lat: 47.54678726196306
    },
    {
        lng: 7.935716152191276,
        lat: 47.546207427978516
    },
    {
        lng: 7.936755657196045,
        lat: 47.54586029052763
    },
    {
        lng: 7.937720775604305,
        lat: 47.54553985595703
    },
    {
        lng: 7.93994331359869,
        lat: 47.54473495483421
    },
    {
        lng: 7.942049980163631,
        lat: 47.544124603271655
    },
    {
        lng: 7.943210601806641,
        lat: 47.54381942749029
    },
    {
        lng: 7.944266319274959,
        lat: 47.54378509521496
    },
    {
        lng: 7.945529460906982,
        lat: 47.54381561279297
    },
    {
        lng: 7.946734428405705,
        lat: 47.54415893554699
    },
    {
        lng: 7.947640419006291,
        lat: 47.544578552246094
    },
    {
        lng: 7.948289394378776,
        lat: 47.54507827758806
    },
    {
        lng: 7.949238300323543,
        lat: 47.546100616455135
    },
    {
        lng: 7.949997901916504,
        lat: 47.54711914062523
    },
    {
        lng: 7.950463771820068,
        lat: 47.547901153564396
    },
    {
        lng: 7.951177597046012,
        lat: 47.549602508545036
    },
    {
        lng: 7.951529979705754,
        lat: 47.550949096679915
    },
    {
        lng: 7.951772212982235,
        lat: 47.5519828796389
    },
    {
        lng: 7.952078342437687,
        lat: 47.55341720581066
    },
    {
        lng: 7.952488899231071,
        lat: 47.554454803467024
    },
    {
        lng: 7.95320463180542,
        lat: 47.55530166626005
    },
    {
        lng: 7.95413255691534,
        lat: 47.55612182617193
    },
    {
        lng: 7.9551100730896565,
        lat: 47.55680084228533
    },
    {
        lng: 7.956010341644287,
        lat: 47.55733489990229
    },
    {
        lng: 7.956719398498592,
        lat: 47.5576934814456
    },
    {
        lng: 7.957873821258659,
        lat: 47.55800628662132
    },
    {
        lng: 7.959083557128906,
        lat: 47.55826568603544
    },
    {
        lng: 7.960473537445125,
        lat: 47.558296203613224
    },
    {
        lng: 7.9615349769592285,
        lat: 47.55815124511719
    },
    {
        lng: 7.962599754333553,
        lat: 47.557945251465014
    },
    {
        lng: 7.964793682098389,
        lat: 47.55729293823242
    },
    {
        lng: 7.965352535247916,
        lat: 47.557128906250114
    },
    {
        lng: 7.96713829040533,
        lat: 47.556598663330135
    },
    {
        lng: 7.968975543975944,
        lat: 47.55612564086914
    },
    {
        lng: 7.970635414123592,
        lat: 47.55582046508812
    },
    {
        lng: 7.972628116607666,
        lat: 47.555610656738395
    },
    {
        lng: 7.973370075225944,
        lat: 47.55554962158209
    },
    {
        lng: 7.973434925079459,
        lat: 47.555545806884766
    },
    {
        lng: 7.9759306907654945,
        lat: 47.55534744262695
    },
    {
        lng: 7.978356361389103,
        lat: 47.55530548095703
    },
    {
        lng: 7.981294155120963,
        lat: 47.555255889892635
    },
    {
        lng: 7.98274040222168,
        lat: 47.55529022216797
    },
    {
        lng: 7.98444843292242,
        lat: 47.555343627929915
    },
    {
        lng: 7.986420631408691,
        lat: 47.555530548095646
    },
    {
        lng: 7.9889726638793945,
        lat: 47.55593490600586
    },
    {
        lng: 7.991528987884465,
        lat: 47.556251525878906
    },
    {
        lng: 7.9943842887879555,
        lat: 47.5564880371096
    },
    {
        lng: 7.9961519241334145,
        lat: 47.55655670166027
    },
    {
        lng: 7.997666835784969,
        lat: 47.556621551513786
    },
    {
        lng: 7.999186038971004,
        lat: 47.556598663330135
    },
    {
        lng: 7.999837875366154,
        lat: 47.556522369384936
    },
    {
        lng: 8.00147628784191,
        lat: 47.556335449218864
    },
    {
        lng: 8.003732681274528,
        lat: 47.55590438842768
    },
    {
        lng: 8.006805419921875,
        lat: 47.55517578125006
    },
    {
        lng: 8.007254600525016,
        lat: 47.554996490478686
    },
    {
        lng: 8.007966995239258,
        lat: 47.55471420288097
    },
    {
        lng: 8.008662223815975,
        lat: 47.55430221557623
    },
    {
        lng: 8.010534286499023,
        lat: 47.553119659424055
    },
    {
        lng: 8.011748313903809,
        lat: 47.552463531494254
    },
    {
        lng: 8.01308536529541,
        lat: 47.55186462402344
    },
    {
        lng: 8.014542579650993,
        lat: 47.55138397216808
    },
    {
        lng: 8.01650428771984,
        lat: 47.550945281982365
    },
    {
        lng: 8.018291473388615,
        lat: 47.550613403320426
    },
    {
        lng: 8.019812583923397,
        lat: 47.55053329467802
    },
    {
        lng: 8.021771430969238,
        lat: 47.55051422119158
    },
    {
        lng: 8.022511482238713,
        lat: 47.550537109375
    },
    {
        lng: 8.02338886260992,
        lat: 47.550701141357536
    },
    {
        lng: 8.024296760559082,
        lat: 47.55109405517578
    },
    {
        lng: 8.025493621826286,
        lat: 47.55160522460949
    },
    {
        lng: 8.026493072509709,
        lat: 47.551856994628906
    },
    {
        lng: 8.02993011474615,
        lat: 47.55227661132835
    },
    {
        lng: 8.03395843505865,
        lat: 47.552654266357536
    },
    {
        lng: 8.03690624237072,
        lat: 47.55299758911144
    },
    {
        lng: 8.040485382080078,
        lat: 47.55368804931669
    },
    {
        lng: 8.041773796081543,
        lat: 47.55405807495117
    },
    {
        lng: 8.043722152709961,
        lat: 47.554546356201456
    },
    {
        lng: 8.045557022094727,
        lat: 47.5551452636721
    },
    {
        lng: 8.046634674072322,
        lat: 47.555538177490234
    },
    {
        lng: 8.04835224151617,
        lat: 47.5565299987793
    },
    {
        lng: 8.050239562988224,
        lat: 47.557674407958984
    },
    {
        lng: 8.0511856079101,
        lat: 47.55815505981457
    },
    {
        lng: 8.052223205566463,
        lat: 47.558490753174055
    },
    {
        lng: 8.053046226501579,
        lat: 47.55891036987316
    },
    {
        lng: 8.05316162109375,
        lat: 47.55900192260759
    },
    {
        lng: 8.053898811340389,
        lat: 47.559585571289176
    },
    {
        lng: 8.054800033569336,
        lat: 47.56011962890631
    },
    {
        lng: 8.055742263794002,
        lat: 47.56068420410179
    },
    {
        lng: 8.056485176086426,
        lat: 47.561012268066406
    },
    {
        lng: 8.057392120361271,
        lat: 47.56143188476585
    },
    {
        lng: 8.05799865722662,
        lat: 47.56196212768566
    },
    {
        lng: 8.058309555053768,
        lat: 47.562511444091854
    },
    {
        lng: 8.05854415893549,
        lat: 47.562885284423885
    },
    {
        lng: 8.058946609497127,
        lat: 47.56326675415045
    },
    {
        lng: 8.05927658081066,
        lat: 47.56341934204124
    },
    {
        lng: 8.059734344482422,
        lat: 47.56354141235346
    },
    {
        lng: 8.060155868530273,
        lat: 47.56354904174805
    },
    {
        lng: 8.060585975646973,
        lat: 47.563522338867415
    },
    {
        lng: 8.061262130737248,
        lat: 47.563346862793196
    },
    {
        lng: 8.062198638916016,
        lat: 47.563167572021484
    },
    {
        lng: 8.063038825988826,
        lat: 47.563243865967024
    },
    {
        lng: 8.06432819366455,
        lat: 47.563617706299055
    },
    {
        lng: 8.066150665283317,
        lat: 47.56428527832054
    },
    {
        lng: 8.067490577697754,
        lat: 47.564487457275334
    },
    {
        lng: 8.068331718444881,
        lat: 47.564575195312614
    },
    {
        lng: 8.069175720214844,
        lat: 47.56452560424822
    },
    {
        lng: 8.07036495208746,
        lat: 47.56435012817383
    },
    {
        lng: 8.071393966674805,
        lat: 47.56400299072277
    },
    {
        lng: 8.071996688842773,
        lat: 47.563697814941406
    },
    {
        lng: 8.072258949279785,
        lat: 47.56356430053711
    },
    {
        lng: 8.074406623840332,
        lat: 47.561744689941406
    },
    {
        lng: 8.076067924499455,
        lat: 47.56068038940424
    },
    {
        lng: 8.078019142150936,
        lat: 47.5595817565918
    },
    {
        lng: 8.079705238342285,
        lat: 47.55873489379911
    },
    {
        lng: 8.08133602142334,
        lat: 47.55814361572283
    },
    {
        lng: 8.082620620727596,
        lat: 47.55774688720703
    },
    {
        lng: 8.084236145019531,
        lat: 47.55746841430681
    },
    {
        lng: 8.08600997924799,
        lat: 47.55742263793957
    },
    {
        lng: 8.087693214416504,
        lat: 47.55748748779308
    },
    {
        lng: 8.089661598205623,
        lat: 47.55775833129894
    },
    {
        lng: 8.09128379821783,
        lat: 47.558223724365234
    },
    {
        lng: 8.093187332153434,
        lat: 47.55897903442383
    },
    {
        lng: 8.095582008361873,
        lat: 47.5600318908692
    },
    {
        lng: 8.097179412841854,
        lat: 47.561008453369254
    },
    {
        lng: 8.09836387634283,
        lat: 47.561805725097884
    },
    {
        lng: 8.09933185577404,
        lat: 47.562709808349666
    },
    {
        lng: 8.10020828247076,
        lat: 47.563785552978516
    },
    {
        lng: 8.100869178771973,
        lat: 47.564941406250284
    },
    {
        lng: 8.100908279419002,
        lat: 47.565040588379134
    },
    {
        lng: 8.101470947265682,
        lat: 47.56647109985374
    },
    {
        lng: 8.101915359497127,
        lat: 47.567733764648665
    },
    {
        lng: 8.102311134338493,
        lat: 47.57002639770502
    },
    {
        lng: 8.10241985321045,
        lat: 47.57214355468756
    },
    {
        lng: 8.102458000183162,
        lat: 47.57389068603527
    },
    {
        lng: 8.102692604065055,
        lat: 47.57522964477539
    },
    {
        lng: 8.10313606262207,
        lat: 47.576362609863395
    },
    {
        lng: 8.104209899902287,
        lat: 47.577888488769645
    },
    {
        lng: 8.105172157287711,
        lat: 47.57893753051769
    },
    {
        lng: 8.106670379638672,
        lat: 47.58022689819359
    },
    {
        lng: 8.108303070068473,
        lat: 47.581378936767805
    },
    {
        lng: 8.10948371887207,
        lat: 47.582260131835994
    },
    {
        lng: 8.110553741455192,
        lat: 47.58282470703131
    },
    {
        lng: 8.111713409423942,
        lat: 47.58327865600609
    },
    {
        lng: 8.113426208496094,
        lat: 47.5836296081543
    },
    {
        lng: 8.115608215332145,
        lat: 47.58387756347656
    },
    {
        lng: 8.117545127868596,
        lat: 47.58395004272455
    },
    {
        lng: 8.119321823120174,
        lat: 47.58384704589838
    },
    {
        lng: 8.123476982116756,
        lat: 47.58342361450195
    },
    {
        lng: 8.124957084655762,
        lat: 47.583339691162394
    },
    {
        lng: 8.126042366027946,
        lat: 47.58328628540062
    },
    {
        lng: 8.128206253051815,
        lat: 47.583526611328125
    },
    {
        lng: 8.128341674804744,
        lat: 47.583541870117415
    },
    {
        lng: 8.130027770996094,
        lat: 47.58356857299799
    },
    {
        lng: 8.132123947143612,
        lat: 47.583438873291016
    },
    {
        lng: 8.133769035339412,
        lat: 47.583446502685774
    },
    {
        lng: 8.135240554809627,
        lat: 47.58356475830101
    },
    {
        lng: 8.136103630066032,
        lat: 47.5838623046875
    },
    {
        lng: 8.136486053466854,
        lat: 47.583992004394645
    },
    {
        lng: 8.137014389038086,
        lat: 47.58440399169933
    },
    {
        lng: 8.13769435882574,
        lat: 47.58518981933588
    },
    {
        lng: 8.138045310974178,
        lat: 47.585796356201286
    },
    {
        lng: 8.138092041015682,
        lat: 47.58659744262707
    },
    {
        lng: 8.137533187866325,
        lat: 47.58858108520508
    },
    {
        lng: 8.137737274169922,
        lat: 47.58961486816423
    },
    {
        lng: 8.138293266296387,
        lat: 47.590339660644474
    },
    {
        lng: 8.139233589172306,
        lat: 47.590988159179915
    },
    {
        lng: 8.140387535095329,
        lat: 47.59155654907238
    },
    {
        lng: 8.14229965209961,
        lat: 47.592166900634766
    },
    {
        lng: 8.143331527709961,
        lat: 47.59264755249029
    },
    {
        lng: 8.1464462280274,
        lat: 47.59459686279297
    },
    {
        lng: 8.147727966308594,
        lat: 47.59516906738281
    },
    {
        lng: 8.148602485656738,
        lat: 47.59541702270508
    },
    {
        lng: 8.149816513061523,
        lat: 47.59561157226568
    },
    {
        lng: 8.151413917541504,
        lat: 47.595733642578125
    },
    {
        lng: 8.152467727661246,
        lat: 47.595756530761946
    },
    {
        lng: 8.153402328491211,
        lat: 47.59563446044933
    },
    {
        lng: 8.154507637023869,
        lat: 47.595458984375284
    },
    {
        lng: 8.156146049499625,
        lat: 47.59497451782255
    },
    {
        lng: 8.15981388092041,
        lat: 47.59388732910162
    },
    {
        lng: 8.161301612854004,
        lat: 47.593662261963004
    },
    {
        lng: 8.16239929199213,
        lat: 47.59365844726585
    },
    {
        lng: 8.163824081421012,
        lat: 47.593860626220646
    },
    {
        lng: 8.164908409118652,
        lat: 47.59413909912121
    },
    {
        lng: 8.165899276733455,
        lat: 47.59458923339844
    },
    {
        lng: 8.16676235198969,
        lat: 47.595092773437614
    },
    {
        lng: 8.168683052062988,
        lat: 47.59642028808605
    },
    {
        lng: 8.170625686645621,
        lat: 47.59794998168968
    },
    {
        lng: 8.170966148376465,
        lat: 47.598327636718864
    },
    {
        lng: 8.172394752502441,
        lat: 47.6001815795899
    },
    {
        lng: 8.17336273193365,
        lat: 47.60102844238281
    },
    {
        lng: 8.174134254455623,
        lat: 47.60167312622082
    },
    {
        lng: 8.17508411407482,
        lat: 47.60212326049805
    },
    {
        lng: 8.176244735717887,
        lat: 47.60257339477545
    },
    {
        lng: 8.177620887756461,
        lat: 47.602916717529524
    },
    {
        lng: 8.179878234863338,
        lat: 47.60339355468773
    },
    {
        lng: 8.181844711303711,
        lat: 47.60375213623047
    },
    {
        lng: 8.183258056640682,
        lat: 47.60420989990246
    },
    {
        lng: 8.184496879577694,
        lat: 47.604804992676065
    },
    {
        lng: 8.185478210449162,
        lat: 47.60548400878929
    },
    {
        lng: 8.186368942260685,
        lat: 47.60630035400402
    },
    {
        lng: 8.187886238098088,
        lat: 47.608470916748104
    },
    {
        lng: 8.190524101257324,
        lat: 47.612243652344034
    },
    {
        lng: 8.191040992736816,
        lat: 47.61288070678722
    },
    {
        lng: 8.193318367004451,
        lat: 47.61569595336937
    },
    {
        lng: 8.194330215454102,
        lat: 47.61663436889671
    },
    {
        lng: 8.19571685791027,
        lat: 47.61769104003929
    },
    {
        lng: 8.196822166442928,
        lat: 47.61842727661144
    },
    {
        lng: 8.198017120361328,
        lat: 47.619079589843864
    },
    {
        lng: 8.199215888977108,
        lat: 47.619617462158146
    },
    {
        lng: 8.200798034667969,
        lat: 47.62010955810547
    },
    {
        lng: 8.202631950378475,
        lat: 47.620605468750114
    },
    {
        lng: 8.204054832458553,
        lat: 47.620891571045036
    },
    {
        lng: 8.206076622009277,
        lat: 47.620990753174
    },
    {
        lng: 8.207891464233512,
        lat: 47.62100219726574
    },
    {
        lng: 8.209500312805176,
        lat: 47.620891571045036
    },
    {
        lng: 8.211285591125602,
        lat: 47.62064361572277
    },
    {
        lng: 8.213074684143123,
        lat: 47.62028121948265
    },
    {
        lng: 8.21529865264904,
        lat: 47.61958312988281
    },
    {
        lng: 8.216804504394531,
        lat: 47.61904907226585
    },
    {
        lng: 8.218184471130428,
        lat: 47.61841964721691
    },
    {
        lng: 8.219267845153809,
        lat: 47.617816925048885
    },
    {
        lng: 8.220318794250488,
        lat: 47.61698150634777
    },
    {
        lng: 8.220947265624943,
        lat: 47.61616516113304
    },
    {
        lng: 8.222535133361816,
        lat: 47.61283111572277
    },
    {
        lng: 8.222918510436955,
        lat: 47.61183929443371
    },
    {
        lng: 8.222967147827262,
        lat: 47.61155700683594
    },
    {
        lng: 8.22331905364996,
        lat: 47.60953521728521
    },
    {
        lng: 8.223539352417106,
        lat: 47.60842895507841
    },
    {
        lng: 8.224018096923885,
        lat: 47.60718154907255
    },
    {
        lng: 8.224260330200195,
        lat: 47.606502532958984
    },
    {
        lng: 8.224971771240291,
        lat: 47.605716705322266
    },
    {
        lng: 8.225708007812443,
        lat: 47.60530471801752
    },
    {
        lng: 8.226543426513786,
        lat: 47.605094909668196
    },
    {
        lng: 8.226647377014274,
        lat: 47.60506820678722
    },
    {
        lng: 8.227575302123967,
        lat: 47.60508728027344
    },
    {
        lng: 8.228583335876465,
        lat: 47.60519409179699
    },
    {
        lng: 8.229330062866211,
        lat: 47.60546493530302
    },
    {
        lng: 8.229865074157829,
        lat: 47.60576248168968
    },
    {
        lng: 8.231576919555721,
        lat: 47.607231140136776
    },
    {
        lng: 8.232858657836914,
        lat: 47.60832977294922
    },
    {
        lng: 8.234026908874512,
        lat: 47.60924148559576
    },
    {
        lng: 8.236118316650334,
        lat: 47.611175537109375
    },
    {
        lng: 8.236849784851017,
        lat: 47.611789703369084
    },
    {
        lng: 8.237627029418945,
        lat: 47.612346649170036
    },
    {
        lng: 8.238540649414062,
        lat: 47.612682342529354
    },
    {
        lng: 8.2393417358399,
        lat: 47.61269760131847
    },
    {
        lng: 8.240230560302791,
        lat: 47.612628936767805
    },
    {
        lng: 8.241250038146973,
        lat: 47.61250686645519
    },
    {
        lng: 8.24217891693121,
        lat: 47.61249923706066
    },
    {
        lng: 8.244287490844727,
        lat: 47.612541198730696
    },
    {
        lng: 8.24584102630621,
        lat: 47.61271667480469
    },
    {
        lng: 8.24784851074213,
        lat: 47.613132476806584
    },
    {
        lng: 8.248795509338493,
        lat: 47.61336898803711
    },
    {
        lng: 8.24983215332037,
        lat: 47.61367797851585
    },
    {
        lng: 8.252035140991211,
        lat: 47.61429977416992
    },
    {
        lng: 8.252312660217285,
        lat: 47.6143798828125
    },
    {
        lng: 8.253087997436637,
        lat: 47.614582061767635
    },
    {
        lng: 8.253680229187012,
        lat: 47.61471176147461
    },
    {
        lng: 8.254101753234863,
        lat: 47.6148033142091
    },
    {
        lng: 8.256240844726506,
        lat: 47.61515808105469
    },
    {
        lng: 8.25809478759777,
        lat: 47.61525344848633
    },
    {
        lng: 8.258979797363338,
        lat: 47.61527252197277
    },
    {
        lng: 8.259749412536678,
        lat: 47.61505889892584
    },
    {
        lng: 8.260181427001953,
        lat: 47.614841461181584
    },
    {
        lng: 8.260662078857536,
        lat: 47.61447906494146
    },
    {
        lng: 8.263081550598145,
        lat: 47.61042022705078
    },
    {
        lng: 8.263785362243766,
        lat: 47.60980606079107
    },
    {
        lng: 8.264385223388672,
        lat: 47.60958862304699
    },
    {
        lng: 8.26502323150629,
        lat: 47.609489440918196
    },
    {
        lng: 8.265910148620605,
        lat: 47.609477996826456
    },
    {
        lng: 8.267718315124625,
        lat: 47.609630584716854
    },
    {
        lng: 8.270612716674862,
        lat: 47.61003112792969
    },
    {
        lng: 8.272203445434627,
        lat: 47.61032104492199
    },
    {
        lng: 8.273914337158203,
        lat: 47.610755920410156
    },
    {
        lng: 8.276585578918457,
        lat: 47.6114387512207
    },
    {
        lng: 8.27784442901617,
        lat: 47.611606597900334
    },
    {
        lng: 8.279023170471191,
        lat: 47.61166000366211
    },
    {
        lng: 8.280119895935059,
        lat: 47.61168289184593
    },
    {
        lng: 8.281135559082088,
        lat: 47.611618041992244
    },
    {
        lng: 8.284912109375,
        lat: 47.611179351806754
    },
    {
        lng: 8.286145210266113,
        lat: 47.61097717285173
    },
    {
        lng: 8.287721633911246,
        lat: 47.610637664795036
    },
    {
        lng: 8.288964271545467,
        lat: 47.61023712158203
    },
    {
        lng: 8.289423942565975,
        lat: 47.61004638671875
    },
    {
        lng: 8.294683456421012,
        lat: 47.60784149169933
    },
    {
        lng: 8.295499801635742,
        lat: 47.60736083984375
    },
    {
        lng: 8.295810699462834,
        lat: 47.607177734375
    },
    {
        lng: 8.296547889709473,
        lat: 47.60673522949213
    },
    {
        lng: 8.297137260437012,
        lat: 47.60611724853527
    },
    {
        lng: 8.297480583191032,
        lat: 47.60567092895519
    },
    {
        lng: 8.297586441040096,
        lat: 47.60518646240263
    },
    {
        lng: 8.297426223754996,
        lat: 47.6040992736817
    },
    {
        lng: 8.296304702758903,
        lat: 47.598880767822266
    },
    {
        lng: 8.295814514160213,
        lat: 47.59756088256859
    },
    {
        lng: 8.295378684997559,
        lat: 47.59598159790039
    },
    {
        lng: 8.29530239105219,
        lat: 47.595218658447266
    },
    {
        lng: 8.29526138305664,
        lat: 47.59480667114269
    },
    {
        lng: 8.295308113098145,
        lat: 47.59375381469749
    },
    {
        lng: 8.2956161499024,
        lat: 47.59253311157221
    },
    {
        lng: 8.296299934387264,
        lat: 47.59137725830101
    },
    {
        lng: 8.296979904174918,
        lat: 47.59030532836914
    },
    {
        lng: 8.297921180725154,
        lat: 47.58906936645536
    },
    {
        lng: 8.299321174621582,
        lat: 47.587924957275675
    },
    {
        lng: 8.300630569458065,
        lat: 47.586952209472656
    },
    {
        lng: 8.302211761474666,
        lat: 47.586048126220646
    },
    {
        lng: 8.30351638793951,
        lat: 47.585613250732536
    },
    {
        lng: 8.304583549499512,
        lat: 47.58531951904308
    },
    {
        lng: 8.307099342346248,
        lat: 47.58471679687523
    },
    {
        lng: 8.308593750000057,
        lat: 47.58431625366222
    },
    {
        lng: 8.309711456298942,
        lat: 47.58382797241234
    },
    {
        lng: 8.310833930969295,
        lat: 47.583248138427734
    },
    {
        lng: 8.31275558471691,
        lat: 47.58195495605469
    },
    {
        lng: 8.313836097717399,
        lat: 47.58116912841808
    },
    {
        lng: 8.3143892288208,
        lat: 47.58069992065424
    },
    {
        lng: 8.314756393432617,
        lat: 47.580387115478516
    },
    {
        lng: 8.3156356811524,
        lat: 47.5795783996582
    },
    {
        lng: 8.317928314208928,
        lat: 47.57731246948259
    },
    {
        lng: 8.319113731384391,
        lat: 47.576251983642635
    },
    {
        lng: 8.320385932922477,
        lat: 47.57513427734375
    },
    {
        lng: 8.322126388549918,
        lat: 47.573913574218864
    },
    {
        lng: 8.32377147674572,
        lat: 47.57294845581055
    },
    {
        lng: 8.325917243957633,
        lat: 47.57210540771507
    },
    {
        lng: 8.328402519226017,
        lat: 47.57121658325195
    },
    {
        lng: 8.330827713012809,
        lat: 47.57072067260748
    },
    {
        lng: 8.333411216735954,
        lat: 47.57045745849621
    },
    {
        lng: 8.336094856262207,
        lat: 47.57041549682617
    },
    {
        lng: 8.33697414398199,
        lat: 47.5704040527346
    },
    {
        lng: 8.341172218322754,
        lat: 47.570442199707145
    },
    {
        lng: 8.343821525573844,
        lat: 47.570610046386776
    },
    {
        lng: 8.345587730407772,
        lat: 47.57072830200207
    },
    {
        lng: 8.347780227661246,
        lat: 47.57074356079113
    },
    {
        lng: 8.350021362304801,
        lat: 47.57061767578131
    },
    {
        lng: 8.354192733764762,
        lat: 47.57031249999994
    },
    {
        lng: 8.356156349182243,
        lat: 47.570140838623104
    },
    {
        lng: 8.357675552368221,
        lat: 47.57011413574213
    },
    {
        lng: 8.358149528503361,
        lat: 47.57012176513666
    },
    {
        lng: 8.358603477478141,
        lat: 47.57012939453125
    },
    {
        lng: 8.35962009429943,
        lat: 47.57000732421898
    },
    {
        lng: 8.360641479492244,
        lat: 47.569801330566406
    },
    {
        lng: 8.362689018249625,
        lat: 47.569271087646484
    },
    {
        lng: 8.363968849182243,
        lat: 47.568923950195426
    },
    {
        lng: 8.365241050720272,
        lat: 47.56874847412138
    },
    {
        lng: 8.3671493530274,
        lat: 47.56859588623041
    },
    {
        lng: 8.369218826293945,
        lat: 47.56842803955078
    },
    {
        lng: 8.371418952941895,
        lat: 47.568271636963175
    },
    {
        lng: 8.372430801391545,
        lat: 47.56811904907221
    },
    {
        lng: 8.374598503112793,
        lat: 47.56787490844738
    },
    {
        lng: 8.376043319702205,
        lat: 47.56761932373058
    },
    {
        lng: 8.377703666687069,
        lat: 47.56725311279291
    },
    {
        lng: 8.378900527954215,
        lat: 47.5668754577639
    },
    {
        lng: 8.380012512206974,
        lat: 47.56649780273432
    },
    {
        lng: 8.381043434143066,
        lat: 47.56606292724621
    },
    {
        lng: 8.3819456100465,
        lat: 47.565681457519645
    },
    {
        lng: 8.383003234863338,
        lat: 47.565586090088004
    },
    {
        lng: 8.383717536926383,
        lat: 47.565658569335994
    },
    {
        lng: 8.384757041931096,
        lat: 47.56599044799833
    },
    {
        lng: 8.385662078857422,
        lat: 47.56652069091797
    },
    {
        lng: 8.38618183135992,
        lat: 47.56718826293945
    },
    {
        lng: 8.386657714843864,
        lat: 47.567913055420036
    },
    {
        lng: 8.387306213378906,
        lat: 47.56952285766624
    },
    {
        lng: 8.387645721435604,
        lat: 47.57047271728544
    },
    {
        lng: 8.38795471191412,
        lat: 47.571132659912394
    },
    {
        lng: 8.388346672058105,
        lat: 47.57182693481468
    },
    {
        lng: 8.389312744140739,
        lat: 47.57292938232433
    },
    {
        lng: 8.391807556152457,
        lat: 47.574748992920036
    },
    {
        lng: 8.393418312072754,
        lat: 47.575721740722884
    },
    {
        lng: 8.394686698913631,
        lat: 47.57648849487316
    },
    {
        lng: 8.395972251892204,
        lat: 47.576995849609375
    },
    {
        lng: 8.397356033325252,
        lat: 47.57719421386719
    },
    {
        lng: 8.398661613464355,
        lat: 47.577247619629134
    },
    {
        lng: 8.40006542205822,
        lat: 47.57696151733421
    },
    {
        lng: 8.404463768005371,
        lat: 47.57567596435547
    },
    {
        lng: 8.405362129211426,
        lat: 47.575382232666016
    },
    {
        lng: 8.406517982483024,
        lat: 47.57497406005882
    },
    {
        lng: 8.408452033996639,
        lat: 47.57410049438499
    },
    {
        lng: 8.4094877243042,
        lat: 47.57354736328119
    },
    {
        lng: 8.411126136779728,
        lat: 47.57269287109398
    },
    {
        lng: 8.412675857543888,
        lat: 47.571952819824276
    },
    {
        lng: 8.413723945617676,
        lat: 47.57152175903349
    },
    {
        lng: 8.414134979248047,
        lat: 47.57135391235346
    },
    {
        lng: 8.415502548217773,
        lat: 47.57092285156267
    },
    {
        lng: 8.418870925903377,
        lat: 47.569873809814624
    },
    {
        lng: 8.419951438903865,
        lat: 47.56959152221691
    },
    {
        lng: 8.421308517456168,
        lat: 47.56923675537132
    },
    {
        lng: 8.423187255859375,
        lat: 47.56867218017601
    },
    {
        lng: 8.424642562866211,
        lat: 47.5681610107423
    },
    {
        lng: 8.426097869873047,
        lat: 47.567646026611555
    },
    {
        lng: 8.42742061614996,
        lat: 47.567272186279354
    },
    {
        lng: 8.428743362426758,
        lat: 47.56689453125
    },
    {
        lng: 8.430144309997672,
        lat: 47.566665649414176
    },
    {
        lng: 8.431202888488883,
        lat: 47.566570281982536
    },
    {
        lng: 8.432297706603947,
        lat: 47.56659317016596
    },
    {
        lng: 8.433687210083008,
        lat: 47.566646575927734
    },
    {
        lng: 8.436079978942814,
        lat: 47.56692123413097
    },
    {
        lng: 8.437924385070744,
        lat: 47.567184448242244
    },
    {
        lng: 8.439051628112793,
        lat: 47.567462921142635
    },
    {
        lng: 8.439925193786621,
        lat: 47.567737579345646
    },
    {
        lng: 8.448956489563102,
        lat: 47.57064819335949
    },
    {
        lng: 8.45033264160162,
        lat: 47.57101440429699
    },
    {
        lng: 8.451628684997615,
        lat: 47.5712966918947
    },
    {
        lng: 8.452716827392635,
        lat: 47.57148742675798
    },
    {
        lng: 8.453759193420467,
        lat: 47.571792602539176
    },
    {
        lng: 8.454549789428654,
        lat: 47.57203674316406
    },
    {
        lng: 8.455548286437988,
        lat: 47.57236862182617
    },
    {
        lng: 8.456302642822322,
        lat: 47.572498321533146
    },
    {
        lng: 8.45685005187994,
        lat: 47.57250976562523
    },
    {
        lng: 8.457337379455566,
        lat: 47.57243728637701
    },
    {
        lng: 8.45905685424816,
        lat: 47.57217788696289
    },
    {
        lng: 8.460834503173828,
        lat: 47.57201385498058
    },
    {
        lng: 8.462269783020076,
        lat: 47.57198333740246
    },
    {
        lng: 8.463948249816951,
        lat: 47.57218551635742
    },
    {
        lng: 8.46557903289795,
        lat: 47.57252883911133
    },
    {
        lng: 8.467198371887264,
        lat: 47.57315826416027
    },
    {
        lng: 8.467953681945858,
        lat: 47.57346725463867
    },
    {
        lng: 8.469687461853027,
        lat: 47.57417678833019
    },
    {
        lng: 8.472026824951286,
        lat: 47.57539749145525
    },
    {
        lng: 8.475590705871639,
        lat: 47.57731246948259
    },
    {
        lng: 8.476876258850154,
        lat: 47.5778503417971
    },
    {
        lng: 8.477755546569767,
        lat: 47.57800674438505
    },
    {
        lng: 8.478894233703727,
        lat: 47.57799911499052
    },
    {
        lng: 8.480169296264648,
        lat: 47.57776641845703
    },
    {
        lng: 8.482634544372559,
        lat: 47.57730102539068
    },
    {
        lng: 8.484070777893123,
        lat: 47.577239990234375
    },
    {
        lng: 8.485294342041072,
        lat: 47.577236175537394
    },
    {
        lng: 8.486549377441463,
        lat: 47.57748794555664
    },
    {
        lng: 8.48783016204834,
        lat: 47.578136444092024
    },
    {
        lng: 8.489098548889274,
        lat: 47.57910537719732
    },
    {
        lng: 8.490336418151799,
        lat: 47.57978439331072
    },
    {
        lng: 8.491576194763127,
        lat: 47.58043289184576
    },
    {
        lng: 8.49250316619873,
        lat: 47.58073043823242
    },
    {
        lng: 8.492616653442383,
        lat: 47.58076858520508
    },
    {
        lng: 8.494546890258789,
        lat: 47.581199645996094
    },
    {
        lng: 8.494337081909237,
        lat: 47.58179855346691
    },
    {
        lng: 8.494200706482047,
        lat: 47.582279205322266
    },
    {
        lng: 8.492215156555233,
        lat: 47.58428192138672
    },
    {
        lng: 8.489185333252067,
        lat: 47.58734130859375
    },
    {
        lng: 8.488717079162711,
        lat: 47.58780288696306
    },
    {
        lng: 8.487502098083553,
        lat: 47.58743667602539
    },
    {
        lng: 8.483389854431152,
        lat: 47.58700180053705
    },
    {
        lng: 8.481976509094352,
        lat: 47.58630752563499
    },
    {
        lng: 8.481344223022518,
        lat: 47.585407257080135
    },
    {
        lng: 8.48117637634283,
        lat: 47.585247039795206
    },
    {
        lng: 8.480919837951774,
        lat: 47.58511734008789
    },
    {
        lng: 8.480507850647086,
        lat: 47.585056304931925
    },
    {
        lng: 8.480108261108342,
        lat: 47.58507919311535
    },
    {
        lng: 8.479755401611385,
        lat: 47.58525085449219
    },
    {
        lng: 8.479441642761344,
        lat: 47.58545303344738
    },
    {
        lng: 8.477525711059513,
        lat: 47.58510589599615
    },
    {
        lng: 8.474591255187988,
        lat: 47.58464813232439
    },
    {
        lng: 8.473296165466309,
        lat: 47.584529876708984
    },
    {
        lng: 8.470932006835994,
        lat: 47.58474731445324
    },
    {
        lng: 8.47029972076416,
        lat: 47.58473587036133
    },
    {
        lng: 8.469850540161246,
        lat: 47.584537506103516
    },
    {
        lng: 8.469373703003043,
        lat: 47.584171295166186
    },
    {
        lng: 8.46864318847662,
        lat: 47.58396530151367
    },
    {
        lng: 8.46779823303217,
        lat: 47.583999633789176
    },
    {
        lng: 8.466938018798828,
        lat: 47.58420181274414
    },
    {
        lng: 8.465546607971191,
        lat: 47.58671188354515
    },
    {
        lng: 8.464787483215446,
        lat: 47.58803558349621
    },
    {
        lng: 8.46298885345459,
        lat: 47.58815383911133
    },
    {
        lng: 8.462653160095215,
        lat: 47.588478088378906
    },
    {
        lng: 8.460420608520508,
        lat: 47.588481903076286
    },
    {
        lng: 8.46065902709961,
        lat: 47.59023284912132
    },
    {
        lng: 8.460744857788143,
        lat: 47.59104537963884
    },
    {
        lng: 8.461214065551815,
        lat: 47.59257125854509
    },
    {
        lng: 8.460529327392635,
        lat: 47.59338760375999
    },
    {
        lng: 8.460022926330566,
        lat: 47.594936370849666
    },
    {
        lng: 8.459364891052246,
        lat: 47.59598541259771
    },
    {
        lng: 8.45812892913824,
        lat: 47.59697341918945
    },
    {
        lng: 8.45702075958252,
        lat: 47.59728622436535
    },
    {
        lng: 8.456722259521541,
        lat: 47.59840393066406
    },
    {
        lng: 8.456392288208008,
        lat: 47.59963607788086
    },
    {
        lng: 8.456486701965332,
        lat: 47.60056304931646
    },
    {
        lng: 8.456511497497559,
        lat: 47.600959777832145
    },
    {
        lng: 8.456600189208928,
        lat: 47.60234451293968
    },
    {
        lng: 8.458515167236271,
        lat: 47.6021728515625
    },
    {
        lng: 8.458919525146484,
        lat: 47.60213851928734
    },
    {
        lng: 8.46006870269781,
        lat: 47.60203552246094
    },
    {
        lng: 8.461548805236816,
        lat: 47.60214996337902
    },
    {
        lng: 8.46304893493658,
        lat: 47.60226821899414
    },
    {
        lng: 8.463983535766545,
        lat: 47.602485656738395
    },
    {
        lng: 8.46511268615734,
        lat: 47.60267257690447
    },
    {
        lng: 8.466886520385799,
        lat: 47.60314559936529
    },
    {
        lng: 8.468544960021916,
        lat: 47.60386657714855
    },
    {
        lng: 8.468115806579533,
        lat: 47.6044654846192
    },
    {
        lng: 8.468261718750057,
        lat: 47.60476303100586
    },
    {
        lng: 8.46819972991949,
        lat: 47.60541534423828
    },
    {
        lng: 8.47000980377203,
        lat: 47.605449676513786
    },
    {
        lng: 8.470795631408805,
        lat: 47.60645294189476
    },
    {
        lng: 8.471353530883846,
        lat: 47.60657119750982
    },
    {
        lng: 8.471405029296875,
        lat: 47.607032775879134
    },
    {
        lng: 8.471042633056754,
        lat: 47.60728836059593
    },
    {
        lng: 8.470645904541072,
        lat: 47.60754394531267
    },
    {
        lng: 8.470170021057186,
        lat: 47.60824584960949
    },
    {
        lng: 8.470438003540153,
        lat: 47.60858154296881
    },
    {
        lng: 8.473750114440861,
        lat: 47.60842132568388
    },
    {
        lng: 8.47498893737793,
        lat: 47.60769653320307
    },
    {
        lng: 8.476500511169377,
        lat: 47.60934066772461
    },
    {
        lng: 8.476961135864201,
        lat: 47.60979461669916
    },
    {
        lng: 8.477785110473633,
        lat: 47.61061096191406
    },
    {
        lng: 8.478055000305176,
        lat: 47.611091613769815
    },
    {
        lng: 8.478438377380371,
        lat: 47.61211395263689
    },
    {
        lng: 8.478480339050407,
        lat: 47.61336135864275
    },
    {
        lng: 8.477900505066032,
        lat: 47.61356735229492
    },
    {
        lng: 8.47895717620861,
        lat: 47.614952087402344
    },
    {
        lng: 8.478989601135368,
        lat: 47.61499404907238
    },
    {
        lng: 8.47911167144781,
        lat: 47.61515426635748
    },
    {
        lng: 8.480427742004508,
        lat: 47.614936828613224
    },
    {
        lng: 8.481863021850586,
        lat: 47.614238739013956
    },
    {
        lng: 8.4831285476684,
        lat: 47.61381149291998
    },
    {
        lng: 8.48415565490734,
        lat: 47.614067077636946
    },
    {
        lng: 8.48458290100092,
        lat: 47.61473464965843
    },
    {
        lng: 8.487069129943848,
        lat: 47.61431121826183
    },
    {
        lng: 8.488365173339787,
        lat: 47.61501312255882
    },
    {
        lng: 8.490975379943904,
        lat: 47.61471557617216
    },
    {
        lng: 8.49255466461193,
        lat: 47.61452484130888
    },
    {
        lng: 8.493048667907829,
        lat: 47.614486694335994
    },
    {
        lng: 8.493683815002555,
        lat: 47.6144714355471
    },
    {
        lng: 8.494205474853516,
        lat: 47.614582061767635
    },
    {
        lng: 8.501374244689941,
        lat: 47.61649703979498
    },
    {
        lng: 8.502003669738883,
        lat: 47.616580963134766
    },
    {
        lng: 8.50313663482666,
        lat: 47.617290496826456
    },
    {
        lng: 8.505279541015739,
        lat: 47.61735153198242
    },
    {
        lng: 8.505779266357536,
        lat: 47.61717224121105
    },
    {
        lng: 8.50825119018566,
        lat: 47.61750793457054
    },
    {
        lng: 8.507154464721793,
        lat: 47.6183319091798
    },
    {
        lng: 8.508187294006461,
        lat: 47.618431091308594
    },
    {
        lng: 8.508129119873047,
        lat: 47.622222900390796
    },
    {
        lng: 8.507280349731388,
        lat: 47.62382888793945
    },
    {
        lng: 8.508448600768986,
        lat: 47.62356185913097
    },
    {
        lng: 8.509488105773926,
        lat: 47.62350845336937
    },
    {
        lng: 8.510464668273926,
        lat: 47.62327575683605
    },
    {
        lng: 8.511464118957576,
        lat: 47.62335586547857
    },
    {
        lng: 8.512520790100154,
        lat: 47.62333679199213
    },
    {
        lng: 8.513154029846305,
        lat: 47.62333679199213
    },
    {
        lng: 8.513587951660156,
        lat: 47.623477935791016
    },
    {
        lng: 8.514164924621639,
        lat: 47.623569488525504
    },
    {
        lng: 8.514535903930607,
        lat: 47.6235160827639
    },
    {
        lng: 8.515119552612362,
        lat: 47.62343215942377
    },
    {
        lng: 8.515020370483342,
        lat: 47.6245956420899
    },
    {
        lng: 8.513731002807617,
        lat: 47.624729156494425
    },
    {
        lng: 8.512861251831168,
        lat: 47.62493896484398
    },
    {
        lng: 8.512767791748104,
        lat: 47.62550735473644
    },
    {
        lng: 8.51305961608898,
        lat: 47.62612915039068
    },
    {
        lng: 8.513492584228516,
        lat: 47.62674331665062
    },
    {
        lng: 8.514037132263297,
        lat: 47.62632751464855
    },
    {
        lng: 8.515306472778263,
        lat: 47.627136230468864
    },
    {
        lng: 8.514935493469352,
        lat: 47.627616882324446
    },
    {
        lng: 8.514816284179801,
        lat: 47.62794494628923
    },
    {
        lng: 8.514926910400504,
        lat: 47.62826919555687
    },
    {
        lng: 8.515290260314941,
        lat: 47.62841796875006
    },
    {
        lng: 8.515813827514648,
        lat: 47.62852478027361
    },
    {
        lng: 8.515767097473145,
        lat: 47.6287956237793
    },
    {
        lng: 8.51536655426031,
        lat: 47.62914657592768
    },
    {
        lng: 8.515055656433162,
        lat: 47.62944793701172
    },
    {
        lng: 8.514918327331657,
        lat: 47.62979125976591
    },
    {
        lng: 8.514955520629883,
        lat: 47.630195617675724
    },
    {
        lng: 8.515403747558707,
        lat: 47.63130950927763
    },
    {
        lng: 8.516480445861816,
        lat: 47.632080078125284
    },
    {
        lng: 8.51598072052002,
        lat: 47.6324081420899
    },
    {
        lng: 8.515776634216422,
        lat: 47.632541656494254
    },
    {
        lng: 8.515884399414062,
        lat: 47.63291168212908
    },
    {
        lng: 8.515798568725586,
        lat: 47.63330459594738
    },
    {
        lng: 8.515585899353141,
        lat: 47.633789062500284
    },
    {
        lng: 8.516733169555721,
        lat: 47.634140014648494
    },
    {
        lng: 8.517244338989258,
        lat: 47.634227752685604
    },
    {
        lng: 8.517745971679688,
        lat: 47.63431549072271
    },
    {
        lng: 8.518854141235408,
        lat: 47.634323120117244
    },
    {
        lng: 8.519860267639103,
        lat: 47.634258270263786
    },
    {
        lng: 8.52012729644781,
        lat: 47.63427352905302
    },
    {
        lng: 8.52082538604742,
        lat: 47.63431167602539
    },
    {
        lng: 8.521319389343319,
        lat: 47.63428497314476
    },
    {
        lng: 8.522064208984489,
        lat: 47.633239746093864
    },
    {
        lng: 8.524712562561092,
        lat: 47.63212203979498
    },
    {
        lng: 8.525504112243766,
        lat: 47.63167190551775
    },
    {
        lng: 8.527173995971793,
        lat: 47.631725311279524
    },
    {
        lng: 8.52849102020258,
        lat: 47.63178634643583
    },
    {
        lng: 8.529916763305721,
        lat: 47.631752014160156
    },
    {
        lng: 8.53078937530529,
        lat: 47.631935119628906
    },
    {
        lng: 8.53152942657482,
        lat: 47.631912231445256
    },
    {
        lng: 8.532027244567871,
        lat: 47.63176727294939
    },
    {
        lng: 8.53281688690197,
        lat: 47.631389617920036
    },
    {
        lng: 8.533633232116642,
        lat: 47.63121414184599
    },
    {
        lng: 8.5345458984375,
        lat: 47.63126373291021
    },
    {
        lng: 8.535076141357479,
        lat: 47.631202697753906
    },
    {
        lng: 8.53564548492443,
        lat: 47.631023406982706
    },
    {
        lng: 8.536334037780762,
        lat: 47.63096618652338
    },
    {
        lng: 8.5372314453125,
        lat: 47.63095855712885
    },
    {
        lng: 8.538130760192871,
        lat: 47.63113784790062
    },
    {
        lng: 8.538887023925895,
        lat: 47.630928039551065
    },
    {
        lng: 8.539007186889762,
        lat: 47.6300964355471
    },
    {
        lng: 8.539256095886287,
        lat: 47.62825393676758
    },
    {
        lng: 8.538523674011287,
        lat: 47.62631988525396
    },
    {
        lng: 8.542986869811955,
        lat: 47.627052307129134
    },
    {
        lng: 8.543123245239371,
        lat: 47.626533508300895
    },
    {
        lng: 8.54409217834467,
        lat: 47.62517166137695
    },
    {
        lng: 8.547370910644588,
        lat: 47.62544250488281
    },
    {
        lng: 8.548262596130371,
        lat: 47.62512588500988
    },
    {
        lng: 8.548909187317008,
        lat: 47.62522125244152
    },
    {
        lng: 8.549834251403865,
        lat: 47.625179290771655
    },
    {
        lng: 8.550674438476676,
        lat: 47.625286102295036
    },
    {
        lng: 8.552620887756461,
        lat: 47.62479019165039
    },
    {
        lng: 8.554153442382756,
        lat: 47.624732971191406
    },
    {
        lng: 8.555245399475154,
        lat: 47.62466430664057
    },
    {
        lng: 8.557312011718807,
        lat: 47.624553680420036
    },
    {
        lng: 8.558004379272518,
        lat: 47.62431335449219
    },
    {
        lng: 8.55839061737072,
        lat: 47.62387084960966
    },
    {
        lng: 8.55890083312994,
        lat: 47.62252426147461
    },
    {
        lng: 8.559503555297965,
        lat: 47.621486663818644
    },
    {
        lng: 8.560249328613395,
        lat: 47.62118148803728
    },
    {
        lng: 8.560803413391113,
        lat: 47.620948791504134
    },
    {
        lng: 8.56065750122076,
        lat: 47.62026214599621
    },
    {
        lng: 8.561850547790584,
        lat: 47.61931991577171
    },
    {
        lng: 8.561938285827694,
        lat: 47.618431091308594
    },
    {
        lng: 8.562617301940861,
        lat: 47.61713409423851
    },
    {
        lng: 8.562800407409782,
        lat: 47.61676788330084
    },
    {
        lng: 8.564090728759822,
        lat: 47.616832733154524
    },
    {
        lng: 8.564842224121037,
        lat: 47.6169548034668
    },
    {
        lng: 8.565771102905273,
        lat: 47.61703109741234
    },
    {
        lng: 8.566791534423942,
        lat: 47.617012023926065
    },
    {
        lng: 8.567743301391545,
        lat: 47.61695861816412
    },
    {
        lng: 8.568410873413029,
        lat: 47.61699295043945
    },
    {
        lng: 8.569297790527344,
        lat: 47.61723709106468
    },
    {
        lng: 8.569591522216854,
        lat: 47.61693191528332
    },
    {
        lng: 8.569778442382926,
        lat: 47.616477966308594
    },
    {
        lng: 8.570564270019474,
        lat: 47.61542892456055
    },
    {
        lng: 8.570118904113713,
        lat: 47.615123748779524
    },
    {
        lng: 8.57005596160883,
        lat: 47.61446762084955
    },
    {
        lng: 8.570228576660156,
        lat: 47.61426162719738
    },
    {
        lng: 8.570369720459041,
        lat: 47.61409378051758
    },
    {
        lng: 8.571094512939453,
        lat: 47.61407470703148
    },
    {
        lng: 8.570757865905819,
        lat: 47.613597869873274
    },
    {
        lng: 8.570909500122127,
        lat: 47.612865447998104
    },
    {
        lng: 8.570940971374569,
        lat: 47.612522125244425
    },
    {
        lng: 8.57429027557373,
        lat: 47.61230468750017
    },
    {
        lng: 8.574447631835938,
        lat: 47.61188125610374
    },
    {
        lng: 8.574298858642578,
        lat: 47.611629486083984
    },
    {
        lng: 8.57282829284668,
        lat: 47.611007690429915
    },
    {
        lng: 8.571790695190487,
        lat: 47.611015319824446
    },
    {
        lng: 8.570087432861271,
        lat: 47.61094665527344
    },
    {
        lng: 8.567986488342285,
        lat: 47.61025619506847
    },
    {
        lng: 8.566392898559684,
        lat: 47.60987472534191
    },
    {
        lng: 8.566390991210938,
        lat: 47.60959625244135
    },
    {
        lng: 8.566378593444881,
        lat: 47.60682678222679
    },
    {
        lng: 8.566036224365348,
        lat: 47.60537338256836
    },
    {
        lng: 8.564506530761719,
        lat: 47.60368347167963
    },
    {
        lng: 8.56382846832281,
        lat: 47.602905273437614
    },
    {
        lng: 8.56376838684082,
        lat: 47.602836608886946
    },
    {
        lng: 8.561985015869197,
        lat: 47.60167312622082
    },
    {
        lng: 8.56159782409668,
        lat: 47.60124969482439
    },
    {
        lng: 8.562787055969295,
        lat: 47.599605560302734
    },
    {
        lng: 8.563921928405875,
        lat: 47.598438262939396
    },
    {
        lng: 8.56647777557373,
        lat: 47.597091674804915
    },
    {
        lng: 8.567609786987418,
        lat: 47.59770584106445
    },
    {
        lng: 8.569194793701172,
        lat: 47.59803390502958
    },
    {
        lng: 8.571455955505485,
        lat: 47.59819030761736
    },
    {
        lng: 8.57204818725586,
        lat: 47.59788131713867
    },
    {
        lng: 8.573372840881404,
        lat: 47.596832275390625
    },
    {
        lng: 8.57465744018549,
        lat: 47.59552383422863
    },
    {
        lng: 8.575542449951229,
        lat: 47.59488296508789
    },
    {
        lng: 8.578639984130973,
        lat: 47.596618652343864
    },
    {
        lng: 8.582296371460075,
        lat: 47.59613800048851
    },
    {
        lng: 8.582542419433707,
        lat: 47.596103668213004
    },
    {
        lng: 8.582672119140682,
        lat: 47.59632492065441
    },
    {
        lng: 8.583328247070426,
        lat: 47.59743881225597
    },
    {
        lng: 8.58421897888195,
        lat: 47.59965896606468
    },
    {
        lng: 8.585906982421932,
        lat: 47.60074234008789
    },
    {
        lng: 8.587799072265625,
        lat: 47.60177612304682
    },
    {
        lng: 8.590232849121094,
        lat: 47.601196289062614
    },
    {
        lng: 8.59136962890625,
        lat: 47.60222625732422
    },
    {
        lng: 8.59141826629633,
        lat: 47.60302734375023
    },
    {
        lng: 8.592047691345329,
        lat: 47.60424041748058
    },
    {
        lng: 8.592694282531795,
        lat: 47.606159210205305
    },
    {
        lng: 8.594149589538574,
        lat: 47.60585403442411
    },
    {
        lng: 8.594663619995174,
        lat: 47.60574340820324
    },
    {
        lng: 8.595602035522518,
        lat: 47.605545043945426
    },
    {
        lng: 8.595708847045898,
        lat: 47.6056823730471
    },
    {
        lng: 8.596159934997559,
        lat: 47.60626220703148
    },
    {
        lng: 8.59698295593273,
        lat: 47.60681533813488
    },
    {
        lng: 8.598559379577694,
        lat: 47.60752868652344
    },
    {
        lng: 8.599754333496037,
        lat: 47.60826492309593
    },
    {
        lng: 8.601683616638184,
        lat: 47.60969543457054
    },
    {
        lng: 8.603360176086426,
        lat: 47.6110954284668
    },
    {
        lng: 8.604247093200797,
        lat: 47.6121940612793
    },
    {
        lng: 8.604640007019043,
        lat: 47.61295700073242
    },
    {
        lng: 8.604797363281307,
        lat: 47.613262176513786
    },
    {
        lng: 8.60509109497076,
        lat: 47.61440658569347
    },
    {
        lng: 8.605084419250488,
        lat: 47.61526870727556
    },
    {
        lng: 8.605080604553336,
        lat: 47.615776062011946
    },
    {
        lng: 8.604738235473746,
        lat: 47.61702728271496
    },
    {
        lng: 8.60401916503912,
        lat: 47.61815643310575
    },
    {
        lng: 8.601730346679744,
        lat: 47.62081527709961
    },
    {
        lng: 8.600610733032227,
        lat: 47.622322082519645
    },
    {
        lng: 8.599627494811955,
        lat: 47.623729705810604
    },
    {
        lng: 8.599157333374023,
        lat: 47.624549865722884
    },
    {
        lng: 8.59876823425293,
        lat: 47.62522888183605
    },
    {
        lng: 8.598025321960506,
        lat: 47.626983642578125
    },
    {
        lng: 8.597352027893066,
        lat: 47.629142761230696
    },
    {
        lng: 8.59705924987793,
        lat: 47.63016510009794
    },
    {
        lng: 8.596795082092342,
        lat: 47.631557464599666
    },
    {
        lng: 8.59669303894043,
        lat: 47.63312530517578
    },
    {
        lng: 8.596630096435547,
        lat: 47.63475036621122
    },
    {
        lng: 8.596583366393986,
        lat: 47.63597869873047
    },
    {
        lng: 8.596241950988713,
        lat: 47.63716888427757
    },
    {
        lng: 8.595582962036246,
        lat: 47.638999938964844
    },
    {
        lng: 8.595288276672306,
        lat: 47.64003753662115
    },
    {
        lng: 8.595126152038631,
        lat: 47.64094543457031
    },
    {
        lng: 8.595204353332576,
        lat: 47.64193344116205
    },
    {
        lng: 8.595388412475643,
        lat: 47.642578125000114
    },
    {
        lng: 8.5957412719726,
        lat: 47.643131256103516
    },
    {
        lng: 8.596285820007381,
        lat: 47.643585205078296
    },
    {
        lng: 8.596596717834473,
        lat: 47.64373397827171
    },
    {
        lng: 8.597234725952148,
        lat: 47.64404296875006
    },
    {
        lng: 8.598588943481502,
        lat: 47.6445121765139
    },
    {
        lng: 8.59988021850586,
        lat: 47.64499664306646
    },
    {
        lng: 8.600919723510799,
        lat: 47.645542144775504
    },
    {
        lng: 8.601617813110408,
        lat: 47.64603805542015
    },
    {
        lng: 8.602221488952694,
        lat: 47.64657592773466
    },
    {
        lng: 8.60251712799078,
        lat: 47.647003173828125
    },
    {
        lng: 8.602745056152457,
        lat: 47.647872924804744
    },
    {
        lng: 8.603167533874569,
        lat: 47.6495628356933
    },
    {
        lng: 8.603774070739746,
        lat: 47.65056610107433
    },
    {
        lng: 8.60435009002697,
        lat: 47.6512718200683
    },
    {
        lng: 8.605195045471248,
        lat: 47.652023315429915
    },
    {
        lng: 8.605806350708065,
        lat: 47.65232849121088
    },
    {
        lng: 8.606454849243278,
        lat: 47.65250778198265
    },
    {
        lng: 8.607139587402457,
        lat: 47.65254211425781
    },
    {
        lng: 8.60770511627203,
        lat: 47.65244674682617
    },
    {
        lng: 8.608715057373104,
        lat: 47.65212631225609
    },
    {
        lng: 8.610299110412598,
        lat: 47.6514778137207
    },
    {
        lng: 8.611195564270076,
        lat: 47.65088272094738
    },
    {
        lng: 8.612223625183049,
        lat: 47.6500816345216
    },
    {
        lng: 8.61297607421875,
        lat: 47.64916610717785
    },
    {
        lng: 8.613612174987793,
        lat: 47.64801788330101
    },
    {
        lng: 8.613781929016056,
        lat: 47.64750289917009
    },
    {
        lng: 8.614034652709961,
        lat: 47.64673995971697
    },
    {
        lng: 8.614236831665153,
        lat: 47.64551925659208
    },
    {
        lng: 8.614139556884709,
        lat: 47.64480209350586
    },
    {
        lng: 8.613886833191032,
        lat: 47.6440620422365
    },
    {
        lng: 8.613440513610954,
        lat: 47.64350509643555
    },
    {
        lng: 8.612835884094181,
        lat: 47.64300918579107
    },
    {
        lng: 8.612259864807186,
        lat: 47.642578125000114
    },
    {
        lng: 8.611557006836051,
        lat: 47.64218521118164
    },
    {
        lng: 8.610695838928223,
        lat: 47.641876220703296
    },
    {
        lng: 8.609766960144043,
        lat: 47.641693115234375
    },
    {
        lng: 8.609508514404354,
        lat: 47.64167022705078
    },
    {
        lng: 8.608554840087947,
        lat: 47.64158630371105
    },
    {
        lng: 8.607464790344352,
        lat: 47.64156723022461
    },
    {
        lng: 8.606245040893555,
        lat: 47.64165115356468
    },
    {
        lng: 8.605340003967285,
        lat: 47.64165496826172
    },
    {
        lng: 8.604596138000602,
        lat: 47.64155960083008
    },
    {
        lng: 8.603888511657829,
        lat: 47.64129257202154
    },
    {
        lng: 8.603372573852653,
        lat: 47.6409263610841
    },
    {
        lng: 8.602982521057243,
        lat: 47.64051818847656
    },
    {
        lng: 8.60280799865734,
        lat: 47.64020156860363
    },
    {
        lng: 8.602789878845215,
        lat: 47.63984298706055
    },
    {
        lng: 8.602981567382756,
        lat: 47.63924789428722
    },
    {
        lng: 8.60358905792242,
        lat: 47.63852691650396
    },
    {
        lng: 8.604171752929688,
        lat: 47.63796997070324
    },
    {
        lng: 8.604970932006893,
        lat: 47.63743591308605
    },
    {
        lng: 8.606010437011832,
        lat: 47.63715744018549
    },
    {
        lng: 8.606826782226562,
        lat: 47.637023925781364
    },
    {
        lng: 8.608168601989746,
        lat: 47.63698577880865
    },
    {
        lng: 8.613050460815373,
        lat: 47.63736343383789
    },
    {
        lng: 8.615593910217228,
        lat: 47.63770294189453
    },
    {
        lng: 8.617325782775822,
        lat: 47.63807296752941
    },
    {
        lng: 8.619022369384822,
        lat: 47.63856506347685
    },
    {
        lng: 8.620404243469295,
        lat: 47.63913726806669
    },
    {
        lng: 8.621781349182186,
        lat: 47.639812469482536
    },
    {
        lng: 8.62287616729742,
        lat: 47.64054870605469
    },
    {
        lng: 8.623690605163574,
        lat: 47.64128112792969
    },
    {
        lng: 8.624558448791447,
        lat: 47.64226531982422
    },
    {
        lng: 8.625386238098258,
        lat: 47.64363479614269
    },
    {
        lng: 8.626303672790527,
        lat: 47.64504623413086
    },
    {
        lng: 8.626576423644963,
        lat: 47.64558029174805
    },
    {
        lng: 8.627169609069881,
        lat: 47.6467742919923
    },
    {
        lng: 8.627442359924316,
        lat: 47.6473007202149
    },
    {
        lng: 8.627755165100211,
        lat: 47.647819519043196
    },
    {
        lng: 8.62830638885498,
        lat: 47.64872741699219
    },
    {
        lng: 8.628605842590389,
        lat: 47.64925384521496
    },
    {
        lng: 8.628804206848258,
        lat: 47.64965438842796
    },
    {
        lng: 8.628921508789006,
        lat: 47.65005874633795
    },
    {
        lng: 8.628972053527889,
        lat: 47.650482177734375
    },
    {
        lng: 8.628982543945312,
        lat: 47.65090560913086
    },
    {
        lng: 8.62896728515625,
        lat: 47.65132904052746
    },
    {
        lng: 8.628962516784782,
        lat: 47.65135955810558
    },
    {
        lng: 8.628911018371582,
        lat: 47.651741027832145
    },
    {
        lng: 8.628834724426383,
        lat: 47.652019500732536
    },
    {
        lng: 8.628691673278922,
        lat: 47.652286529541016
    },
    {
        lng: 8.628548622131461,
        lat: 47.65255355834972
    },
    {
        lng: 8.628378868103027,
        lat: 47.652809143066634
    },
    {
        lng: 8.628196716308594,
        lat: 47.65306091308622
    },
    {
        lng: 8.627863883972111,
        lat: 47.65342330932617
    },
    {
        lng: 8.627491950988883,
        lat: 47.65374755859398
    },
    {
        lng: 8.626793861389217,
        lat: 47.65428543090832
    },
    {
        lng: 8.625123977661246,
        lat: 47.655521392822266
    },
    {
        lng: 8.624125480651912,
        lat: 47.656234741211165
    },
    {
        lng: 8.622651100158635,
        lat: 47.657222747802905
    },
    {
        lng: 8.621601104736328,
        lat: 47.65790557861328
    },
    {
        lng: 8.620538711547965,
        lat: 47.65856170654308
    },
    {
        lng: 8.619436264038143,
        lat: 47.659210205078125
    },
    {
        lng: 8.618471145629996,
        lat: 47.659740447998274
    },
    {
        lng: 8.617806434631404,
        lat: 47.660079956054744
    },
    {
        lng: 8.617129325866813,
        lat: 47.660400390625
    },
    {
        lng: 8.616438865661678,
        lat: 47.66070175170921
    },
    {
        lng: 8.614504814147892,
        lat: 47.661533355713004
    },
    {
        lng: 8.613746643066463,
        lat: 47.661170959472884
    },
    {
        lng: 8.61279201507574,
        lat: 47.66074752807623
    },
    {
        lng: 8.610795974731502,
        lat: 47.662151336669865
    },
    {
        lng: 8.610686302185115,
        lat: 47.66222000122093
    },
    {
        lng: 8.609773635864258,
        lat: 47.662788391113565
    },
    {
        lng: 8.609335899353084,
        lat: 47.66310501098627
    },
    {
        lng: 8.608937263488826,
        lat: 47.663433074951286
    },
    {
        lng: 8.608578681945858,
        lat: 47.663768768310774
    },
    {
        lng: 8.608369827270451,
        lat: 47.66401672363281
    },
    {
        lng: 8.608200073242188,
        lat: 47.66426467895508
    },
    {
        lng: 8.607946395874023,
        lat: 47.66464614868164
    },
    {
        lng: 8.607730865478516,
        lat: 47.665046691894645
    },
    {
        lng: 8.607514381408748,
        lat: 47.6655006408692
    },
    {
        lng: 8.607295989990234,
        lat: 47.66598510742193
    },
    {
        lng: 8.606765747070426,
        lat: 47.66733932495134
    },
    {
        lng: 8.606343269348088,
        lat: 47.6690025329591
    },
    {
        lng: 8.605822563171444,
        lat: 47.66903305053711
    },
    {
        lng: 8.60581207275402,
        lat: 47.67001342773449
    },
    {
        lng: 8.606623649597225,
        lat: 47.672119140625114
    },
    {
        lng: 8.602923393249512,
        lat: 47.6726341247558
    },
    {
        lng: 8.598039627075309,
        lat: 47.67280578613304
    },
    {
        lng: 8.596053123474235,
        lat: 47.66945266723633
    },
    {
        lng: 8.59564113616949,
        lat: 47.66941070556646
    },
    {
        lng: 8.593849182128906,
        lat: 47.66725158691406
    },
    {
        lng: 8.593010902404842,
        lat: 47.66686630249035
    },
    {
        lng: 8.591382980346737,
        lat: 47.66759872436529
    },
    {
        lng: 8.587781906127987,
        lat: 47.666477203369254
    },
    {
        lng: 8.585789680480957,
        lat: 47.66563034057617
    },
    {
        lng: 8.580352783203125,
        lat: 47.66283798217779
    },
    {
        lng: 8.579199790954647,
        lat: 47.662151336669865
    },
    {
        lng: 8.578059196472282,
        lat: 47.661617279052905
    },
    {
        lng: 8.57776165008545,
        lat: 47.66156768798851
    },
    {
        lng: 8.57754898071289,
        lat: 47.661609649658374
    },
    {
        lng: 8.5773544311524,
        lat: 47.661705017090014
    },
    {
        lng: 8.577019691467399,
        lat: 47.66193771362316
    },
    {
        lng: 8.576367378234977,
        lat: 47.66229629516613
    },
    {
        lng: 8.576190948486271,
        lat: 47.6623725891115
    },
    {
        lng: 8.57601547241211,
        lat: 47.66244125366234
    },
    {
        lng: 8.575826644897575,
        lat: 47.662502288818644
    },
    {
        lng: 8.57547855377203,
        lat: 47.66254806518555
    },
    {
        lng: 8.574753761291618,
        lat: 47.66262817382807
    },
    {
        lng: 8.573939323425293,
        lat: 47.66274642944336
    },
    {
        lng: 8.573380470275936,
        lat: 47.66309356689476
    },
    {
        lng: 8.572997093200797,
        lat: 47.66345596313505
    },
    {
        lng: 8.57237911224371,
        lat: 47.66394424438499
    },
    {
        lng: 8.571553230285701,
        lat: 47.66436767578125
    },
    {
        lng: 8.5708646774292,
        lat: 47.66486740112305
    },
    {
        lng: 8.570181846618652,
        lat: 47.66476058959972
    },
    {
        lng: 8.569146156311092,
        lat: 47.664230346679915
    },
    {
        lng: 8.568309783935604,
        lat: 47.664478302002124
    },
    {
        lng: 8.567468643188533,
        lat: 47.66481781005882
    },
    {
        lng: 8.56674957275385,
        lat: 47.66521072387695
    },
    {
        lng: 8.566164970398006,
        lat: 47.665748596191634
    },
    {
        lng: 8.564809799194336,
        lat: 47.665283203125114
    },
    {
        lng: 8.563558578491268,
        lat: 47.66802978515625
    },
    {
        lng: 8.563433647155762,
        lat: 47.66854095458979
    },
    {
        lng: 8.563363075256348,
        lat: 47.668991088867415
    },
    {
        lng: 8.563388824463004,
        lat: 47.66923141479492
    },
    {
        lng: 8.563464164733944,
        lat: 47.66954040527344
    },
    {
        lng: 8.563691139221305,
        lat: 47.670055389404524
    },
    {
        lng: 8.561267852783317,
        lat: 47.67034530639643
    },
    {
        lng: 8.560456275940055,
        lat: 47.67037963867193
    },
    {
        lng: 8.559372901916504,
        lat: 47.670169830322266
    },
    {
        lng: 8.558648109436092,
        lat: 47.66979980468756
    },
    {
        lng: 8.557953834533805,
        lat: 47.66954803466797
    },
    {
        lng: 8.557152748107967,
        lat: 47.669334411621264
    },
    {
        lng: 8.556591033935547,
        lat: 47.66927337646513
    },
    {
        lng: 8.554315567016658,
        lat: 47.66938781738304
    },
    {
        lng: 8.553373336791992,
        lat: 47.669170379638956
    },
    {
        lng: 8.552240371704158,
        lat: 47.668888092041016
    },
    {
        lng: 8.551082611083984,
        lat: 47.66831970214872
    },
    {
        lng: 8.549896240234432,
        lat: 47.66759872436529
    },
    {
        lng: 8.549395561218319,
        lat: 47.667362213134766
    },
    {
        lng: 8.54773330688488,
        lat: 47.6670837402346
    },
    {
        lng: 8.547028541564998,
        lat: 47.667095184326286
    },
    {
        lng: 8.546817779540959,
        lat: 47.667541503906364
    },
    {
        lng: 8.544051170349121,
        lat: 47.666202545166016
    },
    {
        lng: 8.54228305816656,
        lat: 47.66534423828125
    },
    {
        lng: 8.541308403015137,
        lat: 47.665237426757926
    },
    {
        lng: 8.53995227813732,
        lat: 47.66508865356468
    },
    {
        lng: 8.53980922698986,
        lat: 47.66410446166992
    },
    {
        lng: 8.539405822753963,
        lat: 47.6636657714846
    },
    {
        lng: 8.539094924926871,
        lat: 47.663330078125114
    },
    {
        lng: 8.539910316467285,
        lat: 47.66121292114258
    },
    {
        lng: 8.540621757507381,
        lat: 47.659725189208984
    },
    {
        lng: 8.540941238403377,
        lat: 47.658767700195426
    },
    {
        lng: 8.541400909423885,
        lat: 47.65825271606451
    },
    {
        lng: 8.54126358032238,
        lat: 47.65772628784208
    },
    {
        lng: 8.541357994079533,
        lat: 47.65711212158203
    },
    {
        lng: 8.540849685668888,
        lat: 47.65706634521513
    },
    {
        lng: 8.539969444275016,
        lat: 47.65769958496111
    },
    {
        lng: 8.537986755371094,
        lat: 47.6582412719726
    },
    {
        lng: 8.53641414642334,
        lat: 47.65880203247093
    },
    {
        lng: 8.535294532775822,
        lat: 47.65915298461914
    },
    {
        lng: 8.533747673034725,
        lat: 47.659915924072266
    },
    {
        lng: 8.534214019775504,
        lat: 47.66159439086914
    },
    {
        lng: 8.533353805541992,
        lat: 47.66319274902338
    },
    {
        lng: 8.53158187866211,
        lat: 47.662250518799055
    },
    {
        lng: 8.529781341552791,
        lat: 47.66128921508812
    },
    {
        lng: 8.527009010314998,
        lat: 47.66039657592802
    },
    {
        lng: 8.527997016906738,
        lat: 47.65764617919933
    },
    {
        lng: 8.529060363769588,
        lat: 47.657665252685774
    },
    {
        lng: 8.52997970581066,
        lat: 47.65697097778349
    },
    {
        lng: 8.529513359069881,
        lat: 47.65654754638672
    },
    {
        lng: 8.529129981994686,
        lat: 47.65601730346708
    },
    {
        lng: 8.529010772705135,
        lat: 47.65548324584961
    },
    {
        lng: 8.529205322265625,
        lat: 47.65501022338873
    },
    {
        lng: 8.52953815460205,
        lat: 47.654609680175724
    },
    {
        lng: 8.53004455566412,
        lat: 47.65425109863281
    },
    {
        lng: 8.530628204345703,
        lat: 47.65375137329096
    },
    {
        lng: 8.531000137329102,
        lat: 47.65323257446289
    },
    {
        lng: 8.531258583068961,
        lat: 47.65261840820335
    },
    {
        lng: 8.531291961669979,
        lat: 47.652538299560604
    },
    {
        lng: 8.531560897827262,
        lat: 47.6519851684572
    },
    {
        lng: 8.532189369201717,
        lat: 47.651573181152344
    },
    {
        lng: 8.532906532287654,
        lat: 47.65131759643572
    },
    {
        lng: 8.533329010009766,
        lat: 47.65087509155285
    },
    {
        lng: 8.533543586730957,
        lat: 47.65034103393555
    },
    {
        lng: 8.533943176269474,
        lat: 47.65002822875999
    },
    {
        lng: 8.53399562835699,
        lat: 47.649589538574276
    },
    {
        lng: 8.533556938171444,
        lat: 47.64894485473644
    },
    {
        lng: 8.533329010009766,
        lat: 47.647968292236385
    },
    {
        lng: 8.533254623413143,
        lat: 47.64784240722679
    },
    {
        lng: 8.53318023681635,
        lat: 47.6477165222168
    },
    {
        lng: 8.532787322998104,
        lat: 47.64721298217785
    },
    {
        lng: 8.532312393188477,
        lat: 47.64643096923828
    },
    {
        lng: 8.532122611999512,
        lat: 47.6461944580081
    },
    {
        lng: 8.532021522522086,
        lat: 47.64606857299816
    },
    {
        lng: 8.531866073608398,
        lat: 47.6459579467774
    },
    {
        lng: 8.531696319580192,
        lat: 47.64586257934576
    },
    {
        lng: 8.531526565551758,
        lat: 47.64579010009777
    },
    {
        lng: 8.531328201293888,
        lat: 47.64575958251959
    },
    {
        lng: 8.531129837036076,
        lat: 47.645729064941634
    },
    {
        lng: 8.530692100524902,
        lat: 47.64570999145536
    },
    {
        lng: 8.529841423034668,
        lat: 47.64569473266613
    },
    {
        lng: 8.52901649475109,
        lat: 47.64569854736328
    },
    {
        lng: 8.528605461120662,
        lat: 47.64566421508795
    },
    {
        lng: 8.528182029724235,
        lat: 47.64560317993164
    },
    {
        lng: 8.527043342590332,
        lat: 47.64542770385742
    },
    {
        lng: 8.526569366455192,
        lat: 47.64533996582054
    },
    {
        lng: 8.523755073547306,
        lat: 47.64495849609398
    },
    {
        lng: 8.522894859313965,
        lat: 47.644920349121264
    },
    {
        lng: 8.52188491821289,
        lat: 47.64508056640619
    },
    {
        lng: 8.520676612854004,
        lat: 47.645378112793026
    },
    {
        lng: 8.516912460327092,
        lat: 47.64665222167969
    },
    {
        lng: 8.515824317932243,
        lat: 47.64702606201189
    },
    {
        lng: 8.51479339599615,
        lat: 47.647281646728516
    },
    {
        lng: 8.513798713684196,
        lat: 47.64751434326183
    },
    {
        lng: 8.51279354095459,
        lat: 47.6475639343264
    },
    {
        lng: 8.51214790344244,
        lat: 47.647525787353516
    },
    {
        lng: 8.511116027832031,
        lat: 47.64736557006859
    },
    {
        lng: 8.510582923889217,
        lat: 47.64706039428722
    },
    {
        lng: 8.510137557983455,
        lat: 47.647193908691406
    },
    {
        lng: 8.50892448425293,
        lat: 47.646720886230526
    },
    {
        lng: 8.50749397277832,
        lat: 47.64684677124035
    },
    {
        lng: 8.505709648132381,
        lat: 47.6470298767091
    },
    {
        lng: 8.503850936889705,
        lat: 47.64730453491228
    },
    {
        lng: 8.502422332763672,
        lat: 47.64738464355469
    },
    {
        lng: 8.500872611999512,
        lat: 47.64739227294922
    },
    {
        lng: 8.49955844879156,
        lat: 47.647262573242415
    },
    {
        lng: 8.499771118164176,
        lat: 47.64678955078119
    },
    {
        lng: 8.500127792358342,
        lat: 47.64623641967779
    },
    {
        lng: 8.49945163726801,
        lat: 47.64641571044916
    },
    {
        lng: 8.498850822448787,
        lat: 47.64648818969738
    },
    {
        lng: 8.497335433959961,
        lat: 47.64651870727539
    },
    {
        lng: 8.495840072631893,
        lat: 47.64652633666992
    },
    {
        lng: 8.494055747985783,
        lat: 47.64668273925804
    },
    {
        lng: 8.493357658386344,
        lat: 47.646980285644474
    },
    {
        lng: 8.493704795837402,
        lat: 47.64578628540056
    },
    {
        lng: 8.493803977966365,
        lat: 47.64507293701166
    },
    {
        lng: 8.49377346038824,
        lat: 47.64365768432617
    },
    {
        lng: 8.49396896362316,
        lat: 47.64317321777338
    },
    {
        lng: 8.493624687194824,
        lat: 47.64299011230469
    },
    {
        lng: 8.494256973266658,
        lat: 47.64213180541992
    },
    {
        lng: 8.4936780929566,
        lat: 47.64209747314476
    },
    {
        lng: 8.492376327514648,
        lat: 47.64208602905302
    },
    {
        lng: 8.491688728332633,
        lat: 47.64299011230469
    },
    {
        lng: 8.490462303161621,
        lat: 47.642433166503906
    },
    {
        lng: 8.489596366882324,
        lat: 47.642509460449446
    },
    {
        lng: 8.489715576171875,
        lat: 47.643035888671875
    },
    {
        lng: 8.488852500915641,
        lat: 47.64416122436535
    },
    {
        lng: 8.487325668335075,
        lat: 47.64508819580078
    },
    {
        lng: 8.486854553222656,
        lat: 47.644836425781364
    },
    {
        lng: 8.485164642334098,
        lat: 47.64391326904308
    },
    {
        lng: 8.482706069946289,
        lat: 47.644203186035384
    },
    {
        lng: 8.482004165649471,
        lat: 47.645111083984546
    },
    {
        lng: 8.481455802917537,
        lat: 47.645824432373274
    },
    {
        lng: 8.482171058654842,
        lat: 47.64722061157238
    },
    {
        lng: 8.480802536010799,
        lat: 47.648159027099666
    },
    {
        lng: 8.48047924041748,
        lat: 47.649230957031364
    },
    {
        lng: 8.477388381958008,
        lat: 47.64984130859369
    },
    {
        lng: 8.476107597351131,
        lat: 47.65009307861328
    },
    {
        lng: 8.473736763000602,
        lat: 47.6498260498048
    },
    {
        lng: 8.473529815673942,
        lat: 47.64966583251953
    },
    {
        lng: 8.47276973724371,
        lat: 47.6490859985351
    },
    {
        lng: 8.47290134429943,
        lat: 47.64801406860363
    },
    {
        lng: 8.472908973693961,
        lat: 47.64794921875
    },
    {
        lng: 8.473732948303223,
        lat: 47.647003173828125
    },
    {
        lng: 8.475982666015625,
        lat: 47.64662170410156
    },
    {
        lng: 8.476761817932129,
        lat: 47.64624786376953
    },
    {
        lng: 8.477434158325252,
        lat: 47.645927429199446
    },
    {
        lng: 8.477665901184082,
        lat: 47.6446647644043
    },
    {
        lng: 8.474844932556152,
        lat: 47.643390655517635
    },
    {
        lng: 8.47436714172369,
        lat: 47.64317321777338
    },
    {
        lng: 8.47111606597906,
        lat: 47.64234161376959
    },
    {
        lng: 8.47197246551525,
        lat: 47.64091873168957
    },
    {
        lng: 8.472772598266545,
        lat: 47.64087295532249
    },
    {
        lng: 8.475122451782227,
        lat: 47.640975952148665
    },
    {
        lng: 8.477828025817985,
        lat: 47.639701843262
    },
    {
        lng: 8.476782798767147,
        lat: 47.63959503173828
    },
    {
        lng: 8.47412490844738,
        lat: 47.63932800292997
    },
    {
        lng: 8.473855018615836,
        lat: 47.63888931274414
    },
    {
        lng: 8.473494529724121,
        lat: 47.63830566406273
    },
    {
        lng: 8.471799850463924,
        lat: 47.638717651367244
    },
    {
        lng: 8.4701566696167,
        lat: 47.639114379882926
    },
    {
        lng: 8.467460632324276,
        lat: 47.64129257202154
    },
    {
        lng: 8.46613693237299,
        lat: 47.642761230469034
    },
    {
        lng: 8.465860366821403,
        lat: 47.64360046386719
    },
    {
        lng: 8.465044021606502,
        lat: 47.64607620239269
    },
    {
        lng: 8.46375846862793,
        lat: 47.6485595703125
    },
    {
        lng: 8.465826034545898,
        lat: 47.65260314941429
    },
    {
        lng: 8.465851783752555,
        lat: 47.652652740478686
    },
    {
        lng: 8.466996192932243,
        lat: 47.65558624267578
    },
    {
        lng: 8.467162132263127,
        lat: 47.65607452392584
    },
    {
        lng: 8.466900825500602,
        lat: 47.656242370605696
    },
    {
        lng: 8.466123580932674,
        lat: 47.65673828125
    },
    {
        lng: 8.465430259704704,
        lat: 47.65713119506847
    },
    {
        lng: 8.464070320129338,
        lat: 47.65594100952171
    },
    {
        lng: 8.46349143981945,
        lat: 47.65633392333979
    },
    {
        lng: 8.462056159973201,
        lat: 47.65536117553734
    },
    {
        lng: 8.46031570434576,
        lat: 47.653995513916016
    },
    {
        lng: 8.45737171173107,
        lat: 47.65270233154291
    },
    {
        lng: 8.456505775451774,
        lat: 47.653457641601676
    },
    {
        lng: 8.453630447387752,
        lat: 47.6548423767091
    },
    {
        lng: 8.451848983764762,
        lat: 47.65478134155296
    },
    {
        lng: 8.450426101684684,
        lat: 47.655059814453125
    },
    {
        lng: 8.445063591003475,
        lat: 47.65378189086914
    },
    {
        lng: 8.441316604614258,
        lat: 47.6559295654298
    },
    {
        lng: 8.439282417297363,
        lat: 47.65654754638672
    },
    {
        lng: 8.438796997070312,
        lat: 47.657314300537166
    },
    {
        lng: 8.436745643615723,
        lat: 47.657382965087834
    },
    {
        lng: 8.435345649719295,
        lat: 47.65948486328148
    },
    {
        lng: 8.433285713195858,
        lat: 47.66122817993181
    },
    {
        lng: 8.432333946228084,
        lat: 47.66183090209961
    },
    {
        lng: 8.430976867675781,
        lat: 47.662681579589844
    },
    {
        lng: 8.426871299743766,
        lat: 47.66506958007841
    },
    {
        lng: 8.425289154052848,
        lat: 47.66561508178728
    },
    {
        lng: 8.423684120178336,
        lat: 47.666816711425724
    },
    {
        lng: 8.417895317077637,
        lat: 47.66648101806658
    },
    {
        lng: 8.414224624633789,
        lat: 47.666110992431754
    },
    {
        lng: 8.412354469299373,
        lat: 47.66627502441406
    },
    {
        lng: 8.410757064819393,
        lat: 47.668193817138786
    },
    {
        lng: 8.407623291015739,
        lat: 47.67213439941435
    },
    {
        lng: 8.40601825714117,
        lat: 47.6732635498048
    },
    {
        lng: 8.405864715576115,
        lat: 47.67368316650385
    },
    {
        lng: 8.405559539794979,
        lat: 47.67451477050781
    },
    {
        lng: 8.40608215332037,
        lat: 47.67547225952177
    },
    {
        lng: 8.406406402588004,
        lat: 47.676059722900504
    },
    {
        lng: 8.406860351562614,
        lat: 47.6767387390139
    },
    {
        lng: 8.407051086425895,
        lat: 47.67702484130882
    },
    {
        lng: 8.40856742858898,
        lat: 47.67869567871094
    },
    {
        lng: 8.409557342529297,
        lat: 47.679023742675724
    },
    {
        lng: 8.41073131561285,
        lat: 47.67917633056652
    },
    {
        lng: 8.414239883422852,
        lat: 47.67950439453148
    },
    {
        lng: 8.415944099426383,
        lat: 47.68000030517578
    },
    {
        lng: 8.416240692138729,
        lat: 47.679660797119425
    },
    {
        lng: 8.417251586914062,
        lat: 47.680725097656364
    },
    {
        lng: 8.417707443237418,
        lat: 47.68112945556635
    },
    {
        lng: 8.418437004089299,
        lat: 47.68278121948259
    },
    {
        lng: 8.420641899108944,
        lat: 47.68385696411161
    },
    {
        lng: 8.419380187988281,
        lat: 47.68543243408209
    },
    {
        lng: 8.41900539398199,
        lat: 47.68595886230469
    },
    {
        lng: 8.418143272399846,
        lat: 47.688232421875
    },
    {
        lng: 8.417745590209904,
        lat: 47.688632965088175
    },
    {
        lng: 8.41751575469965,
        lat: 47.68886566162115
    },
    {
        lng: 8.412110328674316,
        lat: 47.69425582885742
    },
    {
        lng: 8.40882682800293,
        lat: 47.695369720459155
    },
    {
        lng: 8.406386375427246,
        lat: 47.695465087890796
    },
    {
        lng: 8.406003952026424,
        lat: 47.69631195068365
    },
    {
        lng: 8.405304908752498,
        lat: 47.69774627685547
    },
    {
        lng: 8.404425621032715,
        lat: 47.698062896728516
    },
    {
        lng: 8.406706809997502,
        lat: 47.70006942749052
    },
    {
        lng: 8.407986640930119,
        lat: 47.701557159423885
    },
    {
        lng: 8.40833663940424,
        lat: 47.701927185058594
    },
    {
        lng: 8.4090452194215,
        lat: 47.70267105102562
    },
    {
        lng: 8.410223007202205,
        lat: 47.70376205444353
    },
    {
        lng: 8.412101745605469,
        lat: 47.70510101318371
    },
    {
        lng: 8.413287162780705,
        lat: 47.70603179931646
    },
    {
        lng: 8.414276123046932,
        lat: 47.706932067871094
    },
    {
        lng: 8.415143013000545,
        lat: 47.70790481567394
    },
    {
        lng: 8.416828155517635,
        lat: 47.710006713867415
    },
    {
        lng: 8.417833328247013,
        lat: 47.70966339111334
    },
    {
        lng: 8.419179916381893,
        lat: 47.71015167236345
    },
    {
        lng: 8.419796943664664,
        lat: 47.70972061157249
    },
    {
        lng: 8.42014122009283,
        lat: 47.70947265625006
    },
    {
        lng: 8.42426872253418,
        lat: 47.71119689941412
    },
    {
        lng: 8.425337791442985,
        lat: 47.71164321899437
    },
    {
        lng: 8.42585372924816,
        lat: 47.711158752441634
    },
    {
        lng: 8.430523872375488,
        lat: 47.71409606933622
    },
    {
        lng: 8.431429862976074,
        lat: 47.714401245117415
    },
    {
        lng: 8.432581901550293,
        lat: 47.7155189514163
    },
    {
        lng: 8.434735298156738,
        lat: 47.71723175048828
    },
    {
        lng: 8.436732292175236,
        lat: 47.71823501586914
    },
    {
        lng: 8.43841552734375,
        lat: 47.71844482421881
    },
    {
        lng: 8.44155120849615,
        lat: 47.71766662597662
    },
    {
        lng: 8.441876411438045,
        lat: 47.71850204467796
    },
    {
        lng: 8.440225601196346,
        lat: 47.71975708007835
    },
    {
        lng: 8.442609786987362,
        lat: 47.72170639038086
    },
    {
        lng: 8.445235252380314,
        lat: 47.72296142578125
    },
    {
        lng: 8.447107315063533,
        lat: 47.72312164306658
    },
    {
        lng: 8.450852394104004,
        lat: 47.72277069091797
    },
    {
        lng: 8.454988479614371,
        lat: 47.72225952148443
    },
    {
        lng: 8.455348968505916,
        lat: 47.722919464111385
    },
    {
        lng: 8.454036712646541,
        lat: 47.723537445068644
    },
    {
        lng: 8.45452690124523,
        lat: 47.72541809082048
    },
    {
        lng: 8.454652786254826,
        lat: 47.727218627929744
    },
    {
        lng: 8.456692695617676,
        lat: 47.72862625122093
    },
    {
        lng: 8.456984519958553,
        lat: 47.72882843017578
    },
    {
        lng: 8.456937789917049,
        lat: 47.72996902465826
    },
    {
        lng: 8.456375122070312,
        lat: 47.73037338256847
    },
    {
        lng: 8.454582214355582,
        lat: 47.73040390014677
    },
    {
        lng: 8.452841758728141,
        lat: 47.73043060302757
    },
    {
        lng: 8.451373100280875,
        lat: 47.7333984375
    },
    {
        lng: 8.449997901916618,
        lat: 47.73724746704113
    },
    {
        lng: 8.449780464172363,
        lat: 47.73803710937523
    },
    {
        lng: 8.44982624053955,
        lat: 47.738632202148665
    },
    {
        lng: 8.450134277343807,
        lat: 47.73929214477562
    },
    {
        lng: 8.450433731079215,
        lat: 47.739715576172046
    },
    {
        lng: 8.450577735900993,
        lat: 47.740074157714844
    },
    {
        lng: 8.450986862182617,
        lat: 47.7404022216798
    },
    {
        lng: 8.451744079589787,
        lat: 47.740867614746264
    },
    {
        lng: 8.452528953552246,
        lat: 47.741531372070426
    },
    {
        lng: 8.452680587768555,
        lat: 47.74165725708036
    },
    {
        lng: 8.4537935256958,
        lat: 47.742034912109375
    },
    {
        lng: 8.454733848571891,
        lat: 47.742328643799055
    },
    {
        lng: 8.455823898315487,
        lat: 47.7428245544433
    },
    {
        lng: 8.45572662353527,
        lat: 47.74304962158209
    },
    {
        lng: 8.455508232116756,
        lat: 47.74363708496105
    },
    {
        lng: 8.455340385437069,
        lat: 47.74426269531244
    },
    {
        lng: 8.455984115600643,
        lat: 47.74477767944353
    },
    {
        lng: 8.456548690795955,
        lat: 47.745456695556754
    },
    {
        lng: 8.456523895263729,
        lat: 47.746067047119084
    },
    {
        lng: 8.456218719482365,
        lat: 47.74675369262701
    },
    {
        lng: 8.45599746704113,
        lat: 47.747337341308594
    },
    {
        lng: 8.456043243408203,
        lat: 47.748138427734375
    },
    {
        lng: 8.456182479858455,
        lat: 47.748592376709155
    },
    {
        lng: 8.456640243530217,
        lat: 47.749149322509766
    },
    {
        lng: 8.457628250122127,
        lat: 47.7499542236331
    },
    {
        lng: 8.458558082580623,
        lat: 47.75042343139677
    },
    {
        lng: 8.459728240966854,
        lat: 47.75079345703125
    },
    {
        lng: 8.461017608642578,
        lat: 47.75116348266613
    },
    {
        lng: 8.462385177612305,
        lat: 47.75153350830078
    },
    {
        lng: 8.46374607086193,
        lat: 47.752094268798885
    },
    {
        lng: 8.465606689453239,
        lat: 47.75298309326183
    },
    {
        lng: 8.46660327911377,
        lat: 47.75374603271496
    },
    {
        lng: 8.467126846313533,
        lat: 47.75447463989258
    },
    {
        lng: 8.467486381530762,
        lat: 47.755336761474666
    },
    {
        lng: 8.468186378479004,
        lat: 47.75756072998058
    },
    {
        lng: 8.468806266784668,
        lat: 47.758827209472884
    },
    {
        lng: 8.469735145568848,
        lat: 47.76028060913109
    },
    {
        lng: 8.47086143493658,
        lat: 47.76174163818365
    },
    {
        lng: 8.471957206726131,
        lat: 47.762962341308594
    },
    {
        lng: 8.472986221313477,
        lat: 47.763916015625
    },
    {
        lng: 8.473712921142692,
        lat: 47.76451492309582
    },
    {
        lng: 8.474456787109375,
        lat: 47.76496887207037
    },
    {
        lng: 8.475727081298828,
        lat: 47.76532363891613
    },
    {
        lng: 8.477018356323356,
        lat: 47.76586151123047
    },
    {
        lng: 8.477884292602653,
        lat: 47.76641845703142
    },
    {
        lng: 8.47866249084484,
        lat: 47.76706314086914
    },
    {
        lng: 8.479613304138184,
        lat: 47.767620086669865
    },
    {
        lng: 8.480615615844727,
        lat: 47.76795578002941
    },
    {
        lng: 8.481325149536246,
        lat: 47.76822280883789
    },
    {
        lng: 8.481943130493164,
        lat: 47.76863479614258
    },
    {
        lng: 8.482382774353141,
        lat: 47.76927185058594
    },
    {
        lng: 8.482865333557129,
        lat: 47.76990890502924
    },
    {
        lng: 8.483643531799316,
        lat: 47.77054977416992
    },
    {
        lng: 8.48451137542719,
        lat: 47.7710800170899
    },
    {
        lng: 8.485507965087947,
        lat: 47.771556854248104
    },
    {
        lng: 8.486422538757381,
        lat: 47.77197265625
    },
    {
        lng: 8.487629890441951,
        lat: 47.772480010986555
    },
    {
        lng: 8.488417625427303,
        lat: 47.77289199829107
    },
    {
        lng: 8.489027023315543,
        lat: 47.77326965332031
    },
    {
        lng: 8.49007701873785,
        lat: 47.77205657958996
    },
    {
        lng: 8.490949630737418,
        lat: 47.771682739257926
    },
    {
        lng: 8.49437046051031,
        lat: 47.77107620239258
    },
    {
        lng: 8.49597263336193,
        lat: 47.77069854736334
    },
    {
        lng: 8.498047828674373,
        lat: 47.77181625366205
    },
    {
        lng: 8.498313903808594,
        lat: 47.77249526977562
    },
    {
        lng: 8.500463485717887,
        lat: 47.773109436035156
    },
    {
        lng: 8.502137184143066,
        lat: 47.77360916137718
    },
    {
        lng: 8.503345489501953,
        lat: 47.77315521240257
    },
    {
        lng: 8.504421234130973,
        lat: 47.77360153198242
    },
    {
        lng: 8.504924774169922,
        lat: 47.77378845214872
    },
    {
        lng: 8.505642890930119,
        lat: 47.77392196655268
    },
    {
        lng: 8.50817584991455,
        lat: 47.77411270141596
    },
    {
        lng: 8.50894832611084,
        lat: 47.77421951293951
    },
    {
        lng: 8.509361267089844,
        lat: 47.77446365356474
    },
    {
        lng: 8.510217666625977,
        lat: 47.776161193847656
    },
    {
        lng: 8.511482238769531,
        lat: 47.775691986083984
    },
    {
        lng: 8.512439727783317,
        lat: 47.77513885498058
    },
    {
        lng: 8.513643264770508,
        lat: 47.774616241455135
    },
    {
        lng: 8.51443862915039,
        lat: 47.774276733398665
    },
    {
        lng: 8.514719963073844,
        lat: 47.7741584777832
    },
    {
        lng: 8.515835762023983,
        lat: 47.77359771728544
    },
    {
        lng: 8.51630687713623,
        lat: 47.77330780029297
    },
    {
        lng: 8.517005920410156,
        lat: 47.77257156372082
    },
    {
        lng: 8.51744651794445,
        lat: 47.77216339111328
    },
    {
        lng: 8.518021583557186,
        lat: 47.77144622802757
    },
    {
        lng: 8.518619537353516,
        lat: 47.77106475830101
    },
    {
        lng: 8.519450187683162,
        lat: 47.770568847656364
    },
    {
        lng: 8.52013206481945,
        lat: 47.770492553711165
    },
    {
        lng: 8.5203275680542,
        lat: 47.77157211303722
    },
    {
        lng: 8.520476341247615,
        lat: 47.7718391418457
    },
    {
        lng: 8.520763397216854,
        lat: 47.7721519470216
    },
    {
        lng: 8.520998954773063,
        lat: 47.77244186401367
    },
    {
        lng: 8.521245002746696,
        lat: 47.772899627685604
    },
    {
        lng: 8.521454811096248,
        lat: 47.77338790893572
    },
    {
        lng: 8.52154636383068,
        lat: 47.77375793457037
    },
    {
        lng: 8.521728515624943,
        lat: 47.77405929565441
    },
    {
        lng: 8.52209186553955,
        lat: 47.77423477172857
    },
    {
        lng: 8.522485733032283,
        lat: 47.774524688720874
    },
    {
        lng: 8.522721290588322,
        lat: 47.774814605713004
    },
    {
        lng: 8.522884368896541,
        lat: 47.77513885498058
    },
    {
        lng: 8.523137092590446,
        lat: 47.775440216064396
    },
    {
        lng: 8.523234367370662,
        lat: 47.77566909790056
    },
    {
        lng: 8.523579597473088,
        lat: 47.77585220336914
    },
    {
        lng: 8.5240478515625,
        lat: 47.776065826416016
    },
    {
        lng: 8.524408340454215,
        lat: 47.77630996704124
    },
    {
        lng: 8.52490520477295,
        lat: 47.77667617797874
    },
    {
        lng: 8.525320053100529,
        lat: 47.77689743041998
    },
    {
        lng: 8.525610923767147,
        lat: 47.777126312256144
    },
    {
        lng: 8.525671005249137,
        lat: 47.7773895263673
    },
    {
        lng: 8.525839805603084,
        lat: 47.777584075927905
    },
    {
        lng: 8.526117324829215,
        lat: 47.77770614624052
    },
    {
        lng: 8.526322364807243,
        lat: 47.777866363525504
    },
    {
        lng: 8.526482582092399,
        lat: 47.778133392333984
    },
    {
        lng: 8.527184486389217,
        lat: 47.7779426574707
    },
    {
        lng: 8.52778530120861,
        lat: 47.77791595459007
    },
    {
        lng: 8.5285263061524,
        lat: 47.77790451049816
    },
    {
        lng: 8.529564857483024,
        lat: 47.77797317504883
    },
    {
        lng: 8.530498504638729,
        lat: 47.7779998779298
    },
    {
        lng: 8.53162956237793,
        lat: 47.777961730957145
    },
    {
        lng: 8.532636642456112,
        lat: 47.77798461914091
    },
    {
        lng: 8.534377098083553,
        lat: 47.778930664062614
    },
    {
        lng: 8.535678863525447,
        lat: 47.779640197754134
    },
    {
        lng: 8.540755271911735,
        lat: 47.78147506713873
    },
    {
        lng: 8.541230201721191,
        lat: 47.78149795532238
    },
    {
        lng: 8.543608665466422,
        lat: 47.781612396240234
    },
    {
        lng: 8.545857429504508,
        lat: 47.78264617919922
    },
    {
        lng: 8.548786163330078,
        lat: 47.78311920166027
    },
    {
        lng: 8.549542427062988,
        lat: 47.78341293334972
    },
    {
        lng: 8.55010795593256,
        lat: 47.78363418579113
    },
    {
        lng: 8.551536560058594,
        lat: 47.78419113159208
    },
    {
        lng: 8.552648544311523,
        lat: 47.78462600708008
    },
    {
        lng: 8.55654621124279,
        lat: 47.78353500366234
    },
    {
        lng: 8.559259414672908,
        lat: 47.78204727172857
    },
    {
        lng: 8.561762809753475,
        lat: 47.78043746948259
    },
    {
        lng: 8.563213348388615,
        lat: 47.78059005737299
    },
    {
        lng: 8.563101768493596,
        lat: 47.779384613037166
    },
    {
        lng: 8.562531471252555,
        lat: 47.777854919433594
    },
    {
        lng: 8.565983772277832,
        lat: 47.7783317565918
    },
    {
        lng: 8.566295623779297,
        lat: 47.778026580810774
    },
    {
        lng: 8.567236900329647,
        lat: 47.778480529785384
    },
    {
        lng: 8.568642616272086,
        lat: 47.77915954589838
    },
    {
        lng: 8.572003364563102,
        lat: 47.78007888793945
    },
    {
        lng: 8.57462596893322,
        lat: 47.78066635131859
    },
    {
        lng: 8.577303886413517,
        lat: 47.781707763671875
    },
    {
        lng: 8.576464653015194,
        lat: 47.78286743164085
    },
    {
        lng: 8.576356887817383,
        lat: 47.78330612182634
    },
    {
        lng: 8.57615184783947,
        lat: 47.784141540527344
    },
    {
        lng: 8.575920104980469,
        lat: 47.78508377075218
    },
    {
        lng: 8.575018882751522,
        lat: 47.7862625122072
    },
    {
        lng: 8.574398994445858,
        lat: 47.78707504272455
    },
    {
        lng: 8.574691772460994,
        lat: 47.78850555419916
    },
    {
        lng: 8.574862480163574,
        lat: 47.789337158203125
    },
    {
        lng: 8.574998855590763,
        lat: 47.78962326049805
    },
    {
        lng: 8.572101593017635,
        lat: 47.790599822998274
    },
    {
        lng: 8.568651199340934,
        lat: 47.79109573364258
    },
    {
        lng: 8.564369201660213,
        lat: 47.792289733887
    },
    {
        lng: 8.563707351684684,
        lat: 47.79243469238287
    },
    {
        lng: 8.562656402587834,
        lat: 47.79187011718756
    },
    {
        lng: 8.561915397644043,
        lat: 47.792747497558764
    },
    {
        lng: 8.562049865722656,
        lat: 47.79335784912132
    },
    {
        lng: 8.562365531921444,
        lat: 47.79479598999046
    },
    {
        lng: 8.562312126159668,
        lat: 47.79709625244135
    },
    {
        lng: 8.562374114990291,
        lat: 47.799858093261946
    },
    {
        lng: 8.562409400939941,
        lat: 47.80142211914074
    },
    {
        lng: 8.563511848449764,
        lat: 47.805316925048885
    },
    {
        lng: 8.56590461730957,
        lat: 47.806575775146655
    },
    {
        lng: 8.567852020263672,
        lat: 47.80848312377947
    },
    {
        lng: 8.56886005401617,
        lat: 47.80724716186535
    }
]
export default switzerlandCoordinates;