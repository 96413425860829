import React, {useState, useEffect} from 'react';
import {Table} from 'antd';
import axiosInstance from "services/axios";

const ToDoInfo = (props) => {

  const [data, setData] = useState(undefined);
  const columns = [
    {
      title: 'Vorfall',
      dataIndex: 'event_id',
      key: 'event_id',
    },
    {
      title: 'Priorität',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Kommentar',
      dataIndex: 'comment',
      key: 'comment',
      width: 200,
    },
    {
      title: 'Erstellt von',
      dataIndex: 'editor',
      key: 'editor',
    },
    {
      title: 'Erstellt am',
      dataIndex: 'created_datetime',
      key: 'created_datetime',
    },
    {
      title: 'Bearbeitet am',
      dataIndex: 'modified_datetime',
      key: 'modified_datetime',
    },
  ]

  useEffect(() => {
    axiosInstance.get('./api/incidents/todo_logging')
      .then(function (response) {
        setData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        setData(undefined);
      });
  }, [props.count]);

  return (
    <>
      <Table
        size='small'
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 680 }}
      />
    </>
  );
};

export default ToDoInfo;