import React, {useEffect, useState, useRef} from 'react';
import {Column, DualAxes} from '@ant-design/charts';
import {notification, Typography} from "antd";
import axiosInstance from "../../services/axios";

const {Paragraph, Text} = Typography;
const {Title} = Typography;

const SmallChart = (props) => {
    const [config, setConfig] = useState(null);

    const handleClick = (params) => {
        if (props.handleDriverClick) {
            props.handleDriverClick(params)
        }
    };

    useEffect(() => {
        if (props.data) {

            const seriesColors = {
                cautious: 'rgba(50,161,221,1)',
                normal: 'rgba(50,147,125,1)',
                aggressive: 'rgba(229,85,85,1)',
            };

            const seriesFieldValues = ['cautious', 'normal', 'aggressive'];
            const colorMapping = {};
            seriesFieldValues.forEach((type) => {
                colorMapping[type] = seriesColors[type];
                colorMapping[`${type}_no_click`] = `${seriesColors[type].replace('1)', '0.2)')}`;
            });

            setConfig({
                    data: [props.data, []],
                    xField: 'driver',
                    yField: ['value', 'value'],
                    geometryOptions: [
                        {
                            geometry: 'column',
                            isStack: true,
                            seriesField: 'type',
                            color: (datum) => colorMapping[datum.type],
                            columnWidthRatio: 0.65,
                        },
                        {
                            geometry: 'line',
                            lineStyle: {
                                stroke: 'black', // Set the line color to black
                                lineDash: [4, 4], // Set the line to be dotted
                            },
                        },
                    ],
                    xAxis: {
                        label: {
                            formatter: (text) => {
                                return text.includes('-') ? text.split('-')[0] : text;
                            },
                        },
                    },
                    yAxis: [
                        {
                            max: 100,
                            label: {
                                formatter: (value) => `${value}%`,
                            },
                        },
                        {
                            max: 100,
                            min: 0,
                            visible: false,
                        },
                    ],
                    tooltip: {
                        formatter: (datum) => {
                            return {
                                name:
                                    datum.type ?
                                        datum.type[0].toUpperCase() + datum.type.slice(1) :
                                        'Average ' + props.sortValue[0].toUpperCase() + props.sortValue.slice(1) + ' %',
                                value: `${(datum.value).toFixed(1)}%`, // Format the tooltip value as a percentage
                            };
                        },
                    },
                    legend: false, // You can adjust the legend position
                    onReady: (plot) => {
                        plot.on('element:click', handleClick)
                    }
                }
            );

            props.setLoadingPlot(false)
        }
    }, [props.data]);

    return (<>
        <div style={{height: 200, width: '100%', marginTop: 0}}>
            {config && !props.loadingPlot &&
                <DualAxes
                    {...config}
                />
            }
        </div>
    </>)
};

export default SmallChart;