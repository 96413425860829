import React from 'react';
import {Image} from 'antd';

// Define the MultiImageRenderer component
const MultiImageRendererV2 = ({value}) => {
    // Parse the string value to an array
    const imageArray = value ? JSON.parse(value) : [];

    // Define the maximum number of images to show
    const maxImagesToShow = 7;

    return (
        <Image.PreviewGroup
            preview={{
                onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
            }}
        >
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(25px, 1fr))',
                gap: '8px', // Adjust the gap between images as needed
            }}>
                {imageArray.slice(0, maxImagesToShow).map((src, index) => {
                    const isPDF = src.toLowerCase().includes(".pdf");
                    return isPDF ? (
                        <a key={index} href={src} download style={{ textAlign: 'center' }}>
                            PDF
                        </a>
                    ) : (
                        <Image
                            key={index}
                            width="100%"
                            height="auto"
                            src={src}
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                    );
                })}
                {imageArray.length > maxImagesToShow && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        gridColumn: 'span 1',
                    }}>
                        ...
                    </div>
                )}
            </div>

            {/* Hidden images to include them in the preview group */}
            {imageArray.slice(maxImagesToShow).map((src, index) => {
                const isPDF = src.toLowerCase().includes(".pdf");
                return isPDF ? (
                    <a key={maxImagesToShow + index} href={src} download style={{ display: 'none' }}>
                        PDF
                    </a>
                ) : (
                    <Image
                        key={maxImagesToShow + index}
                        width="0"
                        height="0"
                        src={src}
                        style={{ display: 'none' }}
                    />
                );
            })}
        </Image.PreviewGroup>
    );
};

export default MultiImageRendererV2