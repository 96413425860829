import React, { useEffect, useState } from "react";

import moment from "moment";
import LoaderOverlay from "../../LoaderOverlay/LoaderOverlay";
import axiosInstance from "services/axios";

// Configurations
moment.updateLocale(moment.locale("de"), { invalidDate: "unbekannt" });

const InfoCardGenericType = (props) => {
  const { eventId } = props;
  const [state, setState] = useState();

  const getEventDetailGenericType = (eventId) => {
    return axiosInstance.post(
      './api/incidents/map/event_card',
      {event_id: eventId},
    );
  };

  useEffect(() => {
    getEventDetailGenericType(eventId).then(({ data }) => setState(data));
  }, [eventId]);

  const isLoading = !state || !state.data || !state.data.length;

  const loader = (
    <div style={{ height: "12rem" }}>
      <LoaderOverlay visible={true} />;
    </div>
  );

  return isLoading ? (
    loader
  ) : (
    <>
      <div
        className="d-flex flex-column justify-content-between"
        style={{ height: "100%" }}
      >
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ fontSize: "20px", paddingLeft: "15px"}}
        >
          <h6 className="mb-0">
            {state.title}
            <br />
            {state.subtitle}
          </h6>
        </div>
        <div>
          <ul className="fa-ul mt-4">
            {state.data?.map(({ name, value, icone }, index) => (
              <li className="my-3" key={index}>
                <span className="fa-li">
                  <i className={`fa-lg ${icone}`}></i>
                </span>
                <p style={{ color: "#484848" }} className="mb-0 pl-2">
                  {value || "-"}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default InfoCardGenericType;
