import React, {useState, useEffect, useRef, useContext} from 'react';
import {Col, notification, Table, Row, Card, Typography, Tooltip, Input, Button, DatePicker, Popover} from 'antd';
import axiosInstance from "services/axios";
import {AgGridReact} from "ag-grid-react";
import moment from "moment";
import dayjs, {Dayjs} from "dayjs";
import {
    customDateComparator,
    dateTimeFormatter,
    customDateRenderer,
    formatDateToLocal,
    formatArrayOfObjects,
    iconCellRenderer,
    customTimeRenderer,
    customDeltaComparator, detailsFormatter
} from "./TableHelpers";
import "./custom.css"
import "./../../common_components/AGGrid/carvaloo_theme.css";

import {InboxOutlined, InfoCircleOutlined, ColumnWidthOutlined} from "@ant-design/icons";
import filterIcon from '../../assets/icons/filter.svg';
import checkIcon from "../../assets/icons/check.svg";
import {SettingsContext} from "../../App.js";

import {convertKilometersToMiles, convertMilesToKilometers} from '../../utility/conversions.js';

const {RangePicker} = DatePicker;

const {Search} = Input;

const DriverTable = (props) => {

    const {timezone, distance} = useContext(SettingsContext);
    const gridRef = useRef();
    const [renderKey, setRenderKey] = useState(0);

    let emptyRowData = [
        {box_id: 'empty'},
    ];

    // const handleDateRangeChange = (data) => {
    //     setTableDateRange(data)
    // }

    const InfoTooltip = ({text}) => {
        return (
            <Tooltip title={text}>
                <InfoCircleOutlined style={{color: '#1890ff', fontSize: '12px', marginLeft: '5px'}}/>
            </Tooltip>
        );
    };

    const distanceFormatter = (params) => {
        if (params.value) {
            const convertedValue = convertKilometersToMiles(params.value, params.context?.distance, 0);
            const distanceUnit = params.context?.distance === 'kilometers' ? 'km' : 'miles';
            return `${convertedValue} ${distanceUnit}`;
        } else {
            return null;
        }
    };

    useEffect(() => {
        selectRowByDriverId(props.selectedDriver);
    }, [props.selectedDriver]);

    const selectRowByDriverId = (driverId) => {
        if (!gridRef.current.api) return;

        gridRef.current.api.forEachNode((node) => {
            if (node.data.driver_id === driverId) {
                node.setSelected(true);
                const pageSize = 10;
                const rowIndex = node.rowIndex;
                const pageNumber = Math.floor(rowIndex / pageSize);
                gridRef.current.api.paginationGoToPage(pageNumber);
            } else {
                node.setSelected(false);
            }
        });
    };

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        // Check if any rows are selected
        if (selectedRows.length > 0) {
            const selectedRowData = selectedRows[0]; // Assuming you want to work with the first selected row
            props.setSelectedDriver(selectedRowData.driver_id);
        } else {
            props.setSelectedDriver(null);
        }
    };

    function EventURLRenderer(dataArray) {
        if (dataArray) {
            const urlBase = "https://webapp.carvaloo.com/ereignisse"
            const links = dataArray.map(item => {
                const url = `${urlBase}/${item}`;
                return (
                    <React.Fragment key={item}>
                        <a href={url} target="_blank" rel="noopener noreferrer">{item}</a>
                        <br/>
                    </React.Fragment>
                );
            });

            return <div>{links}</div>;
        } else {
            return <div> None </div>;
        }
    }

    const AnomalyRenderer = ({title = '', ...props}) => {
        const container = EventURLRenderer(props.data.event_id)

        if (props.data.event_id) {
            return (
                <Popover
                    title={title}
                    trigger="click"
                    content={container}
                >
                    <Button>{props.data.UAID}</Button>
                </Popover>
            )
        } else {
            return <> - </>
        }
    }

    useEffect(() => {
        if (gridRef.current.api) {
            gridRef.current.api.refreshCells({force: true});
        }
    }, [distance]);

    // Define your column definition with the custom cell renderer
    const columnDefs = [
        {
            checkboxSelection: true,
            headerCheckboxSelection: true,
            maxWidth: 50
        },
        // {
        //     field: 'rank',
        //     headerName: 'Rank',
        // },
        {
            field: 'driver_id',
            headerName: 'Driver ID',
            filter: 'agTextColumnFilter',
            minWidth: 225,
            // rowGroup: true,
            // floatingFilter: true,
        },
        // {
        //     field: 'driving_style',
        //     headerName: 'Behaviour',
        //     // filter: 'agTextColumnFilter',
        //     // floatingFilter: true,
        // },
        {
            field: 'total_distance',
            headerName: 'Distance',
            valueFormatter: distanceFormatter,
        },
        {
            field: 'total_driving',
            headerName: 'Time',
            // maxWidth: 90,
            valueFormatter: (params) => {
                const totalMinutes = params.value * 60;
                const days = Math.floor(totalMinutes / (60 * 24));
                const remainingHours = totalMinutes % (60 * 24);
                const hours = Math.floor(remainingHours / 60);
                const minutes = Math.round(remainingHours % 60);

                let durationStr = '';
                if (days > 0) {
                    durationStr += `${days}d `;
                }
                if (hours > 0 || days > 0) {
                    durationStr += `${hours}h `;
                }
                durationStr += `${minutes}m`;

                return durationStr;
            },
        },
        {
            field: 'trip_count',
            headerName: 'Trips',
        },
        {
            field: 'reservation_count',
            headerName: 'Reservations',
        },
        {
            field: 'trips_reservations',
            headerName: 'Trips/Reservation',
            hide: true,
            cellRenderer: params => {
                if (params.value || params.value === 0) {
                    return `${(params.value).toFixed(1)}`;
                } else {
                    return null;
                }
            },
        },
        {
            field: 'style_ratio_aggressive',
            headerName: 'Aggressive %',
            sort: true,
            cellRenderer: params => {
                if (params.value || params.value === 0) {
                    return `${(params.value * 100).toFixed(1)}%`;
                } else {
                    return null;
                }
            },
            // filter: 'agNumberColumnFilter',
        },
        {
            field: 'style_ratio_normal',
            headerName: 'Normal %',
            cellRenderer: params => {
                if (params.value || params.value === 0) {
                    return `${(params.value * 100).toFixed(1)}%`;
                } else {
                    return null;
                }
            },
            // filter: 'agNumberColumnFilter',
        },
        {
            field: 'style_ratio_cautious',
            headerName: 'Cautious %',
            cellRenderer: params => {
                if (params.value || params.value === 0) {
                    return `${(params.value * 100).toFixed(1)}%`;
                } else {
                    return null;
                }
            },
            // filter: 'agNumberColumnFilter',
        },
        {
            field: 'vehicle_count',
            headerName: 'Unique Vehicles',
        },
        {
            field: 'vehicle_type_count',
            headerName: 'Vehicle Types',
        },
        {
            field: 'UAID',
            headerName: 'Damage Events',
            cellRenderer: AnomalyRenderer,
            cellRendererParams: {
                title: 'WebApp Events'
            }
        },
    ]

    const [tableHeight, setTableHeight] = useState(600);


    const [gridOptions, setGridOptions] = useState({
        rowData: emptyRowData,
        rowSelection: 'single',
        suppressRowClickSelection: true,
        enableRangeSelection: false,
        columnDefs: columnDefs,
        rowHeight: 40,
        // getContextMenuItems: getContextMenuItems,
        pagination: true,
        paginationPageSize: 10, // Number of rows per page
        // onPaginationChanged: onPaginationChanged,
        // components: {
        //     eventUrlRenderer: EventUrlRenderer
        // },
        defaultColDef: {
            width: 100,
            sortable: true,
            filter: false,
            resizable: true, //minWidth: 100,
            editable: false, //cellEditor: PopupCellEditor
            suppressMenu: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: {fontSize: '14px'}
        },
        onGridReady: (event) => event.api.sizeColumnsToFit(),
        onSelectionChanged: onSelectionChanged,
        // getRowStyle: function (params) {
        //     if (params.data && params.data.action && params.data.action === 'firing') {
        //         return {
        //             background: 'rgba(250,80,42,0.2)', // Set your desired background color for pinned row
        //         };
        //     }
        //     if (params.data && params.data.action && params.data.action === 'pending') {
        //         return {
        //             background: 'rgba(250,205,42,0.2)', // Set your desired background color for pinned row
        //         };
        //     }
        //     return null;
        // },
    })

    useEffect(() => {
        const payload = {
            customer: props.customer,
            date_start: props.dateRangeFilter[0],
            date_end: props.dateRangeFilter[1],
            sort_value: props.sortValue,
            cat_value: props.categoryValue,
            min_distance: convertMilesToKilometers(props.minDistance, distance),
            min_trip_count: props.minTripCount,
            min_reservation_count: props.minReservationCount
        };

        const fetchData = async () => {
            try {
                const response = await axiosInstance.post('/api/prevention/table', payload);
                gridRef.current.api.setRowData(response.data.data);
            } catch (error) {
                console.log(error);
                notification['error']({message: 'Error retreiving alerts', description: error.message});
                gridRef.current?.api?.setRowData(emptyRowData);
            }
        };

        if (gridRef.current) {
            fetchData();
        }

    }, [props.dateRangeFilter, props.sortValue, props.categoryValue, props.minDistance, props.minTripCount, props.minReservationCount, distance]);

    const onFilterSearch = (search) => {
        console.log(search)
        if (gridRef.current && gridRef.current.api) {
            if (search) {
                console.log(gridRef.current.api.getFilterModel())
                const currentFilterModel = {
                    driver_id: {
                        filterType: "text",
                        filter: search,
                        type: "contains"
                    }
                }
                gridRef.current.api.setFilterModel(currentFilterModel);
            } else {
                gridRef.current.api.setFilterModel();
            }
        }
    }

    const onResizeColumns = () => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.sizeColumnsToFit()
        }
    }

    return (<>
            <Card
                style={{
                    width: '100%',
                    height: tableHeight,
                    minHeight: 350,
                    boxSizing: 'border-box',
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #e8e8e8'
                }}
                title={'Driver Overview'}
                extra={
                    <>
                        {/*<RangePicker*/}
                        {/*    style={{width: 300, marginRight: 32}}*/}
                        {/*    // presets={rangePresets}*/}
                        {/*    defaultValue={[dayjs().add(-30, 'd'), dayjs()]}*/}
                        {/*    allowClear={false}*/}
                        {/*    onChange={handleDateRangeChange}*/}
                        {/*/>*/}
                        <Search
                            placeholder="Search Driver ID"
                            onSearch={onFilterSearch}
                            allowClear={true}
                            size={'medium'}
                            style={{width: 200, marginRight: 32}}
                        />
                        <Button
                            icon={<ColumnWidthOutlined style={{color: "gray"}}/>}
                            style={{
                                height: 32,
                            }}
                            onClick={onResizeColumns}
                        />
                    </>
                }
                styles={{ body: {
                    padding: 0
                }}}>
                <div
                    style={{width: '100%', height: tableHeight - 85, minHeight: 280}}
                >
                    <AgGridReact
                        key={renderKey}
                        ref={gridRef}
                        context={{distance}}
                        gridOptions={gridOptions}
                        className="ag-theme-alpine"
                    />
                </div>
            </Card>

        </>
    );
};

export default DriverTable;