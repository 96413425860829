import React, {useState, useRef, useCallback, useEffect} from 'react';
import ReactJson from "react-json-view";
import {
    Badge,
    Button,
    Card,
    Descriptions,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Popover,
    Switch,
    Table,
    Divider, Statistic, Tooltip
} from 'antd';

import Icon from "@ant-design/icons";
import {FaFile, FaCog} from 'react-icons/fa';
import axiosInstance from "services/axios";
import Dashboard from "./Dashboard";
import TrendIndicator from "../TrendIndicator/TrendIndicator";
import {InfoCircleOutlined} from '@ant-design/icons';
import TinyChart from "./TinyChart";

const {Search} = Input;
const {Title, Text} = Typography;

const DashboardCard = (props) => {

    // const data = props.data

    const InfoTooltip = ({text}) => {
        return (
            <Tooltip title={text}>
                <InfoCircleOutlined style={{color: '#1890ff', fontSize: '12px', marginLeft: '5px'}}/>
            </Tooltip>
        );
    };

    const handleClick = () => {
        props.handleCardClick(props.card)
    };

    const getOpacity = () => {

        let opacity = null

        if (props.issueFilter && props.issueFilter !== props.card) {
            opacity = 0.1
        }
        return (opacity)
    }

    return (<>
            <Card
                style={{
                    width: 300,
                    height: 216,
                    boxSizing: 'border-box',
                    boxShadow: '0px 2px 4px 0px rgba(0, 35, 11, 0.20)',
                    marginRight: 24,
                }}
                styles={{ body: {
                    paddingRight: 24,
                    paddingLeft: 24,
                    paddingTop: 10,
                    paddingBottom: 10,
                    opacity: getOpacity()
                }}}
                onClick={handleClick} // Add this event handler
                title={
                    <>
                        <img src={props.icon} alt={props.title} width="16" height="16" style={{marginBottom: '-3px'}}/>
                        <Text style={{marginLeft: 4}}>
                            {props.title}
                        </Text>
                        <InfoTooltip text={props.tooltip}/>
                    </>
                }
            >
                <Row style={{flex: 1, marginLeft: 16, marginTop: 12, marginBottom: 24}}>
                {/*<Row style={{flex: 1, marginLeft: 16, marginTop: 16, marginBottom: 12}}>*/}
                    <Col>
                        <Statistic title="Total" value={props.total}/>
                        {/*<TrendIndicator value={props.trend} text="Monthly"/>*/}
                    </Col>
                    <Divider
                        type="vertical"
                        style={{borderLeft: '1px solid lightgray', height: 50, marginLeft: 20, marginRight: 20}}
                    />
                    <Col>
                        <Statistic title="Active" value={props.active} style={{fontWeight: 'bold'}}/>
                    </Col>
                    <Divider
                        type="vertical"
                        style={{borderLeft: '1px solid lightgray', height: 50, marginLeft: 20, marginRight: 20}}
                    />
                    <Col>
                        <Statistic title="Resolved" value={props.total - props.active}/>
                    </Col>
                </Row>
                <Row>
                   <TinyChart data={props.trend}/>
                </Row>
            </Card>
        </>
    );
}


export default DashboardCard;
