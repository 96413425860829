import {Button, Card, Col, Image, notification, Row, Statistic, Typography, Divider} from 'antd';

import React, {useState, useEffect} from 'react';
import axiosInstance from "services/axios";
import Icon, {InboxOutlined} from "@ant-design/icons";
import {FaSearch} from "react-icons/fa";
import Logo from "../../assets/images/mobility_small.png";
import TriggerEventButton from "./TriggerEventButton";
import ToDoInfoModal from "../DashboardCarvaloo/ToDoInfoModal";

const {Text} = Typography;

const Dashboard = (props) => {
    const [data, setData] = useState(null);
    const [toDoModalOpen, setToDoModalOpen] = useState(false);
    const [toDoOpenCount, setToDoOpenCount] = useState(0);

    const fleetRegion = props.fleetRegion
    const dateRange = props.dateRange

    const showToDoModal = () => {
        setToDoOpenCount(toDoOpenCount + 1)
        setToDoModalOpen(true);
    }

    const getDashboardValues = () => {
        axiosInstance.post(
            './api/incidents/dashboard',
            {
                fleet_region: fleetRegion,
                date: dateRange,
            })
            .then(res => {
                setData(res.data)
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error in getDashboardValues',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    useEffect(() => {
        getDashboardValues();
    }, [fleetRegion, dateRange]);

    return (<>
            <ToDoInfoModal
                showModal={() => setToDoModalOpen(true)}
                hideModal={() => setToDoModalOpen(false)}
                open={toDoModalOpen}
                openCount={toDoOpenCount}
            />
            <Card
                style={{
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8',
                    marginBottom: '8px',
                }}
                styles={{
                    body:{
                    padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"
                }
                }}
            >
                <Row type="flex" justify="space-between">
                    <Col>
                        <Row gutter={[16, 16]}>
                            <div style={{padding: "0px", width: "60px", marginLeft: "10px", marginTop: "5px"}}>
                                <Image src={Logo} preview={false}/>
                            </div>
                            <Col style={{marginLeft: "25px"}}>
                                <Statistic title="Vorfälle" value={data?.cases}/>
                            </Col>
                            <Col>
                                <Statistic title="Neuschaden" value={data?.cases_newdamage}/>
                            </Col>
                            <Divider style={{marginBottom: "4px", marginTop: "4px", borderLeft: '1px solid black'}}
                                     type="vertical"/>
                            <Col>
                                <Statistic title="Neu" value={data?.cases_new}/>
                            </Col>
                            <Col>
                                <Statistic title="Pendent" value={data?.cases_pending}/>
                            </Col>
                            <Col>
                                <Statistic title="Abgeschlossen" value={data?.cases_closed}/>
                            </Col>
                            <Divider style={{marginBottom: "4px", marginTop: "4px", borderLeft: '1px solid black'}}
                                     type="vertical"/>
                            <Col>
                                <Statistic title="Verursacher" value={data?.cases_notified_a}/>
                            </Col>
                            <Col>
                                <Statistic title="Nachfolger" value={data?.cases_notified_b}/>
                            </Col>
                            <Col>
                                <Statistic title="Arbeitsgruppe" value={data?.cases_notified_c}/>
                            </Col>
                        </Row>
                    </Col>
                    {(props.permissions?.incident === "incidentApp_admin") &&
                        <Col>
                            <Row type="flex" justify="end">
                                <Col>
                                    <Button
                                        onClick={showToDoModal}
                                        style={{marginLeft: '10px', marginTop: '16px'}}
                                        icon={<InboxOutlined/>}
                                    >
                                        Notizen
                                    </Button>
                                </Col>
                                <Col style={{marginLeft: 'auto'}}>
                                    <TriggerEventButton
                                        selectedEventId={props.selectedEventId}
                                        handleRefresh={props.onRefreshTableChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
            </Card>
        </>
    );
};

export default Dashboard;