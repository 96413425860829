import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const HomeScreen = () => {
  // const navigate = useNavigate();
  //
  // useEffect(() => {
  //   // Redirect to the incidents page
  //   navigate('/incidents', { replace: true });
  // }, [navigate]);


  // Return any JSX for the HomeScreen component (optional)
  return <div></div>;
};

export default HomeScreen;
