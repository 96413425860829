import React, {useEffect, useState, useContext} from "react";
import {useLocation, Link, useNavigate} from "react-router-dom";
import {
    ConfigProvider,
    Breadcrumb,
    Layout,
    Menu,
    Image,
    Form,
    Switch,
    Typography,
    Popover,
    Dropdown,
    Space,
    message,
    notification,
    Radio,
    Button,
    Row,
    Input, Col, Tabs, Card
} from 'antd';
import {HomeOutlined} from "@ant-design/icons";
import SearchOutlined from "@ant-design/icons/SearchOutlined";

const {Title, Paragraph, Text} = Typography;
const {Search} = Input;

const OverviewCard = (props) => {

    // Return any JSX for the HomeScreen component (optional)
    return (<>
        <div style={{
            maxWidth: 440,
            minWidth: 440,
            backgroundColor: 'white',
            borderRadius: 8,
            boxShadow: '0px 2px 4px 0px rgba(0, 35, 11, 0.20)',
        }}>
            <Col align={'middle'} style={{padding: 24}}>
                {props.icon}
                <Title level={4} style={{margin: 0, marginTop: 24}}>{props.title}</Title>
                <Row style={{marginTop: 24, height: 96, justifyContent: 'center'}}>
                    <Text style={{textAlign: 'center'}}>
                        {props.text}
                    </Text>
                </Row>
                <Button style={{marginTop: 24}} type={'primary'} icon={<SearchOutlined/>}
                        onClick={() => void props.setActiveTab(props.tabKey)}>
                    Details
                </Button>
            </Col>
        </div>
    </>)
};

export default OverviewCard;
