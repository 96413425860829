import React from 'react'

const MarkerCluster = (props) => {
  const {mapLocations, pointCount, mapRef, clusterObj, lat, lng, cluster} = props;

  const style = {
    color: "black",
    backgroundColor: "#088ed7",
    opacity: 0.8,
    ...(mapLocations.length && {
      padding: 10 + (pointCount / mapLocations.length) * 40,
    }),
    borderRadius: 100,
    ...(mapLocations.length && {
      width: 15 + (pointCount / mapLocations.length) * 20,
    }),
    ...(mapLocations.length && {
      height: 15 + (pointCount / mapLocations.length) * 20,
    }),
  }

  const onClickMarkerCluster = () => {
    const expansionZoom = Math.min(
      clusterObj.getClusterExpansionZoom(cluster.id),
      20
    );
    mapRef.current.setZoom(expansionZoom);
    mapRef.current.panTo({
      lat,
      lng,
    });
  }

  return (
    <div
      className="cluster-marker shadow d-flex justify-content-center align-items-center"
      style={style}
      onClick={onClickMarkerCluster}
    >
      <div style={{marginLeft: props.offsetLeft, marginTop: props.offsetTop}}> {pointCount}</div>
    </div>
  )
}

export default MarkerCluster
