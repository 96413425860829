import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';

import {
    Button,
    Card,
    DatePicker, notification, Typography, Tooltip, Row, Col
} from 'antd';
import dayjs, {Dayjs} from "dayjs";

import axiosInstance from "services/axios";
import SimpleMapComponent from "../../components/SimpleMapComponent/SimpleMapComponent";
import {customGoogleMapsStyle} from "../../assets/styles/google-maps-style";
import stationIcon from '../../assets/icons/station_3.svg';
import carIcon from '../../assets/icons/car_black.svg';
import firstWarningIcon from '../../assets/icons/marker_level_1.svg';
import secondWarningIcon from '../../assets/icons/marker_level_2.svg';
import thirdWarningIcon from '../../assets/icons/marker_level_3.svg';
import noneIcon from '../../assets/icons/none.svg';

import {formatArrayOfObjects} from "./TableHelpers";
import MapMarker from "./MapMarker";
import {ExclamationCircleFilled, InfoCircleOutlined} from '@ant-design/icons';
import MapMarkerMisuse from "./MapMarkerMisuse";
import {SettingsContext} from "../../App";

const {RangePicker} = DatePicker;
const {Paragraph, Text} = Typography;

const circleString = `<svg width="800px" height="800px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="iconify iconify--emojione" preserveAspectRatio="xMidYMid meet"><circle cx="32" cy="32" r="30" opacity="0.25" fill="#0071bc"></circle></svg>`;
const circleIconObj = {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(circleString)}`,
    anchorHeight: 10,
    anchorWidth: 10,
    scaledWidth: 20,
    scaledHeight: 20,
    colorAdjust: true,
};

const iconDictionary = {
    warning_1: firstWarningIcon,
    warning_2: secondWarningIcon,
    warning_3: thirdWarningIcon,
    none: noneIcon
};


const WarningMap = (props) => {

    const [forceUpdate, setForceUpdate] = useState(0)
    const [mapData, setMapData] = useState(null);
    const [mapDataSecondary, setMapDataSecondary] = useState(null);
    const [mapDataTracking, setMapDataTracking] = useState(null);
    const [dynamicPolygons, setDynamicPolygons] = useState([]);

    const {mapCenter} = useContext(SettingsContext);

    const InfoTooltip = ({text}) => {
        return (
            <Tooltip title={text}>
                <InfoCircleOutlined style={{color: '#1890ff', fontSize: '16px', marginLeft: '5px'}}/>
            </Tooltip>
        );
    };

    const onSelectedMarkerChange = (marker) => {
        // if (marker) {
        //     props.handleSelectedChange(marker.UAID);
        // } else {
        //     props.handleSelectedChange(null)
        // }
    };

    return (<>
            <Card
                style={{
                    width: '100%',
                    height: 830,
                    flex: '1',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #e8e8e8',
                    marginRight: '16px',
                }}
                styles={{ body: {
                    paddingRight: 24,
                    paddingLeft: 24,
                    paddingTop: 10,
                    paddingBottom: 10,
                }}}
                title={
                    <>
                        Location <InfoTooltip
                        text=
                            "GPS location of the driver warnings"
                    />
                    </>
                }
            >
                {props.mapMarkers &&
                <SimpleMapComponent
                    mapHeight={750}
                    deactivateSelectMode={true}
                    customStyle={customGoogleMapsStyle}
                    center={mapCenter}
                    markers={props.mapMarkers.warnings}
                    markerInfo={MapMarker}
                    iconDictionary={iconDictionary}
                    onSelectedMarkerChange={onSelectedMarkerChange}
                    boundsTertiary={true}
                    markersTertiary={props.mapMarkers.misuses.filter(
                        misuse => misuse.location && misuse.location.lat !== null)}
                    markerIconTertiary={circleIconObj}
                    markerInfoTertiary={MapMarkerMisuse}
                    maxZoomLevel={15}
                    singleZoomLevel={15}
                />
                }
            </Card>
        </>
    );
}

export default WarningMap;