export function getCVTheme(mainColor, sideBarColor, selectColor) {

    const primaryColor = mainColor ? mainColor : '#088ed7'
    const secondaryColor = selectColor ? selectColor : '#5cb5e5'

    return {
        token: {
            "blue.1": "#daeef9",
            "blue.2": "#b0daf2",
            "blue.3": "#86c8eb",
            "blue.4": "#5cb5e5",
            "blue.5": "#32a1dd",
            "blue.6": "#088ed7",
            "blue.7": "#0776b2",
            "blue.8": "#055e8e",
            "blue.9": "#044669",
            "blue.10": "#032d45",
            "green.1": "#daece8",
            "green.2": "#b0d5cd",
            "green.3": "#86bfb3",
            "green.4": "#5caa98",
            "green.5": "#32937d",
            "green.6": "#087d63",
            "green.7": "#076852",
            "green.8": "#055341",
            "green.9": "#043d31",
            "green.10": "#032820",
            "orange.1": "#feede2",
            "orange.2": "#fcd7c0",
            "orange.3": "#fbc29f",
            "orange.4": "#f9ad7f",
            "orange.5": "#f7975d",
            "orange.6": "#f6823c",
            "orange.7": "#cc6c32",
            "orange.8": "#a25628",
            "orange.9": "#79401d",
            "orange.10": "#4f2a13",
            "red.1": "#ffe4e1",
            "red.2": "#f6c8c8",
            "red.3": "#f2acac",
            "red.4": "#ee8f8f",
            "red.5": "#e97272",
            "red.6": "#e55555",
            "red.7": "#be4747",
            "red.8": "#973838",
            "red.9": "#702a2a",
            "red.10": "#491b1b",
            "yellow.1": "#fff6db",
            "yellow.2": "#fdeab0",
            "yellow.3": "#fddf87",
            "yellow.4": "#fcd45e",
            "yellow.5": "#fac933",
            "yellow.6": "#fabe0a",
            "yellow.7": "#cf9e08",
            "yellow.8": "#a57d07",
            "yellow.9": "#7b5d05",
            "yellow.10": "#503d03",
            colorPrimary: primaryColor,
            borderRadius: 8,
            controlItemBgActive: "#daeef9",
            fontFamily: "Roboto"
        },
        components: {
            Layout: {
                headerBg: sideBarColor || primaryColor,
                triggerBg: sideBarColor || primaryColor,
                lightSiderBg: sideBarColor || primaryColor,
                siderBg: sideBarColor || primaryColor,
            },
            Menu: {
                collapsedIconSize: 16,
                iconSize: 14,
                colorBgContainer: primaryColor,
                itemBg: primaryColor,
                subMenuItemBg: primaryColor,
                itemSelectedBg: secondaryColor,
                darkItemBg: primaryColor,
                darkSubMenuItemBg: primaryColor,
                darkItemSelectedBg: secondaryColor,
            },
            Tabs: {
                paddingSM: 8,
                titleFontSizeLG: 14,
                titleFontSizeSM: 14,
                titleFontSize: 14
            },
            Statistic: {
                marginXXS: 0,
                fontSizeHeading3: 16,
            },
            Carousel: {
                colorBgContainer: primaryColor,
            },
            Steps: {
                descriptionMaxWidth: 250
            }
        }
    }
}
