import React, {useEffect, useState, useContext} from "react";
import {Breadcrumb, Typography, Col, Row, Image} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import axiosInstance from "../../services/axios";

const {Title, Paragraph, Text} = Typography;

const MobileApps = (props) => {

    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoaded = () => {
        setImageLoaded(true);
    };

    useEffect(() => {
        const payload = {page_name: 'help_center_mobile_apps'}
        axiosInstance.post('/api/visit', payload).then(res => {}).catch(error => {})
     }, []);

    return (<>
        <Title style={{margin: 0, marginBottom: 24}} level={1}> Mobile Apps </Title>
        <Row style={{gap: 16, maxWidth: 1200, marginBottom: 24}}>
            <Text>
                We provide three browser-based mobile applications to manage your telematics sensor boxes, make vehicle inspections or give feedback to damage events.
            </Text>
            <Image
                preview={false}
                width={'100%'}
                style={{paddingBottom: imageLoaded ? 0 : '66%'}}
                src={require("../../assets/images/help_center/mobile_apps.png")}
                onLoad={handleImageLoaded}
            />
        </Row>
        <Title id="installation_app" style={{margin: 0, marginBottom: 24}} level={2}> InstallationApp </Title>
        <Row style={{gap: 16, maxWidth: 1200, marginBottom: 24}}>
            <Text>
            The InstallationApp is used for pairing or disconnection of a telematics unit and a vehicle. Technicians can access the app by logging in to <a href="https://installation.carvaloo.com">https://installation.carvaloo.com</a>.<br/>
                It can be used with a smartphone and offers a quick and easy pairing process. The app allows users to scan QR-codes or manually enter BoxID and VIN numbers for installation or deinstallation.
            Successful pairings are recorded in the app's history and confirmation is sent to the user via email. The detailed process for the installation and deinstallation is described below:
            </Text>
            <Col>
                <Title style={{margin: 0, marginBottom: 0}} level={5}> Installation Process </Title>
                <ol style={{margin: 0, marginBottom: 0}}>
                    <li>Select if you want to use your camera or an external scanner and switch to 'Installation'.</li>
                    <li>Scan the QR-code on the telematics unit or manually type in the BoxID.</li>
                    <li>Scan or type in the VIN number of the vehicle which you want to pair with the telematics unit.</li>
                    <li>Select the correct installation variant by comparing the physical installation position in the car with the description and picture shown in the app. To display the picture you have to click on the respective 'Description' button.</li>
                    <li>Double check that the displayed BoxID and VIN you want to pair are correct and confirm the pairing by clicking on 'confirm pairing'.</li>
                    <li>A successful pairing is recorded in a history list. The activation occurs when the sensor first records acceleration data and is confirmed by e-mail to the user who made the pairing.</li>
                </ol>
            </Col>
            <Row style={{paddingLeft: 80, paddingRight: 80, marginBottom: 24}}>
                <Image
                    preview={false}
                    width={'100%'}
                    // style={{maxWidth: 1200}}
                    src={require("../../assets/images/help_center/mobile_apps_installation.png")}
                />
            </Row>
            <Col>
                <Title style={{margin: 0, marginBottom: 0}} level={5}> Deinstallation Process </Title>
                <ol style={{margin: 0, marginBottom: 0}}>
                    <li>Select if you want to use your camera or an external scanner and switch to 'Deinstallation'.</li>
                    <li>Scan the QR-code on the telematics unit or manually type in the BoxID.</li>
                    <li>Scan or type in the VIN number of the vehicle which is paired with the selected telematics unit.</li>
                    <li>Double check that the displayed BoxID and VIN you want to deinstall are correct and click on 'confirm disconnection'.</li>
                </ol>
            </Col>
            <Text>
                If a vehicle and box combination is selected that is not currently paired, the InstallationApp throws an error. 
                A successful deinstallation is also shown in the history at the bottom of the app.
            </Text>
        </Row>
        <Title id="inspection_app" style={{margin: 0, marginBottom: 24}} level={2}> InspectionApp </Title>
        <Col style={{maxWidth: 1200, marginBottom: 15}}>
            <Row style={{marginBottom: 40}}>
                <Text>
                    The InspectionApp is a tool that allows for regular recording of a vehicle's status and the creation of damage cases. <br/>
                    There are two ways to trigger an inspection: either the fleet manager can create an inspection order through the carvaloo WebApp and send it to the technician via e-mail, 
                    or the technician can initiate the inspection on-site by logging in to the <a href="https://inspection.carvaloo.com">https://inspection.carvaloo.com</a> website.
                    The inspection procedure involves uploading pictures of the vehicle and marking any new damages, which are pushed to the WebApp as reactive case automatically after finishing the inspection.
                </Text>
            </Row>
            <Row align="top" style={{gap: 40, maxWidth: 1200, marginBottom: 40}}>
                <Col style={{width: 'calc(100% - 440px)'}}>
                    <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={5}>Option 1: Inspection order triggered by fleet manager </Title></Row>
                    <ol style={{margin: 0, marginBottom: 0}}>
                        <li>The inspection order can be created in the vehicle view in the carvaloo WebApp by clicking on 'Vehicle inspection' in the upper right corner. Afterwards a window to request the assessment will open.</li>
                        <li>Enter the technician's e-mail and click 'send' in the lower right corner to trigger the automated e-mail containing the inspection link.</li>
                        <li>The technician can initiate the inspection using the smartphone or tablet by clicking on the link provided in the received e-mail.</li>
                    </ol>
                </Col>
                <Col flex="400px">
                <Image
                        preview={true}
                        width={'100%'}
                        // style={{maxWidth: 1200}}
                        src={require("../../assets/images/help_center/mobile_apps_inspection.png")}
                        />
                </Col>
            </Row>
            <Row align="top" style={{gap: 40, maxWidth: 1200, marginBottom: 40}}>
                <Col style={{width: 'calc(100% - 440px)'}}>
                    <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={5}>Option 2: Inspection triggered by technician on site </Title></Row>
                    <ol style={{margin: 0, marginBottom: 0}}>
                        <li>Technicians go to the website <a href="https://inspection.carvaloo.com"style={{color: "088ED7"}}>https://inspection.carvaloo.com</a>.</li>
                        <li>If you do not have a user account, you can contact us to create one. Alternatively, you can perform the inspection as described in option 1. In this case no account is required.</li>
                        <li>Technicians can select the vehicle via drop down menu (vehicle ID, licence plate or VIN).</li>
                    </ol>
                </Col>
                <Col flex="400px">
                <Image
                        preview={true}
                        width={'100%'}
                        // style={{maxWidth: 1200}}
                        src={require("../../assets/images/help_center/mobile_apps_inspection_technician.png")}
                        />
                </Col>
            </Row>
            <Row align="top" style={{gap: 40, maxWidth: 1200, marginBottom: 40}}>
                <Col style={{width: 'calc(100% - 440px)'}}>
                    <Row flex="auto"><Title style={{margin: 0, marginBottom: 0}} level={4}>The inspection procedure is described in the following steps: </Title></Row>
                    <ol style={{margin: 0, marginBottom: 0}}>
                        <li>With just a few clicks the technician can upload pictures of the vehicle from the different perspectives (360° documentation). </li>
                        <li>If a new damage is found, a damage event can be created by selecting 'Add event' and marking the damaged car parts. Furthermore a picture of the damage can be added via the camera symbol under 'Other images'.</li>
                        <li>Click on 'Send feedback' to upload the inspection.</li>
                        <li>You will get a confirmation, as soon as the inspection has been uploaded. If a damage was added, a reactive case to carvaloo for investigation is automatically opened.</li>
                    </ol>
                </Col>
                <Col flex="400px">
                <Image
                        preview={true}
                        width={'100%'}
                        // style={{maxWidth: 1200}}
                        src={require("../../assets/images/help_center/mobile_apps_inspection_procedure.png")}
                        />
                </Col>
            </Row>
        </Col>
        
        <Title id="feedback_app" style={{margin: 0, marginBottom: 24}} level={2}> FeedbackApp </Title>
        <Row align="top" style={{gap: 40, maxWidth: 1200, marginBottom: 40}}>
            <Col flex="134px">
                    <Image
                    preview={true}
                    width={'134px'}
                    height={'330px'}
                    // style={{maxWidth: 1200}}
                    src={require("../../assets/images/help_center/mobile_apps_feedback.png")}
                    />
            </Col>
            <Col style={{width: 'calc(100% - 180px)'}}>
                <Row style={{gap: 40, maxWidth: 1200, marginBottom: 20}} flex="auto">
                    <Text>
                        The FeedbackApp can be used by both drivers and technicians. It enables users to provide feedback on existing damage notifications. <br/>
                        The FeedbackApp can be accessed via the feedback link that is sent with each damage notification (email and/or API) or via the event page in the WebApp for each event.
                        The link includes a temporary token which allows to use the FeedbackApp withouth having a user account.
                    </Text>    
                </Row>
                <Row flex="auto">
                    <Text>
                        After clicking on the link, the user is asked to provide feedback about the condition of the vehicle:
                    </Text>
                </Row>
                <ol>
                    <li>Upload pictures of the vehicle from different angles.</li>
                    <li>Mark the damaged car parts if a damage is recognized, otherwise select 'no damage present'.</li>
                    <li>By clicking on "Send feedback", the feedback is pushed to respective event in the carvaloo WebApp.</li>
                </ol>
                <Row style={{gap: 40, maxWidth: 1200, marginBottom: 20}} flex="auto">
                    <Text>
                        The validity of the link is limited and can be customized. By default, the link can be used for up to 30 days after the event and up 4 times in total.
                    </Text>
                </Row>
            </Col>
        </Row>
    </>)
};

export default MobileApps;