import {Breadcrumb, Typography, Col, Row, Image, CollapseProps, Collapse} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import React, {useEffect} from "react";
import axiosInstance from "../../services/axios";

const {Title, Paragraph, Text} = Typography;

const FAQ = (props) => {

    useEffect(() => {
        const payload = {page_name: 'help_center_faq'}
        axiosInstance.post('/api/visit', payload).then(res => {}).catch(error => {})
     }, []);

    const items= [
        {
          key: '1',
          label: 'What is a reactive damage case?',
          children:
          <p>In case of damage to the car which we didn't inform about, there is the possibility to create reactive case, which will open a support ticket on carvaloo side.
              Anomalies with lower damage confidence are also detected by our AI but not send out as event notification.
              Once a reactive case is created, our AI and Data Scientists will match the anomaly that most likely caused the damage to the reactive event.
          </p>,
        },
        {
          key: '2',
          label: 'What is difference between proactive and reactive events?',
          children:
            <p> Proactive events are damage cases which our AI detected and for which we automatically send out the damage notification alert.
                Reactive events are damage cases where the assignment of the causer happens retrospectively after a reactive case has been opened.
            </p>,
        },
        {
          key: '3',
          label: 'From your experience how many damage reports do you expect for a fleet of 100 vehicles per month?',
          children:
            <p>
              For a fleet of 100 vehicles we expect alerts in the range between 5 and 50 per month in total.
                The exact number depends on the drivers and actual vehicle utilization.
            </p>,
        },
        // {
        //   key: '4',
        //   label: 'How fast will we be notified after a damage?',
        //   children:
        //     <p>
        //       Normal alerts are transmitted between 5-15 minutes after an event depending on the network coverage.
        //     </p>,
        // },
        {
          key: '4',
          label: 'How will we be notified about a damage?',
          children:
            <p>
              We send out damage notification alerts via email to all users that have been configured to receive these notifications for a respective fleet or station.
                In addition, we also offer notification via API to an endpoint or webhook.
            </p>,
        },
        {
          key: '11',
          label: 'Do you offer API integration for damage notification and management?',
          children:
            <p>
              Yes, we offer REST APIs for automation and integration of the claims process.
                {/*For details, see section on <a href=xxx>API integration</a>.*/}
            </p>,
        },
        {
          key: '5',
          label: 'Have you experienced any issues with weak or lost connection of the telematics device?',
          children:
            <p>
              There is limited network connection in very deep underground parking garages. However even with no connection the damage detection is still active and the data is transmitted once connection is reestablished.
            </p>,
        },
        {
          key: '6',
          label: 'Do you offer 24/7 Support?',
          children:
            <p>
              We offer support during our office hours.
            </p>,
        },
        {
          key: '7',
          label: 'How long are damage events stored in the WebApp?',
          children:
            <p>
              The damage history is available in the WebApp as long as the project is ongoing or as defined in the Data Processing Agreement.
                Furthermore there is the possibility to export the accident details for every event.
            </p>,
        },
        {
          key: '8',
          label: 'What is the process if the damage is reported, but no actual damage is done, how should we react?',
          children:
            <p>
              All alerts are related to a visible or non-visible damage to the vehicle.
                If you do not find a visible damage to the car, you have the possibility to add this feedback via our WebApp,
                our FeedbackApp or an API interface and the case will be closed.
                We use this feedback to improve our AI, so that we better differentiate between visible and non-visible damages.
            </p>,
        },
        {
          key: '9',
          label: 'What happens if we decide to cancel the service, how would the removal of the device work?',
          children:
            <p>
              The devices would need to be deinstalled from the vehicles and send back to us. Removal of the hardware should take about 10 minutes per vehicle.
            </p>,
        },
        {
          key: '10',
          label: 'How the data collected from our rides would be used if we decide to terminate collaboration?',
          children:
            <p>
              The data will be completely anonymized, so that any relation to a customer is technically impossible.
            </p>,
        },
      ];

    return (<>
        <Title style={{margin: 0, paddingBottom: 24}} level={1}> FAQ </Title>
        <Collapse items={items} defaultActiveKey={['1']}/>
    </>)
};

export default FAQ;
