import {Popover, Button, Checkbox, Form, Input, Typography, notification, Divider, Radio, Select, Card} from 'antd';

import React, {useState, useEffect } from 'react';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import axiosInstance from "services/axios";
import {PlusOutlined} from "@ant-design/icons";
import {useAuth} from "react-oidc-context";

dayjs.extend(utc);

const {Title, Text} = Typography;

const TriggerEventButton = ({...props}) => {

    const [form] = Form.useForm();
    const [editing, setEditing] = useState(false);
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);

    const auth = useAuth();
    const profileRoles = (auth?.user?.profile?.resource_access.incident_frontend?.roles) || []

    let editorOptions = [{value: (auth.user?.profile.name), label: (auth.user?.profile.name)}]
    if (profileRoles.includes("carvaloo")) {
        editorOptions.push({value: 'CarValoo', label: 'CarValoo',});
    }
    if (profileRoles.includes("mobility")) {
        editorOptions.push({value: 'Mobility', label: 'Mobility',});
    }

    useEffect(() => {
        if (props.selectedEventId !== undefined) {
          form.setFieldsValue({ event_id: props.selectedEventId});

          axiosInstance.get('./api/incidents/trigger_event/edit/' + props.selectedEventId)
            .then(res => {

                if (res.data.data) {
                    setEditing(true)
                    form.setFieldsValue({comment: res.data.data.comment});
                    form.setFieldsValue({priority: res.data.data.priority});
                } else{
                    setEditing(false)
                    form.setFieldsValue({comment: null});
                    form.setFieldsValue({priority: null});
                }

            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error', description: error.message});
            })
        }
    }, [props.selectedEventId]);

    const onFinish = (values) => {

        values['editing'] = editing
        axiosInstance.post('./api/incidents/trigger_event', values)
            .then(res => {
                console.log('Success:', res);
                notification['success']({
                    message: 'Notiz hinzugefügt für ' + values.event_id,
                    duration: 10,
                    maxCount: 1
                })
                props.handleRefresh();
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error', description: error.message});
                props.handleRefresh();
            })

        setIsPopoverVisible(false);
    };

    const handleVisibleChange = () => {
        setIsPopoverVisible(!isPopoverVisible);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Popover
            trigger={isPopoverVisible ? "click" : ""}
            placement="right"
            open={isPopoverVisible}
            onOpenChange={handleVisibleChange}
            content={
                <Form
                    form={form}
                    name="basic"
                    labelCol={{span: 5}}
                    wrapperCol={{span: 48}}
                    // style={{ width: 600, height: 500}}
                    style={{width: 400}}
                    initialvalues={{remember: false}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Vorfall"
                        name="event_id"
                        style={{marginBottom: "4px"}}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Kommentar"
                        name="comment"
                        style={{marginBottom: "4px"}}
                    >
                        <Input.TextArea rows={3}/>
                    </Form.Item>

                    <Form.Item
                        label="Priorität"
                        name="priority"
                        valuePropName="checked"
                        initialvalue={'Mittel'}
                        style={{marginBottom: "4px"}}
                    >
                        <Select
                            optionFilterProp="children"
                            options={[
                                {
                                    label: 'Hoch',
                                    value: 'Hoch',
                                },
                                {
                                    label: 'Mittel',
                                    value: 'Mittel',
                                },
                                {
                                    label: 'Niedrig',
                                    value: 'Niedrig',
                                },
                                {
                                    label: 'Irrelevant',
                                    value: 'Irrelevant',
                                },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        name="todo"
                        valuePropName="checked"
                        initialvalue={null}
                        style={{marginBottom: "4px"}}
                    >
                        {/*<Checkbox style={{color: 'red', marginLeft: "85px"}}>*/}
                        {/*    ToDo markieren*/}
                        {/*</Checkbox>*/}
                    </Form.Item>

                    {/*<Form.Item*/}
                    {/*    name="private"*/}
                    {/*    valuePropName="checked"*/}
                    {/*    initialvalue={null}*/}
                    {/*>*/}
                    {/*    <Checkbox style={{color: 'red', marginLeft: "85px"}}>*/}
                    {/*        Private Notiz*/}
                    {/*    </Checkbox>*/}
                    {/*</Form.Item>*/}

                    <Form.Item
                        label="Von:"
                        name="editor"
                        initialvalue={(auth.user?.profile.name)}
                    >
                        <Select
                            placeholder="Bitte auswählen"
                            optionFilterProp="children"
                            initialvalue={(auth.user?.profile.name)}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={editorOptions}
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button type="primary" htmlType="submit">
                            Notiz zu dem Vorfall hinzufügen
                        </Button>
                    </Form.Item>

                </Form>
            }
        >
            <Button
                style={{marginLeft: '10px', marginTop: '16px', marginRight: '10px'}}
                icon={<PlusOutlined/>}
                onClick={handleVisibleChange}
            >
              Notiz
            </Button>
        </Popover>
    );
}


export default TriggerEventButton;