import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';

import {
    Button, Row, Col,
    Card,
    DatePicker, notification, Typography, Table, Statistic
} from 'antd';
import dayjs, {Dayjs} from "dayjs";
import {SettingsContext} from "../../App.js";
import {convertKilometersToMiles, timezoneDateString, timezoneTimeString, timezoneDateTimeString} from '../../utility/conversions.js';

const {Title, Paragraph, Text, Link} = Typography;

const MapMarkerStationary = (props) => {

    const {timezone, distance} = useContext(SettingsContext);

    function calculateDuration(startTime, endTime) {
        const start = dayjs(startTime);
        const end = dayjs(endTime);
        const durationMinutes = end.diff(start, 'minutes');
        const hours = Math.floor(durationMinutes / 60);
        const minutes = durationMinutes % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
    }

    const elementData = [
        props?.start_elements || '',
        props?.end_elements || '',
    ];

    const parsedData = elementData.map((str) => str.split(', ').reverse());

    let data = null

    if (parsedData) {
        data = parsedData[0].map((item, index) => ({
            key: index,
            start: timezoneDateTimeString(item, timezone),
            end: timezoneDateTimeString(parsedData[1][index], timezone),
            duration: calculateDuration(item, parsedData[1][index]),
        }));
    }

    const columns = [
        {
            title: 'Start Time',
            dataIndex: 'start',
            key: 'start',
        },
        {
            title: 'End Time',
            dataIndex: 'end',
            key: 'end',
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
        },
    ];

    const paginationConfig = {
        pageSize: 5,
    };

    return (
        <div style={{whiteSpace: "pre-line"}}>
            <Row>
                <Text style={{fontWeight: 'bold', marginBottom: "8px"}}>
                    Vehicle ID - {props.vehicle}
                </Text>
            </Row>
            <Row>
                <Text style={{marginBottom: "8px"}}>
                    Total Stationary Time - {props.ts_diff_str}
                </Text>
            </Row>
            <Table
                size={'small'}
                columns={columns}
                dataSource={data}
                pagination={paginationConfig}
            />
        </div>
    );
}

export default MapMarkerStationary;