import React, {useState, useRef, useCallback, useEffect} from 'react';
import 'ag-grid-enterprise';
import {AgGridReact} from 'ag-grid-react';

import {
    Button, Card, Select, Col, Dropdown, Menu, notification, Row, Space, Typography
} from 'antd';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {FaSearch} from 'react-icons/fa';
import axiosInstance from "services/axios";

import EventUrlRenderer from "./EventUrlRenderer";
import MultiImageRenderer from "./MultiImageRenderer/MultiImageRenderer";
import MultiImageRendererV2 from "./MultiImageRendererV2/MultiImageRendererV2";
import TextCellRenderer from "./TextCellRenderer";
import AnomalyRenderer from "./AnomalyRenderer";
import StatusCircleRenderer from "./StatusCircleRenderer";
import {CheckboxRenderer} from "./CheckBoxRenderer";
import NewEventRenderer from "./NewEventRenderer/NewEventRenderer";
import DetailsCard from "../DetailsCard/DetailsCard";
import HoverCellRenderer from "./HoverCellRenderer";
// import {EPTextColumnFilter} from "./EPTextColumnFilter";


const EventTool = (props) => {

    const [columnVerursacherFilter, setColumnVerursacherFilter] = useState(false);
    const gridRef = useRef();
    const [tableHeight, settableHeight] = useState(620); // Default height for 1080p

    let columnState = [];

    useEffect(() => {
        const screenHeight = window.screen.height;
        if (screenHeight >= 1440) {
            // For 2K resolution or higher
            settableHeight(980);
        }
        // You can add additional conditions for other resolutions if needed
    }, []);

    const datasourceServerSide = {
        getRows(params) {

            params.request.columnVerursacherFilter = columnVerursacherFilter

            // default sort by newest element
            if (params.request.sortModel.length === 0) {
                params.request.sortModel = [
                    {
                        "sort": "desc",
                        "colId": "Vorfalldatum"
                    }
                ]
            }

            // console.log("Request data with params: " + JSON.stringify(params.request, null, 1));
            axiosInstance.post('./api/incidents/query', params.request)
                .then(res => {
                    console.log(res.data)
                    params.successCallback(res.data.data, res.data.lastRow);
                })
                .catch(error => {
                    console.log(error);
                    params.fail();
                    notification['error']({message: 'Error in getRows', description: error.message});
                })
        }
    };

    // function exportAllData() {
    //
    //     const params = {
    //         startRow: 0,
    //         endRow: 1000,
    //         filterModel: gridRef.current.api.getFilterModel(),
    //         sortModel: gridRef.current.api.getSortModel(),
    //     }
    //
    //     // console.log("Request data with params: " + JSON.stringify(params.request, null, 1));
    //     axiosInstance.post('./api/incidents/query', params)
    //         .then(res => {
    //             const data = res.data; // Adjust based on your actual data structure
    //
    //             // Create a Blob from the data (adjust MIME type as needed)
    //             const blob = new Blob([JSON.stringify(data)], {type: 'application/json'});
    //             const url = window.URL.createObjectURL(blob);
    //
    //             // Create a link element to trigger the download
    //             const a = document.createElement('a');
    //             a.href = url;
    //             a.download = 'exported_data.json'; // Set the filename
    //             document.body.appendChild(a);
    //             a.click();
    //
    //             // Cleanup: remove the link and revoke the object URL
    //             document.body.removeChild(a);
    //             window.URL.revokeObjectURL(url);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //             params.fail();
    //             notification['error']({message: 'Error in getRows', description: error.message});
    //         })
    // };

    // Function to update the column state
    function updateColumnState() {
        // Get the current column state
        console.log('Updated column state')
        columnState = gridRef.current.columnApi.getColumnState();

        // Store the updated column state in the session storage
        localStorage.setItem("carvalooIncidentColumns", JSON.stringify(columnState));
    }

    const onResetColumns = useCallback(() => {
        if (gridRef.current.columnApi) {
            gridRef.current.columnApi.resetColumnState();
            console.log('column state reset');
        }
    }, []);

    useEffect(() => {
        onResetColumns();
    }, [props.resetColumns]);

    const getDistinctValues = (params) => {

        let column = params.column.colId;
        axiosInstance.get('./api/incidents/distinct/' + column)
            .then(res => {
                params.success(res.data.data);
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error in getDistinctValues',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    const onSetCarvalooFilter = () => {
        if (gridRef.current && gridRef.current.api) {

            const today = new Date();
            const ninetyDaysAgo = new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000);
            const formattedDate = ninetyDaysAgo.toISOString().slice(0, 19).replace("T", " ");

            let currentFilterModel = {}
            currentFilterModel.gemeldetVon = {
                "filterType": "set",
                "values": ["Nachfolger", "Arbeitsgruppe"]
            }
            currentFilterModel.SensorBox = {
                "filterType": "text",
                "type": "notBlank"
            }
            currentFilterModel.SchadenNeuStatus = {
                "filterType": "set",
                "values": ["Neuer Schaden", "Unbekannt"]
            }
            currentFilterModel.Vorfalldatum = {
                "dateFrom": formattedDate,
                "dateTo": null,
                "filterType": "date",
                "type": "greaterThan"
            }
            gridRef.current.api.setFilterModel(currentFilterModel);
        }
    }

    useEffect(() => {
        onSetCarvalooFilter();
    }, [props.carvalooFilter]);


    const onSetRelevantFilter = () => {
        if (gridRef.current && gridRef.current.api) {

            let currentFilterModel = {}
            currentFilterModel.CarvalooStatus = {
                "filterType": "set",
                "values": ["Relevant"]
            }
            gridRef.current.api.setFilterModel(currentFilterModel);
        }
    }

    useEffect(() => {
        onSetRelevantFilter();
    }, [props.relevantFilter]);

    // async function refreshData() {
    //   try {
    //     await gridRef.current.api.refreshServerSide();
    //     console.log('REFRESH')
    //     gridRef.current.api.refreshCells({ force: true });
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }

    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.refreshServerSide()
            notification['success']({message: 'Tabelle wurde aktualisiert'});
        }
    }, [props.refresh]);

    const onSetVerursacherFilter = () => {
        if (gridRef.current && gridRef.current.api) {

            const today = new Date();
            const ninetyDaysAgo = new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000);
            const formattedDate = ninetyDaysAgo.toISOString().slice(0, 19).replace("T", " ");

            let currentFilterModel = {}
            currentFilterModel.SensorBox = {
                "filterType": "text",
                "type": "notBlank"
            }
            // currentFilterModel.SchadenNeuStatus = {
            //   "filterType": "set",
            //   "values": ["Neuer Schaden"]
            // }
            currentFilterModel.gemeldetVon = {
                "filterType": "set",
                "values": ["Verursacher"]
            }
            // currentFilterModel.CarvalooStatus = {
            //   "filterType": "set",
            //   "values": [null]
            // }
            // currentFilterModel.Schadenreparatur = {
            //   "filterType": "set",
            //   "values": [
            //     "Reparatur",
            //     "Totalschaden",
            //     "Keine Reparatur oder Techniker konnte Schaden polieren oder ausbessern"
            //    ]
            // }
            currentFilterModel.Vorfalldatum = {
                "dateFrom": formattedDate,
                "dateTo": null,
                "filterType": "date",
                "type": "greaterThan"
            }
            // currentFilterModel.DetailsText = {
            //   "filterType": "text",
            //   "type": "notBlank"
            // }
            gridRef.current.api.setFilterModel(currentFilterModel);
        }
    }

    useEffect(() => {
        onSetVerursacherFilter();
    }, [props.verursacherFilter]);


    const onResetAllFilters = () => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.setFilterModel(null);
        }
    }

    useEffect(() => {
        onResetAllFilters();
    }, [props.resetFilters]);

    const onSetStatusFilter = () => {
        if (gridRef.current && gridRef.current.api) {

            let currentFilterModel = gridRef.current.api.getFilterModel();
            currentFilterModel.VorfallStatus = {
                "filterType": "set",
                "values": [
                    props.eventStatus
                ]
            }

            if (props.eventStatus === undefined) {
                delete currentFilterModel.VorfallStatus;
            }

            gridRef.current.api.setFilterModel(currentFilterModel);
        }
    }

    useEffect(() => {
        onSetStatusFilter()
    }, [props.eventStatus]);

    const onSetRegionFilter = () => {
        if (gridRef.current && gridRef.current.api) {

            let currentFilterModel = gridRef.current.api.getFilterModel();
            currentFilterModel.Flottenregion = {
                "filterType": "set",
                "values": [
                    props.fleetRegion
                ]
            }

            if (props.fleetRegion === undefined) {
                delete currentFilterModel.Flottenregion;
            }

            gridRef.current.api.setFilterModel(currentFilterModel);
        }
    }

    useEffect(() => {
        onSetRegionFilter()
    }, [props.fleetRegion]);

    const onSetDateFilter = () => {
        if (gridRef.current && gridRef.current.api) {

            let currentFilterModel = gridRef.current.api.getFilterModel();
            if (props.dateRange === undefined || props.dateRange === null) {
                delete currentFilterModel.Vorfalldatum;
            } else {
                currentFilterModel.Vorfalldatum = {
                    "dateFrom": props.dateRange[0].toISOString(),
                    "dateTo": props.dateRange[1].toISOString(),
                    "filterType": "date",
                    "type": "inRange"
                }
            }

            gridRef.current.api.setFilterModel(currentFilterModel);
        }
    }

    useEffect(() => {
        onSetDateFilter()
    }, [props.dateRange]);

    function toSystemDatetimeRenderer(params) {
        // Get the GMT time string value from the data
        const gmtTimeStr = params.value;

        // Parse the string into a Date object
        const date = new Date(gmtTimeStr);

        // Return the system time as the rendered value
        const systemTime = date.toLocaleString()

        return systemTime.replace(/\//g, '.').replace(/,/g, '');
    }

    function toSystemDateRenderer(params) {
        // Get the GMT time string value from the data
        const gmtTimeStr = params.value;

        // Parse the string into a Date object
        const date = new Date(gmtTimeStr);
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        };

        // Return the system time as the rendered value
        const systemTime = date.toLocaleString(navigator.language, options)

        return systemTime.replace(/\//g, '.').replace(/,/g, '');
    }

    const handleRowSelection = () => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        if (selectedNodes.length) {
            props.setSelectedEventId(selectedNodes[0].data.event_id);
        } else {
            props.setSelectedEventId(null);
        }
    };

    const gridOptions = {
        rowModelType: 'serverSide',
        suppressServerSideInfiniteScroll: false,
        cacheBlockSize: 50,
        rowSelection: 'multiple',
        suppressRowClickSelection: false,
        enableRangeSelection: 'true',
        pagination: true,
        // paginationPageSize: 10,
        paginationAutoPageSize: true,
        suppressLastEmptyLineOnPaste: true,
        components: {
            eventUrlRenderer: EventUrlRenderer,
            newEventRenderer: NewEventRenderer,
            textCellRenderer: TextCellRenderer,
            checkboxRenderer: CheckboxRenderer,
            anomalyRenderer: AnomalyRenderer,
        },
        onGridReady: function () {
            gridRef.current.api.setServerSideDatasource(datasourceServerSide);

            const columnStateString = localStorage.getItem("carvalooIncidentColumns");
            if (columnStateString) {
                console.log('Column state detected')
                const columnState = JSON.parse(columnStateString);
                gridRef.current.columnApi.applyColumnState({state: columnState});
            }

            gridRef.current.api.setFilterModel({
                // 'VorfallStatus': {
                //   "values": [
                //     props.eventStatus
                //   ],
                //   "filterType": "set"
                // },
                "gemeldetVon": {
                    "values": [
                        "Verursacher",
                        "Nachfolger",
                        "Arbeitsgruppe"
                    ],
                    "filterType": "set"
                },
                "SensorBox": {
                    "filterType": "text",
                    "type": "notBlank"
                },
                "ignore": {
                    "values": [
                        null,
                        'false'
                    ],
                    "filterType": "set"
                },
                'Vorfalldatum': {
                    "dateFrom": props.dateRange[0].toISOString(),
                    "dateTo": props.dateRange[1].toISOString(),
                    "filterType": "date",
                    "type": "inRange"
                }
            });
            console.log('grid ready');
        },
        columnDefs: [
            {
                headerName: 'Vorfall',
                children: [
                    {
                        field: 'event_id',
                        headerName: 'Vorfall ID',
                        floatingFilter: true,
                        filter: 'agTextColumnFilter',
                        width: 125,
                        cellRenderer: 'eventUrlRenderer',
                    },
                    {
                        field: 'Vorfalldatum',
                        headerName: 'Datum',
                        width: 150,
                        filter: 'agDateColumnFilter',
                        floatingFilter: true,
                        cellRenderer: toSystemDateRenderer
                    },
                    {
                        field: 'modified_datetime',
                        headerName: 'Aktualisiert',
                        width: 150,
                        hide: true,
                        filter: 'agDateColumnFilter',
                        floatingFilter: true,
                        cellRenderer: toSystemDatetimeRenderer
                    },
                    {
                        field: 'VorfallStatus',
                        headerName: 'Status',
                        floatingFilter: true,
                        width: 80,
                        filter: 'agSetColumnFilter',
                        cellRenderer: StatusCircleRenderer,
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    {
                        field: 'gemeldetVon',
                        headerName: 'Gemeldet',
                        floatingFilter: true,
                        width: 115,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    {
                        field: 'VorfallBeurteilung',
                        headerName: 'Beurteilung',
                        width: 140,
                        floatingFilter: true,
                        filter: 'agTextColumnFilter',
                        // cellRenderer: HoverCellRenderer,
                    },
                    {
                        field: 'Vorfallart',
                        headerName: 'Art',
                        width: 130,
                        hide: true,
                        columnGroupShow: 'open',
                        floatingFilter: true,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    {
                        field: 'VorfallUrsache',
                        headerName: 'Ursache',
                        floatingFilter: true,
                        hide: true,
                        filter: 'agSetColumnFilter',
                        columnGroupShow: 'open',
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    {
                        field: 'driver_id',
                        headerName: 'EP Nummer',
                        floatingFilter: true,
                        columnGroupShow: 'open',
                        // filter: {EPTextColumnFilter},
                        filter: 'agTextColumnFilter',
                    },
                    // {
                    //   field: 'MelderEPNr',
                    //   headerName: 'Melder EP',
                    //   floatingFilter: true,
                    //   filter: 'agTextColumnFilter',
                    //   columnGroupShow: 'open',
                    // },
                ]
            },
            {
                headerName: 'carvaloo',
                children: [
                    {
                        field: 'CarvalooStatus',
                        headerName: 'carvaloo Status',
                        floatingFilter: true,
                        filter: 'agSetColumnFilter',
                        width: 95,
                        cellRenderer: 'newEventRenderer',
                        cellRendererParams: {
                            permission: props.permissions?.incident
                        },
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    {
                        field: 'production_alert',
                        headerName: 'carvaloo Meldung',
                        headerTooltip: "Produktiv Meldungen innerhalb der letzten 14 Tage",
                        floatingFilter: true,
                        filter: 'agTextColumnFilter',
                        cellRenderer: 'anomalyRenderer',
                        cellRendererParams: {
                            title: 'Meldungen innerhalb der letzten 14 Tage vor dem Vorfall'
                        }
                    },
                    {
                        field: 'carvaloo_priority',
                        headerName: 'Priorität',
                        floatingFilter: true,
                        filter: 'agSetColumnFilter',
                        width: 95,
                        columnGroupShow: 'open',
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    {
                        field: 'matching_status',
                        headerName: 'Matching Status',
                        floatingFilter: true,
                        filter: 'agSetColumnFilter',
                        width: 100,
                        columnGroupShow: 'open',
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    // {
                    //   field: 'exists_production_alert',
                    //   headerName: 'Produktiv Meldung',
                    //   floatingFilter: true,
                    //   cellRenderer: 'checkboxRenderer',
                    //   filter: 'agSetColumnFilter',
                    //   hide: true,
                    //   filterParams: {
                    //     values: params => {
                    //       getDistinctValues(params);
                    //     }
                    //   },
                    // },
                    {
                        field: 'editor',
                        headerName: 'Hinzugefügt von',
                        floatingFilter: true,
                        filter: 'agSetColumnFilter',
                        columnGroupShow: 'open',
                        width: 120,
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    {
                        field: 'editor_datetime',
                        headerName: 'Hinzugefügt Datum',
                        floatingFilter: true,
                        width: 120,
                        columnGroupShow: 'open',
                        filter: 'agDateColumnFilter',
                    },
                    {
                        field: 'ignore',
                        headerName: 'Ignoriert',
                        floatingFilter: true,
                        filter: 'agSetColumnFilter',
                        columnGroupShow: 'open',
                        width: 80,
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                ]
            },
            {
                headerName: 'Fahrzeug',
                children: [
                    {
                        field: 'vehicle_id',
                        headerName: 'Fahrzeug ID',
                        floatingFilter: true,
                        width: 120,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'SensorBox',
                        width: 130,
                        headerName: 'Mobility Box',
                        floatingFilter: true,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'Kategorie',
                        headerName: 'Kategorie',
                        width: 105,
                        columnGroupShow: 'open',
                        floatingFilter: true,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    // {
                    //   field: 'Traeger',
                    //   headerName: 'Traeger',
                    //   floatingFilter: true,
                    //   filter: 'agTextColumnFilter',
                    // },
                    {
                        field: 'Versicherungspolice',
                        headerName: 'Versicherungspolice',
                        floatingFilter: true,
                        hide: true,
                        columnGroupShow: 'open',
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'Haftungsreduktion',
                        headerName: 'Haftungsreduktion',
                        floatingFilter: true,
                        hide: true,
                        columnGroupShow: 'open',
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'Versichert',
                        headerName: 'Versichert',
                        floatingFilter: true,
                        hide: true,
                        filter: 'agSetColumnFilter',
                        columnGroupShow: 'open',
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                ]
            },
            {
                headerName: 'Standort',
                hide: true,
                children: [
                    {
                        field: 'Flottenregion',
                        width: 150,
                        hide: true,
                        floatingFilter: true,
                        headerName: 'Flottenregion',
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    {
                        field: 'Sektion',
                        hide: true,
                        headerName: 'Sektion',
                        floatingFilter: true,
                        width: 150,
                        filter: 'agTextColumnFilter',
                        columnGroupShow: 'open',
                    },
                    {
                        field: 'Kanton',
                        headerName: 'Kanton',
                        floatingFilter: true,
                        hide: true,
                        filter: 'agSetColumnFilter',
                        columnGroupShow: 'open',
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    {
                        field: 'Ort',
                        hide: true,
                        columnGroupShow: 'open',
                        headerName: 'Ort',
                        floatingFilter: true,
                        filter: 'agTextColumnFilter',
                        width: 130,
                    },
                    {
                        field: 'Name',
                        columnGroupShow: 'open',
                        headerName: 'Name',
                        hide: true,
                        floatingFilter: true,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'Nummer',
                        columnGroupShow: 'open',
                        headerName: 'Nummer',
                        hide: true,
                        floatingFilter: true,
                        filter: 'agTextColumnFilter',
                    },
                ]
            },
            {
                headerName: 'Kosten',
                children: [
                    {
                        field: 'Offerte',
                        headerName: 'Offerte',
                        floatingFilter: true,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'Gutschrift',
                        headerName: 'Gutschrift',
                        hide: true,
                        columnGroupShow: 'open',
                        floatingFilter: true,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'Rechnung1',
                        headerName: 'Rechnung',
                        hide: true,
                        columnGroupShow: 'open',
                        floatingFilter: true,
                        filter: 'agTextColumnFilter',
                    },
                    // {
                    //   field: 'SelbstbehaltMobility',
                    //   headerName: 'SelbstbehaltMobility',
                    //   floatingFilter: true,
                    //   filter: 'agTextColumnFilter',
                    // },
                    // {
                    //   field: 'SelbstbehaltEP',
                    //   headerName: 'SelbstbehaltEP',
                    //   floatingFilter: true,
                    //   filter: 'agTextColumnFilter',
                    // },
                    // {
                    //   field: 'AusfallzeitTage',
                    //   headerName: 'AusfallzeitTage',
                    //   floatingFilter: true,
                    //   filter: 'agTextColumnFilter',
                    // },
                ]
            },
            {
                headerName: 'Schaden',
                children: [
                    {
                        field: 'Comment',
                        headerName: 'Kommentar',
                        filter: 'agSetColumnFilter',
                        cellRenderer: 'textCellRenderer',
                        cellRendererParams: {
                            title: 'Kommentar'
                        }
                    },
                    {
                        field: 'DetailsText',
                        headerName: 'Details',
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                        cellRenderer: 'textCellRenderer',
                        cellRendererParams: {
                            title: 'Details'
                        }
                    },
                    {
                        field: 'Schadenort',
                        width: 120,
                        headerName: 'Schadenort',
                        floatingFilter: true,
                        filter: 'agTextColumnFilter',
                        // cellRenderer: HoverCellRenderer,
                    },
                    {
                        field: 'SchadenCode',
                        headerName: 'SchadenCode',
                        floatingFilter: true,
                        filter: 'agTextColumnFilter',
                        hide: true,
                    },
                    {
                        field: 'SchadenortStatus',
                        columnGroupShow: 'open',
                        hide: true,
                        headerName: 'SchadenortStatus',
                        floatingFilter: true,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    {
                        field: 'Schadenreparatur',
                        headerName: 'Schadenreparatur',
                        columnGroupShow: 'open',
                        floatingFilter: true,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    {
                        field: 'Schadenart',
                        hide: true,
                        headerName: 'Schadenart',
                        columnGroupShow: 'open',
                        floatingFilter: true,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    {
                        field: 'Schadenuebernahme',
                        hide: true,
                        headerName: 'Schadenuebernahme',
                        columnGroupShow: 'open',
                        floatingFilter: true,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    {
                        field: 'SchadenNeuStatus',
                        headerName: 'Schaden Neu Status',
                        floatingFilter: true,
                        width: 160,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: params => {
                                getDistinctValues(params);
                            }
                        },
                    },
                    {
                        field: 'SchadenFoto',
                        headerName: 'Foto',
                        minWidth: 160,
                        sortable: false,
                        cellRenderer: MultiImageRendererV2,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    },
                ]
            }
            // {
            //   field: 'Comment',
            //   headerName: 'Kommentar',
            //   floatingFilter: true,
            //   filter: 'agTextColumnFilter',
            // }
        ],
        defaultColDef: {
            width: 100,
            sortable: true,
            filter: false,
            resizable: true, //minWidth: 100,
            editable: false, //cellEditor: PopupCellEditor
            suppressMenu: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
        sideBar: {
            position: 'right',
            defaultToolPanel: '',
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 225,
                    maxWidth: 225,
                    width: 225,
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivotMode: true
                    }
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 180,
                    maxWidth: 400,
                    width: 250
                }
            ],
        },
        onRowSelected: (event) => {
            handleRowSelection()
        },
        onColumnVisible: (event) => {
            updateColumnState()
        },
        onColumnResized: (event) => {
            updateColumnState()
        },
        onColumnMoved: (event) => {
            updateColumnState()
        },
        onColumnPinned: (event) => {
            updateColumnState()
        },
        onRowEditingStarted: (event) => {
            console.log('never called - not doing row editing');
        },
        onRowEditingStopped: (event) => {
            console.log('never called - not doing row editing');
        },
        onCellEditingStarted: (event) => {
            console.log('cellEditingStarted');
        },
        onCellEditingStopped: (event) => {
            console.log('cellEditingStopped');
        },
        onNewColumnsLoaded: (event) => {
            console.log('onNewColumnsLoaded');
        },
        onDisplayedColumnsChanged: (event) => {
            console.log('onDisplayedColumnsChanged ');
        },
        // // onCellValueChanged: onCellValueChanged,
        // getContextMenuItems: (params) => {
        //     // Get the default context menu items
        //     const defaultItems = params.defaultItems || [];
        //
        //     // Add custom "Export All Data" option
        //     defaultItems.push({
        //         name: 'Export All Data',
        //         action: () => exportAllData()
        //     });
        //
        //     return defaultItems;
        // }
    }

    // title={<div><Icon component={FaSearch}/> <Text strong>Vorfall Datenbank</Text></div>}
    return (<>
            <Card
                style={{
                    width: '100%',
                    height: 'calc(100% - 280px)',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #e8e8e8'
                }}
                styles={{
                    body: {
                        padding: '5px',
                        display: 'flex',
                        flexDirection: "column",
                        flex: "1 1 auto",
                        height: '100%'
                    }
                }}
            >
                <Row className="ag-theme-alpine" style={{flex: "1 1 auto", height: tableHeight}}>
                    <Col style={{flex: "1 1 auto", height: '100%'}}>
                        <AgGridReact
                            ref={gridRef}
                            gridOptions={gridOptions}
                            style={{width: '100%', height: '100%'}} // Ensure AgGridReact takes full height
                        />
                    </Col>
                    {(props.selectedEventId) && (props.detailCardOpen) &&
                        <Col>
                            <DetailsCard event_id={props.selectedEventId}/>
                        </Col>}
                </Row>
            </Card>

        </>
    );
}

export default EventTool;
