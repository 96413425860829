import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';
import {Col, notification, Table, Row, Card, Typography, Tooltip} from 'antd';
import axiosInstance from "services/axios";
import {AgGridReact} from "ag-grid-react";
import moment from "moment";
import dayjs, {Dayjs} from "dayjs";
import {InfoCircleOutlined} from "@ant-design/icons";
import {formatDateToLocal} from "../FleetSecurity/TableHelpers";
import "./../../common_components/AGGrid/carvaloo_theme.css";

const {Paragraph, Text} = Typography;

const StationaryTrackingTable = (props) => {

    const [tableHeight, setTableHeight] = useState(362);
    const gridRef = useRef();
    const cardRef = useRef();
    // const gridStyle = useMemo(() => (
    //     {height: 300, width: '100%'}), []);

    //
    // useEffect(() => {
    //     // Function to calculate and set the height of the parent container
    //     const setTableHeightFromCard = () => {
    //         if (cardRef.current) {
    //             const cardHeight = cardRef.current.clientHeight;
    //             setTableHeight(cardHeight - 55); // Adjust for your desired value
    //         }
    //     };
    //
    //     // Set the table height initially
    //     setTableHeightFromCard();
    //
    //     // Attach window resize and zoom event listeners to update the table height
    //     const updateTableHeight = () => {
    //         setTableHeightFromCard();
    //     };
    //
    //     window.addEventListener('resize', updateTableHeight);
    //     window.addEventListener('zoom', updateTableHeight);
    //
    //     // Clean up the event listeners when the component unmounts
    //     return () => {
    //         window.removeEventListener('resize', updateTableHeight);
    //         window.removeEventListener('zoom', updateTableHeight);
    //     };
    // }, []);

    // useEffect(() => {
    //     // Function to calculate and set the height of the parent container
    //     function updateHeight() {
    //         if (cardRef.current) {
    //             setTableHeight(cardRef.current.clientHeight - 55); // Adjust for your desired value
    //         }
    //     }
    //
    //     window.addEventListener("resize", updateHeight);
    //     updateHeight();
    //     return () => window.removeEventListener("resize", updateHeight);
    // }, []);

    // useEffect(() => {
    //     // Function to calculate and set the height of the parent container
    //     const setTableHeightFromCard = () => {
    //         if (cardRef.current) {
    //             cardRef.current.style.transition = 'none';
    //             const cardHeight = cardRef.current.clientHeight;
    //             setTableHeight(cardHeight - 55); // Adjust for your desired value
    //         }
    //     };
    //
    //     // Create a ResizeObserver to watch for changes in the card's size
    //     const resizeObserver = new ResizeObserver(setTableHeightFromCard);
    //
    //     if (cardRef.current) {
    //         resizeObserver.observe(cardRef.current); // Start observing the card
    //     }
    //
    //     // Clean up the observer when the component unmounts
    //     return () => {
    //         if (cardRef.current) {
    //             resizeObserver.unobserve(cardRef.current);
    //         }
    //         resizeObserver.disconnect();
    //     };
    // }, []);

    function customTimeRenderer(params) {
        const date = new Date(params.value);
        return date.toLocaleString();
    }

    function renderLocation(params) {
        if (params.data && params.data.location) {
            const location = params.data.location;
            const lat = location.lat.toFixed(6);
            const lon = location.lon.toFixed(6);
            return `Lat: ${lat}, Lon: ${lon}`;
        }
        return '';
    }

    const hhmmssToSeconds = (hhmmss) => {
        const [hours, minutes, seconds] = hhmmss.split(':');
        return parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
    };

    const customComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
        const secondsA = hhmmssToSeconds(valueA);
        const secondsB = hhmmssToSeconds(valueB);

        if (secondsA < secondsB) {
            return -1;
        } else if (secondsA > secondsB) {
            return 1;
        }

        return 0;
    };

    // Define your column definition with the custom cell renderer
    const columnDefs = [
        // {
        //     field: 'issue',
        //     headerTooltip: "Category of data points",
        //     headerName: 'Category',
        //     filter: 'agSetColumnFilter',
        // },
        {
            field: 'ts_start',
            headerTooltip: "Stationary start timestamp",
            headerName: 'Start',
            width: 190,
            cellRenderer: customTimeRenderer,
        },
        {
            field: 'ts_end',
            headerTooltip: "Stationary end timestamp",
            headerName: 'End',
            width: 190,
            cellRenderer: customTimeRenderer,
        },
        {
            field: 'ts_diff_str',
            headerTooltip: "Stationary time in hours:minutes:seconds",
            headerName: 'Stationary',
            comparator: customComparator,
            // filter: 'agNumberColumnFilter',
        },
        {
            field: 'location',
            headerTooltip: "Latitude and Longitude coordinates",
            headerName: 'Location',
            width: 150,
            cellRenderer: renderLocation,
        }
    ]

    const [gridOptions, setGridOptions] = useState({
        rowSelection: 'single',
        suppressRowClickSelection: false,
        enableRangeSelection: 'true',
        columnDefs: columnDefs,
        // getContextMenuItems: getContextMenuItems,
        // pagination: true,
        // paginationAutoPageSize: true,
        // paginationPageSize: 5, // Number of rows per page
        // onPaginationChanged: onPaginationChanged,
        defaultColDef: {
            width: 100,
            sortable: true,
            filter: false,
            resizable: true, //minWidth: 100,
            editable: false, //cellEditor: PopupCellEditor
            suppressMenu: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: {fontSize: '14px'}
        },
        sideBar: {
            position: 'right',
            defaultToolPanel: '',
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 150,
                    maxWidth: 150,
                    width: 150,
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivotMode: true
                    }
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 200,
                    maxWidth: 400,
                    width: 200
                }
            ],
        }
    })

    // const onGridReady = useCallback((params) => {
    //     params.api.sizeColumnsToFit();
    //     window.addEventListener('resize', function () {
    //         setTimeout(function () {
    //             params.api.sizeColumnsToFit();
    //         });
    //     });
    //
    //     gridRef.current.api.sizeColumnsToFit();
    // }, []);

    useEffect(() => {
        if (gridRef.current.api) {
            gridRef.current.api.setRowData(props.tableData);
        }
    }, [props.tableData]);

    return (<>
            <Card
                ref={cardRef}
                style={{
                    width: '100%',
                    height: '100%',
                    minHeight: 300,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8',
                    // flex: 1, // Allow the Card to take up the remaining vertical space
                }}
                styles={{ body: {
                    padding: "8px",
                }}}>
                {/*<Text style={{fontWeight: 'bold'}}>*/}
                {/*    GPS Points*/}
                {/*</Text>*/}
                {/*    <div style={{maxHeight: cardRef.current.clientHeight-100}}>*/}
                <div style={{height: 300}} className="ag-theme-alpine">
                    <AgGridReact
                        ref={gridRef}
                        gridOptions={gridOptions}
                        // domLayout={'autoHeight'}
                        // onGridReady={onGridReady}
                    />
                </div>
                {/*</div>*/}
            </Card>
        </>
    );
};

export default StationaryTrackingTable;