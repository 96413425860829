import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';
import ReactJson from "react-json-view";
import {
    Alert,
    Button,
    Card,
    ConfigProvider,
    Descriptions,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Popover,
    Switch,
    Steps,
    Table,
    Tree,
    Timeline,
    Divider, Tooltip, Statistic
} from 'antd';

import "./custom.css";
import {SettingsContext} from "../../App.js";

import {convertKilometersToMiles, convertMilesToKilometers, timezoneDateTimeString} from '../../utility/conversions.js';

const {Search} = Input;
const {Title, Text} = Typography;

const WarningView = (props) => {

    const {timezone, distance} = useContext(SettingsContext);
    const [loading, setLoading] = useState(false);
    const [timelineData, setTimelineData] = useState(null);

    const [stepStatus, setStepStatus] = useState({
        step1: 'wait',
        step2: 'wait',
        step3: 'wait',
        opacityStep1: 0.1,
        opacityStep2: 0.1,
        opacityStep3: 0.1,
    });

    const getStepStatus = (eventLevel) => {

        const level = parseInt(eventLevel.split('_')[1]);

        switch (level) {
            case 1:
                return {
                    step1: 'current',
                    step2: 'wait',
                    step3: 'wait',
                    opacityStep1: 1,
                    opacityStep2: 0.1,
                    opacityStep3: 0.1,
                };
            case 2:
                return {
                    step1: 'current',
                    step2: 'current',
                    step3: 'wait',
                    opacityStep1: 1,
                    opacityStep2: 1,
                    opacityStep3: 0.1,
                };
            case 3:
                return {
                    step1: 'current',
                    step2: 'current',
                    step3: 'current',
                    opacityStep1: 1,
                    opacityStep2: 1,
                    opacityStep3: 1,
                };
            // Add more cases as needed
            default:
                return {
                    step1: 'wait',
                    step2: 'wait',
                    step3: 'wait',
                    opacityStep1: 0.1,
                    opacityStep2: 0.1,
                    opacityStep3: 0.1,
                };
        }
    };

    function mergeAndSortLists(list1, list2) {
        // Combine the lists
        const combinedList = list1.concat(list2);

        // Sort function, converting event_ts to Date objects
        combinedList.sort((a, b) => {
            const dateA = new Date(a.timestamp);
            const dateB = new Date(b.timestamp);
            return dateA - dateB; // Sort in ascending order
        });

        return combinedList;
    }

    useEffect(() => {

        if (props.mapMarkers) {
            setLoading(false)
            const mergedAndSortedList = mergeAndSortLists([], props.mapMarkers.misuses)
            setTimelineData(mergedAndSortedList)
        } else {
            setTimelineData(null)
            setLoading(false)
        }
    }, [props.mapMarkers]);

    const handleCopy = (text) => {
        // Logic to copy text to clipboard
        navigator.clipboard.writeText(text);
        // Optionally, provide feedback to the user that the text has been copied
    };

    return (<>
            <Card
                style={{
                    width: 600,
                    height: 830,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #e8e8e8',
                }}
                styles={{ body: {
                    paddingRight: 24,
                    paddingLeft: 24,
                    paddingTop: 10,
                    paddingBottom: 10,
                }}}
                loading={loading}
                title="Overview"
            >
                    <div style={{ maxHeight: '750px', overflowY: 'auto', overflowX: 'hidden'}}>
                    {timelineData &&
                        <Timeline mode={'left'} style={{marginLeft: timelineData.length > 10 ? -205 : -220, marginTop: 20}}>
                            {timelineData.map((item) => (
                                <Timeline.Item key={item.id} label={timezoneDateTimeString(item.event_ts, timezone)}>
                                    {item.id} - {item.label}
                                    <Divider style={{marginTop: 4, marginBottom: 4}}></Divider>
                                    <Text style={{color: item.location.lat ? 'black' : 'red'}}>
                                        Latitude: {item.location.lat ? item.location.lat : 'Not available'}
                                    </Text>
                                    <Divider type={'vertical'} style={{marginTop: 4, marginBottom: 4}}></Divider>
                                    <Text style={{color: item.location.lat ? 'black' : 'red'}}>
                                        Longitude: {item.location.lon ? item.location.lon : 'Not available'}
                                    </Text>
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    }
                    </div>
            </Card>
        </>
    )
        ;
}


export default WarningView;
