import React, {useState, useRef, useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    Button,
    Card,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Popover,
    Switch,
    Tag,
    Tabs,
    Divider,
    Space,
    DatePicker
} from 'antd';
import dayjs, {Dayjs} from "dayjs";

import axiosInstance from "services/axios";
import AlertTable from "../components/FleetSecurity/AlertTable";
import MapCard from "../components/FleetSecurity/MapCard";
import AlertInfo from "../components/FleetSecurity/AlertInfo";
import HeaderRow from "../components/HeaderRow/HeaderRow";
import Configurator from "../components/DamagePrevention/Configurator";
import ScoringOverview from "../components/DamagePrevention/ScoringOverview";
import DriverTable from "../components/DamagePrevention/DriverTable";
import ScoringDetails from "../components/DamagePrevention/ScoringDetails";
import ReservationTable from "../components/DamagePrevention/ReservationTable";
import Dashboard from "../components/DamagePrevention/Dashboard";
import WarningTable from "../components/DamagePrevention/WarningTable";
import WarningInfo from "../components/DamagePrevention/WarningInfo";
import ScoringAverage from "../components/DamagePrevention/ScoringAverage";
import WarningMap from "../components/DamagePrevention/WarningMap";
import WarningView from "../components/DamagePrevention/WarningView";
import {ErrorBoundary} from "react-error-boundary";
import CVScreenHeader from "../common_components/Webapp/CVScreenHeader";
import {NumberOutlined} from "@ant-design/icons";

const {RangePicker} = DatePicker;

const {Title, Paragraph, Text, Link} = Typography;
const {Search} = Input;

const DamagePreventionScreen = (props) => {

    const navigate = useNavigate();

    const defaultStartDate = dayjs().subtract(1, 'month').startOf('month');
    const defaultEndDate = dayjs().endOf('month');

    const defaultStartDateWarning = dayjs().subtract(90, 'days')
    const defaultEndDateWarning = dayjs()

    const [updateCounter, setUpdateCounter] = useState(0);
    const [activeTab, setActiveTab] = useState('1');
    const [categoryValue, setCategoryValue] = useState('time');
    const [sortValue, setSortValue] = useState('aggressive');
    const [displaySize, setDisplaySize] = useState(10);
    const [dateRangeFilter, setDateRangeFilter] = useState([defaultStartDate, defaultEndDate]);
    const [dateRangeFilterWarning, setDateRangeFilterWarning] = useState([defaultStartDateWarning, defaultEndDateWarning]);
    const [minDistance, setMinDistance] = useState(100);
    const [minTripCount, setMinTripCount] = useState(10);
    const [minReservationCount, setMinReservationCount] = useState(2);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const [mapMarkers, setMapMarkers] = useState(null);
    const [headerData, setHeaderData] = useState(null);

    const [issueFilter, setIssueFilter] = useState(null);

    const isTabVisible = () => {
        return !document.hidden;
    };

    const handleCardClick = (value) => {
        setIssueFilter(value);
        if (issueFilter === value) {
            setIssueFilter(null)
        }
    };

    function sendMail() {
        let emailSubject = "Support"
        emailSubject += " Damage Prevention"
        emailSubject += ` - ${props.customer}`

        if (issueFilter) {
            emailSubject += ` - ${issueFilter}`
        }

        const body_ = ""
        const link =
            'mailto:' +
            "damage-support@carvaloo.com" +
            //+ "?cc="
            '?subject=' +
            encodeURIComponent(emailSubject) +
            '&body=' +
            encodeURIComponent(body_)
        window.location.href = link
    }

    useEffect(() => {
        const request = {
            customer: props.customer,
        }
        axiosInstance.post('/api/prevention/headers', request)
            .then(res => {
                setHeaderData([
                    {
                        name: 'Total reservations',
                        value: res.data.reservation_count,
                        icon: (<NumberOutlined/>),
                    },
                    {
                        name: 'Total drivers',
                        value: res.data.unique_count,
                        icon: (<NumberOutlined/>),
                    },
                    {
                        name: 'Active reservations',
                        value: res.data.reservation_active,
                        icon: (<NumberOutlined/>),
                    },
                    // {
                    //     name: 'Total drivers',
                    //     value: res.data.unique_count_trip,
                    //     icon: (<NumberOutlined/>),
                    // },
                ])

            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error in header data', description: error.message});
            })
    }, [props.customer]);

    const tabItems = [
        {
            key: '1',
            label: 'Driver Scoring',
        },
        {
            key: '2',
            label: 'Driver Warnings',
        },
        // {
        //   key: '3',
        //   label: 'Tab 3',
        //   children: 'Content of Tab Pane 3',
        // },
    ];

    return (<>
            <div style={{minWidth: 1375}}>
                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                    <CVScreenHeader
                        title={'Damage Prevention'}
                        headerData={headerData}
                        tabData={tabItems}
                        sendEmail={() => sendMail()}
                        setActiveTab={setActiveTab}
                        trial={props.trial}
                        limitedAccess={props.limitedAccess}
                    />
                </ErrorBoundary>
                {activeTab === "1" && (
                    <div style={{padding: 16}}>
                        <Row style={{marginBottom: '16px', display: 'flex'}}>
                            <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                <Configurator
                                    customer={props.customer}
                                    permissions={props.permissions}
                                    setCategoryValue={setCategoryValue}
                                    categoryValue={categoryValue}
                                    setSortValue={setSortValue}
                                    sortValue={sortValue}
                                    setDisplaySize={setDisplaySize}
                                    displaySize={displaySize}
                                    setDateRangeFilter={setDateRangeFilter}
                                    dateRangeFilter={dateRangeFilter}
                                    setMinDistance={setMinDistance}
                                    minDistance={minDistance}
                                    setMinTripCount={setMinTripCount}
                                    minTripCount={minTripCount}
                                    setMinReservationCount={setMinReservationCount}
                                    minReservationCount={minReservationCount}
                                />
                            </ErrorBoundary>
                            <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                <ScoringOverview
                                    customer={props.customer}
                                    categoryValue={categoryValue}
                                    sortValue={sortValue}
                                    displaySize={displaySize}
                                    dateRangeFilter={dateRangeFilter}
                                    minDistance={minDistance}
                                    minTripCount={minTripCount}
                                    minReservationCount={minReservationCount}
                                    setSelectedDriver={setSelectedDriver}
                                    selectedDriver={selectedDriver}
                                />
                            </ErrorBoundary>
                            {/*<ScoringAverage*/}
                            {/*    customer={props.customer}*/}
                            {/*    categoryValue={categoryValue}*/}
                            {/*    sortValue={sortValue}*/}
                            {/*    displaySize={displaySize}*/}
                            {/*    dateRangeFilter={dateRangeFilter}*/}
                            {/*    minDistance={minDistance}*/}
                            {/*    minTripCount={minTripCount}*/}
                            {/*    minReservationCount={minReservationCount}*/}
                            {/*    setSelectedDriver={setSelectedDriver}*/}
                            {/*    selectedDriver={selectedDriver}*/}
                            {/*/>*/}
                        </Row>
                        {selectedDriver &&
                            <div>
                                <div style={{marginBottom: '16px', display: 'flex'}}>
                                    <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                        <ScoringDetails
                                            customer={props.customer}
                                            categoryValue={categoryValue}
                                            selectedDriver={selectedDriver}
                                            dateRangeFilter={dateRangeFilter}
                                        />
                                    </ErrorBoundary>
                                    <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                        <ReservationTable
                                            customer={props.customer}
                                            selectedDriver={selectedDriver}
                                            dateRangeFilter={dateRangeFilter}
                                            categoryValue={categoryValue}
                                        />
                                    </ErrorBoundary>
                                </div>
                            </div>
                        }
                        <ErrorBoundary fallback={<div>Something went wrong</div>}>
                            <DriverTable
                                customer={props.customer}
                                categoryValue={categoryValue}
                                sortValue={sortValue}
                                dateRangeFilter={dateRangeFilter}
                                minDistance={minDistance}
                                minTripCount={minTripCount}
                                minReservationCount={minReservationCount}
                                setSelectedDriver={setSelectedDriver}
                                selectedDriver={selectedDriver}
                            />
                        </ErrorBoundary>
                    </div>
                )}
                {activeTab === "2" && (
                    <>
                        <Row style={{backgroundColor: "white"}}>
                            <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                <Dashboard
                                    customer={props.customer}
                                    update={updateCounter}
                                    handleCardClick={handleCardClick}
                                    issueFilter={issueFilter}
                                    dateRangeFilter={dateRangeFilterWarning}
                                />
                            </ErrorBoundary>
                        </Row>

                        <div style={{padding: 16}}>
                            <div style={{marginBottom: '16px', display: 'flex', flexWrap: 'wrap'}}>
                                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                    <WarningTable
                                        customer={props.customer}
                                        setDateRangeFilter={setDateRangeFilterWarning}
                                        dateRangeFilter={dateRangeFilterWarning}
                                        update={updateCounter}
                                        handleSelectedChange={setSelectedAlert}
                                        selectedAlert={selectedAlert}
                                        issueFilter={issueFilter}
                                    />
                                </ErrorBoundary>
                                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                    <WarningInfo
                                        customerId={props.customerId}
                                        customer={props.customer}
                                        permissions={props.permissions}
                                        selectedAlert={selectedAlert} // Pass null or appropriate data to the map
                                        setUpdateCounter={setUpdateCounter}
                                        setMapMarkers={setMapMarkers}
                                    />
                                </ErrorBoundary>
                            </div>
                            {selectedAlert &&
                                <div style={{marginBottom: '16px', display: 'flex', flexWrap: 'wrap'}}>
                                    <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                        <WarningMap
                                            customer={props.customer}
                                            update={updateCounter}
                                            mapMarkers={mapMarkers}
                                            // issueFilter={issueFilter}
                                            // actionFilter={actionFilter}
                                            selectedAlert={selectedAlert} // Pass null or appropriate data to the map
                                        />
                                    </ErrorBoundary>
                                    <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                        <WarningView
                                            customer={props.customer}
                                            update={updateCounter}
                                            mapMarkers={mapMarkers}
                                        />
                                    </ErrorBoundary>
                                </div>
                            }
                        </div>
                    </>
                )}
            </div>
        </>
    )
        ;
}

export default DamagePreventionScreen;
