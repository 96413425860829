import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';
import ReactJson from "react-json-view";
import {
    Alert,
    Button,
    Card,
    Descriptions,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Popover,
    Switch,
    Steps,
    Table,
    Divider, Tooltip, Statistic
} from 'antd';

import Icon, {
    InfoCircleOutlined,
    CloseOutlined,
    CheckOutlined,
    BellOutlined,
    ExclamationCircleFilled,
    WarningFilled, CloseCircleFilled, CheckCircleOutlined, AlertOutlined, CopyOutlined
} from "@ant-design/icons";
import {FaFile, FaCog} from 'react-icons/fa';
import axiosInstance from "services/axios";
import ToDoInfoModal from "../DashboardCarvaloo/ToDoInfoModal";
import ResolveModal from "./ResolveModal";
import "./custom.css";
import calendarIcon from '../../assets/icons/calendar.svg';
import numberIcon from '../../assets/icons/number.svg';
import editIcon from '../../assets/icons/edit.svg';
import compassIcon from '../../assets/icons/compass.svg';
import checkIcon from '../../assets/icons/check.svg';
import crossIcon from '../../assets/icons/red_x.svg';
import {SettingsContext} from "../../App.js";
import {convertKilometersToMiles, timezoneDateString, timezoneTimeString} from '../../utility/conversions.js';

import geoFencingIcon from '../../assets/icons/geofencing.svg';
import reservationIcon from '../../assets/icons/reservation.svg';
import theftIcon from "../../assets/icons/theft.svg";
import suspiciousIcon from "../../assets/icons/suspicious.svg";

const iconDictionary = {
    geofencing: geoFencingIcon,
    reservation: reservationIcon,
    theft: theftIcon,
    suspicious: suspiciousIcon
};

const {Search} = Input;
const {Title, Text} = Typography;

const AlertInfo = (props) => {

    const {timezone, distance} = useContext(SettingsContext);
    const [showResolveModal, setShowResolveModal] = useState(false);
    // const [showEventModal, setShowEventModal] = useState(false);
    const [resolveType, setResolveType] = useState(null);

    const [refreshKey, setRefreshKey] = useState(0);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [vehicleTrackingUrl, setVehicleTrackingUrl] = useState(null);
    const [alertMatching, setAlertMatching] = useState(null);
    const [eventExists, setEventExists] = useState(false);

    const InfoTooltip = ({text}) => {
        return (
            <Tooltip title={text}>
                <InfoCircleOutlined style={{color: '#1890ff', fontSize: '12px', marginLeft: '5px'}}/>
            </Tooltip>
        );
    };

    const currentURL = window.location.href;
    let urlBase = "https://incident.carvaloo.com/"
    let urlWebApp = "https://webapp.carvaloo.com/"
    let queryParameters = ""
    if (currentURL.includes("incident-dev")) {
        // Redirect to the development URL
        urlBase = "https://incident-dev.carvaloo.com/";
        urlWebApp = "https://webapp-dev.carvaloo.com/";
    }
    if (currentURL.includes("localhost")) {
        // Redirect to the development URL
        urlBase = "http://localhost:3000/";
        urlWebApp = "https://webapp-dev.carvaloo.com/";
    }

    useEffect(() => {

        if (props.selectedAlert) {

            setLoading(true)

            axiosInstance.post(
                '/api/geofunctions/alert/info',
                {alert_id: props.selectedAlert, customer: props.customer})
                .then(res => {
                    setData(res.data)
                    setLoading(false)
                    setEventExists(res.data.event_exists)
                    setAlertMatching(res.data.matching)
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false)
                    setData(null)
                    notification['error']({
                        message: 'Error getting alert info',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                })
        } else {
            setData(null)
        }
    }, [props.selectedAlert]);

    useEffect(() => {

        if (data) {

            const from = new Date(data?.event_ts_utc)
            from.setHours(from.getHours() - 12);

            const to = new Date(data?.event_ts_utc)
            to.setHours(to.getHours() + 12);

            let url = new URL(urlBase + 'fleet_tracking?organization=' + props.customerId);
            url.searchParams.set('search', data.vehicle_id);
            url.searchParams.set('stationary', 'false');
            url.searchParams.set('tracking', 'true');
            url.searchParams.set('from', from.toISOString());
            url.searchParams.set('to', to.toISOString());

            setVehicleTrackingUrl(url)
        }
    }, [data]);


    const handleDeselectClick = (value) => {
        props.handleSelectedChange(null)
    };

    const handleDismissClick = (value) => {

        setResolveType('dismiss')

        if (showResolveModal === true) {
            setShowResolveModal(false)
        } else {
            setShowResolveModal(true);
        }

    };

    const handleConfirmClick = (value) => {

        setResolveType('confirm')

        if (showResolveModal === true) {
            setShowResolveModal(false)
        } else {
            setShowResolveModal(true);
        }

    };

    const handleCreateEventClick = (value) => {
        const payload = {
            customer: props.customer,
            uaid: data.UAID,
            event_id: data.event_id,
        }

        axiosInstance.post('/api/geofunctions/alert/event', payload)
            .then(res => {
                notification['success']({
                    message: 'Success creating webapp event ' + data.event_id,
                    duration: 5,
                    maxCount: 1
                })
                setEventExists(true)
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error', description: error.message});
            })
    };

    const handleSilenceClick = (value) => {
        if (value === 'active') {
            props.setActionFilter(['firing', 'pending'])
        } else {
            props.setActionFilter([value])
        }
        props.handleSelectedChange(null)
    };

    const renderTimestamp = (timestamp, timezone) => (
        <div>
            {timezoneDateString(timestamp, timezone)} <br/> {timezoneTimeString(timestamp, timezone)}
        </div>
    );

    const handleCommentChange = (value) => {

        console.log(value)

        if (value.nativeEvent.data) {
            setData((prevData) => {

                const newComment = prevData.comment ? prevData.comment + value.nativeEvent.data : value.nativeEvent.data

                return {
                    ...prevData,
                    comment: newComment,
                };
            });
        }
    };

    const handleCopy = (text) => {
        // Logic to copy text to clipboard
        navigator.clipboard.writeText(text);
        // Optionally, provide feedback to the user that the text has been copied
    };

    function capitalizeFirstLetter(string) {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        } else {
            return string
        }
    }

    const splitStringIntoChunks = (str, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < str.length; i += chunkSize) {
            chunks.push(str.substring(i, i + chunkSize)); // or use str.slice(i, i + chunkSize)
        }
        return chunks;
    };

    const wrapValueFormatter = (value) => {
        if (!value) return 'Not available';

        const chunks = splitStringIntoChunks(value, 24);

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                {chunks.map((chunk, index) => (
                    <span key={index}>{chunk}</span>
                ))}
            </div>
        );
    };

    return (<>
            <ResolveModal
                showModal={() => setShowResolveModal(true)}
                hideModal={() => setShowResolveModal(false)}
                open={showResolveModal}
                resolveType={resolveType}
                data={data}
                setData={setData}
                refreshKey={refreshKey}
                setRefreshKey={setRefreshKey}
                customer={props.customer}
                setUpdateCounter={props.setUpdateCounter}
                setEventExists={setEventExists}
                setAlertMatching={setAlertMatching}
            />
            <Card
                style={{
                    position: "absolute",
                    top: 24,
                    right: 16,
                    zIndex: 2,
                    width: 470,
                    height: 500,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                loading={loading}
                styles={{
                    body: {
                        paddingRight: 24,
                        paddingLeft: 24,
                        paddingTop: 10,
                        paddingBottom: 10,
                        opacity: props.selectedAlert ? 1 : 0.25,
                    }
                }}
                title={<>
                    {data?.misuse_category &&
                        <img src={iconDictionary[data?.misuse_category]} alt={data?.misuse_category} width="24"
                             height="24" style={{marginBottom: -6, marginRight: 8}}/>
                    }
                    <Text style={{marginRight: 16, fontSize: 16}}>
                        {capitalizeFirstLetter(data?.misuse_category)}
                    </Text>
                    {/*{data?.misuse_details.geofence_name ?*/}
                    {/*    <Text style={{marginRight: 16, fontSize: 12, color: '#767676', fontWeight: 300}}>*/}
                    {/*        {data?.misuse_details.geofence_name} {data?.misuse_type}*/}
                    {/*    </Text>*/}
                    {/*    :*/}
                    <Text style={{marginRight: 16, fontSize: 12, color: '#767676', fontWeight: 300}}>
                        {data?.misuse_type}
                    </Text>
                    {/*}*/}
                </>
                }
                extra={
                    data &&
                    <>
                        {!loading && alertMatching !== false && props.permissions.security === 'fleet-security_editor' &&
                            <Button
                                onClick={handleDismissClick}
                                // icon={<CheckOutlined style={{marginRight: 8}}/>}
                                style={{
                                    backgroundColor: '#ffffff',
                                    color: '#E55555',
                                    borderColor: '#E55555',
                                    marginLeft: 12
                                }}
                            >
                                Dismiss
                            </Button>
                        }
                        {!loading && alertMatching !== true && props.permissions.security === 'fleet-security_editor' && data.misuse_category !== 'geofencing' &&
                            <Button
                                onClick={handleConfirmClick}
                                type={'primary'}
                                // icon={<CheckOutlined style={{marginRight: 8}}/>}
                                style={{
                                    marginLeft: 12
                                }}
                            >
                                Confirm
                            </Button>
                        }
                        {!loading && alertMatching && eventExists === false && props.permissions.security === 'fleet-security_editor' && data.misuse_category !== 'geofencing' &&
                            <Button
                                onClick={handleCreateEventClick}
                                type={'primary'}
                                // icon={<CheckOutlined style={{marginRight: 8}}/>}
                                style={{
                                    marginLeft: 12
                                }}
                            >
                                Create Event
                            </Button>
                        }
                        {!loading && alertMatching !== null && eventExists === true &&
                            <Button
                                onClick={() => {
                                    window.open(urlWebApp + '/ereignisse/' + data.event_id + "?organization=" + props.customerId, '_blank')
                                }}
                                type={'primary'}
                                // icon={<CheckOutlined style={{marginRight: 8}}/>}
                                style={{
                                    marginLeft: 12
                                }}
                            >
                                View Event
                            </Button>
                        }
                    </>
                }
            >
                <CloseOutlined
                    onClick={handleDeselectClick} // Make the icon clickable
                    style={{
                        position: 'absolute', // Position the icon absolutely within the card
                        top: -10,
                        left: -10,
                        zIndex: 3, // Ensure the icon is above other content
                        cursor: 'pointer', // Change the cursor to pointer on hover
                        fontSize: '16px', // Adjust the size of the icon
                        color: 'gray', // Set the icon color to gray
                        backgroundColor: 'white', // Set the background color to white
                        borderRadius: '50%', // Make the background a circle
                        padding: 4,
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
                    }}
                />
                {data &&
                    <Row>
                        <Steps
                            style={{marginTop: 16}}>
                            <Steps.Step
                                title={<>
                                    <Text style={{marginRight: 16}}>
                                        Pending
                                    </Text>
                                </>}
                                style={{marginLeft: 8}}
                                description={data.pending_ts ? renderTimestamp(data.pending_ts, timezone) : ''}
                                icon={<ExclamationCircleFilled
                                    style={{fontSize: 28, color: '#088ED7', opacity: data.pending_ts ? 1 : 0.1}}/>}
                                status={data.pending_ts ? 'current' : 'wait'}
                            />
                            <Steps.Step
                                title={<>
                                    <Text style={{marginRight: 16}}>
                                        Firing
                                    </Text>
                                </>}
                                description={data.firing_ts ? renderTimestamp(data.firing_ts, timezone) : ''}
                                icon={<WarningFilled
                                    style={{fontSize: 28, color: '#088ED7', opacity: data.firing_ts ? 1 : 0.1}}/>}
                                status={data.firing_ts ? 'current' : 'wait'}
                            />
                            <Steps.Step
                                title={<>
                                    <Text style={{marginRight: 16}}>
                                        {alertMatching === null ? data.resolved_ts ? 'Resolved' : 'Feedback' : alertMatching ? 'Confirmed' : 'Dismissed'}
                                    </Text>
                                </>}
                                style={{marginRight: 10}}
                                description={
                                    data.resolved_ts ? renderTimestamp(data.resolved_ts, timezone) : (
                                        <>
                                            Timestamp
                                            <br/>
                                            not available
                                        </>
                                    )}
                                icon={alertMatching === false ?
                                    <CloseCircleFilled
                                        style={{
                                            fontSize: 28,
                                            color: '#E55555',
                                        }}/> :
                                    <CheckCircleOutlined
                                        style={{fontSize: 28, color: '#088ED7', opacity: data.resolved_ts ? 1 : 0.1}}/>}
                                status={data.resolved_ts ? 'current' : 'wait'}
                            />
                        </Steps>
                    </Row>
                }
                <Divider style={{marginBottom: 16}}></Divider>
                {/*<Text style={{marginRight: 16, fontSize: 12, color: '#767676', fontWeight: 300}}>*/}
                {/*    {data?.UAID}*/}
                {/*</Text>*/}
                {/*{data?.misuse_category === 'geofencing' &&*/}
                {/*    <Statistic*/}
                {/*        title="Alert Details"*/}
                {/*        value={*/}
                {/*            data?.misuse_type ?*/}
                {/*                `${data.misuse_details.geofence_name}: ${data.misuse_type}` :*/}
                {/*                '.'*/}
                {/*        }*/}
                {/*        prefix={<AlertOutlined style={{marginRight: 4}}/>}*/}
                {/*        valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}*/}
                {/*        size="small"*/}
                {/*    />}*/}
                {/*{data?.misuse_category !== 'geofencing' &&*/}
                {/*    <Statistic*/}
                {/*        title="Alert details"*/}
                {/*        value={*/}
                {/*            data?.misuse_type ?*/}
                {/*                `${data.misuse_type}` :*/}
                {/*                '.'*/}
                {/*        }*/}
                {/*        prefix={<AlertOutlined style={{marginRight: 4}}/>}*/}
                {/*        valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}*/}
                {/*        size="small"*/}
                {/*    />}*/}
                <Row style={{marginTop: 0, marginBottom: 0}}>
                    {/*<Text style={{marginRight: 16, fontSize: 15, color: '#767676', fontWeight: 300}}>*/}
                    {/*    {data?.UAID}*/}
                    {/*</Text>*/}
                    <Col span={24}>
                        <Statistic
                            groupSeparator={''}
                            title="Event ID"
                            value={
                                data?.event_id ?
                                    `${data?.event_id}` :
                                    'Not available'
                            }
                            prefix={<img src={numberIcon} alt={numberIcon} style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 0}}
                            size="small"
                        />
                    </Col>
                    {/*<Col span={12}>*/}
                    {/*    <Statistic*/}
                    {/*        title="Distance from station"*/}
                    {/*        value={*/}
                    {/*            data?.station_distance !== undefined && data.station_distance !== null ?*/}
                    {/*                (data.station_distance !== 0 ? `${convertKilometersToMiles(data.station_distance, distance)}` : '0') :*/}
                    {/*                'Not available'*/}
                    {/*        }*/}
                    {/*        prefix={<img src={compassIcon} alt={compassIcon}*/}
                    {/*                     style={{marginBottom: -4, marginRight: 4}}/>}*/}
                    {/*        suffix={distance === 'kilometers' ? 'km' : 'miles'}*/}
                    {/*        valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}*/}
                    {/*        size="small"*/}
                    {/*    />*/}
                    {/*</Col>*/}
                </Row>
                {/*<Divider style={{marginBottom: 8, marginTop: 8}}></Divider>*/}
                <Row style={{marginTop: 0}}>
                    <Col span={12}>
                        <Statistic
                            title="Misuse timestamp"
                            value={
                                data?.event_ts_utc ?
                                    `${timezoneDateString(data.event_ts_utc, timezone)} ${timezoneTimeString(data.event_ts_utc, timezone)}` :
                                    'Not available'
                            }
                            prefix={<img src={calendarIcon} alt={calendarIcon}
                                         style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                        />
                    </Col>
                    <Col span={12}>
                        <Statistic
                            title="Reservation status"
                            value={
                                data?.reservation_active !== undefined ?
                                    data?.reservation_active === true ? 'Active' : 'Not Active'
                                    : 'Not available'
                            }
                            prefix={
                                data?.reservation_active ? (
                                    <img src={checkIcon} alt="Check Icon" style={{marginBottom: -4, marginRight: 4}}/>
                                ) : (
                                    <img src={crossIcon} alt="Cross Icon" style={{marginBottom: -2, marginRight: 4}}/>
                                )
                            }
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                        />
                    </Col>
                </Row>

                <Row style={{marginTop: 0, marginBottom: 0}}>
                    <Col span={12}>
                        <Statistic
                            title="Last GPS position"
                            value={
                                data?.last_ts ?
                                    `${timezoneDateString(data.last_ts, timezone)} ${timezoneTimeString(data.last_ts, timezone)}` :
                                    'Not available'
                            }
                            prefix={<img src={calendarIcon} alt={calendarIcon}
                                         style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 0}}
                            size="small"
                        />
                    </Col>
                    <Col span={12}>
                        <Statistic
                            title="Reservation ID"
                            groupSeparator={""}
                            value={
                                data?.reservation_id ?
                                    `${data.reservation_id.length > 10 ? data.reservation_id.slice(0, 10) + '...' : data.reservation_id}` :
                                    'Not available'
                            }
                            prefix={<img src={numberIcon} alt={numberIcon} style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 0}}
                            size="small"
                            suffix={
                                <Button
                                    type="link"
                                    icon={<CopyOutlined style={{marginTop: -16}}/>}
                                    onClick={() => handleCopy(data?.reservation_id)}
                                />
                            }
                        />
                    </Col>
                </Row>

                <Row style={{marginTop: 0}}>
                    <Col span={12}>
                        <Statistic
                            groupSeparator={''}
                            title="Vehicle ID"
                            value={data?.vehicle_id || 'Not available'}
                            formatter={(value) =>
                                data?.vehicle_uuid ? (
                                    <a
                                        href={`https://webapp.carvaloo.com/fahrzeug/${encodeURIComponent(data?.vehicle_id)}?UVID=${encodeURIComponent(data?.vehicle_uuid)}&organization=${encodeURIComponent(props.customerId)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {value}
                                    </a>
                                ) : (
                                    'Not available'
                                )
                            }
                            suffix={
                                <Button
                                    type="link"
                                    icon={<CopyOutlined style={{fontSize: 16, marginTop: -16}}/>}
                                    onClick={() => handleCopy(data?.vehicle_id)}
                                />
                            }
                            prefix={<img src={numberIcon} alt={numberIcon} style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                        />
                    </Col>
                    <Col span={12}>
                        <Statistic
                            title="Station"
                            value={data?.station}
                            prefix={<img src={compassIcon} alt={compassIcon}
                                         style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                            formatter={wrapValueFormatter}
                        />
                    </Col>
                </Row>
                {/*<Row style={{marginTop: 8}}>*/}
                {/*    <Statistic*/}
                {/*        title="Comment"*/}
                {/*        valueStyle={{fontSize: 15, fontWeight: 500,  marginBottom: -4, height: 0}}*/}
                {/*        size="small"*/}
                {/*    />*/}
                {/*    /!*<Text style={{color: 'gray', fontWeight: 400, opacity: 0.4}}>*!/*/}
                {/*    /!*    Comment*!/*/}
                {/*    /!*</Text>*!/*/}
                {/*</Row>*/}
                {/*<Row style={{marginTop: 8}}>*/}
                {/*    <Search*/}
                {/*        value={data?.comment}*/}
                {/*        prefix={<img src={editIcon} alt={editIcon} style={{marginBottom: -4}}/>}*/}
                {/*        onChange={handleCommentChange}*/}
                {/*        placeholder={'Add a comment'}*/}
                {/*        style={{fontSize: 15, fontWeight: 500}}*/}
                {/*        enterButton="Submit"*/}
                {/*        // onSearch={onSearch}*/}
                {/*    />*/}
                {/*</Row>*/}
                {data?.stage === 2 &&
                    <Row style={{marginTop: 8}}>
                        <Tooltip
                            title={
                                data?.inspection_result ?
                                    `${data.inspection_result.length > 500 ? `${data.inspection_result.slice(0, 500)}...` : data.inspection_result}` :
                                    'no comment'
                            }
                        >
                            <Statistic
                                title="Inspection Result"
                                value={data?.inspection_result ?
                                    `${data.inspection_result.length > 50 ? `${data.inspection_result.slice(0, 50)}...` : data.inspection_result}` :
                                    'no comment'
                                }
                                prefix={<img src={editIcon} alt={editIcon} style={{marginBottom: -4, marginRight: 4}}/>}
                                valueStyle={{
                                    fontSize: 15,
                                    fontWeight: 500,
                                    marginBottom: 6,
                                    width: 420,
                                }}
                                size="small"
                            />
                        </Tooltip>
                    </Row>
                }
                {/*{props.permissions.tracking && data &&*/}
                {/*    <>*/}
                {/*        <Button*/}
                {/*            type="primary"*/}
                {/*            href={vehicleTrackingUrl}*/}
                {/*            target="_blank"*/}
                {/*        >*/}
                {/*            View in Fleet Tracking*/}
                {/*        </Button>*/}
                {/*    </>*/}
                {/*}*/}
            </Card>
        </>
    )
        ;
}


export default AlertInfo;
