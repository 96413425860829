import React, {useState, useRef, useCallback, useEffect} from 'react';
import ReactJson from "react-json-view";
import {
    Button,
    Card,
    Descriptions,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Popover,
    Switch,
    Table,
    Divider
} from 'antd';

import Icon from "@ant-design/icons";
import axiosInstance from "services/axios";
import DashboardCard from "./DashboardCard";

import stationIcon from '../../assets/icons/station_3.svg';
import geoFencingIcon from '../../assets/icons/geofencing.svg';
import reservationIcon from '../../assets/icons/reservation.svg';
import theftIcon from "../../assets/icons/theft.svg";
import suspiciousIcon from "../../assets/icons/suspicious.svg";

const Dashboard = (props) => {

    const [data, setData] = useState(null);

    useEffect(() => {
        const payload = {
            customer: props.customer,
            date_range: props.dateRangeFilter,
        };

        axiosInstance.post('/api/prevention/warnings/dashboard', payload)
            .then(res => {
                setData(res.data)
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error in dashboard', description: error.message});
            })
    }, [props.update, props.dateRangeFilter]);

    return (
        <>
            <Row style={{padding: 24}} gutter={[8, 24]}>
                <DashboardCard
                    card={'warning_1'}
                    title={'First Warning'}
                    tagColor={'#FABE0A'}
                    active={data?.warning_1.active}
                    total={data?.warning_1.total}
                    resolved={data?.warning_1.resolved}
                    trend={0}
                    handleCardClick={props.handleCardClick}
                    issueFilter={props.issueFilter}
                />
                <DashboardCard
                    card={'warning_2'}
                    title={'Second Warning'}
                    tagColor={'#F6823C'}
                    active={data?.warning_2.active}
                    total={data?.warning_2.total}
                    resolved={data?.warning_2.resolved}
                    trend={0}
                    handleCardClick={props.handleCardClick}
                    issueFilter={props.issueFilter}
                />
                <DashboardCard
                    card={'warning_3'}
                    title={'Third+ Warning'}
                    tagColor={'#E55555'}
                    active={data?.warning_3.active}
                    total={data?.warning_3.total}
                    resolved={data?.warning_3.resolved}
                    trend={0}
                    handleCardClick={props.handleCardClick}
                    issueFilter={props.issueFilter}
                />
            </Row>
        </>
    );
}


export default Dashboard;
