import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';
import ReactJson from "react-json-view";
import {
    Alert,
    Button,
    Card,
    Descriptions,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Popover,
    Switch,
    Steps,
    Table,
    Divider, Tooltip, Statistic, Timeline
} from 'antd';

import Icon, {InfoCircleOutlined, CheckOutlined, BellOutlined, CarOutlined, AlertOutlined} from "@ant-design/icons";
import {FaFile, FaCog} from 'react-icons/fa';
import axiosInstance from "services/axios";
import ToDoInfoModal from "../DashboardCarvaloo/ToDoInfoModal";
import ResolveModal from "./ResolveModal";
import "./custom.css";
import calendarIcon from '../../assets/icons/calendar.svg';
import numberIcon from '../../assets/icons/number.svg';
import editIcon from '../../assets/icons/edit.svg';
import compassIcon from '../../assets/icons/compass.svg';
import checkIcon from '../../assets/icons/check.svg';
import crossIcon from '../../assets/icons/red_x.svg';
import {SettingsContext} from "../../App.js";

import {convertKilometersToMiles} from '../../utility/conversions.js';

const {Search} = Input;
const {Title, Text} = Typography;

const ConfigInfo = (props) => {

    const {timezone, distance} = useContext(SettingsContext);
    const [showResolveModal, setShowResolveModal] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    const InfoTooltip = ({text}) => {
        return (
            <Tooltip title={text}>
                <InfoCircleOutlined style={{color: '#1890ff', fontSize: '12px', marginLeft: '5px'}}/>
            </Tooltip>
        );
    };

    const [data, setData] = useState(null);

    useEffect(() => {

        if (props.selectedAlert) {
            axiosInstance.post(
                '/api/geofunctions/alert/info',
                {alert_id: props.selectedAlert, customer: props.customer})
                .then(res => {
                    // console.log(res)
                    setData(res.data)
                })
                .catch(error => {
                    console.log(error);
                    setData(null)
                    notification['error']({
                        message: 'Error getting alert info',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                })
        } else {
            setData(null)
        }
    }, [props.selectedAlert]);

    return (<>
            <Card
                style={{
                    width: 400,
                    height: 770,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #e8e8e8',
                    marginRight: 4,
                }}
                styles={{
                    body: {
                        paddingRight: 24,
                        paddingLeft: 24,
                        paddingTop: 16,
                        paddingBottom: 10,
                    }
                }}
                title="Geofence Details"
            >
                {props.configData &&
                    <div style={{marginTop: -20}}>
                        {Object.values(props.configData).map((item, index) => (
                            <div key={`${item.name}-${index}`}>
                                <Row style={{marginBottom: 8, marginTop: 20}}>
                                    {item.notification ?
                                        <Tooltip
                                            title={'Notifications are active for this geofence.'}>
                                            <AlertOutlined style={{color: 'red'}}/>
                                        </Tooltip>
                                        :
                                        <Tooltip
                                            title={'Notifications are not activated for this geofence.'}>
                                            <AlertOutlined/>
                                        </Tooltip>
                                    }
                                    <Text
                                        key={`${item.name}-${index}`}
                                        title={item.name}
                                        style={{fontWeight: 500, fontSize: 16, marginLeft: 8}}
                                    >
                                        {item.name}
                                    </Text>
                                </Row>
                                <Row key={`${item.name}-alert-${index}`} style={{marginBottom: 8}}>
                                    <Text
                                        key={`${item.name}-alert-${index}`}
                                        title={item.name}
                                        style={{fontWeight: 500, fontSize: 14, marginLeft: 8}}
                                    >
                                        {item.alert_type} -
                                        pending: {item.pending.toString()} min {item.resolve_time ? ' - auto resolve ' + item.resolve_time.toString() + ' min' : ''}
                                    </Text>
                                </Row>
                            </div>
                        ))}
                    </div>
                }
            </Card>
        </>
    );
}


// suffix={' - pending: ' + item.pending.toString() + ' minutes'}


export default ConfigInfo;
