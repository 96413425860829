import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';
import ReactJson from "react-json-view";
import {
    Alert,
    Button,
    Card,
    ConfigProvider,
    Descriptions,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Popover,
    Switch,
    Steps,
    Table,
    Divider, Tooltip, Statistic
} from 'antd';

import Icon, {
    InfoCircleOutlined,
    CheckOutlined,
    BellOutlined,
    CopyOutlined,
    CarOutlined,
    UserOutlined,
    ExclamationOutlined,
    WarningOutlined,
    CloseCircleFilled,
    ExclamationCircleOutlined,
    ExclamationCircleFilled,
    WarningFilled, ClockCircleOutlined, MessageOutlined, CalendarOutlined, NumberOutlined
} from "@ant-design/icons";
import {FaFile, FaCog} from 'react-icons/fa';
import axiosInstance from "services/axios";
import ToDoInfoModal from "../DashboardCarvaloo/ToDoInfoModal";
import "./custom.css";
import calendarIcon from '../../assets/icons/calendar.svg';
import numberIcon from '../../assets/icons/number.svg';
import editIcon from '../../assets/icons/edit.svg';
import compassIcon from '../../assets/icons/compass.svg';
import checkIcon from '../../assets/icons/check.svg';
import crossIcon from '../../assets/icons/red_x.svg';
import SmallChart from "./SmallChart";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import {SettingsContext} from "../../App.js";
import {convertKilometersToMiles, timezoneDateString, timezoneTimeString} from '../../utility/conversions.js';

const {Search} = Input;
const {Title, Text} = Typography;

const WarningInfo = (props) => {

    const {timezone, distance} = useContext(SettingsContext);
    const [loading, setLoading] = useState(false);
    const [driverData, setDriverData] = useState(null);
    const [data, setData] = useState(null);
    const [stepStatus, setStepStatus] = useState({
        step1: 'wait',
        step2: 'wait',
        step3: 'wait',
        opacityStep1: 0.1,
        opacityStep2: 0.1,
        opacityStep3: 0.1,
    });

    const currentURL = window.location.href;
    let urlBase = "https://incident.carvaloo.com/"
    let queryParameters = ""
    if (currentURL.includes("incident-dev")) {
        // Redirect to the development URL
        urlBase = "https://incident-dev.carvaloo.com/";
    }
    if (currentURL.includes("localhost")) {
        // Redirect to the development URL
        urlBase = "http://localhost:3000/";
    }

    const getStepStatus = (eventLevel) => {

        const level = parseInt(eventLevel.split('_')[1]);

        switch (level) {
            case 1:
                return {
                    step1: 'current',
                    step2: 'wait',
                    step3: 'wait',
                    opacityStep1: 1,
                    opacityStep2: 0.1,
                    opacityStep3: 0.1,
                };
            case 2:
                return {
                    step1: 'current',
                    step2: 'current',
                    step3: 'wait',
                    opacityStep1: 1,
                    opacityStep2: 1,
                    opacityStep3: 0.1,
                };
            case 3:
                return {
                    step1: 'current',
                    step2: 'current',
                    step3: 'current',
                    opacityStep1: 1,
                    opacityStep2: 1,
                    opacityStep3: 1,
                };
            // Add more cases as needed
            default:
                return {
                    step1: 'wait',
                    step2: 'wait',
                    step3: 'wait',
                    opacityStep1: 0.1,
                    opacityStep2: 0.1,
                    opacityStep3: 0.1,
                };
        }
    };

    useEffect(() => {

        if (props.selectedAlert) {

            setLoading(true)

            axiosInstance.post(
                '/api/prevention/warning/info',
                {alert_id: props.selectedAlert, customer: props.customer})
                .then(res => {
                    // console.log(res.data)
                    setData(res.data)
                    props.setMapMarkers(
                        {
                            'warnings': res.data.markers,
                            'misuses': res.data.secondary_markers
                        }
                    )
                    setStepStatus(getStepStatus(res.data?.event_label))
                    setLoading(false)
                })
                .catch(error => {
                    console.log(error);
                    setData(null)
                    notification['error']({
                        message: 'Error getting alert info',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                })

        } else {
            setData(null)
        }
    }, [props.selectedAlert]);

    const renderTimestamp = (timestamp) => (
        <div style={{fontSize: 12}}>
            {timezoneDateString(timestamp, timezone)} {timezoneTimeString(timestamp, timezone)}
        </div>
    );

    let timeSinceLastWarning = 'Not available'
    if (data) {

        if (data?.last_alert === 'NaT') {
            timeSinceLastWarning = '-------'
        } else {
            // Get the last alert date from your data
            const thisAlertDate = data?.alert_timestamp ? new Date(data.alert_timestamp) : null;
            const lastAlertDate = data?.last_alert ? new Date(data.last_alert) : null;

            // Calculate the time difference in milliseconds
            const timeDifference = lastAlertDate ? thisAlertDate - lastAlertDate : 0;

            // Calculate the time in months and days
            const months = Math.floor(timeDifference / (30 * 24 * 60 * 60 * 1000));
            const days = Math.floor((timeDifference % (30 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000));

            // Render the time since last warning
            timeSinceLastWarning = lastAlertDate ?
                `${months} months ${days} days` :
                'Not available';
        }

    }

    const handleCopy = (text) => {
        // Logic to copy text to clipboard
        navigator.clipboard.writeText(text);
        // Optionally, provide feedback to the user that the text has been copied
    };

    return (<>
            <Card
                style={{
                    width: 600,
                    height: 688,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #e8e8e8',
                    marginLeft: 16
                }}
                styles={{
                    body: {
                        paddingRight: 24,
                        paddingLeft: 24,
                        paddingTop: 10,
                        paddingBottom: 10,
                        opacity: props.selectedAlert ? 1 : 0.25,
                    }
                }}
                loading={loading}
                title="Warning Details"
            >
                {data &&
                    <Steps style={{marginTop: 16}}>
                        <Steps.Step
                            title="First Warning"
                            description={data.timestamp_warning_1 ? renderTimestamp(data.timestamp_warning_1) : ''}
                            icon={<ExclamationCircleFilled
                                style={{color: '#FABE0A', opacity: stepStatus.opacityStep1}}/>}
                            status={stepStatus.step1}
                        />
                        <Steps.Step
                            title="Second Warning"
                            description={data.timestamp_warning_2 ? renderTimestamp(data.timestamp_warning_2) : ''}
                            icon={<WarningFilled style={{color: '#F6823C', opacity: stepStatus.opacityStep2}}/>}
                            status={stepStatus.step2}
                        />
                        <Steps.Step
                            title="Third Warning"
                            description={data.timestamp_warning_3 ? renderTimestamp(data.timestamp_warning_3) : ''}
                            icon={<CloseCircleFilled style={{color: '#E55555', opacity: stepStatus.opacityStep3}}/>}
                            status={stepStatus.step3}
                        />
                    </Steps>
                }
                <Divider style={{marginTop: 16, marginBottom: 16}}></Divider>
                {/*</Tooltip>*/}
                <Statistic
                    style={{marginTop: 8}}
                    title="Driver ID"
                    groupSeparator={''}
                    value={data?.customer_id || 'Not available'}
                    prefix={<UserOutlined style={{marginRight: 4}}/>}
                    valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                    size="small"
                    suffix={
                        <Button
                            type="link"
                            icon={<CopyOutlined style={{marginRight: 4, marginTop: -16}}/>}
                            onClick={() => handleCopy(data?.customer_id)}
                        />
                    }
                />
                <Row style={{marginTop: 0}}>
                    <Col span={12}>
                        <Statistic
                            title="Customer since"
                            value={new Date(data?.customer_since).toLocaleDateString() || ''}
                            prefix={<CalendarOutlined style={{marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                        />
                    </Col>
                    <Col span={12}>
                        <Statistic
                            title="Total reservations"
                            value={data?.total_reservation_count || 'Not available'}
                            prefix={<InfoCircleOutlined style={{marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: 0}}>
                    <Col span={12}>
                        <Statistic
                            title="Total driven trips since"
                            value={data?.total_trip_count || 'Not available'}
                            prefix={<CarOutlined style={{marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                        />
                    </Col>
                    <Col span={12}>
                        <Statistic
                            title="Total driven distance"
                            value={data?.total_distance || 'Not available'}
                            prefix={<CarOutlined style={{marginRight: 4}}/>}
                            suffix={distance === 'kilometers' ? 'km' : 'miles'}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                        />
                    </Col>
                </Row>
                <Divider style={{marginTop: 16, marginBottom: 16}}></Divider>
                {/*</Tooltip>*/}
                <Statistic
                    style={{marginTop: 8}}
                    title="Warning ID"
                    groupSeparator={''}
                    value={data?.warning_id || 'Not available'}
                    prefix={<UserOutlined style={{marginRight: 4}}/>}
                    valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                    size="small"
                    suffix={
                        <Button
                            type="link"
                            icon={<CopyOutlined style={{marginTop: -16}}/>}
                            onClick={() => handleCopy(data?.warning_id)}
                        />
                    }
                />
                <Row>
                    <Statistic
                        title="Reservation ID"
                        groupSeparator={''}
                        value={
                            data?.reservation_id ?
                                `${data.reservation_id.length > 36 ? data.reservation_id.slice(0, 36) + '...' : data.reservation_id}` :
                                'Not available'
                        }
                        prefix={<NumberOutlined style={{marginRight: 4}}/>}
                        valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                        size="small"
                        suffix={
                            <Button
                                type="link"
                                icon={<CopyOutlined style={{marginTop: -16}}/>}
                                onClick={() => handleCopy(data?.reservation_id)}
                            />
                        }
                    />
                    <Button
                        type="primary"
                        href={
                            urlBase + 'fleet_tracking?organization=' + props.customerId + '&search=' + data?.reservation_id}
                        target="_blank"
                        style={{marginTop: 16, marginLeft: 12}}
                    >
                        View in Fleet Tracking
                    </Button>
                </Row>
                <Row style={{marginTop: 8}}>
                    <Col span={12}>
                        <Statistic
                            title="Warning timestamp"
                            value={
                                data?.alert_timestamp ?
                                    `${timezoneDateString(data.alert_timestamp, timezone)} ${timezoneTimeString(data.alert_timestamp, timezone)}` :
                                    'Not available'
                            }
                            prefix={<img src={calendarIcon} alt={calendarIcon}
                                         style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                        />
                    </Col>
                    <Col span={12}>
                        <Statistic
                            title="Time since last warning"
                            value={timeSinceLastWarning}
                            prefix={<img src={calendarIcon} alt={calendarIcon}
                                         style={{marginBottom: -4, marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                        />
                    </Col>
                </Row>
                <Statistic
                    title="Reckless behavior"
                    value={data?.event_category || ''}
                    prefix={<UserOutlined style={{marginRight: 4}}/>}
                    valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                    size="small"
                />
                {/*<Tooltip title={*/}
                {/*    data?.event_type === 'single-violation' ?*/}
                {/*        `violated: ${data?.harsh_turn_per_h_limit} reckless steering maneuvers per hour in the last reservation` :*/}
                {/*        `violated: ${data?.harsh_turn_per_h_limit} reckless steering maneuvers per hour in ${data?.resi_ratio_limit*100}% of reservations`*/}
                {/*}>*/}
                {data &&
                    <>
                        <Statistic
                            title="Details"
                            value={
                                data?.event_category === 'steering' ?
                                    data?.event_type === 'single-violation' ?
                                        `Rate of ${data?.harsh_per_h} high risk steering maneuvers per hour in the last reservation` :
                                        `Rate of ${data?.harsh_per_h} high risk steering maneuvers per hour in ${data?.resi_ratio * 100}% of reservations` :
                                    data?.event_type === 'single-violation' ?
                                        `Rate of ${data?.harsh_per_h} high risk accelerations per hour in the last reservation` :
                                        `Rate of ${data?.harsh_per_h} high risk accelerations per hour in ${data?.resi_ratio * 100}% of reservations`
                            }
                            prefix={<WarningOutlined style={{marginRight: 4}}/>}
                            valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                            size="small"
                        />
                    </>
                }
            </Card>
        </>
    )
        ;
}


export default WarningInfo;
