export const convertKilometersToMiles = (kilometers, units, decimals = 2) => {
    if (kilometers) {
        if (units === 'miles') {
            const miles = kilometers * 0.621371; // 1 kilometer is approximately 0.621371 miles
            return miles.toFixed(decimals); // Round to 2 decimal points
        } else {
            return kilometers.toFixed(decimals);
        }
    } else {
        return null
    }
};

export const convertMilesToKilometers = (miles, units) => {
    if (miles) {

        if (units === 'miles') {
            return miles * 1.60934; // Round to 2 decimal points
        } else {
            return miles;
        }
    } else {
        return null
    }
};

export const timezoneDateString = (timestamp, timeZone, options = {}) => {
    const date = new Date(timestamp);

    let timeZoneOption = null
    if (timeZone === 'UTC') {
        timeZoneOption = 'UTC';
    } else if (timeZone === 'Browser') {
        timeZoneOption = undefined; // Let the browser handle time zone
    } else {
        timeZoneOption = timeZone; // Assume it's a time zone string like 'Europe/Berlin'
    }

    return date.toLocaleDateString(undefined, { ...options, timeZone: timeZoneOption });
};

export const timezoneTimeString = (timestamp, timeZone, options = {}) => {
    const date = new Date(timestamp);

    let timeZoneOption = null
    if (timeZone === 'UTC') {
        timeZoneOption = 'UTC';
    } else if (timeZone === 'Browser') {
        timeZoneOption = undefined; // Let the browser handle time zone
    } else {
        timeZoneOption = timeZone; // Assume it's a time zone string like 'Europe/Berlin'
    }

    return date.toLocaleTimeString(undefined, { ...options, timeZone: timeZoneOption });
};

export const timezoneDateTimeString = (timestamp, timeZone, options = {}) => {
    const date = new Date(timestamp);

    let timeZoneOption = null
    if (timeZone === 'UTC') {
        timeZoneOption = 'UTC';
    } else if (timeZone === 'Browser') {
        timeZoneOption = undefined; // Let the browser handle time zone
    } else {
        timeZoneOption = timeZone; // Assume it's a time zone string like 'Europe/Berlin'
    }

    return date.toLocaleString(undefined, { ...options, timeZone: timeZoneOption });
};