import React, {useEffect, useState, useContext} from "react";
import {useLocation, Link, useNavigate} from "react-router-dom";
import {
    ConfigProvider,
    Layout,
    Menu,
    Image,
    Form,
    Switch,
    Typography,
    Popover,
    Dropdown,
    Space,
    message,
    notification,
    Radio,
    Button,
    Row,
    Input, Col, Tabs
} from 'antd';
import {getCVTheme} from "../common_components/AntD/CVTheme";
import Overview from "../components/HelpCenter/Overview";
import ContentCard from "../components/HelpCenter/ContentCard";
import TabContent from "../components/HelpCenter/TabContent";
import DamageEvents from "../components/HelpCenter/DamageEvents";
import VehicleManagement from "../components/HelpCenter/VehicleManagement";
import UserManagement from "../components/HelpCenter/UserManagement";
import Installation from "../components/HelpCenter/Installation";
import MobileApps from "../components/HelpCenter/MobileApps";
import FleetSecurity from "../components/HelpCenter/FleetSecurity";
import FAQ from "../components/HelpCenter/FAQ";
import axiosInstance from "../services/axios";

const {Title, Paragraph, Text} = Typography;
const {Search} = Input;

const HelpCenterScreen = (props) => {

    const [activeTab, setActiveTab] = useState(window.location.hash.slice(1) || 'overview');

    const onChangeTab = (key) => {
        setActiveTab(key)
    };

    useEffect(() => {
        const payload = {page_name: 'help_center_start'}
        axiosInstance.post('/api/visit', payload).then(res => {
        }).catch(error => {
        })
    }, []);

    let tabs = ["overview", "damage_events", "vehicle_management", "user_management", "installation", "mobile_apps"];
    if (props.permissions?.security) {
        tabs.push("fleet_security")
    }
    tabs.push("faq")


    useEffect(() => {
        const handleHashChange = () => {
            if (tabs.includes(window.location.hash.slice(1))) {
                setActiveTab(window.location.hash.slice(1));
            }
        };

        window.addEventListener("hashchange", handleHashChange);

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);

    useEffect(() => {
        window.location.hash = `#${activeTab}`;
    }, [activeTab]);

    let tabItems = [
        {
            key: 'overview',
            label: 'Overview',
            children: <TabContent content={<Overview setActiveTab={setActiveTab} permissions={props.permissions}/>}/>,
        },
        {
            key: 'damage_events',
            label: 'Damage Events',
            children: <TabContent
                content={<ContentCard
                    tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}
                    body={<DamageEvents setActiveTab={setActiveTab}/>}
                />}
            />,
        },
        {
            key: 'installation',
            label: 'Installation',
            children: <TabContent
                content={<ContentCard
                    tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}
                    body={<Installation setActiveTab={setActiveTab}/>}
                />}
            />,
        },
        {
            key: 'user_management',
            label: 'User Management',
            children: <TabContent
                content={<ContentCard
                    tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}
                    body={<UserManagement setActiveTab={setActiveTab}/>}
                />}
            />,
        },

        {
            key: 'mobile_apps',
            label: 'Mobile Apps',
            children: <TabContent
                content={<ContentCard
                    tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}
                    body={<MobileApps setActiveTab={setActiveTab}/>}
                />}
            />,
        },
        {
            key: 'vehicle_management',
            label: 'Vehicle Management',
            children: <TabContent
                content={<ContentCard
                    tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}
                    body={<VehicleManagement setActiveTab={setActiveTab}/>}
                />}
            />,
        }
    ]
    if (props.permissions?.security) {
        tabItems.push(
            {
                key: 'fleet_security',
                label: 'Fleet Security',
                children: <TabContent
                    content={<ContentCard
                        tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}
                        body={<FleetSecurity setActiveTab={setActiveTab} permissions={props.permissions}/>}
                    />}
                />,
            }
        )
    }
    tabItems.push(
        {
            key: 'faq',
            label: 'FAQ',
            children: <TabContent
                content={<ContentCard
                    tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}
                    body={<FAQ setActiveTab={setActiveTab}/>}
                />}
            />,
        }
    )

    // Return any JSX for the HomeScreen component (optional)
    return (<>
        <div style={{boxShadow: '0px 2px 4px 0px rgba(0, 35, 11, 0.20)'}}>
            <Row align={'middle'} style={{
                justifyContent: "space-between",
                padding: 24,
                backgroundColor: "#717171",
            }}>
                <Title level={1} style={{
                    margin: 0,
                    color: 'white',
                    fontWeight: 500
                }}
                >
                    Help Center
                </Title>
                {/*<Search style={{width: 300}}>*/}

                {/*</Search>*/}
            </Row>
            <Row style={{
                height: 48,
                backgroundColor: "#717171",
            }}>
                <ConfigProvider
                    theme={{
                        components: {
                            Tabs: {
                                itemColor: 'white',
                                itemSelectedColor: 'white',
                                inkBarColor: 'white'
                            }
                        }
                    }}
                >
                    <Tabs
                        activeKey={activeTab}
                        style={{marginTop: 10, marginLeft: 24}}
                        defaultActiveKey="overview"
                        items={tabItems}
                        onChange={onChangeTab}
                        tabBarStyle={{
                            color: 'white'
                        }}
                    />
                </ConfigProvider>
            </Row>
        </div>
    </>)
};

export default HelpCenterScreen;
