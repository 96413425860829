import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';

import {
    Button,
    Card,
    Row, Typography, Divider
} from 'antd';
import dayjs, {Dayjs} from "dayjs";
import {SettingsContext} from "../../App.js";
import {convertKilometersToMiles, timezoneDateTimeString} from '../../utility/conversions.js';

const {Title, Paragraph, Text, Link} = Typography;


const MapMarkerMisuse = (props) => {

    const {timezone, distance} = useContext(SettingsContext);

    return (
        <div style={{whiteSpace: "pre-line"}}>
            <Row>
                <Text>
                    Type: {props.misuse_type}
                </Text>
            </Row>
            <Row>
                <Text>
                    Timestamp: {timezoneDateTimeString(props.event_ts, timezone)}
                </Text>
            </Row>
        </div>
    );
}

export default MapMarkerMisuse;