import React, {useState, useEffect, useRef, useContext} from 'react';
import {Col, notification, Table, Row, Card, Typography, Tooltip, Input, Button, DatePicker, Switch} from 'antd';
import axiosInstance from "services/axios";
import {AgGridReact} from "ag-grid-react";
import moment from "moment";
import dayjs, {Dayjs} from "dayjs";

import "./../../common_components/AGGrid/carvaloo_theme.css";
import {
    customDateComparator,
    dateTimeFormatter,
    customDateRenderer,
    formatDateToLocal,
    formatArrayOfObjects,
    iconCellRenderer,
    customTimeRenderer,
    statusCellRenderer,
    feedbackCellRenderer,
    customDeltaComparator, detailsFormatter
} from "./TableHelpers";
import "./custom.css"
import {InboxOutlined, InfoCircleOutlined, ColumnWidthOutlined} from "@ant-design/icons";
import EventUrlRenderer from "./EventUrlRenderer";
import TriggerEventButton from "../Dashboard/TriggerEventButton";
import filterIcon from '../../assets/icons/filter.svg';
import checkIcon from "../../assets/icons/check.svg";
import {SettingsContext} from "../../App";
import {convertKilometersToMiles, timezoneDateString, timezoneTimeString} from '../../utility/conversions.js';

const {Search} = Input;
const {Paragraph, Text} = Typography;
const {RangePicker} = DatePicker;

const AlertTable = (props) => {

    const {timezone, distance} = useContext(SettingsContext);
    const gridRef = useRef();
    const [renderKey, setRenderKey] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [dataUpdated, setDataUpdated] = useState(false);

    let emptyRowData = [
        {box_id: 'empty'},
    ];

    const InfoTooltip = ({text}) => {
        return (
            <Tooltip title={text}>
                <InfoCircleOutlined style={{color: '#1890ff', fontSize: '12px', marginLeft: '5px'}}/>
            </Tooltip>
        );
    };

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();

        // Check if any rows are selected
        if (selectedRows.length > 0) {
            const selectedRowData = selectedRows[0]; // Assuming you want to work with the first selected row
            props.handleSelectedChange(selectedRowData.UAID);
        } else {
            props.handleSelectedChange(null);
            // No rows are selected, you can update your parameter accordingly
            // Example:
            // setMyParameter(null);
        }
    };

    const datetimeFormatter = (params) => {
        if (params.value === null || params.value === undefined) {
            return null; // Return null if the date value is null
        }
        const options = {year: 'numeric', month: '2-digit', day: 'numeric', hour: '2-digit', minute: '2-digit'};
        return timezoneDateString(params.value, params.context.timezone, options)
    };

    useEffect(() => {
        selectRowByAlertId(props.selectedAlert);
    }, [props.selectedAlert, dataUpdated]);

    const selectRowByAlertId = (alertId) => {

        if (!gridRef.current.api) return;

        gridRef.current.api.forEachNode((node) => {
            if (node.data.UAID === alertId) {
                node.setSelected(true);
                const rowIndex = node.rowIndex;
                const pageNumber = Math.floor(rowIndex / pageSize);
                gridRef.current.api.paginationGoToPage(pageNumber);
            } else {
                node.setSelected(false);
            }
        });
        setDataUpdated(false)
    };

    // Define your column definition with the custom cell renderer
    const columnDefs = [
        {
            field: 'UAID',
            headerName: 'UAID',
            width: 150,
            hide: true,
            filter: 'agTextColumnFilter',
            // cellRenderer: 'eventUrlRenderer',
        },
        {
            field: 'misuse_category',
            headerTooltip: "Category of security alert",
            headerName: 'Category',
            width: 130,
            cellRenderer: iconCellRenderer, // Use the custom cell renderer
            filter: 'agSetColumnFilter',
        },
        {
            field: 'event_id',
            headerName: 'Event ID',
            width: 200,
            filter: 'agTextColumnFilter',
            // cellRenderer: 'eventUrlRenderer',
        },
        {
            field: 'misuse_type',
            headerTooltip: "Detailed situation of security alert",
            headerName: 'Situation',
            width: 120,
            filter: 'agSetColumnFilter',
        },
        {
            field: 'misuse_details_info',
            headerTooltip: "Details of the security alert",
            headerName: 'Details',
            sort: false,
            width: 150,
            filter: 'agSetColumnFilter',
        },
        {
            field: 'vehicle_id',
            headerName: 'Vehicle ID',
            width: 120,
            filter: 'agTextColumnFilter',
            // cellRenderer: 'eventUrlRenderer',
        },
        {
            field: 'box_id',
            headerName: 'Box ID',
            // floatingFilter: true,
            hide: true,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'event_ts_utc',
            headerTooltip: "Timestamp of when the fleet security misuse occured",
            headerName: 'Misuse Timestamp',
            width: 150,
            sort: 'desc',
            comparator: customDateComparator,
            valueFormatter: datetimeFormatter,
        },
        {
            field: 'firing_datetime',
            headerTooltip: "Timestamp of when the security violation was firing (can also be None)",
            headerName: 'Firing Timestamp',
            width: 150,
            comparator: customDateComparator,
            valueFormatter: datetimeFormatter,
        },
        {
            field: 'last_update',
            headerTooltip: "Timestamp of the last security violation update",
            headerName: 'Updated',
            width: 150,
            comparator: customDateComparator,
            valueFormatter: datetimeFormatter,
        },
        {
            field: 'duration',
            headerTooltip: "Time passed since the last state update",
            headerName: 'Since Update',
            // floatingFilter: true,
            width: 150,
            cellRenderer: customTimeRenderer,
            valueFormatter: datetimeFormatter,
        },
        {
            field: 'alert_status',
            headerTooltip: "State of security violation",
            headerName: 'Status',
            cellRenderer: statusCellRenderer,
            width: 70,
            filter: 'agSetColumnFilter',
        },
        {
            field: 'matching',
            headerTooltip: "Feedback for security violation",
            headerName: 'Feedback',
            cellRenderer: feedbackCellRenderer,
            width: 85,
            filter: 'agSetColumnFilter',
        },
        // {
        //     field: 'Latitude',
        //     headerTooltip: "GPS Latitude",
        //     headerName: 'Latitude',
        //     hide: true,
        // },
        // {
        //     field: 'Longitude',
        //     headerTooltip: "GPS Longitude",
        //     headerName: 'Longitude',
        //     hide: true,
        // },
        // {
        //     field: 'misuse_details',
        //     hide: true,
        //     headerTooltip: "Details for the security violation",
        //     headerName: 'Details',
        //     valueFormatter: detailsFormatter,
        //     filter: "agTextColumnFilter",
        //     width: 350,
        // },
        // {
        //     field: 'created_datetime',
        //     headerTooltip: "Timestamp for when the GPS data was processed",
        //     headerName: 'Processed',
        //     width: 150,
        //     hide: true,
        //     // floatingFilter: true,
        //     valueFormatter: dateTimeFormatter,
        //     // // filter: 'agDateColumnFilter',
        //     // filterParams: {
        //     //   inRangeInclusive: true,
        //     //   suppressAndOrCondition: true,
        //     // },
        //     comparator: customDateComparator,
        //     cellRenderer: customDateRenderer,
        // },
    ]

    const [tableHeight, setTableHeight] = useState(678);

    useEffect(() => {
        if (gridRef.current.api) {
            gridRef.current.api.refreshCells({force: true});
        }
    }, [timezone]);

    const [gridOptions, setGridOptions] = useState({
        rowData: emptyRowData,
        rowSelection: 'single',
        suppressRowClickSelection: false,
        enableRangeSelection: 'true',
        columnDefs: columnDefs,
        // getContextMenuItems: getContextMenuItems,
        pagination: true,
        paginationPageSize: 10, // Number of rows per page
        // onPaginationChanged: onPaginationChanged,
        components: {
            eventUrlRenderer: EventUrlRenderer
        },
        defaultColDef: {
            width: 100,
            sortable: true,
            filter: false,
            resizable: true, //minWidth: 100,
            editable: false, //cellEditor: PopupCellEditor
            suppressMenu: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            // cellStyle: {fontSize: '14px'}
        },
        onGridReady: (event) => event.api.sizeColumnsToFit(),
        onSelectionChanged: onSelectionChanged, // Add this line
    })

    useEffect(() => {
        let isInitialRun = true; // Initialize a variable to track the first run
        let currentFilterModel = {}

        if (gridRef.current.api) {
            currentFilterModel = gridRef.current.api.getFilterModel();
        }

        const payload = {
            customer: props.customer,
            date_range: props.dateRangeFilter,
            search: props.searchFilter,
            fleet: props.fleetFilter,
            matching: props.matchingFilter,
        };

        const fetchData = async () => {
            try {
                const response = await axiosInstance.post('/api/geofunctions/alerts', payload);

                if (response.data.data.length === 0) {
                    gridRef.current.api.setRowData(null);
                    notification['warning']({message: 'No alerts found in database - please check daterange filter'});
                } else if (response.data.data) {

                    const formattedArray = formatArrayOfObjects(response.data.data);

                    // if (formattedArray.length < 5) {
                    //     setTableHeight(375)
                    // } else {
                    if (gridRef.current) {
                        if (isInitialRun) {
                            // if (response.data.unique <= 3) {
                            //     // Update gridOptions with new pageSize
                            //     setGridOptions({
                            //         ...gridOptions,
                            //         paginationPageSize: 7
                            //     });
                            //     setPageSize(7)
                            //     setRenderKey(renderKey + 1)
                            //     setTableHeight(3 * 176)
                            // } else {
                            setGridOptions({
                                ...gridOptions,
                                paginationPageSize: 10
                            });
                            setPageSize(10)
                            setRenderKey(renderKey + 1)
                            // }
                        }

                        gridRef.current.api.setRowData(formattedArray);
                        gridRef.current.api.setFilterModel(currentFilterModel);
                        setDataUpdated(true)

                        if (isInitialRun) {
                            // This code will run on the first run of this useEffect
                            currentFilterModel = gridRef.current.api.getFilterModel();
                            currentFilterModel.alert_status = {
                                "filterType": "set",
                                "values": props.actionFilter
                            }
                            gridRef.current.api.setFilterModel(currentFilterModel);

                            isInitialRun = false; // Set the flag to false after the first run
                        }
                    }

                } else {
                    notification['warning']({message: 'No alerts found in database - please check daterange filter'});
                }

            } catch (error) {
                console.log(error);
                notification['error']({message: 'Error retreiving alerts', description: error.message, duration: 10});
                gridRef.current?.api?.setRowData(emptyRowData);
            }
        };

        console.log('updating alert table');
        if (gridRef.current && props.dateRangeFilter) {
            fetchData();
        }

    }, [props.update, props.dateRangeFilter, props.searchFilter, props.fleetFilter, props.matchingFilter]);

    const onSetIssueFilter = () => {

        if (gridRef.current && gridRef.current.api) {

            let currentFilterModel = gridRef.current.api.getFilterModel();
            currentFilterModel.misuse_category = {
                "filterType": "set",
                "values": [props.issueFilter]
            }

            if (props.issueFilter === null) {
                delete currentFilterModel.misuse_category;
            }

            gridRef.current.api.setFilterModel(currentFilterModel);
        }
    }

    useEffect(() => {
        onSetIssueFilter();
    }, [props.issueFilter]);


    const onSetActionFilter = () => {

        if (gridRef.current && gridRef.current.api) {

            let currentFilterModel = gridRef.current.api.getFilterModel();
            currentFilterModel.alert_status = {
                "filterType": "set",
                "values": props.actionFilter
            }

            if (props.actionFilter === null) {
                delete currentFilterModel.alert_status;
            }

            gridRef.current.api.setFilterModel(currentFilterModel);
        }
    }

    useEffect(() => {
        onSetActionFilter();
    }, [props.actionFilter]);

    // // Check the window width
    // const windowWidth = window.innerWidth;
    // console.log(windowWidth)
    //
    // // Set the column visibility based on the window width
    // if (gridRef.current && gridRef.current.api) {
    //     if (windowWidth < 1900) {
    //         gridRef.current.columnApi.setColumnVisible('details', false); // Hide the column
    //     } else {
    //         gridRef.current.columnApi.setColumnVisible('details', true); // Show the column
    //     }
    // }

    const onResizeColumns = () => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.sizeColumnsToFit()
        }
    }

    return (<>
            <Card
                style={{
                    marginRight: 4,
                    width: '100%',
                    height: tableHeight,
                    minHeight: 350,
                    boxSizing: 'border-box',
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #e8e8e8'
                }}
                title={'Alerts'}
                extra={
                    <>
                        <Switch
                            ref={props.refUpdate}
                            style={{marginRight: 32}}
                            onChange={props.handleUpdateActive}
                            checkedChildren="update"
                            unCheckedChildren="update"
                            checked={props.updateActive}
                        />
                        <Button
                            icon={<ColumnWidthOutlined style={{color: "gray"}}/>}
                            style={{
                                height: 32,
                            }}
                            onClick={onResizeColumns}
                        />
                    </>
                }
                styles={{ body: {
                    padding: 0
                }}}>
                <div
                    style={{width: '100%', height: tableHeight - 85, minHeight: 280}} ref={props.refTable}
                >
                    <AgGridReact
                        key={renderKey}
                        ref={gridRef}
                        context={{timezone}}
                        gridOptions={gridOptions}
                        className="ag-theme-alpine"
                    />
                </div>
            </Card>

        </>
    );
};

export default AlertTable;