import React from 'react'
import MapPin from '../MapPin/MapPin';

const MarkerCarButton = (props) => {
  const {cluster, mapState, handleSelectedMarkerChange, iconSize} = props;

  const onClickCarButton = (e) => {
    e.stopPropagation();
    handleSelectedMarkerChange({
      id: cluster.properties.id,
      lat: cluster.properties.latitude,
      lng: cluster.properties.longitude,
    });
  }

  return (
    <button
      type="button"
      onClick={onClickCarButton}
      style={{backgroundColor: "transparent", border: "none",}}
      className="crime-marker"
    >
      <MapPin
        size={{ height: iconSize, width: iconSize }}
        eventStatus={cluster.properties && cluster.properties.eventStatus}
      />
    </button>
  )
}

export default MarkerCarButton
