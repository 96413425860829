import {Button, Card, Col, Image, notification, Row, Statistic, Typography, Divider, Progress} from 'antd';

import React, {useState, useEffect, useContext} from 'react';
import Icon, {InboxOutlined} from "@ant-design/icons";
import {FaSearch} from "react-icons/fa";
import VelocityPlot from "./VelocityPlot";
import {SettingsContext} from "../../App";
import {
    convertKilometersToMiles,
    timezoneDateString,
    timezoneTimeString,
    timezoneDateTimeString
} from '../../utility/conversions.js';

const {Text} = Typography;

const Dashboard = (props) => {
    const {timezone, distance} = useContext(SettingsContext);
    const [data, setData] = useState(null);
    const [render, setRender] = useState(0);

    useEffect(() => {
        if (data?.positions) {
            data.positions.forEach(marker => {
                marker.markerColor = "#0071bc";
            });
        }
        setData(props.data)
    }, [props.data]);

    useEffect(() => {
        if (data?.positions && props.selectedRow) {
            const startTime = new Date(props.selectedRow[0]);
            const endTime = new Date(props.selectedRow[1]);
            setRender(render + 1)
            const updatedMarkers = data.positions.map(marker => {
                const markerTime = new Date(marker.ts);
                const updatedMarker = {...marker}; // Create a copy of the marker

                if (markerTime >= startTime && markerTime <= endTime) {
                    updatedMarker.markerColor = 'red'; // Update marker color
                } else {
                    updatedMarker.markerColor = '#0071bc'; // Default color
                }

                return updatedMarker;
            });
            const additionalMarkers = updatedMarkers.filter(marker => marker.markerColor === 'red');

            // Concatenate the updated markers with the original data array
            const newData = {...props.data}; // Create a copy of the original data object
            newData.positions = [...newData.positions, ...additionalMarkers]; // Concatenate the arrays
            setData(newData); // Update state with the combined data
        }

    }, [props.selectedRow]);

    // console.log(data)

    return (<>
            <Row>

                <Card
                    style={{
                        width: 'calc(100% - 486px)',
                        height: 120,
                        boxSizing: 'border-box',
                        border: '1px solid #e8e8e8',
                    }}
                    styles={{ body: {
                        paddingRight: 24,
                        paddingLeft: 24,
                        paddingTop: 10,
                        paddingBottom: 10,
                        opacity: data?.total_stationary_str ? 1 : 0.25
                    }}}
                >
                    <VelocityPlot
                        data={data?.positions}
                        render={render}
                    />
                </Card>
                <Card
                    style={{
                        width: 470,
                        height: 120,
                        boxSizing: 'border-box',
                        border: '1px solid #e8e8e8',
                        marginLeft: 16,
                    }}
                    styles={{ body: {
                        paddingRight: 24,
                        paddingLeft: 24,
                        paddingTop: 10,
                        paddingBottom: 10,
                        opacity: data?.total_stationary_str ? 1 : 0.25
                    }}}
                >
                    <Row style={{alignItems: 'center'}}>
                        <Col style={{marginRight: 48}}>
                            <Statistic
                                title="Stationary"
                                value={data?.total_stationary_str}
                                valueStyle={{fontSize: '16px'}}
                                style={{marginBottom: 8}}
                            />
                            <div style={{width: 100}}>
                                <Progress percent={data?.stationary_pct}/>
                            </div>
                        </Col>
                        <Col style={{marginRight: 48}}>
                            <Statistic
                                title="at Station"
                                value={data?.total_station_str}
                                valueStyle={{fontSize: '16px'}}
                                style={{marginBottom: 8}}
                            />
                            <div style={{width: 100}}>
                                <Progress percent={data?.at_station_pct}/>
                            </div>
                        </Col>
                        <Col>
                            <Statistic
                                title="Driving Distance"
                                value={
                                    data?.distance ?
                                        `${convertKilometersToMiles(data.distance, distance)}` :
                                        '- '
                                }
                                valueStyle={{fontSize: '16px'}}
                                style={{marginBottom: 8}}
                                suffix={distance === 'kilometers' ? 'km' : 'miles'}
                            />
                            <div style={{width: 100, height: 30}}>
                            </div>
                            {/*<div style={{width: 100}}>*/}
                            {/*    <Progress percent={data?.at_station_pct}/>*/}
                            {/*</div>*/}
                        </Col>
                        {/*<Divider*/}
                        {/*    type="vertical"*/}
                        {/*    style={{borderLeft: '1px solid black', height: 100, marginLeft: 8, marginRight: 8}}*/}
                        {/*/>*/}
                    </Row>
                </Card>
            </Row>
        </>
    );
};

export default Dashboard;