import React, {useContext, useEffect, useState} from 'react';
import {Column} from '@ant-design/charts';
import {Typography} from "antd";
import {SettingsContext} from "../../App.js";

const {Paragraph, Text} = Typography;
const {Title} = Typography;

const AbsoluteChart = (props) => {
    const [config, setConfig] = useState(null);
    const {timezone, distance} = useContext(SettingsContext);

    const handleClick = (params) => {
        if (props.handleDriverClick) {
            props.handleDriverClick(params)
        }
    };

    useEffect(() => {

        const seriesColors = {
            cautious: 'rgba(50,161,221,1)',
            normal: 'rgba(50,147,125,1)',
            aggressive: 'rgba(229,85,85,1)',
        };

        const seriesFieldValues = ['cautious', 'normal', 'aggressive'];
        const colorMapping = {};
        seriesFieldValues.forEach((type) => {
            colorMapping[type] = seriesColors[type];
            colorMapping[`${type}_no_click`] = `${seriesColors[type].replace('1)', '0.2)')}`;
        });

        if (props.data) {
            setConfig({
                    data: props.data,
                    isGroup: true,
                    xField: 'driver',
                    yField: 'value',
                    xAxis: {
                        label: {
                            formatter: (text) => {
                                if (text === 'average') {
                                    return 'average driver'
                                } else {
                                    return text.includes('-') ? text.split('-')[0] : text;
                                }
                            },
                        },
                    },
                    yAxis: {
                        visible: props.hideAxisY !== true,
                        max: props.maxValueY
                    },
                    columnStyle: {
                        radius: 3, // Adjust the values to set the radius for each corner individually (top-left, top-right, bottom-right, bottom-left)
                    },
                    seriesField: 'type', // This is used to stack the bars
                    legend: false, // You can adjust the legend position
                    color: (datum) => colorMapping[datum.type],
                    tooltip: {
                        formatter: (datum) => {
                            if (props.categoryValue === 'distance') {
                                return {
                                    name: datum.type,
                                    value: `${(datum.value).toFixed(1)} ${distance === 'kilometers' ? 'km' : 'miles'}`,
                                };
                            } else if (props.categoryValue === 'time') {
                                // Assuming datum.value represents time in hours, you can format it accordingly
                                const hours = Math.floor(datum.value);
                                const minutes = Math.round((datum.value - hours) * 60);
                                return {
                                    name: datum.type,
                                    value: `${hours} h ${minutes} min`,
                                };
                            }
                        },
                    },
                    onReady: (plot) => {
                        plot.on('element:click', handleClick)
                    }
                }
            );
        }
    }, [props.data]);

    return (<>
        <div style={{height: 150, width: '100%', marginTop: 55}}>
            <Title level={5} align="center"
                   style={{marginTop: 0, color: props.titleColor ? props.titleColor : 'black'}}>
                {props.title ? props.title : `Absolute driving ${props.categoryValue}`}
            </Title>
            {config &&
                <Column
                    {...config}
                />
            }
        </div>
    </>)
};

export default AbsoluteChart;
