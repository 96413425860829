import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';

import {
    Button,
    Card, Modal, Spin,
    DatePicker, notification, Typography, Tooltip, Row, Col, Radio
} from 'antd';
import dayjs, {Dayjs} from "dayjs";

import axiosInstance from "services/axios";
import RelativeChart from "./RelativeChart"
import AbsoluteChart from "./AbsoluteChart"
import CountChart from "./CountChart";
import {ColumnWidthOutlined} from "@ant-design/icons";
import {SettingsContext} from "../../App.js";

const {Paragraph, Text} = Typography;

const ScoringDetail = (props) => {

    const {timezone, distance} = useContext(SettingsContext);
    const [loading, setLoading] = useState(false);
    const [relativeData, setRelativeData] = useState(null);
    const [absoluteData, setAbsoluteData] = useState(null);
    const [countData, setCountData] = useState(null);
    const [groupValue, setGroupValue] = useState('day');

    const handleRadioChange = (e) => {
        setGroupValue(e.target.value)
    };

    useEffect(() => {

        let userTimezone = timezone
        if (timezone === 'Browser') {
            userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }

        const payload = {
            customer: props.customer,
            driver_id: props.selectedDriver,
            group: groupValue,
            cat_value: props.categoryValue,
            date_start: props.dateRangeFilter[0],
            date_end: props.dateRangeFilter[1],
            timezone: userTimezone
        };

        setLoading(true)
        axiosInstance.post('/api/prevention/driver', payload)
            .then(res => {
                setRelativeData(res.data.relative_data)
                setAbsoluteData(res.data.absolute_data)
                setCountData(res.data.count_data)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                console.log(error);
                notification['error']({message: 'Error in ScoringDetails', description: error.message});
            })
    }, [props.selectedDriver, groupValue, props.dateRangeFilter, timezone]);

    // const handleSelectClick = (value) => {
    //     if (value === 'all') {
    //         props.setActionFilter(['firing', 'pending', 'resolved'])
    //     } else if (value === 'active') {
    //         props.setActionFilter(['firing', 'pending'])
    //     } else {
    //         props.setActionFilter([value])
    //     }
    //     props.handleSelectedChange(null)
    // };

    return (<>
            <Modal
                open={loading}
                closable={false}
                footer={null}
                centered={true}
                mask={true}
                maskClosable={false}
                styles={{ body: {backdropFilter: 'blur(3px)'}}}
            >
                <div>
                    <Spin style={{marginRight: 20}} size="large"/>
                    Loading driver charts ...
                </div>
            </Modal>
            <Card
                style={{
                    width: 'calc(100% - 700px)',
                    height: 678,
                    boxSizing: 'border-box',
                    border: '1px solid #e8e8e8',
                }}
                styles={{ body: {
                    paddingRight: 24,
                    paddingLeft: 24,
                    paddingTop: 10,
                    paddingBottom: 10,
                }}}
                title={<>
                    Scoring Details{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}
                    <Text style={{fontSize: 12}}>{props.selectedDriver} </Text>
                    {/*<InfoTooltip*/}
                    {/*    text=*/}
                    {/*        "GPS Track before and after the alert as well as the last GPS Position of the vehicle.*/}
                    {/*            Active alerts include firing and pending alerts."*/}
                    {/*/>*/}
                </>}
                subTitle={props.selectedDriver}
                extra={
                    <>
                        <Radio.Group
                            onChange={handleRadioChange}
                            style={{marginTop: 8}}
                            options={[
                                {label: 'Monthly', value: 'month'},
                                {label: 'Weekly', value: 'week'},
                                {label: 'Daily', value: 'day'},
                            ]}
                            defaultValue={'day'}
                            optionType="button"
                        />
                    </>
                }
            >
                <RelativeChart data={relativeData} categoryValue={props.categoryValue}/>
                <AbsoluteChart data={absoluteData} categoryValue={props.categoryValue}/>
                <CountChart data={countData} categoryValue={props.categoryValue}/>
            </Card>
        </>
    );
}

export default ScoringDetail;