import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {TinyColumn} from '@ant-design/plots';
import {Typography, Row} from "antd";

const {Title, Text} = Typography;

const TinyChart = (props) => {

    const [config, setConfig] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.data) {
            setData(props.data)
        }
    }, [props.data]);

    useEffect(() => {
        setConfig({
            data,
            color: '#83C6EA', // Set your desired color here
            tooltip: {
                customContent: function (x, data) {
                    const currentDate = new Date();
                    const daysBefore = 13 - x;
                    const targetDate = new Date(currentDate);
                    targetDate.setDate(currentDate.getDate() - daysBefore);

                    const formattedDate = targetDate.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                    });

                    return `Date: ${formattedDate}<br>Alert Count: ${data[0]?.data?.y.toFixed(0)}`;
                },
            },
        })
    }, [data]);

    return (<>
        <div style={{height: 40, width: '100%', marginTop: 0, paddingLeft: 16, paddingRight: 16}}>
            {config &&
                <TinyColumn
                    {...config}
                />
            }
            <Row>
                {/*<Text style={{fontSize: 11, marginLeft: 0, marginTop: 4}}>*/}
                {/*    14-day alert trend*/}
                {/*</Text>*/}
                {/*<div*/}
                {/*    style={{*/}
                {/*        marginLeft: 10,*/}
                {/*        marginTop: 7,*/}
                {/*        width: 0,*/}
                {/*        height: 0,*/}
                {/*        borderLeft: '4px solid transparent',*/}
                {/*        borderRight: '4px solid transparent',*/}
                {/*        borderTopWidth: '6px',*/}
                {/*        borderTopStyle: 'solid',*/}
                {/*        borderTopColor: true ? 'green' : 'red',*/}
                {/*    }}></div>*/}
                {/*<Text style={{fontSize: 11, marginLeft: 6, color: "green"}}>*/}
                {/*    10.5%*/}
                {/*</Text>*/}
            </Row>
        </div>
    </>)


};

export default TinyChart;