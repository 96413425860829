// BtnCellRenderer.jsx
import {Popover, Button, Collapse} from 'antd';

import React from "react";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { Typography } from 'antd';
const { Title, Text } = Typography;

const DefaultIcon = L.icon({
  iconUrl: icon,
  iconAnchor: [12, 41],
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

function EventURLRenderer(data) {
  if (data) {
    // console.log(data)
    const dataArray = data.replace(/[\[\],]/g, '').split(" ");
    // console.log(dataArray)
    const urlBase = "https://webapp.carvaloo.com/ereignisse"
    const links = dataArray.map(item => {
      const stringItem = item.slice(1, -1);
      const url = `${urlBase}/${stringItem}`;
      return (
        <React.Fragment key={stringItem}>
          <a href={url} target="_blank" rel="noopener noreferrer">{stringItem}</a>
          <br />
        </React.Fragment>
      );
    });

    return <div>{links}</div>;
  } else {
    return <div></div>;
  }
}

const AnomalyRenderer = ({title = '', ...props}) => {
  const container = EventURLRenderer(props.data.production_alert)
  return (
    <>
      {props.data.production_alert && (
        <Popover
          title={title}
          trigger="click"
          content={container}
        >
          <Button>Click</Button>
        </Popover>
      )}
    </>
  );
}


export default AnomalyRenderer;