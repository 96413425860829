import React from "react";
import { Card } from "antd";

const EventLegend = (props) => {
  const dictionary = props.dictionary || {};
  return (
    <Card styles={{ body: { padding: "5px 10px" }}}>
      <div style={{ display: "block", marginBottom: "5px" }}>
        <div
          style={{
            display: "inline-block",
            height: "10px",
            width: "10px",
            backgroundColor: "#d42e53",
            borderRadius: "10px",
            marginRight: "5px",
          }}
        ></div>
        <span style={{ display: "inline-block", fontSize: "12px"  }}>{dictionary.event__legend_new}</span>
      </div>

      <div style={{ display: "block", marginBottom: "5px" }}>
        <div
          style={{
            display: "inline-block",
            height: "10px",
            width: "10px",
            backgroundColor: "#FFB400",
            borderRadius: "10px",
            marginRight: "5px",
          }}
        ></div>
        <span style={{ display: "inline-block", fontSize: "12px"  }}>{dictionary.event__legend_pending}</span>
      </div>

      <div style={{ display: "block" }}>
        <div
          style={{
            display: "inline-block",
            height: "10px",
            width: "10px",
            backgroundColor: "#0f963a",
            borderRadius: "10px",
            marginRight: "5px",
          }}
        ></div>
        <span style={{ display: "inline-block", fontSize: "12px" }}>{dictionary.event__legend_done}</span>
      </div>
    </Card>
  );
};

export default EventLegend;