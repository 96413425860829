import React, { useState, useRef, useEffect } from "react";

import { createRoot } from 'react-dom/client';
import { Button, Radio, Card, DatePicker } from "antd";
import UndoOutlined from "@ant-design/icons/UndoOutlined";
import GoogleMapReact from "google-map-react";
import Supercluster from "supercluster";
import { customGoogleMapsStyle } from "../../assets/styles/google-maps-style";
import switzerlandCoordinates from "../../assets/vectors/switzerland_path";
import LoaderOverlay from "../LoaderOverlay/LoaderOverlay";

import locale from "antd/es/locale/de_DE";
import "moment/locale/de";

import MarkerInfoCard from "./MarkerInfoCard/MarkerInfoCard";
import MarkerCarButton from "./MarkerCarButton/MarkerCarButton";
import MarkerCluster from "./MarkerCluster/MarkerCluster";
import EventLegend from "./EventLegend/EventLegend";

const Marker = ({ children }) => children;
let clusterObj;
let mapsItem;

const ClusterMap = (props) => {
  const dictionary = {
    cluster__map__handleMapLoaded_radioButton1: "Neu",
    cluster__map__handleMapLoaded_radioButton2: "Pendent",
    cluster__map__handleMapLoaded_radioButton3: "Abgeschlossen",
    cluster__map__handleMapLoaded_radioButton4: "Ungeklärt",
    cluster__map__handleMapLoaded_radioButton5: "Alle",
    event__legend_new: "Neu",
    event__legend_pending: "Pendent",
    event__legend_done: "Abgeschlossen",
  };
  const mapRef = useRef();
  const mapsRef = useRef();
  const [center, setCenter] = useState({
    lat: 46.9797477722168,
    lng: 7.468743991851807,
  });
  const [mapLocations, setMapLocations] = useState([]);
  const [iconSize, setIconSize] = useState(25);
  const [mapSuperCluster, setMapSuperCluster] = useState([]);
  const [zoom, setZoom] = useState(8);
  const [bounds, setBounds] = useState(null);

  const configMapCenterOnClusters = (map, maps) => {
    let markers = mapLocations.map((item) => [
      item.properties.latitude,
      item.properties.longitude,
    ]);

    let boundsOnStart = new maps.LatLngBounds();

    for (let i = 0; i < markers.length; i++) {
      let position = new maps.LatLng(markers[i][0], markers[i][1]);

      boundsOnStart.extend(position);
    }

    map.fitBounds(boundsOnStart);

    let isAllMarkersSameLocation = markers.every((marker) => (marker[0] === markers[0][0]) && (marker[1] === markers[0][1]));
    if(isAllMarkersSameLocation) mapRef.current.setZoom(14);
  };

  useEffect(() => {
    const allLocations = props.mapLocations.filter(
      (item) =>
        item.geometry.coordinates[0] !== 0 && item.geometry.coordinates[1] !== 0
    );
    setMapLocations(allLocations);
    if ((mapRef.current, mapsRef.current, allLocations.length > 0)) {
      // setMapCenterToAllCluster(mapRef.current, mapsRef.current);
    }
  }, [props.mapLocations]);

  // Listen parameters to set map center
  useEffect(() => {
    if (mapRef.current && mapsRef.current && props.mapLocations.length > 0) {
      configMapCenterOnClusters(mapRef.current, mapsRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLocations.length, mapRef.current, mapsRef.current]);

  useEffect(() => {
    const index = new Supercluster({
      radius: 75,
      maxZoom: 16,
      zoom,
      bounds,
    });
    index.load(mapLocations);
    setMapSuperCluster(index.getClusters([-180, -85, 180, 85], zoom));
    clusterObj = index;
  }, [mapLocations, zoom, bounds]);

  const handleMapLoaded = ({ map, maps }) => {
    mapRef.current = map;
    mapsRef.current = maps;
    mapsItem = maps;

    const polygon = new maps.Polygon({
      paths: switzerlandCoordinates,
      strokeColor: '#2a2727',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#088ed7',
      fillOpacity: 0.03
    });
    polygon.setMap(map)

    // const controlButtonDiv = document.createElement("div");
    // const controlMap = createRoot(controlButtonDiv);
    // controlMap.render(
    //   <Radio.Group
    //     onChange={(val) => {
    //       props.handleMapStateChange(val.target.value);
    //     }}
    //     defaultValue="new"
    //     className="mt-2 mr-2"
    //   >
    //     <Radio.Button value="new">
    //       {dictionary.cluster__map__handleMapLoaded_radioButton1}
    //     </Radio.Button>
    //     <Radio.Button value="pending">
    //       {dictionary.cluster__map__handleMapLoaded_radioButton2}
    //     </Radio.Button>
    //     <Radio.Button value="done">
    //       {dictionary.cluster__map__handleMapLoaded_radioButton3}
    //     </Radio.Button>
    //     <Radio.Button value="unclear">
    //       {dictionary.cluster__map__handleMapLoaded_radioButton4}
    //     </Radio.Button>
    //     <Radio.Button value="all">
    //       {dictionary.cluster__map__handleMapLoaded_radioButton5}
    //     </Radio.Button>
    //   </Radio.Group>
    // );
    // map.controls[maps.ControlPosition.RIGHT_TOP].push(controlButtonDiv);

    const titleDiv = document.createElement("div");
    const titleMap = createRoot(titleDiv);
    titleMap.render(
      <div>
        <Card styles={{
                    body:{ padding: "5px 10px" }}}>
          <div style={{ display: "block", marginBottom: "5px" }}>
            <span style={{ display: "inline-block", fontSize: "14px"  }}>
              Fahrzeugstationen der Vorfälle
            </span>
          </div>
        </Card>
      </div>
    );
    map.controls[maps.ControlPosition.LEFT_TOP].push(titleDiv);

    const legendDiv = document.createElement("div");
    const legendMap = createRoot(legendDiv);
    legendMap.render(
      <div className="m-2">
        <EventLegend dictionary={dictionary} />
      </div>
    );
    map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(legendDiv);

    map.setZoom(8);
    configMapCenterOnClusters(map, maps);
  };

  useEffect(() => {
    if (mapRef.current) {
      if (
        zoom === 8 &&
        center.lat === 46.9797477722168 &&
        center.lng === 7.468743991851807
      ) {
        if (mapRef.current.controls[mapsItem.ControlPosition.RIGHT_TOP].length) {
          mapRef.current.controls[mapsItem.ControlPosition.RIGHT_TOP].pop();
        }
      } else {
        if (mapRef.current.controls[mapsItem.ControlPosition.RIGHT_TOP].length) {
          mapRef.current.controls[mapsItem.ControlPosition.RIGHT_TOP].pop();
        }
        const zoomResetDiv = document.createElement("div");
        const zoomMap = createRoot(zoomResetDiv);
        zoomMap.render(
          <div className="m-2">
            <Button
              type="primary"
              onClick={() => {
                mapRef.current.setZoom(8);
                configMapCenterOnClusters(mapRef.current, mapsRef.current);
              }}
              style={{background: '#088ed7'}}
              icon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <UndoOutlined />
                </div>
              }
            ></Button>
          </div>
        );
        mapRef.current.controls[mapsItem.ControlPosition.RIGHT_TOP].push(
          zoomResetDiv
        );
      }
    }
  }, [zoom, center]);

  useEffect(() => {
    if (
      props.selectedMarker &&
      props.selectedMarker.id &&
      props.selectedMarker.selectedFromTable
    ) {
      mapRef.current.setZoom(14);
      setTimeout(() => {
        setCenter({
          lat: props.selectedMarker.lat,
          lng: props.selectedMarker.lng,
        });
        setTimeout(() => {
          mapRef.current.panBy(0, -100);
        }, 150);
      }, 100);
    } else if (props.selectedMarker && props.selectedMarker.id) {
      setCenter({
        lat: props.selectedMarker.lat,
        lng: props.selectedMarker.lng,
      });
      setTimeout(() => {
        mapRef.current.panBy(0, -100);
      }, 150);
    }
  }, [props.selectedMarker]);

  // useEffect(() => {
  //   const fetchGeojsonData = async () => {
  //     try {
  //       const response = await fetch("../../assets/vectors/geojson-data.json");
  //       const data = await response.json();
  //       setGeojsonData(data);
  //     } catch (error) {
  //       console.error('Error fetching GeoJSON data:', error);
  //     }
  //   };
  //   fetchGeojsonData();
  // }, []);


  const MemoizedMarkerCarButton = React.memo(MarkerCarButton);
  const MemoizedMarkerCluster = React.memo(MarkerCluster);

  return (
    <div className="col-12 p-3">
      <LoaderOverlay visible={props.mapLocationsLoading} />
      <Card className="shadow-sm overflow-hidden" size="small">
        <div style={{ height: props.mapHeight, width: "100%", position: "relative" }}>
          <GoogleMapReact
            options={{
              zoomControl: false,
              fullscreenControl: false,
              styles: customGoogleMapsStyle,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={handleMapLoaded}
            bootstrapURLKeys={{
              key: "AIzaSyBZoGSnnZux0DvKt8qvhJenQL_OxnyqMjk",
            }}
            onDrag={() => props.handleSelectedMarkerChange(null)}
            onClick={(e, b) => props.handleSelectedMarkerChange(null)}
            center={center}
            zoom={zoom}
            onChange={({ zoom, bounds }) => {
              setIconSize(zoom > 10 ? (zoom / 10) * 25 : 25);
              setZoom(zoom);
              setBounds([
                bounds.nw.lng,
                bounds.se.lat,
                bounds.se.lng,
                bounds.nw.lat,
              ]);
            }}
          >
            {mapSuperCluster.map((cluster) => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const { cluster: isCluster,point_count: pointCount, } = cluster.properties;
              
              if (isCluster) {
                return (
                  <Marker
                    key={`cluster-${cluster.id}`}
                    lat={latitude}
                    lng={longitude}
                  >
                    <MemoizedMarkerCluster
                      mapLocations={mapLocations}
                      lat={latitude}
                      lng={longitude}
                      mapRef={mapRef}
                      clusterObj={clusterObj}
                      pointCount={pointCount}
                      cluster={cluster}
                      offsetLeft={props.offsetLeft}
                      offsetTop={props.offsetTop}
                    />
                  </Marker>
                );
              }
              return (
                <Marker
                  key={`crime-${cluster.properties.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <div style={{ position: "relative" }}>
                    <MarkerInfoCard
                      mapState={props.mapState}
                      selectedMarker={props.selectedMarker}
                      cluster={cluster}
                    />
                    <MemoizedMarkerCarButton
                      mapState={props.mapState}
                      cluster={cluster}
                      handleSelectedMarkerChange={props.handleSelectedMarkerChange}
                      iconSize={iconSize}
                    />
                  </div>
                </Marker>
              );
            })}
          </GoogleMapReact>
        </div>
      </Card>
    </div>
  );
};

export default ClusterMap;
