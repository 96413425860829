import React from 'react';

function StatusCircleRenderer(props) {
      let color;

      const MobilityStatus = props.data.VorfallStatus

      switch (MobilityStatus) {
        case 'neu':
          color = '#ea0800';
          break;
        case 'pendent':
          color = '#FFB400';
          break;
        case 'abgeschlossen':
          color = '#0f963a';
          break;
        default:
          color = 'gray';
      }

      const circleStyle = {
        backgroundColor: color,
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        margin: 'auto'
      };

      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <div style={circleStyle} title={MobilityStatus}></div>
        </div>
      );
}

export default StatusCircleRenderer;