import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';

import {
    Button,
    Card,
    Row, Typography, Divider
} from 'antd';
import dayjs, {Dayjs} from "dayjs";
import {SettingsContext} from "../../App.js";
import {convertKilometersToMiles, timezoneDateString, timezoneTimeString} from '../../utility/conversions.js';

const {Title, Paragraph, Text, Link} = Typography;


const MapMarker = (props) => {

    const {timezone, distance} = useContext(SettingsContext);
    const timestamp = new Date(props.ts);

    return (
        <div style={{whiteSpace: "pre-line"}}>
            <Row>
                <Text style={{fontWeight: 'bold', marginBottom: "8px"}}>
                    Box ID - {props.box}
                </Text>
            </Row>
            <Row>
                <Text>
                   GPS Date: {timezoneDateString(timestamp, timezone)}
                </Text>
            </Row>
            <Row>
                <Text>
                   GPS Time: {timezoneTimeString(timestamp, timezone)}
                </Text>
            </Row>
            <Divider style={{marginBottom: 4, marginTop: 4}}></Divider>
            <Row>
                <Text>
                   Speed: {convertKilometersToMiles(props?.speed, distance)} {distance === 'kilometers' ? 'km/h' : 'mph'}
                </Text>
            </Row>
        </div>
    );
}

export default MapMarker;