import React, {useState} from 'react';
import {Modal} from 'antd';
import ToDoInfo from "./ToDoInfo";

const ToDoInfoModal = (props) => {

  const handleCancel = () => {
    props.hideModal && props.hideModal();
  };

  return (
    <>
      <Modal title="Übersicht der Notizen"
             open={props.open}
             onCancel={handleCancel}
             width={"60vw"}
             height={"60vh"}
             footer={null}
      >
        <ToDoInfo count={props.openCount}/>
      </Modal>
    </>
  );
};

export default ToDoInfoModal;