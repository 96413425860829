import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';

import {
    Button,
    Card,
    DatePicker, notification, Row, Typography, Divider
} from 'antd';
import dayjs, {Dayjs} from "dayjs";
import {SettingsContext} from "../../App.js";
import {convertKilometersToMiles, timezoneDateString, timezoneTimeString} from '../../utility/conversions.js';

const {Title, Paragraph, Text, Link} = Typography;


const MapMarkerStationary = (props) => {

    const {timezone, distance} = useContext(SettingsContext);
    const timestampStart = new Date(props.ts_start);
    const timestampEnd = new Date(props.ts_end);

    return (
        <div style={{whiteSpace: "pre-line"}}>
            <Row>
                <Text style={{fontWeight: 'bold', marginBottom: "8px"}}>
                    Vehicle ID - {props.vehicle}
                </Text>
            </Row>
            <Row>
                <Text style={{marginBottom: "8px"}}>
                    Total Stationary Time - {props.ts_diff_str}
                </Text>
            </Row>
            <Divider style={{marginBottom: 4, marginTop: 4}}></Divider>
            <Row>
                <Text>
                   Date Start: {timezoneDateString(timestampStart, timezone)}
                </Text>
            </Row>
            <Row>
                <Text>
                   Time Start: {timezoneTimeString(timestampStart, timezone)}
                </Text>
            </Row>
            <Divider style={{marginBottom: 4, marginTop: 4}}></Divider>
            <Row>
                <Text>
                   Date End: {timezoneDateString(timestampEnd, timezone)}
                </Text>
            </Row>
            <Row>
                <Text>
                   Time End: {timezoneTimeString(timestampEnd, timezone)}
                </Text>
            </Row>
        </div>
    );
}

export default MapMarkerStationary;