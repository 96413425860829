import React from "react";
import Popup from 'reactjs-popup';
import './ImageRenderer.css'


export default class ImageRenderer extends React.Component {

  constructor(props) {
    super(props);
    // console.log(props)
  }

  render() {

    if (this.props.value == null || typeof this.props.value !== "string") {
      return null;
    }

    const isPDF = this.props.value.includes(".pdf");
    if (isPDF) {
      return (
        <a href={this.props.value} download>
          PDF
        </a>
      );
    }

    return (
      <Popup
        trigger={<img
          className="small"
          src={this.props.value}
          alt={this.props.name}
          style={{width: "100%"}}
        />}
        modal
        nested
        position="center center"
      >
        {close => (
          <div className="image-modal">
            <button className="close" onClick={close}>
              &times;
            </button>
            <div className="content">
              <img
                className="plot"
                src={this.props.value}
                alt={this.props.name}
              />
            </div>
          </div>
        )}
      </Popup>
    );
  }
}
