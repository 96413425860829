import React, {useEffect, useState} from 'react';
import {Button, Card, Col, DatePicker, notification, Row, Select, Switch, Typography} from 'antd';
import {MenuProps} from 'antd';
import dayjs from 'dayjs';
import {Dayjs} from 'dayjs';
import ClusterMapModal from "../ClusterMap/ClusterMapModal";
import {GlobalOutlined, ReloadOutlined, HistoryOutlined} from "@ant-design/icons";
import axiosInstance from "../../services/axios";

const {RangePicker} = DatePicker;
const {Text, Link} = Typography;

const FilterCard = (props) => {

    const [updated, setUpdated] = useState('loading');

    const getUpdatedValue = () => {
        axiosInstance.get(
            './api/incidents/updated')
            .then(res => {
                const date = new Date(res.data);
                const options = {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false
                };
                const systemTime = date.toLocaleString(navigator.language, options)
                setUpdated(systemTime.replace(/\//g, '.').replace(/,/g, ''))
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error in getUpdatedValue',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getUpdatedValue(); // assuming getUpdatedValue returns a Promise
            } catch (error) {
                // handle errors here
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 15 * 60 * 1000); // 15 minutes in milliseconds

        return () => clearInterval(intervalId);
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const handleSelect = () => setIsOpen(false);

    const rangePresets = [
        {label: 'Letzten 7 Tage', value: [dayjs().add(-7, 'd'), dayjs()]},
        {label: 'Letzten 14 Tage', value: [dayjs().add(-14, 'd'), dayjs()]},
        {label: 'Letzten 30 Tage', value: [dayjs().add(-30, 'd'), dayjs()]},
        {label: 'Letzten 90 Tage', value: [dayjs().add(-90, 'd'), dayjs()]},
    ];

    return (<>
            <Card style={{
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                border: '1px solid #e8e8e8',
                marginBottom: '8px',
            }} styles={{
                body: {padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"}
            }}>
                <Row gutter={16}>
                    <RangePicker
                        // picker="month"
                        open={isOpen}
                        onOpenChange={(open) => setIsOpen(open)}
                        onSelect={handleSelect}
                        defaultValue={props.dateRange}
                        defaultPickerValue={props.dateRange}
                        presets={rangePresets}
                        onChange={props.onDateRangeChange}
                        style={{marginLeft: '25px', marginTop: '10px', marginBottom: '10px', width: '230px'}}
                    />
                    <Select
                        showSearch
                        allowClear={true}
                        // size={'large'}
                        style={{width: 130, marginLeft: '10px', marginTop: '10px'}}
                        placeholder="Flottenregion"
                        optionFilterProp="children"
                        onChange={props.onFleetRegionChange}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={[
                            {
                                label: 'Mobility-Go Basel',
                                value: 'Mobility-Go Basel',
                            },
                            {
                                label: 'ZH-Stadt Süd - ZH-See - Obersee',
                                value: 'ZH-Stadt Süd - ZH-See - Obersee',
                            },
                            {
                                label: 'ZH-Stadt Nord - Ostschweiz',
                                value: 'ZH-Stadt Nord - Ostschweiz',
                            },
                            {
                                label: 'Westschweiz',
                                value: 'Westschweiz',
                            },
                            {
                                label: 'Nordwest Schweiz',
                                value: 'Nordwest Schweiz',
                            },
                            {
                                label: 'Mittelland-West',
                                value: 'Mittelland-West',
                            },
                            {
                                label: 'Flottenmanagement intern',
                                value: 'Flottenmanagement intern',
                            },
                            {
                                label: 'Zentral-, Südost-Schweiz',
                                value: 'Zentral-, Südost-Schweiz',
                            }
                        ]}
                    />
                    <Select
                        showSearch
                        allowClear={true}
                        // size={'large'}
                        style={{width: 120, marginLeft: '10px', marginTop: '10px'}}
                        placeholder="Status"
                        optionFilterProp="children"
                        defaultValue={props.eventStatus}
                        onChange={props.onEventStatusChange}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={[
                            {
                                label: 'Neu',
                                value: 'neu',
                            },
                            {
                                label: 'Pendent',
                                value: 'pendent',
                            },
                            {
                                label: 'Abgeschlossen',
                                value: 'abgeschlossen',
                            },
                            {
                                label: 'Ungeklärt',
                                value: 'ungeklärt',
                            }
                        ]}
                    />
                    <Button
                        style={{marginLeft: '10px', marginTop: '10px'}}
                        onClick={props.onRefreshTableChange}
                        icon={<ReloadOutlined/>}
                    >
                        Refresh
                    </Button>
                    {/*<Button*/}
                    {/*  style={{marginLeft: '10px', marginTop: '10px'}}*/}
                    {/*  onClick={props.onResetColumnsChange}*/}
                    {/*  icon={<HistoryOutlined/>}*/}
                    {/*>*/}
                    {/*  Reset */}
                    {/*</Button>*/}
                    <Button
                        style={{marginLeft: '10px', marginTop: '10px'}}
                        onClick={props.onResetFilterChange}
                        icon={<HistoryOutlined/>}
                    >
                        Reset Filter
                    </Button>
                    <Button
                        style={{marginLeft: '10px', marginTop: '10px'}}
                        onClick={props.onMapChange}
                        icon={<GlobalOutlined/>}
                    >
                        Karte
                    </Button>
                    <Switch
                        style={{marginLeft: '10px', marginTop: '15px'}}
                        onChange={props.onDetailsChange}
                        checkedChildren="details"
                        unCheckedChildren="details"
                        checked={props.detailCardOpen}
                    />
                    <Text style={{
                        fontSize: '13px',
                        marginRight: '20px',
                        marginLeft: 'auto',
                        marginTop: '8px',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        Updated:<br/> {updated}
                    </Text>
                </Row>
            </Card>
        </>
    );
};

export default FilterCard;