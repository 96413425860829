import React, {useState, useEffect, useRef, useCallback, useMemo, useContext} from 'react';
import {Col, notification, Table, Row, Card, Typography, Tooltip, Button} from 'antd';
import axiosInstance from "services/axios";
import {AgGridReact} from "ag-grid-react";
import moment from "moment";
import dayjs, {Dayjs} from "dayjs";
import {ColumnWidthOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {SettingsContext} from "../../App.js";
import {
    convertKilometersToMiles,
    timezoneDateString,
    timezoneTimeString,
    timezoneDateTimeString
} from '../../utility/conversions.js';
import "./../../common_components/AGGrid/carvaloo_theme.css";

const {Paragraph, Text} = Typography;

const TripTable = (props) => {

    const {timezone, distance} = useContext(SettingsContext);
    const gridRef = useRef();
    const cardRef = useRef();

    function customDateFormatter(params) {
        return timezoneDateString(params.value, params.context?.timezone)
    }

    function customTimeFormatter(params) {
        return timezoneTimeString(params.value, params.context?.timezone)
    }

    const distanceFormatter = (params) => {
        if (params.value) {
            return convertKilometersToMiles(params.value, params.context?.distance);
        } else {
            return null;
        }
    }


    const getCustomContextMenuItems = (params) => {

        const contextMenu = [
            "cut",
            "copy",
            "copyWithHeaders",
            "copyWithGroupHeaders",
            "paste",
            "seperator",
            {
                name: "CSV Export (.csv)",
                action: () => params.api.exportDataAsCsv({
                    allColumns: true,
                    processCellCallback: processCellForClipboard
                })
            },
            {
                name: "Excel Export (.xlsx)",
                action: () => params.api.exportDataAsExcel({
                    allColumns: true,
                    processCellCallback: processCellForClipboard
                })
            }
        ]

        return contextMenu
    }

    const processCellForClipboard = (params) => {
        let colDef = params.column.getColDef();
        if (colDef.valueFormatter) {
            return colDef.valueFormatter({
                ...params,
                data: params.node?.data,
                colDef: colDef
            });
        }

        return params.value;
    }

    function renderLocation(params) {
        if (params.data && params.data.location) {
            const location = params.data.location;
            const lat = location.lat.toFixed(6);
            const lon = location.lon.toFixed(6);
            return `Lat: ${lat}, Lon: ${lon}`;
        }
        return '';
    }

    useEffect(() => {
        if (gridRef.current.api) {
            gridRef.current.api.refreshCells({force: true});
        }
    }, [distance, timezone]);

    // Define your column definition with the custom cell renderer
    const columnDefs = [
        {
            field: 'trip_start',
            headerName: 'Start Date',
            width: 120,
            valueFormatter: customDateFormatter
        },
        {
            field: 'trip_start',
            headerName: 'Start Time',
            width: 120,
            valueFormatter: customTimeFormatter,
        },
        {
            field: 'trip_end',
            headerName: 'End Date',
            width: 120,
            hide: true,
            valueFormatter: customDateFormatter
        },
        {
            field: 'trip_end',
            headerName: 'End Time',
            width: 120,
            valueFormatter: customTimeFormatter,
        },

        {
            field: 'driving_total',
            useValueFormatterForExport: false,
            headerName: 'Duration',
            width: 120,
            filter: "agNumberColumnFilter",
            valueFormatter: (params) => {
                // Assuming params.value represents time in hours
                const hours = Math.floor(params.value / 60);
                const minutes = Math.round((params.value / 60 - hours) * 60);
                if (hours > 0) {
                    return `${hours}h ${minutes}m`;
                } else {
                    return `${minutes}m`;
                }
            },
        },
        {
            field: 'Fahrstil',
            headerName: 'Driving Behavior',
            width: 120,
        },
        {
            field: 'distance',
            headerName: 'Driving Distance',
            width: 120,
            filter: "agNumberColumnFilter",
            valueFormatter: distanceFormatter,
        },
        {
            field: 'velocity_max',
            headerName: 'Max Speed',
            width: 120,
            filter: "agNumberColumnFilter",
            valueFormatter: distanceFormatter,
        },
        {
            field: 'velocity_mean',
            headerName: 'Mean Speed',
            width: 130,
            filter: "agNumberColumnFilter",
            valueFormatter: distanceFormatter,
        },
    ]

    const [gridOptions, setGridOptions] = useState({
        rowSelection: 'single',
        suppressRowClickSelection: false,
        enableRangeSelection: 'true',
        columnDefs: columnDefs,
        context: {},
        onGridReady: function () {
            gridRef.current.api.sizeColumnsToFit()
            if (props.data) {
                gridRef.current.api.setRowData(props.data);
            }
        },
        onRowSelected: (event) => {
            if (event.node.isSelected()) {
                if (event.data) {
                    props.setSelectedRow([event.data.trip_start, event.data.trip_end])
                }
            }
        },
        getContextMenuItems: getCustomContextMenuItems,
        defaultColDef: {
            width: 100,
            sortable: true,
            filter: true,
            resizable: true, //minWidth: 100,
            editable: false, //cellEditor: PopupCellEditor
            // suppressMenu: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: {fontSize: '14px'},
            menuTabs: ["filterMenuTab"],
            useValueFormatterForExport: true
        },
        // sideBar: {
        //     position: 'right',
        //     defaultToolPanel: '',
        //     toolPanels: [
        //         {
        //             id: 'columns',
        //             labelDefault: 'Columns',
        //             labelKey: 'columns',
        //             iconKey: 'columns',
        //             toolPanel: 'agColumnsToolPanel',
        //             minWidth: 150,
        //             maxWidth: 150,
        //             width: 150,
        //             toolPanelParams: {
        //                 suppressRowGroups: true,
        //                 suppressValues: true,
        //                 suppressPivotMode: true
        //             }
        //         },
        //         {
        //             id: 'filters',
        //             labelDefault: 'Filters',
        //             labelKey: 'filters',
        //             iconKey: 'filter',
        //             toolPanel: 'agFiltersToolPanel',
        //             minWidth: 200,
        //             maxWidth: 400,
        //             width: 200
        //         }
        //     ],
        // }
    })

    useEffect(() => {
        if (gridRef.current && props.data) {
            if (gridRef.current.api) {
                gridRef.current.api.setRowData(props.data);
            }
        }
    }, [props.data, props.vehicleStationary, props.vehicleTracking]);

    const InfoTooltip = ({text}) => {
        return (
            <Tooltip title={text}>
                <InfoCircleOutlined style={{color: '#1890ff', fontSize: '16px', marginLeft: '5px'}}/>
            </Tooltip>
        );
    };

    const onResizeColumns = () => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.sizeColumnsToFit()
        }
    }

    return (<>
            <Card
                ref={props.refTour}
                style={{
                    width: 'calc(50% - 8px)',
                    height: '100%',
                    minHeight: 380,
                    marginRight: 16
                }}
                extra={
                    <>
                        <Button
                            icon={<ColumnWidthOutlined style={{color: "gray"}}/>}
                            style={{
                                height: 32,
                            }}
                            onClick={onResizeColumns}
                        />
                    </>
                }
                styles={{ body: {padding: 0}}}
                title={<>
                    Trips
                    <InfoTooltip text="Show the trip details for the vehicle within the selected daterange."/>
                </>}
            >
                <div style={{height: 350}} className="ag-theme-alpine">
                    <AgGridReact
                        ref={gridRef}
                        context={{distance, timezone}}
                        gridOptions={gridOptions}
                    />
                </div>
            </Card>
        </>
    );
};

export default TripTable;