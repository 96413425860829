import React, {useEffect, useState, useRef} from 'react'
import {Card, Button, Space, Tag, Tabs, message, Popconfirm, Statistic, Typography} from 'antd'

import RightOutlined from "@ant-design/icons/RightOutlined";
import Tooltip from "antd/es/tooltip";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import {ReloadOutlined} from "@ant-design/icons";

const {Title, Paragraph, Text} = Typography;


function CVScreenHeader(props) {
    const [loading, setLoading] = useState(true)
    const [dataReload, setDataReload] = useState(0)
    const [openPopUps, setOpenPopUps] = useState({})

    return (
        <>
            <div style={{
                width: "100%",
                backgroundColor: "white",
                borderBottom: `1px solid #f2f2f2`
            }}>
                <div style={{minHeight: '50px'}}>
                    <div style={{
                        padding: "16px 24px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "baseline", gap: "16px"}}>
                            {props.hasDrawer ? (
                                    <Button
                                        onClick={() => props.setShowDrawer((prev) => !prev)}
                                        type="primary"
                                        icon={
                                            <div
                                                style={{display: "flex", justifyContent: "center", alignItems: "center",}}
                                                className={`ereignisse-icon ${props.showDrawer ? "rotate-180" : "rotate-0"}`}
                                            >
                                                <RightOutlined/>
                                            </div>
                                        }
                                    />
                                ) :
                                <Button
                                    type="primary"
                                    icon={
                                        <div
                                            style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                                        >
                                            <ReloadOutlined/>
                                        </div>
                                    }
                                />
                            }
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <Title level={4} style={{
                                    margin: 0,
                                    fontWeight: 500
                                }}
                                >
                                    {props.title}
                                </Title>
                                {props.trial &&
                                    <Tag color="orange" style={{marginTop: 0, marginLeft: 16}}>Free trial use</Tag>
                                }
                                {props.limitedAccess &&
                                    <Tooltip title={props.limitedAccessTooltip}>
                                        <Tag color="orange" style={{marginTop: 0, marginLeft: 8}}>Limited access</Tag>
                                    </Tooltip>
                                }
                            </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "baseline"}}>
                            {props.sendEmail && (
                                <Button onClick={props.sendEmail} style={{marginLeft: '5px'}}>
                                    <div className="d-flex justify-content-center align-items-center">
                                        Support
                                    </div>
                                </Button>
                            )}
                        </div>
                    </div>
                    <div style={{
                        padding: "0px 24px",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: "10px 48px"
                    }}>
                        {props.headerData && props.headerData.map(function (e, idx) {
                            return (
                                <Statistic
                                    key={idx}
                                    title={e.name}
                                    groupSeparator={''}
                                    value={e.value !== null ? e.value.toString() : e.value}
                                    prefix={e.icon}
                                    valueStyle={{fontSize: 20, fontWeight: 500}}
                                    size="small"
                                    style={{marginRight: 30}}
                                />)
                        })}
                        {!props.headerData &&
                            <div style={{height: 53}}>
                            </div>
                        }
                    </div>
                </div>
                {props.tabData && (
                    <div style={{
                        display: "flex",
                        padding: "8px 24px 0px 24px",
                        height: "47px",
                    }}>
                        <Tabs
                            style={{gap: "12px", zIndex: 2, marginTop: 2}}
                            defaultActiveKey={"1"}
                            items={props.tabData}
                            onTabClick={(e) => props.setActiveTab(e)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default CVScreenHeader;


