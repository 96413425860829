import {Breadcrumb, Typography, Col, Row, Image, Button} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import React, {useEffect} from "react";
import handleDownload from "services/download";
import axiosInstance from "../../services/axios";

// import Manual from './../../assets/files/20240124_Installation_Manual_DDM_on_Windshield_incl_safetyRemarks.pdf'
// import Requirements from './../../assets/files/20240315_DDM_installation_requirements.pdf'

const {Title, Paragraph, Text} = Typography;

const Installation = (props) => {

    useEffect(() => {
        const payload = {page_name: 'help_center_damage_installation'}
        axiosInstance.post('/api/visit', payload).then(res => {}).catch(error => {})
     }, []);

    return (<>
        <Title style={{margin: 0, marginBottom: 24}} level={1}> Installation </Title>
        {/* <Row style={{gap: 16, maxWidth: 1200, marginBottom: 24}}>
            <Text>
                This section describes both the physical and the virtual installation of the hardware.
            </Text>
        </Row> */}
        <Title id="telematics_mounting" style={{margin: 0, marginBottom: 24}} level={2}> Telematics Mounting </Title>
        <Row style={{gap: 16, maxWidth: 1200, marginBottom: 24}}>
            <Text>
                The instructions for the hardware installation are dependent on the hardware used and the vehicle type.
                Please contact us via <a href="mailto:operations-support@carvaloo.com">operations-support@carvaloo.com</a> before installation and state the type of vehicle in which you wish to install the telematics hardware.
                You will then receive installation instructions from us.<br/><br/>
                If you use the CloudBoxx with the damage detection module, you can also simply mount the module on the
                windscreen. Please follow the instructions below.<br/>
                <Button style={{marginTop: 4, marginBottom: 24}} type="primary"
                        onClick={() => void handleDownload('20240124_Installation_Manual_DDM_on_Windshield_incl_safetyRemarks.pdf')}>
                    Download Installation Manual
                </Button><br/>
                If a specific vehicle model is not yet qualified for the telematics unit, please reach out to us. 
                Make sure to follow the installation requirements below, if you want to propose a new installation spot. Before installing more vehicles, we need to approve the proposed position.<br/>
                <Button style={{marginTop: 4}} type="primary"
                        onClick={() => void handleDownload('20240315_DDM_installation_requirements.pdf')}>
                    Download DDM Installation Requirements
                </Button>
            </Text>
        </Row>
        <Title id="box_activation" style={{margin: 0, marginBottom: 24}} level={2}> Box Activation </Title>
            <Text style={{width: '100%'}}>
                After mounting the telematics unit into a vehicle you need to activate the damage detection service for a
                specific vehicle. Each unit needs to be virtually installed and paired with the vehicle.
                The process differs for carvaloo and INVERS customers:
            </Text>
        <Row style={{gap: 0, maxWidth: 1200, marginBottom: 0, marginTop: 16}}>
            <Title style={{margin: 0, marginBottom: 0, marginTop: 0}} level={5}> carvaloo Customers </Title>
            <Text style={{width: '100%'}}>
                carvaloo customers can install the vehicles by using our browser-based <a href="https://installation.carvaloo.com">InstallationApp</a>. 
                The instructions for this can be found in the<a href={'#installation_app'} onClick={() => props.setActiveTab('mobile_apps')} style={{marginLeft: 4, marginRight: 4}}>InstallationApp</a>section.
            </Text>
        </Row>
        <Row style={{gap: 0, maxWidth: 1200, marginBottom: 0}}>
            
            <Title style={{margin: 0, marginBottom: 0, marginTop: 24}} level={5}> INVERS Customers </Title>
            <Text style={{width: '100%'}}>
                INVERS customers activate the telematics with the SmartControl App. For a more detailed instruction please contact INVERS.
            </Text>
        </Row>
    </>)
};

export default Installation;
