import React from "react";

const EventUrlRenderer = ({...props}) => {
    const urlBase = "https://webapp.carvaloo.com/fahrzeug/"
    const urlEvent = urlBase.concat(props.data?.vehicle_id, "?UVID=", props.data?.UVID)
    return (
        <a href={urlEvent} target="_blank">
            {props.data?.vehicle_id}
        </a>
    )
}

export default EventUrlRenderer;