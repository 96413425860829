import React, {useEffect} from "react";
import {Button, Layout, Result} from 'antd';
import Icon, {LoginOutlined} from '@ant-design/icons';
import {useAuth} from "react-oidc-context";

import CVLogo from "../../assets/logos/cv_logo.svg?react";

const {Content} = Layout;
export const SignOutScreen = () => {

    const auth = useAuth();

    useEffect(() => {
        if (auth.isAuthenticated) {
            console.log('user currently authenticated.')
            auth.removeUser();
            auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
        }
    }, [auth.isAuthenticated]);

    return (
        <Layout>
            <Content
                style={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                <Result
                    status="success"
                    // icon={<Icon component={CVLogo}/>}
                    title="You signed out of your account"
                    subTitle="If you want to sign in again, you can click the button below"
                    extra={[
                        <Button key="signin" icon={<LoginOutlined/>} onClick={() => void auth.signinRedirect()}
                                type="primary">Sign in</Button>,
                    ]}
                ></Result>
            </Content>
        </Layout>
    );
}

