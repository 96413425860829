import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';
import ReactJson from "react-json-view";
import {
    Button,
    Card,
    Descriptions,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Popover,
    Switch,
    Table, Space, Checkbox,
    Divider, DatePicker, Tooltip, InputNumber, Statistic
} from 'antd';

import Icon, {
    InfoCircleOutlined,
    SettingOutlined,
    ExclamationCircleOutlined,
    CopyOutlined,
    CarOutlined
} from "@ant-design/icons";
import {FaFile, FaCog} from 'react-icons/fa';
import axiosInstance from "services/axios";
import dayjs from "dayjs";

import timezone from 'dayjs/plugin/timezone'; // Import the timezone plugin
import utc from 'dayjs/plugin/utc';
import calendarIcon from "../../assets/icons/calendar.svg";
import numberIcon from "../../assets/icons/number.svg";
import checkIcon from "../../assets/icons/check.svg";
import compassIcon from "../../assets/icons/compass.svg"; // Import utc plugin
import {SettingsContext} from "../../App.js";
import {
    convertKilometersToMiles,
    timezoneDateString,
    timezoneTimeString,
    timezoneDateTimeString
} from '../../utility/conversions.js';


// Enable the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone); // Extend dayjs with the timezone plugin

const {Search} = Input;
const {Title, Paragraph, Text, Link} = Typography;
const {RangePicker} = DatePicker;


const VehicleSearch = (props) => {
    const {timezone, distance} = useContext(SettingsContext);
    const [data, setData] = useState(null);

    const [reservationDates, setReservationDates] = useState([null, null]);

    // Detect browser's locale
    const userLocale = Intl.DateTimeFormat().resolvedOptions().locale;

    useEffect(() => {
        if (reservationDates[0]) {
            const reservation_from = dayjs(new Date(reservationDates[0]).toISOString()).tz(timezone);
            const reservation_to = dayjs(new Date(reservationDates[1]).toISOString()).tz(timezone);
            props.handleDateRangeChange([reservation_from, reservation_to])
            props.handleTrackingChange(true)
        }
    }, [reservationDates, timezone]);

    useEffect(() => {
        if (props.inputSearch) {
            getInfoValues();
        } else {
            setData(null)
        }
    }, [props.inputSearch]);

    const getInfoValues = () => {
        axiosInstance.post(
            '/api/location/vehicle_info',
            {
                search: props.inputSearch,
                customer: props.customer
            })
            .then(res => {
                setData(res.data)
                if (res.data.set_reservation) {

                    if (props.permissions?.tracking === 'vehicle-tracking_limited') {
                        // If permission is limited, allow dates only within the past week
                        const start_date = dayjs(res.data.reservation_from)
                        if (start_date.isBefore(dayjs().subtract(7, 'd'))) {
                            notification['warning']({
                                message: 'Reservation start more than 7 days in the past - missing the required permissions to display the reservation tracking data',
                            });
                        } else {
                            setReservationDates([res.data.reservation_from, res.data.reservation_to])
                        }
                    } else {
                        setReservationDates([res.data.reservation_from, res.data.reservation_to])
                    }
                }

            })
            .catch(error => {
                console.log(error);
                setData(null)
                notification['error']({
                    message: 'Error in get VehicleInfo',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    // Set the locale for dayjs based on the browser's locale
    dayjs.locale(userLocale);

    // Define a function to determine the time format based on the locale
    const getTimeFormatFromLocale = (locale) => {
        // Map locale to appropriate time format
        if (locale.startsWith('en')) {
            // English locale example: Use 12-hour format for English
            return 'hh:mm A';
        } else {
            // For other locales, default to a 24-hour format
            return 'HH:mm';
        }
    };

    // Define a function to determine the time format based on the locale
    const getDateFormatFromLocale = (locale) => {
        // Map locale to appropriate time format
        if (locale.startsWith('en')) {
            // English locale example: Use 12-hour format for English
            return 'DD-MM-YYYY';
        } else {
            // For other locales, default to a 24-hour format
            return 'MM-DD-YYYY';
        }
    };

    const use12HourTimeFormat = (locale) => {
        // Map locale to appropriate time format
        if (locale.startsWith('en')) {
            // English locale example: Use 12-hour format for English
            return true;
        } else {
            // For other locales, default to a 24-hour format
            return false;
        }
    };

    const userTimeFormat = getTimeFormatFromLocale(userLocale);

    const userDateTimeFormat = () => {
        const timeFormat = getTimeFormatFromLocale(userLocale)
        const dateFormat = getDateFormatFromLocale(userLocale)
        return dateFormat + ' ' + timeFormat;
    }

    const rangePresets = [
        {label: 'Last 1 hour', value: [dayjs().add(-1, 'hours'), dayjs()]},
        {label: 'Last 3 hours', value: [dayjs().add(-3, 'hours'), dayjs()]},
        {label: 'Last 6 hours', value: [dayjs().add(-6, 'hours'), dayjs()]},
        {label: 'Last 12 hours', value: [dayjs().add(-12, 'hours'), dayjs()]},
        {label: 'Last 24 hours', value: [dayjs().add(-1, 'd'), dayjs()]},
        {label: 'Last 48 hours', value: [dayjs().add(-2, 'd'), dayjs()]},
        {label: 'Last 72 hours', value: [dayjs().add(-3, 'd'), dayjs()]},
        {label: 'Last week', value: [dayjs().add(-7, 'd'), dayjs()]},
    ];

    useEffect(() => {

        if (timezone === 'UTC') {
            const startDate = dayjs(props.dateRange[0]).utc();
            const endDate = dayjs(props.dateRange[1]).utc();
            props.setDateRange([startDate, endDate]);
        } else if (timezone === 'Browser') {
            const startDate = dayjs(props.dateRange[0]).local();
            const endDate = dayjs(props.dateRange[1]).local();
            props.setDateRange([startDate, endDate]);
        } else {
            const startDate = dayjs(props.dateRange[0]).tz(timezone);
            const endDate = dayjs(props.dateRange[1]).tz(timezone);
            props.setDateRange([startDate, endDate]);
        }
    }, [timezone]);

    const InfoTooltip = ({text}) => {
        return (
            <Tooltip title={text}>
                <InfoCircleOutlined style={{color: '#1890ff', fontSize: '16px', marginLeft: '5px'}}/>
            </Tooltip>
        );
    };

    const onChangeMinimum = (value) => {
        if (value) {
            props.setSearchSettings({
                ...props.searchSettings, // Spread the existing state
                min_stationary: value // Update the specific property
            });
        }
    }

    const onChangeMarkerSize = (value) => {
        if (value) {
            props.setSearchSettings({
                ...props.searchSettings, // Spread the existing state
                marker_size: value // Update the specific property
            });
        }
    }

    const onChangeStationInclude = (value) => {
        props.setSearchSettings({
            ...props.searchSettings, // Spread the existing state
            include_station: value.target.checked // Update the specific property
        });
    }

    const onChangeStationaryCluster = (value) => {
        props.setSearchSettings({
            ...props.searchSettings, // Spread the existing state
            cluster_stationary: value.target.checked // Update the specific property
        });
    }

    const settingsMenu = (
        <div style={{width: 280, height: 120}}>
            <Row>
                <Col>
                    <span>Minimum stationary time (minutes): </span>
                    <InputNumber
                        min={1}
                        max={60}
                        defaultValue={props.searchSettings.min_stationary}
                        style={{width: '50px'}}
                        onChange={onChangeMinimum}
                        size={'small'}
                    />
                </Col>
            </Row>
            <Row>
                <span style={{marginRight: 13}}>Include stationary close to station:</span>
                <Checkbox
                    onChange={onChangeStationInclude}
                    defaultChecked={props.searchSettings.include_station}
                    style={{verticalAlign: 'middle'}}
                >
                </Checkbox>
            </Row>
            <Row>
                <Col>
                    <span>Stationary Marker Scaling (%): </span>
                    <InputNumber
                        min={50}
                        max={300}
                        defaultValue={props.searchSettings.marker_size}
                        style={{width: '80px'}}
                        onChange={onChangeMarkerSize}
                        size={'small'}
                    />
                </Col>
            </Row>
            <Row>
                <span style={{marginRight: 10}}>GeoCluster stationary points:</span>
                <Checkbox
                    onChange={onChangeStationaryCluster}
                    defaultChecked={props.searchSettings.cluster_stationary}
                    style={{verticalAlign: 'middle'}}
                >
                </Checkbox>
            </Row>
            <Row>
                <Col>
                    <span>Animation Duration (seconds): </span>
                    <InputNumber
                        min={5}
                        max={120}
                        defaultValue={props.animationDuration}
                        style={{width: '80px'}}
                        onChange={props.setAnimationDuration}
                        size={'small'}
                    />
                </Col>
            </Row>
        </div>
    );

    const handleCopy = (text) => {
        // Logic to copy text to clipboard
        navigator.clipboard.writeText(text);
        // Optionally, provide feedback to the user that the text has been copied
    };

    return (<>
            <Card
                style={{
                    width: 600,
                    height: 630,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #e8e8e8',
                }}
                styles={{ body: {
                    paddingRight: 24,
                    paddingLeft: 24,
                    paddingTop: 10,
                    paddingBottom: 10,
                }}}
                title={<>
                    Search
                    <InfoTooltip text="Search for a vehicle using VIN, VehicleID, ReservationID or BoxID.
                         Live update refreshes the current vehicle location every 5 seconds.
                         Tracking visualizes logged GPS points and Stationary visualizes points where the
                         vehicle was not moving."/>
                </>}
                extra={
                    <Popover
                        title={'Settings'}
                        trigger="click"
                        content={settingsMenu}

                    >
                        <Button size={'small'}>
                            <Space>
                                Settings
                                <SettingOutlined/>
                            </Space>
                        </Button>
                    </Popover>
                }
            >
                <Row>

                </Row>
                <Row>
                    <Text>
                        <Paragraph style={{marginBottom: 4}}>
                            Select the vehicle location visualization option
                        </Paragraph>
                    </Text>
                </Row>
                <Row>
                    <Switch
                        style={{marginTop: '0px', marginBottom: 16}}
                        onChange={props.handleUpdateChange}
                        checkedChildren="live update"
                        unCheckedChildren="live update"
                        checked={props.vehicleUpdate}
                        disabled={!props.inputSearch || props.vehicleTracking || props.vehicleStationary}
                    />
                    <Switch
                        style={{marginTop: '0px', marginLeft: 8, marginBottom: 16}}
                        onChange={props.handleTrackingChange}
                        checkedChildren="tracking"
                        unCheckedChildren="tracking"
                        checked={props.vehicleTracking}
                        disabled={!props.inputSearch}
                    />
                    <Switch
                        style={{marginTop: '0px', marginLeft: 8, marginBottom: 16}}
                        onChange={props.handleStationaryChange}
                        checkedChildren="stationary"
                        unCheckedChildren="stationary"
                        checked={props.vehicleStationary}
                        disabled={!props.inputSearch}
                    />
                </Row>
                <Text>
                    <Paragraph style={{marginBottom: 4}}>
                        Set datetime range for vehicle tracking
                    </Paragraph>
                </Text>
                <RangePicker
                    format={userDateTimeFormat()}
                    showTime={{
                        format: userTimeFormat,
                        use12Hours: use12HourTimeFormat(userLocale),
                    }}
                    disabled={!(props.vehicleTracking || props.vehicleStationary)}
                    presets={rangePresets}
                    disabledDate={(date) => {
                        if (props.permissions?.tracking === 'vehicle-tracking_limited') {
                            // If permission is limited, allow dates only within the past week
                            return date.isBefore(dayjs().subtract(7, 'd'));
                        } else {
                            // For other cases, allow dates within the past 90 days
                            return date.isBefore(dayjs().subtract(365, 'd'));
                        }
                    }}
                    onChange={props.handleDateRangeChange}
                    style={{marginBottom: 16, width: 475}}
                    allowClear={false}
                    value={props.dateRange}
                />
                <Divider style={{marginBottom: 8, marginTop: 8}}></Divider>
                {props.inputSearch && <div>
                    <Row style={{marginTop: 8}}>
                        <Col span={12}>
                            <Statistic
                                title="Last GPS position"
                                groupSeparator={''}
                                value={data?.last_signal ? `${timezoneDateString(data.last_signal, timezone)} ${timezoneTimeString(data.last_signal, timezone)}` : 'Not available'}
                                prefix={<img src={calendarIcon} alt={calendarIcon}
                                             style={{marginBottom: -4, marginRight: 4}}/>}
                                valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                                size="small"
                            />
                            <Statistic
                                title="Vehicle ID"
                                groupSeparator={''}
                                value={data?.vehicle || 'Not available'}
                                formatter={(value) =>
                                    data?.vehicle ? (
                                        <a href={`https://webapp.carvaloo.com/fahrzeug/${String(data?.vehicle)}?UVID=${String(data?.uuid)}`}
                                           target="_blank" rel="noopener noreferrer">
                                            {value}
                                        </a>
                                    ) : (
                                        'Not available'
                                    )
                                }
                                prefix={<img src={numberIcon} alt={numberIcon}
                                             style={{marginBottom: -4, marginRight: 4}}/>}
                                valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 2}}
                                size="small"
                                suffix={
                                    <Button
                                        type="link"
                                        icon={<CopyOutlined style={{marginTop: -16}}/>}
                                        onClick={() => handleCopy(data?.vehicle)}
                                    />
                                }
                            />
                            <Statistic
                                title="Box ID"
                                groupSeparator={''}
                                value={
                                    data?.box ?
                                        `${data?.box}` :
                                        'Not available'
                                }
                                prefix={<img src={numberIcon} alt={numberIcon}
                                             style={{marginBottom: -4, marginRight: 4}}/>}
                                valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                                size="small"
                            />
                        </Col>
                        <Col span={12}>
                            <Statistic
                                title="Station"
                                value={
                                    data?.vehicle_station ?
                                        `${data.vehicle_station}` :
                                        'Not available'
                                }
                                prefix={<img src={compassIcon} alt={compassIcon}
                                             style={{marginBottom: -4, marginRight: 4}}/>}
                                valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                                size="small"
                            />
                            <Statistic
                                title="Distance to station"
                                value={
                                    data?.station_distance ?
                                        `${convertKilometersToMiles(data.station_distance, distance)}` :
                                        'Not available'
                                }
                                prefix={<img src={compassIcon} alt={compassIcon}
                                             style={{marginBottom: -4, marginRight: 4}}/>}
                                suffix={distance === 'kilometers' ? 'km' : 'miles'}
                                valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                                size="small"
                            />
                            <Statistic
                                title="Fleet"
                                value={
                                    data?.vehicle_fleet ?
                                        `${data?.vehicle_fleet}` :
                                        'Not available'
                                }
                                prefix={<CarOutlined style={{marginRight: 4}}/>}
                                valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                                size="small"
                            />
                        </Col>
                    </Row>
                    <Divider style={{marginBottom: 8, marginTop: 8}}></Divider>
                    <Row style={{marginTop: 8}}>
                        <Col span={12}>
                            <Statistic
                                title={data?.set_reservation ? "Selected Reservation Status" : "Current Reservation Status"}
                                groupSeparator={''}
                                value={
                                    data?.reservation_active ?
                                        `${data.reservation_active}` :
                                        'Not active'
                                }
                                prefix={
                                    data?.reservation_active ?
                                        <img src={checkIcon} alt={checkIcon}
                                             style={{marginBottom: -4, marginRight: 4}}/> :
                                        <ExclamationCircleOutlined style={{marginRight: 4}}/>
                                }
                                valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                                size="small"
                            />
                            <Statistic
                                title="Reservation From"
                                groupSeparator={''}
                                value={data?.reservation_from ? timezoneDateTimeString(data?.reservation_from, timezone) : 'Not available'}
                                prefix={<img src={calendarIcon} alt={calendarIcon}
                                             style={{marginBottom: -4, marginRight: 4}}/>}
                                valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                                size="small"
                            />
                        </Col>
                        <Col span={12}>
                            <Statistic
                                title={data?.set_reservation ? "Selected Reservation ID" : data?.reservation_active ? "Current Reservation ID" : "Most Recent Reservation ID"}
                                groupSeparator={''}
                                value={
                                    data?.reservation_id ?
                                        `${data.reservation_id.length > 24 ? data.reservation_id.slice(0, 24) + '...' : data.reservation_id}` :
                                        'Not available'
                                }
                                prefix={<img src={numberIcon} alt={numberIcon}
                                             style={{marginBottom: -4, marginRight: 4}}/>}
                                valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 2}}
                                size="small"
                                suffix={
                                    <Button
                                        type="link"
                                        icon={<CopyOutlined style={{marginTop: -16}}/>}
                                        onClick={() => handleCopy(data?.reservation_id)}
                                    />
                                }
                            />
                            <Statistic
                                title="Reservation To"
                                groupSeparator={''}
                                value={data?.reservation_to ? timezoneDateTimeString(data?.reservation_to, timezone) : 'Not available'}
                                prefix={<img src={calendarIcon} alt={calendarIcon}
                                             style={{marginBottom: -4, marginRight: 4}}/>}
                                valueStyle={{fontSize: 15, fontWeight: 500, marginBottom: 6}}
                                size="small"
                            />
                        </Col>
                    </Row>
                    {
                        (data?.footer) &&
                        <div style={{textAlign: "left", marginTop: 10, fontSize: 12, marginRight: 10}}>
                            * outdated box firmware may result in incomplete or faulty GPS data
                        </div>
                    }
                </div>
                }
            </Card>
        </>
    );
}


export default VehicleSearch;