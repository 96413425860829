import React, {useState, useEffect, useContext} from 'react';
import {Line, Area} from '@ant-design/plots';
import {SettingsContext} from "../../App.js";
import {convertKilometersToMiles, timezoneDateString, timezoneTimeString} from '../../utility/conversions.js';

const VelocityPlot = (props) => {
    const [data, setData] = useState([]);
    const {timezone, distance} = useContext(SettingsContext);

    useEffect(() => {
        if (props.data) {
            setData(props.data);
        }
    }, [props.data]);

    const config = {
        padding: 'auto',
        xField: 'ts',
        yField: 'speed',
        seriesField: 'markerColor',
        legend: false,
        isStack: false,
        // colorField: 'markerColor',// or seriesField in some cases
        // color: ({markerColor}) => {
        //     if (markerColor === 'red') {
        //         return 'red';
        //     }
        //     // return 'green';
        //     return '#0071bc';
        // },
        tooltip: {
            customContent: (title, items) => {
                const data = items[0]?.data || {};
                if (data) {
                    const date = new Date(data.ts);
                    return <div> {timezoneDateString(data.ts, timezone)} {timezoneTimeString(data.ts, timezone)}</div>
                } else {
                    return ''
                }
            }
        },
        yAxis: {
            // title: {text: 'Status'},
            label: {
                formatter: (value) => {
                    if (value === '1') {
                        return `Driving`;
                    } else if (value === '0') {
                        return `Stationary`;
                    } else {
                        return ''
                    }
                },
            }
        },
        xAxis: {
            // type: 'timeCat',
            tickCount: 15,
            label: {
                formatter: (timestamp) => {
                    if (timestamp) {
                        return timezoneTimeString(timestamp, timezone, {hour: '2-digit', minute: '2-digit'})
                    } else {
                        return ''
                    }
                },
            },
            // min: data.length > 1 ? data[1].ts : undefined,
        },
        // interactions: [
        //     {
        //         type: 'brush',
        //     },
        // ],
        // // type: 'rect',
        // // action: 'brush-x',
        // // mask: {
        // //     style: {
        // //         fill: 'rgba(255,0,0,0.3)', // Custom brush area color
        // //         stroke: '#ff0000', // Custom brush outline color
        // //         lineWidth: 1.5,
        // //     },
        // //     animation: {
        // //         appear: {
        // //             animation: 'fade-in',
        // //             easing: 'ease-in-out',
        // //             duration: 300,
        // //         },
        // //         update: {
        // //             animation: 'fade-in',
        // //             easing: 'ease-in-out',
        // //             duration: 300,
        // //         },
        // //     },
        // // },
        // // // Overwrite the default zoom action
        // events: {
        //     'plot:click': (event) => {
        //         const {selection} = event.data;
        //         const [domainX, domainY] = selection;
        //         const [minX, maxX] = domainX;
        //         const [minY, maxY] = domainY;
        //         console.log(minX, maxX, minY, maxY);
        //
        //         // Implement custom logic based on the brush selection
        //         // For example, you could filter the chart data based on the selected range
        //     }
        // }
    };

    return (
        <div style={{height: '100%', width: '100%'}}>
            {data.length > 0 &&
                <Area
                    style={{height: 100}}
                    key={props.render}
                    data={data}
                    colorField={'markerColor'} // or seriesField in some cases
                    color={({markerColor}) => {
                        if (markerColor === 'red') {
                            return 'red';
                        }
                        // return 'green';
                        return '#0071bc';
                    }}
                    {...config}/>
            }
        </div>
    )
};

export default VelocityPlot;