import React from "react";

const EventUrlRenderer = ({...props}) => {
    let urlBase = "https://webapp.carvaloo.com/ereignisse/"

    if (props.data.CarvalooStatus &&
        props.data.CarvalooStatus !== 'ToDo' &&
        props.data.CarvalooStatus !== 'Relevant' &&
        props.data.CarvalooStatus !== 'Irrelevant') {
      const urlEvent = urlBase.concat(props.data?.event_id)
      return (
          <a href={urlEvent} target="_blank">
              {props.data?.event_id}
          </a>
      )
    } else {
      return props.data?.event_id
    }
  }

export default EventUrlRenderer;