import React, {useState, useEffect, useRef, useContext} from 'react';
import {Col, notification, Table, Row, Card, Typography, Tooltip, Input, Button, DatePicker} from 'antd';
import axiosInstance from "services/axios";
import {AgGridReact} from "ag-grid-react";
import "./custom.css"
import "./../../common_components/AGGrid/carvaloo_theme.css";

import {InboxOutlined, InfoCircleOutlined, ColumnWidthOutlined} from "@ant-design/icons";
import filterIcon from '../../assets/icons/filter.svg';
import checkIcon from "../../assets/icons/check.svg";
import {SettingsContext} from "../../App.js";
import {convertKilometersToMiles, timezoneDateString, timezoneDateTimeString} from '../../utility/conversions.js';

const ReservationTable = (props) => {

    const {timezone, distance} = useContext(SettingsContext);
    const gridRef = useRef();
    const [renderKey, setRenderKey] = useState(0);

    let emptyRowData = [
        {box_id: 'empty'},
    ];

    function customDateFormatter(params) {
        return timezoneDateTimeString(params.value, params.context?.timezone)
    }

    const InfoTooltip = ({text}) => {
        return (
            <Tooltip title={text}>
                <InfoCircleOutlined style={{color: '#1890ff', fontSize: '12px', marginLeft: '5px'}}/>
            </Tooltip>
        );
    };

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        // Check if any rows are selected
        if (selectedRows.length > 0) {
            const selectedRowData = selectedRows[0]; // Assuming you want to work with the first selected row
            props.setSelectedDriver(selectedRowData.driver_id);
        } else {
            props.setSelectedDriver(null);
        }
    };

    // Define your column definition with the custom cell renderer
    const columnDefs = [
        {
            field: 'reservation_id',
            headerName: 'Reservation ID',
            filter: 'agTextColumnFilter',
            width: 140,
            maxWidth: 140,
            // floatingFilter: true,
        },
        {
            field: 'start',
            headerName: 'Begin',
            width: 110,
            sort: 'desc',
            valueFormatter: customDateFormatter
            // filter: 'agTextColumnFilter',
            // floatingFilter: true,
        },
        {
            field: 'end',
            headerName: 'Finish',
            width: 110,
            valueFormatter: customDateFormatter
            // filter: 'agNumberColumnFilter',
        },
        {
            field: 'ratio',
            headerName: 'Aggressive %',
            maxWidth: 120
            // filter: 'agNumberColumnFilter',
        },
    ]

    const [tableHeight, setTableHeight] = useState(678);

    useEffect(() => {
        if (gridRef.current.api) {
            gridRef.current.api.refreshCells({force: true});
        }
    }, [distance, timezone]);

    const [gridOptions, setGridOptions] = useState({
        rowData: emptyRowData,
        rowSelection: 'single',
        suppressRowClickSelection: false,
        enableRangeSelection: 'true',
        columnDefs: columnDefs,
        rowHeight: 41,
        // getContextMenuItems: getContextMenuItems,
        pagination: true,
        paginationPageSize: 12, // Number of rows per page
        // onPaginationChanged: onPaginationChanged,
        // components: {
        //     eventUrlRenderer: EventUrlRenderer
        // },
        defaultColDef: {
            width: 100,
            sortable: true,
            filter: false,
            resizable: true, //minWidth: 100,
            editable: false, //cellEditor: PopupCellEditor
            suppressMenu: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: {fontSize: '14px'}
        },
        onGridReady: (event) => event.api.sizeColumnsToFit(),
        // onSelectionChanged: onSelectionChanged,
        // getRowStyle: function (params) {
        //     if (params.data && params.data.action && params.data.action === 'firing') {
        //         return {
        //             background: 'rgba(250,80,42,0.2)', // Set your desired background color for pinned row
        //         };
        //     }
        //     if (params.data && params.data.action && params.data.action === 'pending') {
        //         return {
        //             background: 'rgba(250,205,42,0.2)', // Set your desired background color for pinned row
        //         };
        //     }
        //     return null;
        // },
    })

    useEffect(() => {

        let userTimezone = timezone
        if (timezone === 'Browser') {
            userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }

        const payload = {
            customer: props.customer,
            cat_value: props.categoryValue,
            driver_id: props.selectedDriver,
            date_start: props.dateRangeFilter[0],
            date_end: props.dateRangeFilter[1],
            timezone: userTimezone
        };

        const fetchData = async () => {
            try {
                const response = await axiosInstance.post('/api/prevention/reservations', payload);
                console.log(response)
                gridRef.current.api.setRowData(response.data.data);
            } catch (error) {
                console.log(error);
                notification['error']({message: 'Error retreiving alerts', description: error.message});
                gridRef.current?.api?.setRowData(emptyRowData);
            }
        };

        if (gridRef.current) {
            fetchData();
        }

    }, [props.selectedDriver, props.dateRangeFilter, props.categoryValue]);

    const onResizeColumns = () => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.sizeColumnsToFit()
        }
    }

    return (<>
            <Card
                style={{
                    height: tableHeight,
                    boxSizing: 'border-box',
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #e8e8e8',
                    marginLeft: 16,
                }}
                title={'Reservations'}
                extra={
                    <>
                        {/*<RangePicker*/}
                        {/*    style={{width: 300, marginRight: 32}}*/}
                        {/*    // presets={rangePresets}*/}
                        {/*    defaultValue={[dayjs().add(-30, 'd'), dayjs()]}*/}
                        {/*    allowClear={false}*/}
                        {/*    onChange={handleDateRangeChange}*/}
                        {/*/>*/}
                        <Button
                            icon={<ColumnWidthOutlined style={{color: "gray"}}/>}
                            style={{
                                height: 32,
                            }}
                            onClick={onResizeColumns}
                        />
                    </>
                }
                styles={{ body: {
                    padding: 0
                }}}>
                <div
                    style={{width: '100%', height: tableHeight - 85, minHeight: 280}}
                >
                    <AgGridReact
                        key={renderKey}
                        ref={gridRef}
                        gridOptions={gridOptions}
                        className="ag-theme-alpine"
                        context={{timezone}}
                    />
                </div>
            </Card>

        </>
    );
};

export default ReservationTable;