import {Breadcrumb, Typography, Col, Row, Image, Button} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import React, {useEffect} from "react";
import axiosInstance from "services/axios";
import handleDownload from "services/download";

const {Title, Paragraph, Text} = Typography;

const UserManagement = (props) => {

    useEffect(() => {
        const payload = {page_name: 'help_center_user_management'}
        axiosInstance.post('/api/visit', payload).then(res => {}).catch(error => {})
     }, []);

    return (<>
        <Title style={{margin: 0, marginBottom: 24}} level={1}> User Management </Title>
        <Row style={{gap: 0, maxWidth: 1200, marginBottom: 0}}>
            <Text style={{marginBottom: 16}}>
                If you would like to create new user accounts or want to edit or delete existing user accounts, please send an e-mail
                to <a href="mailto:operations-support@carvaloo.com">operations-support@carvaloo.com</a>. <br/>
                In order to create a new user we need the following details: first name, last name, e-mail, gender and position
                ('management/executive team', 'damage/claims team', 'technician').
                Furthermore, please specify which permissions and e-mail notifications the user shall receive and if the
                user shall receive access to all vehicles or only specific fleets or stations.
            </Text>
            <Row style={{width: '100%'}}>
                <Text>
                    Permissions:<br/>
                    <ul>
                        <li>Viewing or editing within the WebApp</li>
                        <li>Access to the InstallationApp</li>
                        <li>Access to the InspectionApp</li>
                    </ul>
                </Text>
            </Row>
            <Row>
            <Text>
                Notifications:<br/>
                <ul>
                    <li>Notification new damage event</li>
                    <li>Notification inactive vehicles</li>
                    <li>Notification for each vehicle activation</li>
                </ul>
            </Text>
            </Row>
        </Row>
        <Row> Please send us the attached file with your user details. </Row>
        <Button style={{marginTop: 4}} type="primary" onClick={() => void handleDownload('User_Permission_Table.xlsx')}>
            Download Excel Sheet
        </Button>
    </>)
};

export default UserManagement;
