import React from 'react'
import InfoCardGenericType from '../InfoCardGenericType/InfoCardGenericType'

const MarkerInfoCard = (props) => {

  const {cluster, selectedMarker } = props;
  const isSelected = selectedMarker?.id === cluster.properties.id;

  const style = {
    position: "absolute",
    width: "250px",
    backgroundColor: "#f7a29c",
    zIndex: 100,
    transform: "translate(-50px, -175px)",
    borderRadius: "5px",
    // transition: "all 0.5s",
    opacity:
      props.selectedMarker &&
      props.selectedMarker.id === props.cluster.properties.id
        ? 1
        : 0,
    maxHeight:
      props.selectedMarker &&
      props.selectedMarker.id === props.cluster.properties.id
        ? 300
        : 0,
    overflow: "hidden",
  }

  return (
    <>
      {isSelected && (
        <div className="shadow" style={style}>
          <InfoCardGenericType eventId={cluster.properties.id} />
        </div>
      )}
    </>
  )
}

export default MarkerInfoCard
