import React, {useState, useEffect, useRef, useContext} from 'react';
import {Col, notification, Table, Row, Card, Typography, Tooltip, Input, Button, DatePicker, Tag} from 'antd';
import axiosInstance from "services/axios";
import {AgGridReact} from "ag-grid-react";
import moment from "moment";
import dayjs, {Dayjs} from "dayjs";
import {
    customDateComparator,
    dateTimeFormatter,
    formatDateToLocal,
    formatArrayOfObjects,
    iconCellRenderer,
    customTimeRenderer,
    customDeltaComparator, detailsFormatter
} from "./TableHelpers";
import "./custom.css"
import "./../../common_components/AGGrid/carvaloo_theme.css";

import {InboxOutlined, InfoCircleOutlined, ColumnWidthOutlined} from "@ant-design/icons";
import {SettingsContext} from "../../App.js";
import {convertKilometersToMiles, timezoneDateString, timezoneTimeString} from '../../utility/conversions.js';

const {Search} = Input;
const {Paragraph, Text} = Typography;
const {RangePicker} = DatePicker;

const WarningTable = (props) => {

    const {timezone, distance} = useContext(SettingsContext);
    const [loading, setLoading] = useState(true);
    const gridRef = useRef();
    const [renderKey, setRenderKey] = useState(0);
    const [searchFilter, setSearchFilter] = useState(null);

    let emptyRowData = [];

    function customDateMaxAggregation(params) {
        return new Date(Math.max.apply(null, params.values.map(dateString => new Date(dateString))));
    }

    function customDateFormatter(params) {
        return timezoneDateString(params.value, params.context?.timezone)
    }

    function customWarningAggregation(params) {
        // Define a map to assign numerical values to warning levels
        const warningLevelMap = {
            warning_1: 1,
            warning_2: 2,
            warning_3: 3
        };

        // Find the maximum warning level among the values
        const maxWarningLevel = Math.max.apply(null, params.values.map(warningLevel => warningLevelMap[warningLevel]));

        // Reverse map the numerical value to get the corresponding warning level
        const maxWarningLevelKey = Object.keys(warningLevelMap).find(key => warningLevelMap[key] === maxWarningLevel);

        // Return the highest warning level
        return maxWarningLevelKey;
    }

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();

        // Check if any rows are selected
        if (selectedRows.length > 0) {
            const selectedRowData = selectedRows[0]; // Assuming you want to work with the first selected row
            props.handleSelectedChange(selectedRowData.event_id);
        } else {
            props.handleSelectedChange(null);
        }
    };

    const warningColorMap = {
        'warning_1': '#FABE0A',
        'warning_2': '#F6823C',
        'warning_3': '#E55555',
        // Add more warning values and colors as needed
    };

    const warningValueMap = {
        'warning_1': 'First Warning',
        'warning_2': 'Second Warning',
        'warning_3': 'Third+ Warning',
        // Add more warning values and colors as needed
    };

    useEffect(() => {
        if (gridRef.current.api) {
            gridRef.current.api.refreshCells({force: true});
        }
    }, [distance, timezone]);

    const onRowClicked = (event) => {
        if (event.node.group && !event.node.expanded) {
            event.node.setExpanded(true);
        }
    };

    // Define your column definition with the custom cell renderer
    const columnDefs = [
        {
            field: 'warning_count',
            // headerTooltip: "Detailed situation of security alert",
            headerName: 'Count',
            maxWidth: 80,
            aggFunc: 'count'
        },
        {
            field: 'event_label',
            // headerTooltip: "Detailed situation of security alert",
            headerName: 'Warning Level',
            maxWidth: 180,
            filter: 'agSetColumnFilter',
            cellRenderer: params => {

                if (params.value) {
                    const tagColor = warningColorMap[params.value] || '#E55555'; // Default color if not found in the map
                    return (
                        <Tag color={tagColor} style={{borderRadius: '4px'}}>
                            <Text style={{fontSize: 13, color: '#ffffff', textAlign: 'center'}}>
                                {warningValueMap[params.value] || 'Third+ Warning'}
                            </Text>
                        </Tag>
                    );
                } else {
                    return <></>
                }
            },
            aggFunc: customWarningAggregation
        },
        {
            field: 'event_category',
            headerName: 'Behavior',
            // floatingFilter: true,
            maxWidth: 120,
            filter: 'agSetColumnFilter',
        },
        {
            field: 'driver_id',
            rowGroup: true,
            hide: true,
            headerName: 'Driver ID',
            // floatingFilter: true,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'reservation_id',
            headerName: 'Reservation ID',
            // floatingFilter: true,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'event_id',
            headerName: 'Warning ID',
            // floatingFilter: true,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'observed',
            headerTooltip: "Date when the last warning was updated",
            headerName: 'Warning Date',
            sort: 'desc',
            comparator: customDateComparator,
            valueFormatter: customDateFormatter,
            aggFunc: customDateMaxAggregation
        },
    ]

    const [tableHeight, setTableHeight] = useState(688);

    const [gridOptions, setGridOptions] = useState({
        suppressAggFuncInHeader: true,
        autoGroupColumnDef: {
            headerName: 'Driver ID',
            minWidth: 100,
            cellRendererParams: {
                suppressCount: true,
                checkbox: false,
            }
        },
        rowData: emptyRowData,
        rowSelection: 'single',
        suppressRowClickSelection: false,
        enableRangeSelection: 'true',
        columnDefs: columnDefs,
        // getContextMenuItems: getContextMenuItems,
        pagination: true,
        paginationPageSize: 10, // Number of rows per page
        // onPaginationChanged: onPaginationChanged,
        defaultColDef: {
            width: 100,
            sortable: true,
            filter: false,
            resizable: true, //minWidth: 100,
            editable: false, //cellEditor: PopupCellEditor
            suppressMenu: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: {fontSize: '14px'}
        },
        onGridReady: (event) => event.api.sizeColumnsToFit(),
        onSelectionChanged: onSelectionChanged, // Add this line
        onRowClicked: onRowClicked // Add this line
    })

    const onSetIssueFilter = () => {

        if (gridRef.current && gridRef.current.api) {

            let currentFilterModel = gridRef.current.api.getFilterModel();
            currentFilterModel.event_label = {
                "filterType": "set",
                "values": [props.issueFilter]
            }

            if (props.issueFilter === null) {
                delete currentFilterModel.event_label;
            }

            gridRef.current.api.setFilterModel(currentFilterModel);
        }
    }

    useEffect(() => {
        onSetIssueFilter();
    }, [props.issueFilter]);

    useEffect(() => {
        let currentFilterModel = {}

        if (gridRef.current && gridRef.current.api) {
            currentFilterModel = gridRef.current.api.getFilterModel();
        }

        const payload = {
            customer: props.customer,
            date_range: props.dateRangeFilter,
            search: searchFilter,
        };

        const fetchData = async () => {
            try {
                const response = await axiosInstance.post('/api/prevention/warnings', payload);

                if (response.data.data.length === 0) {
                    gridRef.current.api.setRowData(emptyRowData)
                    notification['warning']({message: 'No warnings found in database'});
                } else if (response.data.data) {
                    setLoading(false)
                    const formattedArray = formatArrayOfObjects(response.data.data);
                    if (gridRef.current) {
                        gridRef.current.api.setRowData(formattedArray);
                        gridRef.current.api.setFilterModel(currentFilterModel);
                    }

                } else {
                    gridRef.current.api.setRowData(emptyRowData)
                    notification['warning']({message: 'No warnings found in database'});
                }

            } catch (error) {
                console.log(error);
                notification['error']({message: 'Error retreiving warnings', description: error.message});
                gridRef.current?.api?.setRowData(emptyRowData);
            }
        };

        console.log('updating warning table');
        if (gridRef.current) {
            setLoading(true)
            fetchData();
        }

    }, [props.update, searchFilter, props.dateRangeFilter]);

    const onFilterSearch = (search) => {
        if (gridRef.current && gridRef.current.api) {
            if (search === undefined || search === null) {
                setSearchFilter(null)
            } else {
                setSearchFilter(search)
            }
        }
    }

    const onFilterDate = (dateRange) => {
        if (gridRef.current && gridRef.current.api) {
            if (dateRange === null) {
                props.setDateRangeFilter([null, null]);
            } else if (dateRange[0] === null || dateRange[1] === null) {
                props.setDateRangeFilter([null, null])
            } else {
                const startDate = new Date(dateRange[0]);
                startDate.setHours(0, 0, 0, 0); // Set to beginning of the day

                const endDate = new Date(dateRange[1]);
                endDate.setHours(23, 59, 59, 999); // Set to end of the day

                // Set the date range filter
                props.setDateRangeFilter([startDate, endDate]);
            }
        }
    }

    const onResizeColumns = () => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.sizeColumnsToFit()
        }
    }


    const rangePresets = [
        {label: 'Last 24 hours', value: [dayjs().add(-1, 'd'), dayjs()]},
        {label: 'Last 7 days', value: [dayjs().add(-7, 'd'), dayjs()]},
        {label: 'Last 14 days', value: [dayjs().add(-14, 'd'), dayjs()]},
        {label: 'Last 30 days', value: [dayjs().add(-30, 'd'), dayjs()]},
        {label: 'Last 90 days', value: [dayjs().add(-90, 'd'), dayjs()]},
    ];

    return (<>
            <Card
                style={{
                    width: '100%',
                    height: tableHeight,
                    minHeight: 350,
                    boxSizing: 'border-box',
                    flex: '1',
                    // display: 'flex',
                    // flexDirection: 'column',
                    border: '1px solid #e8e8e8',
                }}
                // loading={loading}
                title={'Warnings'}
                extra={
                    <>
                        <Search
                            placeholder="Search Warnings or Driver ID"
                            onSearch={onFilterSearch}
                            allowClear={true}
                            size={'medium'}
                            style={{width: 280, marginRight: 32}}
                        />
                        <RangePicker
                            // picker="month"
                            // open={isOpen}
                            // onOpenChange={(open) => setIsOpen(open)}
                            // onSelect={props.handleSelect}
                            // defaultValue={props.dateRange}
                            // defaultPickerValue={props.dateRange}
                            defaultValue={props.dateRangeFilter}
                            presets={rangePresets}
                            allowClear={true}
                            onCalendarChange={onFilterDate}
                            style={{
                                width: 256,
                                height: 32,
                                marginRight: 32
                            }}
                        />
                        <Button
                            icon={<ColumnWidthOutlined style={{color: "gray"}}/>}
                            style={{
                                height: 32,
                            }}
                            onClick={onResizeColumns}
                        />
                    </>
                }
                styles={{ body: {
                    padding: 0
                }}}>
                <div
                    style={{width: '100%', height: tableHeight - 85, minHeight: 280}}
                >
                    <AgGridReact
                        key={renderKey}
                        ref={gridRef}
                        gridOptions={gridOptions}
                        className="ag-theme-alpine"
                        context={{timezone}}
                    />
                </div>
            </Card>

        </>
    );
};

export default WarningTable;