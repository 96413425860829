import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button,
    Card,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Popover,
    Switch,
    Tag,
    Divider,
    Space,
    DatePicker, Tabs, Tour, Tooltip
} from 'antd';
import dayjs, {Dayjs} from "dayjs";

import axiosInstance from "services/axios";
import MapCard from "../components/FleetTracking/MapCard";
import VehicleSearch from "../components/FleetTracking/VehicleSearch";
import SearchBar from "../components/FleetTracking/SearchBar";
import VehicleInfo from "../components/FleetTracking/VehicleInfo";
import Dashboard from "../components/FleetTracking/Dashboard";
import TrackingTable from "../components/FleetTracking/TrackingTable";
import StationaryTrackingTable from "../components/FleetTracking/StationaryTrackingTable";
import TripTable from "../components/FleetTracking/TripTable";
import StandTable from "../components/FleetTracking/StandTable";

import {useNavigate} from "react-router-dom";
import HeaderRow from "../components/HeaderRow/HeaderRow";
import {ErrorBoundary} from "react-error-boundary";
import CVScreenHeader from "../common_components/Webapp/CVScreenHeader";
import {InfoCircleOutlined, NumberOutlined} from "@ant-design/icons";

const {Title, Paragraph, Text, Link} = Typography;
const {Search} = Input;
const {RangePicker} = DatePicker;

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const FleetTrackingScreen = (props) => {

    const [open, setOpen] = useState(false);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const [showNewFeatureTour, setShowNewFeatureTour] = useState(false);

    const refSearch = useRef(null);
    const refMapMode = useRef(null);
    const refLiveUpdate = useRef(null);
    const refSettings = useRef(null);
    const refPOIs = useRef(null);
    const [showIntroTour, setShowIntroTour] = useState(true);

    const navigate = useNavigate();
    const [updateCounter, setUpdateCounter] = useState(0);
    const [fastUpdateCounter, setFastUpdateCounter] = useState(0);
    const [inputSearch, setInputSearch] = useState(null);
    const [initialSearch, setInitialSearch] = useState(null);
    const [limitedWarning, setLimitedWarning] = useState(false);

    const [activeReservation, setActiveReservation] = useState(false);
    const [activeTable, setActiveTable] = useState(false);
    const [activeDashboard, setActiveDashboard] = useState(false);
    const [headerData, setHeaderData] = useState(null);

    const [tripsTableData, setTripsTableDate] = useState(null);
    const [stationaryTableData, setStationaryTableDate] = useState(null);

    const [vehicleLocation, setVehicleLocation] = useState(false);
    const [vehicleTracking, setVehicleTracking] = useState(false);
    const [vehicleStationary, setVehicleStationary] = useState(false);
    const [searchSettings, setSearchSettings] = useState({
        min_stationary: 1, include_station: true, marker_size: 100, cluster_stationary: false
    });
    const [animationDuration, setAnimationDuration] = useState(15);
    const [statistics, setStatistics] = useState({});
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [vehicleUpdate, setVehicleUpdate] = useState(false);
    const [dateRange, setDateRange] = useState([dayjs().add(-1, 'd'), dayjs()]);
    const [lastGPS, setLastGPS] = useState(null);

    function sendMail() {
        let emailSubject = "Support"
        emailSubject += " Vehicle Tracking"
        emailSubject += ` - ${props.customer}`

        if (inputSearch) {
            emailSubject += ` - ${inputSearch}`
        }

        const body_ = ""
        const link =
            'mailto:' +
            "damage-support@carvaloo.com" +
            //+ "?cc="
            '?subject=' +
            encodeURIComponent(emailSubject) +
            '&body=' +
            encodeURIComponent(body_)
        window.location.href = link
    }

    const onFinishNewFeatureTour = () => {
        setShowNewFeatureTour(false)
        props.setUserSettings((prevState) => ({
            ...prevState,
            new_feature_tracking_tour: false,
        }));

        const request = {
            customer: props.customer,
            setting_value: false,
            setting_name: 'new_feature_tracking_tour'
        }

        axiosInstance.post('/api/setup/settings', request)
            .then(res => {
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error finishing tour', description: error.message});
            })

    };

    const newFeatureSteps = [
        {
            title: 'Highlight points',
            description: 'Click any row in the trips table to highlight the according points between trip start and trip end in the timeline and in the map.',
            target: () => ref1.current,
            placement: 'top'
        },
        {
            title: 'Animate points',
            description: 'Click here to play the animation for the vehicle tracking. You can pause the animation or reset it at any point. Changing the animation speed is possible in the settings.',
            target: () => ref3.current,
            nextButtonProps: {
                children: (
                    "Do not show again"
                ),
                onClick: onFinishNewFeatureTour
                // style: {
                //     // backgroundColor: "red",
                //     // borderRadius: "50%",
                //     // width: 32,
                //     // minWidth: 32,
                //     // height: 32,
                // }
            }
        }
    ];

    const onFinishIntroTour = () => {
        setShowIntroTour(false)

        props.setUserSettings((prevState) => ({
            ...prevState,
            intro_tracking_tour: false,
        }));

        const request = {
            customer: props.customer,
            setting_value: false,
            setting_name: 'intro_tracking_tour'
        }

        axiosInstance.post('/api/setup/settings', request)
            .then(res => {
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error finishing tour', description: error.message});
            })

    };

    const introSteps = [
        {
            title: 'Search',
            description: 'This is a global search where you can enter any Vehicle ID, VIN, Box ID or a Reservation ID.',
            target: () => refSearch.current,
            placement: 'top'
        },
        {
            title: 'Map Mode',
            description: 'The position map mode is used to locate the vehicle. Use the tracking mode to observe where the vehicle has been driving. The stationary mode shows where the vehicle has been standing.',
            target: () => refMapMode.current,
            placement: 'top'
        },
        {
            title: 'Live Update',
            description: 'Click here to update the vehicle location every 5 seconds while in the position map mode. Please take into consideration that if the vehicle is not sending data this location will not update.',
            target: () => refLiveUpdate.current,
            placement: 'top'
        },
        {
            title: 'Settings',
            description: 'In the settings multiple options are available concerning how the stationary points are displayed in the map.',
            target: () => refSettings.current,
            placement: 'top'
        },
        // {
        //     title: 'Points of Interest',
        //     description: 'This button toggles the Points Of Interest (POIs) in the map. The street view button is also available to the left together with the other map control buttons.',
        //     target: () => refPOIs.current,
        //     placement: 'top'
        // },
        {
            title: 'Points of Interest',
            description: 'This button toggles the Points Of Interest (POIs) in the map. The street view button is also available to the left together with the other map control buttons.',
            target: () => refPOIs.current,
            nextButtonProps: {
                children: (
                    "Do not show again"
                ),
                onClick: onFinishIntroTour
                // style: {
                //     // backgroundColor: "red",
                //     // borderRadius: "50%",
                //     // width: 32,
                //     // minWidth: 32,
                //     // height: 32,
                // }
            }
        }
    ];

    useEffect(() => {
        let url = new URL(window.location.href);

        const search = url.searchParams.get('search');
        if (search) {
            setInputSearch(search)
            setInitialSearch(search)
        }

        const tracking = url.searchParams.get('tracking');
        const stationary = url.searchParams.get('stationary');
        if (tracking === 'true') {
            setVehicleTracking(true)
        } else if (stationary === 'true') {
            setVehicleStationary(true)
        } else if (search) {
            setVehicleLocation(true)
        }

        const from = url.searchParams.get('from');
        const to = url.searchParams.get('to');
        if (from && to) {
            setDateRange([dayjs(from), dayjs(to)])
        }

        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set('organization', props.customerId);
        const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;
        window.history.replaceState({}, document.title, newUrl);

    }, []);

    useEffect(() => {
        if (props.userSettings.new_feature_tracking_tour) {
            setShowNewFeatureTour(true)
        } else {
            setShowNewFeatureTour(false)
        }

    }, []);

    useEffect(() => {
        if (props.userSettings.intro_tracking_tour) {
            setShowIntroTour(true)
        } else {
            setShowIntroTour(false)
        }

    }, []);

    const isTabVisible = () => {
        return !document.hidden;
    };

    useEffect(() => {
        const request = {
            customer: props.customer,
        }
        axiosInstance.post('/api/location/setup', request)
            .then(res => {
                setActiveReservation(res.data.reservation)
                setActiveTable(res.data.table)
                setActiveDashboard(res.data.dashboard)
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error in setup', description: error.message});
            })
    }, [props.customer]);

    useEffect(() => {
        const request = {
            customer: props.customer,
        }
        axiosInstance.post('/api/location/headers', request)
            .then(res => {
                setHeaderData([
                    {
                        name: 'Active Vehicles (last hour)',
                        value: res.data.active_last_hour,
                        icon: (<NumberOutlined/>),
                    },
                    {
                        name: 'Active Vehicles (last day)',
                        value: res.data.active_last_day,
                        icon: (<NumberOutlined/>),
                    },
                    {
                        name: 'Active Vehicles (last week)',
                        value: res.data.active_last_week,
                        icon: (<NumberOutlined/>),
                    },
                ])

            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error in header data', description: error.message});
            })
    }, [props.customer]);

    useInterval(() => {
        setUpdateCounter(updateCounter + 1);
    }, isTabVisible() ? 60 * 1000 : null);

    useInterval(() => {
        setFastUpdateCounter(fastUpdateCounter + 1);
    }, (isTabVisible() && vehicleUpdate) ? 5 * 1000 : null);

    useEffect(() => {
        if (limitedWarning) {
            setDateRange([dayjs().subtract(7, 'day'), dateRange[1]])
        }
    }, [limitedWarning]);

    useEffect(() => {
        const payload = {
            customer: props.customer,
            search: inputSearch,
            range: dateRange,
        };

        const fetchTrips = async (payload) => {
            try {
                const response = await axiosInstance.post('/api/location/trips', payload);
                setTripsTableDate(response.data.trips)
                setStationaryTableDate(response.data.stationary)
                setStatistics(response.data.statistics)
            } catch (error) {
                console.log(error);
                notification['error']({message: 'Error retrieving data', description: error.message});
            }
        };

        if (inputSearch && (vehicleTracking || vehicleStationary)) {
            fetchTrips(payload);
        }
    }, [inputSearch, dateRange, vehicleTracking, vehicleStationary]);

    const onSearch = (newValue) => {
        if (newValue) {
            const valueStr = newValue.trim()
            setInputSearch(newValue)
            setVehicleLocation(true)
            let url = new URL(window.location.href);
            url.searchParams.set('organization', props.customerId);
            url.searchParams.set('search', valueStr);
            window.history.pushState({}, '', url.href);
        } else {
            setInputSearch(null)
            setVehicleLocation(false)
            let url = new URL(window.location.href);
            url.searchParams.delete('search');
            window.history.pushState({}, '', url.href);
        }
        setLastGPS(null)
        setVehicleStationary(false)
        setVehicleTracking(false)
        setVehicleUpdate(false)
        setStatistics({})
    };

    const handleUpdateChange = (value) => {
        setVehicleUpdate(value)
        if (value) {
            notification['info']({message: 'Vehicle location will be updated every 5 seconds'});
        }
    }

    const handleTrackingChange = (value) => {
        setVehicleTracking(value)
        if (value) {
            if (showNewFeatureTour) {
                setOpen(true)
            }
            setVehicleLocation(false)
            setVehicleStationary(false)
            let url = new URL(window.location.href);
            url.searchParams.set('stationary', 'false');
            url.searchParams.set('tracking', value);
            const startDateISO = dateRange[0].utc().toISOString();
            const endDateISO = dateRange[1].utc().toISOString();

            url.searchParams.set('from', startDateISO);
            url.searchParams.set('to', endDateISO);
            window.history.pushState({}, '', url.href);
        } else {
            setVehicleLocation(true)
            let url = new URL(window.location.href);
            url.searchParams.set('tracking', 'false');
            url.searchParams.delete('from');
            url.searchParams.delete('to');
            window.history.pushState({}, '', url.href);
        }
    }

    const handleStationaryChange = (value) => {
        setVehicleStationary(value)
        if (value) {
            setVehicleLocation(false)
            setVehicleTracking(false)
            let url = new URL(window.location.href);
            url.searchParams.set('tracking', 'false');
            url.searchParams.set('stationary', value);

            const startDateISO = dateRange[0].utc().toISOString();
            const endDateISO = dateRange[1].utc().toISOString();
            url.searchParams.set('from', startDateISO);
            url.searchParams.set('to', endDateISO);
            window.history.pushState({}, '', url.href);
        } else {
            setVehicleLocation(true)
            let url = new URL(window.location.href);
            url.searchParams.set('stationary', 'false');
            url.searchParams.delete('from');
            url.searchParams.delete('to');
            window.history.pushState({}, '', url.href);
        }
    }

    const handleDateRangeChange = (data) => {
        setDateRange(data)
        setLimitedWarning(false)

        let url = new URL(window.location.href);
        const startDateISO = data[0].utc().toISOString();
        const endDateISO = data[1].utc().toISOString();
        url.searchParams.set('from', startDateISO);
        url.searchParams.set('to', endDateISO);
        window.history.pushState({}, '', url.href);
    }

    const tabItems = [
        {
            key: '1',
            label: 'Location',
            // children: 'Content of Alerts Tab',
        }
        // {
        //   key: '2',
        //   label: 'Tab 2',
        //   children: 'Content of Tab Pane 2',
        // },
        // {
        //   key: '3',
        //   label: 'Tab 3',
        //   children: 'Content of Tab Pane 3',
        // },
    ];


    return (<>
            <div style={{minWidth: 1080}}>
                {showNewFeatureTour &&
                    <Tour
                        open={open}
                        onClose={() => setOpen(false)}
                        steps={newFeatureSteps}
                        indicatorsRender={(current, total) => (<span>{current + 1} / {total}</span>)}
                    />
                }
                {showIntroTour &&
                    <Tour
                        open={showIntroTour}
                        onClose={() => setShowIntroTour(false)}
                        steps={introSteps}
                        indicatorsRender={(current, total) => (<span>{current + 1} / {total}</span>)}
                    />
                }
                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                    <CVScreenHeader
                        title={'Vehicle Tracking'}
                        headerData={headerData}
                        tabData={tabItems}
                        sendEmail={() => sendMail()}
                        trial={props.trial}
                        limitedAccess={props.limitedAccess}
                    />
                </ErrorBoundary>
                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                    <SearchBar
                        initialSearch={initialSearch}
                        customer={props.customer}
                        permissions={props.permissions}
                        onSearch={onSearch}
                        handleTrackingChange={handleTrackingChange}
                        handleStationaryChange={handleStationaryChange}
                        handleDateRangeChange={handleDateRangeChange}
                        setDateRange={setDateRange}
                        handleUpdateChange={handleUpdateChange}
                        vehicleUpdate={vehicleUpdate}
                        inputSearch={inputSearch}
                        vehicleTracking={vehicleTracking}
                        vehicleStationary={vehicleStationary}
                        dateRange={dateRange}
                        setSearchSettings={setSearchSettings}
                        searchSettings={searchSettings}
                        update={updateCounter}
                        activeReservation={activeReservation}
                        vehicleLocation={vehicleLocation}
                        setAnimationDuration={setAnimationDuration}
                        animationDuration={animationDuration}
                        refSearch={refSearch}
                        refMapMode={refMapMode}
                        refLiveUpdate={refLiveUpdate}
                        refSettings={refSettings}
                    />
                </ErrorBoundary>
                <div style={{
                    position: "relative",
                    // height: 600,
                    overflow: "hidden",
                    backgroundColor: '#eef0f2'
                }}>
                    <ErrorBoundary fallback={<div>Something went wrong</div>}>
                        <MapCard
                            customer={props.customer}
                            update={fastUpdateCounter}
                            inputSearch={inputSearch}
                            vehicleLocation={vehicleLocation}
                            vehicleTracking={vehicleTracking}
                            vehicleStationary={vehicleStationary}
                            dateRange={dateRange}
                            limitedWarning={limitedWarning}
                            setLimitedWarning={setLimitedWarning}
                            setLastGPS={setLastGPS}
                            searchSettings={searchSettings}
                            setTableData={setTableData}
                            activeDashboard={activeDashboard}
                            selectedRow={selectedRow}
                            animationDuration={animationDuration}
                            refTour1={ref2}
                            refTour2={ref3}
                            refPOIs={refPOIs}
                        />
                    </ErrorBoundary>
                    <ErrorBoundary fallback={<div>Something went wrong</div>}>
                        {inputSearch &&
                            <VehicleInfo
                                initialSearch={initialSearch}
                                customer={props.customer}
                                permissions={props.permissions}
                                onSearch={onSearch}
                                vehicleUpdate={vehicleUpdate}
                                inputSearch={inputSearch}
                                vehicleTracking={vehicleTracking}
                                activeReservation={activeReservation}
                                vehicleLocation={vehicleLocation}
                                handleDateRangeChange={handleDateRangeChange}
                                handleTrackingChange={handleTrackingChange}
                                handleStationaryChange={handleStationaryChange}
                            />
                        }
                    </ErrorBoundary>
                    {(vehicleTracking || vehicleStationary) &&
                        <div style={{padding: 16}}>
                            <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                <Dashboard
                                    data={statistics}
                                    selectedRow={selectedRow}
                                />
                            </ErrorBoundary>
                            <Row style={{marginTop: 16}}>
                                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                    <TripTable
                                        data={tripsTableData}
                                        customer={props.customer}
                                        inputSearch={inputSearch}
                                        dateRange={dateRange}
                                        vehicleTracking={vehicleTracking}
                                        vehicleStationary={vehicleStationary}
                                        setSelectedRow={setSelectedRow}
                                        selectedRow={selectedRow}
                                        refTour={ref1}
                                    />
                                </ErrorBoundary>
                                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                    <StandTable
                                        data={stationaryTableData}
                                        customer={props.customer}
                                        inputSearch={inputSearch}
                                        dateRange={dateRange}
                                        vehicleTracking={vehicleTracking}
                                        vehicleStationary={vehicleStationary}
                                        setSelectedRow={setSelectedRow}
                                        selectedRow={selectedRow}
                                    />
                                </ErrorBoundary>
                            </Row>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default FleetTrackingScreen;
