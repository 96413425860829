// BtnCellRenderer.jsx
import {Popover, Button, Collapse} from 'antd';

import React from "react";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { Typography } from 'antd';
const { Title, Text } = Typography;

const DefaultIcon = L.icon({
  iconUrl: icon,
  iconAnchor: [12, 41],
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const TextCellRenderer = ({
                            title = '',
                            ...props
                          }) => {

  return (
    <>
      {props.data.DetailsText && (
        <Popover
          title={title}
          trigger="click"
          content={
            <div>
              <Text>{props.data.DetailsText}</Text>
            </div>
          }
        >
          <Button>Click</Button>
        </Popover>
      )}
    </>
  );
}


export default TextCellRenderer;