import React, {useState, useEffect, useRef, useCallback, useMemo, useContext} from 'react';
import {Col, notification, Table, Row, Card, Typography, Tooltip, Button} from 'antd';
import axiosInstance from "services/axios";
import {AgGridReact} from "ag-grid-react";
import moment from "moment";
import dayjs, {Dayjs} from "dayjs";
import {ColumnWidthOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {SettingsContext} from "../../App.js";
import {
    convertKilometersToMiles,
    timezoneDateString,
    timezoneTimeString,
    timezoneDateTimeString
} from '../../utility/conversions.js';
import "./../../common_components/AGGrid/carvaloo_theme.css";

const {Paragraph, Text} = Typography;

const StandTable = (props) => {

    const {timezone, distance} = useContext(SettingsContext);
    const gridRef = useRef();
    const cardRef = useRef();


    function customDateTimeFormatter(params) {
        return timezoneDateTimeString(params.value, params.context?.timezone)
    }

    function customDateFormatter(params) {
        return timezoneDateString(params.value, params.context?.timezone)
    }

    function customTimeFormatter(params) {
        return timezoneTimeString(params.value, params.context?.timezone)
    }

    const distanceFormatter = (params) => {
        if (params.value) {
            return convertKilometersToMiles(params.value, params.context?.distance);
        } else {
            return null;
        }
    }

    const getCustomContextMenuItems = (params) => {

        const contextMenu = [
            "cut",
            "copy",
            "copyWithHeaders",
            "copyWithGroupHeaders",
            "paste",
            "seperator",
            {
                name: "CSV Export (.csv)",
                action: () => params.api.exportDataAsCsv({
                    allColumns: true,
                    processCellCallback: processCellForClipboard
                })
            },
            {
                name: "Excel Export (.xlsx)",
                action: () => params.api.exportDataAsExcel({
                    allColumns: true,
                    processCellCallback: processCellForClipboard
                })
            }
        ]

        return contextMenu
    }

    const processCellForClipboard = (params) => {
            let colDef = params.column.getColDef();
            if (colDef.valueFormatter) {
                return colDef.valueFormatter({
                    ...params,
                    data: params.node?.data,
                    colDef: colDef
                });
            }

            return params.value;
        }

    // function renderLocation(params) {
    //     if (params.data && params.data.location) {
    //         const location = params.data.location;
    //         const lat = location.lat.toFixed(6);
    //         const lon = location.lon.toFixed(6);
    //         return `Lat: ${lat}, Lon: ${lon}`;
    //     }
    //     return '';
    // }

    useEffect(() => {
        if (gridRef.current.api) {
            gridRef.current.api.refreshCells({force: true});
        }
    }, [distance, timezone]);

    // Define your column definition with the custom cell renderer
    const columnDefs = [
        {
            field: 'stationary_start',
            headerName: 'Start Date',
            width: 120,
            valueFormatter: customDateFormatter
        },
        {
            field: 'stationary_start',
            headerName: 'Start Time',
            width: 120,
            valueFormatter: customTimeFormatter,
        },
        {
            field: 'stationary_end',
            headerName: 'End Date',
            width: 120,
            hide: true,
            valueFormatter: customDateFormatter
        },
        {
            field: 'stationary_end',
            headerName: 'End Time',
            width: 120,
            valueFormatter: customTimeFormatter,
        },
        {
            field: 'stationary_total',
            headerName: 'Duration',
            filter: "agNumberColumnFilter",
            useValueFormatterForExport: false,
            width: 120,
            valueFormatter: (params) => {
                const totalMinutes = params.value;
                const days = Math.floor(totalMinutes / (60 * 24));
                const remainingHours = totalMinutes % (60 * 24);
                const hours = Math.floor(remainingHours / 60);
                const minutes = Math.round(remainingHours % 60);

                let durationStr = '';
                if (days > 0) {
                    durationStr += `${days}d `;
                }
                if (hours > 0 || days > 0) {
                    durationStr += `${hours}h `;
                }
                durationStr += `${minutes}m`;

                return durationStr;
            },
        },
        {
            field: 'Adresse_1',
            headerName: 'Address',
            width: 200,
        },
        // {
        //     field: 'Adresse_2',
        //     headerName: 'Region',
        //     width: 200,
        // },
        {
            field: 'max_distance',
            headerName: 'Station Distance',
            width: 120,
            filter: "agNumberColumnFilter",
            valueFormatter: distanceFormatter,
        },
        {
            field: 'Latitude',
            headerName: 'GPS Latitude',
            width: 130,
            hide: true,
        },
        {
            field: 'Longitude',
            headerName: 'GPS Longitude',
            width: 130,
            hide: true
        },
    ]

    const [gridOptions, setGridOptions] = useState({
        rowSelection: 'single',
        suppressRowClickSelection: false,
        enableRangeSelection: 'true',
        columnDefs: columnDefs,
        getContextMenuItems: getCustomContextMenuItems,
        processCellForClipboard: processCellForClipboard,
        // pagination: true,
        // paginationAutoPageSize: true,
        // paginationPageSize: 5, // Number of rows per page
        // onPaginationChanged: onPaginationChanged,
        context: {},
        onGridReady: function () {
            gridRef.current.api.sizeColumnsToFit()
            if (props.data) {
                gridRef.current.api.setRowData(props.data);
            }
        },
        onRowSelected: (event) => {
            if (event.node.isSelected()) {
                if (event.data) {
                    props.setSelectedRow([event.data.stationary_start, event.data.stationary_end])
                }
            }
        },
        defaultColDef: {
            width: 100,
            sortable: true,
            filter: false,
            resizable: true, //minWidth: 100,
            editable: false, //cellEditor: PopupCellEditor
            menuTabs: ["filterMenuTab"],
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: {fontSize: '14px'},
            useValueFormatterForExport: true
        },
    })

    useEffect(() => {
        if (gridRef.current && props.data) {
            if (gridRef.current.api) {
                gridRef.current.api.setRowData(props.data);
            }
        }
    }, [props.data, props.vehicleStationary, props.vehicleTracking]);

    const InfoTooltip = ({text}) => {
        return (
            <Tooltip title={text}>
                <InfoCircleOutlined style={{color: '#1890ff', fontSize: '16px', marginLeft: '5px'}}/>
            </Tooltip>
        );
    };

    const onResizeColumns = () => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.sizeColumnsToFit()
        }
    }

    return (<>
            <Card
                style={{
                    width: 'calc(50% - 8px)',
                    height: '100%',
                    minHeight: 380,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #e8e8e8',
                }}
                extra={
                    <>
                        <Button
                            icon={<ColumnWidthOutlined style={{color: "gray"}}/>}
                            style={{
                                height: 32,
                            }}
                            onClick={onResizeColumns}
                        />
                    </>
                }
                styles={{ body: {padding: 0}}}
                title={<>
                    Stationary
                    <InfoTooltip text="Show the stationary details for the vehicle within the selected daterange."/>
                </>}
            >
                <div style={{width: `100%`, height: 350}} className="ag-theme-alpine">
                    <AgGridReact
                        ref={gridRef}
                        context={{distance, timezone}}
                        gridOptions={gridOptions}
                    />
                </div>
            </Card>
        </>
    );
};

export default StandTable;