import React, {useEffect, useState, useContext} from "react";
import {
    Typography,
    Input, Col, Tabs, Tooltip, Row, InputNumber, Checkbox
} from 'antd';
import Icon, {HomeOutlined, InfoCircleOutlined} from "@ant-design/icons";


const SettingsMenu = (props) => {

    const onChangeMinimum = (value) => {
        if (value) {
            props.setSearchSettings({
                ...props.searchSettings, // Spread the existing state
                min_stationary: value // Update the specific property
            });
        }
    }

    const onChangeMarkerSize = (value) => {
        if (value) {
            props.setSearchSettings({
                ...props.searchSettings, // Spread the existing state
                marker_size: value // Update the specific property
            });
        }
    }

    const onChangeStationInclude = (value) => {
        props.setSearchSettings({
            ...props.searchSettings, // Spread the existing state
            include_station: value.target.checked // Update the specific property
        });
    }

    const onChangeStationaryCluster = (value) => {
        props.setSearchSettings({
            ...props.searchSettings, // Spread the existing state
            cluster_stationary: value.target.checked // Update the specific property
        });
    }

    return (
        <div style={{width: 280, height: 120}}>
            <Row>
                <Col>
                    <span>Minimum stationary time (minutes): </span>
                    <InputNumber
                        min={1}
                        max={60}
                        defaultValue={props.searchSettings.min_stationary}
                        style={{width: '50px'}}
                        onChange={onChangeMinimum}
                        size={'small'}
                    />
                </Col>
            </Row>
            <Row>
                <span style={{marginRight: 13}}>Include stationary close to station:</span>
                <Checkbox
                    onChange={onChangeStationInclude}
                    defaultChecked={props.searchSettings.include_station}
                    style={{verticalAlign: 'middle'}}
                >
                </Checkbox>
            </Row>
            <Row>
                <Col>
                    <span>Stationary Marker Scaling (%): </span>
                    <InputNumber
                        min={50}
                        max={300}
                        defaultValue={props.searchSettings.marker_size}
                        style={{width: '80px'}}
                        onChange={onChangeMarkerSize}
                        size={'small'}
                    />
                </Col>
            </Row>
            <Row>
                <span style={{marginRight: 10}}>GeoCluster stationary points:</span>
                <Checkbox
                    onChange={onChangeStationaryCluster}
                    defaultChecked={props.searchSettings.cluster_stationary}
                    style={{verticalAlign: 'middle'}}
                >
                </Checkbox>
            </Row>
            <Row>
                <Col>
                    <span>Animation Duration (seconds): </span>
                    <InputNumber
                        min={5}
                        max={120}
                        defaultValue={props.animationDuration}
                        style={{width: '80px'}}
                        onChange={props.setAnimationDuration}
                        size={'small'}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default SettingsMenu;
