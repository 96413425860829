import React, {useEffect, useState, useContext} from "react";
import {
    Typography,
    Input, Col, Tabs, Tooltip, Row, DatePicker, Radio, Button, Space, Popover
} from 'antd';
import Icon, {HomeOutlined, InfoCircleOutlined, SettingOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import InfoTooltip from "../InfoTooltip/InfoTooltip.js"
import SettingsMenu from "./SettingsMenu.js"
import {SettingsContext} from "../../App";
import {
    convertKilometersToMiles,
    timezoneDateString,
    timezoneTimeString,
    timezoneDateTimeString
} from '../../utility/conversions.js';

const {Title, Paragraph, Text, Link} = Typography;
const {Search} = Input;
const {RangePicker} = DatePicker;

const SearchBar = (props) => {
    const {timezone, distance} = useContext(SettingsContext);
    const userLocale = Intl.DateTimeFormat().resolvedOptions().locale;

    useEffect(() => {

        if (timezone === 'UTC') {
            const startDate = dayjs(props.dateRange[0]).utc();
            const endDate = dayjs(props.dateRange[1]).utc();
            props.setDateRange([startDate, endDate]);
        } else if (timezone === 'Browser') {
            const startDate = dayjs(props.dateRange[0]).local();
            const endDate = dayjs(props.dateRange[1]).local();
            props.setDateRange([startDate, endDate]);
        } else {
            const startDate = dayjs(props.dateRange[0]).tz(timezone);
            const endDate = dayjs(props.dateRange[1]).tz(timezone);
            props.setDateRange([startDate, endDate]);
        }
    }, [timezone]);

    // Set the locale for dayjs based on the browser's locale
    dayjs.locale(userLocale);

    // Define a function to determine the time format based on the locale
    const getTimeFormatFromLocale = (locale) => {
        // Map locale to appropriate time format
        if (locale.startsWith('en')) {
            // English locale example: Use 12-hour format for English
            return 'hh:mm A';
        } else {
            // For other locales, default to a 24-hour format
            return 'HH:mm';
        }
    };

    // Define a function to determine the time format based on the locale
    const getDateFormatFromLocale = (locale) => {
        // Map locale to appropriate time format
        if (locale.startsWith('en')) {
            // English locale example: Use 12-hour format for English
            return 'DD-MM-YYYY';
        } else {
            // For other locales, default to a 24-hour format
            return 'MM-DD-YYYY';
        }
    };

    const use12HourTimeFormat = (locale) => {
        // Map locale to appropriate time format
        if (locale.startsWith('en')) {
            // English locale example: Use 12-hour format for English
            return true;
        } else {
            // For other locales, default to a 24-hour format
            return false;
        }
    };

    const userTimeFormat = getTimeFormatFromLocale(userLocale);

    const userDateTimeFormat = () => {
        const timeFormat = getTimeFormatFromLocale(userLocale)
        const dateFormat = getDateFormatFromLocale(userLocale)
        return dateFormat + ' ' + timeFormat;
    }

    const rangePresets = [
        {label: 'Last 1 hour', value: [dayjs().add(-1, 'hours'), dayjs()]},
        {label: 'Last 3 hours', value: [dayjs().add(-3, 'hours'), dayjs()]},
        {label: 'Last 6 hours', value: [dayjs().add(-6, 'hours'), dayjs()]},
        {label: 'Last 12 hours', value: [dayjs().add(-12, 'hours'), dayjs()]},
        {label: 'Last 24 hours', value: [dayjs().add(-1, 'd'), dayjs()]},
        {label: 'Last 48 hours', value: [dayjs().add(-2, 'd'), dayjs()]},
        {label: 'Last 72 hours', value: [dayjs().add(-3, 'd'), dayjs()]},
        {label: 'Last week', value: [dayjs().add(-7, 'd'), dayjs()]},
    ];

    const handleMapMode = (value) => {

        if (value.target.value === 'position') {
            if (props.vehicleTracking) {
                props.handleTrackingChange(false)
            }
            if (props.vehicleStationary) {
                props.handleStationaryChange(false)
            }
        }
        if (value.target.value === 'tracking') {
            props.handleTrackingChange(true)
        }
        if (value.target.value === 'stationary') {
            props.handleStationaryChange(true)
        }
    };

    const handleUpdate = (value) => {
        if (value.target.value === 'off') {
            props.handleUpdateChange(false)
        }
        if (value.target.value === 'on') {
            props.handleUpdateChange(true)
        }
    };

    return (
        <Row style={{height: 118, padding: 24, backgroundColor: 'white', gap: 24}}>
            <Col>
                <Row style={{margin: 0}}>
                    <Text style={{fontWeight: 500}}> Search </Text>
                </Row>
                <Row style={{marginTop: 8}} ref={props.refSearch}>
                    <Search
                        placeholder={props.initialSearch ? props.initialSearch : "VIN, Box ID or Vehicle ID"}
                        // placeholder={props.initialSearch ? props.initialSearch : "enter a Vehicle ID, Box ID or Reservation ID"}
                        onSearch={props.onSearch}
                        allowClear={true}
                        size={'medium'}
                    />
                </Row>
            </Col>
            <Col>
                <Row style={{margin: 0}}>
                    <Text style={{fontWeight: 500}}> Time Range </Text>
                </Row>
                <Row style={{marginTop: 8}}>
                    <RangePicker
                        format={userDateTimeFormat()}
                        showTime={{
                            format: userTimeFormat,
                            use12Hours: use12HourTimeFormat(userLocale),
                        }}
                        disabled={!(props.vehicleTracking || props.vehicleStationary)}
                        presets={rangePresets}
                        disabledDate={(date) => {
                            if (props.permissions?.tracking === 'vehicle-tracking_limited') {
                                // If permission is limited, allow dates only within the past week
                                return date.isBefore(dayjs().subtract(7, 'd'));
                            } else {
                                // For other cases, allow dates within the past 90 days
                                return date.isBefore(dayjs().subtract(365, 'd'));
                            }
                        }}
                        onChange={props.handleDateRangeChange}
                        style={{marginBottom: 16, width: 475}}
                        allowClear={false}
                        value={props.dateRange}
                    />
                </Row>
            </Col>
            <Col>
                <Row style={{margin: 0}}>
                    <Text style={{fontWeight: 500}}> Map Mode </Text> <InfoTooltip/>
                </Row>
                <Row style={{marginTop: 8}}>
                    <Radio.Group
                        onChange={handleMapMode}
                        value={props.vehicleTracking ? "tracking" : props.vehicleStationary ? "stationary" : "position"}
                        disabled={!props.inputSearch}
                        type={'primary'}
                        buttonStyle={"solid"}
                        ref={props.refMapMode}
                    >
                        <Radio.Button value="position" type={'primary'}>Position</Radio.Button>
                        <Radio.Button value="tracking" type={'primary'}>Tracking</Radio.Button>
                        <Radio.Button value="stationary" type={'primary'}>Stationary</Radio.Button>
                    </Radio.Group>
                </Row>
            </Col>
            <Col>
                <Row style={{margin: 0}}>
                    <Text style={{fontWeight: 500}}> Live Update </Text> <InfoTooltip/>
                </Row>
                <Row style={{marginTop: 8}}>
                    <Radio.Group
                        onChange={handleUpdate} defaultValue="off"
                        disabled={!props.inputSearch || props.vehicleTracking || props.vehicleStationary}
                        type={'primary'}
                        ref={props.refLiveUpdate}
                    >
                        <Radio.Button value="off" type={'primary'}>Off</Radio.Button>
                        <Radio.Button value="on" type={'primary'}>On</Radio.Button>
                    </Radio.Group>
                    <Popover
                        title={'Settings'}
                        trigger="click"
                        placement={'right'}
                        content={<SettingsMenu searchSettings={props.searchSettings}/>}
                    >
                        <Button size={'medium'} style={{marginLeft: 24}} ref={props.refSettings}>
                            <Space>
                                <SettingOutlined/>
                            </Space>
                        </Button>
                    </Popover>
                </Row>
            </Col>
        </Row>
    );
};

export default SearchBar;
