import React, {useState, useRef, useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    Button,
    Card,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Popover,
    Switch,
    Tag,
    Tabs,
    Divider,
    Space,
    DatePicker, Tour
} from 'antd';
import dayjs, {Dayjs} from "dayjs";

import axiosInstance from "services/axios";
import AlertTable from "../components/FleetSecurity/AlertTable";
import MapCard from "../components/FleetSecurity/MapCard";
import AlertInfo from "../components/FleetSecurity/AlertInfo";
import Dashboard from "../components/FleetSecurity/Dashboard";
import HeaderRow from "../components/HeaderRow/HeaderRow";
import MapConfigCard from "../components/FleetSecurity/MapConfigCard";
import ConfigInfo from "../components/FleetSecurity/ConfigInfo";
import {ErrorBoundary} from "react-error-boundary";
import CVScreenHeader from "../common_components/Webapp/CVScreenHeader";
import {NumberOutlined} from "@ant-design/icons";

const {RangePicker} = DatePicker;

const {Title, Paragraph, Text, Link} = Typography;
const {Search} = Input;

const FleetSecurityScreen = (props) => {

    const refFilter = useRef(null);
    const refCategory = useRef(null);
    const refMap = useRef(null);
    const refUpdate = useRef(null);
    const refTable = useRef(null);

    const [showIntroTour, setShowIntroTour] = useState(true);

    const navigate = useNavigate();
    const [headerData, setHeaderData] = useState(null);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const [updateCounter, setUpdateCounter] = useState(0);
    const [updateActive, setUpdateActive] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [activeFleetsecurity, setActiveFleetsecurity] = useState(false);

    const [geoFencePolygon, setGeoFencePolygon] = useState(null);
    const [geoFenceLabel, setGeoFenceLabel] = useState(null);
    const [fleets, setFleets] = useState(null);

    const [matchingFilter, setMatchingFilter] = useState(null);
    const [fleetFilter, setFleetFilter] = useState(null);
    const [issueFilter, setIssueFilter] = useState(null);
    const [actionFilter, setActionFilter] = useState(['firing', 'pending', 'resolved']);
    const [dateRangeFilter, setDateRangeFilter] = useState(null);
    const [searchFilter, setSearchFilter] = useState(null);

    function sendMail() {
        let emailSubject = "Support"
        emailSubject += " Fleet Security"
        emailSubject += ` - ${props.customer}`

        if (selectedAlert) {
            emailSubject += ` - ${selectedAlert}`
        }

        const body_ = ""
        const link =
            'mailto:' +
            "damage-support@carvaloo.com" +
            //+ "?cc="
            '?subject=' +
            encodeURIComponent(emailSubject) +
            '&body=' +
            encodeURIComponent(body_)
        window.location.href = link
    }

    const onFinishIntroTour = () => {

        setShowIntroTour(false)

        props.setUserSettings((prevState) => ({
            ...prevState,
            intro_security_tour: false,
        }));

        const request = {
            customer: props.customer,
            setting_value: false,
            setting_name: 'intro_security_tour'
        }

        axiosInstance.post('/api/setup/settings', request)
            .then(res => {
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error finishing tour', description: error.message});
            })

    };

    const introSteps = [
        {
            title: 'Filter alerts',
            description: 'These filters allow you to search for an alert, select the timerange with which the map and table will be filtered or select the status filter. By default only alerts within the last 30 days are being shown. The global search bar allows for you to search for various alert details - such as different geofences.',
            target: () => refFilter.current,
            placement: 'top'
        },
        {
            title: 'Select a category',
            description: 'These dashboard cards show the total number of alerts for each security category. Clicking on these cards filters the map and table by this alert category. The columns display the alert count for each of the last 14 days',
            target: () => refCategory.current,
            placement: 'top'
        },
        {
            title: 'Using the map',
            description: 'The map displays the locations for all alerts in the table below. Alerts can be selected from the map directly. Depending on the alert category additional details are shown along with tracking information.',
            target: () => refMap.current,
            placement: 'top'
        },
        {
            title: 'Updating the alerts',
            description: 'Activate this switch to update the alerts on the map and in the table each minute automatically.',
            target: () => refUpdate.current,
            placement: 'top'
        },
        {
            title: 'Alert table',
            description: 'The table displays the details to all alerts. The details are sortable by clicking on the column headers. Alerts can be selected from the table directly and deselected by pressing "control" on your keyboard and clicking the alert again. You can also deselect an alert by clicking the blue X button.',
            target: () => refTable.current,
            nextButtonProps: {
                children: (
                    "Do not show again"
                ),
                onClick: onFinishIntroTour
                // style: {
                //     // backgroundColor: "red",
                //     // borderRadius: "50%",
                //     // width: 32,
                //     // minWidth: 32,
                //     // height: 32,
                // }
            }
        }
    ];

    useEffect(() => {
        if (props.userSettings.intro_security_tour) {
            setShowIntroTour(true)
        } else {
            setShowIntroTour(false)
        }

    }, []);

    const isTabVisible = () => {
        return !document.hidden;
    };

    const handleUpdateActive = useCallback((data) => setUpdateActive(data), []);

    useEffect(() => {
        if (updateActive) {
            notification['info']({
                message: 'Alert page will update automatically every 60 seconds',
                duration: 5
            });
        }
    }, [updateActive]);

    useEffect(() => {
        const path = window.location.pathname;
        if (path.includes("UAID")) {
            const parts = path.split("-");
            if (parts.length === 3 && parts[2].length === 12) {
                const uaidDateStr = parts[2];
                const year = parseInt(`20${uaidDateStr.substring(0, 2)}`, 10);
                const month = parseInt(uaidDateStr.substring(2, 4), 10) - 1; // JavaScript months are 0-11
                const day = parseInt(uaidDateStr.substring(4, 6), 10);
                const hour = parseInt(uaidDateStr.substring(6, 8), 10);
                const minute = parseInt(uaidDateStr.substring(8, 10), 10);
                const second = parseInt(uaidDateStr.substring(10, 12), 10);

                const parsedDate = dayjs(new Date(year, month, day, hour, minute, second));

                setDateRangeFilter([parsedDate.subtract(7, 'day'), parsedDate.add(7, 'day')]);

                setSelectedAlert(path.substring(path.indexOf("UAID")));
                setIssueFilter(null)
                setActionFilter(['firing', 'pending', 'resolved'])
            } else {
                setDateRangeFilter([dayjs().add(-30, 'd'), dayjs()]);
                notification['error']({
                    message: 'Not a valid UAID',
                    duration: 5
                });
            }
        } else {
            setDateRangeFilter([dayjs().add(-30, 'd'), dayjs()]);
        }

        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set('organization', props.customerId);
        const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;
        window.history.replaceState({}, document.title, newUrl);
    }, []);

    useEffect(() => {
        let intervalId;

        const updateCounter = () => {
            if (isTabVisible() && updateActive) {
                setUpdateCounter(prevCounter => prevCounter + 1);
            }
        };

        intervalId = setInterval(updateCounter, 60 * 1000); // 1 minute in milliseconds

        // Clear interval on component unmount
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        if (props.customer) {

            const request = {
                customer: props.customer,
            }
            axiosInstance.post('/api/geofunctions/setup', request)
                .then(res => {
                    setActiveFleetsecurity(res.data.fleetsecurity)
                    setFleets(res.data.fleets)
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({message: 'Error in setup', description: error.message});
                })
        }
    }, [props.customer]);


    useEffect(() => {
        if (props.customer) {
            const request = {
                customer: props.customer,
                fleet: fleetFilter,
            }
            axiosInstance.post('/api/geofunctions/geofence', request)
                .then(res => {
                    if (res.data.geofence) {
                        setGeoFenceLabel(res.data.geofence_label)
                        let geofenceObject = {}
                        res.data.geofence_label.forEach((labelObj, index) => {
                            const label = labelObj.label;
                            const paths = res.data.geofence[index];

                            geofenceObject[label] = [];

                            paths.forEach(path => {
                                const pathObj = {
                                    paths: path,
                                    strokeColor: '#2a2727',
                                    strokeOpacity: 0.8,
                                    strokeWeight: 2,
                                    fillColor: labelObj.alert_type === 'zone-enter' ? '#ff9696' : '#088ed7',
                                    fillOpacity: 0.05,
                                    text: labelObj.name
                                };
                                geofenceObject[label].push(pathObj);
                            });
                        });
                        setGeoFencePolygon(geofenceObject)
                    }
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({message: 'Error in setup geofences', description: error.message});
                })
        }
    }, [fleetFilter]);


    useEffect(() => {
        const request = {
            customer: props.customer,
            fleet: fleetFilter
        }
        axiosInstance.post('/api/geofunctions/headers', request)
            .then(res => {
                setHeaderData([
                    {
                        name: 'Alerted Unique Vehicles',
                        value: res.data.unique_count,
                        icon: (<NumberOutlined/>),
                    },
                    {
                        name: 'Total active',
                        value: res.data.total_active,
                        icon: (<NumberOutlined/>),
                    },
                    {
                        name: 'Total firing last 24 hours',
                        value: res.data.total_active_day,
                        icon: (<NumberOutlined/>),
                    }
                ])

            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error in header data', description: error.message});
            })
    }, [props.customer, fleetFilter]);

    const handleSelectedChange = (alertId) => {
        setSelectedAlert(alertId);

        if (alertId) {
            const valueStr = alertId.trim()
            const parts = valueStr.split("-");
            if (valueStr.includes("UAID") && parts.length === 3 && parts[2].length === 12) {
                navigate(`/fleet_security/${valueStr}?organization=${props.customerId}`);
            } else {
                notification['error']({
                        message: 'Not a valid UAID',
                        duration: 5
                    }
                );
            }
        } else {
            navigate('/fleet_security');
        }
    };

    const handleCardClick = (value) => {
        setIssueFilter(value);

        handleSelectedChange(null)

        if (issueFilter === value) {
            setIssueFilter(null)
        }

    };

    const tabItems = [
        {
            key: '1',
            label: 'Alerts',
            // children: 'Content of Alerts Tab',
        },
        {
            key: '2',
            label: 'Configuration',
            // children: 'Content of Alerts Tab',
        }
        // {
        //   key: '2',
        //   label: 'Tab 2',
        //   children: 'Content of Tab Pane 2',
        // },
        // {
        //   key: '3',
        //   label: 'Tab 3',
        //   children: 'Content of Tab Pane 3',
        // },
    ];

    return (<>
            <div style={{minWidth: 1150}}>
                {showIntroTour &&
                    <Tour
                        open={showIntroTour}
                        onClose={() => setShowIntroTour(false)}
                        steps={introSteps}
                        indicatorsRender={(current, total) => (<span>{current + 1} / {total}</span>)}
                    />
                }
                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                    <CVScreenHeader
                        title={'Fleet Security'}
                        headerData={headerData}
                        tabData={tabItems}
                        sendEmail={() => sendMail()}
                        setActiveTab={setActiveTab}
                        trial={props.trial}
                        limitedAccess={props.limitedAccess}
                        limitedAccessTooltip={"Only valid for suspicious activity for the fleets of Paris and Turin and a daily time period between 9 p.m. and 6 a.m."}
                    />
                </ErrorBoundary>
                {activeTab === "1" && (
                    <>
                        <Row style={{backgroundColor: "white"}}>
                            <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                <Dashboard
                                    customer={props.customer}
                                    update={updateCounter}
                                    activeFleetsecurity={activeFleetsecurity}
                                    handleCardClick={handleCardClick}
                                    updateActive={updateActive}
                                    handleUpdateActive={handleUpdateActive}
                                    setDateRangeFilter={setDateRangeFilter}
                                    dateRangeFilter={dateRangeFilter}
                                    setSearchFilter={setSearchFilter}
                                    searchFilter={searchFilter}
                                    issueFilter={issueFilter}
                                    handleSelectedChange={handleSelectedChange}
                                    selectedAlert={selectedAlert}
                                    actionFilter={actionFilter}
                                    setActionFilter={setActionFilter}
                                    refFilter={refFilter}
                                    refCategory={refCategory}
                                    setFleetFilter={setFleetFilter}
                                    fleetFilter={fleetFilter}
                                    fleets={fleets}
                                    setMatchingFilter={setMatchingFilter}
                                    matchingFilter={matchingFilter}
                                />
                            </ErrorBoundary>
                        </Row>
                        <div style={{
                            position: "relative",
                            // height: 600,
                            overflow: "hidden",
                            backgroundColor: '#eef0f2'
                        }}>
                            <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                <MapCard
                                    dateRangeFilter={dateRangeFilter}
                                    searchFilter={searchFilter}
                                    customer={props.customer}
                                    update={updateCounter}
                                    handleSelectedChange={handleSelectedChange}
                                    issueFilter={issueFilter}
                                    actionFilter={actionFilter}
                                    setActionFilter={setActionFilter}
                                    selectedAlert={selectedAlert} // Pass null or appropriate data to the map
                                    refMap={refMap}
                                    fleetFilter={fleetFilter}
                                    matchingFilter={matchingFilter}
                                    geoFencePolygon={geoFencePolygon}
                                    // style={{
                                    //     transition: 'transform 0.3s ease', // Adjust the duration and timing function as needed
                                    // }}
                                />
                            </ErrorBoundary>
                            <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                {selectedAlert &&
                                    <AlertInfo
                                        customerId={props.customerId}
                                        customer={props.customer}
                                        permissions={props.permissions}
                                        selectedAlert={selectedAlert} // Pass null or appropriate data to the map
                                        setUpdateCounter={setUpdateCounter}
                                        handleSelectedChange={handleSelectedChange}
                                    />}
                            </ErrorBoundary>
                        </div>
                        <div style={{padding: 16}}>
                            <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                <AlertTable
                                    updateActive={updateActive}
                                    handleUpdateActive={handleUpdateActive}
                                    setDateRangeFilter={setDateRangeFilter}
                                    dateRangeFilter={dateRangeFilter}
                                    setSearchFilter={setSearchFilter}
                                    searchFilter={searchFilter}
                                    customer={props.customer}
                                    update={updateCounter}
                                    issueFilter={issueFilter}
                                    handleSelectedChange={handleSelectedChange}
                                    selectedAlert={selectedAlert}
                                    actionFilter={actionFilter}
                                    setActionFilter={setActionFilter}
                                    refUpdate={refUpdate}
                                    refTable={refTable}
                                    fleetFilter={fleetFilter}
                                    matchingFilter={matchingFilter}
                                />
                            </ErrorBoundary>
                        </div>
                    </>
                )}
                {activeTab === "2" && (
                    <>
                        <Row gutter={24} style={{marginBottom: '8px', display: 'flex', paddingLeft: 28, paddingRight: 28, paddingTop: 24}}>
                            <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                {geoFencePolygon &&
                                    <MapConfigCard
                                        customer={props.customer}
                                        update={updateCounter}
                                        fleetFilter={fleetFilter}
                                        geoFencePolygon={geoFencePolygon}
                                    />
                                }
                            </ErrorBoundary>
                            <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                <ConfigInfo
                                    customer={props.customer}
                                    configData={geoFenceLabel}
                                    permissions={props.permissions}
                                    setUpdateCounter={setUpdateCounter}
                                    fleetFilter={fleetFilter}
                                />
                            </ErrorBoundary>
                        </Row>
                    </>
                )}
            </div>
        </>
    );
}

export default FleetSecurityScreen;
