import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';

import {
    Button,
    Card,
    DatePicker, notification, Typography, Tooltip, Switch, Modal, Spin
} from 'antd';
import dayjs, {Dayjs} from "dayjs";

import axiosInstance from "services/axios";
import SimpleMapComponent from "../../components/SimpleMapComponent/SimpleMapComponent";
import {customGoogleMapsStyle} from "../../assets/styles/google-maps-style";
import stationIcon from '../../assets/icons/station_3.svg';
import carIcon from '../../assets/icons/car.svg';

import MapMarkerTracking from "./MapMarkerTracking";
import MapMarkerStationary from "./MapMarkerStationary";

import {
    BorderOutlined, CaretRightOutlined,
    InfoCircleOutlined,
    PauseCircleOutlined,
    PauseOutlined,
    PlaySquareOutlined
} from '@ant-design/icons';
import MapMarkerCluster from "./MapMarkerCluster";
import {SettingsContext} from "../../App";

const {RangePicker} = DatePicker;
const {Title, Paragraph, Text, Link} = Typography;

const stationIconObj = {
    url: stationIcon,
    anchorHeight: 12,
    anchorWidth: 12,
    scaledWidth: 24,
    scaledHeight: 24,
    colorAdjust: false
}

const circleString = `<svg width="800px" height="800px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="iconify iconify--emojione" preserveAspectRatio="xMidYMid meet"><circle cx="32" cy="32" r="30" opacity="0.50" fill="#0071bc"></circle></svg>`;
const circleIconObj = {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(circleString)}`,
    anchorHeight: 5,
    anchorWidth: 5,
    scaledWidth: 10,
    scaledHeight: 10,
    colorAdjust: true
};

const iconDictionary = {
    location: carIcon,
    tracking: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(circleString)}`,
};

const MapCard = (props) => {

    const [loading, setLoading] = useState(false);
    const [ignoreBounds, setIgnoreBounds] = useState(null);
    const [mapData, setMapData] = useState(null);
    const [primaryMapMarker, setPrimaryMapMarker] = useState(null)
    const [secondaryMapData, setSecondaryMapData] = useState(null);
    const [initialRun, setInitialRun] = useState(null);
    const [renderMap, setRenderMap] = useState(0)
    const [isPOIActive, setPOIActive] = useState(false);
    const [animateTracking, setAnimateTracking] = useState(false);
    const [pauseAnimateTracking, setPauseAnimateTracking] = useState(null);

    const {mapCenter} = useContext(SettingsContext);

    useEffect(() => {
        if (!props.vehicleLocation) {
            setMapData(null)
        }
        if (!props.vehicleTracking) {
            setMapData(null)
        }
        if (!props.vehicleStationary) {
            setMapData(null)
            setPrimaryMapMarker(null)
        }
    }, [props.vehicleLocation, props.vehicleTracking, props.vehicleStationary]);

    const fetchLocation = async (payload) => {
        try {
            const response = await axiosInstance.post('/api/location/locate', payload);
            setLoading(false)
            if (response.data.markers) {
                setMapData(response.data.markers)
                props.setLastGPS(response.data.markers[0].ts)
            } else {
                notification['warning']({message: 'No location data available'});
            }
            if (response.data.secondary_markers) {
                setSecondaryMapData(response.data.secondary_markers)
            }
        } catch (error) {
            setLoading(false)
            props.setStatistics(null)
            setMapData(null)
            setSecondaryMapData(null)
            console.log(error);
            notification['error']({
                message: 'Error in get VehicleInfo',
                description: (
                    <>
                        {error.message + ':'}<br/>
                        {error.response && error.response.data && error.response.data.message}
                    </>
                ),
            });
        }
    };

    useEffect(() => {
        if (!initialRun) {
            const payload = {
                customer: props.customer,
                search: props.inputSearch,
                current_ts: mapData ? mapData[0].ts : null
            };

            if (props.vehicleLocation && props.inputSearch) {
                console.log('Updating vehicle location')
                notification['info']({message: 'Updating vehicle location', duration: 1});
                setIgnoreBounds(true)
                fetchLocation(payload);
            }
        }
        setInitialRun(false)

    }, [props.inputSearch, props.vehicleLocation, props.update]);

    useEffect(() => {
        if (mapData && props.selectedRow) {
            setRenderMap(renderMap + 1)

            const startTime = new Date(props.selectedRow[0]);
            const endTime = new Date(props.selectedRow[1]);
            mapData.forEach(marker => {

                if ('ts' in marker) {
                    const markerTime = new Date(marker.ts);
                    if (markerTime >= startTime && markerTime <= endTime) {
                        marker.markerColor = 'red';
                        marker.zIndex = 20000;
                    } else {
                        marker.markerColor = "#0071bc";
                    }
                } else {
                    const markerEnd = new Date(marker.ts_end);
                    const markerStart = new Date(marker.ts_start);
                    const averageTimestamp = new Date((markerEnd.getTime() + markerStart.getTime()) / 2);

                    if (averageTimestamp >= startTime && averageTimestamp <= endTime) {
                        marker.markerColor = 'red';
                        marker.zIndex = 20000;
                    } else {
                        marker.markerColor = "#0071bc";
                    }

                    // if (markerStart >= startTime && markerEnd <= endTime) {
                    //     marker.markerColor = 'red';
                    // } else {
                    //     marker.markerColor = "#0071bc";
                    // }
                }
            });
        }
    }, [props.selectedRow]);

    useEffect(() => {
        const payload = {
            customer: props.customer,
            search: props.inputSearch,
        };

        setInitialRun(true)

        if (props.vehicleLocation && props.inputSearch) {
            setIgnoreBounds(false)
            setLoading(true)
            fetchLocation(payload);
        }
        // else {
        //     setSecondaryMapData(null)
        // }

    }, [props.inputSearch, props.vehicleLocation]);

    useEffect(() => {
        const payload = {
            customer: props.customer,
            search: props.inputSearch,
            range: props.dateRange,
            min_stationary: props.searchSettings.min_stationary,
            include_station: props.searchSettings.include_station,
            marker_size: props.searchSettings.marker_size,
            cluster_stationary: props.searchSettings.cluster_stationary,
        };

        setAnimateTracking(false)
        setPauseAnimateTracking(null)

        const fetchTracking = async (payload) => {
            try {
                const response = await axiosInstance.post('/api/location/tracking', payload);
                setLoading(false)

                if (response.data.tracking) {
                    // props.setStatistics(response.data.statistics)
                    // props.setTableData(response.data.stationary)
                    if (props.vehicleTracking) {
                        // console.log(response.data.tracking)
                        if (response.data.tracking.length > 9999) {
                            notification['warning']({message: 'Showing maximum number of points - please reduce date range'});
                        }
                        setMapData(response.data.tracking)
                        // props.setTableData(response.data.tracking)
                    }
                    if (props.vehicleStationary) {
                        setMapData(response.data.stationary)
                        // props.setTableData(response.data.stationary)
                    }
                } else {
                    setMapData(null)
                    // props.setStatistics(null)
                    // props.setTableData(null)
                    notification['warning']({message: 'No GPS data found for this datetime range'});
                }

                if (response.data.warning) {
                    if (!props.limitedWarning) {
                        notification['warning']({
                            message: 'Limited tracking permissions for this vehicle - for the selected timeframe only data from the last 7 days is available',
                            duration: 10,
                        });
                    }
                    props.setLimitedWarning(true)
                } else {
                    props.setLimitedWarning(false)
                }

            } catch (error) {
                setLoading(false)
                // props.setStatistics(null)
                // props.setTableData(null)
                console.log(error);
                notification['error']({message: 'Error retrieving data', description: error.message});
            }
        };

        if (props.inputSearch && (props.vehicleStationary || props.vehicleTracking)) {
            setIgnoreBounds(false)
            setPrimaryMapMarker(circleIconObj)
            setLoading(true)
            fetchTracking(payload);
        }

    }, [props.inputSearch, props.dateRange, props.vehicleStationary, props.vehicleTracking, props.searchSettings]);

    const InfoTooltip = ({text}) => {
        return (
            <Tooltip title={text}>
                <InfoCircleOutlined style={{color: '#088ed7', fontSize: '16px', marginLeft: '5px'}}/>
            </Tooltip>
        );
    };

    const onSelectedMarkerChange = (marker) => {
    };

    const handleTogglePOI = () => {
        setPOIActive(!isPOIActive);
    };

    const handlePlayAnimation = () => {
        if (animateTracking) {
            setAnimateTracking(false)
            setPauseAnimateTracking(null)
        } else {
            setAnimateTracking(true)
        }
    };

    const handlePauseAnimation = () => {
        if (pauseAnimateTracking) {
            setPauseAnimateTracking(false)
        } else {
            setPauseAnimateTracking(true)
        }
    };

    return (<>
            <Modal
                open={loading}
                closable={false}
                footer={null}
                centered={true}
                mask={true}
                maskClosable={false}
                styles={{ body: {backdropFilter: 'blur(3px)'}}}
            >
                <div>
                    <Spin style={{marginRight: 20}} size="large"/>
                    Loading tracking data...
                </div>
            </Modal>

            <div style={{position: "absolute", zIndex: 5, top: 12, left: 200}}>
                <Switch
                    size={'medium'}
                    onChange={handleTogglePOI}
                    checkedChildren="POIs"
                    unCheckedChildren="POIs"
                    ref={props.refPOIs}
                    style={{marginRight: 16}}
                />
                {props.vehicleTracking &&
                    <>
                        {animateTracking &&
                            <Button
                                size={'medium'}
                                icon={<PauseOutlined/>}
                                onClick={handlePauseAnimation}
                                shape="circle"
                                style={{
                                    marginRight: 16,
                                    backgroundColor: pauseAnimateTracking ? '#1890ff' : 'white',
                                    // opacity: pauseAnimateTracking ? 0.5 : 1,
                                }}
                            />
                        }
                        <Button
                            size={'medium'}
                            icon={animateTracking ? <BorderOutlined/> : <CaretRightOutlined/>}
                            onClick={handlePlayAnimation}
                            shape="circle"
                            style={{marginRight: 16}}
                            ref={props.refTour2}
                        >
                            {/*{animateTracking ? '' : 'Animation'}*/}
                        </Button>
                    </>
                }
            </div>
            <SimpleMapComponent
                mapHeight={550}
                relativeHeight={props.activeDashboard ? 72 : 86}
                customStyle={customGoogleMapsStyle}
                center={mapCenter}
                onSelectedMarkerChange={onSelectedMarkerChange}
                iconDictionary={iconDictionary}
                markers={mapData}
                markersSecondary={secondaryMapData}
                markerIconPrimary={primaryMapMarker}
                markerIconSecondary={stationIconObj}
                markerInfo={props.vehicleStationary && props.searchSettings.cluster_stationary ? MapMarkerCluster : props.vehicleStationary ? MapMarkerStationary : MapMarkerTracking}
                ignoreBounds={ignoreBounds}
                renderMap={renderMap}
                maxZoomLevel={15}
                isPOIActive={isPOIActive}
                animateTracking={animateTracking}
                pauseAnimateTracking={pauseAnimateTracking}
                animationDuration={props.animationDuration}
            />
        </>
    );
}

export default MapCard;