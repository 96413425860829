import React, {useEffect, useState, useContext} from "react";
import {Layout, Menu, Image, ConfigProvider} from 'antd';
import {useNavigate} from "react-router-dom";

const {Sider} = Layout;


const CVSidebar = (props) => {

    const sidebarWidthCollapsed = 67  // 67
    const sidebarWidthExpanded = props.sidebarWidthExpanded ? props.sidebarWidthExpanded : 145

    return (
        <div style={{
            display: 'flex',
            height: "100vh",
        }}>
            <ConfigProvider theme={
                {
                    token: {
                        colorPrimary: props.color
                    },
                    components: {
                        Menu: {
                            collapsedIconSize: 16,
                            iconSize: 14,
                            colorBgContainer: props.color,
                            itemBg: props.color,
                            subMenuItemBg: props.color,
                            itemSelectedBg: props.color,
                            darkItemBg: props.color,
                            darkSubMenuItemBg: props.color,
                            darkItemSelectedBg: '#5cb5e5',
                        },
                    }
                }
            }
            >
                <Sider
                    collapsible={true}
                    defaultCollapsed={true}
                    width={sidebarWidthExpanded}
                    collapsedWidth={sidebarWidthCollapsed}
                    onCollapse={props.onCollapse}
                    style={{
                        height: "100vh",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            height: "100%"
                        }}>
                        <div style={{
                            padding: 16,
                            width: '100%',
                            display: "flex",
                            justifyContent: 'center'
                        }}>
                            <Image src={props.logo} preview={false} style={{height: "32px", width: "auto"}}/>
                        </div>
                        <div style={{
                            // marginTop: 64,
                        }}>
                            <Menu
                                theme={"dark"}
                                className="custom-menu"
                                defaultSelectedKeys={[props.selectedKey]}
                                selectedKeys={[props.selectedKey]}
                                items={props.menuItems}
                                onSelect={props.onSelect}
                            />
                        </div>
                        <div style={{
                            // verticalAlign: 'bottom'
                        }}>
                            <Menu
                                theme="dark"
                                defaultSelectedKeys={[props.selectedKey]}
                                selectedKeys={[props.selectedKey]}
                                items={props.settingsItems}
                                onClick={props.onClickSettings}
                                onSelect={props.onSelect}
                                style={{
                                    marginTop: 'auto',
                                }}
                            />
                        </div>
                    </div>
                </Sider>
            </ConfigProvider>
        </div>
    );
}


export default CVSidebar;