import React, {useState} from 'react';
import {Modal} from 'antd';
import ClusterMap from "./ClusterMap";

const ClusterMapMapModal = (props) => {

  const handleCancel = () => {
    props.hideModal && props.hideModal();
  };

  return (
    <>
      <Modal title="Kartenansicht"
             open={props.open}
             onCancel={handleCancel}
             width={"80vw"}
             height={"80vh"}
             footer={null}
      >
        <ClusterMap
          mapHeight={props.mapHeight}
          mapLocations={props.mapLocations}
          mapLocationsLoading={props.mapLocationsLoading}
          selectedMarker={props.selectedMarker}
          handleSelectedMarkerChange={props.handleSelectedMarkerChange}
        />
      </Modal>
    </>
  );
};

export default ClusterMapMapModal;